import React, { Component } from 'react';
import { withStyles, Grid, Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import WithContexts from '../../contexts/withContexts';
import InternalServerErrorImage from '../../assets/images/500.png';
import FileNotFoundImage from '../../assets/images/404.png';
import ServiceUnavailableImage from '../../assets/images/503.png';
import Logo from '../../assets/logo/locumtenens_com_logo.svg';
import { COLORS } from '../../utils/Application_Constants';

const styles = (theme) => ({
	root: {
		justifyContent: 'center',
		textAlign: 'center',
		padding: '50px 0',
		color: COLORS.RAVEN,
	},
	header: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '700',
		fontSize: '45px',
		margin: '30px 0 0',
		[theme.breakpoints.down('sm')]: {
			margin: '0px',
		},
	},
	innerMessage: {
		fontWeight: '700',
		fontSize: '24x',
	},
	graphicContainer: {
		margin: '20px',
	},
	graphic: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '75%',
		},
	},
	notFoundGraphic: {
		width: '70%',
		[theme.breakpoints.down('sm')]: {
			width: '95%',
		},
	},
	homeButton: {
		fontWeight: '700',
		marginTop: '10px',
	},
	helpText: {
		fontWeight: '500',
		fontSize: '16px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
});

class Error extends Component {
	constructor(props) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName('Oops...');
	}

	renderImage() {
		const { classes, errorCode } = this.props;
		switch (errorCode) {
			case '404':
				return (
					<img
						src={FileNotFoundImage}
						alt='File Not Found'
						className={classes.notFoundGraphic}
					/>
				);
			case '503':
				return (
					<img
						src={ServiceUnavailableImage}
						alt='Service Unavailable'
						className={classes.graphic}
					/>
				);
			default:
				return (
					<img
						src={InternalServerErrorImage}
						alt='Internal Server Error'
						className={classes.graphic}
					/>
				);
		}
	}

	renderNextSteps() {
		const { classes, errorCode, t } = this.props;
		if (errorCode === '503') {
			return (
				<Grid item xs={11} md={6}>
					<h3 className={classes.helpText}>
						{t('error:503.NEXT_STEPS')}
						<a
							href='https://twitter.com/LocumTenens_com'
							target='_blank'
							rel='noopener noreferrer'
							className={classes.clickableSpan}
						>
							Twitter
						</a>
						{t('error:503.CONJUNCTION')}
						<a
							href='https://www.facebook.com/LocumTenenscom/'
							target='_blank'
							rel='noopener noreferrer'
							className={classes.clickableSpan}
						>
							Facebook
						</a>
						.
					</h3>
				</Grid>
			);
		}
		return (
			<>
				<Grid item xs={10}>
					<Button
						className={classes.homeButton}
						variant='contained'
						color='primary'
						href='#/'
					>
						{t('error:BUTTON.HOME')}
					</Button>
				</Grid>
				<Grid item xs={11} md={6}>
					<h3 className={classes.helpText}>
						{t(`error:${errorCode}.NEXT_STEPS`)}
						<a
							href='mailto: CustomerService@LocumTenens.com'
							className={classes.clickableSpan}
						>
							{' '}
							CustomerService@LocumTenens.com{' '}
						</a>
						{t(`error:${errorCode}.CONJUNCTION`)}
						<a
							href={t('common:INFORMATION.HELP_NUMBER_LINK')}
							className={classes.clickableSpan}
						>
							{t('common:INFORMATION.HELP_NUMBER')}
						</a>
						.
					</h3>
				</Grid>
			</>
		);
	}

	render() {
		const { classes, errorCode, t } = this.props;

		//403 signals B2C Logout is happening so just show blank
		if (errorCode === '403') {
			return <div className={classes.container}></div>;
		} else if (errorCode === '999') {
			return (
				<div className={classes.container}>
					<div id='login-container' className={classes.loginContainer}>
						<img src={Logo} className={classes.image} alt='LocumTenens Logo' />
						<h2>Email issue detected</h2>
						<div className={classes.divOne}>
							<p>
								Temporary Email issue detected. Please wait 5 minutes and try again.
							</p>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<Grid container className={classes.root}>
					<Grid item xs={10}>
						<h1 className={classes.header}>{t(`error:${errorCode}.HEADER`)}</h1>
					</Grid>
					<Grid item xs={6}>
						<h3 className={classes.innerMessage}>
							{t(`error:${errorCode}.INNER_MESSAGE`)}
						</h3>
					</Grid>
					<Grid
						item
						xs={errorCode === '404' ? 12 : 10}
						classes={{ root: classes.graphicContainer }}
					>
						{this.renderImage()}
					</Grid>
					{this.renderNextSteps()}
				</Grid>
			);
		}
	}
}

export default WithContexts(withTranslation()(withStyles(styles)(Error)));
