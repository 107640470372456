import React from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	buttonContainer: {
		alignItems: 'center',
		justify: 'center',
	},
}));

function ViewMoreButton({ t, viewMore, toggleViewMore }) {
	const classes = styles();

	return (
		<Grid container direction='column' className={classes.buttonContainer}>
			{viewMore ? (
				<Grid item xs={12} md={12}>
					<Button
						variant='outlined'
						size='medium'
						color='primary'
						onClick={toggleViewMore}
					>
						{t('credentials:CRED_OVERVIEW.VIEW_LESS')}
					</Button>
				</Grid>
			) : (
				<Grid item xs={12} md={12}>
					<Button
						variant='outlined'
						size='medium'
						color='primary'
						onClick={toggleViewMore}
					>
						{t('credentials:CRED_OVERVIEW.VIEW_MORE')}
					</Button>
				</Grid>
			)}
		</Grid>
	);
}

export default ViewMoreButton;
