import React from 'react';
import { withStyles, Grid, Button } from '@material-ui/core';
import WithContexts from '../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import GenericErrorImage from '../../../../assets/images/generic-error-image.png';
import { COLORS } from '../../../../utils/Application_Constants';
import UserContext from '../../../../contexts/userContext';
import msSalService from '../../../../services/msSalService';

const styles = (theme) => ({
	root: {
		justifyContent: 'center',
		textAlign: 'center',
		padding: '50px 0',
		color: COLORS.RAVEN,
	},
	header: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '800',
		fontSize: '35px',
		margin: '30px 0 10px',
		[theme.breakpoints.down('sm')]: {
			margin: '0px',
		},
	},
	innerMessage: {
		fontWeight: '700',
		fontSize: '24x',
		textAlign: 'center',
		marginTop: '20px',
	},
	graphicContainer: {
		margin: '20px',
	},
	graphic: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '75%',
		},
	},
	notFoundGraphic: {
		width: '70%',
		[theme.breakpoints.down('sm')]: {
			width: '95%',
		},
	},
	reportButton: {
		fontWeight: '700',
		marginTop: '20px',
		fontSize: '20px',
	},
	helpText: {
		fontWeight: '500',
		fontSize: '16px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		textAlign: 'center',
		cursor: 'pointer',
	},
});

export class ErrorBoundaryPage extends React.Component {
	componentDidMount() {
		const { flags, updateFlags } = this.props;
		if (flags.navDisabled === false) {
			flags.navDisabled = true;
			updateFlags(flags);
		}
	}

	render() {
		const { classes, t } = this.props;
		const userName = msSalService.pullEmailFromAccount();
		const date = new Date().toLocaleString();
		const currentPage = window.location.href;
		const emailLink = `mailto: lt_onlineservices@locumtenens.com?subject=Clinician Portal Error&body=${userName} had an error on ${date} at:%0A%0A${currentPage}`;
		return (
			<Grid container className={classes.root}>
				<Grid item xs={10}>
					<h1 className={classes.header}>{t(`error:GENERIC.HEADER`)}</h1>
				</Grid>
				<Grid item xs={6}>
					<h3 className={classes.innerMessage}>
						{t(`error:GENERIC.INNER_MESSAGE`)}
						<a className={classes.clickableSpan} href={emailLink}>
							{' '}
							lt_onlineservices@locumtenens.com
						</a>
						.
					</h3>
				</Grid>
				<Grid item xs={10}>
					<Button className={classes.reportButton} href={emailLink} color='primary'>
						Report this issue
					</Button>
				</Grid>
				<Grid item xs={10} className={classes.graphicContainer}>
					<img src={GenericErrorImage} alt='Client Error' className={classes.graphic} />
				</Grid>
			</Grid>
		);
	}
}

ErrorBoundaryPage.contextType = UserContext;
export default WithContexts(withTranslation()(withStyles(styles)(ErrorBoundaryPage)));
