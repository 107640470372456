import { Chip, Grid, IconButton, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { useState } from 'react';
import { COLORS } from '../../../utils/Application_Constants';

const useStyles = makeStyles((theme) => ({
	menuButton: {
		color: COLORS.LT_MIDNIGHT25,
	},
	menuIcon: {
		color: COLORS.LT_MIDNIGHT25,
		display: 'flex',
		alignItems: 'center',
	},
	actionsCell: {
		padding: '4px 16px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	chipRoot: {
		textAlign: 'center',
		width: '100%',

		[theme.breakpoints.up('lg')]: {
			minWidth: '175px',
		},
	},
	chipActive: {
		backgroundColor: COLORS.AQUA_SPRING,
		color: COLORS.KELLY_GREEN,
		borderColor: COLORS.KELLY_GREEN,
	},
	chipInactive: {
		backgroundColor: COLORS.LT_PEPPER10,
		color: COLORS.LT_PEPPER,
		borderColor: COLORS.LT_PEPPER,
	},
	tableBody: {
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		borderRadius: '8px',
	},
	tableRow: {
		'&:not(:last-child)': {
			borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		},
		'&:hover': {
			transition: '.25s',
			cursor: 'pointer',
			background: COLORS.LT_MIDNIGHTBG1,
		},
	},
	tableCell: {
		padding: '16px',
		'&:first-child': {
			paddingLeft: '24px',
		},
		'& .MuiTypography-body2': {
			maxWidth: '100%',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			textWrap: 'nowrap',
		},
	},
}));

const TableBody = (props) => {
	const classes = useStyles();
	const { items, tableColumns, handleView, actions } = props;
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const renderStatusChip = (item, size, uniqueKey) => (
		<Grid item xs={size} className={classes.tableCell} key={`${uniqueKey}-status`}>
			<Chip
				label={item.status}
				className={classes[`chip${item.status}`]}
				classes={{ root: classes.chipRoot }}
				title={item.status}
			/>
		</Grid>
	);

	const renderActions = (item, uniqueKey) => (
		<Grid item xs={1} className={classes.actionsCell} key={`${uniqueKey}-actions`}>
			<IconButton
				className={classes.menuButton}
				color='default'
				aria-label='More options'
				onClick={handleClick}
				id={`${uniqueKey}-btn`}
			>
				<MoreVert />
			</IconButton>
			<Menu
				id={`${uniqueKey}-menu`}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl?.id === `${uniqueKey}-btn`)}
				onClose={handleClose}
			>
				{actions.map(({ name, icon }) => {
					const isActive = item.status === 'Active';
					const hideEdit = !isActive && name === 'Edit';
					if (hideEdit) return;
					return (
						<MenuItem
							onClick={() => {
								handleClose();
							}}
							key={name}
						>
							<Grid container alignItems='center' spacing={2}>
								<Grid item className={classes.menuIcon}>
									{icon}
								</Grid>
								<Grid item>
									<Typography variant='body2'>{name}</Typography>
								</Grid>
							</Grid>
						</MenuItem>
					);
				})}
			</Menu>
		</Grid>
	);

	return (
		<div className={classes.tableBody}>
			{items.map((item) => {
				const uniqueKey = `${item.id}-key`;
				return (
					<Grid
						container
						className={classes.tableRow}
						key={uniqueKey}
						alignItems='center'
						onClick={handleView}
					>
						{tableColumns.map(({ key, size }) => {
							const isEmpty = item[key] === 0 || !item[key];
							const isActions = key === 'actions';
							const isStatus = key === 'status';
							if (isStatus) return renderStatusChip(item, size, uniqueKey);
							if (isActions) return renderActions(item, uniqueKey);
							return (
								<Grid
									key={`${uniqueKey}-${key}`}
									item
									xs={size}
									className={classes.tableCell}
									// onClick={() => handleView(item)}
								>
									<Typography
										variant='body2'
										title={item[key]}
										className={isEmpty ? classes.emptyText : null}
									>
										{isEmpty ? '-' : item[key]}
									</Typography>
								</Grid>
							);
						})}
					</Grid>
				);
			})}
		</div>
	);
};

export default TableBody;
