import React, { useEffect, useState } from 'react';
import { Container, Grid, withStyles, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import ExpenseButtons from '../Components/Expense/ExpenseButtons';
import ExpenseReceiptUpload from '../Components/Expense/ExpenseReceiptUpload';
import ExpenseEntryHeader from '../Components/Expense/ExpenseEntryHeader';
import { createExpense, createExpenseImage,updateExpense, getExpenseById, getExpenseCategories, getDivisions } from '../Services/ExpenseService';
import { formatErrorMessage } from '../Utils/ErrorMessage';
import Alert from '../Components/Alert';
import ExpenseTabs from '../Components/Expense/Tabs/ExpenseTabs';
import ExpenseTab from '../Components/Expense/Tabs/ExpenseTab';
import ExpenseTabLabel from '../Components/Expense/Tabs/ExpenseTabLabel';
import AddIcon from '@material-ui/icons/Add';
import AddExpenseTab from '../Components/Expense/Tabs/AddExpenseTab';
import ExpenseTabPanel from '../Components/Expense/Tabs/ExpenseTabPanel';
import BookingDetailsForm from '../Components/Expense/BookingDetailsForm';
import ClinicianExpenseDetailsForm from '../Components/Expense/ClinicianExpenseDetailsForm';
import ExpenseSummary from '../Components/Expense/ExpenseSummary';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import InternalNotesForm from '../Components/Expense/InternalNotesForm';
import ReasonForSplittingForm from '../Components/Expense/ReasonForSplittingForm';
import { ExpenseStatusEnum } from '../Components/Expense/ExpenseStatus.enum';
import ExpenseNeedsReviewAlert from '../Components/Expense/ExpenseNeedsReviewAlert';
import _ from 'lodash';

const styles = (theme) => ({
    ContainerRoot: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        paddingTop: '1.5rem',
        paddingBottom: '5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
    },
    formGridContainer: {
        gap: 32,
    },
});

const defaultExpense = {
    bookingId: null,
    clinicianId: '',
    clinicianFirstName: '',
    clinicianLastName: '',
    clientId: '',
    division: '',
    state: '',
    billType: '',
    category: '',
    type: '',
    currency: 'USD',
    amount: '',
    comments: '',
    label: 'Expense',
    mileage: '',
    status: '',
    salesperson: '',
    recruiter: '',
    payDate: null,
    expenseClass: 1,
    splitTransactionComment: ''
};

const ClinicianReimbursement = (props) => {
    const { classes } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [expenseCategories, setExpenseCategories] = useState(null);
    const [divisions, setDivisions] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const [alert, setAlert] = useState();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const backLink = id ? 'All Expenses' : 'Expense Types';
    const title = id ? 'Clinician Reimbursement' : 'Clinician Reimbursement Expense Entry';
    const expensesPath = id ? '/expenses/review' : '/expenses';

    const defaultValues = {
        fileUpload: [],
        expenseEntries: [defaultExpense],
    };
    const methods = useForm({ shouldUnregister: false, defaultValues: defaultValues });
    const { handleSubmit, reset, control, getValues,watch,setValue,clearErrors } = methods;
    const {
        fields: expenseEntries,
        append,
        remove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'expenseEntries', // unique name for your Field Array
        keyName: 'expenseEntryId',
    });
    const watchExpenseEntries = watch(`expenseEntries`);
    useEffect(() => {

        Promise.all([getDivisions(), getExpenseCategories('NonSGA')])
            .then(async ([divs, cats]) => {
                setExpenseCategories(cats);
                setDivisions(divs);
            })
            .catch((error) => {
                console.error(error);
            });

        if (id) {
            fetchExpenseById();
        }
    }, []);

    useEffect(() => {
        if (watchExpenseEntries.length === 1 && watchExpenseEntries[0].status != "Posted"){
            clearErrors(`expenseEntries[0].splitTransactionComment`);
            setValue(`expenseEntries[0].splitTransactionComment`, '');
        }
	}, [watchExpenseEntries.length, setValue])

    const fetchExpenseById = async () => {
        try {
            setIsLoading(true);
            const res = await getExpenseById(id);
            const formattedValues = formatResponse(res);
            //Reset back to Null as empty string on the as the default was causing issues
            formattedValues.payDate = (_.isString(formattedValues.payDate) && formattedValues.payDate.length === 0) ? null : formattedValues.payDate.substring(0,10);
            const expenseEntries = [{ ...formattedValues, label: 'Expense' }];
            const defaultValues = { fileUpload: [], expenseEntries };
            reset(defaultValues);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const formatResponse = (res) => {
        return Object.fromEntries(
            Object.entries(res).map(([key, value]) => [key, value === null ? '' : value])
        );
    };

    const validateSubmit = async () => {
        handleSubmit(onSubmit)();
    };

    const validateSave = async () => {
        handleSubmit(onSave)();
    };

    const getStatusKey = (value) => {
        return Object.keys(ExpenseStatusEnum).find((key) => ExpenseStatusEnum[key].value === value);
    };

    const prepareExpense = (expense) => {
        expense.bookingId = parseInt(expense.bookingId, 10);
        expense.clientId = expense.clientId !== '' ? expense.clientId : null;
        expense.mileage = expense.mileage !== '' ? expense.mileage : null;
        expense.status = getStatusKey(expense.status);
        Object.keys(expense).forEach((i) => {
            if (_.isString(expense[i]) && expense[i].length === 0){
                expense[i] = null;
            }
         }   
        );
        return expense;
    };
    
    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            const expenses = data.expenseEntries.map(prepareExpense);
            const expenseResponse = await createExpense(expenses[0]);
            const remainingExpenses = expenses.slice(1);
            const promises = remainingExpenses.map((expense) => createExpense(expense));
            await Promise.all(promises);

            if (data.fileUpload) {
                const expenseId = expenseResponse.id;
                let imgData = new FormData();
                const files = data.fileUpload;
                if (Array.isArray(files)) {
                    files.forEach((file) => {
                        imgData.append('Images', file);
                    });
                } else {
                    imgData.append('Images', files);
                }
                imgData.append('Id', expenseId);
                imgData.append('IsPrecoded', false);
                await createExpenseImage(imgData);
            }
            history.push({
                pathname: '/expenses/submit',
                state: { prevPage: '/expenses/clinician-reimbursement' }
            });
        } catch (error) {
            console.error('Error onSubmit Clinician Reimbursement Expense Entry');
            reset({ expenseEntries: data.expenseEntries, fileUpload: data.fileUpload });
            const message = formatErrorMessage(error);
            setAlert({
                message,
                type: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const onSave = async (data) => {
        try {
            setIsLoading(true);
            const expenses = data.expenseEntries.map(prepareExpense);
            const expenseResponse = await updateExpense(expenses[0].id,expenses[0]);
            const remainingExpenses = expenses.slice(1);
            const promises = remainingExpenses.map((expense) => 
                {
                    if (expense.id && expense.id > 0){
                        updateExpense(expense.id,expense)
                    }else{
                        createExpense(expense)
                    }
                }
            );
            await Promise.all(promises);
            //Image handling onSave commented out for now
            /*
            if (data.fileUpload) {
                const expenseId = expenseResponse.id;
                let imgData = new FormData();
                const files = data.fileUpload;
                if (Array.isArray(files)) {
                    files.forEach((file) => {
                        imgData.append('Images', file);
                    });
                } else {
                    imgData.append('Images', files);
                }
                imgData.append('Id', expenseId);
                imgData.append('IsPrecoded', false);
                await createExpenseImage(imgData);
            }
            */
            history.push({
                pathname: '/expenses/submit',
                state: { prevPage: '/expenses/review' }
            });
        } catch (error) {
            console.error('Error onSave Clinician Reimbursement Expense Entry');
            reset({ expenseEntries: data.expenseEntries, fileUpload: data.fileUpload });
            const message = formatErrorMessage(error);
            setAlert({
                message,
                type: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddExpense = () => {
        const newExpense = {
            ...getValues('expenseEntries[0]'),
            label: 'New Expense',
            amount: '',
            billType: '',
            id: null,
            created: null,
            lastModified: null,
        };
        if (newExpense.hasOwnProperty('errors')) {
            delete newExpense.errors;
        }
        if (newExpense.hasOwnProperty('importedData')) {
            delete newExpense.importedData;
        }
        append(newExpense);
        setActiveTabIndex(expenseEntries.length);
    };

    const handleRemoveExpense = (index) => {
        remove(index);
        setActiveTabIndex(index - 1);
    };

    return (
        <Container maxWidth='xl' className={classes.ContainerRoot}>
            <FormProvider {...methods}>
                <Grid container direction='column' spacing={2}>
                    <ExpenseEntryHeader
                        title={title}
                        backLink={backLink}
                        expensesPath={expensesPath}
                    />
                    {isLoading ? (
                        <CircularProgress color='primary' />
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={4}>
                                        <Grid item sm={7} md={8} lg={9} xs={12}>
                                            <ExpenseTabs value={activeTabIndex}>
                                                {expenseEntries.map((expense, index) => (
                                                    <ExpenseTab
                                                        key={expense.expenseEntryId}
                                                        id={`expense-tab-${index}`}
                                                        label={
                                                            <ExpenseTabLabel
                                                                isActive={activeTabIndex === index}
                                                                index={index}
                                                                handleRemoveTab={() =>
                                                                    handleRemoveExpense(index)
                                                                }
                                                                setValue={setActiveTabIndex}
                                                                tab={expense}
                                                            />
                                                        }
                                                    />
                                                ))}
                                                <AddExpenseTab
                                                    label={<AddIcon color='primary' />}
                                                    disabled={expenseEntries.length === 5}
                                                    onClick={handleAddExpense}
                                                    id='expense-tab-add'
                                                />
                                            </ExpenseTabs>
                                            {expenseEntries.map((expense, index) => (

                                                <ExpenseTabPanel
                                                    className={classes.tabPanel}
                                                    key={`${expense.expenseEntryId}-panel`}
                                                    value={activeTabIndex}
                                                    index={index}
                                                    id={`expense-panel-${index}`}
                                                >
                                                    {expense.errors && expense.errors.length > 0 && (
                                                        <ExpenseNeedsReviewAlert expense={expense} />
                                                    )}

                                                    <Grid
                                                        container
                                                        direction='column'
                                                        className={classes.formGridContainer}
                                                    >

                                                        <Grid item xs={12}>
                                                            <BookingDetailsForm
                                                                index={index}
                                                                expense={expense}
                                                                divisions={divisions}
                                                                isSGA={[]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ClinicianExpenseDetailsForm
                                                                index={index}
                                                                expense={expense}
                                                                expenseCategories={expenseCategories}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <InternalNotesForm
                                                                index={index}
                                                                expense={expense}
                                                                isReimbursement
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ReasonForSplittingForm
                                                                index={index}
                                                                expense={expense}
                                                                isReimbursement
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </ExpenseTabPanel>
                                            ))}
                                        </Grid>
                                        <Grid item md={4} sm={5} lg={3} xs={12}>
                                            <ExpenseReceiptUpload />
                                            <ExpenseSummary />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid item xs={12}>
                                <ExpenseButtons
                                    validateSubmit={validateSubmit}
                                    validateSave={validateSave}
                                    defaultValues={defaultValues}
                                    setActiveTabIndex={setActiveTabIndex}
                                    expensesPath= {id ? '/expenses/review' : '/expenses'}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </FormProvider>
            <Alert alert={alert} setAlert={setAlert} />
        </Container>
    );
};

export default withStyles(styles)(ClinicianReimbursement);
