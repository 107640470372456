import { AppBar, Hidden, withStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import DesktopNavigation from '../../components/HeaderComponents/DesktopNavigation/DesktopNavigation';
import MobileNavbar from '../../components/HeaderComponents/MobileNavbar/MobileNavbar';
import { AdminAuthContext } from '../Contexts/AdminAuthContext';

const styles = (theme) => ({
	root: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		borderRadius: 3,
		boxShadow: '0px 3px 12px #0000000D',
		color: 'black',
		height: '60px',
		zIndex: 1300,
		[theme.breakpoints.up('md')]: {
			height: '80px',
		},
	},
	filler: {
		height: '80px',
		[theme.breakpoints.down('sm')]: {
			height: '60px',
		},
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		marginTop: '25vh',
	},
});

const timesheetLink = {
	name: 'Timesheet Management',
	isParentClickable: true,
	parentUrlLink: '/timesheets',
	children: [],
	id: 1,
};

const expenseLink = {
	name: 'Expense Management',
	isParentClickable: true,
	parentUrlLink: '/expenses',
	children: [],
	id: 2,
};

const creditCardLink = {
	name: 'Credit Card Management',
	isParentClickable: true,
	parentUrlLink: '/credit-cards',
	children: [],
	id: 3,
};

const AdminNavbar = (props) => {
	const { classes } = props;
	const [navigation, setNavigation] = useState([]);
	const {
		isTimesheetUser,
		isExpenseUser,
		isExpenseSystemAdministratorUser,
		isAuthenticated,
		accessDenied,
	} = useContext(AdminAuthContext);

	useEffect(() => {
		let links = [];
		if (isTimesheetUser) links = [...links, timesheetLink];
		if (isExpenseUser) links = [...links, expenseLink];
		if (isExpenseSystemAdministratorUser) links = [...links, creditCardLink];
		setNavigation(links);
	}, [isAuthenticated, accessDenied, isExpenseUser, isTimesheetUser]);

	return (
		<>
			<AppBar className={classes.root}>
				<Hidden mdUp>
					<MobileNavbar
						menuNavigation={isAuthenticated && !accessDenied ? navigation : []}
					/>
				</Hidden>
				<Hidden smDown>
					<DesktopNavigation
						menuNavigation={isAuthenticated && !accessDenied ? navigation : []}
					/>
				</Hidden>
			</AppBar>
			<div className={classes.filler} />
		</>
	);
};

export default withStyles(styles)(AdminNavbar);
