/**
 * @file The AppRouter Component is what drives the applicaiton content
 * based on the users route. The component is a wrapper around React Router.
 */
import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Error from '../../pages/Error/Error';
import clientRoutes from './ClientRouteConfig';
import PrivateRoute from './PrivateRoute';
import Authentication from '../Pages/Authentication';
import { ClientAuthContext } from '../Contexts/ClientAuthContext';

const ClientAppRouter = (props) => {
	const { isAuthenticated, rejectedAuthReason } = useContext(ClientAuthContext);
	return (
		<>
			<Switch>
				{clientRoutes.map((item) => (
					<PrivateRoute exact {...item} key={`${item.path}-route`} />
				))}
				<Route
					exact
					path='/auth'
					render={(props) =>
						isAuthenticated ? (
							<Redirect {...props} to='/timesheets' />
						) : (
							<Authentication {...props} rejectedAuthReason={rejectedAuthReason} />
						)
					}
				/>
				<Route
					exact
					path='/'
					render={(props) =>
						isAuthenticated ? (
							<Redirect {...props} to='/timesheets' />
						) : (
							<Redirect
								{...props}
								rejectedAuthReason={rejectedAuthReason}
								to='/auth'
							/>
						)
					}
				/>
				<Route exact path='/500' render={(props) => <Error errorCode='500' {...props} />} />
				<Route exact path='/403' render={(props) => <Error errorCode='403' {...props} />} />
				<Route exact path='/503' render={(props) => <Error errorCode='503' {...props} />} />
				<Route exact path='*' render={(props) => <Error errorCode='404' {...props} />} />
			</Switch>
		</>
	);
};
export default ClientAppRouter;
