import React, { Component } from 'react';
import {
	withStyles,
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Checkbox,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';

const styles = (theme) => ({
	contentRoot: {
		height: '100vh',
	},
	bold: {
		fontWeight: 'bold',
	},
	center: {
		textAlign: 'center',
	},
	dialogHeaderRoot: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE_LILAC,
		justifyContent: 'center',
		textAlign: 'center',
		paddingTop: '16px',
		paddingBottom: '16px',
	},
	dialogContentRoot: {
		padding: '15px',
		margin: '0',
		borderRadius: '5px',
		color: `${COLORS.RAVEN}`,
		backgroundColor: `${COLORS.BLACK_HAZE}`,
	},
	imageAndText: {
		textAlign: 'center',
		alignContent: 'middle',
		justifyContent: 'center',
	},
	buttonRoot: {
		border: `1px solid ${COLORS.TRUE_BLUE}`,
		marginLeft: '30px',
		marginRight: '30px',
		marginBottom: '20px',
		width: '100%',
		textTransform: 'none',
		fontSize: '16px',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginLeft: '10px',
			marginRight: '10px',
		},
	},

	timeOutHeader: {
		fontWeight: '500',
		opacity: '0.5',
	},
	closeIconButtonRoot: {
		color: `${COLORS.WHITE_LILAC}`,
		position: 'absolute',
		right: '0',
		top: '7px',
	},
	popUpMsg: {
		textAlign: 'center',
		//fontSize: '15px',
		//fontWeight: '600'
	},
	notificationImg: {
		width: '100%',
		heigh: 'auto',
		margin: '0 auto',
		[theme.breakpoints.down('xs')]: {
			fontSize: '18px',
		},
	},
	checkboxWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		textAlign: 'left',
	},
});

class PersonalInformationDisclaimerAlert extends Component {
	state = {
		alertChecked: false,
	};

	handleCheck = () => {
		this.setState({ alertChecked: !this.state.alertChecked });
	};

	updateHideDialogFlag = () => {
		//Post call to update sign in user flag
		const { alertChecked } = this.state;
		this.props.toggleHandler(false, alertChecked);
	};

	render() {
		const { classes, toggleHandler, openDialog } = this.props;
		const { alertChecked } = this.state;
		return (
			<div className={classes.contentRoot}>
				<Dialog
					open={openDialog}
					fullWidth
					scroll='body'
					maxWidth={'xs'}
					onClose={() => toggleHandler(false, alertChecked)}
				>
					<DialogContent classes={{ root: classes.dialogContentRoot }}>
						<div className={classes.popUpMsg}>
							<p>
								If any of the information found in the credentialing application is
								incorrect, please update or delete as necessary.
							</p>

							<br />

							<div className={classes.checkboxWrapper}>
								<Checkbox
									color='primary'
									name='alertCheckbox'
									checked={this.state.alertChecked}
									onChange={this.handleCheck}
								/>
								<span>Do not show this again</span>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							color='primary'
							classes={{ root: classes.buttonRoot }}
							onClick={this.updateHideDialogFlag}
						>
							CLOSE
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(PersonalInformationDisclaimerAlert));
