import React from 'react';
import { Paper, Button } from '@material-ui/core';
import { COLORS } from '../../../../../utils/Application_Constants';
import { Grid, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
	},
	root: {
		padding: '20px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		maxWidth: '100%',
		//backgroundColor: COLORS.BLACK_HAZE,
	},
	ContainerRoot: {
		[theme.breakpoints.up('md')]: {
			//    paddingLeft: 70,
			//    paddingRight: 70
		},
	},
	helpPanelContainerRoot: {
		padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	header: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '700',
		fontSize: '24px',
	},
	info: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '16px',
		textAlign: 'left',
	},
	topTileRoot: {
		// marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
}));

function NeedHelpCard({ t }) {
	const classes = styles();

	return (
		<div>
			<Paper className={classes.paper}>
				<Grid container>
					<Grid item xs={12}>
						<h4>{t(`credentials:CRED_OVERVIEW.NEED_HELP`)}</h4>

						<p>{t(`credentials:CRED_OVERVIEW.HELP_INFO`)}</p>

						<Button
							variant='contained'
							color='primary'
							disableElevation
							href={`mailto: ltonlineservices@locumtenens.com`}
						>
							{t(`credentials:CRED_OVERVIEW.CONTACT_US`)}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
}

export default NeedHelpCard;
