import React, { Component } from 'react';
import {
	withStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Hidden,
	Grid,
	FormControl,
	InputLabel,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Edit } from '@material-ui/icons';
import TextFieldComponent from '../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { COLORS } from '../../../utils/Application_Constants';
import FacilityContactsDeleteDialog from './FacilityContactsDeleteDialog';
import { createContact, deleteContact } from '../../../services/AssignmentsService';

const styles = (theme) => ({
	editIcon: {
		color: COLORS.TRUE_BLUE,
		cursor: 'pointer',
	},
	facilityWrapper: {
		paddingTop: '18px',
	},
	gridRoot: {
		justifyContent: 'space-evenly',
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
	textFieldContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('xs')]: {
			margin: '0 15px',
		},
	},
	textFieldRoot: {
		marginTop: '15px',
		width: '100%',
	},
	textFieldInput: {
		width: '100%',
	},
	widgetHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '18px',
		paddingLeft: '20px',
		textAlign: 'left',
	},
	paperRoot: {
		[theme.breakpoints.down('sm')]: {
			marginTop: '20px',
		},
		boxShadow: '0px 3px 6px #00000008',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	dialogRoot: {
		zIndex: '30000',
	},
	dialogHeaderRoot: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE_LILAC,
		justifyContent: 'left',
	},
	dialogContentRoot: {
		padding: '0',
		marginTop: '10px',
	},
	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '750',
		fontSize: '16px',
		whiteSpace: 'nowrap',
	},
	addButton: {
		lineHeight: 'inherit',
		backgroundColor: `${COLORS.TRUE_BLUE}`,
		color: `${COLORS.WHITE}`,
		marginBottom: '15px',
		fontWeight: '700',
		marginRight: '25px',
		width: '150px',
		textTransform: 'inherit',
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			margin: '0 15px 15px',
		},
	},
	deleteButton: {
		lineHeight: 'inherit',
		color: `${COLORS.MILANO_RED}`,
		backgroundColor: `${COLORS.WHITE}`,
		marginLeft: '25px',
		marginBottom: '15px',
		fontWeight: '700',
		width: '150px',
		textTransform: 'inherit',
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			margin: '0 15px',
		},
	},
	cancelButton: {
		lineHeight: 'inherit',
		color: `${COLORS.TRUE_BLUE}`,
		backgroundColor: `${COLORS.WHITE}`,
		border: `1px solid ${COLORS.TRUE_BLUE}`,
		marginBottom: '15px',
		marginRight: '15px',
		fontWeight: '700',
		width: '150px',
		textTransform: 'inherit',
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			margin: '15px',
		},
	},
	openButton: {
		fontWeight: '700',
		lineHeight: 'inherit',
		color: `${COLORS.TRUE_BLUE}`,
		backgroundColor: `${COLORS.WHITE}`,
		width: '200px',
		margin: '20px',
		textTransform: 'inherit',
	},
	mobileButtonWrapper: {
		marginTop: '20px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	desktopButtonWrapper: {
		marginTop: '30px',
		justifyContent: 'left',
	},
	actionButtonWrapper: {
		width: '100%',
		textAlign: 'right', // flexDirection: 'row-reverse',
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
		},
	},
});

class FacilityContactsAddDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			contact: {},
		};
	}

	componentDidMount() {
		const { contact, edit } = this.props;
		if (edit) {
			this.setState({ contact: contact });
		}
	}

	openDialog = () => {
		this.setState({ open: true });
	};

	closeDialog = () => {
		this.setState({ open: false });
	};

	addContact = async () => {
		const { facilityId, updateAssignments } = this.props;
		const { contact } = this.state;
		if (await createContact(facilityId, contact)) {
			if (updateAssignments) updateAssignments();
		}
		this.closeDialog();
	};

	editContact = async () => {
		const { facilityId, updateAssignments } = this.props;
		const { contact } = this.state;
		await deleteContact(contact.id);
		if (await createContact(facilityId, contact)) {
			if (updateAssignments) updateAssignments();
		}
		this.closeDialog();
	};

	deleteContact = async () => {
		const { updateAssignments } = this.props;
		const { contact } = this.state;
		if (await deleteContact(contact.id)) {
			if (updateAssignments) updateAssignments();
		}
		this.closeDialog();
	};

	textFieldChangeHandler = (value, field) => {
		const state = { ...this.state };
		state.contact[field] = value;
		this.setState(state);
	};

	renderTitleField(title) {
		const { classes, t } = this.props;
		return (
			<FormControl classes={{ root: classes.textFieldRoot }}>
				<InputLabel
					shrink
					htmlFor='Title'
					classes={{ root: classes.inputLabel }}
					error={title === ''}
				>
					{t('contacts:NEW.TITLE')}
				</InputLabel>
				<TextFieldComponent
					id='Title'
					onChange={(event) => this.textFieldChangeHandler(event.target.value, 'title')}
					name='title'
					classes={{ input: classes.textFieldInput }}
					defaultValue={title}
				/>
			</FormControl>
		);
	}

	renderFirstNameField(firstName) {
		const { classes, t } = this.props;
		return (
			<FormControl classes={{ root: classes.textFieldRoot }}>
				<InputLabel
					shrink
					htmlFor='FirstName'
					classes={{ root: classes.inputLabel }}
					error={firstName === ''}
				>
					{t('profile:ACCOUNT.FIRST_NAME')}
				</InputLabel>
				<TextFieldComponent
					id='FirstName'
					onChange={(event) =>
						this.textFieldChangeHandler(event.target.value, 'firstName')
					}
					name='firstName'
					classes={{ input: classes.textFieldInput }}
					defaultValue={firstName}
				/>
			</FormControl>
		);
	}

	renderLastNameField(lastName) {
		const { classes, t } = this.props;
		return (
			<FormControl classes={{ root: classes.textFieldRoot }}>
				<InputLabel
					shrink
					htmlFor='LastName'
					classes={{ root: classes.inputLabel }}
					error={lastName === ''}
				>
					{t('profile:ACCOUNT.LAST_NAME')}
				</InputLabel>
				<TextFieldComponent
					id='LastName'
					classes={{ input: classes.textFieldInput }}
					onChange={(event) =>
						this.textFieldChangeHandler(event.target.value, 'lastName')
					}
					name='lastName'
					defaultValue={lastName}
				/>
			</FormControl>
		);
	}

	renderEmailField(emailAddress) {
		const { classes, t } = this.props;
		return (
			<FormControl classes={{ root: classes.textFieldRoot }}>
				<InputLabel
					shrink
					htmlFor='Email'
					classes={{ root: classes.inputLabel }}
					error={emailAddress === ''}
				>
					{t('profile:ACCOUNT.EMAIL')}
				</InputLabel>
				<TextFieldComponent
					id='Email'
					classes={{ input: classes.textFieldInput }}
					onChange={(event) =>
						this.textFieldChangeHandler(event.target.value, 'emailAddress')
					}
					name='emailAddress'
					defaultValue={emailAddress}
				/>
			</FormControl>
		);
	}

	renderPhoneField(phoneNumber) {
		const { classes, t } = this.props;
		return (
			<FormControl classes={{ root: classes.textFieldRoot }}>
				<InputLabel
					shrink
					htmlFor='Phone'
					classes={{ root: classes.inputLabel }}
					error={phoneNumber === ''}
				>
					{t('contacts:NEW.PHONE')}
				</InputLabel>
				<TextFieldComponent
					id='Phone'
					classes={{ input: classes.textFieldInput }}
					onChange={(event) =>
						this.textFieldChangeHandler(event.target.value, 'phoneNumber')
					}
					name='phoneNumber'
					defaultValue={phoneNumber}
				/>
			</FormControl>
		);
	}

	renderForm() {
		const { classes } = this.props;
		const { contact } = this.state;
		const { title, firstName, lastName, emailAddress, phoneNumber } = contact;

		return (
			<>
				<Grid item xs={12} classes={{ root: classes.gridRoot }}>
					<Grid item xs={12} sm={5} classes={{ root: classes.textFieldContainer }}>
						{this.renderTitleField(title)}
					</Grid>
					<Hidden only={['xs']}>
						<Grid item xs={12} sm={5} classes={{ root: classes.textFieldContainer }} />
					</Hidden>
				</Grid>
				<Grid item xs={12} classes={{ root: classes.gridRoot }}>
					<Grid item xs={12} sm={5} classes={{ root: classes.textFieldContainer }}>
						{this.renderFirstNameField(firstName)}
					</Grid>
					<Grid item xs={12} sm={5} classes={{ root: classes.textFieldContainer }}>
						{this.renderLastNameField(lastName)}
					</Grid>
				</Grid>
				<Grid item xs={12} classes={{ root: classes.gridRoot }}>
					<Grid item xs={12} sm={5} classes={{ root: classes.textFieldContainer }}>
						{this.renderEmailField(emailAddress)}
					</Grid>
					<Grid item xs={12} sm={5} classes={{ root: classes.textFieldContainer }}>
						{this.renderPhoneField(phoneNumber)}
					</Grid>
				</Grid>
			</>
		);
	}

	renderDeleteButton() {
		const { edit } = this.props;
		if (edit) {
			return <FacilityContactsDeleteDialog deleteContact={this.deleteContact} />;
		}
		return null;
	}

	renderButtons() {
		const { classes, edit, t } = this.props;
		return (
			<>
				{this.renderDeleteButton()}
				<div className={classes.actionButtonWrapper}>
					<Button classes={{ root: classes.cancelButton }} onClick={this.closeDialog}>
						{t('common:BUTTONS.CANCEL')}
					</Button>
					<Button
						classes={{ root: classes.addButton }}
						onClick={edit ? this.editContact : this.addContact}
					>
						{edit ? t('common:BUTTONS.UPDATE') : t('common:BUTTONS.ADD')}
					</Button>
				</div>
			</>
		);
	}

	renderActionButtons() {
		const { classes } = this.props;
		return (
			<>
				<Hidden only={['xs']}>
					<DialogActions classes={{ root: classes.desktopButtonWrapper }}>
						{this.renderButtons()}
					</DialogActions>
				</Hidden>
				<Hidden only={['sm', 'md', 'lg', 'xl']}>
					<div className={classes.mobileButtonWrapper}>{this.renderButtons()}</div>
				</Hidden>
			</>
		);
	}

	renderOpenMechanism() {
		const { classes, edit, t } = this.props;
		if (edit) {
			return (
				<div className={classes.editWrapper}>
					<Edit className={classes.editIcon} onClick={this.openDialog} />
				</div>
			);
		}
		return (
			<Button classes={{ root: classes.openButton }} onClick={this.openDialog}>
				{t('contacts:BUTTONS.ADD_CONTACT')}
			</Button>
		);
	}

	render() {
		const { classes, edit, t } = this.props;
		const state = { ...this.state };
		return (
			<>
				{this.renderOpenMechanism()}
				<Dialog
					open={state.open}
					fullWidth
					scroll='body'
					classes={{ root: classes.dialogRoot }}
				>
					<DialogTitle classes={{ root: classes.dialogHeaderRoot }}>
						{edit ? t('contacts:EDIT.EDIT_CONTACT') : t('contacts:NEW.NEW_CONTACT')}
					</DialogTitle>
					<DialogContent classes={{ root: classes.dialogContentRoot }} />
					{this.renderForm()}
					{this.renderActionButtons()}
				</Dialog>
			</>
		);
	}
}

export default withTranslation()(withStyles(styles)(FacilityContactsAddDialog));
