import React from 'react';
import WithContexts from '../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import ReferencesForm from './ReferencesForm';
import { reactPlugin } from '../../../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBoundaryPage from '../../../ContentComponents/Credentials/PageComponenets/ErrorBoundaryPage';
function References({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	updateFlags,
	activePath,
	setCredAppError,
	credState,
	credType,
	type = 'References',
	constantType = 'REFERENCES',
	apiType = 'references',
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	return (
		<AppInsightsErrorBoundary
			onError={() => (
				<ErrorBoundaryPage errorCode='500' flags={flags} updateFlags={updateFlags} />
			)}
			appInsights={reactPlugin}
		>
			<ReferencesForm
				t={t}
				setSaveHandler={setSaveHandler}
				blockedBackNavigation={blockedBackNavigation}
				UserContext={UserContext}
				flags={flags}
				updateFlags={updateFlags}
				type={type}
				constantType={constantType}
				apiType={apiType}
				activePath={activePath}
				credState={credState}
				credType={credType}
				sectionCompletion={sectionCompletion}
				updateSectionCompletion={updateSectionCompletion}
				updateHighlightFieldsState={updateHighlightFieldsState}
				shouldHighlightFields={shouldHighlightFields}
				setCredAppError={setCredAppError}
			/>
		</AppInsightsErrorBoundary>
	);
}

export default WithContexts(withTranslation()(References));
