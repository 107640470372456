const TimesheetStatusEnum = {
	NotStarted: 'Not Started',
	PendingSubmission: 'Pending Submission',
	Paid: 'Paid',
	Approved: 'Approved',
	Submitted: 'Submitted',
	Rejected: 'Rejected',
	DidNotWork: 'Did Not Work',
	TimeNotEntered: 'Time Not Entered',
	PeopleNetTimesheet: 'View in PeopleNet'
};

const TimesheetStatusEnumApi = {
	NotStarted: 'NotStarted',
	PendingSubmission: 'PendingSubmission',
	Paid: 'Paid',
	Approved: 'Approved',
	Submitted: 'Submitted',
	Rejected: 'Rejected',
	DidNotWork: 'DidNotWork',
	TimeNotEntered: 'TimeNotEntered',
	PeopleNetTimesheet: 'PeopleNetTimesheet'
};

export { TimesheetStatusEnumApi, TimesheetStatusEnum };
export default TimesheetStatusEnum;
