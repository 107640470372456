import React, { Component } from 'react';
import { withStyles, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import ContactCard from '../ContactCard/ContactCard';

const styles = (theme) => ({
	facilityWrapper: {
		paddingTop: '18px',
	},
	widgetHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '18px',
		paddingLeft: '20px',
		textAlign: 'left',
	},
	paperRootNotFirstMargin: {
		marginTop: '20px',
	},
	// paperRoot: {
	//   boxShadow: '0px 3px 6px #00000008',
	// },
});
class LocumTenensContacts extends Component {
	/**
	 * Some assignments have LT as the first widget,
	 * when the LT contacts are first, we pass the isFirst
	 * prop to make margin match the content cards.
	 * @return {String}
	 */
	isComponentFirstWidget = () => {
		const { classes, isFirst } = this.props;
		if (isFirst) {
			return '';
		}
		return classes.paperRootNotFirstMargin;
	};

	render() {
		const { classes, contacts, t } = this.props;
		return (
			<Paper classes={{ root: `${this.isComponentFirstWidget()} ${classes.paperRoot}` }}>
				<div className={classes.facilityWrapper}>
					<h1 className={classes.widgetHeader}>{t('contacts:LT_CONTACTS')}</h1>
					{contacts &&
						contacts.map((item, index) => (
							<ContactCard key={`contactCard-${index + 1}`} contact={item} />
						))}
				</div>
			</Paper>
		);
	}
}

export default withTranslation()(withStyles(styles)(LocumTenensContacts));
