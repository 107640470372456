import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, {useEffect} from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';

const useStyles = makeStyles((theme) => ({
	formHeader: {
		borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
		paddingBottom: 8,
		marginBottom: 16,
	},
}));

const ReasonForSplittingForm = (props) => {
	const { index, expense } = props;
	const { control, errors,setValue,getValues,watch } = useFormContext();
	const expenseEntries = getValues('expenseEntries');
	const isReadOnly = expense.status === "Posted" ? true : false;
	const watchSplitTransactionComment = watch(`expenseEntries[${index}].splitTransactionComment`);
	const classes = useStyles();
	useEffect(() => {
		for (let indexLoop = 0; indexLoop < expenseEntries.length; ++indexLoop) {
			if (indexLoop != index){
				var aStatus = getValues(`expenseEntries[${indexLoop}].status`);
				if (aStatus != "Posted"){
					setValue(`expenseEntries[${indexLoop}].splitTransactionComment`, watchSplitTransactionComment, {
						shouldValidate: true,
					});
				}
			}
		}
	}, [watchSplitTransactionComment, setValue])

	return (
		<div>
			{expenseEntries.length > 1 && (
				<div>
					<Typography variant='h5' component='h5' className={classes.formHeader}>
						Transaction Reason
					</Typography>
					<div>
						<Grid item xs={12}>
						<Controller
							control={control}
							name={`expenseEntries[${index}].splitTransactionComment`}
							defaultValue={expense.splitTransactionComment || ''}
							rules={{
								required: 'Split transaction reason is required',
							}}
							render={({ onChange, value }) => (
									<ExpenseTextField
										multiline
										inputProps={{ maxLength: 100 }}
										minRows={3}
										id='split-transaction-details'
										onChange={onChange}
										value={value}
										label='Reason for splitting transaction'
										placeholder='Enter your split transaction details here'
										required
										disabled={isReadOnly}
										error={Boolean(errors.expenseEntries?.[index]?.splitTransactionComment)}
										helperText={
											errors.expenseEntries?.[index]?.splitTransactionComment?.message
										}
									/>
								)}
							/>
						</Grid>
					</div>
				</div>
			)}
		</div>
	);
};

export default ReasonForSplittingForm;
