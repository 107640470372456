import { createTheme } from '@material-ui/core/styles';
import { COLORS } from '../../../utils/Application_Constants';

const datePickerTheme = createTheme({
	overrides: {
		MuiInputLabel: {
			root: {
				color: 'inherit',
				fontSize: 'inherit',
				fontWeight: '700',
				marginLeft: '0px',
				textTransform: 'capitalize',
				fontFamily: 'museo-sans',
			},
			formControl: {
				transform: 'none',
				position: 'inherit',
			},
		},
		MuiInput: {
			borderRadius: '0px',
			underline: {
				'&:before': {
					content: 'none',
				},
				'&:after': {
					boxShadow: COLORS.WHITE_LILAC,
					//borderColor: COLORS.RAVEN,
				},
			},
		},
		MuiTypography: {
			subtitle1: {
				'text-decoration': 'underline',
				'font-weight': 'bold',
			},
			colorPrimary: {
				color: COLORS.LT_JOURNEY,
			},
		},
		MuiPickersToolbar: {
			toolbar: {
				'background-color': COLORS.LT_JOURNEY,
			},
		},
		MuiPickersDay: {
			daySelected: {
				'background-color': COLORS.LT_JOURNEY,
			},
		},
		MuiOutlinedInput: {
			root: {
				fontFamily: 'museo-sans',
				color: `${COLORS.LT_MIDNIGHT}!important`,
				borderRadius: '0px',
				transition: 'all 0.3s',
				'& $notchedOutline': {
					color: COLORS.LT_MIDNIGHT,
					border: '1px solid #e7e7e7!important',
					transition: 'all 0.3s',
				},
				'&:hover $notchedOutline': {
					border: '1px solid #0077C8!important',
				},
				'&$focused $notchedOutline': {
					border: '1px solid #0077C8!important',
				},
				'&&& $input': {
					padding: '12px',
				},
			},
		},
	},
});
export default datePickerTheme;
