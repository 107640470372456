import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { COLORS } from '../../../../../utils/Application_Constants';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withTranslation } from 'react-i18next';
import LaunchIcon from '@material-ui/icons/Launch';

const styles = (theme) => ({
	navLinkStyle: {
		textDecoration: 'none',
		//  width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	navLinkSubNavStyle: {
		textDecoration: 'none',
		// width: '100%',
		//paddingLeft: '15px'
		color: COLORS.DENIM,
	},
	headerNavigationBorder: {
		'&:not(:last-child)': {
			borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		},
		'&:last-child': {
			borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		},
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginBottom: '10px',
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
			textAlign: 'left',
		},
	},
	sectionStyle: {
		color: COLORS.DENIM,
		fontWeight: '700',
		display: 'flex',
	},
	sectionSubStyle: {
		marginLeft: '20px',
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '10px',
		},
	},
	chipRootComplete: {
		border: `none`,
		color: COLORS.KELLY_GREEN,
		background: 'none',
		marginLeft: '15px',
		//marginTop: '15px',
		borderRadius: '5px',
		width: '115px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '5px',
			marginBottom: '10px',
			width: '115px',
		},
	},
	chipRootInComplete: {
		border: `none`,
		color: COLORS.MILANO_RED,
		background: 'none',
		marginLeft: '15px',
		//marginTop: '15px',
		borderRadius: '5px',
		width: '115px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '5px',
			marginBottom: '10px',
			width: '115px',
		},
	},
	chipRootNotProvided: {
		border: `none`,
		color: '#000000DE',
		background: 'none',
		marginLeft: '15px',
		//marginTop: '15px',
		borderRadius: '5px',
		width: '115px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '5px',
			marginBottom: '10px',
			width: '115px',
		},
	},
	chipLabel: {
		fontWeight: '300',
		fontSize: '12px',
		padding: '0px 20px',
	},
	containerStyle: {
		paddingTop: '5px',
	},
	checkStatusIcon: {
		fontSize: '.8rem',
		marginLeft: '4px',
		marginTop: '3px;',
	},
	statusNotProvided: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '18px',
		},
	},
	statusInComplete: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginBottom: '10px',
			color: '#EA3219',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '4px',
			color: '#EA3219',
		},
	},
});

class SectionAndStatusComponent extends Component {
	componentDidMount = () => {};

	/**
	 * Method styles the border for the mobile drawer navigation depending
	 * on where the components are being rendered.
	 * @param {Boolean} isLast - Checks to see if it is the last item in the map being rendered.
	 * @param {Boolean} isExpanded - Checks to see if the current component is expanded.
	 * @returns {String}
	 */
	styleHeaderNavigationBorder = (isLast) => {
		const { isFooter, classes } = this.props;
		if (isFooter || this.isLastSubnavItemExpandedInMobileHeader(isLast)) {
			return '';
		}
		return classes.headerNavigationBorder;
	};

	/**
	 * Method checks to see if the current component being rendered is last and expanded.
	 * @param {Boolean} isLast
	 * @param {Boolean} isExpanded
	 * @returns {Boolean}
	 */
	isLastSubnavItemExpandedInMobileHeader = (isLast) => {
		if (isLast) {
			return true;
		}
		return false;
	};

	/**
	 * Renders navigation itmem with collapsable children.
	 * @param {any} navigationItem
	 * @returns {JSX}
	 */
	renderSectionItemWithSubNav = (navigationItem, isLast) => {
		const { name, id } = navigationItem;
		const { classes, t } = this.props;
		const { updateHighlightFieldsState } = this.props;
		return (
			<div key={id.toString()} className={this.styleHeaderNavigationBorder(isLast)}>
				<CssBaseline />

				<Grid container className={classes.containerStyle}>
					<Grid classes={{ root: classes.gridRoot }} item xs={6} md={6}>
						<span className={classes.sectionStyle}>{name}</span>
					</Grid>
					<Grid classes={{ root: classes.gridRoot }} item xs={4} md={4}>
						{/* <NavLink to={{ pathname: parentUrlLink }} className={classes.navLinkStyle}>
                            <Chip classes={{ root: classes.chipRootComplete, label: classes.chipLabel }} label={t(`credentials:STATUSANDSIGN.COMPLETE`)} />
                        </NavLink> */}
					</Grid>
				</Grid>

				{navigationItem.children.map((item, index) => {
					return (
						<Grid container>
							<Grid classes={{ root: classes.gridRoot }} item xs={6} md={6}>
								<NavLink
									key={`navigationChildren-${index + 1}`}
									to={item.link}
									className={classes.navLinkSubNavStyle}
									onClick={() => {
										updateHighlightFieldsState(true, item);
									}}
								>
									<span className={classes.sectionSubStyle}>
										{item.name}
										<LaunchIcon className={classes.checkStatusIcon} />
									</span>
								</NavLink>
							</Grid>
							<Grid classes={{ root: classes.gridRoot }} item xs={4} md={4}>
								{this.props.credentialingAppObject[item.keyName] === 'complete' ? (
									<div align='center' className='credStatus'>
										<span className='statusComplete'>
											{t(`credentials:STATUSANDSIGN.COMPLETE`)}
										</span>
									</div>
								) : this.props.credentialingAppObject[item.keyName] ===
								  'notProvided' ? (
									<div align='center' className='credStatus'>
										<span className={classes.statusNotProvided}>
											{t(`credentials:STATUSANDSIGN.NOT_PROVIDED`)}
										</span>
									</div>
								) : (
									<div align='center' className='credStatus'>
										<span className={classes.statusInComplete}>
											{t(`credentials:STATUSANDSIGN.INCOMPLETE`)}
										</span>
									</div>
								)}
							</Grid>
						</Grid>
					);
				})}
			</div>
		);
	};

	/**
	 * Method renders an individual navigation item.
	 * @param {any} navigationItem
	 * @returns {JSX}
	 */
	renderSectionItem = (navigationItem) => {
		const { name, id, parentUrlLink, keyName } = navigationItem;
		const { classes, t, updateHighlightFieldsState } = this.props;

		if (name !== 'Check status and sign' && name !== 'Overview') {
			return (
				<div key={id.toString()} className={this.styleHeaderNavigationBorder()}>
					<CssBaseline />
					<Grid container className={classes.containerStyle}>
						<Grid classes={{ root: classes.gridRoot }} item xs={6} md={6}>
							<NavLink
								onClick={() => {
									updateHighlightFieldsState(true, navigationItem);
								}}
								to={{ pathname: parentUrlLink }}
								className={classes.navLinkStyle}
							>
								<span className={classes.sectionStyle}>
									{name}
									<LaunchIcon className={classes.checkStatusIcon} />
								</span>
							</NavLink>
						</Grid>
						<Grid classes={{ root: classes.gridRoot }} item xs={4} md={4}>
							{this.props.credentialingAppObject[keyName] === 'complete' ? (
								<div align='center' className='credStatus'>
									<span className='statusComplete'>
										{t(`credentials:STATUSANDSIGN.COMPLETE`)}
									</span>
								</div>
							) : this.props.credentialingAppObject[keyName] === 'notProvided' ? (
								<div align='center' className='credStatus'>
									<span className='statusNA'>
										{t(`credentials:STATUSANDSIGN.NOT_PROVIDED`)}
									</span>
								</div>
							) : (
								<div align='center' className='credStatus'>
									<span className='statusIncomplete'>
										{t(`credentials:STATUSANDSIGN.INCOMPLETE`)}
									</span>
								</div>
							)}
						</Grid>
					</Grid>
				</div>
			);
		}
	};
	renderSections = () => {
		const { menuNavigation } = this.props;
		return menuNavigation.map((item, index) => {
			if (item.children.length > 0) {
				const isLast = item.children.length === index;
				return this.renderSectionItemWithSubNav(item, isLast);
			}
			return this.renderSectionItem(item);
		});
	};
	render() {
		return this.renderSections();
	}
}

export default withTranslation()(withStyles(styles)(SectionAndStatusComponent));
