import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress, NativeSelect, ThemeProvider, withStyles } from '@material-ui/core';
import TextFieldComponent from '../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { COLORS, gapsReason, CRED_STATE } from '../../../../../utils/Application_Constants';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import DatePickerComponent from '../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import createTheme from '@material-ui/core/styles/createTheme';
import { getGapsByType, postGapsByType } from '../../../../../services/Credentialing';
import moment from 'moment';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { convertToDateIgnoringTime } from '../../../../../utils/helpers';
import Alert from '@material-ui/lab/Alert';
import { Event } from '@material-ui/icons';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	gapsInTrainingSectionPointsCheck,
	gapsInWorkHistorySectionPointsCheck,
	gapsInTrainingSectionCheck,
	gapsInWorkHistorySectionCheck,
} from '../../../../../utils/CredentialingAppUtility';

const styles = (theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	inputRadio: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
	},

	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	completedIcon: {
		color: 'green',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		'& input': {
			height: '32px',
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.RAVEN,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},

	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: `${COLORS.RAVEN} `,
			borderWidth: 1,
			borderRadius: 1,
		},

		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '18.5px 14px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
	},

	cssFocused: {},
	disabledSelect: {
		opacity: 0.6,
		cursor: 'default',
	},
	focused: {},
	error: {
		borderColor: '#6D707E', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		[theme.breakpoints.up('md')]: {
			marginTop: '25vh',
			marginLeft: '30vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '20vh',
			marginLeft: '25vh',
		},
	},
	successGrid: {
		marginTop: '25px',
	},
	iconButtonMargin: {
		marginRight: '10px',
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
	},
	secondaryHeaderArea: {
		alignItems: 'center',
		maxWidth: '100%',
		marginTop: '5px',
		marginBottom: '10px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	marginLeft12: {
		marginLeft: 12,
	},
	gapTitle: {
		color: COLORS.ST_TROPAZ,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 0 0px 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		fontSize: '17px',
		fontWeight: '700',
	},
	gapMessage: {
		color: COLORS.RAVEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '14px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '32px',
		paddingBottom: '15px',
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '32px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
	noGapAlertMessage: {
		border: `1px solid #009639`,
		color: COLORS.RAVEN,
		backgroundColor: '#f0f8ff',
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
	disabledInput: {
		opacity: 0.6,
	},
});

const GapsForm = ({
	classes,
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	updateFlags,
	activePath,
	setCredAppError,
	credState,
	type,
	constantType,
	sectionCompletion,
	updateSectionCompletion,
	shouldHighlightFields,
	updateHighlightFieldsState,
}) => {
	const normalTheme = createTheme({
		overrides: {
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '18px',
					borderRadius: '1px !important',
				},
			},
		},
	});

	const errorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '17px',
					borderRadius: '1px !important',
					border: '2px solid red !important',
				},
			},
		},
	});

	const [formTemplate] = useState({
		id: null,
		reason: '',
		explanation: '',
		startDate: null,
		endDate: null,
	});

	const { register, control, handleSubmit, reset, formState, watch, errors } = useForm({
		mode: 'onBlur',
		defaultValues: {
			id: null,
			reason: '',
			explanation: '',
			startDate: null,
			endDate: null,
		},
	});

	const [isLoaded, setIsLoaded] = useState(false);
	const [gapCount, setGapCount] = useState(0);
	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);

	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;
			setSaveHandler(
				GapsForm,
				type,
				handleSubmit(async (data) => await onSubmit(data))
			);

			await getGapsByType(externalId, type)
				.then(function (data) {
					const { gaps = [] } = data;
					setGapCount(gaps.length);
					// if (gapsInWorkHistory.length === 0) {
					//     append({
					//         reason: '',
					//         explanation: '',
					//         startDate: null,
					//         endDate: null
					//     })
					// }
					if (gaps.length !== 0) {
						data['gaps'].map((item) => {
							if (item.reason === null) {
								item.reason = '';
							}
							if (item.explanation === null) {
								item.explanation = '';
							}
							return item;
						});
					}
					updateSectionCompletion(sectionCompletion);
					flags.dataSaved = false;
					updateFlags(flags);
					reset(data);
					setIsLoaded(true);

					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status;
						if (type === 'traininggaps') {
							status = gapsInTrainingSectionCheck(data['gaps'], activePath);
						} else if (type === 'workgaps') {
							status = gapsInWorkHistorySectionCheck(data['gaps'], activePath);
						}
						if (!!status.missingFields) {
							setHighlights(status.missingFields);
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);

	const { fields } = useFieldArray({
		control,
		name: 'gaps',
	});

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('gaps')) {
			for (let i = 0; i < errors.gaps.length; i++) {
				if (!!errors?.gaps[i]) {
					//This would be 'return anError.type !== 'manual'', but manually set errors return a malformed error object with no 'type' field (the contents of the type field are inserted as an array of chars on the main object)
					missingRequiredToSaveFields = Object.values(errors.gaps[i]).filter(
						(anError) => {
							return anError.hasOwnProperty('type');
						}
					);
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	function updateHighlight() {
		if (localShouldHighlightFields) {
			let status = {
				status: 'notProvided',
				missingFields: [],
			};
			if (type === 'traininggaps') {
				status = gapsInTrainingSectionCheck(watch('gaps'), activePath);
			} else if (type === 'workgaps') {
				status = gapsInWorkHistorySectionCheck(watch('gaps'), activePath);
			}
			//alert(`missingFields: ${status.missingFields}`)
			setHighlights(status.missingFields);
		}
	}

	async function onSubmit(data) {
		let success = false;

		const { gaps } = data;
		if (gaps === undefined) {
			flags.dataSaved = true;
			flags.isDirty = false;
			success = true;
			setIsLoaded(true);
		} else {
			let indexes = _.keys(_.pickBy(gaps, formTemplate));
			for (let i = 0; i < indexes.length; i++) {
				gaps.splice(indexes[i], 1);
			}

			const { externalId } = UserContext;
			data['clinicianId'] = externalId;
			data['gaps'].map((item) => {
				if (item.id === 'null') {
					item.id = null;
				}
				if (item.reason !== 'Other') {
					item.explanation = null;
				}
				item.startDate = moment(item.startDate).toISOString(true);
				item.endDate = moment(item.endDate).toISOString(true);
				return item;
			});
			const jsonData = JSON.stringify(data);
			console.log('jsonData' + jsonData);
			await postGapsByType(externalId, jsonData, type);
			reset(data);
			flags.dataSaved = true;
			flags.isDirty = false;
			success = true;

			if (type === 'workgaps') {
				sectionCompletion.workHistory.gapsInWorkHistory.current =
					await gapsInWorkHistorySectionPointsCheck(
						data['gaps'],
						activePath,
						sectionCompletion
					);
				updateSectionCompletion(sectionCompletion);
			} else if (type === 'traininggaps') {
				sectionCompletion.training.gapsInTraining.current =
					await gapsInTrainingSectionPointsCheck(
						data['gaps'],
						activePath,
						sectionCompletion
					);
				updateSectionCompletion(sectionCompletion);
			}

			setIsLoaded(true);
		}

		updateFlags(flags);
		return success;
	}

	const showExplanation = (item, index) => {
		const lockFields =
			credState === CRED_STATE.PARTIALLY_OPEN || credState === CRED_STATE.CLOSED;
		return (
			<Grid container>
				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor={`gaps[${index}].explanation`}
							classes={{ root: classes.inputLabel }}
							error={
								(!!errors?.gaps && errors?.gaps[`${index}`]?.explanation) ||
								(!!highlights && highlights.includes(`gaps[${index}].explanation`))
							}
							required
						>
							{t(`credentials:${constantType}.EXPLANATION`)}
						</InputLabel>
						<TextField
							id={`gaps[${index}].explanation`}
							name={`gaps[${index}].explanation`}
							className={lockFields ? classes.disabledInput : classes.textField}
							error={
								(!!errors?.gaps && errors?.gaps[`${index}`]?.explanation) ||
								(!!highlights && highlights.includes(`gaps[${index}].explanation`))
							}
							margin='normal'
							variant='outlined'
							multiline
							rows={4}
							inputRef={register({
								maxLength: {
									value: 10000,
									message: t('common:MESSAGES.CHARACTER_LIMIT'),
								},
							})}
							helperText={
								(errors?.gaps &&
									errors?.gaps[`${index}`]?.explanation &&
									errors?.gaps[`${index}`]?.explanation['type'] ===
										'maxLength') ||
								(!!highlights && highlights.includes(`gaps[${index}].explanation`))
									? t('common:MESSAGES.CHARACTER_LIMIT')
									: null
							}
							defaultValue={item.explanation !== null ? `${item.explanation}` : ''}
							autoComplete='off'
							onBlur={() => updateHighlight()}
							onChange={() => updateHighlight()}
							InputProps={{
								readOnly: lockFields,
								classes: {
									root: classes.cssOutlinedInput,
									focused: classes.cssFocused,
									notchedOutline: classes.notchedOutline,
									error: classes.error,
								},
							}}
						/>
					</FormControl>
				</Grid>
			</Grid>
		);
	};
	const renderForm = (item, index) => {
		const lockFields =
			credState === CRED_STATE.PARTIALLY_OPEN || credState === CRED_STATE.CLOSED;
		return (
			<>
				<CssBaseline />
				<form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
					<RouteDeparturePrompt
						blockedBackNavigation={blockedBackNavigation}
						when={
							!flags.dataSaved ? !!Object.keys(formState.dirtyFields).length : false
						}
					/>

					<Input
						autoComplete='off'
						variant='outlined'
						style={{ display: 'none' }}
						name={`gaps[${index}].id`}
						fullWidth
						value={`${item.id}` || null}
						defaultValue={`${item.id}`}
						inputRef={register()}
					/>

					<Grid item xs={12} md={10}>
						<h3 className={classes.gapTitle}>
							{t(`credentials:${constantType}.GAP_LABEL`)} {index + 1}
						</h3>
					</Grid>
					<Grid item xs={10} md={10}>
						<span className={`${classes.gapMessage}`}>
							{t(`credentials:${constantType}.GAP_MESSAGE_1`)}
						</span>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor={`gaps[${index}].startDate`}
									classes={{ root: classes.inputLabel }}
									error={
										(!!errors?.gaps && errors?.gaps[`${index}`]?.startDate) ||
										(!!highlights &&
											highlights.includes(`gaps[${index}].startDate`))
									}
									required
								>
									{t(`credentials:${constantType}.DATE_FROM`)}
								</InputLabel>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Controller
										control={control}
										rules={{
											validate: (date) => dateValidation(date),
										}}
										defaultValue={
											`${item.startDate}` === 'null'
												? null
												: convertToDateIgnoringTime(`${item.startDate}`)
										}
										name={`gaps[${index}].startDate`}
										render={({ onChange, name, onBlur, value }) => (
											<DatePickerComponent
												onChange={onChange}
												onClose={() => {
													updateHighlight();
												}}
												name={name}
												onBlur={() => {
													onBlur();
													updateHighlight();
												}}
												format='MM/yy'
												value={value}
												placeholder='MM/YY'
												clearable
												disabled
												variant='inline'
												autoOk='true'
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<Event />
														</InputAdornment>
													),
													classes: { disabled: classes.disabledInput },
												}}
												minDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t(
															'credentials:VALIDATION_MESSAGE.MONTH_AND_YEAR'
														)}
													</span>
												}
												maxDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t(
															'credentials:VALIDATION_MESSAGE.MONTH_AND_YEAR'
														)}
													</span>
												}
												invalidDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t(
															'credentials:VALIDATION_MESSAGE.MONTH_AND_YEAR'
														)}
													</span>
												}
												margin='normal'
												theme={datePickerTheme}
												error={
													(errors?.gaps &&
														errors?.gaps[`${index}`]?.startDate) ||
													(!!highlights &&
														highlights.includes(
															`gaps[${index}].startDate`
														))
												}
												KeyboardButtonProps={{
													'aria-label': t(
														`credentials:${constantType}.DATE_FROM`
													),
												}}
											/>
										)}
									/>
								</MuiPickersUtilsProvider>
							</FormControl>
						</Grid>

						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor={`gaps[${index}].endDate`}
									classes={{ root: classes.inputLabel }}
									error={
										(!!errors?.gaps && errors?.gaps[`${index}`]?.endDate) ||
										(!!highlights &&
											highlights.includes(`gaps[${index}].endDate`))
									}
									required
								>
									{t(`credentials:${constantType}.DATE_TO`)}
								</InputLabel>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Controller
										control={control}
										rules={{
											validate: (date) => dateValidation(date),
										}}
										defaultValue={
											`${item.endDate}` === 'null'
												? null
												: convertToDateIgnoringTime(`${item.endDate}`)
										}
										name={`gaps[${index}].endDate`}
										render={({ onChange, name, onBlur, value }) => (
											<DatePickerComponent
												onChange={onChange}
												onClose={() => {
													updateHighlight();
												}}
												name={name}
												onBlur={() => {
													onBlur();
													updateHighlight();
												}}
												format='MM/yy'
												value={value}
												placeholder='MM/YY'
												clearable
												disabled
												variant='inline'
												autoOk='true'
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<Event />
														</InputAdornment>
													),
													classes: { disabled: classes.disabledInput },
												}}
												minDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t(
															'credentials:VALIDATION_MESSAGE.MONTH_AND_YEAR'
														)}
													</span>
												}
												maxDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t(
															'credentials:VALIDATION_MESSAGE.MONTH_AND_YEAR'
														)}
													</span>
												}
												invalidDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t(
															'credentials:VALIDATION_MESSAGE.MONTH_AND_YEAR'
														)}
													</span>
												}
												margin='normal'
												theme={datePickerTheme}
												error={
													(errors?.gaps &&
														errors?.gaps[`${index}`]?.endDate) ||
													(!!highlights &&
														highlights.includes(
															`gaps[${index}].endDate`
														))
												}
												KeyboardButtonProps={{
													'aria-label': t(
														`credentials:${constantType}.DATE_TO`
													),
												}}
											/>
										)}
									/>
								</MuiPickersUtilsProvider>
							</FormControl>
						</Grid>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor={`gaps[${index}].reason`}
									classes={{ root: classes.inputLabel }}
									error={
										(!!errors?.gaps && errors?.gaps[`${index}`]?.reason) ||
										(!!highlights &&
											highlights.includes(`gaps[${index}].reason`))
									}
									required
								>
									{t(`credentials:${constantType}.REASON`)}
								</InputLabel>
								<ThemeProvider
									theme={
										(errors?.gaps && errors?.gaps[`${index}`]?.reason) ||
										(!!highlights &&
											highlights.includes(`gaps[${index}].reason`))
											? errorTheme
											: normalTheme
									}
								>
									<NativeSelect
										id={`gaps[${index}].reason`}
										name={`gaps[${index}].reason`}
										className={
											lockFields
												? classes.disabledSelect
												: classes.normalInput
										}
										disabled={
											lockFields
												? classes.disabledSelect
												: classes.normalInput
										}
										inputRef={register()}
										defaultValue={`${item.reason}`}
										onBlur={() => updateHighlight()}
										onChange={() => updateHighlight()}
										input={
											<TextFieldComponent
												style={{ border: `0px solid ${COLORS.RAVEN}` }}
												classes={{ input: classes.setWidthTo100OnMobile }}
											/>
										}
									>
										<option key={''} value={null}></option>
										{gapsReason.map((item) => (
											<option key={item.value} value={item.value}>
												{item.label}
											</option>
										))}
									</NativeSelect>
								</ThemeProvider>
							</FormControl>
							{lockFields ? (
								<Input
									style={{ display: 'none' }}
									name={`gaps[${index}].reason`}
									value={`${item.reason}`}
									inputRef={register()}
								/>
							) : null}
						</Grid>
					</Grid>
					{watch(`gaps[${index}].reason`) === 'Other'
						? showExplanation(item, index)
						: null}
				</form>
			</>
		);
	};

	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}

		return fields.map((item, index) => <div key={item.id}>{renderForm(item, index)}</div>);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12} md={12}>
					<h3 className={classes.header}>{t(`credentials:${constantType}.TITLE`)}</h3>
				</Grid>
				{credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN ? (
					<Grid item xs={12} md={10} className={classes.messageGrid}>
						<Alert severity='error' className={classes.alertMessage} icon={false}>
							{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
						</Alert>
					</Grid>
				) : null}
				{isLoaded &&
					(gapCount === 0 ? (
						<Grid item xs={12} md={10} className={classes.messageGrid}>
							<Alert
								severity='success'
								className={classes.noGapAlertMessage}
								icon={false}
							>
								{t(`credentials:${constantType}.NO_GAP_MESSAGE`)}
							</Alert>
						</Grid>
					) : (
						<Grid item xs={12} md={10} className={classes.messageGrid}>
							<Alert severity='error' className={classes.alertMessage} icon={false}>
								{t(`credentials:${constantType}.GAP_MESSAGE_2`)}
							</Alert>
						</Grid>
					))}

				{gapCount !== 0 ? (
					<Grid item xs={10} md={10}>
						<span className={`${classes.gapMessage}`}>
							{t(`credentials:${constantType}.INFO_MESSAGE`)}
						</span>
					</Grid>
				) : null}

				<Grid item xs={12} md={12}>
					{renderForms()}
				</Grid>
			</Grid>
		</>
	);
};
export default WithContexts(withTranslation()(withStyles(styles)(GapsForm)));
