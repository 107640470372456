import React, { Component } from 'react';
import { withStyles, Grid, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import HappyEmoji from '../../../assets/images/happy-emoji.svg';
import MehEmoji from '../../../assets/images/meh-emoji.svg';
import AngryEmoji from '../../../assets/images/angry-emoji.svg';
import { postFeedbackResponse } from '../../../services/TimeAndPayService';

const styles = (theme) => ({
	graphicContainer: {
		justifyContent: 'center',
	},
	graphicItem: {
		display: 'flex',
		alignItems: 'stretch',
		justifyContent: 'center',
		margin: '10px 0 10px 0',
	},
	graphic: {
		width: '60%',
		cursor: 'pointer',
		paddingBottom: '10px',
		[theme.breakpoints.down('sm')]: {
			width: '30%',
		},
	},
	feedbackHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'center',
	},
	topTileRoot: {
		marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
});

class FeedbackTile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			happy: false,
			notHappy: false,
		};
	}

	componentDidMount() {
		const state = { ...this.state };
		this.setState({ ...state });
	}

	handleHappyClick = async () => {
		const state = { ...this.state };
		state.happy = true;
		let feedback = 1;
		await postFeedbackResponse(parseInt(this.props.externalId), feedback);
		this.setState({ happy: state.happy });
	};

	handleNeutralClick = async () => {
		const state = { ...this.state };
		state.notHappy = true;
		let feedback = 2;
		await postFeedbackResponse(parseInt(this.props.externalId), feedback);
		this.setState({ notHappy: state.notHappy });
	};

	handleNotHappyClick = async () => {
		const state = { ...this.state };
		state.notHappy = true;
		let feedback = 3;
		await postFeedbackResponse(parseInt(this.props.externalId), feedback);
		this.setState({ notHappy: state.notHappy });
	};

	render() {
		const { happy, notHappy } = this.state;
		const { classes, t } = this.props;

		return (
			<Paper classes={{ root: classes.topTileRoot }} elevation={3}>
				{happy === false && notHappy === false ? (
					<Grid container classes={{ root: classes.topTileRoot }}>
						<Grid item xs={12} md={9} className={classes.feedbackHeader}>
							{t('timeAndPay:HEADERS.FEEDBACK')}
						</Grid>
						<Grid container className={classes.graphicContainer}>
							<Grid item xs={4} md={3} className={classes.graphicItem}>
								<img
									src={HappyEmoji}
									alt='Happy Emoji'
									className={classes.graphic}
									onClick={this.handleHappyClick}
								/>
							</Grid>
							<Grid item xs={4} md={3} className={classes.graphicItem}>
								<img
									src={MehEmoji}
									alt='Meh Emoji'
									className={classes.graphic}
									onClick={this.handleNeutralClick}
								/>
							</Grid>
							<Grid item xs={4} md={3} className={classes.graphicItem}>
								<img
									src={AngryEmoji}
									alt='Angry Emoji'
									className={classes.graphic}
									onClick={this.handleNotHappyClick}
								/>
							</Grid>
						</Grid>
					</Grid>
				) : null}
				{happy === true && notHappy === false ? (
					<Grid container classes={{ root: classes.topTileRoot }}>
						<Grid item xs={12} md={8} className={classes.feedbackHeader}>
							{t('timeAndPay:HEADERS.FEEDBACK_HAPPY')}
						</Grid>
					</Grid>
				) : null}
				{happy === false && notHappy === true ? (
					<Grid container classes={{ root: classes.topTileRoot }}>
						<Grid item xs={12} md={8} className={classes.feedbackHeader}>
							{t('timeAndPay:HEADERS.FEEDBACK_NOTHAPPY')}
						</Grid>
					</Grid>
				) : null}
			</Paper>
		);
	}
}
export default withTranslation()(withStyles(styles)(FeedbackTile));
