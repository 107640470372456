import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../../../utils/Application_Constants';
import { NavLink } from 'react-router-dom';
import { Grid, makeStyles, CircularProgress } from '@material-ui/core';
import { getOtherCertifications } from '../../../../../services/Credentialing';
import moment from 'moment';
import { useForm, useFieldArray } from 'react-hook-form';
import LaunchIcon from '@material-ui/icons/Launch';
import { convertToDateIgnoringTime } from '../../../../../utils/helpers';
import ViewMoreButton from './ViewMoreButton';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	tableHeader: {
		borderBottom: `1px solid ${COLORS.LT_SLATE10}`,
		marginBottom: '1rem',
	},
	root: {
		padding: '20px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		maxWidth: '100%',
		//backgroundColor: COLORS.BLACK_HAZE,
	},
	helpPanelContainerRoot: {
		padding: '15px',
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			padding: '0px',
		},
	},
	linkHeader: {
		color: COLORS.DENIM,
		textDecoration: 'none',
		display: 'flex',
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
	},
	sectionStyle: {
		color: COLORS.DENIM,
		fontWeight: '700',
		display: 'flex',
	},
	info: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'left',
	},
	columnInfoLeft: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '16px',
		textAlign: 'left',
		margin: '20px 0 10px 0',
		//display: 'inline-block',
		//whiteSpace: 'nowrap',
		//overflow: 'hidden',
		//textOverflow: 'ellipsis',
		//width: '350px',
		//[theme.breakpoints.down('md')]: {
		//    width: '190px',
		//},
		//[theme.breakpoints.down('sm')]: {
		//    width: '100px',
		//},
	},
	columnInfoRight: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '16px',
		textAlign: 'right',
		margin: '20px 0 10px 0',
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
	lifeTime: {
		marginLeft: '29px',
	},
	columnHeaderLeft: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
		marginTop: '20px',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
	columnHeaderRight: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
		marginTop: '20px',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
	checkStatusIcon: {
		color: COLORS.DENIM,
		fontSize: '22px',
		marginLeft: '4px',
		marginTop: '3px;',
	},
	noMaxWidth: {
		maxWidth: 'none',
	},
	topTileRoot: {
		marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	expirationDate: {
		textAlign: 'right',
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '16px',
		margin: '20px 0 10px 0',
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
	iconAlign: {
		paddingLeft: '110px',
		paddingTop: '0px',
	},
	dateInfoGrid: {
		display: 'grid',
		width: '75px',
		gridTemplateColumns: 'repeat(auto-fit, minmax(30px, 1fr))',
		[theme.breakpoints.down('sm')]: {
			width: '50px',
			gridTemplateColumns: 'repeat(auto-fit, minmax(25px, 1fr))',
		},
	},

	dateItemColumn: {
		textAlign: 'center',
	},
	iconIndicator: {
		textAlign: 'left',
	},
}));

function OtherCertsCard({
	t,
	UserContext,
	setCredAppError,
	isLoaded,
	setIsLoaded,
	renderIndicatorColor,
}) {
	const classes = styles();
	const [viewMore, setViewMore] = useState(false);

	const toggleViewMore = () => {
		setViewMore(!viewMore);
	};

	const { control, reset } = useForm({
		mode: 'all',
		defaultValues: {
			id: null,
			certificationType: '',
			expirationDate: null,
			explainOther: '',
		},
	});

	const { fields, append } = useFieldArray({
		control,
		name: 'certifications',
		keyName: 'internalId',
	});

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			await getOtherCertifications(externalId)
				.then(function (data) {
					const { certifications = [] } = data;

					if (certifications.length === 0) {
						append({
							id: null,
							certificationType: '',
							expirationDate: null,
							explainOther: '',
						});
						data['certifications'].push({
							id: null,
							certificationType: '',
							expirationDate: null,
							explainOther: '',
						});
					} else {
						data['certifications'].map((item) => {
							if (item.certificationType === null) {
								item.certificationType = '';
							}
							if (item.explainOther === null) {
								item.explainOther = '';
							}
							if (item.expirationDate === null || item.expirationDate === undefined) {
								item.expirationDate = '';
							}
							return item;
						});
					}
					reset(data);
					setIsLoaded(true);
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, []);

	const otherCertificationList = () => {
		let data = fields.filter((item) => {
			if (item.expirationDate !== '') {
				var todaysDate = moment(new Date()).format('MM/DD/YYYY');
				var certificateDate = moment(item.expirationDate).format('MM/DD/YYYY');

				if (moment(todaysDate).diff(certificateDate, 'years', true) <= 2) {
					return item;
				}
			}
		});
		data = data.sort((a, b) => {
			return moment(a.expirationDate).diff(b.expirationDate);
		});

		return data;
	};

	const renderOtherCertificationData = () => {
		let data = otherCertificationList();
		if (!viewMore) {
			data = data.slice(0, 4);
		}
		if (!isLoaded) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		} else {
			return (
				<Grid container>
					{data.map((item) => (
						<>
							<Grid iem xs={8} md={8}>
								<p>{item.certificationType}</p>
							</Grid>
							<Grid item xs={4} md={4}>
								<p>
									{renderIndicatorColor(item.expirationDate)}{' '}
									{convertToDateIgnoringTime(item.expirationDate).format(
										'MM/DD/YYYY'
									)}
								</p>
							</Grid>
						</>
					))}
				</Grid>
			);
		}
	};

	const renderOtherCerts = () => {
		let certList = otherCertificationList();
		return (
			<Grid container>
				<Grid item xs={12}>
					<NavLink to={{ pathname: '/credentials/certifications/otherCertifications' }}>
						<h3 className='cr-journey'>
							{t(`credentials:CRED_OVERVIEW.CERTIFICATIONS`)}
							<LaunchIcon className={classes.checkStatusIcon} />
						</h3>
					</NavLink>
					<p>{t(`credentials:CRED_OVERVIEW.OTHER_CERT_INFO`)}</p>
				</Grid>
				<Grid container direction='row' className={classes.tableHeader}>
					<Grid item xs={8} md={8}>
						<h6 className='cr-mb-0'>{t(`credentials:CRED_OVERVIEW.CERT_TYPE`)}</h6>
					</Grid>
					<Grid item xs={4} md={4}>
						<h6 className='cr-mb-0'>
							{t(`credentials:CRED_OVERVIEW.EXPIRATION_DATE`)}
						</h6>
					</Grid>
				</Grid>
				<Grid container direction='row'>
					<Grid item xs={12} md={12}>
						{renderOtherCertificationData()}
					</Grid>
					{certList.length > 4 ? (
						<ViewMoreButton t={t} viewMore={viewMore} toggleViewMore={toggleViewMore} />
					) : null}
				</Grid>
			</Grid>
		);
	};

	return <>{renderOtherCerts()}</>;
}

export default OtherCertsCard;
