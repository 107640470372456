import React, { Component } from 'react';
import { withStyles, Grid, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import Flights from './Flights/Flights';
import Lodging from './Lodging/Lodging';
import CarRental from './CarRental/CarRental';
import { COLORS } from '../../../utils/Application_Constants';
import { getTravelData } from '../../../services/AssignmentsService';
import FeatureFlag from '../../GlobalComponents/FeatureFlagComponent/FeatureFlag';

const styles = (theme) => ({
	header: {
		fontWeight: '700',
		fontSize: '20px',
		color: COLORS.RAVEN,
		margin: 0,
		textAlign: 'left',
	},
	headerGridRoot: {
		paddingLeft: '25px',
	},
	helpNumber: {
		paddingRight: '25px',
		textAlign: 'right',
		color: COLORS.TRUE_BLUE,
	},
	summaryHeaderGridRoot: {
		color: COLORS.RAVEN,
		padding: '25px 15px 0px 15px',
		textAlign: 'left',
	},
	dashboardCard: {
		width: '100%',
		marginTop: '20px',
		paddingTop: '20px',
		paddingBottom: '20px',
	},
	panelHeader: {
		color: COLORS.RAVEN,
	},
	expansionPanelSummaryRoot: {
		padding: '8px 20px',
	},
	chevronRoot: {
		width: '25px',
		right: '0',
		color: COLORS.TRUE_BLUE,
	},
	clickableLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	alignLeft: {
		width: '50%',
		float: 'left',
		textAlign: 'left',
	},
	iconClass: {
		verticalAlign: 'middle',
		paddingRight: '6px',
		color: COLORS.TRUE_BLUE,
	},
});

class Travel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			travelInfo: [],
		};
	}

	async componentDidMount() {
		const { assignment, filter } = this.props;
		const state = { ...this.state };
		try {
			state.travelInfo = await getTravelData(assignment.name, filter);
		} catch (error) {
			const { status } = error.response || { status: undefined };
			if (status === 404) {
				window.location.href = '#/404';
			} else if (status === 503) {
				window.location.href = '#/503';
			} else if (status === 500) {
				window.location.href = '#/500';
			} else {
				// default to 403
				window.location.href = '#/403';
			}
		}
		this.setState({ ...state });
	}

	render = () => {
		const state = { ...this.state };
		const { t, classes } = this.props;
		return (
			<Paper classes={{ root: classes.dashboardCard }}>
				<Grid container>
					<Grid item xs={12} classes={{ root: classes.headerGridRoot }}>
						<Grid container>
							<Grid item xs={12} md={6} classes={{ root: classes.header }}>
								{t('assignments:TRAVEL.TITLE')}
							</Grid>
							<Grid item xs={12} md={6} classes={{ root: classes.helpNumber }}>
								<a
									href={t('common:INFORMATION.HELP_NUMBER_LINK')}
									className={classes.clickableLink}
								>
									{t('assignments:TRAVEL.AFTER_HOURS')}{' '}
									{t('common:INFORMATION.HELP_NUMBER')}
								</a>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} classes={{ root: classes.summaryHeaderGridRoot }}>
						<Flights travelInfo={state.travelInfo} />
					</Grid>
					<FeatureFlag name='Lodging'>
						<Grid item xs={12} classes={{ root: classes.summaryHeaderGridRoot }}>
							<Lodging travelInfo={state.travelInfo} />
						</Grid>
					</FeatureFlag>
					<FeatureFlag name='CarRental'>
						<Grid item xs={12} classes={{ root: classes.summaryHeaderGridRoot }}>
							<CarRental travelInfo={state.travelInfo} />
						</Grid>
					</FeatureFlag>
				</Grid>
			</Paper>
		);
	};
}

export default withTranslation()(withStyles(styles)(Travel));
