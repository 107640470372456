import { axiosJobSeachInstance } from '../configuration/axios/axiosConfiguration';
import { ApiError } from '../utils/ApiError';

/**
 * Get Job search URL based on job search keywords
 **/
async function getJobSearchUrl(Keywords) {
	let response;
	try {
		response = await axiosJobSeachInstance.post(
			`/Resources/JobSearch/FullTextSearch?Keywords=${Keywords}`
		);
	} catch (error) {
		console.log('getJobSearchUrl error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getJobSearchUrl'
			);
		}
	}
	return response.data;
}

export { getJobSearchUrl };
