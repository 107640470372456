import moment from 'moment';
import _ from 'lodash';
import { DOCUMENT_TYPE, DOCUMENT_TYPE_LABEL } from '../utils/Application_Constants';

function dateRange(travelItem) {
	let departure = moment(travelItem.originatedDate);
	let arrival = moment(travelItem.completedDate);
	if (departure.isSame(arrival, 'day')) {
		return departure.format('MMM D') + ', ' + departure.format('YYYY');
	} else if (departure.isSame(arrival, 'month')) {
		return (
			departure.format('MMM D') +
			' - ' +
			arrival.format('D') +
			', ' +
			departure.format('YYYY')
		);
	} else if (departure.isSame(arrival, 'year')) {
		return (
			departure.format('MMM D') +
			' - ' +
			arrival.format('MMM D') +
			', ' +
			departure.format('YYYY')
		);
	}
	return departure.format('MMM D YYYY') + ' - ' + arrival.format('MMM D YYYY');
}

function getItemCookie(cName) {
	const name = cName + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
}

function formatPhoneNumber(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? `+${match[1]} ` : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
}

function wait(ms) {
	var start = new Date().getTime();
	var end = start;
	while (end < start + ms) {
		end = new Date().getTime();
	}
}

function isExpired() {
	var aIdtoken = getItemCookie('msal.idtoken');
	var isExpired = null;
	var JWTHelper;
	var helper;
	var decoded;

	if (aIdtoken !== null) {
		JWTHelper = require('jwthelper');
		helper = JWTHelper.createJWTHelper();
		decoded = helper.decode(aIdtoken);
		if (Math.floor(new Date().getTime() / 1000) >= decoded.exp) {
			isExpired = true;
		} else {
			isExpired = false;
		}
	}

	return isExpired;
}

function setLocalStorageWithExpiry(key, value, ttl) {
	const now = new Date();

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	};
	//console.log("DeleteDocument setWithExpiry!" + key + " " + JSON.stringify(item));
	localStorage.setItem(key, JSON.stringify(item));
}

function getLocalStorageWithExpiry(key) {
	const itemStr = localStorage.getItem(key);
	//console.log("DeleteDocument getWithExpiry!" + key );
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr);
	const now = new Date();

	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
}

function parse_query_string(query) {
	var vars = query.split('&');
	var query_string = {};
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		var key = decodeURIComponent(pair[0]);
		var value = decodeURIComponent(pair[1]);
		// If first entry with this name
		if (typeof query_string[key] === 'undefined') {
			query_string[key] = decodeURIComponent(value);
			// If second entry with this name
		} else if (typeof query_string[key] === 'string') {
			var arr = [query_string[key], decodeURIComponent(value)];
			query_string[key] = arr;
			// If third or later entry with this name
		} else {
			query_string[key].push(decodeURIComponent(value));
		}
	}
	return query_string;
}

function isValidZipCode(zipCode) {
	if (!zipCode) return true;
	return zipCode.length === 0 || (zipCode.length > 4 && zipCode.length < 13);
}

function convertToDateIgnoringTime(dateWithTime) {
	if (!dateWithTime) return null;
	const tIdx = dateWithTime.indexOf('T');
	if (tIdx === -1) return dateWithTime;
	return moment(dateWithTime.substring(0, tIdx));
}

function dateInUrlFormat(d) {
	return d.toISOString();
}

function getNestedProperty(obj, propPath) {
	if (!obj) return undefined;
	const props = propPath.split('.');
	let curr = obj;
	for (let i = 0; i < props.length; ++i && curr !== undefined) {
		let newCur = curr[props[i]];
		if (!newCur) newCur = curr[props[i] + '0'];
		curr = newCur;
	}
	return curr;
}

function getNestedDocumentTypePropertyLabel(obj, propPath) {
	if (!obj) return undefined;
	const props = propPath.split('.');
	let curr = obj;
	for (let i = 0; i < props.length; ++i && curr !== undefined) {
		let newCur = curr[props[i]];
		if (!newCur) newCur = curr[props[i] + '0'];
		curr = newCur;
	}

	var labelLookup = DOCUMENT_TYPE_LABEL[getKeyByValue(DOCUMENT_TYPE, curr)];

	return labelLookup;
}

function getKeyByValue(object, value) {
	return Object.keys(object).find((key) => object[key] === value);
}

function stripPrefix(prefix, string) {
	if (string.startsWith(prefix)) return string.substring(prefix.length);
	return string;
}

function matchesTemplate(item, formTemplate) {
	Object.getOwnPropertyNames(formTemplate).forEach((attribute) => {
		if (!item.hasOwnProperty(attribute)) {
			delete formTemplate[attribute];
		}
	});
	const fieldsToCheck = pickFields(item, Object.getOwnPropertyNames(formTemplate));
	console.log('fieldsToCheck=' + JSON.stringify(fieldsToCheck));
	console.log('formTemplate=' + JSON.stringify(formTemplate));
	return _.isEqual(fieldsToCheck, formTemplate);
}

function pickFields(obj, props) {
	// Make sure object and properties are provided
	if (!obj || !props) return null;

	// Create new object
	var picked = {};

	// Loop through props and push to new object
	props.forEach(function (prop) {
		picked[prop] = obj[prop];
	});

	// Return new object
	return picked;
}

function browserName() {
	const userAgentString = navigator.userAgent;

	if (userAgentString.indexOf('Chrome') > -1) return 'Chrome';
	if (userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1) return 'MSIE';
	if (userAgentString.indexOf('Firefox') > -1) return 'Firefox';
	if (userAgentString.indexOf('Safari') > -1) return 'Safari';
	return null;
}

function compareEntryStrings(str1, str2) {
	if (!str1 && !!str2) return false;
	if (!!str1 && !str2) return false;
	if (!str1 && !str2) return true; // both null or empty?
	return str1 === str2;
}

function environment() {
	var environmentName = process.env.REACT_APP_EXECUTION_ENVIRONMENT;
	console.log(' environmentName -> ' + environmentName);
	if (environmentName !== null && environmentName.length > 0) {
		return environmentName;
	}
	return null;
}

function ltdomain() {
	return process.env.REACT_APP_LOGIN_URL;
}

const stateJson = {
	AZ: 'Arizona',
	AL: 'Alabama',
	AK: 'Alaska',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DC: 'District of Columbia',
	DE: 'Delaware',
	FL: 'Florida',
	GA: 'Georgia',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PA: 'Pennsylvania',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
	AS: 'American Samoa',
	GU: 'Guam',
	MP: 'Northern Mariana Islands',
	PR: 'Puerto Rico',
	VI: 'U.S. Virgin Islands',
	UM: 'U.S. Minor Outlying Islands',
};

function stateAcronymToFullName(acronym) {
	if (stateJson[acronym] != null) {
		return stateJson[acronym];
	}
	return acronym;
}

function stateFullNameToAcronym(fullName) {
	if (!fullName) return '';
	var rtn = Object.keys(stateJson).find((key) => stateJson[key] === fullName);

	if (!!rtn) {
		return rtn;
	}
	return fullName;
}

function cityIfNotNull(city) {
	return !!(city)?city+", ":"";
}

function totalCount(objects, property) {
	return (
		Math.round(objects.reduce((partialSum, a) => partialSum + a[property], 0) * 100) / 100
	);
}

export {
	dateRange,
	getItemCookie,
	isExpired,
	wait,
	parse_query_string,
	isValidZipCode,
	convertToDateIgnoringTime,
	dateInUrlFormat,
	getNestedProperty,
	stripPrefix,
	matchesTemplate,
	pickFields,
	browserName,
	compareEntryStrings,
	getLocalStorageWithExpiry,
	setLocalStorageWithExpiry,
	getNestedDocumentTypePropertyLabel,
	stateAcronymToFullName,
	stateFullNameToAcronym,
	environment,
	ltdomain,
	formatPhoneNumber,
	cityIfNotNull,
	totalCount,
};
