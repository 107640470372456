import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import AssignmentListItem from '../AssignmentListItem/AssignmentListItem';
import AssignmentListEmptyState from '../AssignmentListItem/AssignmentListEmptyState';
import { COLORS } from '../../../utils/Application_Constants';

const styles = (theme) => ({
	header: {
		color: COLORS.WATERLOO,
		fontWeight: '700',
		fontSize: '18px',
		textAlign: 'left',
	},
	upcomingAssignmentHeading: {
		//width: '90%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginLeft: '-20px',
	},
	upcomingAssignmentHeadingLevel2: {
		// width: '90%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '40px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
});

class UpcomingAssignmentDashboard extends Component {
	toggleAssignmentView(index) {
		const i = index;
		return () => {
			const { history, assignments } = this.props;
			history.push(`/assignments/upcoming/${assignments[i].id}`);
		};
	}

	renderAssignmentsConditionally = () => {
		const { assignments } = this.props;
		if (!assignments) {
			return <AssignmentListEmptyState assignmentType='upcoming' error />;
		}
		const assignmentCount = assignments.length;
		if (assignmentCount === 0) {
			return <AssignmentListEmptyState assignmentType='upcoming' />;
		}
		if (assignmentCount === 1) {
			return (
				<AssignmentListItem
					type='upcoming'
					toggleAssignmentView={this.toggleAssignmentView(0)}
					assignment={assignments[0]}
				/>
			);
		}
		if (assignmentCount >= 2) {
			return (
				<>
					<AssignmentListItem
						type='upcoming'
						toggleAssignmentView={this.toggleAssignmentView(0)}
						assignment={assignments[0]}
					/>

					<AssignmentListItem
						type='upcoming'
						toggleAssignmentView={this.toggleAssignmentView(1)}
						assignment={assignments[1]}
					/>
				</>
			);
		}
	};

	renderAssignmentTitleConditonally = () => {
		const { assignments, classes } = this.props;
		if (!assignments) {
			return null;
		}
		if (assignments && assignments.length === 0) {
			return (
				<>
					<span className='tileHeader'>Upcoming Assignments({assignments.length})</span>
					{/* <div className={classes.upcomingAssignmentHeadingLevel2}>
              <h1 className={classes.header}>Upcoming Assignments({assignments.length})</h1>
            </div> */}
				</>
			);
		}

		return (
			<div className={classes.upcomingAssignmentHeadingLevel2}>
				<span className='tileHeader'>Upcoming Assignments({assignments.length})</span>
				<Link
					to='/assignments/upcoming'
					className='textLink'
					style={{ marginBottom: '.5rem' }}
				>
					View All
				</Link>
			</div>
		);
	};

	render() {
		return (
			<>
				{this.renderAssignmentTitleConditonally()}
				{this.renderAssignmentsConditionally()}
			</>
		);
	}
}

export default withRouter(withStyles(styles)(UpcomingAssignmentDashboard));
