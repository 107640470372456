class ApiError extends Error {
	constructor(msg) {
		super(!msg ? null : !!msg.detail ? msg.detail : msg);
		// Creates stack prop

		if (!!msg.status) this.errorCode = msg.status;
		this.title = !!msg.title ? msg.title : 'Error';
		this.message = !!msg.detail ? msg.detail : msg.message;
		if (!!msg.instance) this.instance = msg.instance;
		//if (!!msg.errorId) this.errorId = msg.errorId;
		this.errorId = !!msg.errorId ? msg.errorId : msg.statusCode;
	}

	static UnwrappedError(title, msg, location) {
		const err = new ApiError(msg);
		err.title = title;
		err.message = msg;
		err.instance = location;
		return err;
	}
}

export { ApiError };
