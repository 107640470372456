import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles, Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import { COLORS } from '../../../utils/Application_Constants';
import { TimesheetStatusEnumApi } from './Status.enum';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

const styles = (theme) => ({
	viewTimesheetLink: {
		color: COLORS.LT_PEPPER,
		cursor: 'pointer',
	},
	errorText: {
		fontSize: '14px',
	},
	viewTimesheetInError: {
		marginLeft: '6px',
		fontSize: '14px',
	},
	errorListItem: {
		margin: '0',
		marginTop: '0px',
	},
});

const TimesheetsErrors = ({ timesheets, classes, t }) => {
	const timesheetsWithErrors = timesheets?.filter((timesheet) => {
		const timesheetReviewsWithRejectedStatus = timesheet?.timesheetReviews?.filter(
			(timesheetReview) => timesheetReview?.status === TimesheetStatusEnumApi.Rejected
		);
		return (
			timesheetReviewsWithRejectedStatus?.length &&
			timesheetReviewsWithRejectedStatus.length > 0
		);
	});

	return (
		<>
			{timesheetsWithErrors?.length > 0 ? (
				<Alert severity='error'>
					<Grid container alignItems='center'>
						{t('submitTimesheet:HEADERS.TIMESHEET_REJECTED_FOR_WEEKS')}
					</Grid>
					<>
						<ul className={classes.errorListItem}>
							{timesheetsWithErrors.map((timesheet) => {
								const timesheetKey = `${timesheet.bookingId}-${timesheet.weekEnding}`;
								return (
									<li key={timesheetKey}>
										<Grid container alignItems='center'>
											<Typography
												variant='body2'
												classes={{ root: classes.errorText }}
											>
												Week ending{' '}
												{moment(timesheet?.weekEnding).format('MMMM D')}
											</Typography>
											<Link
												to={{
													pathname: `timesheet/${timesheet.id}/edit`,
													search: `?weekEnding=${timesheet.weekEnding}&dueDate=${timesheet.dueDate}&bookingId=${timesheet.bookingId}`,
												}}
												color={COLORS.LT_PEPPER}
												className={{ root: classes.viewTimesheetLink }}
											>
												<Typography
													classes={{ root: classes.viewTimesheetInError }}
													variant='body1'
												>
													{t(
														'submitTimesheet:HEADERS.VIEW_REJECTED_TIMESHEET'
													)}
												</Typography>
											</Link>
										</Grid>
									</li>
								);
							})}
						</ul>
					</>
				</Alert>
			) : (
				<></>
			)}
		</>
	);
};

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(TimesheetsErrors))));
