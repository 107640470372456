import React, { useState, useEffect } from 'react';
import {
	Grid,
	Paper,
	Button,
	FormControl,
	FormGroup,
	FormControlLabel,
	InputLabel,
	InputAdornment,
	ThemeProvider,
	TableHead,
	TableRow,
	TableCell,
	Checkbox,
	Toolbar,
	TableContainer,
	Table,
	TableBody,
	TablePagination,
	CircularProgress,
} from '@material-ui/core';
import DatePickerComponent from '../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { Event } from '@material-ui/icons';
import { COLORS } from '../../../utils/Application_Constants';
import {
	getClinicianPaysheetPdf,
	getPayStatementDetailsData,
	getClinicianPaysheetZip,
} from '../../../services/TimeAndPayService';

const styles = (theme) => ({
	root: {
		padding: '80px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		backgroundColor: COLORS.BLACK_HAZE,
	},
	helpPanelRoot: {
		marginTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	buttonRoot: {
		lineHeight: 'inherit',
		margin: '0 30px 20px',
		padding: 0,
		width: '100%',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
	},
	helpPanelContainerRootTable: {
		padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '30px',
	},
	helpPanelHeader: {
		marginBottom: '10px',
	},
	helpPanelLinks: {
		textAlign: 'left',
		marginBottom: '10px',
	},
	header: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '20px',
		margin: 0,
	},
	linkHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
	},
	progressWrapper: {
		height: '80vh',
	},
	progressWrapperLoading: {
		height: '1vh',
	},
	progressRoot: {
		marginTop: '25vh',
	},
	topTileRoot: {
		marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	headerStatementTile: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			padding: '0 32px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '5px 10px',
		},
	},
	applyButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '150px',
		paddingBottom: '10px',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
		fontWeight: '700',
		marginTop: '20px',
	},
	table: {
		minWidth: '100%',
	},
	link: {
		color: COLORS.TRUE_BLUE,
		marginBottom: '10px',
		textDecoration: `underline dotted ${COLORS.LIGHT_BLUE}`,
		fontWeight: '400',
		fontSize: '16px',
		cursor: 'pointer',
		display: 'block',
	},
	linkDetails: {
		color: COLORS.TRUE_BLUE,
		textDecoration: `underline dotted ${COLORS.LIGHT_BLUE}`,
		fontWeight: '400',
		fontSize: '16px',
		cursor: 'pointer',
		display: 'block',
	},
	topTileTable: {
		marginTop: '30px',
		height: '95%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	helpPanelContainerRoot: {
		padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
	},
});

function PayStatementTable({ t, classes, payStatementDetails, UserContext, payStatementError }) {
	const headCells = [
		{ id: 'date', numeric: false, disablePadding: false, label: 'DATE', fontWeight: 'bold' },
		{ id: 'amount', numeric: true, disablePadding: false, label: 'AMOUNT', fontWeight: 'bold' },
		{ id: 'details', numeric: false, disablePadding: false, label: '' },
	];

	const [page, setPage] = useState(0);

	const [rowsPerPage, setRowsPerPage] = useState(12);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [isChecked, setIsChecked] = useState(
		!payStatementError ? payStatementDetails.slice().fill(false) : false
	);
	const [selectedRows, setSelectedRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [paymentDetails, setPaymentDetails] = useState({});
	const [paymentTableError, setPaymentTableError] = useState(false);

	useEffect(() => {
		(async function setData() {
			setPaymentDetails(payStatementDetails);
		})();
		setIsLoading(false);
	}, [payStatementDetails]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleApplyClicked = async () => {
		const { externalId } = UserContext;
		if (startDate && endDate) {
			const dateFrom = moment(startDate).toISOString(false);
			const dateTo = moment(endDate).toISOString(false);
			setIsLoading(true);
			setPaymentTableError(false);
			let paymentData = await getPayStatementDetailsData(externalId, dateFrom, dateTo).catch(
				(e) => {
					setPaymentTableError(true);
				}
			);
			setPaymentDetails(paymentData);
			if (!paymentTableError) {
				setIsChecked(paymentData.slice().fill(false));
			}
			setIsCheckedAll(false);
		}

		if (startDate === null && endDate === null) {
			setIsLoading(true);
			setPaymentTableError(false);
			let paymentData = await getPayStatementDetailsData(externalId, null, null).catch(
				(e) => {
					setPaymentTableError(true);
				}
			);
			setPaymentDetails(paymentData);
			if (!paymentTableError) {
				setIsChecked(paymentData.slice().fill(false));
			}
			setIsCheckedAll(false);
		}
		setIsLoading(false);
	};

	const handleChangeCheckbox = (page, rowsPerPage, row, index) => {
		const adjustedIndex = page * rowsPerPage + index;
		setIsChecked(isChecked.map((value, i) => (i === adjustedIndex ? !value : value)));

		if (isChecked[adjustedIndex] === false) {
			row['adjustedIndex'] = adjustedIndex;
			selectedRows.push(row);
		}
		if (isChecked[adjustedIndex] === true) {
			if (isCheckedAll) {
				setIsCheckedAll(false);
			}
			if (!row.adjustedIndex) {
				row['adjustedIndex'] = adjustedIndex;
			}
			const findIndex = selectedRows.findIndex((obj) => obj.adjustedIndex === adjustedIndex);
			selectedRows.splice(findIndex, 1);
		}
		setSelectedRows(selectedRows);
	};

	const handleSelectAll = (event) => {
		setIsCheckedAll(event.target.checked);
		setIsChecked(isChecked.map((item) => (item = event.target.checked)));

		if (isCheckedAll === false) {
			selectedRows.splice(0, selectedRows.length);
			paymentDetails.map((item) => selectedRows.push(item));
		}
		if (isCheckedAll === true) {
			selectedRows.splice(0, selectedRows.length);
		}
		setSelectedRows(selectedRows);
	};

	async function handleDowloadSelected(externalId, selectedRows) {
		setIsLoading(true);
		setPaymentTableError(false);
		await getClinicianPaysheetZip(UserContext.externalId, selectedRows).catch((e) => {
			setPaymentTableError(true);
			setIsLoading(false);
		});
		setIsLoading(false);
	}

	function renderPaymentTable() {
		if (isLoading) {
			return (
				<div
					className={isLoading ? classes.progressWrapperLoading : classes.progressWrapper}
				>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}

		if (!!paymentDetails) {
			const numberFormatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
			});
			const payData = paymentDetails;
			const numberofRecords = Object.keys(payData).length;
			const emptyRows =
				rowsPerPage - Math.min(rowsPerPage, numberofRecords - page * rowsPerPage);

			return (
				<>
					<Toolbar>
						<TableContainer component={Paper}>
							<Table classes={{ root: classes.table }}>
								<TableHead>
									<TableRow>
										<TableCell padding='checkbox'>
											<Checkbox
												indeterminate={false}
												color='primary'
												checked={isCheckedAll}
												onChange={handleSelectAll}
												inputProps={{ 'aria-label': 'select all' }}
											/>
										</TableCell>
										{headCells.map((headCell) => (
											<TableCell
												key={headCell.id}
												align={'left'}
												padding={
													headCell.disablePadding ? 'none' : 'default'
												}
											>
												{headCell.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{payData
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => (
											<TableRow key={row.id}>
												<TableCell align='center'>
													<FormGroup>
														<FormControlLabel
															control={
																<Checkbox
																	color='primary'
																	checked={
																		isChecked[
																			page * rowsPerPage +
																				index
																		]
																	}
																	inputProps={{
																		'aria-label': 'select',
																	}}
																	onChange={() =>
																		handleChangeCheckbox(
																			page,
																			rowsPerPage,
																			row,
																			index
																		)
																	}
																/>
															}
														/>
													</FormGroup>
												</TableCell>
												<TableCell align='left'>
													{moment.utc(row.checkDate).format('MM/DD/YYYY')}
												</TableCell>
												<TableCell align='left'>
													{numberFormatter.format(row.paymentAmount)}
												</TableCell>
												<TableCell
													align='left'
													classes={{ root: classes.linkDetails }}
													onClick={async () => {
														setIsLoading(true);
														setPaymentTableError(false);
														await getClinicianPaysheetPdf(
															UserContext.externalId,
															row.providerID,
															row.checkNumber,
															moment
																.utc(row.checkDate)
																.format('YYYY-MM-DD')
														).catch((e) => {
															setPaymentTableError(true);
															setIsLoading(false);
														});
														setIsLoading(false);
													}}
												>
													Download Statement
												</TableCell>
											</TableRow>
										))}
									{emptyRows > 0 && (
										<TableRow style={{ height: 1 * emptyRows }}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							</Table>
							<TablePagination
								count={numberofRecords}
								page={page}
								rowsPerPage={rowsPerPage}
								component='div'
								rowsPerPageOptions={[]}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						</TableContainer>
					</Toolbar>
				</>
			);
		}
	}

	const datePickerHelper = (date) => {
		if (date) {
			return date;
		}
		return null;
	};

	const dateChangeHandler = (date, parsedDate, name) => {
		if (date) {
			if (name === 'startDate') setStartDate(date);
			else setEndDate(date);
		} else {
			if (name === 'startDate') setStartDate(null);
			else setEndDate(null);
		}
	};

	return (
		<>
			<Paper classes={{ root: classes.topTileRoot }}>
				<Grid container classes={{ root: classes.helpPanelContainerRoot }} spacing={2}>
					<Grid item xs={12} md={4}>
						<ThemeProvider theme={datePickerTheme}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} moment={moment}>
								<FormControl classes={{ root: classes.setWidthTo100 }}>
									<InputLabel
										shrink
										htmlFor='StartDate'
										classes={{ root: classes.inputLabel }}
									>
										{t('timeAndPay:PAY_STATEMENT_TABLE.FORM_DATE')}
									</InputLabel>
									<DatePickerComponent
										theme={datePickerTheme}
										variant='inline'
										autoOk='true'
										format='MM/dd/yyyy'
										placeholder='MM/DD/YYYY'
										name='startDate'
										margin='normal'
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<Event />
												</InputAdornment>
											),
											classes: { root: classes.cssOutlinedInput },
										}}
										id='StartDate'
										value={datePickerHelper(startDate)}
										onChange={(date, parsedDate) => {
											dateChangeHandler(date, parsedDate, 'startDate');
										}}
										KeyboardButtonProps={{
											'aria-label': 'Start Date',
										}}
										inputVariant='outlined'
									/>
								</FormControl>
							</MuiPickersUtilsProvider>
						</ThemeProvider>
					</Grid>
					<Grid item xs={12} md={4}>
						<ThemeProvider theme={datePickerTheme}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} moment={moment}>
								<FormControl classes={{ root: classes.setWidthTo100 }}>
									<InputLabel
										shrink
										htmlFor='EndDate'
										classes={{ root: classes.inputLabel }}
									>
										{t('timeAndPay:PAY_STATEMENT_TABLE.TO_DATE')}
									</InputLabel>
									<DatePickerComponent
										theme={datePickerTheme}
										variant='inline'
										autoOk='true'
										format='MM/dd/yyyy'
										placeholder='MM/DD/YYYY'
										name='endDate'
										margin='normal'
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<Event />
												</InputAdornment>
											),
											classes: { root: classes.cssOutlinedInput },
										}}
										id='EndDate'
										value={datePickerHelper(endDate)}
										onChange={(date, parsedDate) => {
											dateChangeHandler(date, parsedDate, 'endDate');
										}}
										KeyboardButtonProps={{
											'aria-label': 'End Date',
										}}
										inputVariant='outlined'
									/>
								</FormControl>
							</MuiPickersUtilsProvider>
						</ThemeProvider>
					</Grid>
					<Grid item xs={12} md={4}>
						<Button
							className={classes.applyButton}
							variant='contained'
							color='primary'
							onClick={() => {
								handleApplyClicked();
							}}
						>
							{t('timeAndPay:PAY_STATEMENT_TABLE.TEXT_APPLY')}
						</Button>
					</Grid>
				</Grid>
				<Grid container classes={{ root: classes.helpPanelContainerRoot }} spacing={2}>
					<Grid item xs={12} md={4}>
						<Button
							className={classes.helpPanelButton}
							disabled={selectedRows.length === 0}
							color='primary'
							variant='outlined'
							onClick={async () => {
								await handleDowloadSelected(UserContext.externalId, selectedRows);
							}}
						>
							{t('timeAndPay:PAY_STATEMENT_TABLE.DOWNLOAD_SELECTED')}
						</Button>
					</Grid>
				</Grid>
				<Grid container spacing={3} classes={{ root: classes.helpPanelContainerRoot }}>
					<Grid item xs={12}>
						{renderPaymentTable()}
					</Grid>
				</Grid>
				{payStatementError || paymentTableError ? (
					<Grid container spacing={3} classes={{ root: classes.helpPanelContainerRoot }}>
						<Grid item xs={12}>
							<h3>
								Error Occurred while fetching/downloading data. Please try after
								some time.
							</h3>
						</Grid>
					</Grid>
				) : null}
				<Grid container classes={{ root: classes.helpPanelContainerRoot }} spacing={2}>
					<Grid item xs={12} md={4}>
						<Button
							className={classes.helpPanelButton}
							disabled={selectedRows.length === 0}
							color='primary'
							variant='outlined'
							onClick={async () => {
								await handleDowloadSelected(UserContext.externalId, selectedRows);
							}}
						>
							{t('timeAndPay:PAY_STATEMENT_TABLE.DOWNLOAD_SELECTED')}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
}
export default PayStatementTable;
