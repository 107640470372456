import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	withStyles,
	Paper,
	Grid,
	FormControl,
	FormHelperText,
	InputLabel,
	CircularProgress,
	Switch,
	InputAdornment,
	ThemeProvider,
	IconButton,
	Radio,
	RadioGroup,
	FormControlLabel,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@material-ui/core';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Event } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import UtcUtils from '../../GlobalComponents/UtcUtils/UtcUtils';
import { states } from '../Account/accountOptions';
import { COLORS } from '../../../utils/Application_Constants';
import DatePickerComponent from '../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import TextFieldComponent from '../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import datePickerTheme from '../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import PhoneIcon from '../../../assets/images/comm-pref-phone-icon.svg';
import EditIcon from '@material-ui/icons/Edit';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
	getClinicianCommPreference,
	postClinicianCommPreference,
} from '../../../services/ProfileServices/profileService';

const styles = (theme) => ({
	header: {
		color: COLORS.ST_TROPAZ,
		textAlign: 'left',
		paddingTop: '20px',
		fontSize: '20px',
		fontWeight: '700',
	},
	topHeader: {
		color: COLORS.DENIM,
		textAlign: 'left',
		fontSize: '24px',
		marginTop: '25px',
	},
	textLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
		marginBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			margin: '20px 0 20px',
		},
	},
	inputLabel: {
		// color: COLORS.RAVEN,
		// fontWeight: '800',
		fontSize: '21px',
		// textAlign: 'left',
		// marginBottom: '20px',
		// [theme.breakpoints.down('sm')]: {
		//     margin: '-20px 0'
		// }
	},
	onTheGoLabel: {
		color: COLORS.RAVEN,
		textAlign: 'left',
		fontWeight: '700',
		fontSize: '20px',
		marginBottom: '20px',
	},
	onTheGoContainer: {
		// justifyContent: 'flex-start'
		// paddingLeft: '1rem',
	},
	graphicContainer: {
		alignItems: 'stretch',
		justifyContent: 'center',
		paddingRight: '2rem',
	},
	graphic: {
		width: '100%',
	},
	progress: {
		height: '100vh',
	},
	paperRoot: {
		padding: '2rem',
	},
	// topHeaderRoot: {
	//     [theme.breakpoints.up('md')]: {
	//         display: 'flex',
	//         padding: '0 32px 0px 32px',
	//     },
	//     [theme.breakpoints.down('sm')]: {
	//         padding: '5px 20px 25px 20px',
	//     }
	// },
	// gridRoot: {
	//     [theme.breakpoints.up('md')]: {
	//         display: 'flex',
	//         padding: '0 32px 25px 32px',
	//     },
	//     [theme.breakpoints.down('sm')]: {
	//         padding: '5px 20px 0px 20px',
	//     }
	// },
	onTheGoGridRoot: {
		backgroundColor: COLORS.LT_SLATE10,
		// display: 'flex',
		padding: '32px',
		// width: '93%'
		// [theme.breakpoints.down('sm')]: {
		//     backgroundColor: '#f6f6f6',
		//     width: '91%',
		//     padding: '20px 0px 20px 20px',
		//     margin: ' 0 30px 30px 30px',
		// },
		// [theme.breakpoints.down('xs')]: {
		//     backgroundColor: '#f6f6f6',
		//     width: '90%',
		//     padding: '20px 0px 20px 20px',
		//     margin: ' 0 30px 30px 15px',
		// },
	},
	textingContainer: {
		display: 'flex',
	},
	mobileContainer: {
		alignItems: 'center',
		marginTop: '-30px',
		marginLeft: '140px',
		[theme.breakpoints.down('sm')]: {
			margin: '20px 0px 0px 0px',
		},
	},
	radioGroup: {
		// paddingLeft: '1rem',
		//     marginLeft: '140px',
		//     display: 'flex',
		//     [theme.breakpoints.down('sm')]: {
		//         marginLeft: '0px'
		//     }
	},
	radioGroupLabel: {
		whitespace: 'wrap',
		textAlign: 'left',
	},
	dateGrid: {
		padding: '0px',
	},
	setWidthTo100: {
		width: '100%',
	},
	editLabel: {
		color: COLORS.LT_JOURNEY,
		fontSize: '16px',
		marginTop: '20px',
	},
	errorLabel: {
		color: 'red !important',
		fontSize: '16px',
		marginTop: '10px',
	},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '16px',
	},
	dateGridWidth: {
		width: '100%',
		marginTop: '-16px',
	},
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.RAVEN,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	cssOutlinedInput: {
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
		'&$cssFocused $notchedOutline': {
			borderColor: '#6D707E', //focused
			borderWidth: '1px',
			borderRadius: 1,
		},
	},
	disabledInput: {
		opacity: 0.6,
	},
	labelStyle: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'left',
		textTransform: 'uppercase',
	},
	infoStyle: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '16px',
		textAlign: 'left',
		textTransform: 'none',
	},
	infoAlert: {
		color: COLORS.LT_PEPPER,
		fontWeight: '500',
		fontSize: '16px',
		textAlign: 'left',
		textTransform: 'none',
		justifyContent: 'flex-start',
		marginTop: '-2.5rem',
	},
	dateGrid: {
		padding: '0px',
	},
	alertText: {
		color: COLORS.DENIM,
		fontWeight: '700',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
		},
	},
});

class CommunicationPreferences extends Component {
	constructor(props) {
		super(props);
		this.states = states;
		this.state = {
			clinicianPreferences: {},
			disableMobile: true,
			showOptOutAlert: false,
		};
	}

	async componentDidMount() {
		const { externalId, setSaveHandler, setPreferencePageError } = this.props;
		const state = { ...this.state };

		await Promise.all([getClinicianCommPreference(externalId)])
			.then(async ([clinicianPreferences]) => {
				state.clinicianPreferences = clinicianPreferences;

				this.setState({
					clinicianPreferences: state.clinicianPreferences,
					disableMobile: true,
					showOptOutAlert: state.clinicianPreferences.hasOptedOutOfSms
				});
			})
			.catch((e) => {
				setPreferencePageError(e);
			});

		this.baseMobile = this.state.clinicianPreferences.mobilePhone;
		setSaveHandler(this, this.doSubmit);
		this.shouldDisableSave();
	}

	async doSubmit() {
		// Validate required fields
		const state = { ...this.state };
		const flags = this.props.flags;
		const { externalId, setPreferencePageError } = this.props;
		const missingAnyFields =
			!!state.clinicianPreferences.mobilePhone &&
			state.clinicianPreferences.hasOptedOutOfSms === null
				? true
				: false;
		flags.missingData = missingAnyFields;

		if (!missingAnyFields) {
			flags.isPendingSave = true;
			this.props.updateFlags(flags);

			await postClinicianCommPreference(externalId, state.clinicianPreferences).catch((e) => {
				setPreferencePageError(e);
			});

			flags.dataSaved = true;
			flags.isDirty = false;
			flags.isPendingSave = false;
			this.props.updateFlags(flags);
		}
		this.setState({
			clinicianPreferences: state.clinicianPreferences,
			disableMobile: true,
		});
	}
	markFlagsDirty = () => {
		const flags = this.props.flags;
		flags.isDirty = true;
		flags.dataSaved = false;
		this.props.updateFlags(flags);
	};

	shouldDisableSave = () => {
		const flags = this.props.flags;
		const state = { ...this.state };
		const missingAnyFields =
			!!state.clinicianPreferences.mobilePhone &&
			state.clinicianPreferences.hasOptedOutOfSms === null;
		flags.missingData = missingAnyFields;
		this.props.updateFlags(flags);
	};

	handleChange = (event) => {
		const { name } = event.target;
		const state = { ...this.state };
		state.clinicianPreferences[name] = event.target.checked;
		if (
			state.clinicianPreferences.emailFromMarketing === true ||
			state.clinicianPreferences.emailFromRecruiter === true ||
			state.clinicianPreferences.emailFromJobRec === true
		) {
			state.clinicianPreferences.hasOptedOutofEmail = false;
		}
		if (
			state.clinicianPreferences.emailFromMarketing === false &&
			state.clinicianPreferences.emailFromRecruiter === false &&
			state.clinicianPreferences.emailFromJobRec === false
		) {
			state.clinicianPreferences.hasOptedOutofEmail = true;
		}
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	handleUnsubscribeChange = (event) => {
		const { name } = event.target;
		const state = { ...this.state };
		state.clinicianPreferences[name] = event.target.checked;
		if (state.clinicianPreferences[name] === false) {
			state.clinicianPreferences.emailFromMarketing = true;
			state.clinicianPreferences.emailFromRecruiter = true;
			state.clinicianPreferences.emailFromJobRec = true;
		} else {
			state.clinicianPreferences.emailFromMarketing = false;
			state.clinicianPreferences.emailFromRecruiter = false;
			state.clinicianPreferences.emailFromJobRec = false;
		}
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	handleRadioChange = (event) => {
		const { value, name } = event.target;
		const state = { ...this.state };

		if (value === 'true') {
			this.handleConfirmationDialogOpen();
		}
		else {
			state.clinicianPreferences[name] = value;
			this.markFlagsDirty();
			this.setState({
				clinicianPreferences: {
					...this.state.clinicianPreferences,
					hasOptedOutOfSms: false,
				},
				showOptOutAlert: false,
			});
		}
		this.shouldDisableSave();
	};

	handleConfirmationDialogOpen = () => {
		this.setState({ showConfirmationDialog: true });
	};

	handleConfirmationDialogClose = () => {
		this.markFlagsDirty();
		this.setState({
			clinicianPreferences: {
				...this.state.clinicianPreferences,
				hasOptedOutOfSms: false,
			},
			showOptOutAlert: false,
			showConfirmationDialog: false
		});
		this.shouldDisableSave();
	};

	handleConfirmation = () => {
		this.markFlagsDirty();
		this.setState({
			clinicianPreferences: {
				...this.state.clinicianPreferences,
				hasOptedOutOfSms: true,
			},
			showOptOutAlert: true,
			showConfirmationDialog: false
		});
		this.shouldDisableSave();
	};

	handleEditMobile = () => {
		const state = { ...this.state };
		if (state.disableMobile === true) {
			state.clinicianPreferences.hasOptedOutOfSms = null;
		}
		this.markFlagsDirty();
		this.setState({
			clinicianPreferences: state.clinicianPreferences,
			disableMobile: !state.disableMobile,
		});
		this.shouldDisableSave();
	};

	handleCancelMobile = () => {
		const state = { ...this.state };
		this.markFlagsDirty();
		state.clinicianPreferences.mobilePhone = this.baseMobile;
		this.setState({
			clinicianPreferences: state.clinicianPreferences,
			disableMobile: !state.disableMobile,
		});
		this.shouldDisableSave();
	};

	textFieldChangeHandler = (event) => {
		const { value, name } = event.target;
		const state = { ...this.state };
		if (state.clinicianPreferences[name] !== value) {
			state.clinicianPreferences[name] = value;
			this.markFlagsDirty();
		}
		this.setState({ ...state });
	};

	normalizePhoneInput(value, previousValue) {
		if (!value) return value;

		const onlyNums = value.replace(/[^\d]/g, ''); // only allows 0-9
		if (!previousValue || value.length > previousValue.length) {
			if (onlyNums.length === 3) return `(${onlyNums})`;
			if (onlyNums.length === 6) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}-`;

			if (onlyNums.length <= 3) return onlyNums;
			if (onlyNums.length <= 6) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;

			return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
		}
		return value;
	}

	textFieldChangeHandlerProfilePhone = (event) => {
		const { value, name } = event.target;

		const state = { ...this.state };
		if (state.clinicianPreferences[name] !== value) {
			if (name === 'mobilePhone') {
				state.clinicianPreferences[name] = this.normalizePhoneInput(
					value,
					this.state.clinicianPreferences.mobilePhone
				);
				state.clinicianPreferences.mobilePhone = this.normalizePhoneInput(
					value,
					this.state.clinicianPreferences.mobilePhone
				);
			}
			this.markFlagsDirty();
		}
		this.setState({ ...state });
		this.shouldDisableSave();
	};

	datePickerHelper(date) {
		if (date) {
			return date;
		}
		return null;
	}
	dateChangeHandler = (date, parsedDate, name) => {
		const state = { ...this.state };
		if (date) {
			state.clinicianPreferences[name] = moment.utc(
				date.startOf('day').format('YYYY-MM-DD HH:mm:ss')
			);
		} else {
			state.clinicianPreferences[name] = null;
		}
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	render() {
		const { classes, t } = this.props;
		const { clinicianPreferences, disableMobile, showOptOutAlert, showConfirmationDialog } = this.state;
		if (Object.keys(clinicianPreferences).length === 0) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}
		const {
			hasOptedOutofEmail,
			emailFromMarketing,
			emailFromRecruiter,
			emailFromJobRec,
			snoozeDate,
			hasOptedOutOfSms,
			email,
			mobilePhone,
		} = clinicianPreferences;

		return (
			<>
				<Paper classes={{ root: classes.paperRoot }}>
					<Grid container classes={{ root: classes.topHeaderRoot }} item xs={12}>
						<h3 className={classes.topHeader}>
							{t('profile:PREFERENCES.EMAIL_TEXT_PREFERENCES')}
						</h3>
					</Grid>

					<Grid container classes={{ root: classes.gridRoot }} item xs={12}>
						<h3 className={classes.header}>
							{t('profile:PREFERENCES.TEXT_COMMUNICATION_PREFERENCES')}
						</h3>
					</Grid>
					<Grid container classes={{ root: classes.onTheGoGridRoot }}>
						<Grid item sm={2} xs={3} className={classes.graphicContainer}>
							<img
								src={PhoneIcon}
								alt='Phone Notifications'
								className={classes.graphic}
							/>
						</Grid>
						<Grid container item sm={10} xs={12} className={classes.onTheGoContainer}>
							<div className={classes.onTheGoLabel}>
								{t('profile:COMMUNICATION.ON_THE_GO')}
							</div>
							<div style={{ marginBottom: '1rem' }} className={classes.infoStyle}>
								{t('profile:COMMUNICATION.ON_THE_GO_INFO')}
							</div>

							<Grid item xs={8}>
								<FormControl>
									<InputLabel
										shrink
										htmlFor='Mobile Phone'
										classes={{ root: classes.inputLabel }}
									>
										Mobile Phone
									</InputLabel>
									<TextFieldComponent
										id='MobilePhone'
										classes={{
											input: classes.setWidthTo100,
											disabled: classes.disabledInput,
										}}
										onChange={this.textFieldChangeHandlerProfilePhone}
										name='mobilePhone'
										disabled={disableMobile}
										placeholder='(xxx) xxx-xxxx'
										value={mobilePhone || ''}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								{disableMobile ? (
									<IconButton
										aria-label='edit mobile number'
										className={classes.editLabel}
										onClick={this.handleEditMobile}
									>
										<EditIcon color='primary' fontSize='small' />
										Edit
									</IconButton>
								) : (
									<IconButton
										aria-label='cancel mobile number'
										className={classes.errorLabel}
										onClick={this.handleCancelMobile}
									>
										<CancelOutlinedIcon color='secondary' fontSize='small' />
										Cancel
									</IconButton>
								)}
							</Grid>
						</Grid>
						{!!mobilePhone && (
							<Grid container>
								<Grid item xs={2}>
									<p></p>
								</Grid>
								<Grid item sm={10} xs={12} className={classes.radioGroup}>
									<FormControl error={!hasOptedOutOfSms}>
										<RadioGroup
											aria-label='hasOptedOutOfSms'
											name='hasOptedOutOfSms'
											value={`${hasOptedOutOfSms}`}
											onChange={this.handleRadioChange}
											className={classes.radioGroupLabel}
										>
											<FormControlLabel
												value='false'
												control={
													<Radio
														color='primary'
														style={{
															color: COLORS.RAVEN,
															alignSelf: 'flex-start',
														}}
														disableRipple
														size='small'
													/>
												}
												label={
													<span className={classes.infoStyle}>
														{t('profile:COMMUNICATION.CONSENT')}
													</span>
												}
											/>
											<FormControlLabel
												value='true'
												style={{ marginTop: '20px' }}
												control={
													<Radio
														color='primary'
														style={{
															color: COLORS.RAVEN,
															alignSelf: 'flex-start',
														}}
														disableRipple
														size='small'
													/>
												}
												label={
													<span className={classes.infoStyle}>
														{t('profile:COMMUNICATION.DENY_CONSENT')}
													</span>
												}
											/>
										</RadioGroup>
									</FormControl>
									{hasOptedOutOfSms === null && (
										<FormHelperText className={classes.cssLabel}>
											{t('profile:COMMUNICATION.VALIDATION_MESSAGE')}
										</FormHelperText>
									)}
								</Grid>
							</Grid>
						)}
						<Dialog
							open={showConfirmationDialog}
							onClose={this.handleConfirmationDialogClose}
							aria-labelledby="confirmation-dialog-title"
							aria-describedby="confirmation-dialog-description"
							className={classes.alertText}
						>
							<DialogTitle id="confirmation-dialog-title">Text Opt Out Alert</DialogTitle>
							<DialogContent dividers>
								You've selected that you'd like to opt-out of text messages. This action also opts you out of receiving texts (including responses to your inquiries) from your LocumTenens.com recruiter in any capacity. Do you still want to opt-out?
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleConfirmationDialogClose} color="secondary">
									Cancel
								</Button>
								<Button onClick={this.handleConfirmation} color="primary" autoFocus>
									Yes, Opt-Out
								</Button>
							</DialogActions>
						</Dialog>
						{showOptOutAlert === true || showOptOutAlert === 'true' ? (
							<Grid container className='cr-mt-4'>
								<Grid item xs={12} className={classes.onTheGoContainer}>
									<div className={classes.infoAlert}>
										{t('profile:COMMUNICATION.OPTOUT_ALERT')}
									</div>
								</Grid>
							</Grid>
						) : null}
					</Grid>

					<Grid container classes={{ root: classes.gridRoot }} item xs={12}>
						<h3 className={classes.header}>
							{t('profile:PREFERENCES.EMAIL_COMMUNICATION_PREFERENCES')}
						</h3>
					</Grid>
					<Grid item classes={{ root: classes.gridRoot }} xs={8} md={5}>
						<FormControl classes={{ root: classes.setWidthTo100 }}>
							<InputLabel
								shrink
								htmlFor='Email'
								classes={{ root: classes.inputLabel }}
								error={email === ''}
							>
								{t('profile:COMMUNICATION.EMAIL_ADDRESS')}
							</InputLabel>
							<TextFieldComponent
								id='Email'
								classes={{
									input: classes.setWidthTo100,
									disabled: classes.disabledInput,
								}}
								disabled
								name='email'
								value={email}
							/>
						</FormControl>
					</Grid>

					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={8} md={8}>
							<div className={classes.textLabel}>
								{t('profile:COMMUNICATION.MARKETING_EMAILS')}
							</div>
							<div className={classes.infoStyle}>
								{t('profile:COMMUNICATION.MARKETING_EMAILS_INFO')}
							</div>
						</Grid>
						<Grid item xs={4} md={4}>
							<div style={{ textAlign: 'left' }}>
								<Switch
									checked={emailFromMarketing}
									color='primary'
									onChange={this.handleChange}
									name='emailFromMarketing'
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							</div>
						</Grid>
					</Grid>

					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={8} md={8}>
							<div className={classes.textLabel}>
								{t('profile:COMMUNICATION.JOB_REC_EMAILS')}
							</div>
							<div className={classes.infoStyle}>
								{t('profile:COMMUNICATION.JOB_REC_EMAILS_INFO')}
							</div>
						</Grid>
						<Grid item xs={4} md={4}>
							<div style={{ textAlign: 'left' }}>
								<Switch
									checked={emailFromJobRec}
									color='primary'
									onChange={this.handleChange}
									name='emailFromJobRec'
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							</div>
						</Grid>
					</Grid>

					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={8} md={8}>
							<div className={classes.textLabel}>
								{t('profile:COMMUNICATION.RECRUITERS_EMAIL')}
							</div>
							<div className={classes.infoStyle}>
								{t('profile:COMMUNICATION.RECRUITERS_EMAIL_INFO')}
							</div>
						</Grid>
						<Grid item xs={4} md={4}>
							<div style={{ textAlign: 'left' }}>
								<Switch
									checked={emailFromRecruiter}
									color='primary'
									onChange={this.handleChange}
									name='emailFromRecruiter'
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							</div>
						</Grid>
					</Grid>

					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={12} md={8}>
							<div className={classes.textLabel}>
								{t('profile:COMMUNICATION.SNOOZE_EMAIL')}
							</div>
							<div className={classes.infoStyle}>
								{t('profile:COMMUNICATION.SNOOZE_EMAIL_INFO')}
							</div>
						</Grid>
						<Grid item xs={8} md={4} classes={{ root: classes.dateGrid }}>
							<ThemeProvider theme={datePickerTheme}>
								<MuiPickersUtilsProvider utils={UtcUtils} moment={moment}>
									<FormControl classes={{ root: classes.dateGridWidth }}>
										<DatePickerComponent
											theme={datePickerTheme}
											variant='inline'
											autoOk='true'
											format='MM/DD/YYYY'
											placeholder='MM/DD/YYYY'
											name='snoozeDate'
											margin='normal'
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<Event />
													</InputAdornment>
												),
												classes: { root: classes.cssOutlinedInput },
											}}
											id='snoozeDate'
											value={this.datePickerHelper(snoozeDate)}
											onChange={(date, parsedDate) => {
												this.dateChangeHandler(
													date,
													parsedDate,
													'snoozeDate'
												);
											}}
											KeyboardButtonProps={{
												'aria-label': 'Snooze Date',
											}}
											inputVariant='outlined'
										/>
									</FormControl>
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</Grid>
					</Grid>

					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={8} md={8}>
							<div className={classes.textLabel}>
								{t('profile:COMMUNICATION.UNSUBSCRIBE')}
							</div>
							<div className={classes.infoStyle}>
								{t('profile:COMMUNICATION.UNSUBSCRIBE_INFO')}
							</div>
						</Grid>
						<Grid item xs={4} md={4}>
							<div style={{ textAlign: 'left' }}>
								<Switch
									checked={hasOptedOutofEmail}
									color='primary'
									onChange={this.handleUnsubscribeChange}
									name='hasOptedOutofEmail'
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							</div>
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
}

CommunicationPreferences.propTypes = {
	classes: PropTypes.shape({
		header: PropTypes.string.isRequired,
	}).isRequired,
	t: PropTypes.func.isRequired,
	tabHandler: PropTypes.func.isRequired,
	flags: PropTypes.shape({
		missingData: PropTypes.bool.isRequired,
		dataSaved: PropTypes.bool.isRequired,
		isDirty: PropTypes.bool.isRequired,
		showSaveChanges: PropTypes.bool.isRequired,
		isPendingSave: PropTypes.bool.isRequired,
		targetTab: PropTypes.number.isRequired,
	}).isRequired,
	updateFlags: PropTypes.func.isRequired,
	setSaveHandler: PropTypes.func.isRequired,
	externalId: PropTypes.number.isRequired,
};

export default withTranslation()(withStyles(styles)(CommunicationPreferences));
