/**
 * @file The AppRouter Component is what drives the applicaiton content
 * based on the users route. The component is a wrapper around React Router.
 */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import routes from './routeConfig';
import Home from '../pages/Home/Home';
import Error from '../pages/Error/Error';
import UserContext from '../contexts/userContext';
import TimedOutPage from '../pages/TimedOutPage/TimedOutPage';
import LogOutPage from '../pages/LogOutPage/LogOutPage';
import RegistrationPage from '../pages/RegistrationPage/RegistrationPage';
import VerificationPage from '../pages/VerificationPage/VerificationPage';
import Jobboard from '../pages/Jobboard/Jobboard';
import WorknSSOPage from '../pages/Workn/WorknSSOPage';
import msSalService from '../services/msSalService';

class AppRouter extends Component {
	constructor(props, context) {
		super(props);
		this.context = context;
	}

	render() {
		const { account } = this.context;
		const { verifiedDate } = this.context;
		const JobboardSignedIN = localStorage.getItem('LocalStorage_JobboardSignedIN');
		const EmailInvitation = localStorage.getItem('LocalStorage_EmailInvitation');
		const EmailInvitationToken = localStorage.getItem('LocalStorage_EmailInvitationToken');
		//const EmailInvitation = getLocalStorageWithExpiry('LocalStorage_EmailInvitation');

		const aWorkNSignedIN = localStorage.getItem('LocalStorage_WorkNSignedIN');
		const aWorkNFlow = localStorage.getItem('LocalStorage_WorkNFlow');

		//    appInsights.trackPageView({ uri: window.location });

		if (aWorkNFlow === 'true') {
			msSalService.setRedirectUri(`${window.location.origin}/#/sessiontimeout`);
			return (
				<Switch>
					<Route path='/jobboard' render={(props) => <Jobboard {...props} />} />
					<Route path='/worknsso' render={(props) => <WorknSSOPage {...props} />} />
					<Route
						path='/registration'
						render={(props) => <RegistrationPage {...props} />}
					/>
					{account ? (
						verifiedDate ? (
							JobboardSignedIN === 'true' ? (
								<Redirect exact from='/' to={{ pathname: '/jobboard' }} />
							) : (
								<Redirect
									exact
									from='/'
									to={{
										pathname: localStorage.getItem(
											'LocalStorage_WorkNSignedINPath'
										),
									}}
								/>
							)
						) : (
							<Route exact path='/' component={VerificationPage} />
						)
					) : (
						<Route exact path='/' component={Home} />
					)}
					{account && verifiedDate
						? routes.map((item) => (
								<PrivateRoute {...this.props} {...item} key={`${item.path}-route`} />
						  ))
						: account
						? routes.map((item) => <Route {...item} component={VerificationPage} />)
						: routes.map((item) => <Route {...item} component={Home} />)}
					<Route path='/logout' render={(props) => <LogOutPage {...props} />} />
					<Route path='/sessiontimeout' render={(props) => <TimedOutPage {...props} />} />
					<Route path='/500' render={(props) => <Error errorCode='500' {...props} />} />
					<Route path='/403' render={(props) => <Error errorCode='403' {...props} />} />
					<Route path='/503' render={(props) => <Error errorCode='503' {...props} />} />
					<Route path='*' render={(props) => <Error errorCode='404' {...props} />} />
				</Switch>
			);
		} else if (aWorkNSignedIN === 'true') {
			return (
				<Switch>
					<Route exact path='/' component={WorknSSOPage} />
					<Route path='/worknsso' render={(props) => <WorknSSOPage {...props} />} />
				</Switch>
			);
		} else if (EmailInvitation === 'true') {
			var JWTHelper = require('jwthelper');
			var helper = JWTHelper.createJWTHelper();
			var decodeEmailInvitationToken = helper.decode(EmailInvitationToken);
			var path = window.location.hash;
			console.log(path);
			// Always send this state to the reg page
			if (path.length > 2 && !path.includes('registration')) {
				return <RegistrationPage {...this.props} />;
			}

			//If the EmailInvitation is set to true the path can only be #/ or #/registration
			//if its not clear the cache and tell to rety in 5 minutes.
			if (decodeEmailInvitationToken !== null && decodeEmailInvitationToken.tfp) {
				console.log(
					"Used logged in with User Id and Password but not yet verified don't clear"
				);
			} else {
				msSalService.clearAuthInfo();
			}
			return (
				<Switch>
					<Route exact path='/' component={RegistrationPage} />
					<Route
						path='/registration'
						render={(props) => <RegistrationPage {...props} />}
					/>
				</Switch>
			);
		} else {
			return (
				<div>
					<Switch>
						<Route path='/jobboard' render={(props) => <Jobboard {...props} />} />
						<Route path='/worknsso' render={(props) => <WorknSSOPage {...props} />} />
						<Route
							path='/registration'
							render={(props) => <RegistrationPage {...props} />}
						/>
						{account ? (
							verifiedDate ? (
								JobboardSignedIN === 'true' ? (
									<Redirect exact from='/' to={{ pathname: '/jobboard' }} />
								) : (
									<Redirect exact from='/' to={{ pathname: '/dashboard' }} />
								)
							) : (
								<Route exact path='/' component={VerificationPage} />
							)
						) : (
							<Route exact path='/' component={Home} />
						)}
						{account && verifiedDate
							? routes.map((item) => (
									<PrivateRoute {...this.props} {...item} key={`${item.path}-route`} />
							  ))
							: account
							? routes.map((item) => <Route {...item} component={VerificationPage} />)
							: routes.map((item) => <Route {...item} component={Home} />)}
						<Route path='/logout' render={(props) => <LogOutPage {...props} />} />
						<Route
							path='/sessiontimeout'
							render={(props) => <TimedOutPage {...props} />}
						/>
						<Route
							path='/500'
							render={(props) => <Error errorCode='500' {...props} />}
						/>
						<Route
							path='/403'
							render={(props) => <Error errorCode='403' {...props} />}
						/>
						<Route
							path='/503'
							render={(props) => <Error errorCode='503' {...props} />}
						/>
						<Route path='*' render={(props) => <Error errorCode='404' {...props} />} />
					</Switch>
				</div>
			);
		}
	}
}
AppRouter.contextType = UserContext;
export default AppRouter;
