import React, { useEffect, useState, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, DOCUMENT_TYPES, DOCUMENT_FIELD_NAME } from '../../../utils/Application_Constants';
import { getNestedProperty } from '../../../utils/helpers';

const styles = makeStyles((theme) => ({
	menuItem: {
		fontSize: '13px',
		fontWeight: '600',
		color: COLORS.TRUE_BLUE,
		[theme.breakpoints.down('sm')]: {
			minHeight: '10px',
		},
	},
}));

export default function AssignmentDocumentActionMenu({
	viewAndSignButtonHandler,
	downloadtHandler,
	document,
	reloadDocuments,
}) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [delay, setDelay] = useState(30000); // 30 sec initially
	const [timerId, setTimerId] = useState(null);
	const [docStatusCheck, setDocStatusCheck] = useState(false);

	const open = Boolean(anchorEl);
	const classes = styles();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const viewAndSign = () => {
		setAnchorEl(null);
		viewAndSignButtonHandler(document);

		setDelay(2000);
		setDocStatusCheck(true);
	};

	const download = () => {
		setAnchorEl(null);
		downloadtHandler(document);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const downloadFlag =
		(getNestedProperty(document, DOCUMENT_FIELD_NAME.DocumentType) ===
			DOCUMENT_TYPES.ADDENDUM ||
			getNestedProperty(document, DOCUMENT_FIELD_NAME.DocumentType) ===
				DOCUMENT_TYPES.AGREEMENT) &&
		getNestedProperty(document, DOCUMENT_FIELD_NAME.Status) &&
		getNestedProperty(document, DOCUMENT_FIELD_NAME.Status) !== 'SIGNED'
			? true
			: false;

	const viewAndSignDocumentFlag = () => {
		let viewAndSignFlag;
		if (
			getNestedProperty(document, DOCUMENT_FIELD_NAME.Status) === 'OUT_FOR_SIGNATURE' &&
			getNestedProperty(document, DOCUMENT_FIELD_NAME.Email) &&
			getNestedProperty(document, DOCUMENT_FIELD_NAME.Agreement_ID)
		) {
			viewAndSignFlag = true;
		} else {
			viewAndSignFlag = false;
		}
		return viewAndSignFlag;
	};

	//Document Status Polling
	useInterval(async () => {
		if (docStatusCheck) {
			console.log(
				'docStatusCheck: Timer id: ' + timerId + ', delay at ' + delay + ' inteval'
			);
			reloadDocuments();

			setTimeout(() => {
				if (getNestedProperty(document, DOCUMENT_FIELD_NAME.Status) === 'SIGNED') {
					console.log('status is SIGNED, changing setting .......');
					setDocStatusCheck(false);
					setDelay(30000);
				}
			}, 3000);
		} else {
			setDelay(30000);
		}

		console.log(
			'Timer id: ' +
				timerId +
				', docCheckStatus:' +
				docStatusCheck +
				', delay: ' +
				delay +
				' inteval'
		);
	}, delay);

	function useInterval(callback, delay) {
		const savedCallback = useRef();

		// Remember the latest function.
		useEffect(() => {
			savedCallback.current = callback;
		}, [callback]);

		// Set up the interval.
		useEffect(() => {
			function tick() {
				savedCallback.current();
			}
			if (delay !== null) {
				let id = setInterval(tick, delay);
				setTimerId(id);
				console.log('TImeer ID' + timerId);
				return () => clearInterval(id);
			}
		}, [delay]);
	}

	return (
		<div>
			<IconButton
				aria-label='more'
				aria-controls='long-menu'
				aria-haspopup='true'
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id='long-menu'
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				PaperProps={{
					style: {
						maxHeight: '130px',
						width: '130px',
						boxShadow: 'none',
						border: '1px solid #d3d4d5',
						borderRadius: '0px',
					},
				}}
			>
				{(getNestedProperty(document, DOCUMENT_FIELD_NAME.DocumentType) ===
					DOCUMENT_TYPES.ADDENDUM ||
					getNestedProperty(document, DOCUMENT_FIELD_NAME.DocumentType) ===
						DOCUMENT_TYPES.AGREEMENT) && (
					<MenuItem
						key={1}
						className={classes.menuItem}
						onClick={viewAndSign}
						disabled={!viewAndSignDocumentFlag()}
					>
						View and Sign
					</MenuItem>
				)}

				{/* <MenuItem key={2} className={classes.menuItem} onClick={view} disabled={false}>
                    View
            </MenuItem> */}
				<MenuItem
					key={3}
					className={classes.menuItem}
					onClick={download}
					disabled={downloadFlag}
				>
					Download
				</MenuItem>
			</Menu>
		</div>
	);
}
