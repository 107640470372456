import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import msSalService from '../../services/msSalService';
import { verifyToken } from '../../services/LtonlineService';
import { parse_query_string } from '../../utils/helpers';
import xImg from '../../assets/images/email-denied.jpg';
import Logo from '../../assets/logo/locumtenens_com_logo.svg';

const styles = (theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		background: '#FFFFFF',
		fontFamily: 'sans-serif',
		color: '#757B92',
		fontSize: '1rem',
	},
	loginContainer: {
		textAlign: 'center',
		margin: '0 auto',
		padding: '70px',
		zIndex: '1',
	},
	divOne: {
		marginBottom: '50px',
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		marginTop: '70px',
	},
});

class WorknSSOPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirecturl: undefined,
			signedIn: undefined,
			signedInPath: undefined,
			result: undefined,
			isTokenValid: undefined,
			b2cLogin: undefined,
		};
	}

	async componentDidMount() {
		var aWorkNSignedIN = localStorage.getItem('LocalStorage_WorkNSignedIN');
		// var aWorkNSignedINPath = localStorage.getItem('LocalStorage_WorkNSignedINPath');

		if (aWorkNSignedIN === 'true') {
			localStorage.removeItem('LocalStorage_WorkNSignedIN');
			localStorage.setItem('LocalStorage_WorkNFlow', 'true');
			msSalService.signIn();
		} else if (aWorkNSignedIN === 'false' || aWorkNSignedIN === null) {
			//modify header of request to include these 2 params
			//ask WorkN of they can send in Header..
			var isTokenValid = await this.validateToken();

			if (!isTokenValid.includes('Error')) {
				console.log(
					'WorknSSOPage validateToken Success: Get B2C Token for clinicianId ' +
						isTokenValid
				);
				localStorage.setItem('LocalStorage_WorkNSignedIN', 'true');

				this.setState({
					isTokenValid: true,
					signedIn: false,
					b2cLogin: isTokenValid,
				});
			} else {
				this.setState({
					isTokenValid: false,
					signedIn: false,
				});
			}
		}
	}

	validateToken = async () => {
		var rtn = undefined;
		var query_string = window.location.hash.split('?')[1];
		if (query_string !== undefined) {
			var parsed_qs = parse_query_string(query_string);
			if (parsed_qs.token !== undefined && parsed_qs.target !== undefined) {
				switch (parsed_qs.target) {
					case 'assignments':
						// code block
						localStorage.setItem(
							'LocalStorage_WorkNSignedINPath',
							'/assignments/active'
						);
						break;
					case 'profile':
						// code block
						localStorage.setItem(
							'LocalStorage_WorkNSignedINPath',
							'/profile/preference'
						);
						break;
					case 'timeandpay':
						// code block
						localStorage.setItem('LocalStorage_WorkNSignedINPath', '/timeandpay');
						break;
					default:
						break;
				}

				var obj = {
					clientId: process.env.REACT_APP_LTONLINE_PORTAL_CLIENT_ID,
					clientSecret: process.env.REACT_APP_LTONLINE_PORTAL_CLIENT_SECRET,
					token: {
						accessToken: parsed_qs.token,
					},
				};
				var aToken = JSON.stringify(obj);
				rtn = await verifyToken(aToken);

				if (rtn instanceof Error) {
					console.log('WOrknSSOPage validateToken Error: ' + rtn.message);
					rtn = rtn.message;
				} else {
					console.log(
						'WOrknSSOPage validateToken Success: clinicianId ' + rtn.clinicianId
					);
					rtn = rtn.accessURL;
				}
			}
		}
		return rtn;
	};

	render() {
		const { classes } = this.props;

		if (this.state.isTokenValid === false) {
			return (
				<div className={classes.container}>
					<div id='login-container' className={classes.loginContainer}>
						<img src={Logo} className={classes.image} alt='LocumTenens Logo' />
						<img src={xImg} className={classes.image} alt='X mark' />
						<h2>Token expired</h2>
						<div className={classes.divOne}>
							<p>Token expired.</p>
						</div>
					</div>
				</div>
			);
		} else if (this.state.isTokenValid === true) {
			window.location = this.state.b2cLogin;
			return <div className={classes.container}></div>;
		} else {
			return <div className={classes.container}></div>;
		}
	}
}

export default withTranslation()(withStyles(styles)(WorknSSOPage));
