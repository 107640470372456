import React from 'react';
import { Grid, Paper, Button, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import { ltdomain } from '../../../utils/helpers';

const styles = makeStyles((theme) => ({
	workPaper: {
		backgroundColor: COLORS.LT_MIDNIGHT,
		padding: '2rem',
		color: 'white',
	},
	header: {
		color: COLORS.LT_SUNFLOWER,
	},
	button: {
		marginBottom: '20px',
		marginLeft: '20px',
		justifyContent: 'center',
	},
}));

function LetUsDoTheWorkTile({ t }) {
	const classes = styles();

	return (
		<>
			<Paper className={classes.workPaper}>
				<Grid container spacing={4}>
					<Grid item xs={12} md={3} lg={2}>
						<h2 className={classes.header}>
							{t(`dashboard:HEADERS.LET_US_DO_THE_WORK`)}
						</h2>
					</Grid>
					<Grid item xs={12} md={9} lg={10}>
						<h4 className='cr-white'>
							{t(`dashboard:INFO.LET_US_DO_THE_WORK_CONTENT1`)}
						</h4>
						<p>{t(`dashboard:INFO.LET_US_DO_THE_WORK_CONTENT2`)}</p>

						<Button
							variant='contained'
							color='primary'
							disableElevation
							href={ltdomain() + '/contact-a-recruiter/'}
						>
							{t(`dashboard:BUTTONS.LET_US_DO_THE_WORK_BUTTON`)}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
}

export default LetUsDoTheWorkTile;
