import React from 'react';
import AdminNavbar from './AdminNavbar';
import AdminAppRouter from '../Router/AdminAppRouter';
import { withStyles } from '@material-ui/core';
import Banner from '../../components/HeaderComponents/Banner/Banner';
import Footer from '../../Shared/Components/Footer';
import { COLORS } from '../../utils/Application_Constants';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '800px',
	},
	pageRoot: {
		display: 'flex',
		flexDirection: 'column',
		background: COLORS.LT_MIDNIGHTBG1,
		minHeight: 'calc(100vh - 210px)',
		[theme.breakpoints.up('md')]: {
			minHeight: 'calc(100vh - 230px)',
		},
	},
	isLoadingRoot: {
		height: '100vh',
	},
});

const AdminLayout = (props) => {
	const { classes } = props;

	return (
		<div className={classes.root}>
			<AdminNavbar />
			<Banner pageName='' />
			<div className={classes.pageRoot}>
				<AdminAppRouter />
			</div>
			<Footer />
		</div>
	);
};

export default withStyles(styles)(AdminLayout);
