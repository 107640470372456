import React, { Component } from 'react';
import {
	withStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableContainer,
	Hidden,
	TableHead,
	IconButton,
	Chip,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { COLORS } from '../../../utils/Application_Constants';

const styles = (theme) => ({
	contentRoot: {
		height: '100vh',
	},
	dialogHeaderRoot: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE_LILAC,
		justifyContent: 'left',
	},
	dialogContentRoot: {
		padding: '0',
		margin: '24px',
		borderRadius: '5px',
		color: `${COLORS.RAVEN}`,
	},
	buttonRoot: {
		lineHeight: 'inherit',
		color: `${COLORS.TRUE_BLUE}`,
		backgroundColor: `${COLORS.WHITE}`,
		border: `1px solid ${COLORS.TRUE_BLUE}`,
		margin: '0 15px 15px',
		width: '200px',
		textTransform: 'inherit',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	timeOutHeader: {
		fontWeight: '500',
		opacity: '0.5',
	},
	tableContainerRoot: {
		width: '98%',
		margin: '0 auto',
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		marginTop: '15px',
	},
	tableRowHeaderFont: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		padding: '16px',
		fontSize: '16px',
	},
	cellFont: {
		fontWeight: '300',
		fontSize: '16px',
		color: COLORS.RAVEN,
	},
	fileDetailWrapper: {
		display: 'flex',
		flexDirection: 'column',
	},
	mobileDocumentCard: {
		margin: '0 10px',
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		padding: '25px 16px',
		textAlign: 'left',
	},
	mobileLabelContentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		padding: '11px 0 11px 42px',
	},
	mobileTitle: {
		fontWeight: '900',
		textTransform: 'uppercase',
		fontSize: '12px',
		color: COLORS.RAVEN,
	},
	fileNameBlockWrapper: {
		display: 'flex',
	},
	clickableLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	textInherit: {
		textTransform: 'initial',
		[theme.breakpoints.down('sm')]: {
			width: 'auto',
		},
	},
	mobileButtonRoot: {
		padding: 0,
		width: '55px',
		display: 'flex',
		justifyContent: 'left',
	},
	closeIconButtonRoot: {
		color: `${COLORS.WHITE_LILAC}`,
		position: 'absolute',
		right: '0',
		top: '7px',
	},
	chipRoot: {
		border: `1px solid ${COLORS.ASTRAL}`,
		color: COLORS.ASTRAL,
		backgroundColor: COLORS.MYSTIC,
		margin: '10px 0 10px 10px ',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 10px 10px 0',
		},
	},
	chipLabel: {
		fontWeight: '300',
		fontSize: '14px',
	},
});

class AssignmentShiftList extends Component {
	renderDesktopTable() {
		const { classes, shifts, t } = this.props;
		return (
			<TableContainer classes={{ root: classes.tableContainerRoot }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell classes={{ root: classes.tableRowHeaderFont }}>
								{t('assignments:SHIFT_DIALOG.DATE')}
							</TableCell>
							<TableCell classes={{ root: classes.tableRowHeaderFont }}>
								{t('assignments:SHIFT_DIALOG.SHIFT')}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.entries(shifts).map((item) => (
							<TableRow>
								{item[1].map((dateObject) => (
									<>
										<TableCell classes={{ root: classes.cellFont }}>
											{`${moment(dateObject.startDateTime).tz(dateObject.timeZoneId).format('MMM D, YYYY')}`}
										</TableCell>
										<TableCell classes={{ root: classes.cellFont }}>
											<Chip
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												label={`${moment(dateObject.startDateTime)
													.tz(dateObject.timeZoneId)
													.format('h:mm a ')} - ${moment(dateObject.endDateTime)
													.tz(dateObject.timeZoneId)
													.format('h:mm a ')}`}
											/>
										</TableCell>
									</>
								))}
 							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}

	renderMobileCards() {
		const { classes, t, shifts } = this.props;
		return Object.entries(shifts).map((item) => (
			<div className={classes.mobileDocumentCard}>
				{item[1].map((dateObject) => (
					<>
						<div className={classes.mobileLabelContentWrapper}>
							<span className={classes.mobileTitle}>
								{t('assignments:SHIFT_DIALOG.DATE')}
							</span>
							<span className={classes.cellFont}>
								{moment(dateObject.startDateTime).tz(dateObject.timeZoneId).format('MMM D, YYYY')}
							</span>
						</div>
						<div className={classes.mobileLabelContentWrapper}>
							<span className={classes.mobileTitle}>
								{t('assignments:SHIFT_DIALOG.SHIFT')}
							</span>
							<div>
								<Chip
									classes={{ root: classes.chipRoot, label: classes.chipLabel }}
									label={`${moment(dateObject.startDateTime)
										.tz(dateObject.timeZoneId)
										.format('h:mm a ')} - ${moment(dateObject.endDateTime)
										.tz(dateObject.timeZoneId)
										.format('h:mm a ')}`}
								/>
							</div>
						</div>
					</>
				))}
			</div>
		));
	}

	render() {
		const { classes, t, toggleHandler } = this.props;
		return (
			<div className={classes.contentRoot}>
				<Dialog open fullWidth onBackdropClick={() => toggleHandler(false)} scroll='body'>
					<IconButton
						onClick={() => toggleHandler(false)}
						classes={{ root: classes.closeIconButtonRoot }}
					>
						<Close />
					</IconButton>
					<DialogTitle classes={{ root: classes.dialogHeaderRoot }}>
						{t('assignments:SHIFT_DIALOG.TITLE')}
					</DialogTitle>
					<DialogContent classes={{ root: classes.dialogContentRoot }}>
						<Hidden only={['xs', 'sm']}>{this.renderDesktopTable()}</Hidden>
						<Hidden only={['md', 'lg', 'xl']}>{this.renderMobileCards()}</Hidden>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => toggleHandler(false)}
							classes={{ root: classes.buttonRoot }}
						>
							{t('common:BUTTONS.CLOSE')}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(AssignmentShiftList));
