import React, { Component } from 'react';
import FeatureFlag from '../../components/GlobalComponents/FeatureFlagComponent/FeatureFlag';
import TimeAndPay from './TimeAndPay';
import TimeAndPayV2 from './TimeAndPayV2';

class TimeAndPayWrapper extends Component {
	render() {
		return (
			<>
				<FeatureFlag name='ShowRewiredNextPayDate' isFlagEnabled='true'>
					<TimeAndPayV2 />
				</FeatureFlag>
				<FeatureFlag name='ShowRewiredNextPayDate' isFlagEnabled='false'>
					<TimeAndPay />
				</FeatureFlag>
			</>
		);
	}
}

export default TimeAndPayWrapper;
