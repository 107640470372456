/**
 * @file UTCUTils
 * @description When the current date picker is using the moment utils,
 * it automatically defaults to the users locale, we do not want this,
 * this extends the momentutils and forces the datepicker to be relative
 * to the UTC anchor. Note, we still have to make sure when we format a date
 * as the value we set it to UTC.
 */
import MomentUtils from '@date-io/moment';
import moment from 'moment';

class UtcUtils extends MomentUtils {
	format(value, formatString) {
		return moment(value).utc().format(formatString);
	}
}

export default UtcUtils;
