import React from 'react';
import { makeStyles, InputAdornment, Typography } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	startAdornment: {
		paddingLeft: '12px',
	},
}));

const BookingNumberInputAdornment = (props) => {
	const classes = styles();

	return (
		<InputAdornment position='start'>
			<Typography variant='body2' className={classes.startAdornment}>
				BKG-
			</Typography>
		</InputAdornment>
	);
};

export default BookingNumberInputAdornment;
