import React from 'react';
import DesktopNavigationV1 from './DesktopNavigationV1';
import DesktopNavigationV2 from './DesktopNavigationV2';
import FeatureFlag from '../../GlobalComponents/FeatureFlagComponent/FeatureFlag';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
const styles = (theme) => ({});

function DesktopNavigation(props) {
	return (
		<>
			<FeatureFlag name='AxuallCredentialing' isFlagEnabled='True'>
				<DesktopNavigationV2 {...props} />
			</FeatureFlag>

			<FeatureFlag name='AxuallCredentialing' isFlagEnabled='False'>
				<DesktopNavigationV1 {...props} />
			</FeatureFlag>
		</>
	);
}

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(DesktopNavigation))));