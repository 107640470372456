/**
 * @file The AppRouter Component is what drives the applicaiton content
 * based on the users route. The component is a wrapper around React Router.
 */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error from '../../pages/Error/Error';
import adminRoutes from './AdminRouteConfig';
import { CircularProgress } from '@material-ui/core';
import LoginCallback from '../Components/LoginCallback';
import SecureRoute from '../Components/SecureRoute';

const AdminAppRouter = (props) => {
	return (
		<Switch>
			{adminRoutes.map((item) => (
				<SecureRoute exact {...item} key={`${item.path}-route`} />
			))}
			<Route
				path='/'
				exact
				render={(props) => (
					<LoginCallback
						homePath='/login/callback'
						loadingElement={
							<CircularProgress style={{ margin: 'auto' }} color='primary' />
						}
					/>
				)}
			/>
			<Route exact path='/500' render={(props) => <Error errorCode='500' {...props} />} />
			<Route exact path='/403' render={(props) => <Error errorCode='403' {...props} />} />
			<Route exact path='/503' render={(props) => <Error errorCode='503' {...props} />} />
			<Route exact path='*' render={(props) => <Error errorCode='404' {...props} />} />
		</Switch>
	);
};
export default AdminAppRouter;
