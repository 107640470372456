import { axiosWCApiInstance } from '../configuration/axios/axiosConfiguration';
import { ApiError } from '../utils/ApiError';

/**
 * Gets upcoming assignments from salesforce
 */
async function getPresents(externalId, mainPresentsPage = false) {
	let response;
	try {
		response = await axiosWCApiInstance.get(`clinicians/${externalId}/Presents`);
	} catch (error) {
		console.log('GetClinicianPresent error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'GetClinicianPresent'
			);
		}
		//return error.message;
	}
	return response.data;
}

export { getPresents };
