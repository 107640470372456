import React, { Component } from 'react';
import {
	withStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import DialogContactCard from '../DialogContactCard/DialogContactCard';

const styles = (theme) => ({
	facilityWrapper: {
		paddingTop: '18px',
	},
	widgetHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '18px',
		paddingLeft: '20px',
		textAlign: 'left',
	},
	contactsHeaderContainer: {
		width: '90%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	paperRoot: {
		[theme.breakpoints.down('sm')]: {
			marginTop: '20px',
		},
		boxShadow: '0px 3px 6px #00000008',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	dialogHeaderRoot: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE_LILAC,
		justifyContent: 'left',
	},
	dialogContentRoot: {
		padding: '0',
		margin: '24px',
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		borderRadius: '5px',
	},
	buttonRoot: {
		lineHeight: 'inherit',
		color: `${COLORS.TRUE_BLUE}`,
		backgroundColor: `${COLORS.WHITE}`,
		border: `1px solid ${COLORS.TRUE_BLUE}`,
		margin: '0 15px 15px',
		width: '200px',
		textTransform: 'inherit',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	viewAllContactsWrapper: {
		textTransform: 'inherit',
		marginBottom: '10px',
		padding: '0',
	},
});

class FacilityContactsViewDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	openDialog = () => {
		this.setState({ open: true });
	};

	closeDialog = () => {
		this.setState({ open: false });
	};

	get areContactsEmpty() {
		const { contacts } = this.props;
		if (!contacts) {
			return true;
		}
		return contacts.length === 0;
	}

	render() {
		const { contacts, classes, facilityId, updateAssignments, t, primaryWorkLocation } =
			this.props;
		const state = { ...this.state };
		return (
			<>
				<Button
					classes={{
						root: `${classes.viewAllContactsWrapper}`,
					}}
					onClick={() => {
						this.openDialog();
					}}
					component='span'
					disabled={this.areContactsEmpty}
				>
					<span
						className={
							this.areContactsEmpty
								? `${classes.disabledClickableSpan}`
								: `${classes.clickableSpan}`
						}
					>
						{t('common:BUTTONS.VIEW_ALL')}
					</span>
				</Button>
				<Dialog
					open={state.open}
					onClose={this.closeDialog.bind(this)}
					fullWidth
					scroll='body'
				>
					<DialogTitle classes={{ root: classes.dialogHeaderRoot }}>
						{t('contacts:FACILITY_CONTACTS')}
					</DialogTitle>
					<DialogContent classes={{ root: classes.dialogContentRoot }}>
						{contacts &&
							contacts.map((item, index) => (
								<DialogContactCard
									key={`dialogcard-${index + 1}`}
									contact={item}
									facilityId={facilityId}
									primaryWorkLocation={primaryWorkLocation}
									updateAssignments={updateAssignments}
									isLast={index === contacts.length - 1}
								/>
							))}
					</DialogContent>
					<DialogActions>
						<Button
							classes={{ root: classes.buttonRoot }}
							onClick={this.closeDialog.bind(this)}
						>
							{t('common:BUTTONS.CLOSE')}
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

export default withTranslation()(withStyles(styles)(FacilityContactsViewDialog));
