import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const ExpenseTab = withStyles((theme) => ({
	root: {
		background: 'white',
		borderTopRightRadius: 8,
		borderTopLeftRadius: 8,
		flexShrink: 1,
		minWidth: 0,
		minHeight: 0,

		'&$selected': {
			flexShrink: 0,
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default ExpenseTab;
