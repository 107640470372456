import { COLORS } from '../../../utils/Application_Constants';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';

const styles = (theme) => ({
	text: {
		color: COLORS.DENIM,
		fontWeight: '700',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
		},
	},
});

class AlertDialog extends Component {
	constructor(props, context) {
		super(props);
	}

	handleClose = () => {
		this.props.onClose();
	};

	render = () => {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.open}
				onClose={this.handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				className={classes.text}
			>
				{this.props.title !== '' ? <DialogTitle>{this.props.title}</DialogTitle> : ''}
				<DialogContent>
					<DialogContentText>{this.props.text}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color='primary'>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	};
}

AlertDialog.propTypes = {
	classes: PropTypes.shape({
		text: PropTypes.string.isRequired,
	}).isRequired,
	onClose: PropTypes.shape(PropTypes.func).isRequired,
	text: PropTypes.shape(PropTypes.string).isRequired,
	title: PropTypes.string,
};

export default withStyles(styles)(AlertDialog);
