import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import AssignmentListItem from '../AssignmentListItem/AssignmentListItem';
import AssignmentListEmptyState from '../AssignmentListItem/AssignmentListEmptyState';

const styles = (theme) => ({
	header: {
		color: COLORS.WATERLOO,
		fontWeight: '700',
		fontSize: '18px',
		width: '90%',
		padding: '18px 0 0 20px',
		textAlign: 'left',
	},
	assignmentListPaperWrapper: {
		// display: 'flex',
		// justifyContent: 'center',
		// flexWrap: 'wrap',
	},
});

class ActiveAssignmentList extends Component {
	renderAssignments = () => {
		const { assignments, toggleAssignmentView } = this.props;
		if (!assignments || assignments.length === 0) {
			return (
				<div style={{ width: '100%' }}>
					<AssignmentListEmptyState assignmentType='active' />
				</div>
			);
		}
		return (
			<Grid container justify='center'>
				{assignments.map((item) => (
					<AssignmentListItem
						key={item.name}
						assignment={item}
						toggleAssignmentView={toggleAssignmentView}
						type='active'
					/>
				))}
			</Grid>
		);
	};

	renderAssignmentTitleConditonally = () => {
		const { assignments } = this.props;
		if (!assignments || assignments.length === 0) {
			return <span className='tileHeader'>Active Assignmentsss</span>;
		}
		return (
			<span className='tileHeader'>
				Active Assignments(
				{assignments.length})
			</span>
		);
	};

	render() {
		return (
			<div>
				{/* {this.renderAssignmentTitleConditonally()} */}
				<Grid container>{this.renderAssignments()}</Grid>
			</div>
		);
	}
}

export default withStyles(styles)(ActiveAssignmentList);
