const baseURL = process.env.REACT_APP_WC_API_BASE_URI;
const key = process.env.REACT_APP_WC_API_SUBSCRIPTION_KEY;

export const validateToken = async (guidKey) => {
	try {
		const options = {
			method: 'GET',
			headers: {
				'LT-Roles': 'ClientApprover',
				'Ocp-Apim-Subscription-Key': key,
			},
		};
		const url = `${baseURL}clientApproval/tokens/${guidKey}`;
		const response = await fetch(url, options);
		if (response.ok) return await response.json();
		throw `Invalid token`;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const generateToken = async (email) => {
	try {
		const options = {
			method: 'POST',
			headers: {
				'LT-Roles': 'ClientApprover',
				'Ocp-Apim-Subscription-Key': key,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }),
		};
		const url = `${baseURL}clientApproval/tokens`;
		const response = await fetch(url, options);
		if (!response.ok) throw response.statusText;
		return 'ok';
	} catch (error) {
		console.error(error);
		throw error;
	}
};
