import { Box, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	status: {
		width: 12,
		height: 12,
		borderRadius: 100,
		borderWidth: 1,
		borderStyle: 'solid',
		background: COLORS.LT_MIDNIGHT10,
		borderColor: COLORS.LT_MIDNIGHT50,
	},
	optionPrimary: {
		background: COLORS.LT_JOURNEY10,
		borderColor: COLORS.LT_JOURNEY,
	},
	optionSuccess: {
		background: COLORS.LT_EMERALD10,
		borderColor: COLORS.LT_EMERALD,
	},
	optionError: {
		background: COLORS.LT_PEPPER10,
		borderColor: COLORS.LT_PEPPER,
	},
	optionWarning: {
		background: COLORS.LT_SUNFLOWER10,
		borderColor: '#AD7829',
	},
}));

const statusColors = {
	InProcess: 'Primary',
	ReadyForReview: 'Primary',
	Rejected: 'Error',
	OnHoldMatchPending: 'Warning',
	OnHoldPendingAirfare: 'Warning',
	OnHoldAirlineCreditOnFile: 'Warning',
	OnHoldDisputed: 'Warning',
	OnHoldAdvancedDeposit: 'Warning',
	OnHoldReimbursement: 'Warning',
	Abandoned: 'Error',
	Matched: 'Success',
	Approved: 'Success',
	Posted: 'Success',
};

const StatusOption = (props) => {
	const { status, t } = props;
	const classes = useStyles();
	const color = useMemo(() => statusColors[status], [status]);
	return (
		<Box display={'flex'} gridGap={8} alignItems='center'>
			<div
				className={`${classes.status} ${classes[`option${color ? color : 'Primary'}`]}`}
			></div>
			<div>{t(`expenseStatuses:${status}`)}</div>
		</Box>
	);
};

export default withTranslation()(StatusOption);
