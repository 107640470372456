import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, Tab, Tabs, Container, CircularProgress } from '@material-ui/core';
import ActiveAssignments from '../../components/ContentComponents/ActiveAssignments/ActiveAssignments';
import UpcomingAssignments from '../../components/ContentComponents/UpcomingAssignments/UpcomingAssignments';
import PastAssignments from '../../components/ContentComponents/PastAssignments/PastAssignments';
import ActiveAssignmentsYourTeam from '../../components/ContentComponents/ActiveAssignments/ActiveAssignmentsYourTeam';
import UpcomingAssignmentsYourTeam from '../../components/ContentComponents/UpcomingAssignments/UpcomingAssignmentsYourTeam';
import PresentsList from '../../components/ContentComponents/PresentsList/PresentsList';
import { COLORS } from '../../utils/Application_Constants';
import WithContexts from '../../contexts/withContexts';
import ErrorDialog from '../../components/GlobalComponents/Dialog/ErrorDialog';
import FeatureFlag from '../../components/GlobalComponents/FeatureFlagComponent/FeatureFlag';

const styles = (theme) => ({
	root: {
		backgroundColor: COLORS.BLACK_HAZE,
		minHeight: '800px',
		paddingBottom: '25px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '32px',
		},
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		marginTop: '25vh',
	},
	indicator: {
		backgroundColor: COLORS.DENIM,
		opacity: '1',
	},
	tabsRoot: {
		color: COLORS.SLATE_GRAY,
		paddingBottom: '25px',
		[theme.breakpoints.up('md')]: {
			padding: '26px 88px',
		},
	},
	Tab_Root: {
		minWidth: '95px',
	},
	scrollableVariant: {
		'-ms-overflow-style': 'none',
	},
});

class Assignments extends Component {
	constructor(props) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName('Assignments');
		this.state = {
			currentlyActiveTab: 0,
			isLoading: true,
			url: {
				location: 'active',
				id: null,
			},
			flags: {
				isLoading: false,
				dataSaved: false,
				isDirty: false,
				showSaveChanges: false,
				isPendingSave: false,
				targetTab: 0,
				validationError: false,
				navDisabled: false,
				apiError: '',
				errorMessage: '',
			},
		};
	}

	setAssignmentPageError = (e) => {
		const { flags } = { ...this.state };
		if (e.hasOwnProperty('title') && e.hasOwnProperty('message')) {
			flags.apiError = e;
		} else {
			flags.errorMessage = 'Error loading page';
		}
		flags.isLoading = false;
		flags.isPendingSave = false;
		this.setState({ flags: flags });
	};

	closeError = () => {
		const state = { ...this.state };
		state.flags.apiError = null;
		this.setState({ ...state });
	};

	async componentDidMount() {
		await this.getUrlParams();
		await this.setTabState();
	}

	async componentDidUpdate(prevProps) {
		const { match } = this.props;
		if (prevProps.match.params.status !== match.params.status) {
			await this.getUrlParams();
			this.setState({
				currentlyActiveTab: this.getLocationIndexByStatus(match.params.status),
			});
		}
	}

	getUrlParams() {
		const { match } = this.props;
		this.setState({ url: { ...match.params } });
	}

	getLocationByIndex(currentIndex) {
		switch (currentIndex) {
			case 0:
				return 'active';
			case 1:
				return 'upcoming';
			case 2:
				return 'past';
			case 3:
				return 'presents';
			default:
				return 'active';
		}
	}

	setTabState = async () => {
		const { url } = this.state;
		await this.setState({
			currentlyActiveTab: this.getLocationIndexByStatus(url.status),
			isLoading: false,
		});
	};

	getLocationIndexByStatus(status) {
		switch (status) {
			case 'active':
				return 0;
			case 'upcoming':
				return 1;
			case 'past':
				return 2;
			case 'presents':
				return 3;
			default:
				return 0;
		}
	}

	tabChanged = async (event, newValue) => {
		const { history } = this.props;
		history.push(`/assignments/${this.getLocationByIndex(newValue)}`);
		await this.setState({ currentlyActiveTab: newValue });
	};

	tabRender = (currentIndex) => {
		switch (currentIndex) {
			case 0:
				return (
					<>
						<FeatureFlag name='ShowYourTeam' isFlagEnabled='true'>
							<ActiveAssignmentsYourTeam
								setAssignmentPageError={this.setAssignmentPageError}
							/>
						</FeatureFlag>
						<FeatureFlag name='ShowYourTeam' isFlagEnabled='false'>
							<ActiveAssignments
								setAssignmentPageError={this.setAssignmentPageError}
							/>
						</FeatureFlag>
					</>
				);
			case 1:
				return (
					<>
						<FeatureFlag name='ShowYourTeam' isFlagEnabled='true'>
							<UpcomingAssignmentsYourTeam
								setAssignmentPageError={this.setAssignmentPageError}
							/>
						</FeatureFlag>
						<FeatureFlag name='ShowYourTeam' isFlagEnabled='false'>
							<UpcomingAssignments
								setAssignmentPageError={this.setAssignmentPageError}
							/>
						</FeatureFlag>
					</>
				);
			case 2:
				return (
					<>
						<PastAssignments setAssignmentPageError={this.setAssignmentPageError} />
					</>
				);
			case 3:
				return <PresentsList setAssignmentPageError={this.setAssignmentPageError} />;
			default:
				return (
					<>
						<FeatureFlag name='ShowYourTeam' isFlagEnabled='true'>
							<ActiveAssignmentsYourTeam
								setAssignmentPageError={this.setAssignmentPageError}
							/>
						</FeatureFlag>
						<FeatureFlag name='ShowYourTeam' isFlagEnabled='false'>
							<ActiveAssignments
								setAssignmentPageError={this.setAssignmentPageError}
							/>
						</FeatureFlag>
					</>
				);
		}
	};

	render() {
		const { classes } = this.props;
		const { currentlyActiveTab, isLoading, flags } = this.state;
		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}
		return (
			<div className={classes.root}>
				<ErrorDialog
					open={!!flags.apiError}
					onClose={this.closeError}
					title={!flags.apiError ? null : flags.apiError.title}
					description={!flags.apiError ? null : flags.apiError.message}
					errorId={!flags.apiError ? null : flags.apiError.errorId}
				/>
				<Container maxWidth='xl'>
					<Tabs
						classes={{
							indicator: classes.indicator,
							root: classes.tabsRoot,
							scrollable: classes.scrollableVariant,
						}}
						value={currentlyActiveTab}
						onChange={this.tabChanged}
						scrollButtons='auto'
						variant='scrollable'
					>
						<Tab label='Active Assignments' />
						<Tab label='Upcoming Assignments' />
						<Tab label='Past Assignments' />
						<Tab classes={{ root: classes.Tab_Root }} label='Presents' />
					</Tabs>
					{this.tabRender(currentlyActiveTab)}
				</Container>
			</div>
		);
	}
}

export default WithContexts(withRouter(withStyles(styles)(Assignments)));
