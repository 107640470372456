import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {
	availabilityOptions,
	assignmentTypeOptions,
	shiftTypeOptions,
	workInterestsOptions,
} from './preferencesOptions';
import { states } from '../Account/accountOptions';
import { COLORS } from '../../../utils/Application_Constants';
import PreferencesTravelComponent from '../PreferencesTravelComponent/PreferencesTravelComponent';
import {
	getClinicianTravelPreferences,
	postClinicianTravelPreferences,
} from '../../../services/ProfileServices/profileService';

const styles = (theme) => ({
	header: {
		color: COLORS.ST_TROPAZ,
		textAlign: 'left',
		paddingTop: '36px',
		fontSize: '17px',
		fontWeight: '700',
	},
	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '12px',
	},
	progress: {
		height: '100vh',
	},
	paperRoot: {
		paddingBottom: '60px',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			padding: '0 32px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '5px 10px',
		},
	},
	setWidthTo100: {
		width: '100%',
	},

	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.RAVEN,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	disabledInput: {
		opacity: '0.6',
	},
});

class TravelPreferences extends Component {
	constructor(props) {
		super(props);
		this.availabilityOptions = availabilityOptions;
		this.assignmentTypeOptions = assignmentTypeOptions;
		this.shiftTypeOptions = shiftTypeOptions;
		this.workInterestsOptions = workInterestsOptions;
		this.states = states;
		this.state = {
			clinicianPreferences: {},
			workInterestArray: [],
			emailSubscribeflag: false,
		};
	}

	async componentDidMount() {
		const { externalId, setSaveHandler, setPreferencePageError } = this.props;
		const state = { ...this.state };
		state.clinicianPreferences = await getClinicianTravelPreferences(externalId).catch((e) => {
			setPreferencePageError(e);
		});
		this.setState({ clinicianPreferences: state.clinicianPreferences });
		this.shouldDisableSave();
		setSaveHandler(this, this.doSubmit);
	}

	async doSubmit() {
		// Validate required fields
		const state = { ...this.state };
		const flags = this.props.flags;
		const { externalId, setPreferencePageError } = this.props;
		const missingAnyFields = false;
		flags.missingData = missingAnyFields;
		if (!missingAnyFields) {
			flags.isPendingSave = true;
			this.props.updateFlags(flags);
			await this.validatePreferences();
			await postClinicianTravelPreferences(externalId, state.clinicianPreferences).catch(
				(e) => {
					setPreferencePageError(e);
				}
			);
			flags.dataSaved = true;
			flags.isDirty = false;
			flags.isPendingSave = false;
			this.props.updateFlags(flags);
		}
	}

	shouldDisableSave = () => {
		const flags = this.props.flags;
		const state = { ...this.state };
		const tooManyCharacters =
			(!!state.clinicianPreferences.legalFirstName &&
				state.clinicianPreferences.legalFirstName.length > 50) ||
			(!!state.clinicianPreferences.legalMiddleInitial &&
				state.clinicianPreferences.legalMiddleInitial.length > 1) ||
			(!!state.clinicianPreferences.legalLastName &&
				state.clinicianPreferences.legalLastName.length > 50) ||
			this.loopPreferencesLengthCheck('airlinePreferences', 'knownTravellerNumber', 20) ||
			this.loopPreferencesLengthCheck('airlinePreferences', 'frequentFlyerNumber', 20) ||
			this.loopPreferencesLengthCheck('rentalServices', 'membershipNumber', 20) ||
			this.loopPreferencesLengthCheck('hotels', 'hotelName', 50) ||
			this.loopPreferencesLengthCheck('hotels', 'membershipNumber', 20) ||
			(!!state.clinicianPreferences.petType &&
				state.clinicianPreferences.petType.length > 100) ||
			(!!state.clinicianPreferences.petBreed &&
				state.clinicianPreferences.petBreed.length > 100) ||
			(!!state.clinicianPreferences.petWeight &&
				state.clinicianPreferences.petWeight.length > 20);
		flags.isCharacterLimit = tooManyCharacters;
		this.props.updateFlags(flags);
	};

	loopPreferencesLengthCheck = (travelGroup, field, limit) => {
		const state = { ...this.state };
		for (let i = 0; i < state.clinicianPreferences[travelGroup].length; i++) {
			if (
				!!state.clinicianPreferences[travelGroup][i][field] &&
				state.clinicianPreferences[travelGroup][i][field].length > limit
			) {
				return true;
			}
		}
		return false;
	};

	async validatePreferences() {
		await this.airlinePreferenceValidator();
		await this.hotelPreferenceValidator();
	}

	async airlinePreferenceValidator() {
		const state = { ...this.state };
		if (state.clinicianPreferences.airlinePreferences.length > 0) {
			let ktn = state.clinicianPreferences.airlinePreferences[0].knownTravellerNumber;
			let expDate = state.clinicianPreferences.airlinePreferences[0].expirationDate;
			let seatPref = state.clinicianPreferences.airlinePreferences[0].seatPreference;
			state.clinicianPreferences.airlinePreferences.forEach((pref) => {
				if (pref.knownTravellerNumber === '' || pref.knownTravellerNumber === null) {
					pref.knownTravellerNumber = ktn;
				}
				if (pref.expirationDate === '' || pref.expirationDate === null) {
					pref.expirationDate = expDate;
				}
				if (pref.seatPreference === '' || pref.seatPreference === null) {
					pref.seatPreference = seatPref;
				}
			});
			await this.setState({ clinicianPreferences: state.clinicianPreferences });
		}
		if (state.clinicianPreferences.airlinePreferences.length > 3) {
			const newPreferences = state.clinicianPreferences.airlinePreferences.filter(
				(item) => item.airlineName
			);
			state.clinicianPreferences.airlinePreferences = newPreferences;
			this.shouldDisableSave();
			await this.setState({ clinicianPreferences: state.clinicianPreferences });
		}
	}

	async hotelPreferenceValidator() {
		const state = { ...this.state };
		if (state.clinicianPreferences.hotels.length > 3) {
			const newPreferences = state.clinicianPreferences.hotels.filter(
				(item) => item.hotelName
			);
			state.clinicianPreferences.hotels = newPreferences;
			this.shouldDisableSave();
			await this.setState({ clinicianPreferences: state.clinicianPreferences });
		}
	}

	markFlagsDirty = () => {
		const flags = this.props.flags;
		flags.isDirty = true;
		flags.dataSaved = false;
		this.props.updateFlags(flags);
	};

	airlineConstructor = () => ({
		isPreferred: false,
		airlineName: '',
		frequentFlyerNumber: '',
		knownTravellerNumber: '',
		seatPreference: '',
		expirationDate: '',
	});

	carRentalCompanyConstructor = () => ({
		isPreferred: false,
		membershipNumber: '',
		rentalCompany: '',
		otherCarMembershipNumber: '',
	});

	hotelConstructor = () => ({
		isPreferred: false,
		hotelName: '',
		membershipNumber: '',
	});

	getWorkInterestLabelFromValue = (value) => {
		let label;
		Object.entries(this.workInterestsOptions).forEach((item) => {
			if (item[1].value === value) {
				label = item[1].label;
			}
		});
		return label;
	};

	labelValueConstructor = (value, label) => ({
		label,
		value,
	});

	/**
	 * Work interests are handled differently than the rest of the
	 * multi selects in the profile page. We must have logic to
	 * transform them to what material ui expects.
	 */
	workInterestTransformer = (workInterests) => {
		const currentlySelectedWorkIntersts = [];
		for (const [key, value] of Object.entries(workInterests)) {
			const label = this.getWorkInterestLabelFromValue(key);
			if (value) {
				currentlySelectedWorkIntersts.push(this.labelValueConstructor(key, label));
			}
		}
		return currentlySelectedWorkIntersts;
	};

	/**
	 * Handler for work interest. Work interests are the most complicated of logic flow
	 * due to the way it's coming back from the api.
	 * Note - the first line in the method is just  making sure duplicates aren't selected by the user.
	 */
	workInterestHandler = (newValue) => {
		newValue = Array.from(new Set(newValue.map(JSON.stringify))).map(JSON.parse);
		const newWorkInterests = {};
		const state = { ...this.state };
		newValue.forEach((item) => {
			newWorkInterests[item.value] = true;
		});
		const formattedInterests = this.formatWorkInterestsForSave(newWorkInterests);
		state.clinicianPreferences.workInterests = formattedInterests;
		state.workInterestArray = newValue;
		this.markFlagsDirty();
		this.setState({ ...state });
	};

	/**
	 * NOTE - missionWork is hardcoded here to always be false (it has to be right now).
	 * When it is no longer needed, must remove it here.
	 */
	formatWorkInterestsForSave = (selectedInterests) => {
		const unSelectedWorkInterests = {};
		const allWorkInterests = this.getWorkInterestOptionValueList();
		for (const [key] of Object.entries(selectedInterests)) {
			if (allWorkInterests.indexOf(key) > -1) {
				const index = allWorkInterests.indexOf(key);
				allWorkInterests.splice(index, 1);
			}
		}
		allWorkInterests.forEach((item) => {
			unSelectedWorkInterests[item] = false;
		});
		//unSelectedWorkInterests.missionWork = false;
		return Object.assign(unSelectedWorkInterests, selectedInterests);
	};

	getWorkInterestOptionValueList = () => {
		const allWorkInterests = [];
		Object.entries(this.workInterestsOptions).forEach((item) => {
			allWorkInterests.push(item[1].value);
		});
		return allWorkInterests;
	};

	workInterestDelete = (index) => {
		const state = { ...this.state };
		const { workInterestArray } = state;
		const newWorkInterests = {};
		workInterestArray.splice(index, 1);
		state.workInterestArray = workInterestArray;
		workInterestArray.forEach((item) => {
			newWorkInterests[item.value] = true;
		});
		const formattedInterests = this.formatWorkInterestsForSave(newWorkInterests);
		state.clinicianPreferences.workInterests = formattedInterests;
		this.markFlagsDirty();
		this.setState({ ...state });
	};

	/**
	 * Adds an ariline to the airline preferences array
	 */
	addAirlineClickEvent = () => {
		const state = { ...this.state };
		state.clinicianPreferences.airlinePreferences.push(this.airlineConstructor());
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	filterLocationOptionsFromValue = (selectedValue) => {
		const selectedState = this.states.filter((item) => item.value === selectedValue);
		if (!selectedState[0]) return selectedValue;
		return selectedState[0].label;
	};

	filterAvailabilityOptionsFromValue = (selectedValue) => {
		const selectedAvailabilityOption = this.availabilityOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedAvailabilityOption[0]) return selectedValue;
		return selectedAvailabilityOption[0].label;
	};

	filterAssignmentTypeOptionsFromValue = (selectedValue) => {
		const selectedAssignmentType = this.assignmentTypeOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedAssignmentType[0]) return selectedValue;
		return selectedAssignmentType[0].label;
	};

	filterShiftTypeOptionsFromValue = (selectedValue) => {
		const selectedShiftType = this.shiftTypeOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedShiftType[0]) return selectedValue;
		return selectedShiftType[0].label;
	};

	/**
	 * Adds a hotel to the hotel preferences array.
	 */
	addHotelClickEvent = () => {
		const state = { ...this.state };
		state.clinicianPreferences.hotels.push(this.hotelConstructor());
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	dropdownHandler = (event) => {
		const state = { ...this.state };
		const { name, value } = event.target;
		state.clinicianPreferences[name] = value;
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	handleChangeMultiple = (event) => {
		const state = { ...this.state };
		const { name, options, value } = event.target;
		const newArr = [];
		options.forEach((item) => {
			if (item.selected) {
				newArr.push(item);
			}
		});
		state.clinicianPreferences[name] = value;
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	/**
	 * On change handler for input fields in the component
	 * @param {MouseEvent} event
	 * @param {Number} index
	 * @param {String} travelGroup
	 */
	travelGroupHandler = (event, index, travelGroup) => {
		const state = { ...this.state };
		const { name, value } = event.target;
		state.clinicianPreferences[travelGroup][index][name] = value;
		this.markFlagsDirty();
		this.shouldDisableSave();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	checkboxHandler = (event) => {
		const state = { ...this.state };
		const { name, checked } = event.target;
		state.clinicianPreferences[name] = !checked;
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
		if (checked === false) {
			this.setState({ emailSubscribeflag: true });
		}
	};

	handleClose = () => {
		this.setState({ emailSubscribeflag: false });
	};

	dateChangeHandler = (date, parsedDate, name) => {
		const state = { ...this.state };
		if (date) {
			state.clinicianPreferences[name] = moment.utc(
				date.startOf('day').format('YYYY-MM-DD HH:mm:ss')
			);
		} else {
			state.clinicianPreferences[name] = null;
		}
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	autoCompleteHandler = (newValue, name) => {
		const newValues = [];
		newValue.forEach((item) => {
			if (typeof item === 'string') {
				newValues.push(item);
				return;
			}
			newValues.push(item.value);
		});
		const state = { ...this.state };
		state.clinicianPreferences[name] = newValues;
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	/**
	 * Rental car companies need a unique handler incase
	 * the user selects other. Other will trigger the other
	 * field in the rental car company.
	 * @param {MouseEvent} event
	 * @param {Number} index
	 */
	rentalCarCompanyHandler = (event, index) => {
		const state = { ...this.state };
		state.clinicianPreferences.rentalServices[index].rentalCompany = event.target.value;
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	/**
	 * Handler that removes the selected preference when toggled.
	 * @param {Number} index
	 * @param {String} travelGroup
	 */
	removeSelectedPreferenceByTravelGroup = (index, travelGroup) => {
		const state = { ...this.state };
		if (state.clinicianPreferences[travelGroup][index].isPreferred) {
			state.clinicianPreferences[travelGroup][0].isPreferred = true;
		}
		if (travelGroup === 'airlinePreferences') {
			state.clinicianPreferences.airlinePreferences[index].airlineName = null;
		} else if (travelGroup === 'hotels') {
			state.clinicianPreferences.hotels[index].hotelName = null;
		} else {
			state.clinicianPreferences[travelGroup].splice(index, 1);
		}
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	/**
	 * There can only be one preferred item in a group. This method checks whether or not
	 * another travel item has been set to preferred and removes the previous flag.
	 * @param {Number} the currently selected index;
	 * @param {String} the group currenyl being toggled
	 */
	preferredGroupToggled = (currentlyToggledIndex, travelGroup) => {
		const state = { ...this.state };
		state.clinicianPreferences[travelGroup].forEach((item, index) => {
			if (index === currentlyToggledIndex) {
				return;
			}
			state.clinicianPreferences[travelGroup][index].isPreferred = false;
		});
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	/**
	 * When clicking a preference this logic ensures that theres always
	 * a preference selected
	 * @param {Number} index
	 * @param {String} travelGroup
	 */
	handlePreferredButtonClick = (index, travelGroup) => {
		const state = { ...this.state };
		state.clinicianPreferences[travelGroup][index].isPreferred =
			!state.clinicianPreferences[travelGroup][index].isPreferred;
		this.preferredGroupToggled(index, travelGroup);
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	autoCompleteDelete = async (index, name) => {
		const state = { ...this.state };
		state.clinicianPreferences[name].splice(index, 1);
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	datePickerHelper(date) {
		if (date) {
			return date;
		}
		return null;
	}

	render() {
		const { classes } = this.props;
		const { clinicianPreferences } = this.state;
		if (Object.keys(clinicianPreferences).length === 0) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}
		const { airlinePreferences, rentalServices, hotels } = clinicianPreferences;

		return (
			<>
				<Paper classes={{ root: classes.paperRoot }}>
					<PreferencesTravelComponent
						clinicianPreferences={clinicianPreferences}
						airlinePreferences={airlinePreferences}
						rentalServices={rentalServices}
						hotels={hotels}
						shouldDisableSave={this.shouldDisableSave}
						addAirlineClickEvent={this.addAirlineClickEvent}
						addHotelClickEvent={this.addHotelClickEvent}
						travelGroupHandler={this.travelGroupHandler}
						removeSelectedPreferenceByTravelGroup={
							this.removeSelectedPreferenceByTravelGroup
						}
						handlePreferredButtonClick={this.handlePreferredButtonClick}
						rentalCarCompanyHandler={this.rentalCarCompanyHandler}
					/>
				</Paper>
			</>
		);
	}
}

TravelPreferences.propTypes = {
	classes: PropTypes.shape({
		header: PropTypes.string.isRequired,
	}).isRequired,
	t: PropTypes.func.isRequired,
	tabHandler: PropTypes.func.isRequired,
	flags: PropTypes.shape({
		missingData: PropTypes.bool.isRequired,
		dataSaved: PropTypes.bool.isRequired,
		isDirty: PropTypes.bool.isRequired,
		showSaveChanges: PropTypes.bool.isRequired,
		isPendingSave: PropTypes.bool.isRequired,
		targetTab: PropTypes.number.isRequired,
	}).isRequired,
	updateFlags: PropTypes.func.isRequired,
	setSaveHandler: PropTypes.func.isRequired,
	externalId: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(styles)(TravelPreferences));
