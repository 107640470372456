import { Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const ExpenseTabs = withStyles({
	root: {
		minHeight: 0,
	},
	flexContainer: {
		gap: 4,
	},
})((props) => <Tabs indicatorColor='primary' {...props} />);

export default ExpenseTabs;
