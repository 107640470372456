import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	withStyles,
	Paper,
	Grid,
	FormControl,
	InputLabel,
	CircularProgress,
	TextField,
	Chip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { titles } from './accountOptions';
import { COLORS, LENGTH_CONSTANTS } from '../../../utils/Application_Constants';
import TextFieldComponent from '../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import ProfilePhoneComponentV2 from '../ProfilePhoneComponent/ProfilePhoneComponentV2';
import ProfileAddressComponent from '../ProfileAddressComponent/ProfileAddressComponent';
import {
	getClinicianAccountV2,
	putClinicianAccount,
	getClinicianCommPreference,
	postClinicianCommPreference,
} from '../../../services/ProfileServices/profileService';

const styles = (theme) => ({
	header: {
		color: COLORS.ST_TROPAZ,
		[theme.breakpoints.down('sm')]: {
			padding: '36px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '36px',
		fontSize: '17px',
		fontWeight: '700',
	},
	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
	},
	nativeSelectStyling: {
		[theme.breakpoints.up('md')]: {
			width: '70px',
		},
	},
	progress: {
		height: '100vh',
	},
	progressRoot: {
		height: '100vh',
		[theme.breakpoints.up('md')]: {
			marginTop: '25vh',
			marginLeft: '30vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '20vh',
			marginLeft: '25vh',
		},
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	setWidthTo100OnMobile: {
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	setWidthTo100OnMobileForUserName: {
		width: '350px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	profileWrapper: {
		paddingBottom: '50px',
		marginTop: '30px',
		borderBottom: `1px solid ${COLORS.SEASHELL}`,
	},
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		'& input': {
			height: '32px',
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.RAVEN,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	disabeldInput: {
		opacity: 0.6,
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		cursor: 'pointer',
		marginBottom: '15px',
		borderBottom: `1px solid ${COLORS.TRUE_BLUE}`,
	},
	changePassword: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '10px',
			marginBottom: '10px',
			marginTop: '20px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '8px 10px',
			textAlign: 'left',
		},
	},
});
class Account extends Component {
	constructor(props) {
		super(props);
		this.titles = titles;
		this.state = {
			clinicianAccount: {},
			clinicianPreferences: {},
			flags: {
				titleEmpty: false,
			},
			disableMobile: true,
			showOptOutAlert: false,
			primaryMailingAddress: {},
		};
	}

	async profilePhoneNumberFormatter(number) {
		if (!number) return number;

		// only allows 0-9 inputs
		const currentValue = number.replace(/[^\d]/g, '');

		const cvLength = currentValue.length;
		if (cvLength >= 0) {
			if (cvLength < 4) return currentValue;

			// returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
			if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

			// returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
			return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(
				6,
				10
			)}`;
		} else {
			return currentValue;
		}
	}

	normalizePhoneInput(value, previousValue) {
		if (!value) return value;

		const onlyNums = value.replace(/[^\d]/g, ''); // only allows 0-9
		if (!previousValue || value.length > previousValue.length) {
			if (onlyNums.length === 3) return `(${onlyNums})`;
			if (onlyNums.length === 6) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}-`;

			if (onlyNums.length <= 3) return onlyNums;
			if (onlyNums.length <= 6) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;

			return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
		}
		return value;
	}

	async componentDidMount() {
		const { externalId, setSaveHandler, setAccountPageError } = this.props;
		const state = { ...this.state };

		await Promise.all([
			getClinicianCommPreference(externalId),
			getClinicianAccountV2(externalId),
		])
			.then(async ([clinicianPreferences, clinicianAccount]) => {
				state.clinicianPreferences = clinicianPreferences;
				state.clinicianAccount = clinicianAccount;
				state.specialtyList = [{ label: 'Select', value: null }];
				state.clinicianAccount.phoneNumber = await this.profilePhoneNumberFormatter(
					state.clinicianAccount.phoneNumber
				);
				state.clinicianAccount.otherPhone = await this.profilePhoneNumberFormatter(
					state.clinicianAccount.otherPhone
				);
				state.clinicianAccount.mobilePhone = await this.profilePhoneNumberFormatter(
					state.clinicianAccount.mobilePhone
				);

				if (state.clinicianAccount.fax) {
					state.clinicianAccount.fax = await this.profilePhoneNumberFormatter(
						state.clinicianAccount.fax
					);
				}

				state.primaryMailingAddress = {
					addressLine1:
						!!clinicianAccount.street && clinicianAccount.street.lastIndexOf(',') > -1
							? clinicianAccount.street.substring(
									0,
									clinicianAccount.street.lastIndexOf(',')
							  )
							: clinicianAccount.street,
					addressLine2:
						!!clinicianAccount.street && clinicianAccount.street.lastIndexOf(',') > -1
							? clinicianAccount.street
									.substring(clinicianAccount.street.lastIndexOf(',') + 1)
									.trim()
							: null,
					city: clinicianAccount.city,
					state: clinicianAccount.state,
					zipCode: clinicianAccount.zip,
				};

				state.flags.titleEmpty = this.isTitleEmpty(state.clinicianAccount);
				this.setState({
					clinicianAccount: state.clinicianAccount,
					clinicianPreferences: state.clinicianPreferences,
					flags: state.flags,
					disableMobile: true,
					primaryMailingAddress: state.primaryMailingAddress,
				});

				this.baseMobile = state.clinicianAccount.mobilePhone;
			})
			.catch((e) => {
				setAccountPageError(e);
			});

		setSaveHandler(this, this.doSubmit);
		this.isCharacterLimit();
		this.shouldDisableSave();
	}

	async doSubmit() {
		// Validate required fields
		const state = { ...this.state };
		const { externalId, flags, setAccountPageError } = this.props;
		const missingAnyFields =
			state.clinicianAccount.firstName === '' ||
			state.clinicianAccount.lastName === '' ||
			state.clinicianAccount.ltoeUserName === '' ||
			!state.clinicianAccount.providerTitle ||
			state.clinicianAccount.providerTitle.length === 0 ||
			state.primaryMailingAddress.addressLine1 === '' ||
			(!!state.clinicianAccount.mobilePhone &&
				state.clinicianPreferences.hasOptedOutOfSms === null);

		const tooManyCharacters =
			state.clinicianAccount.firstName.length > LENGTH_CONSTANTS.LENGTH_NAME ||
			state.clinicianAccount.lastName.length > LENGTH_CONSTANTS.LENGTH_NAME ||
			(!!state.clinicianAccount.middleName &&
				state.clinicianAccount.middleName.length > LENGTH_CONSTANTS.LENGTH_NAME) ||
			(!!state.primaryMailingAddress.addressLine1 &&
				state.primaryMailingAddress.addressLine1.length >=
					LENGTH_CONSTANTS.LENGTH_ADDRESS_LINE1) ||
			(!!state.primaryMailingAddress.addressLine2 &&
				state.primaryMailingAddress.addressLine2.length >=
					LENGTH_CONSTANTS.LENGTH_ADDRESS_LINE2) ||
			(!!state.primaryMailingAddress.city &&
				state.primaryMailingAddress.city.length >= LENGTH_CONSTANTS.LENGTH_CITY) ||
			(!!state.primaryMailingAddress.zipCode &&
				state.primaryMailingAddress.zipCode.length > LENGTH_CONSTANTS.LENGTH_ZIP_CODE);
		flags.isCharacterLimit = tooManyCharacters;

		flags.missingData = missingAnyFields;
		if (!missingAnyFields) {
			if (state.clinicianPreferences.hasOptedOutOfSMS !== null) {
				state.clinicianPreferences.hasOptedOutOfSms =
					state.clinicianPreferences.hasOptedOutOfSms !== 'false';
			}
			flags.isPendingSave = true;
			this.props.updateFlags(flags);

			state.clinicianAccount.street = !!state.primaryMailingAddress.addressLine2
				? state.primaryMailingAddress.addressLine1 +
				  ', ' +
				  state.primaryMailingAddress.addressLine2
				: state.primaryMailingAddress.addressLine1;
			state.clinicianAccount.city = state.primaryMailingAddress.city;
			state.clinicianAccount.state = state.primaryMailingAddress.state;
			state.clinicianAccount.zip = state.primaryMailingAddress.zipCode;

			await postClinicianCommPreference(externalId, state.clinicianPreferences).catch((e) => {
				setAccountPageError(e);
			});
			await putClinicianAccount(externalId, state.clinicianAccount).catch((e) => {
				setAccountPageError(e);
			});
			flags.dataSaved = true;
			flags.isDirty = false;
			flags.isPendingSave = false;
			this.props.updateFlags(flags);
		}
		this.setState({
			clinicianAccount: state.clinicianAccount,
			clinicianPreferences: state.clinicianPreferences,
			flags: state.flags,
			disableMobile: true,
			showOptOutAlert: false,
			primaryMailingAddress: state.primaryMailingAddress,
		});
	}

	filterTitleOptionsFromValue = (selectedValue) => {
		const selectedState = this.titles.filter((item) => item.value === selectedValue);
		if (!selectedState[0]) return selectedValue;
		return selectedState[0].label;
	};

	markFlagsDirty = () => {
		const flags = this.props.flags;
		flags.isDirty = true;
		flags.dataSaved = false;
		this.props.updateFlags(flags);
	};

	isCharacterLimit = () => {
		const flags = this.props.flags;
		const state = { ...this.state };
		const tooManyCharacters =
			(!!state.clinicianAccount.firstName &&
				state.clinicianAccount.firstName.length > LENGTH_CONSTANTS.LENGTH_NAME) ||
			(!!state.clinicianAccount.lastName &&
				state.clinicianAccount.lastName.length > LENGTH_CONSTANTS.LENGTH_NAME) ||
			(!!state.clinicianAccount.middleName &&
				state.clinicianAccount.middleName.length > LENGTH_CONSTANTS.LENGTH_NAME) ||
			(!!state.primaryMailingAddress.addressLine1 &&
				state.primaryMailingAddress.addressLine1.length >=
					LENGTH_CONSTANTS.LENGTH_ADDRESS_LINE1) ||
			(!!state.primaryMailingAddress.addressLine2 &&
				state.primaryMailingAddress.addressLine2.length >=
					LENGTH_CONSTANTS.LENGTH_ADDRESS_LINE2) ||
			(!!state.primaryMailingAddress.city &&
				state.primaryMailingAddress.city.length >= LENGTH_CONSTANTS.LENGTH_CITY) ||
			(!!state.primaryMailingAddress.zipCode &&
				state.primaryMailingAddress.zipCode.length > LENGTH_CONSTANTS.LENGTH_ZIP_CODE);
		flags.isCharacterLimit = tooManyCharacters;
		this.props.updateFlags(flags);
	};

	shouldDisableSave = () => {
		const flags = this.props.flags;
		const state = { ...this.state };
		const missingAnyFields =
			state.clinicianAccount.firstName === '' ||
			state.clinicianAccount.lastName === '' ||
			state.clinicianAccount.ltoeUserName === '' ||
			!state.clinicianAccount.providerTitle ||
			state.clinicianAccount.providerTitle.length === 0 ||
			state.primaryMailingAddress.addressLine1 === '' ||
			state.primaryMailingAddress.addressLine1 === null ||
			(!!state.clinicianAccount.mobilePhone &&
				state.clinicianPreferences.hasOptedOutOfSms === null);
		flags.missingData = missingAnyFields;
		this.props.updateFlags(flags);
	};

	textFieldChangeHandler = (event) => {
		const { value, name } = event.target;
		const state = { ...this.state };

		if (state.clinicianAccount[name] !== value) {
			if (name === 'fax') {
				state.clinicianAccount[name] = this.normalizePhoneInput(
					value,
					this.state.clinicianAccount.fax
				);
			} else {
				state.clinicianAccount[name] = value;
			}
			this.markFlagsDirty();
		}
		this.setState({ ...state });
		this.isCharacterLimit();
		this.shouldDisableSave();
	};

	textFieldChangeHandlerProfilePhone = (event) => {
		const { value, name } = event.target;

		const state = { ...this.state };

		if (state.clinicianAccount[name] !== value) {
			if (name === 'phoneNumber') {
				state.clinicianAccount[name] = this.normalizePhoneInput(
					value,
					this.state.clinicianAccount.phoneNumber
				);
			} else if (name === 'otherPhone') {
				state.clinicianAccount[name] = this.normalizePhoneInput(
					value,
					this.state.clinicianAccount.otherPhone
				);
			} else if (name === 'mobilePhone') {
				state.clinicianAccount[name] = this.normalizePhoneInput(
					value,
					this.state.clinicianAccount.mobilePhone
				);
				state.clinicianPreferences.mobilePhone = this.normalizePhoneInput(
					value,
					this.state.clinicianAccount.mobilePhone
				);
			}

			this.markFlagsDirty();
		}
		this.setState({ ...state });
		this.shouldDisableSave();
	};

	addressTextFieldChangehandler = (event, addressType) => {
		const { value, name } = event.target;
		const state = { ...this.state };
		if (state[addressType][name] !== value) {
			state[addressType][name] = value;
			this.markFlagsDirty();
		}
		this.setState({ ...state });
		this.isCharacterLimit();
		this.shouldDisableSave();
	};

	handleRadioChange = (event) => {
		const { value, name } = event.target;
		const state = { ...this.state };
		state.clinicianPreferences[name] = value;
		this.markFlagsDirty();
		this.setState({
			clinicianPreferences: state.clinicianPreferences,
			showOptOutAlert: value,
		});
		this.shouldDisableSave();
	};

	handleEditMobile = () => {
		const state = { ...this.state };
		if (state.disableMobile === true) {
			state.clinicianPreferences.hasOptedOutOfSms = null;
		}
		this.markFlagsDirty();
		this.setState({
			disableMobile: !state.disableMobile,
		});
		this.shouldDisableSave();
	};

	handleCancelMobile = () => {
		const state = { ...this.state };
		state.clinicianAccount.mobilePhone = this.baseMobile;
		this.markFlagsDirty();
		this.setState({
			clinicianAccount: state.clinicianAccount,
			disableMobile: !state.disableMobile,
		});
		this.shouldDisableSave();
	};

	dateOfBirthHandler = (date) => {
		const state = { ...this.state };
		if (date) {
			state.clinicianAccount.birthDate = moment.utc(
				date.startOf('day').format('YYYY-MM-DD HH:mm:ss')
			);
		} else {
			state.clinicianAccount.birthDate = null;
			this.markFlagsDirty();
		}
		this.setState({ ...state });
		this.shouldDisableSave();
	};

	datePickerHelper(date) {
		if (date) {
			return date;
		}
		return null;
	}

	autoCompleteHandler = (newValue, name) => {
		const state = { ...this.state };
		const newValues = [];
		newValue.forEach((item) => {
			if (typeof item === 'string') {
				newValues.push(item);
				return;
			}
			newValues.push(item.value);
		});

		if (state.clinicianAccount[name] !== newValues) {
			state.clinicianAccount[name] = newValues;
			this.markFlagsDirty();
		}
		state.flags.titleEmpty = this.isTitleEmpty(state.clinicianAccount);
		this.setState({ ...state });
		this.isCharacterLimit();
		this.shouldDisableSave();
	};

	autoCompleteDelete = async (index, name) => {
		const state = { ...this.state };
		state.clinicianAccount[name].splice(index, 1);
		this.markFlagsDirty();
		state.flags.titleEmpty = this.isTitleEmpty(state.clinicianAccount);
		this.setState({ ...state });
		this.isCharacterLimit();
		this.shouldDisableSave();
	};

	isTitleEmpty(clinicianAccount) {
		return !clinicianAccount.providerTitle || clinicianAccount.providerTitle.length === 0;
	}

	renderAddressComponents = () => {
		const { primaryMailingAddress } = this.state;
		return (
			<ProfileAddressComponent
				address={primaryMailingAddress}
				textFieldChangeHandler={this.addressTextFieldChangehandler}
				addressType='primaryMailingAddress'
			/>
		);
	};
	changeProfilePasswordComponent() {
		const { classes, t } = this.props;

		const reset_password_link = encodeURI(
			`${process.env.REACT_APP_B2C_BASE_URI}/b2c_1_password_reset/oauth2/v2.0/authorize?response_type=id_token&scope=openid profile&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.origin}&nonce=dummy&state=12345&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=1.2.1&response_mode=fragment`
		);

		return (
			<>
				<Grid item xs={12} md={12} classes={{ root: classes.changePassword }}>
					<a href={reset_password_link}>
						<span className={classes.clickableSpan}>
							{t('profile:ACCOUNT.CHANGE_PASSWORD')}
						</span>
					</a>
				</Grid>
			</>
		);
	}

	render() {
		const { classes } = this.props;
		const { clinicianAccount, clinicianPreferences, flags, disableMobile, showOptOutAlert } =
			this.state;
		if (Object.keys(clinicianAccount).length === 0) {
			return (
				<div className={classes.progressRoot}>
					<CircularProgress />
				</div>
			);
		}
		const { t } = this.props;
		const {
			providerTitle,
			firstName,
			middleName,
			lastName,
			ltoeUserName,
			fax,
			phoneNumber,
			otherPhone,
			mobilePhone,
		} = clinicianAccount;
		const { hasOptedOutOfSms } = clinicianPreferences;

		return (
			<>
				<Paper classes={{ root: classes.paperRoot }}>
					<div className={classes.profileWrapper}>
						<Grid item xs={12}>
							<h3 className={classes.header}>{t('tabs:PROFILE.ACCOUNT')}</h3>
						</Grid>
						<Grid container>
							<Grid container>
								<Grid item classes={{ root: classes.gridRoot }} xs={12} md={10}>
									<FormControl
										classes={{ root: classes.setWidthTo100OnMobileForUserName }}
									>
										<InputLabel
											htmlFor='Email'
											classes={{ root: classes.inputLabel }}
											error={
												ltoeUserName === '' ||
												(!!ltoeUserName &&
													ltoeUserName.length >
														LENGTH_CONSTANTS.LENGTH_EMAIL_ADDRESS)
											}
										>
											{t('profile:ACCOUNT.USER_NAME')}
										</InputLabel>
										<TextFieldComponent
											id='Email'
											classes={{
												input: classes.setWidthTo100OnMobileForUserName,
												disabled: classes.disabeldInput,
											}}
											required
											disabled
											onChange={this.textFieldChangeHandler}
											name='ltoeUserName'
											value={ltoeUserName}
										/>
									</FormControl>
								</Grid>
							</Grid>

							<Grid container>
								<Grid item classes={{ root: classes.gridRoot }} xs={12} md={10}>
									<FormControl
										classes={{ root: classes.setWidthTo100OnMobileForUserName }}
									>
										<InputLabel
											htmlFor='Password'
											classes={{ root: classes.inputLabel }}
											error={ltoeUserName === ''}
										>
											Password
										</InputLabel>
										{this.changeProfilePasswordComponent()}
									</FormControl>
								</Grid>
							</Grid>

							<Grid container>
								<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
									<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
										<InputLabel
											htmlFor='FirstName'
											classes={{ root: classes.inputLabel }}
											required
											error={
												firstName === '' ||
												firstName.length > LENGTH_CONSTANTS.LENGTH_NAME
											}
										>
											{t('profile:ACCOUNT.FIRST_NAME')}
										</InputLabel>
										<TextFieldComponent
											id='FirstName'
											required
											onChange={this.textFieldChangeHandler}
											name='firstName'
											classes={{ input: classes.setWidthTo100OnMobile }}
											value={firstName}
										/>
									</FormControl>
								</Grid>
								<Grid item classes={{ root: classes.gridRoot }} xs={12} md={2}>
									<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
										<InputLabel
											htmlFor='MiddleName'
											classes={{ root: classes.inputLabel }}
											error={
												!!middleName &&
												middleName.length > LENGTH_CONSTANTS.LENGTH_NAME
											}
										>
											{t('profile:ACCOUNT.MIDDLE_INITIAL')}
										</InputLabel>
										<TextFieldComponent
											id='MiddleName'
											classes={{ input: classes.setWidthTo100OnMobile }}
											onChange={this.textFieldChangeHandler}
											name='middleName'
											value={middleName || ''}
										/>
									</FormControl>
								</Grid>
								<Grid item classes={{ root: classes.gridRoot }} xs={12} md={3}>
									<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
										<InputLabel
											htmlFor='LastName'
											classes={{ root: classes.inputLabel }}
											required
											error={
												lastName === '' ||
												lastName.length > LENGTH_CONSTANTS.LENGTH_NAME
											}
										>
											{t('profile:ACCOUNT.LAST_NAME')}
										</InputLabel>
										<TextFieldComponent
											id='LastName'
											required
											classes={{ input: classes.setWidthTo100OnMobile }}
											onChange={this.textFieldChangeHandler}
											name='lastName'
											value={lastName}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={12} classes={{ root: classes.gridRoot }}>
									<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
										<InputLabel
											htmlFor='Title'
											classes={{ root: classes.inputLabel }}
											required
											error={flags.titleEmpty}
										>
											{t('profile:ACCOUNT.TITLES')}
										</InputLabel>
										<Autocomplete
											multiple
											options={this.titles.map((item) => {
												return item.label;
											})}
											getOptionLabel={(option) =>
												this.filterTitleOptionsFromValue(option)
											}
											id='providerTitle'
											filterSelectedOptions
											onChange={(event, newValue) =>
												this.autoCompleteHandler(newValue, 'providerTitle')
											}
											value={providerTitle || []}
											renderTags={(value, { className }) =>
												value.map((option, index) => (
													<Chip
														key={`chip-${index + 1}`}
														variant='outlined'
														classes={{
															root: classes.chipRoot,
															label: classes.chipLabel,
														}}
														data-tag-index={index}
														tabIndex={-1}
														label={this.filterTitleOptionsFromValue(
															option
														)}
														className={className}
														onDelete={() =>
															this.autoCompleteDelete(
																index,
																'providerTitle'
															)
														}
													/>
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													classes={{ root: classes.typeAheadInput }}
													variant='standard'
													margin='normal'
													fullWidth
													error={flags.titleEmpty}
												/>
											)}
										/>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container>
								{this.renderAddressComponents()}

								<ProfilePhoneComponentV2
									textFieldChangeHandler={this.textFieldChangeHandlerProfilePhone}
									handleRadioChange={this.handleRadioChange}
									handleEditMobile={this.handleEditMobile}
									handleCancelMobile={this.handleCancelMobile}
									phoneNumber={phoneNumber}
									otherPhone={otherPhone}
									mobilePhone={mobilePhone}
									hasOptedOutOfSms={hasOptedOutOfSms}
									disableMobile={disableMobile}
									t={t}
									showOptOutAlert={showOptOutAlert}
								/>
								<Grid item classes={{ root: classes.gridRoot }} xs={12} md={12}>
									<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
										<InputLabel
											htmlFor='Fax'
											classes={{ root: classes.inputLabel }}
										>
											{t('profile:ACCOUNT.FAX')}
										</InputLabel>
										<TextFieldComponent
											id='Fax'
											required
											classes={{ input: classes.setWidthTo100OnMobile }}
											onChange={this.textFieldChangeHandler}
											name='fax'
											placeholder='(xxx) xxx-xxxx'
											value={fax}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</Paper>
			</>
		);
	}
}

Account.propTypes = {
	classes: PropTypes.shape({
		header: PropTypes.string.isRequired,
	}).isRequired,
	t: PropTypes.func.isRequired,
	tabHandler: PropTypes.func.isRequired,
	flags: PropTypes.shape({
		missingData: PropTypes.bool.isRequired,
		dataSaved: PropTypes.bool.isRequired,
		isDirty: PropTypes.bool.isRequired,
		showSaveChanges: PropTypes.bool.isRequired,
		isPendingSave: PropTypes.bool.isRequired,
		targetTab: PropTypes.number.isRequired,
	}).isRequired,
	updateFlags: PropTypes.func.isRequired,
	setSaveHandler: PropTypes.func.isRequired,
	externalId: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(styles)(Account));
