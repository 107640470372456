import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	withStyles,
	CircularProgress,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { COLORS } from '../../utils/Application_Constants';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = (theme) => ({
	dialogPaperRoot: {
		padding: '40px',
		gap: '16px',
	},
	dialogTitleRoot: {
		color: COLORS.LT_MIDNIGHT,
		fontSize: '20px',
		fontWeight: '700',
		padding: '0',
		fontStyle: 'italic',
	},
	dialogContentRoot: {
		padding: '0',
		'&:first-child': {
			paddingTop: '0',
		},
	},
	dialogContentRootSubtitle: {
		paddingBottom: '1em',
		'&:first-child': {
			paddingTop: '0',
		},
	},
	footNote: {
		color: COLORS.LT_MIDNIGHT50,
		fontStyle: 'italic',
		fontSize: '12px',
	},
	footNoteClient: {
		color: COLORS.LT_MIDNIGHT50,
		fontStyle: 'italic',
		fontSize: '12px',
	},
	alignCheckbox: { marginTop: '1em' },
	dialogGrid: {
		gap: '24px',
	},
	dialogActionsRoot: {
		padding: '0',
	},
	underlinedButton: {
		textDecoration: 'underline',
	},
	formControl: {
		marginBottom: '16px',
	},
	checkboxInput: {
		padding: '24px',
		justifyContent: 'left',
	},
	body1: {
		lineHeight: '20px',
	},
	clientRecipient: {
		fontSize: '12px',
		fontWeight: 'bold',
		marginTop: '1em',
	},
});

const EmailDialog = (props) => {
	const { classes, onClose, onExited, handleAction, open, name, emailsList, isLoading } = props;

	let recipientType;
	const communicationEventType = emailsList?.map((recipient) => recipient.communicationEventType);
	if (communicationEventType.length > 0) {
		recipientType = communicationEventType[0] === 'ClientApproval' ? 'Client' : 'Clinician';
	}
	const [eventTypes, setEventTypes] = useState();
	const [nameList, setNameList] = useState();

	useMemo(() => {
		setEventTypes(recipientType === 'Clinician' ? [emailsList[0].communicationEventType] : []);
		setNameList(
			recipientType === 'Clinician'
				? [emailsList[0].recipientFirstName + ' ' + emailsList[0].recipientLastName]
				: []
		);
	}, [recipientType]);

	const handleCheckboxChange = (eventType, isChecked, firstName, lastName) => {
		const newState = isChecked
			? eventTypes.filter((event) => event !== eventType)
			: [...eventTypes, eventType];
		setEventTypes(newState);
		const newStateName = isChecked
			? nameList.filter((event) => event !== firstName + ' ' + lastName)
			: [...nameList, firstName + ' ' + lastName];
		setNameList(newStateName);
	};

	const renderReminderType = () => {
		let title;

		switch (communicationEventType[0]) {
			case 'ClientApproval':
			case 'SecondaryClientApproval':
				title = 'Approval Request';
				break;

			case 'TimesheetApproved_Paper':
				title = 'Clinician Paper Check Approval';
				break;

			case 'TimesheetApproved':
				title = 'Clinician Direct Deposit Approval';
				break;

			case 'TimesheetPaid':
			case 'TimesheetPaid_Paper':
				title = 'Pay Remittance';
				break;

			default:
				title = 'Past Due Timesheet';
				break;
		}

		return (
			<Typography variant='body2' className={classes.dialogContentRootSubtitle}>
				{title}
			</Typography>
		);
	};

	const handleClose = () => {
		setEventTypes([]);
		setNameList([]);
		onClose();
	};

	const renderRecipientTypeClient = () => (
		<>
			<Typography variant='body1' color='textPrimary'>
				Select Recipient(s)
			</Typography>
			<FormGroup>
				{emailsList.map((receipient, index) => {
					const type = index === 0 ? 'PRIMARY' : 'SECONDARY';
					const isChecked = eventTypes.includes(receipient.communicationEventType);
					const key = `${receipient.recipientEmail}-${index}`;

					return (
						<React.Fragment key={key}>
							<Typography
								variant='body2'
								color='textPrimary'
								className={classes.clientRecipient}
							>
								{type}
							</Typography>
							<FormControlLabel
								required
								control={
									<Checkbox
										checked={isChecked}
										onChange={() =>
											handleCheckboxChange(
												receipient.communicationEventType,
												isChecked,
												receipient.recipientFirstName,
												receipient.recipientLastName
											)
										}
										name={receipient.communicationEventType}
										color='primary'
									/>
								}
								label={
									<>
										<Typography
											variant='body2'
											className={classes.alignCheckbox}
										>
											{receipient.recipientFirstName}{' '}
											{receipient.recipientLastName}
										</Typography>
										<Typography
											variant='body2'
											className={classes.footNoteClient}
										>
											{receipient.recipientEmail}
										</Typography>
									</>
								}
								key={index}
							/>
						</React.Fragment>
					);
				})}
			</FormGroup>
			<DialogActions classes={{ root: classes.dialogActionsRoot }}>
				<Button
					color='primary'
					variant='contained'
					disabled={eventTypes.length === 0}
					onClick={() => {
						setEventTypes([]);
						setNameList([]);
						handleAction(eventTypes, nameList);
					}}
				>
					Send Reminder
				</Button>
				<Button color='primary' onClick={handleClose}>
					Cancel
				</Button>
			</DialogActions>
		</>
	);

	const renderRecipientTypeClinician = () => (
		<>
			<Typography variant='body1' color='textPrimary'>
				Recipient
			</Typography>
			{emailsList.map((receipient, index) => {
				const key = `${receipient.recipientEmail}-${index}`;
				return (
					<React.Fragment key={key}>
						<Typography variant='body2' key={index}>
							{receipient.recipientFirstName} {receipient.recipientLastName}
						</Typography>
						<Typography variant='body2' className={classes.footNote}>
							{receipient.recipientEmail}
						</Typography>
					</React.Fragment>
				);
			})}
			<DialogActions classes={{ root: classes.dialogActionsRoot }}>
				<Button
					color='primary'
					variant='contained'
					onClick={() => {
						handleAction(eventTypes, nameList);
					}}
				>
					Send Reminder
				</Button>
				<Button color='primary' onClick={handleClose}>
					Cancel
				</Button>
			</DialogActions>
		</>
	);

	const renderDialogContent = () => (
		<>
			<DialogTitle
				id='clear-dialog-title'
				classes={{ root: classes.dialogTitleRoot }}
				variant='body1'
				disableTypography
			>
				Send a {recipientType} timesheet reminder
			</DialogTitle>
			<DialogContent classes={{ root: classes.dialogContentRoot }}>
				<Typography variant='body1' color='textPrimary'>
					Reminder Type
				</Typography>
				{renderReminderType()}
				{recipientType === 'Client'
					? renderRecipientTypeClient()
					: renderRecipientTypeClinician()}
			</DialogContent>
		</>
	);

	return (
		<form>
			<Dialog
				open={open}
				onClose={handleClose}
				TransitionProps={{ onExited }}
				aria-labelledby={`${name}-dialog-title`}
				aria-describedby={`${name}-dialog-description`}
				fullWidth
				maxWidth='xs'
				classes={{ paper: classes.dialogPaperRoot }}
			>
				{!isLoading && emailsList ? (
					renderDialogContent()
				) : (
					<CircularProgress color='primary' />
				)}
			</Dialog>
		</form>
	);
};

export default withStyles(styles)(EmailDialog);
