import React, { Fragment, Component } from 'react';
import { Grid, withStyles, ExpansionPanel, ExpansionPanelSummary, Chip } from '@material-ui/core';
import { ExpandMore, DirectionsCar, Room, Language } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../../utils/Application_Constants';
import moment from 'moment';
import { dateRange } from '../../../../utils/helpers';

const styles = (theme) => ({
	expansionPanelSummaryRoot: {
		padding: '8px 20px',
	},
	clickableLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	clickableWebLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	clickableDirectionMapSpan: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			marginLeft: '10px',
		},
	},
	header: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '16px',
		paddingTop: '15px',
		margin: '0 0 0 15px',
	},
	addressLine: {
		fontWeight: '500',
		color: COLORS.RAVEN,
		fontSize: '14px',
		margin: '0 0 15px 15px',
	},
	carInfoLine: {
		fontWeight: '500',
		color: COLORS.RAVEN,
		fontSize: '14px',
		margin: '0 0 15px 0',
	},
	reminderBlock: {
		fontWeight: '500',
		color: COLORS.RAVEN,
		fontSize: '14px',
		margin: '0 15px 0 15px',
		padding: '0 0 10px 0',
	},
	carRentalTime: {
		fontWeight: 'bold',
		color: COLORS.RAVEN,
		fontSize: '11px',
		textAlign: 'center',
	},
	alignLeft: {
		width: '50%',
		float: 'left',
		textAlign: 'left',
	},
	bold: {
		fontWeight: 'bold',
	},
	iconClass: {
		verticalAlign: 'middle',
		color: COLORS.TRUE_BLUE,
	},
	carCard: {
		marginTop: '16px',
		backgroundColor: COLORS.DENIM,
		minWidth: '100%',
	},
	fullWidth: {
		minWidth: '100%',
	},
	cellConfHeader: {
		color: COLORS.WHITE,
		padding: '10px 8px 10px 8px',
		textAlign: 'right',
	},
	cellDateHeader: {
		color: COLORS.WHITE,
		fontWeight: 'bold',
		padding: '10px 8px 10px 8px',
		textAlign: 'left',
	},
	cellHeading: {
		color: COLORS.RAVEN,
		fontWeight: 'bold',
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		border: 'none',
	},
	cellRoot: {
		color: COLORS.RAVEN,
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		border: 'none',
	},
	cellGrid: {
		padding: '0 0 10px 0',
	},
	cellRightBorder: {
		color: COLORS.RAVEN,
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		borderRight: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	cellTopBorder: {
		borderRight: `1px solid ${COLORS.WHITE_LILAC}`,
		borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	bottomBorder: {
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	iconRoot: {
		width: '16px',
		marginRight: '5px',
		marginBottom: '-8px',
		color: COLORS.TRUE_BLUE,
	},
	cellItinerary: {
		color: COLORS.RAVEN,
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
			borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		},
	},
	cellCarWebsite: {
		color: COLORS.RAVEN,
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		borderRight: `1px solid ${COLORS.WHITE_LILAC}`,
		[theme.breakpoints.down('sm')]: {
			borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		},
	},
	chipRoot: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		marginLeft: '15px',
		marginTop: '15px',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
		},
	},
	chipLabel: {
		fontWeight: '300',
		fontSize: '14px',
	},
	reminderList: {
		listStyleType: 'square',
	},
	remiderListItem: {
		paddingBottom: '5px',
	},
	reminderChip: {
		display: 'block',
	},
	chipRootForHeader: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		marginLeft: '0px',
		marginTop: '10px',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
		},
	},
});

const REMINDERS_LIST = [
	'REMINDER_1',
	'REMINDER_2',
	'REMINDER_3',
	'REMINDER_4',
	'REMINDER_5',
	'REMINDER_6',
];

class CarRental extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	/*return hardcoded website depened for each rental verdor */
	getWebSiteForCarVendor = (carRentalVendor) => {
		const { t, classes } = this.props;
		if (carRentalVendor === 'Avis') {
			return (
				<a
					href={t('common:AVIS_WEB.WEB_URL_LINK')}
					className={classes.clickableWebLink}
					target='_blank'
					rel='noopener noreferrer'
				>
					{t('common:AVIS_WEB.WEB_URL')}
				</a>
			);
		}
		if (carRentalVendor === 'National') {
			return (
				<a
					href={t('common:NATIONAL_WEB.WEB_URL_LINK')}
					className={classes.clickableWebLink}
					target='_blank'
					rel='noopener noreferrer'
				>
					{t('common:NATIONAL_WEB.WEB_URL')}
				</a>
			);
		}
		if (carRentalVendor === 'Enterprise') {
			return (
				<a
					href={t('common:ENTERPRISE_WEB.WEB_URL_LINK')}
					className={classes.clickableWebLink}
					target='_blank'
					rel='noopener noreferrer'
				>
					{t('common:ENTERPRISE_WEB.WEB_URL')}
				</a>
			);
		}
	};
	/* return hardcoded roadside assistance number for each car rental verdor */
	getRoadSideAssistance = (carRentalVendor) => {
		const { t, classes } = this.props;
		if (carRentalVendor === 'Avis') {
			return (
				<a
					href={t('common:AVIS_ROADSIDE_ASSISTANCE.ROADSIDE_ASSISTANCE_NUMBER_LINK')}
					className={classes.clickableLink}
				>
					{' '}
					{t('common:AVIS_ROADSIDE_ASSISTANCE.ROADSIDE_ASSISTANCE_NUMBER')}
				</a>
			);
		}
		if (carRentalVendor === 'National') {
			return (
				<a
					href={t('common:NATIONAL_ROADSIDE_ASSISTANCE.ROADSIDE_ASSISTANCE_NUMBER_LINK')}
					className={classes.clickableLink}
				>
					{' '}
					{t('common:NATIONAL_ROADSIDE_ASSISTANCE.ROADSIDE_ASSISTANCE_NUMBER')}
				</a>
			);
		}
		if (carRentalVendor === 'Enterprise') {
			return (
				<a
					href={t(
						'common:ENTERPRISE_ROADSIDE_ASSISTANCE.ROADSIDE_ASSISTANCE_NUMBER_LINK'
					)}
					className={classes.clickableLink}
				>
					{' '}
					{t('common:ENTERPRISE_ROADSIDE_ASSISTANCE.ROADSIDE_ASSISTANCE_NUMBER')}
				</a>
			);
		}
		return null;
	};

	/* retieve reminder list from application contasts */
	getRemindersList = () => {
		const { classes, t } = this.props;
		const reminderListItem = REMINDERS_LIST.map((reminder) => (
			<li className={classes.remiderListItem}>
				{t(`assignments:TRAVEL.CAR_RENTAL.${reminder}`)}
			</li>
		));
		return reminderListItem;
	};
	mapsSelectorForTravel = (addressInfo) => {
		if (
			navigator.platform.indexOf('iPhone') !== -1 ||
			navigator.platform.indexOf('iPad') !== -1 ||
			navigator.platform.indexOf('iPod') !== -1
		) {
			window.open(`https://maps.apple.com/?q=${this.carRentalAddressParser(addressInfo)}}`);
			return;
		}
		window.open(
			`https://google.com/maps/search/?api=1&query=${this.carRentalAddressParser(
				addressInfo
			)}}`
		);
	};

	carRentalAddressParser = (addressInfo) => {
		let encodedAddress = '';
		const addressString = `${addressInfo.propertyAddress1} ${addressInfo.propertyCity} ${addressInfo.propertyState} ${addressInfo.propertyZip}`;
		encodedAddress = encodeURI(addressString);
		return encodedAddress;
	};

	justCarRental = (travelInfo) => {
		return travelInfo.filter((item) => item.segmentType === 'C');
	};

	renderNoCarRental = (travelInfo, classes, t) => {
		return (
			<ExpansionPanel>
				<ExpansionPanelSummary
					classes={{ root: classes.expansionPanelSummaryRoot }}
					aria-controls='travelPanelContent'
					id='travelPanelHeader'
				>
					<div className={classes.alignLeft}>
						<DirectionsCar classes={{ root: classes.iconClass }} />
						<span className={classes.panelHeader}>
							{t('assignments:TRAVEL.RENTAL_TITLE')} (
							{this.justCarRental(travelInfo).length})
						</span>
					</div>
				</ExpansionPanelSummary>
			</ExpansionPanel>
		);
	};

	render() {
		const { t, classes, travelInfo } = this.props;

		if (this.justCarRental(travelInfo).length === 0) {
			return this.renderNoCarRental(travelInfo, classes, t);
		}

		return (
			<ExpansionPanel>
				<ExpansionPanelSummary
					classes={{ root: classes.expansionPanelSummaryRoot }}
					expandIcon={<ExpandMore classes={{ root: classes.chevronRoot }} />}
					aria-controls='travelPanelContent'
					id='travelPanelHeader'
				>
					<div className={classes.alignLeft}>
						<span>
							<DirectionsCar classes={{ root: classes.iconClass }} />{' '}
							<span className={classes.panelHeader}>
								{t('assignments:TRAVEL.RENTAL_TITLE')} (
								{this.justCarRental(travelInfo).length})
							</span>
						</span>
					</div>
				</ExpansionPanelSummary>
				{this.justCarRental(travelInfo).map((item) => (
					<Fragment key={item.tripKey + '-' + item.confirmationNumber}>
						<Grid container direction='row' classes={{ root: classes.carCard }}>
							<Grid item xs={6} classes={{ root: classes.cellDateHeader }}>
								{dateRange(item)}
							</Grid>
							<Grid item xs={6} classes={{ root: classes.cellConfHeader }}>
								{t('assignments:TRAVEL.CONFIRMATION_NO')}: {item.confirmationNumber}
							</Grid>
						</Grid>

						<Grid container>
							<Grid container item xs={12} direction='column'>
								<Grid
									container
									direction='row'
									classes={{ root: classes.bottomBorder }}
								>
									<Grid item xs={12}>
										<h1 className={classes.header}>{item.vendorName}</h1>
									</Grid>
									<Grid item xs={12} classes={{ root: classes.cellGrid }}>
										{item.propertyAddress1 !== '' &&
											item.propertyCity !== '' &&
											item.propertyState !== '' &&
											item.propertyZip !== '' && (
												<span className={classes.addressLine}>
													{' '}
													{item.propertyAddress1} {item.propertyCity},{' '}
													{item.propertyState} {item.propertyZip}{' '}
													<span
														className={
															classes.clickableDirectionMapSpan
														}
													>
														<Room
															classes={{ root: classes.iconRoot }}
														/>
														<span
															className={classes.clickableLink}
															onClick={() =>
																this.mapsSelectorForTravel(item)
															}
														>
															Get directions
														</span>
													</span>
												</span>
											)}
									</Grid>
									<Grid item xs={12} classes={{ root: classes.cellGrid }}>
										<span className={classes.addressLine}>
											{t('assignments:TRAVEL.ROADSIDE_ASSISTANCE')}:{' '}
											{this.getRoadSideAssistance(item.vendorName)}
										</span>
									</Grid>
								</Grid>
								<Grid container classes={{ root: classes.bottomBorder }}>
									<Grid
										item
										container
										xs={12}
										md={4}
										direction='column'
										classes={{ root: classes.cellCarWebsite }}
									>
										<span className={classes.carRentalTime}>
											{t('assignments:TRAVEL.WEBSITE')}
										</span>
										<span className={classes.carInfoLine}>
											<Language classes={{ root: classes.iconRoot }} />
											{this.getWebSiteForCarVendor(item.vendorName)}
										</span>
									</Grid>
									<Grid
										item
										container
										xs={6}
										md={3}
										direction='column'
										classes={{ root: classes.cellRightBorder }}
									>
										<span className={classes.carRentalTime}>
											{t('assignments:TRAVEL.PICK_UP_DATE')}
										</span>
										<span className={classes.carInfoLine}>
											{`${moment(item.originatedDate).format(
												'MMM D'
											)}, ${moment(item.originatedDate).format('YYYY')} `}
										</span>
									</Grid>
									<Grid
										item
										container
										xs={6}
										md={3}
										direction='column'
										classes={{ root: classes.cellRightBorder }}
									>
										<span className={classes.carRentalTime}>
											{t('assignments:TRAVEL.DROP_OFF_DATE')}
										</span>
										<span className={classes.carInfoLine}>
											{`${moment(item.completedDate).format(
												'MMM D'
											)}, ${moment(item.completedDate).format('YYYY')} `}
										</span>
									</Grid>
									<Grid item container xs={12} md={2} direction='row'>
										<Grid
											item
											xs={12}
											classes={{ root: classes.cellItinerary }}
										>
											<a
												href={item.eItinLink}
												className={classes.clickableLink}
												target='travel'
											>
												{t('assignments:TRAVEL.VIEW_ITENERARY_LINE')}
											</a>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									container
									direction='row'
									classes={{ root: classes.bottomBorder }}
								>
									<Grid item xs={12}>
										<Chip
											classes={{
												root: classes.chipRoot,
												label: classes.chipLabel,
											}}
											label={`${REMINDERS_LIST.length} Reminders`}
										/>
									</Grid>
									<Grid item xs={12} classes={{ root: classes.reminderBlock }}>
										<span className={classes.reminderBlock}>
											<ui className={classes.reminderList}>
												{this.getRemindersList()}
											</ui>
										</span>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Fragment>
				))}
			</ExpansionPanel>
		);
	}
}
export default withTranslation()(withStyles(styles)(CarRental));
