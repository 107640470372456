import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import YourTeamCard from './YourTeamCard.js';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		width: '100%',
	},
	noTeamSpan: {
		fontWeight: '500',
		fontSize: '14px',
	},
});

class YourTeamGrid extends React.Component {
	render() {
		const { yourteam, classes, style, t } = this.props;
		const items = [];

		if (!yourteam || yourteam.length === 0) {
			items.push(
				<span className={classes.noTeamSpan}>Your team will be assigned soon.</span>
			);
		} else {
			for (let i = 0; i < yourteam.length; i++) {
				items.push(<YourTeamCard key={`teamCard-${i + 1}`} contact={yourteam[i]} />);
			}
		}

		return (
			<>
				<div classes={{ root: classes.header }}>{t('assignments:YOURTEAM.TITLE')}</div>
				<div className='YourTeamGridCards' style={style}>
					{items}
				</div>
			</>
		);
	}
}

YourTeamGrid.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(YourTeamGrid));
