import React from 'react';
import WithContexts from '../../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import GapsForm from '../../gapsForm/GapsForm';

function GapsInWorkHistoryForm({
	t,
	setSaveHandler,
	blockedBackNavigation,
	activePath,
	setCredAppError,
	credState,
	UserContext,
	flags,
	updateFlags,
	type = 'workgaps',
	constantType = 'GAPS_IN_WORK_HISTORY',
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	return (
		<GapsForm
			t={t}
			setSaveHandler={setSaveHandler}
			blockedBackNavigation={blockedBackNavigation}
			activePath={activePath}
			credState={credState}
			UserContext={UserContext}
			flags={flags}
			updateFlags={updateFlags}
			type={type}
			constantType={constantType}
			sectionCompletion={sectionCompletion}
			updateSectionCompletion={updateSectionCompletion}
			updateHighlightFieldsState={updateHighlightFieldsState}
			shouldHighlightFields={shouldHighlightFields}
			setCredAppError={setCredAppError}
		/>
	);
}

export default WithContexts(withTranslation()(GapsInWorkHistoryForm));
