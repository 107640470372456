/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Container,
    Grid,
    Paper,
    Typography,
    withStyles,
    Button,
    useMediaQuery,
    useTheme,
    CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ChevronLeft } from '@material-ui/icons';
import { withRouter, useHistory } from 'react-router-dom';
import WithContexts from '../../contexts/withContexts';
import { COLORS } from '../../utils/Application_Constants';
import TimesheetAccordion from '../../components/ContentComponents/Timesheets/TimesheetAccordion';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getTimesheetById, saveTimesheet } from '../../services/TimeAndPayService';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { TimesheetStatusEnumApi } from '../../components/ContentComponents/Timesheets/Status.enum';
import splitEntriesByBreaks from '../../Shared/Components/SplitEntriesByBreaks';

const styles = (theme) => ({
    root: {
        backgroundColor: COLORS.LT_MIDNIGHTBG1,
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ContainerRoot: {
        paddingTop: '2.5rem',
        paddingBottom: '5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
    },
    PaperRoot: {
        padding: '1.5rem',
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
    },
    containedSuccess: {
        backgroundColor: COLORS.LT_EMERALD,
        '&:hover': {
            backgroundColor: COLORS.LT_EMERALD75,
        },
        color: 'white',
        paddingLeft: '48px',
        paddingRight: '48px',
    },
    approveButton: {
        backgroundColor: COLORS.LT_EMERALD,
        '&:hover': {
            backgroundColor: COLORS.LT_EMERALD75,
        },
        color: 'white',
    },
    rejectButton: {
        backgroundColor: COLORS.LT_PEPPER10,
        color: COLORS.LT_PEPPER,
        '&:hover': {
            backgroundColor: COLORS.LT_PEPPER25,
        },
    },
    overline1: {
        fontWeight: '600',
    },
    overline2: {
        fontSize: '10px',
        fontWeight: '600',
        lineHeight: '18px',
        color: COLORS.LT_MIDNIGHT50,
    },
    body1: {
        lineHeight: '20px',
    },
    warningIcon: {
        marginRight: '35px',
    },
    warningGridWrapper: {
        background: COLORS.LT_SUNFLOWER25,
        padding: '25px 15px',
    },
    bookingName: {
        fontStyle: 'italic',
        color: COLORS.LT_MIDNIGHT50,
    },
});

function TimesheetReviewClinician(props) {
    const { classes, t, UserContext, setRefreshNotifications } = props;
    const [timesheet, setTimesheet] = useState(null);
    const [viewTimesheet, setViewTimesheet] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    });
    const { id } = useParams();
    const history = useHistory();
    let timesheetHasBeenSubmitted = false;
    const locationsToBeSubmitted = [];

    const { externalId } = UserContext;

    for (let i = 0; i < timesheet?.timesheetReviews.length; i++) {
        if (
            timesheet.timesheetReviews[i].status === TimesheetStatusEnumApi.Submitted ||
            timesheet.timesheetReviews[i].status === TimesheetStatusEnumApi.Approved ||
            timesheet.timesheetReviews[i].status === TimesheetStatusEnumApi.Paid
        ) {
            timesheetHasBeenSubmitted = true;
        } else {
            const locationId = timesheet.timesheetReviews[i].locationId;
            locationsToBeSubmitted.push(
                timesheet?.booking?.locations?.filter((location) => location.id === locationId)[0]
                    ?.displayName
            );
        }
    }

    useEffect(() => {
        const fetchTimesheetById = async () => {
            try {
                const timesheetDetails = await getTimesheetById(externalId, id, null);
                const viewTimesheetDetails = cloneDeep(timesheetDetails);
                setViewTimesheet(viewTimesheetDetails);
                mapTimeToLocationTimezone(viewTimesheetDetails);
                setTimesheet(timesheetDetails);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error(error);
            }
        };
        fetchTimesheetById();
    }, []);

    const convertUTCtoTime = (utcString, timeZoneBooking) => {
        let utcDateTime = utcString;
        if (utcString !== null) {
            utcDateTime = moment(utcString).tz(timeZoneBooking).format('HH:mm');
        }
        return utcDateTime;
    };

    const mapTimeToLocationTimezone = (timesheet) => {
        timesheet.timesheetEntries.forEach((timesheetEntry) => {
            const timeZoneBooking =
                timesheet?.booking?.locations?.find(
                    (location) => location.id === timesheetEntry?.locationId
                )?.timeZone || moment.tz.guess();
            let startTime = timesheetEntry.startTime;
            let endTime = timesheetEntry.endTime;
            timesheetEntry.startTime = convertUTCtoTime(startTime, timeZoneBooking);
            timesheetEntry.endTime = convertUTCtoTime(endTime, timeZoneBooking);
            timesheetEntry.transactionDate = timesheetEntry.transactionDate = moment(
                timesheetEntry.transactionDate
            )
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss');

            mapEntryBreakTimesToLocationTimezone(timesheetEntry, timeZoneBooking);
        });
        timesheet.mileageEntries.forEach((mileageEntry) => {
            mileageEntry.transactionDate = moment(mileageEntry.transactionDate)
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss');
        });
        return timesheet;
    };

    const mapEntryBreakTimesToLocationTimezone = (entry, timeZoneBooking) => {
        if (!entry.timesheetBreaks)
            return;

        entry.timesheetBreaks = entry.timesheetBreaks.sort((a, b) => a.startTime.localeCompare(b.startTime));

        entry.timesheetBreaks.forEach((timesheetBreak) => {
            timesheetBreak.startTime = convertUTCtoTime(timesheetBreak.startTime, timeZoneBooking);
            timesheetBreak.endTime = convertUTCtoTime(timesheetBreak.endTime, timeZoneBooking);
        });
    };

    const formatPeriod = (weekEnding) => {
        let endDate = moment(weekEnding).utc().format('MMM D, YYYY');
        let startDate = moment(weekEnding).subtract(6, 'days').utc().format('MMM D');
        return `Week of ${startDate} - ${endDate}`;
    };

    const onSubmit = async () => {
        setIsLoading(true);
        const path = `/timesheet/${timesheet.id}/submit`;
        let newTimesheet = timesheet;
        newTimesheet.status = TimesheetStatusEnumApi.Submitted;
        newTimesheet.timesheetEntries = newTimesheet.timesheetEntries.filter(
            (entry) => entry.locationId && entry.rateTypeId
        );
        newTimesheet.mileageEntries = newTimesheet.mileageEntries.filter(
            (entry) => entry.locationId
        );
        try {
            await saveTimesheet(newTimesheet, timesheet.id);

            if (setRefreshNotifications) {
                setRefreshNotifications();
            }

            history.push({
                pathname: path,
                state: { timesheetPreviouslySubmitted: timesheetHasBeenSubmitted },
            });
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
        window.Appcues.track("Submit Timesheet For Processing (Submit)", {
            key: "Click the Submit button",
            description: "A user has submitted their timesheet for proessing",
            bookingName: newTimesheet.BookingName,
            weekEnding: newTimesheet.endDate
        })
    };

    const formatListToCommaSeparated = (locations) => {
        const length = locations.length;

        if (length === 0) {
            return '';
        } else if (length === 1) {
            return locations[0];
        } else if (length === 2) {
            return `${locations[0]} and ${locations[1]}`;
        } else {
            let result = locations.slice(0, -1).join(', ');
            result += `, and ${locations[length - 1]}`;
            return result;
        }
    };

    const renderTimesheetPanels = (bookingLocation, index) => {
        const timesheetEntries = viewTimesheet.timesheetEntries.filter(
            (entry) => entry.locationId === bookingLocation.id
        ).sort((a, b) => new Date(a.transactionDate) - new Date(b.transactionDate));

        // Group timesheetEntries by relatedEntryId or form individual groups for entries without relatedEntryId
        const groups = {};
        timesheetEntries.forEach((entry, index) => {
            if (entry.id === 0) {
                entry.id = -(index + 1);
            }
            const relatedEntryId = entry.relatedEntryId;
            if (relatedEntryId) {
                if (!groups[relatedEntryId]) {
                    groups[relatedEntryId] = [];
                }
                groups[relatedEntryId].push(entry);
            } else {
                // Form individual groups for entries without relatedEntryId
                groups[entry.id] = [entry];
            }
        });

        const sortEntriesWithinGroup = (group) => {
            return group.sort((a, b) => {
                const dateA = new Date(a.transactionDate);
                const dateB = new Date(b.transactionDate);
                if (dateA.getTime() !== dateB.getTime()) {
                    return dateA - dateB;
                }
                // Sort by start time if it exists
                if (a.startTime && b.startTime) {
                    return a.startTime.localeCompare(b.startTime);
                }
                // If start time doesn't exist, maintain the existing order
                return 0;
            });
        };

        //sort groups by the transactionDate of their first entry
        const sortGroupsByTransactionDate = (groups) => {
            return Object.values(groups).sort((a, b) => {
                const dateA = new Date(a[0].transactionDate);
                const dateB = new Date(b[0].transactionDate);
                return dateA - dateB;
            });
        };

        // Sort groups by transactionDate
        const sortedGroups = sortGroupsByTransactionDate(groups);

        // Sort entries within each group
        sortedGroups.forEach(group => {
            sortEntriesWithinGroup(group);
        });

        // Flatten sortedGroups into an array while keeping the order of groups
        let sortedEntries = sortedGroups.flatMap(group => group);
        sortedEntries = splitEntriesByBreaks(sortedEntries);

        const mileageEntries = viewTimesheet.mileageEntries.filter(
            (entry) => entry.locationId === bookingLocation.id
        );

        return (
            <TimesheetAccordion
                key={bookingLocation.id}
                timesheetEntries={sortedEntries}
                mileageEntries={mileageEntries}
                timesheet={viewTimesheet}
                bookingLocation={bookingLocation}
                defaultExpanded={false}
                index={index}
            />
        );
    };

    const renderReviewPage = () => {
        return (
            <Container maxWidth='xl' className={classes.ContainerRoot}>
                <Grid container direction='row' spacing={isMobile ? 2 : 4}>
                    <Grid item xs={12} md={3}>
                        <Grid container direction='column' spacing={isMobile ? 0 : 2}>
                            <Grid item>
                                <Button
                                    variant='text'
                                    color='primary'
                                    startIcon={<ChevronLeft />}
                                    onClick={() =>
                                        history.push(
                                            `/timesheet/${timesheet.id}/edit?weekEnding=${timesheet.weekEnding}`
                                        )
                                    }
                                >
                                    <Typography variant='overline' className={classes.overline1}>
                                        {t('submitTimesheet:LINKS.GO_BACK_TO_EDIT_TIME')}
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant='h3'
                                    component='h3'
                                    gutterBottom
                                    className={classes.header}
                                >
                                    {t('submitTimesheet:HEADERS.REVIEW_AND_SUBMIT')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container direction='column' spacing={4}>
                            {timesheetHasBeenSubmitted && (
                                <Grid item xs={12}>
                                    <Alert severity='warning'>
                                        {t('submitTimesheet:HEADERS.ALREADY_SUBMITTED_WARNING')}
                                        {formatListToCommaSeparated(locationsToBeSubmitted)}
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Paper className={classes.PaperRoot}>
                                    <Typography variant='h5' className={classes.header}>
                                        {formatPeriod(timesheet.weekEnding)}
                                    </Typography>
                                    <Typography variant='body2' className={classes.bookingName}>
                                        {timesheet.booking.name}
                                    </Typography>
                                    {timesheet.booking.locations.map((location, index) =>
                                        renderTimesheetPanels(location, index)
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent='flex-end'>
                    <Grid item>
                        <Button
                            onClick={onSubmit}
                            variant='contained'
                            className={classes.containedSuccess}
                        >
                            {t('submitTimesheet:BUTTONS.SUBMIT')}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        );
    };

    return (
        <div className={classes.root}>
            {isLoading ? (
                <CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
            ) : (
                renderReviewPage()
            )}
        </div>
    );
}

export default WithContexts(
    withRouter(withTranslation()(withStyles(styles)(TimesheetReviewClinician)))
);
