import React, { useState } from 'react';
import {
	Button,
	CircularProgress,
	Container,
	Grid,
	Paper,
	Box,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import { generateToken } from '../Services/AuthService';
import { Controller, useForm } from 'react-hook-form';
import { COLORS } from '../../utils/Application_Constants';
import Logo from '../../assets/logo/locumtenens_com_logo.svg';

const styles = (theme) => ({
	ContainerRoot: {
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '16px',
			paddingRight: '16px',
		},
		paddingBottom: '5rem',
		display: 'flex',
		flexDirection: 'column',
		gap: '40px',
	},
	formControl: {
		borderRadius: '6px',
	},
	input: {
		padding: '16px !important',
		height: 'auto',
		fontSize: '18px',
		letterSpacing: '0px',
		borderRadius: '6px',
		opacity: '1',
		lineHeight: '20px',
		border: `2px solid ${COLORS.LT_MIDNIGHT5}`,
		'&$disabled': {
			background: COLORS.LT_MIDNIGHTBG2,
		},
		'&[type="time"]': {
			height: '20px',
		},
	},
	inputRoot: {
		borderRadius: '6px',
		'&$disabled $notchedOutline': {
			borderColor: 'transparent',
		},

		'&$disabled $input': {
			background: COLORS.LT_MIDNIGHTBG2,
			color: COLORS.LT_MIDNIGHT33,
		},
	},
	disabled: {},
	notchedOutline: {},
	logo: {
		maxWidth: '365px',
		width: '100%',
		marginBottom: '35px',
	},
	mainGridContainer: {
		maxWidth: '440px',
		width: '100%',
	},
	dialogContainer: {
		padding: '63px 99px',
		[theme.breakpoints.down('sm')]: {
			padding: '25px 40px',
		},
		maxWidth: '640px',
		width: '100%',
		boxSizing: 'border-box',
		textAlign: 'center',
		borderRadius: '16px',
		boxShadow: `0px 3px 8px ${COLORS.LIGHT_BLUE_TRANSPARENT}`,
	},
	formContainer: {
		height: '128px',
		padding: '0px',
		maxWidth: '440px',
		width: '100%',
		marginTop: '24px',
	},
	sendEmailButton: {
		background: '#0077C8 0% 0% no-repeat padding-box',
		border: '1px solid #0077C8',
		borderRadius: '4px',
		opacity: '1',
		width: '100%',
		height: '50px',
		fontSize: '16px',
	},
	InvalidAuthHeader: {
		fontSize: '25px',
		fontWeight: '600',
		color: COLORS.LT_FOUNDATION,
		letterSpacing: '0px',
	},
	InvalidAuthBodyText: {
		fontSize: '18px',
		color: COLORS.LT_MIDNIGHT75,
		letterSpacing: '0px',
		fontWeight: 'normal',
		lineHeight: '24px',
		maxWidth: '364px',
		width: '100%',
		margin: 'auto',
	},
	InvalidAuthFooterText: {
		fontSize: '18px',
		letterSpacing: '0px',
		color: COLORS.SLATE_GRAY,
		fontWeight: 'normal',
		lineHeight: '24px',
		maxWidth: '570px',
		width: '100%',
		textAlign: 'center',
	},
	loadingSlide: {
		maxWidth: '440px',
		width: '100%',
	},
	authPageRoot: {
		background: `transparent linear-gradient(126deg, ${COLORS.WHITE} 0%, ${COLORS.LT_JOURNEY10} 65%, ${COLORS.LT_JOURNEY10} 100%) 0% 0% no-repeat padding-box`,
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
});

const AuthenticationInvalid = (props) => {
	const { classes, rejectedAuthReason } = props;
	const [rejectionReason] = useState(rejectedAuthReason);
	const [isLoading, setIsLoading] = useState(false);
	const [accessEmailSent, setAccessEmailSent] = useState(false);
	let title, body, buttonText;
	if (accessEmailSent) {
		title = 'Thanks!';
		body =
			'If you have an email address in our system, you will be emailed a link to view your timesheets.';
		buttonText = 'Send me an access link';
	} else if (rejectionReason === 'No token') {
		title = 'Timesheet Portal';
		body = 'Enter your email address below to receive a link to access your timesheets';
		buttonText = 'Send me an access link';
	} else {
		title = 'Session Expired';
		body = `Your session has expired. Please type in your email below to request a new access link.`;
		buttonText = 'Send me a new link';
	}
	const { handleSubmit, control, errors } = useForm();

	const handleNewLinkRequest = async (email) => {
		setIsLoading(true);
		try {
			await generateToken(email);
			setIsLoading(false);
			setAccessEmailSent(true);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			setAccessEmailSent(true);
		}
	};

	const handleEmailSubmit = (data) => {
		// TODO Call API / handle response
		setIsLoading(true);
		handleNewLinkRequest(data?.email);
	};

	return (
		<div className={classes.authPageRoot}>
			<Container maxWidth='xl' className={classes.ContainerRoot}>
				<Grid container justifyContent='center' alignItems='center' direction='column'>
					<img alt='LocumTenens Logo' className={classes.logo} src={Logo} />
					<Paper className={classes.dialogContainer}>
						<Grid item>
							{!isLoading && (
								<>
									<Typography
										component='h1'
										variant='h3'
										className={classes.InvalidAuthHeader}
									>
										{title}
									</Typography>
									<Box sx={{ m: 1.5 }} />
									<Typography className={classes.InvalidAuthBodyText}>
										{body}
									</Typography>
									<Grid
										className={classes.formContainer}
										container
										justifyContent='space-between'
										direction='column'
									>
										{!accessEmailSent && !isLoading && (
											<>
												<form
													onSubmit={handleSubmit((data) =>
														handleEmailSubmit(data)
													)}
												>
													<Controller
														name='email'
														control={control}
														defaultValue=''
														rules={{
															pattern: {
																value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
																message:
																	'A valid email is required',
															},
															required: 'A valid email is required',
														}}
														render={({ onChange, value, ref }) => (
															<TextField
																id='AuthEmailEntry'
																className={classes.formControl}
																value={value}
																error={errors?.email ? true : false}
																helperText={errors?.email?.message}
																onChange={onChange}
																placeholder='Email address'
																inputRef={ref}
																InputProps={{
																	classes: {
																		input: classes.input,
																		root: classes.inputRoot,
																		disabled: classes.disabled,
																		notchedOutline:
																			classes.notchedOutline,
																	},
																}}
																inputProps={{
																	step: 300, // 5 min
																}}
																variant='outlined'
															/>
														)}
													/>
													<Button
														variant='contained'
														color='primary'
														type='submit'
														className={classes.sendEmailButton}
													>
														{buttonText}
													</Button>
												</form>
											</>
										)}
									</Grid>
								</>
							)}
							{isLoading && (
								<>
									<Grid className={classes.loadingSlide}>
										<CircularProgress />
									</Grid>
								</>
							)}
						</Grid>
					</Paper>
					<Box sx={{ m: 4 }} />
					<Typography variant='body2' className={classes.InvalidAuthFooterText}>
						If you do not receive an email or are having issues getting a link, please
						reach out to your recruiter or call 1-877-732-7080
					</Typography>
				</Grid>
			</Container>
		</div>
	);
};

export default withStyles(styles)(AuthenticationInvalid);
