import { Snackbar } from '@material-ui/core';
import { Alert as MUIAlert } from '@material-ui/lab';
import React from 'react';

const Alert = ({ alert, setAlert }) => {
	const handleAlertClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert(null);
	};

	return (
		<Snackbar
			open={!!alert}
			autoHideDuration={6000}
			onClose={handleAlertClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<MUIAlert onClose={handleAlertClose} severity={alert?.type}>
				{alert?.message}
			</MUIAlert>
		</Snackbar>
	);
};

export default Alert;
