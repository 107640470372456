/**
 * @file Desktop Navigation component is rendered on medium and large devices.
 * @TODO integrate with react router, links currently lead to nowhere.
 *
 */
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grow, Paper, Popper, MenuItem, MenuList, Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { menuNavigationProps } from '../../../types/centralizedProps';
import Logo from '../../../assets/logo/locumtenens_com_logo.svg';
import { COLORS } from '../../../utils/Application_Constants';
import { withTranslation } from 'react-i18next';
import ProfileNavComponent from '../../ContentComponents/ProfileNavigation/ProfileNavComponent';
import NavigationService from '../../../services/NavigationService';
import UserContext from '../../../contexts/userContext';
import Menu from '@material-ui/core/Menu';
import Notifications from '../Notifications/Notifications';
const styles = () => ({
	menuListRoot: {
		display: 'flex',
	},
	logoWrapper: {
		maxWidth: '270px',
	},
	supportNumberMenuListRoot: {
		display: 'flex',
		paddingLeft: '5px',
		fontSize: '14px',
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 0px 18px 0px',
		marginTop: '15px',
		flexGrow: '1',
	},
	navbarWrapper: {
		padding: '0 15px',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		color: `${COLORS.DENIM}`,
	},
	fontBoldHeader: {
		fontWeight: '700',
		fontSize: '14px',
		padding: '12px 10px',
		transition: '0.3s all',
		'&:hover': {
			background: 'none',
		},
	},

	paperPopper: {
		zIndex: '1302',
	},
	subNavRoot: {
		padding: '32px 49px 30px 30px',
	},
	gridFlexWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	supportNavNumber: {
		fontWeight: '700',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		cursor: 'pointer',
		padding: '12px 10px',
		transition: '0.3s all',
		'&:hover': {
			background: 'none',
			color: '#336195',
		},
	},
	subNavMenuItem: {
		fontWeight: '700',
		color: `${COLORS.TRUE_BLUE}`,
		fontSize: '14px',
		'&:hover': {
			background: COLORS.TRUE_BLUE,
			color: '#ffffff',
		},
	},
});

class DesktopNavigationV1 extends Component {
	constructor(props, context) {
		super(props);
		this.state = {
			childMenuOpenCloseController: {},
			profileNavigation: [],
			anchorEl: null,
			isAdminApp: process.env.REACT_APP_TYPE === 'Admin',
			isClientApp: process.env.REACT_APP_TYPE === 'Client',
		};
		this.anchorRef = React.createRef(null);
		this.context = context;
	}

	componentDidMount() {
		const { menuNavigation } = this.props;
		const profileNavigation = NavigationService.getProfileNavigation();
		this.setSubnavInitialStateToggles(menuNavigation);
		this.setState({ profileNavigation: profileNavigation.profile });
	}

	navigationItemMenuLinks = (item) => {
		const { classes } = this.props;

		if (item.link === '/manage-cv') {
			return (
				<NavLink exact key={item.id.toString()} to={{ pathname: item.link }}>
					<MenuItem classes={{ root: classes.subNavMenuItem }} onClick={this.handleClose}>
						{item.name}
					</MenuItem>
				</NavLink>
			);
		}

		return (
			<a
				key={item.id.toString()}
				href={`${process.env.REACT_APP_LOGIN_URL}${item.link}`}
				rel='noopener noreferrer'
			>
				<MenuItem classes={{ root: classes.subNavMenuItem }} onClick={this.handleClose}>
					{item.name}
				</MenuItem>
			</a>
		);
	};

	renderSubMenu(navigationItem) {
		const { classes } = this.props;
		const { id, name, parentUrlLink } = navigationItem;
		return (
			<>
				<NavLink
					exact
					key={id.toString()}
					to={{ pathname: parentUrlLink }}
					className='desk-link'
					activeClassName='is-active'
				>
					<MenuItem onClick={this.handleClick} className={classes.fontBoldHeader}>
						{name}
						<span className='caret'></span>
					</MenuItem>
				</NavLink>
				<Menu
					id='simple-menu'
					anchorEl={this.state.anchorEl}
					keepMounted
					open={Boolean(this.state.anchorEl)}
					onClose={this.handleClose}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					{navigationItem.children.map((item, index) =>
						this.navigationItemMenuLinks(item)
					)}
				</Menu>
			</>
		);
	}

	handleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	renderSupportNumber = () => {
		const { t, classes } = this.props;
		return (
			<a href={t('common:INFORMATION.HELP_NUMBER_LINK')} className={classes.supportNavNumber}>
				{' '}
				{t('common:INFORMATION.HELP_NUMBER')}
			</a>
		);
	};
	/**
	 * Loops through the menu navigation props and sets state dynamically
	 * for navigation items that contain children. This is required because
	 * we need unique booleans based on how many
	 * @param {any} navigationItems
	 */
	setSubnavInitialStateToggles = (navigationItems) => {
		const initialState = {};
		navigationItems.forEach((item) => {
			if (item.children.length > 0) {
				initialState[item.name] = false;
			}
		});
		this.setState({ childMenuOpenCloseController: { ...initialState } });
	};

	/**
	 * Handler fires when the parent is navigation element is out of focus.
	 * @param {MouseEvent} event
	 */

	/**
	 * Renders navigation itmem with collapsable children.
	 * @param {any} navigationItem
	 * @returns {JSX}
	 */
	renderNavigationItemWithSubNav = (navigationItem) => {
		const { name, id } = navigationItem;
		const currentState = { ...this.state };
		const { classes } = this.props;
		return (
			// Added Feature flag for Job board navigation - need to work on this in feature
			<div key={id.toString()}>
				<MenuItem
					className={classes.fontBoldHeader}
					onClick={(event) => {
						const state = { ...this.state };
						this.anchorRef = event.target;
						state.childMenuOpenCloseController[name] =
							!state.childMenuOpenCloseController[name];
						this.setState({ ...state });
					}}
					onBlur={this.handleClose}
				>
					{name}
				</MenuItem>
				<Popper
					open={currentState.childMenuOpenCloseController[name] || false}
					anchorEl={this.anchorRef}
					keepMounted
					transition
					className={classes.paperPopper}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === 'bottom' ? 'center top' : 'center bottom',
							}}
						>
							<Paper>
								<MenuList classes={{ root: classes.subNavRoot }}>
									{navigationItem.children.map((item, index) => (
										//added anchor tag for child url, need to replace with NAVBAR if we need to use router functionality
										<a
											key={`footerChildren-${index + 1}`}
											href={item.link}
											rel='noopener noreferrer'
											target='_blank'
										>
											<MenuItem key={`${item.id}`}>{item.name}</MenuItem>
										</a>
									))}
								</MenuList>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		);
	};

	/**
	 * Method renders a single item as part of navigation.
	 * @param {any} navigationItem
	 * @returns {JSX}
	 */
	renderNavigationItem = (navigationItem) => {
		const { classes } = this.props;
		const { id, name, parentUrlLink } = navigationItem;

		if (name === 'Credentials') {
			return (
				<NavLink
					key={id.toString()}
					to={{ pathname: parentUrlLink }}
					className='desk-link'
					activeClassName='is-active'
				>
					<MenuItem className={classes.fontBoldHeader}>{name}</MenuItem>
				</NavLink>
			);
		} else {
			return (
				<NavLink
					exact
					key={id.toString()}
					to={{ pathname: parentUrlLink }}
					className='desk-link'
					activeClassName='is-active'
				>
					<MenuItem className={classes.fontBoldHeader}>{name}</MenuItem>
				</NavLink>
			);
		}
	};

	/**
	 * Main navigation method called in the render method. This method decides
	 * how to render the navigation based on subnav properties.
	 */
	renderNavigation = () => {
		const { menuNavigation } = this.props;
		return menuNavigation.map((item) => {
			if (item.children.length > 0) {
				return <Fragment key={item.id}>{this.renderSubMenu(item)}</Fragment>;

				//return this.renderNavigationItemWithSubNav(item);
			}
			return this.renderNavigationItem(item);
		});
	};

	render() {
		const { classes,refreshNotifications } = this.props;
		const { profileNavigation, isAdminApp, isClientApp } = this.state;
		const { account } = this.context;

		return (
			<Grid
				container
				justifyContent='flex-start'
				alignContent='center'
				className={classes.navbarWrapper}
			>
				<Grid item classes={{ root: classes.logoWrapper }} md={3}>
					<a href='/'>
						<img alt='LocumTenens Logo' src={Logo} />
					</a>
				</Grid>
				<Grid item md={6}>
					<MenuList classes={{ root: classes.menuListRoot }}>
						{this.renderNavigation()}
					</MenuList>
				</Grid>

				<Grid item md='auto' classes={{ root: classes.buttonWrapper }}>
					<MenuList classes={{ root: classes.supportNumberMenuListRoot }}>
						{this.renderSupportNumber()}
					</MenuList>

					{!isClientApp && !isAdminApp && (
						<span>
							<MenuList>
								<Notifications refreshNotifications = {refreshNotifications} device='desktop' />
							</MenuList>
						</span>
					)}

					<span>
						<ProfileNavComponent profileNavigation={account ? profileNavigation : []} />
					</span>
				</Grid>
				{/* Removed this logout button and added this function in profileNavigation  */}
				{/*
        < Grid item container justify="flex-end" alignContent="center" md={1} >
          <MenuList classes={{ root: classes.menuListRoot }}>
            <LoginComponent />
          </MenuList>
        </Grid > */}
			</Grid>
		);
	}
}

DesktopNavigationV1.propTypes = {
	classes: PropTypes.shape({
		menuListRoot: PropTypes.string.isRequired,
		logoWrapper: PropTypes.string.isRequired,
		navbarWrapper: PropTypes.string.isRequired,
		fontBoldHeader: PropTypes.string.isRequired,
		paperPopper: PropTypes.string.isRequired,
		subNavRoot: PropTypes.string.isRequired,
		gridFlexWrapper: PropTypes.string.isRequired,
	}).isRequired,
	menuNavigation: menuNavigationProps.isRequired,
};

DesktopNavigationV1.contextType = UserContext;
export default withTranslation()(withStyles(styles)(DesktopNavigationV1));
