import { CircularProgress, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { textFieldStyles } from '../../Configurations/ExpenseEntryConfig';
import { valueAsNumber } from '../../Utils/ValueAsUtils';
import ExpenseTextField from './ExpenseTextField';

const styles = makeStyles((theme) => {
	const baseStyles = textFieldStyles(theme);
	return {
		...baseStyles,
		autoCompleteInputRoot: {
			paddingBottom: '0px !important',
			paddingRight: '16px !important',
		},
		autoCompleteInput: {
			...baseStyles.input,
		},
		autoCompletePaper: {
			fontWeight: 'normal',
		},
		autoCompleteClear: {
			marginRight: '8px',
		},
	};
});

export default function ExpenseAutocomplete(props) {
	const classes = styles();
	const {
		startAdornment,
		label,
		error,
		helperText,
		required,
		onChange,
		value,
		minLength = 1,
		inputProps,
		getOptionsMethod,
		optionLabelKey,
		optionSelectedKey,
		valueAsMethod,
	} = props;
	const excludedProps = [
		'startAdornment',
		'error',
		'helperText',
		'required',
		'inputProps',
		'minLength',
		'getOptionsMethod',
		'optionLabelKey',
		'optionSelectedKey',
		'valueAsMethod',
	];
	const includedProps = Object.keys(props).reduce((result, key) => {
		if (!excludedProps.includes(key)) {
			result[key] = props[key];
		}
		return result;
	}, {});
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [inputValue, setInputValue] = useState('');
	const defaultNoOptionsText = `Please enter a minimum of ${minLength} character(s)`;
	const [noOptionsText, setNoOptionsText] = useState(defaultNoOptionsText);
	const [isLoading, setIsLoading] = useState(false);

	const debounced = useDebouncedCallback((input) => {
		if (input.length >= minLength && open) {
			handleSearch();
		} else if (!input) {
			setOptions([]);
		}
	}, 1000);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	const handleSearch = async () => {
		setIsLoading(true);
		try {
			let response = await getOptionsMethod(inputValue);
			setOptions([response]);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		} finally {
			setNoOptionsText('No options');
		}
	};

	const handleInputChange = (e, newInputValue, reason) => {
		//TDS-8120 leaving this commented out in case someone changes their mind on this functionatily
		//This solves: After searching for a booking number/clinician id, if I highlight the number to overwrite it, the first number I select is ignored.
		//However, booking details will not be changed until a new booking is selected or the field is cleared. Tested that booking and clinician ids can't be mismatched
		//if (reason && reason === 'input' && value) {
		//	onChange(null);
		//	setOptions([]);
		//}
		if (!newInputValue) {
			setNoOptionsText(defaultNoOptionsText);
		}
		if (reason !== 'reset') {
			debounced(newInputValue);
		}
		if (valueAsMethod) {
			setInputValue(valueAsNumber(newInputValue));
		} else {
			setInputValue(newInputValue);
		}
	};


	return (
		<Autocomplete
			{...includedProps}
			classes={{
				inputRoot: classes.autoCompleteInputRoot,
				input: classes.autoCompleteInput,
				paper: classes.autoCompletePaper,
				clearIndicator: classes.autoCompleteClear,
			}}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			noOptionsText={noOptionsText}
			options={options}
			onChange={(e, newValue) => onChange(newValue)}
			onInputChange={(e, newInputValue, reason) => {
				handleInputChange(e, newInputValue, reason);
			}}
			inputValue={inputValue}
			loading={isLoading}
			getOptionLabel={(option) =>
				option[optionLabelKey]?.toString() || option.toString() || ''
			}
			getOptionSelected={(option, value) =>
				option === value || '' + option[optionSelectedKey] === value
			}
			renderInput={(params) => (
				<ExpenseTextField
					{...params}
					label={label}
					inputProps={{ ...params.inputProps, ...inputProps }}
					InputProps={{
						...params.InputProps,
						classes: {
							root: classes.inputRoot,
							error: classes.error,
						},
						startAdornment: startAdornment && startAdornment,
						endAdornment: (
							<>{isLoading ? <CircularProgress color='inherit' size={20} /> : null}</>
						),
					}}
					placeholder={props.placeholder}
					InputLabelProps={{
						shrink: true,
						classes: {
							asterisk: classes.inputLabelAsterisk,
						},
					}}
					required={required}
					error={error}
					helperText={helperText}
				/>
			)}
		/>
	);
}
