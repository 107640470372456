import React, { useEffect, useState, memo, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { useForm, Controller, useFieldArray, FormProvider, useFormContext } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import WithContexts from '../../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import {
	Checkbox,
	NativeSelect,
	ThemeProvider,
	FormHelperText,
	CircularProgress,
	Input,
} from '@material-ui/core';
import {
	COLORS,
	locumsPosition,
	ACTIVE_PATHNAME_AP,
	specialties,
	CRED_STATE,
} from '../../../../../../utils/Application_Constants';
import TextFieldComponent from '../../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { statesForLicense } from '../../../../Account/accountOptions';
import createTheme from '@material-ui/core/styles/createTheme';
import DatePickerComponent from '../../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import RouteDeparturePrompt from '../../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	getWorkHistories,
	postWorkHistories,
	deleteWorkHistories,
} from '../../../../../../services/Credentialing';
import { withStyles } from '@material-ui/core';
import InputMask from 'react-input-mask';
import DateFnsUtils from '@date-io/date-fns';
import { convertToDateIgnoringTime, matchesTemplate } from '../../../../../../utils/helpers';
import _ from 'lodash';
import Alert from '@material-ui/lab/Alert';
import {
	hospitalAffiliationSectionCheck,
	practiceExperienceSectionCheck,
	hospitalAffiliationSectionPointsCheck,
	practiceExperienceSectionPointsCheck,
} from '../../../../../../utils/CredentialingAppUtility';
import RequiredQuestionDialog from '../../../../../GlobalComponents/Dialog/RequiredQuestionDialog';

const styles = (theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	credAppFirstHeader: {
		padding: '20px 0px',
		borderTop: '1px solid #e7e7e7',
		borderBottom: '1px solid #e7e7e7',
		margin: '30px',
		marginTop: '-15px',
	},
	headerText: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '500',
		fontSize: '14px',
		whiteSpace: 'wrap',
		textAlign: 'left',
		paddingLeft: '33px !important',
		paddingRight: '30px',
		paddingBottom: '10px',
	},
	headerTextNR: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '500',
		fontSize: '14px',
		whiteSpace: 'wrap',
		textAlign: 'left',
		paddingLeft: '33px !important',
		paddingRight: '30px',
		paddingTop: '1rem',
	},
	presentText: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '500',
		fontSize: '16px',
		whiteSpace: 'wrap',
		textAlign: 'left',
		paddingTop: '10px',
	},
	// inputRadio: {
	//     color: COLORS.RAVEN,
	//     "&.Mui-focused": {
	//         color: COLORS.RAVEN
	//     },

	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',

	// },

	// inputLabel: {
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase'
	// },
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			// marginBottom: '10px'
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	gridRootForCheckBox: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			// marginBottom: '10px',
			marginTop: '15px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	completedIcon: {
		color: 'green',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	checkbox: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '15px',
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '10px 10px',
		},
	},
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		'& input': {
			height: '32px',
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.RAVEN,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: `${COLORS.RAVEN}`,
			borderWidth: 1,
			borderRadius: 1,
		},
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
	},

	cssFocused: {},
	hideDeleteBtn: {
		display: 'none',
	},
	disabled: {},
	disabledInput: {
		opacity: 0.6,
	},
	disabledSelect: {
		opacity: 0.6,
		cursor: 'default',
	},
	focused: {},
	error: {
		border: `1px solid ${COLORS.LT_PEPPER} !important`, //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		[theme.breakpoints.up('md')]: {
			marginTop: '25vh',
			marginLeft: '30vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '20vh',
			marginLeft: '25vh',
		},
	},
	successGrid: {
		marginTop: '25px',
	},
	iconButtonMargin: {
		marginRight: '10px',
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
	},
	secondaryHeaderArea: {
		alignItems: 'center',
		maxWidth: '100%',
		marginTop: '5px',
		marginBottom: '10px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	errorColor: {
		color: `${COLORS.LT_PEPPER} !important`,
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: `${COLORS.LT_PEPPER} !important`,
			},
		},
	},
	marginLeft32: {
		marginLeft: 32,
	},
	marginLeft12: {
		marginLeft: 12,
	},
	leftPad30: {
		paddingLeft: '30px !important',
	},
	checkboxInput: {
		padding: '16px',
		justifyContent: 'left',
	},
	infoMessage: {
		color: COLORS.RAVEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '10px !important',
		paddingBottom: '15px',
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '32px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
});

const NestedInput = memo(
	({
		item,
		index,
		renderIndex,
		firstRender,
		values,
		isHistoryEmpty,
		isRequired,
		children,
		register,
		errors,
		formState: { isDirty, dirtyFields },
	}) => {
		return <div>{children}</div>;
	},
	(prevProps, nextProps) => {
		const { workHistories } = nextProps.values;

		var render = nextProps.firstRender.current;
		nextProps.firstRender.current = false;

		if (nextProps.index === 0) {
			//Used to ensure that a form with a single Blank Item won't block on validation run this check only on first item in list for performance reasons.
			nextProps.isHistoryEmpty(nextProps.index, nextProps.isRequired);
		}

		if (
			!workHistories ||
			nextProps.renderIndex === nextProps.index ||
			nextProps.index + 1 === workHistories.length ||
			nextProps.index === 0
		) {
			return false; // render
		}

		if (nextProps.renderIndex !== nextProps.index && !render) {
			return true; // dont render
		}

		return false; // render
	}
);

export const NestedInputContainer = ({
	item,
	index,
	renderIndex,
	values,
	isHistoryEmpty,
	isRequired,
	children,
}) => {
	const methods = useFormContext();
	const firstRender = useRef(true);

	return (
		<NestedInput
			item={item}
			index={index}
			renderIndex={renderIndex}
			firstRender={firstRender}
			values={values}
			isHistoryEmpty={isHistoryEmpty}
			isRequired={isRequired}
			{...methods}
		>
			{children}
		</NestedInput>
	);
};

function WorkHistoriesForm({
	classes,
	t,
	setSaveHandler,
	blockedBackNavigation,
	activePath,
	credState,
	setCredAppError,
	UserContext,
	flags,
	updateFlags,
	type,
	constantType,
	sectionCompletion,
	updateSectionCompletion,
	shouldHighlightFields,
	updateHighlightFieldsState,
}) {
	const normalTheme = createTheme({
		overrides: {
			MuiSelect: {
				select: {},
			},
		},
	});
	const errorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS

					borderRadius: '3px !important',
					border: '1px solid red !important',
				},
			},
		},
	});

	const [formTemplate] = useState({
		id: null,
		hospital: '',
		hospitalId: null,
		streetAddress: '',
		city: '',
		state: '',
		zipCode: '',
		facilityContactFirstName: '',
		facilityContactLastName: '',
		facilityContactPhone: '',
		facilityContactFax: '',
		dateFrom: null,
		dateTo: null,
		locumsPosition: '',
		specialty: '',
		isCurrentlyWorkingHere: null,
		jobTitle: '',
	});

	const itemValidationTemplate = {
		id: 'null',
		hospital: '',
		hospitalId: 'null',
		streetAddress: '',
		city: '',
		state: '',
		zipCode: '',
		facilityContactFirstName: '',
		facilityContactLastName: '',
		facilityContactPhone: '',
		facilityContactFax: '',
		dateFrom: null,
		dateTo: null,
		locumsPosition: '',
		isCurrentlyWorkingHere: null,
		jobTitle: '',
	};

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isRequired, setIsRequired] = useState(false);

	const [deleteRecordIndex, setDeleteRecordIndex] = useState();
	const [deleteRecordId, setDeleteRecordId] = useState();
	const [deleteRecords, setDeleteRecords] = useState([]);
	const [triggerToggle, setTriggerToggle] = useState([false]);
	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);
	const [renderIndex, setRenderIndex] = useState(null);

	const methods = useForm({
		mode: 'all',
		defaultValues: {
			id: null,
			hospital: '',
			hospitalId: null,
			streetAddress: '',
			city: '',
			state: '',
			zipCode: '',
			facilityContactFirstName: '',
			facilityContactLastName: '',
			facilityContactPhone: '',
			facilityContactFax: '',
			dateFrom: null,
			dateTo: null,
			locumsPosition: '',
			specialty: '',
			isCurrentlyWorkingHere: null,
			jobTitle: '',
		},
	});

	const { register, control, handleSubmit, errors, reset, getValues, watch, formState, trigger } =
		methods;

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			setSaveHandler(
				WorkHistoriesForm,
				type,
				handleSubmit(async (response) => await onSubmit(response))
			);

			await getWorkHistories(externalId, type)
				.then(function (response) {
					const { workHistories = [] } = response;
					console.log('response is', response);

					if (workHistories.length === 0) {
						append({
							id: null,
							hospital: '',
							hospitalId: null,
							streetAddress: '',
							city: '',
							state: '',
							zipCode: '',
							facilityContactFirstName: '',
							facilityContactLastName: '',
							facilityContactPhone: '',
							facilityContactFax: '',
							dateFrom: null,
							dateTo: null,
							locumsPosition: '',
							specialty: '',
							isCurrentlyWorkingHere: null,
							jobTitle: '',
						});
						response['workHistories'].push({
							id: null,
							hospital: '',
							hospitalId: null,
							streetAddress: '',
							city: '',
							state: '',
							zipCode: '',
							facilityContactFirstName: '',
							facilityContactLastName: '',
							facilityContactPhone: '',
							facilityContactFax: '',
							dateFrom: null,
							dateTo: null,
							locumsPosition: '',
							specialty: '',
							isCurrentlyWorkingHere: null,
							jobTitle: '',
						});
					} else {
						response['workHistories'].map((item) => {
							if (item.hospital === null) {
								item.hospital = '';
							}
							if (item.streetAddress === null) {
								item.streetAddress = '';
							}
							if (item.city === null) {
								item.city = '';
							}
							if (item.state === null) {
								item.state = '';
							}
							if (item.zipCode === null) {
								item.zipCode = '';
							}
							if (item.facilityContactFirstName === null) {
								item.facilityContactFirstName = '';
							}
							if (item.facilityContactLastName === null) {
								item.facilityContactLastName = '';
							}
							if (item.facilityContactPhone === null) {
								item.facilityContactPhone = '';
							}
							if (item.facilityContactFax === null) {
								item.facilityContactFax = '';
							}
							if (item.locumsPosition === null) {
								item.locumsPosition = '';
							}
							if (item.specialty === null) {
								item.specialty = '';
							}
							if (item.jobTitle === null) {
								item.jobTitle = '';
							}

							return item;
						});
					}
					flags.dataSaved = false;
					updateFlags(flags);
					updateSectionCompletion(sectionCompletion);
					reset(response);
					setIsLoaded(true);

					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status = {
							status: 'incomplete',
							missingFields: [],
						};
						if (type === 'hospitalAffiliations') {
							status = hospitalAffiliationSectionCheck(
								response[`workHistories`],
								activePath
							);
						} else if (type === 'practiceExperiences') {
							status = practiceExperienceSectionCheck(
								response[`workHistories`],
								activePath
							);
						}
						if (!!status.missingFields) {
							//alert(`missingFields: ${status.missingFields}`)
							setHighlights(status.missingFields);
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, []);

	useEffect(() => {
		isLoaded && trigger();
	}, [isLoaded, isRequired, triggerToggle]);

	function checkHighlights(value) {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};

			var checkValues = getValues();

			if (type === 'hospitalAffiliations') {
				status = hospitalAffiliationSectionCheck(checkValues.workHistories, activePath);
			} else if (type === 'practiceExperiences') {
				status = practiceExperienceSectionCheck(checkValues.workHistories, activePath);
			}

			var rtn = status.missingFields.includes(value);
			return rtn;
		}
		return false;
	}

	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'workHistories',
		keyName: 'internalId',
	});

	const isHistoryEmpty = async (index, required) => {
		const { workHistories } = getValues();
		if (!!workHistories) {
			if (workHistories.length > 1 && required !== true) {
				setIsRequired(true);
				return true;
			} else if (workHistories.length > 1 && required === true) {
				return false;
			} else {
				var aEmpty = _.isEqual(workHistories[index], itemValidationTemplate);
				if (aEmpty) {
					if (required !== false) {
						setIsRequired(false);
						return true;
					}
				} else {
					if (required !== true) {
						setIsRequired(true);
						return true;
					}
				}
			}
		}
		return false;
	};

	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('workHistories')) {
			for (let i = 0; i < errors.workHistories.length; i++) {
				if (!!errors?.workHistories[i]) {
					//This would be 'return anError.type !== 'manual'', but manually set errors return a malformed error object with no 'type' field (the contents of the type field are inserted as an array of chars on the main object)
					missingRequiredToSaveFields = Object.values(errors.workHistories[i]).filter(
						(anError) => {
							return anError.hasOwnProperty('type');
						}
					);
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	async function onSubmit(data) {
		let success = false;

		const { workHistories } = data;

		let indexes = _.keys(_.pickBy(workHistories, formTemplate));
		for (let i = 0; i < indexes.length; i++) {
			workHistories.splice(indexes[i], 1);
		}

		const { externalId } = UserContext;
		data['clinicianId'] = externalId;
		if (!!data['workHistories']) {
			data['workHistories'] = data['workHistories']
				.map((item) => {
					if (item.id === 'null') {
						item.id = null;
					}
					if (item.hospitalId === 'null') {
						item.hospitalId = null;
					}

					if (matchesTemplate(item, formTemplate)) {
						return null;
					}

					item.facilityContactPhone = !item.facilityContactPhone
						? null
						: item.facilityContactPhone.replace(/-/g, '').trim();
					item.facilityContactFax = !item.facilityContactFax
						? null
						: item.facilityContactFax.replace(/-/g, '').trim();

					if (item.dateFrom !== null) {
						let firstDay = moment(item.dateFrom).startOf('month').format('MM/DD/yyyy');
						item.dateFrom = !firstDay ? null : moment(firstDay).toISOString(true);
					}
					if (item.dateTo !== null) {
						let lastDay = moment(item.dateTo).endOf('month').format('MM/DD/yyyy');
						item.dateTo = !lastDay ? null : moment(lastDay).toISOString(true);
					}

					//item.dateFrom = moment(item.dateFrom).toISOString(true)
					//item.dateTo = moment(item.dateTo).toISOString(true)

					if (item.isCurrentlyWorkingHere === true) {
						item.dateTo = null;
					} else {
						item.dateTo = moment(item.dateTo).toISOString(true);
					}

					return item;
				})
				.filter((d) => d !== null);
		}

		if (deleteRecords.length > 0) {
			const deleteRecordsNumbers = deleteRecords.filter(function (element) {
				return element !== 'null';
			});
			await deleteWorkHistories(externalId, deleteRecordsNumbers, type).catch((e) => {
				setCredAppError(e);
			});
		}

		console.log('before json', data);
		const jsonData = JSON.stringify(data);
		console.log('post payload is:', jsonData);
		await postWorkHistories(externalId, jsonData, type);

		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;

		if (type === 'hospitalAffiliations') {
			sectionCompletion.workHistory.hospitalAffiliations.current =
				await hospitalAffiliationSectionPointsCheck(
					data['workHistories'],
					activePath,
					sectionCompletion
				);
			updateSectionCompletion(sectionCompletion);
		} else if (type === 'practiceExperiences') {
			sectionCompletion.workHistory.practiceExperience.current =
				await practiceExperienceSectionPointsCheck(
					data['workHistories'],
					activePath,
					sectionCompletion
				);
			updateSectionCompletion(sectionCompletion);
		}
		updateFlags(flags);
		setIsLoaded(false);
		return success;
	}

	const deleteEntry = (btnPressed, index) => {
		if (btnPressed === 'Yes') {
			if (deleteRecordId !== null) {
				deleteRecords.push(deleteRecordId);
				setDeleteRecords(deleteRecords);
				setShowDeleteModal(false);
				setTriggerToggle(!triggerToggle);
			}
			remove(deleteRecordIndex);
			setShowDeleteModal(false);
		}
		setShowDeleteModal(false);
	};

	const openDeleteConfirmation = (item, index) => {
		setDeleteRecordIndex(index);
		setDeleteRecordId(item.id);
		setShowDeleteModal(true);
	};

	const numberValidation = (value) => {
		//console.log(`numberValidation: ${value}`)
		if (!!!value) {
			return true;
		}

		const number = value.replace(/-/g, '').trim();
		//console.log('inside validate')
		if (!number) return true;
		return number.length === 10 && !isNaN(number) ? true : false;
	};

	const renderForm = (item, index) => {
		return (
			<>
				<Grid container>
					<Grid
						container
						className={
							index === 0 ? classes.credAppFirstHeader : 'credAppAdditionalHeader'
						}
					>
						<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
							{index === 0
								? t(`credentials:WORK_HISTORIES.TITLE_${constantType}`)
								: t(`credentials:WORK_HISTORIES.ADDITIONAL_${constantType}`)}
						</Grid>
						<Grid
							item
							xs={3}
							className={
								item.isVerified || credState === CRED_STATE.CLOSED
									? classes.hideDeleteBtn
									: 'credAppAdditionalDelete'
							}
							onClick={() => {
								openDeleteConfirmation(item, index);
							}}
						>
							{t('credentials:WORK_HISTORIES.DELETE_BTN')}
						</Grid>
					</Grid>
				</Grid>
				<CssBaseline />
				<RouteDeparturePrompt
					blockedBackNavigation={blockedBackNavigation}
					when={!flags.dataSaved ? !!Object.keys(formState.dirtyFields).length : false}
				/>

				{activePath === ACTIVE_PATHNAME_AP &&
				(constantType === 'HOSPITAL_AFFILIATIONS' ||
					constantType === 'PRACTICE_EXPERIENCE') ? (
					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor={`workHistories[${index}].specialty`}
									classes={{ root: classes.inputLabel }}
									error={
										(errors?.workHistories &&
											errors?.workHistories[`${index}`]?.specialty) ||
										(localShouldHighlightFields &&
											checkHighlights(`workHistories[${index}].specialty`))
									}
									//className={errors?.workHistories && errors?.workHistories[`${index}`]?.specialty && classes.errorColor}
								>
									{t('credentials:WORK_HISTORIES.SPECIALTY')}
								</InputLabel>
								<ThemeProvider
									theme={
										(errors?.workHistories &&
											errors?.workHistories[`${index}`]?.specialty) ||
										(localShouldHighlightFields &&
											checkHighlights(`workHistories[${index}].specialty`))
											? errorTheme
											: normalTheme
									}
								>
									<NativeSelect
										id={`workHistories[${index}].specialty`}
										name={`workHistories[${index}].specialty`}
										defaultValue={`${item.specialty}`}
										className={
											!!item.id && credState === CRED_STATE.CLOSED
												? classes.disabledSelect
												: classes.normalInput
										}
										disabled={!!item.id && credState === CRED_STATE.CLOSED}
										inputRef={register()}
										onFocus={() => setRenderIndex(index)}
										input={
											<TextFieldComponent
												style={{ border: `0px solid ${COLORS.RAVEN}` }}
												classes={{ input: classes.setWidthTo100OnMobile }}
											/>
										}
									>
										<option key={''} value={null}></option>
										{specialties.map((item) => (
											<option key={item.value} value={item.value}>
												{item.label}
											</option>
										))}
									</NativeSelect>
								</ThemeProvider>
								{((errors?.workHistories &&
									errors?.workHistories[`${index}`]?.specialty) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].specialty`))) && (
									<FormHelperText className={'Mui-error'}>
										{t('credentials:VALIDATION_MESSAGE.SPECIALTY')}
									</FormHelperText>
								)}
							</FormControl>
							{!!item.id && credState === CRED_STATE.CLOSED ? (
								<Input
									style={{ display: 'none' }}
									name={`workHistories[${index}].specialty`}
									value={`${item.specialty}`}
									inputRef={register()}
								/>
							) : null}
						</Grid>
					</Grid>
				) : (
					''
				)}

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].hospital`}
								classes={{ root: classes.inputLabel }}
								required
								error={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.hospital) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].hospital`))
								}
							>
								{constantType === 'PRACTICE_EXPERIENCE'
									? t('credentials:WORK_HISTORIES.PRACTICE')
									: t('credentials:WORK_HISTORIES.HOSPITAL')}
							</InputLabel>
							<TextField
								id={`workHistories[${index}].hospital`}
								name={`workHistories[${index}].hospital`}
								required
								margin='dense'
								variant='outlined'
								className={
									!!item.id &&
									(credState === CRED_STATE.CLOSED ||
										credState === CRED_STATE.PARTIALLY_OPEN)
										? classes.disabledInput
										: classes.textField
								}
								defaultValue={`${item.hospital}`}
								inputRef={register({
									required: isRequired,
									maxLength: {
										value: 80,
										message: t('common:MESSAGES.CHARACTER_LIMIT'),
									},
									pattern: /^[A-Za-z0-9][\s\S]{2,}/,
								})}
								onFocus={() => setRenderIndex(index)}
								helperText={
									((errors?.workHistories &&
										errors?.workHistories[`${index}`]?.hospital &&
										errors?.workHistories[`${index}`]?.hospital['type'] !==
											'maxLength') ||
										(localShouldHighlightFields &&
											checkHighlights(`workHistories[${index}].hospital`))) &&
									t(
										`credentials:VALIDATION_MESSAGE.NAME_FOR_FACILITY_${constantType}`
									)
								}
								error={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.hospital) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].hospital`))
								}
								autoComplete='off'
								InputProps={{
									readOnly:
										!!item.id &&
										(credState === CRED_STATE.CLOSED ||
											credState === CRED_STATE.PARTIALLY_OPEN),
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
										error: classes.error,
									},
								}}
							/>
							<Input
								autoComplete='off'
								variant='outlined'
								style={{ display: 'none' }}
								name={`workHistories[${index}].id`}
								fullWidth
								value={`${item.id}` || null}
								defaultValue={`${item.id}`}
								inputRef={register()}
							/>
							<Input
								autoComplete='off'
								variant='outlined'
								name={`workHistories[${index}].hospitalId`}
								style={{ display: 'none' }}
								fullWidth
								value={`${item.hospitalId}` || null}
								defaultValue={`${item.hospitalId}`}
								inputRef={register()}
							/>
						</FormControl>
					</Grid>
					{((errors?.workHistories &&
						errors?.workHistories[`${index}`]?.hospital &&
						errors?.workHistories[`${index}`]?.hospital['type'] === 'maxLength') ||
						(localShouldHighlightFields &&
							checkHighlights(`workHistories[${index}].hospital`))) && (
						<span className={`${classes.cssLabel} ${classes.marginLeft32}`}>
							{errors?.workHistories &&
								errors?.workHistories[`${index}`]?.hospital &&
								errors?.workHistories[`${index}`]?.hospital.message}
						</span>
					)}
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].streetAddress`}
								classes={{ root: classes.inputLabel }}
								error={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.streetAddress) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].streetAddress`))
								}
							>
								{t('credentials:WORK_HISTORIES.ADDRESS')}
							</InputLabel>
							<TextField
								id={`workHistories[${index}].streetAddress`}
								name={`workHistories[${index}].streetAddress`}
								autoComplete='off'
								margin='dense'
								variant='outlined'
								error={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.streetAddress) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].streetAddress`))
								}
								className={
									!!item.id && credState === CRED_STATE.CLOSED
										? classes.disabledInput
										: classes.textField
								}
								defaultValue={`${item.streetAddress}`}
								inputRef={register({
									maxLength: {
										value: 255,
										message: t('common:MESSAGES.CHARACTER_LIMIT'),
									},
								})}
								onFocus={() => setRenderIndex(index)}
								InputProps={{
									readOnly: !!item.id && credState === CRED_STATE.CLOSED,
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
										error: classes.error,
									},
								}}
							/>
						</FormControl>
					</Grid>
					{((errors?.workHistories &&
						errors?.workHistories[`${index}`]?.streetAddress &&
						errors?.workHistories[`${index}`]?.streetAddress['type'] === 'maxLength') ||
						(localShouldHighlightFields &&
							checkHighlights(`workHistories[${index}].streetAddress`))) && (
						<span className={`${classes.cssLabel} ${classes.marginLeft32}`}>
							{errors?.workHistories &&
								errors?.workHistories[`${index}`]?.streetAddress &&
								errors?.workHistories[`${index}`]?.streetAddress.message}
						</span>
					)}
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].city`}
								classes={{ root: classes.inputLabel }}
								error={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.city) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].city`))
								}
								required
							>
								{t('credentials:WORK_HISTORIES.CITY')}
							</InputLabel>
							<TextField
								id={`workHistories[${index}].city`}
								name={`workHistories[${index}].city`}
								margin='dense'
								variant='outlined'
								required
								error={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.city) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].city`))
								}
								className={
									!!item.id && credState === CRED_STATE.CLOSED
										? classes.disabledInput
										: classes.textField
								}
								defaultValue={`${item.city}`}
								inputRef={register({
									required: isRequired,
									maxLength: {
										value: 255,
										message: t('common:MESSAGES.CHARACTER_LIMIT'),
									},
									pattern: /^[A-Za-z0-9]/,
								})}
								onFocus={() => setRenderIndex(index)}
								helperText={
									errors?.workHistories &&
									errors?.workHistories[`${index}`]?.city &&
									errors?.workHistories[`${index}`]?.city['type'] === 'maxLength'
										? t('common:MESSAGES.CHARACTER_LIMIT')
										: (errors?.workHistories &&
												errors?.workHistories[`${index}`]?.city) ||
										  (localShouldHighlightFields &&
												checkHighlights(`workHistories[${index}].city`))
										? t('credentials:VALIDATION_MESSAGE.CITY')
										: null
								}
								InputProps={{
									readOnly: !!item.id && credState === CRED_STATE.CLOSED,
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
										error: classes.error,
									},
								}}
							/>
						</FormControl>
					</Grid>

					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].state`}
								classes={{ root: classes.inputLabel }}
								required
								error={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.state) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].state`))
								}
								//className={errors?.workHistories && errors?.workHistories[`${index}`]?.state && classes.errorColor}
							>
								{t('credentials:WORK_HISTORIES.STATE')}
							</InputLabel>
							<ThemeProvider
								theme={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.state) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].state`))
										? errorTheme
										: normalTheme
								}
							>
								<NativeSelect
									id={`workHistories[${index}].state`}
									name={`workHistories[${index}].state`}
									defaultValue={`${item.state}`}
									className={
										!!item.id &&
										(credState === CRED_STATE.CLOSED ||
											credState === CRED_STATE.PARTIALLY_OPEN)
											? classes.disabledSelect
											: classes.normalInput
									}
									disabled={
										!!item.id &&
										(credState === CRED_STATE.CLOSED ||
											credState === CRED_STATE.PARTIALLY_OPEN)
									}
									inputRef={register({ required: isRequired })}
									onFocus={() => setRenderIndex(index)}
									input={
										<TextFieldComponent
											style={{ border: `0px solid ${COLORS.RAVEN}` }}
											classes={{ input: classes.setWidthTo100OnMobile }}
										/>
									}
								>
									<option key={''} value={null}></option>
									{statesForLicense.map((item) => (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									))}
								</NativeSelect>
							</ThemeProvider>
							{((errors?.workHistories && errors?.workHistories[`${index}`]?.state) ||
								(localShouldHighlightFields &&
									checkHighlights(`workHistories[${index}].state`))) && (
								<FormHelperText className={'Mui-error'}>
									{t('credentials:VALIDATION_MESSAGE.STATE')}
								</FormHelperText>
							)}
						</FormControl>
						{!!item.id && credState === CRED_STATE.CLOSED ? (
							<Input
								style={{ display: 'none' }}
								name={`workHistories[${index}].state`}
								value={`${item.state}`}
								inputRef={register()}
							/>
						) : null}
					</Grid>

					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].zipCode`}
								classes={{ root: classes.inputLabel }}
								error={
									(!!errors?.workHistories &&
										errors?.workHistories[`${index}`]?.zipCode) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].zipCode`))
								}
							>
								{t('credentials:WORK_HISTORIES.ZIP_CODE')}
							</InputLabel>

							<TextField
								id={`workHistories[${index}].zipCode`}
								name={`workHistories[${index}].zipCode`}
								margin='dense'
								variant='outlined'
								className={
									!!item.id && credState === CRED_STATE.CLOSED
										? classes.disabledInput
										: classes.textField
								}
								defaultValue={`${item.zipCode}`}
								inputRef={register({
									minLength: 5,
									maxLength: {
										value: 5,
										message: t('common:MESSAGES.CHARACTER_LIMIT'),
									},
								})}
								onFocus={() => setRenderIndex(index)}
								error={
									(!!errors?.workHistories &&
										errors?.workHistories[`${index}`]?.zipCode) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].zipCode`))
								}
								InputProps={{
									readOnly: !!item.id && credState === CRED_STATE.CLOSED,
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
										error: classes.error,
									},
								}}
							/>
							{errors?.workHistories &&
								errors?.workHistories[`${index}`]?.zipCode &&
								errors?.workHistories &&
								errors?.workHistories[`${index}`]?.zipCode['type'] ===
									'minLength' && (
									<FormHelperText className={'Mui-error'}>
										{t('credentials:VALIDATION_MESSAGE.ZIP_CODE')}
									</FormHelperText>
								)}

							{errors?.workHistories &&
								errors?.workHistories[`${index}`]?.zipCode &&
								errors?.workHistories &&
								errors?.workHistories[`${index}`]?.zipCode['type'] ===
									'maxLength' && (
									<FormHelperText className={'Mui-error'}>
										{t('credentials:VALIDATION_MESSAGE.ZIP_CODE')}
									</FormHelperText>
								)}
						</FormControl>
					</Grid>
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].jobTitle`}
								classes={{ root: classes.inputLabel }}
								error={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.jobTitle) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].jobTitle`))
								}
							>
								{t('credentials:WORK_HISTORIES.JOB_TITLE')}
							</InputLabel>
							<TextField
								id={`workHistories[${index}].jobTitle`}
								name={`workHistories[${index}].jobTitle`}
								className={
									!!item.id && credState === CRED_STATE.CLOSED
										? classes.disabledInput
										: classes.textField
								}
								margin='dense'
								variant='outlined'
								defaultValue={`${item.jobTitle}`}
								inputRef={register({
									maxLength: {
										value: 50,
										message: t('common:MESSAGES.CHARACTER_LIMIT'),
									},
								})}
								onFocus={() => setRenderIndex(index)}
								helperText={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.jobTitle &&
										errors?.workHistories[`${index}`]?.jobTitle['type'] ===
											'maxLength') ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].jobTitle`))
										? t('common:MESSAGES.CHARACTER_LIMIT')
										: null
								}
								error={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.jobTitle) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].jobTitle`))
								}
								InputProps={{
									readOnly: !!item.id && credState === CRED_STATE.CLOSED,
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
										error: classes.error,
									},
								}}
							/>
						</FormControl>
					</Grid>
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].dateFrom`}
								classes={{ root: classes.inputLabel }}
								error={
									(!!errors?.workHistories &&
										errors?.workHistories[`${index}`]?.dateFrom) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].dateFrom`))
								}
								required
							>
								{t('credentials:WORK_HISTORIES.FROM_DATE')}
							</InputLabel>
							<ThemeProvider theme={datePickerTheme}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Controller
										control={control}
										rules={{
											validate: (date) => dateValidation(date),
										}}
										defaultValue={
											`${item.dateFrom}` === 'null'
												? null
												: convertToDateIgnoringTime(`${item.dateFrom}`)
										}
										name={`workHistories[${index}].dateFrom`}
										render={({ onChange, onBlur, value, name }) => (
											<DatePickerComponent
												onChange={onChange}
												name={name}
												value={value}
												format='MM/yyyy'
												placeholder='MM/YYYY'
												clearable
												variant='inline'
												autoOk='true'
												disabled={
													!!item.id &&
													(credState === CRED_STATE.CLOSED ||
														credState === CRED_STATE.PARTIALLY_OPEN)
												}
												minDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												maxDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												invalidDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												margin='dense'
												theme={datePickerTheme}
												error={
													(!!errors?.workHistories &&
														errors?.workHistories[`${index}`]
															?.dateFrom) ||
													(localShouldHighlightFields &&
														checkHighlights(
															`workHistories[${index}].dateFrom`
														))
												}
												KeyboardButtonProps={{
													'aria-label': t(
														'credentials:WORK_HISTORIES.FROM_DATE'
													),
												}}
												InputProps={{
													classes: {
														disabled: classes.disabledInput,
														root: classes.cssOutlinedInput,
													},
												}}
												inputVariant='outlined'
											/>
										)}
									/>
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</FormControl>
					</Grid>

					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].dateTo`}
								classes={{ root: classes.inputLabel }}
								error={
									(!!errors?.workHistories &&
										errors?.workHistories[`${index}`]?.dateTo) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].dateTo`))
								}
								required
							>
								{t('credentials:WORK_HISTORIES.TO_DATE')}
							</InputLabel>
							{watch(`workHistories[${index}].isCurrentlyWorkingHere`) === true ? (
								<h3 className={classes.presentText}>Present</h3>
							) : (
								<ThemeProvider theme={datePickerTheme}>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<Controller
											control={control}
											rules={{
												validate: (date) => dateValidation(date),
											}}
											defaultValue={
												`${item.dateTo}` === 'null'
													? null
													: convertToDateIgnoringTime(`${item.dateTo}`)
											}
											name={`workHistories[${index}].dateTo`}
											render={({ onChange, onBlur, value, name }) => (
												<DatePickerComponent
													onChange={onChange}
													name={name}
													value={value}
													format='MM/yyyy'
													placeholder='MM/YYYY'
													clearable
													variant='inline'
													autoOk='true'
													disabled={
														!!item.id && credState === CRED_STATE.CLOSED
													}
													minDateMessage={
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.DATE'
															)}
														</span>
													}
													maxDateMessage={
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.DATE'
															)}
														</span>
													}
													invalidDateMessage={
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.DATE'
															)}
														</span>
													}
													margin='dense'
													theme={datePickerTheme}
													error={
														(!!errors?.workHistories &&
															errors?.workHistories[`${index}`]
																?.dateTo) ||
														(localShouldHighlightFields &&
															checkHighlights(
																`workHistories[${index}].dateTo`
															))
													}
													KeyboardButtonProps={{
														'aria-label': t(
															'credentials:WORK_HISTORIES.TO_DATE'
														),
													}}
													InputProps={{
														classes: {
															disabled: classes.disabledInput,
															root: classes.cssOutlinedInput,
														},
													}}
													inputVariant='outlined'
												/>
											)}
										/>
									</MuiPickersUtilsProvider>
								</ThemeProvider>
							)}
						</FormControl>
					</Grid>

					<Grid classes={{ root: classes.gridRootForCheckBox }} item xs={12} md={3}>
						{type === 'practiceExperiences' || type === 'hospitalAffiliations' ? (
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor={`workHistories[${index}].isCurrentlyWorkingHere`}
									classes={{ root: classes.inputLabel, error: classes.error }}
									error={
										(errors?.workHistories &&
											errors?.workHistories[`${index}`]
												?.isCurrentlyWorkingHere) ||
										(localShouldHighlightFields &&
											checkHighlights(
												`workHistories[${index}].isCurrentlyWorkingHere`
											))
									}
								>
									{t('credentials:WORK_HISTORIES.CURRENTLY_WORKING')}
								</InputLabel>
								<Controller
									name={`workHistories[${index}].isCurrentlyWorkingHere`}
									control={control}
									rules={{}}
									defaultChecked={item.isCurrentlyWorkingHere}
									render={({ onChange, value, name }) => (
										<Checkbox
											color='primary'
											onChange={(e) => onChange(e.target.checked)}
											checked={value}
											name={name}
											error={
												(errors?.workHistories &&
													errors?.workHistories[`${index}`]
														?.isCurrentlyWorkingHere) ||
												(localShouldHighlightFields &&
													checkHighlights(
														`workHistories[${index}].isCurrentlyWorkingHere`
													))
											}
											className={classes.checkboxInput}
											disabled={!!item.id && credState === CRED_STATE.CLOSED}
										/>
									)}
								/>
							</FormControl>
						) : null}
					</Grid>
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].locumsPosition`}
								classes={{ root: classes.inputLabel }}
								//required
								error={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.locumsPosition) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].locumsPosition`))
								}
								//className={errors?.workHistories && errors?.workHistories[`${index}`]?.locumsPosition && classes.errorColor}
							>
								{t('credentials:WORK_HISTORIES.LOCUMS POSITION')}
							</InputLabel>
							<ThemeProvider
								theme={
									(errors?.workHistories &&
										errors?.workHistories[`${index}`]?.locumsPosition) ||
									(localShouldHighlightFields &&
										checkHighlights(`workHistories[${index}].locumsPosition`))
										? errorTheme
										: normalTheme
								}
							>
								<NativeSelect
									id={`workHistories[${index}].locumsPosition`}
									name={`workHistories[${index}].locumsPosition`}
									defaultValue={`${item.locumsPosition}`}
									className={
										!!item.id && credState === CRED_STATE.CLOSED
											? classes.disabledSelect
											: classes.normalInput
									}
									disabled={!!item.id && credState === CRED_STATE.CLOSED}
									inputRef={register()}
									onFocus={() => setRenderIndex(index)}
									input={
										<TextFieldComponent
											style={{ border: `0px solid ${COLORS.RAVEN}` }}
											classes={{ input: classes.setWidthTo100OnMobile }}
										/>
									}
								>
									<option key={''} value={null}></option>
									{locumsPosition.map((item) => (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									))}
								</NativeSelect>
							</ThemeProvider>
							{((errors?.workHistories &&
								errors?.workHistories[`${index}`]?.locumsPosition) ||
								(localShouldHighlightFields &&
									checkHighlights(`workHistories[${index}].locumsPosition`))) && (
								<FormHelperText className={'Mui-error'}>
									{t('credentials:VALIDATION_MESSAGE.COMPLETED')}
								</FormHelperText>
							)}
						</FormControl>
						{!!item.id && credState === CRED_STATE.CLOSED ? (
							<Input
								style={{ display: 'none' }}
								name={`workHistories[${index}].locumsPosition`}
								value={`${item.locumsPosition}`}
								inputRef={register()}
							/>
						) : null}
					</Grid>
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].facilityContactFirstName`}
								classes={{ root: classes.inputLabel }}
								error={
									(!!errors?.workHistories &&
										errors?.workHistories[`${index}`]
											?.facilityContactFirstName) ||
									(localShouldHighlightFields &&
										checkHighlights(
											`workHistories[${index}].facilityContactFirstName`
										))
								}
							>
								{t('credentials:WORK_HISTORIES.FACILITY_CONTACT_FIRST_NAME')}
							</InputLabel>
							<TextField
								id={`workHistories[${index}].facilityContactFirstName`}
								name={`workHistories[${index}].facilityContactFirstName`}
								className={classes.textField}
								margin='dense'
								variant='outlined'
								defaultValue={`${item.facilityContactFirstName}`}
								inputRef={register({
									maxLength: {
										value: 100,
										message: t('common:MESSAGES.CHARACTER_LIMIT'),
									},
								})}
								onFocus={() => setRenderIndex(index)}
								error={
									(!!errors?.workHistories &&
										errors?.workHistories[`${index}`]
											?.facilityContactFirstName) ||
									(localShouldHighlightFields &&
										checkHighlights(
											`workHistories[${index}].facilityContactFirstName`
										))
								}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
										error: classes.error,
									},
								}}
							/>
						</FormControl>
					</Grid>
					{((errors?.workHistories &&
						errors?.workHistories[`${index}`]?.facilityContactFirstName &&
						errors?.workHistories[`${index}`]?.facilityContactFirstName['type'] ===
							'maxLength') ||
						(localShouldHighlightFields &&
							checkHighlights(
								`workHistories[${index}].facilityContactFirstName`
							))) && (
						<span className={`${classes.cssLabel} ${classes.marginLeft32}`}>
							{errors?.workHistories &&
								errors?.workHistories[`${index}`]?.facilityContactFirstName &&
								errors?.workHistories[`${index}`]?.facilityContactFirstName.message}
						</span>
					)}
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].facilityContactLastName`}
								classes={{ root: classes.inputLabel }}
								error={
									(!!errors?.workHistories &&
										errors?.workHistories[`${index}`]
											?.facilityContactLastName) ||
									(localShouldHighlightFields &&
										checkHighlights(
											`workHistories[${index}].facilityContactLastName`
										))
								}
							>
								{t('credentials:WORK_HISTORIES.FACILITY_CONTACT_LAST_NAME')}
							</InputLabel>
							<TextField
								id={`workHistories[${index}].facilityContactLastName`}
								name={`workHistories[${index}].facilityContactLastName`}
								className={classes.textField}
								margin='dense'
								variant='outlined'
								defaultValue={`${item.facilityContactLastName}`}
								inputRef={register({
									maxLength: {
										value: 100,
										message: t('common:MESSAGES.CHARACTER_LIMIT'),
									},
								})}
								onFocus={() => setRenderIndex(index)}
								error={
									(!!errors?.workHistories &&
										errors?.workHistories[`${index}`]
											?.facilityContactLastName) ||
									(localShouldHighlightFields &&
										checkHighlights(
											`workHistories[${index}].facilityContactLastName`
										))
								}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
										error: classes.error,
									},
								}}
							/>
						</FormControl>
					</Grid>
					{((errors?.workHistories &&
						errors?.workHistories[`${index}`]?.facilityContactLastName &&
						errors?.workHistories[`${index}`]?.facilityContactLastName['type'] ===
							'maxLength') ||
						(localShouldHighlightFields &&
							checkHighlights(
								`workHistories[${index}].facilityContactLastName`
							))) && (
						<span className={`${classes.cssLabel} ${classes.marginLeft32}`}>
							{errors?.workHistories &&
								errors?.workHistories[`${index}`]?.facilityContactLastName &&
								errors?.workHistories[`${index}`]?.facilityContactLastName.message}
						</span>
					)}
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].facilityContactPhone`}
								classes={{ root: classes.inputLabel }}
								error={
									(!!errors?.workHistories &&
										errors?.workHistories[`${index}`]?.facilityContactPhone) ||
									(localShouldHighlightFields &&
										checkHighlights(
											`workHistories[${index}].facilityContactPhone`
										))
								}
							>
								{t('credentials:WORK_HISTORIES.FACILITY_CONTACT_PHONE_NUMBER')}
							</InputLabel>

							<Controller
								control={control}
								name={`workHistories[${index}].facilityContactPhone`}
								rules={{
									validate: (value) => numberValidation(value),
								}}
								onFocus={() => setRenderIndex(index)}
								render={({ onChange, name, onBlur, value, ref }) => (
									<InputMask
										name={name}
										mask='999-999-9999'
										disabled={false}
										maskChar=' '
										defaultValue={`${item.facilityContactPhone}`}
										onBlur={onBlur}
										onChange={onChange}
										inputRef={ref}
									>
										{() => (
											<TextField
												id={`workHistories[${index}].facilityContactPhone`}
												name={name}
												className={classes.textField}
												margin='dense'
												variant='outlined'
												placeholder='XXX-XXX-XXXX'
												defaultValue={`${item.facilityContactPhone}`}
												error={
													(!!errors?.workHistories &&
														errors?.workHistories[`${index}`]
															?.facilityContactPhone) ||
													(!!highlights &&
														highlights.includes(
															`workHistories[${index}].facilityContactPhone`
														))
												}
												helperText={
													((!!errors?.workHistories &&
														errors?.workHistories[`${index}`]
															?.facilityContactPhone) ||
														(!!highlights &&
															highlights.includes(
																`workHistories[${index}].facilityContactPhone`
															))) &&
													t('credentials:VALIDATION_MESSAGE.HOME_PHONE')
												}
												InputProps={{
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
											/>
										)}
									</InputMask>
								)}
							/>
						</FormControl>
					</Grid>
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`workHistories[${index}].facilityContactFax`}
								classes={{ root: classes.inputLabel }}
								error={
									(!!errors?.workHistories &&
										errors?.workHistories[`${index}`]?.facilityContactFax) ||
									(localShouldHighlightFields &&
										checkHighlights(
											`workHistories[${index}].facilityContactFax`
										))
								}
							>
								{t('credentials:WORK_HISTORIES.FACILITY_CONTACT_FAX_NUMBER')}
							</InputLabel>
							<Controller
								control={control}
								name={`workHistories[${index}].facilityContactFax`}
								rules={{
									validate: (value) => numberValidation(value),
								}}
								onFocus={() => setRenderIndex(index)}
								render={({ onChange, name, onBlur, value, ref }) => (
									<InputMask
										name={name}
										mask='999-999-9999'
										disabled={false}
										maskChar=' '
										defaultValue={`${item.facilityContactFax}`}
										onBlur={onBlur}
										onChange={onChange}
										inputRef={ref}
									>
										{({ onChange, name, onBlur, value, ref }) => (
											<TextField
												id={`workHistories[${index}].facilityContactFax`}
												name={name}
												className={classes.textField}
												margin='dense'
												variant='outlined'
												placeholder='XXX-XXX-XXXX'
												defaultValue={`${item.facilityContactFax}`}
												error={
													(!!errors?.workHistories &&
														errors?.workHistories[`${index}`]
															?.facilityContactFax) ||
													(!!highlights &&
														highlights.includes(
															`workHistories[${index}].facilityContactFax`
														))
												}
												helperText={
													((!!errors?.workHistories &&
														errors?.workHistories[`${index}`]
															?.facilityContactFax) ||
														(!!highlights &&
															highlights.includes(
																`workHistories[${index}].facilityContactFax`
															))) &&
													t('credentials:VALIDATION_MESSAGE.FAX_NUMBER')
												}
												InputProps={{
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
											/>
										)}
									</InputMask>
								)}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</>
		);
	};
	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}

		return fields.map((item, index) => (
			<div key={item.internalId}>
				<NestedInputContainer
					item={item}
					index={index}
					renderIndex={renderIndex}
					values={getValues()}
					isHistoryEmpty={isHistoryEmpty}
					isRequired={isRequired}
				>
					{isLoaded ? renderForm(item, index) : null}
					<RequiredQuestionDialog
						open={showDeleteModal}
						title={t(`credentials:WORK_HISTORIES.CONFIRM_DELETE_TITLE_${constantType}`)}
						text={t(
							`credentials:WORK_HISTORIES.CONFIRM_DELETE_MESSAGE_${constantType}`
						)}
						buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
						buttonIDs='Yes;No'
						disableBackgroundClick={false}
						onClose={(btnPressed) => {
							deleteEntry(btnPressed, index);
						}}
					/>
				</NestedInputContainer>
			</div>
		));
	};

	return (
		<>
			<div className={classes.profileWrapper}>
				<Grid container>
					<Grid item xs={12} md={12}>
						<h3 className={classes.header}>
							{t(`credentials:WORK_HISTORIES.TITLE_${constantType}`)}
						</h3>
						{credState === CRED_STATE.CLOSED ||
						credState === CRED_STATE.PARTIALLY_OPEN ? (
							<Grid item xs={12} md={10} className={classes.messageGrid}>
								<Alert
									severity='error'
									className={classes.alertMessage}
									icon={false}
								>
									{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
								</Alert>
							</Grid>
						) : null}
						<h3 className={classes.headerText}>
							{t(`credentials:${constantType}.INFO_MESSAGE`)}
						</h3>
						<h3 className={classes.headerText}>
							{t(`credentials:OTHER_CERTIFICATIONS.NON_REQUIRED`)}
						</h3>
					</Grid>
					<Grid item xs={12} md={12}>
						<FormProvider {...methods}>
							<form
								className={classes.form}
								noValidate
								onSubmit={handleSubmit(onSubmit)}
							>
								{renderForms()}
							</form>
						</FormProvider>
					</Grid>
					<Grid item xs={12} md={12}>
						{isLoaded && credState !== CRED_STATE.CLOSED ? (
							<div
								className={'credAppAddMoreButton'}
								onClick={() => {
									append({
										id: null,
										hospital: '',
										hospitalId: null,
										streetAddress: '',
										city: '',
										state: '',
										zipCode: '',
										facilityContactFirstName: '',
										facilityContactLastName: '',
										facilityContactPhone: '',
										facilityContactFax: '',
										dateFrom: null,
										dateTo: null,
										locumsPosition: '',
										specialty: '',
										isCurrentlyWorkingHere: null,
										jobTitle: '',
									});
									setTriggerToggle(!triggerToggle);
								}}
							>
								{t(`credentials:WORK_HISTORIES.ADD_MORE_${constantType}`)}
							</div>
						) : null}
					</Grid>
				</Grid>
			</div>
		</>
	);
}

export default WithContexts(withTranslation()(withStyles(styles)(WorkHistoriesForm)));
