import React, { Component } from 'react';
import { withStyles, Grid, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../../utils/Application_Constants';
import { NavLink } from 'react-router-dom';
const styles = (theme) => ({
	paper: {
		background: COLORS.LT_JOURNEY,
		color: 'white',
		padding: theme.spacing(4),
		'& a': {
			color: 'white',
		},
	},
});
class GetCredentialsWidgetDashboard extends Component {
	render() {
		const { classes, t } = this.props;
		const parentUrlLink = '/credentials';
		return (
			<Paper className={classes.paper}>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12}>
							<h4 className='cr-white'>
								{t('credentials:GET_CRED_WIDGET_DASHBOARD.TITLE')}
							</h4>
						</Grid>
						<Grid item xs={12} classes={{ root: classes.information }}>
							<p>{t('credentials:GET_CRED_WIDGET_DASHBOARD.INFORMATION1')}</p>
						</Grid>
						<Grid item xs={12} classes={{ root: classes.information }}>
							<p>{t('credentials:GET_CRED_WIDGET_DASHBOARD.INFORMATION2')}</p>
						</Grid>
						<Grid item xs={12} classes={{ root: classes.linkButton }}>
							<NavLink className='textLink' to={{ pathname: parentUrlLink }}>
								{t('credentials:GET_CRED_WIDGET_DASHBOARD.CRED_LINK')}
							</NavLink>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}
export default withTranslation()(withStyles(styles)(GetCredentialsWidgetDashboard));
