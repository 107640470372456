import React from 'react';
import {
	makeStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	Grid,
	Paper,
} from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import { stateAcronymToFullName } from '../../../utils/helpers';
import YTDEarningsComponent from './YTDEarningsComponent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Alert from '@material-ui/lab/Alert';
import { CSVLink } from 'react-csv';

const styles = makeStyles((theme) => ({
	contentRoot: {
		height: '80vh',
	},
	bold: {
		fontWeight: 'bold',
	},
	center: {
		textAlign: 'center',
	},
	dialogContentRoot: {
		margin: '0',
		borderRadius: '5px',
	},
	helpPanelContainerRoot: {
		padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: '10px',
		width: '500px !important',
	},
	linkHeaderTitle: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '18px',
		margin: 0,
		textAlign: 'center',
		whiteSpace: 'nowrap',
		marginTop: '20px',
	},
	linkHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
		whiteSpace: 'nowrap',
	},
	DoughnutContainer: {
		minHeight: '175px !important',
		minWidth: '175px !important',
		paddingRight: '20px',
		marginLeft: '-25px',
	},
	ButtonContainer: {
		marginBottom: '2px',
		textAlign: 'right',
		paddingRight: '20px',
	},
	progressButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		whiteSpace: 'nowrap',
		align: 'right',
	},
	columnHeaderLeft: {
		color: COLORS.BLACK_MARLIN,
		fontSize: '12px',
		textAlign: 'left',
		marginTop: '20px',
		textTransform: 'uppercase',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		fontWeight: 'bold',
		paddingBottom: '5px',
	},
	columnHeaderRight: {
		color: COLORS.BLACK_MARLIN,
		fontSize: '12px',
		textAlign: 'right',
		marginTop: '20px',
		textTransform: 'uppercase',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		fontWeight: 'bold',
	},
	columnHeaderCenter: {
		color: COLORS.BLACK_MARLIN,
		fontSize: '12px',
		textAlign: 'center',
		marginTop: '20px',
		textTransform: 'uppercase',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		fontWeight: 'bold',
	},
	columnInfoLeft: {
		//color: COLORS.RAVEN,
		fontWeight: 'bold',
		fontSize: '12px',
		textAlign: 'left',
		margin: '10px 0 10px 0',
	},
	columnInfoCenter: {
		//color: COLORS.RAVEN,
		fontWeight: 'bold',
		fontSize: '12px',
		textAlign: 'center',
		margin: '10px 0 10px 0',
	},
	columnInfoRight: {
		//color: COLORS.RAVEN,
		fontWeight: 'bold',
		fontSize: '12px',
		textAlign: 'right',
		margin: '10px 0 10px 0',
	},
	columnData: {
		fontSize: '12px',
		textAlign: 'left',
		marginTop: '15%',
	},
	indicatorState: {
		verticalAlign: 'middle',
		color: 'black',
	},
	tableContent: {
		padding: '0px 60px',
		alignitems: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 10px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
}));
function YTDEarningsDetails({ t, openDialog, toggleOpendialog, ytdDataRaw, finalData }) {
	const classes = styles();
	const ytdData = finalData;

	let downloadData = '';
	if (!!ytdDataRaw) {
		downloadData = ytdDataRaw;
	}
	const blankFile = [
		{ id: '', externalId: '', providerID: '', facilityState: '', net: '', ten99amount: '' },
	];

	if (ytdData.text === 'No Data') {
		downloadData = blankFile;
	}

	const numberFormatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	const renderStateColor = (index) => {
		var stateColor = finalData.datasets[0].backgroundColor[index];
		return (
			<FiberManualRecordIcon
				classes={{ root: classes.indicatorState }}
				size='small'
				style={{ color: stateColor }}
			/>
		);
	};

	const consolidateData = () => {
		let netData = {};
		let net1099 = {};

		let tempData = {
			results: [],
		};

		if (!!ytdDataRaw && ytdDataRaw.length > 0) {
			for (let i = 0; i < ytdDataRaw.length; i++) {
				let state = ytdDataRaw[i].facilityState;
				//Calcuate net amount for state
				if (netData.hasOwnProperty(state)) {
					netData[state] += ytdDataRaw[i].net;
				} else {
					Object.assign(netData, { [`${state}`]: ytdDataRaw[i].net });
				}
				// Calculate 1099 amount
				if (net1099.hasOwnProperty(state)) {
					net1099[state] += ytdDataRaw[i].ten99amount;
				} else {
					Object.assign(net1099, { [`${state}`]: ytdDataRaw[i].ten99amount });
				}
			}
		}

		if (netData.size === net1099.size) {
			var jsonData = {};
			Object.keys(netData).forEach(function (key) {
				jsonData['state'] = key;
				jsonData['net'] = netData[key];
				jsonData['net1099'] = net1099[key];
				tempData.results.push(jsonData);
				jsonData = {};
			});
		}
		return tempData.results;
	};

	const renderYTDTableData = () => {
		let data = consolidateData();

		return (
			<>
				{data.map((item, index) => (
					<>
						<Grid item xs={4} className={classes.columnInfoLeft}>
							{renderStateColor(index)}
							{stateAcronymToFullName(item.state)}
						</Grid>
						<Grid
							item
							xs={4}
							className={classes.columnInfoLeft}
							style={{ textAlign: 'center' }}
						>
							{numberFormatter.format(item.net1099)}
						</Grid>
						<Grid
							item
							xs={4}
							className={classes.columnInfoLeft}
							style={{ textAlign: 'right' }}
						>
							{numberFormatter.format(item.net)}
						</Grid>
					</>
				))}
			</>
		);
	};
	return (
		<div className={classes.contentRoot}>
			<Paper classes={{ root: classes.topTileRoot }}>
				<Dialog
					open={openDialog}
					scroll='body'
					maxWidth={'sm'}
					fullWidth
					onClose={() => toggleOpendialog(false)}
				>
					<DialogTitle style={{ padding: '8px 16px' }}>
						<Grid item xs={12} className={classes.linkHeaderTitle}>
							{t(`timeAndPay:HEADERS.YTD_EARNINGS`)}
						</Grid>
					</DialogTitle>
					<DialogContent
						classes={{ root: classes.dialogContentRoot }}
						style={{ padding: '8px 16px' }}
					>
						<YTDEarningsComponent ytdDataRaw={ytdDataRaw} finalData={finalData} />
						<Grid
							container
							justify='flex-end'
							spacing={2}
							className={classes.ButtonContainer}
						>
							<Grid item justify='flex-end' xs={6} md={5} lg={4} xl={3}>
								<CSVLink
									data={downloadData}
									filename={'YTD Earnings.csv'}
									target='_blank'
									className='hidden'
								>
									<Button
										className={classes.progressButton}
										variant='outlined'
										color='primary'
										size='small'
										align='right'
									>
										{t('timeAndPay:BUTTONS.DOWNLOAD')}
									</Button>
								</CSVLink>
							</Grid>
						</Grid>
						{ytdData.text !== 'No Data' ? (
							<Grid
								container
								direction='row'
								className={classes.tableContent}
								style={{ alignItems: 'center' }}
							>
								<Grid items xs={4} className={classes.columnHeaderLeft}>
									{t('timeAndPay:HEADERS.STATE')}
								</Grid>
								<Grid
									items
									xs={4}
									className={classes.columnHeaderLeft}
									style={{ textAlign: 'center' }}
								>
									{t('timeAndPay:HEADERS.1099')}
								</Grid>
								<Grid
									item
									xs={4}
									className={classes.columnHeaderLeft}
									style={{ textAlign: 'right' }}
								>
									{t('timeAndPay:HEADERS.EARNINGS')}
								</Grid>
								{renderYTDTableData()}
							</Grid>
						) : null}
						<Grid item xs={12}>
							<Alert severity='error' className={classes.alertMessage} icon={false}>
								{t(`timeAndPay:INFO.YTD_DISCLAIMER`)}
							</Alert>
						</Grid>
					</DialogContent>
				</Dialog>
			</Paper>
		</div>
	);
}
export default YTDEarningsDetails;
