import { Button, Collapse, Divider, Paper, Typography, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		marginTop: theme.spacing(4),
	},
	lineItem: {
		display: 'flex',
		justifyContent: 'space-between',

		'&:not(:last-child)': {
			marginBottom: theme.spacing(1),
		},
	},
	divider: {
		margin: theme.spacing(1, 0, 3, 0),
	},
	viewMore: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1, 0),

		'&:hover': {
			cursor: 'pointer',
		},
	},
	total: {
		marginTop: theme.spacing(2),
	},
}));

const ExpenseSummaryItem = ({ expense }) => (
	<>
		<Typography variant='body2'>{expense.label}</Typography>
		<Typography variant='body2'>
			${expense.amount ? parseFloat(expense.amount).toFixed(2) : 0}
		</Typography>
	</>
);

const ExpenseSummary = () => {
	const classes = useStyles();
	const { watch } = useFormContext();
	const expenseEntires = watch('expenseEntries');
	const limit = 2;
	const [viewMore, setViewMore] = useState(false);
	const total = expenseEntires.reduce((total, expense) => total + Number(expense.amount), 0);
	const { id } = useParams();

	return (
		<Paper elevation={1} className={classes.paper}>
			<Typography variant='h5'>Expense Summary</Typography>
			<Divider className={classes.divider} />
			{expenseEntires.slice(0, limit).map((expense, index) => (
				<div className={classes.lineItem} key={`${index}-line-item`}>
					<ExpenseSummaryItem expense={expense} />
				</div>
			))}
			<Collapse in={viewMore}>
				{expenseEntires.slice(limit).map((expense, index) => (
					<div className={classes.lineItem} key={`${index}-line-item`}>
						<ExpenseSummaryItem expense={expense} />
					</div>
				))}
			</Collapse>
			{expenseEntires.length > limit && (
				<Typography
					variant='body2'
					onClick={() => setViewMore((prev) => !prev)}
					className={classes.viewMore}
				>
					<b>{viewMore ? 'Show less' : 'View more'}</b>{' '}
					<ExpandMoreIcon
						style={{
							transition: 'transform .25s',
							transform: `${viewMore ? 'rotate(180deg)' : 'rotate(0)'}`,
						}}
						fontSize='small'
					/>
				</Typography>
			)}
			<div className={`${classes.lineItem} ${classes.total}`}>
				<Typography variant='h5'>Total</Typography>
				<Typography variant='h5'>${total !== 0 ? total.toFixed(2) : 0}</Typography>
			</div>
		</Paper>
	);
};

export default ExpenseSummary;
