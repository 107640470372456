import React, { Component } from 'react';
import {
	withStyles,
	ExpansionPanel,
	Chip,
	ExpansionPanelSummary,
	Paper,
	TableContainer,
	TableRow,
	Table,
	TableCell,
	TableBody,
	Hidden,
	CircularProgress,
	Button,
	TableHead,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { COLORS, DOCUMENT_TYPES, DOCUMENT_FIELD_NAME } from '../../../utils/Application_Constants';
import {
	getDocumentsFromAssignmentWC,
	downloadDocument,
	getAdobeSignDocumentUrl,
} from '../../../services/DocumentService';
import AssignmentDocumentActionMenu from './AssignmentDocumentActionMenu';
import AssignmentViewAndSignDocumentModel from './AssignmentViewAndSignDocumentModel';
import UserContext from '../../../contexts/userContext';
import WithContexts from '../../../contexts/withContexts';
import Alert from '@material-ui/lab/Alert';
import { getNestedProperty } from '../../../utils/helpers';

const styles = (theme) => ({
	documentPaperRoot: {
		marginTop: '20px',
	},
	tableContainerRoot: {
		width: '98%',
		margin: '0 auto',
		border: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	mobileDocumentCard: {
		margin: '0 10px',
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		padding: '25px 16px',
		textAlign: 'left',
	},
	mobileLabelContentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		padding: '11px 0',
	},
	mobileTitle: {
		fontWeight: '900',
		textTransform: 'uppercase',
		fontSize: '12px',
		color: COLORS.RAVEN,
	},
	mobileData: {
		textAlign: 'left',
	},
	tableRowHeaderFont: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		padding: '16px',
		fontSize: '16px',
	},
	expansionPanelSummaryRoot: {
		padding: '8px 20px',
	},
	panelHeader: {
		fontWeight: '700',
		fontSize: '18px',
		color: COLORS.RAVEN,
	},
	dateFont: {
		fontWeight: '300',
		fontSize: '16px',
		color: COLORS.RAVEN,
	},
	chevronRoot: {
		width: '25px',
		right: '0',
		color: COLORS.TRUE_BLUE,
	},
	clickableLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	textClass: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		padding: '10px',
		[theme.breakpoints.down('sm')]: {
			padding: '0px',
		},
	},
	mobileButtonRoot: {
		padding: 0,
		display: 'flex',
		justifyContent: 'left',
	},
	textInherit: {
		textTransform: 'initial',
		[theme.breakpoints.down('sm')]: {
			width: 'auto',
		},
	},
	header: {
		textAlign: 'left',
		fontWeight: '700',
		letterSpacing: '0',
		fontSize: '20px',
		width: '100%',
		color: COLORS.RAVEN,
		opacity: '0.3',
	},
	noDocuments: {
		padding: '8px 20px',
	},
	unsignedStatus: {
		color: '#EA3219',
		borderColor: '#EA3219',
		backgroundColor: '#ffcccb',
	},
	signedStatus: {
		color: COLORS.KELLY_GREEN,
		borderColor: COLORS.KELLY_GREEN,
		backgroundColor: COLORS.AQUA_SPRING,
	},
	naStatus: {
		color: COLORS.STORM_GREY,
		backgroundColor: COLORS.DESERT_STORM,
	},
	alertMessageDiv: {
		margin: '10px 10px',
	},
	alertMessage: {
		fontSize: '12px',
		fontWeight: '500',
		padding: '10px 15px',
	},
});

class AssignmentDocumentComponentV2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignmentDocuments: [],
			isLoading: true,
			isViewAndSign: false,
			responseTimeout: '',
			credentialingSigningUrl: '',
			alertMessage: false,
		};
	}

	async componentDidMount() {
		const { assignmentName } = this.props;
		let assignmentId = assignmentName.slice(4);
		const { externalId } = this.props.UserContext;
		const documentResponse = await getDocumentsFromAssignmentWC(externalId, assignmentId);
		this.setState({
			assignmentDocuments: documentResponse ? documentResponse.data : [],
			isLoading: false,
		});
	}

	viewAndSignButtonHandler = async (document) => {
		const { externalId } = this.props.UserContext;
		this.setState({
			isViewAndSign: !this.state.isViewAndSign,
			responseTimeout: '',
			credentialingSigningUrl: '',
		});

		if (
			getNestedProperty(document, DOCUMENT_FIELD_NAME.Email) &&
			getNestedProperty(document, DOCUMENT_FIELD_NAME.Agreement_ID)
		) {
			const emailID = getNestedProperty(document, DOCUMENT_FIELD_NAME.Email);
			const agreementID = getNestedProperty(document, DOCUMENT_FIELD_NAME.Agreement_ID);

			let adobeURL = await getAdobeSignDocumentUrl(externalId, agreementID, emailID);
			if (adobeURL) {
				this.setState({ credentialingSigningUrl: adobeURL });
			} else {
				this.setState({ responseTimeout: 'error' });
			}
		} else {
			this.setState({ responseTimeout: 'error' });
		}
	};

	downloadHandler(item) {
		downloadDocument(item, 'Assignment');
	}

	toggleViewAndSignModal = async (isViewable) => {
		this.setState({ isViewAndSign: isViewable, alertMessage: true });
	};

	adobeSignDocumentModal() {
		const { isViewAndSign, credentialingSigningUrl, responseTimeout } = this.state;
		if (isViewAndSign === true) {
			return (
				<AssignmentViewAndSignDocumentModel
					credentialingSigningUrl={credentialingSigningUrl}
					toggleHandler={this.toggleViewAndSignModal}
					openDialog={isViewAndSign}
					responseTimeout={responseTimeout}
				/>
			);
		}

		return null;
	}

	checkDocumentType(item) {
		switch (getNestedProperty(item, DOCUMENT_FIELD_NAME.DocumentType)) {
			case DOCUMENT_TYPES.COI:
				return true;
			case DOCUMENT_TYPES.ADDENDUM:
				return false;
			case DOCUMENT_TYPES.TRAVEL:
				return true;
			case DOCUMENT_TYPES.AGREEMENT:
				return false;
			case '':
			case undefined:
			case null:
				return true;
			default:
				return false;
		}
	}

	checkDocumentStatus(item) {
		switch (getNestedProperty(item, DOCUMENT_FIELD_NAME.Status)) {
			case 'SIGNED':
			case 'WAITING_FOR_COUNTER-SIGNATURE':
			case 'OUT_FOR_SIGNATURE':
				return false;
			case '':
			case undefined:
			case null:
				return true;
			default:
				return false;
		}
	}
	cleanDocName(docFileName) {
		//strip off guid from filename 'AssignmentAddendum-a1u11000001lPaM.pdf'
		const idxOfDot = docFileName.lastIndexOf('.');
		const idxOfDash = docFileName.lastIndexOf('-');
		if (idxOfDash !== -1) {
			if (idxOfDot === -1 && docFileName.length - idxOfDash === 16) {
				// No extension
				return docFileName.substring(0, idxOfDash);
			} else if (idxOfDot - idxOfDash === 16) {
				return docFileName.substring(0, idxOfDash) + docFileName.substring(idxOfDot);
			}
		}
		return docFileName;
	}

	reloadDocuments = async () => {
		const { assignmentName } = this.props;
		let assignmentId = assignmentName.slice(4);
		const { externalId } = this.props.UserContext;
		const documentResponse = await getDocumentsFromAssignmentWC(externalId, assignmentId);
		this.setState({
			assignmentDocuments: documentResponse ? documentResponse.data : [],
			isLoading: false,
		});
	};

	renderDateForItem(item) {
		//if (getNestedProperty(item, DOCUMENT_FIELD_NAME.DocumentType) === DOCUMENT_TYPES.COI) return ('');
		return moment
			.utc(getNestedProperty(item, DOCUMENT_FIELD_NAME.Created))
			.local()
			.format('MMM D, YYYY ');
	}

	renderDateTitleItem(t, item) {
		//if (getNestedProperty(item, DOCUMENT_FIELD_NAME.DocumentType) === DOCUMENT_TYPES.COI) return ('');
		return t('assignments:DOCUMENTS.DATE_SENT');
	}

	renderStatus(classes, t, item) {
		switch (getNestedProperty(item, DOCUMENT_FIELD_NAME.Status)) {
			case 'SIGNED':
			case 'WAITING_FOR_COUNTER-SIGNATURE':
				return (
					<Chip
						variant='outlined'
						label={t('assignments:DOCUMENTS.STATUS_SIGNED')}
						className={classes.signedStatus}
					/>
				);
			case 'OUT_FOR_SIGNATURE':
				return (
					<Chip
						variant='outlined'
						label={t('assignments:DOCUMENTS.STATUS_PENDINGSIGNATURE')}
						className={classes.unsignedStatus}
					/>
				);
			case '':
			case undefined:
			case null:
				return null;
			default:
				return (
					<Chip
						variant='outlined'
						label={t('assignments:DOCUMENTS.STATUS_UNSIGNED')}
						className={classes.unsignedStatus}
					/>
				);
		}
	}

	renderDesktopTable() {
		const { classes, t } = this.props;
		const { assignmentDocuments } = this.state;
		return (
			<TableContainer classes={{ root: classes.tableContainerRoot }}>
				<Table>
					<TableHead>
						<TableRow classes={{ root: classes.tableRowHeaderWrapper }}>
							<TableCell classes={{ root: classes.tableRowHeaderFont }}>
								{t('assignments:DOCUMENTS.NAME')}
							</TableCell>

							<TableCell classes={{ root: classes.tableRowHeaderFont }}>
								{t('assignments:DOCUMENTS.DATE_SENT')}
							</TableCell>
							<TableCell classes={{ root: classes.tableRowHeaderFont }}>
								{t('assignments:DOCUMENTS.STATUS')}
							</TableCell>
							<TableCell classes={{ root: classes.tableRowHeaderFont }}>
								{t('assignments:DOCUMENTS.ACTIONS')}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{assignmentDocuments.map((item, index) => (
							<TableRow key={`tableRow-${index + 1}`}>
								<TableCell>
									{this.checkDocumentType(item) ||
									this.checkDocumentStatus(item) ||
									getNestedProperty(item, DOCUMENT_FIELD_NAME.Status) ===
										'SIGNED' ? (
										<Button
											classes={{
												label: `${classes.clickableLink} ${classes.textInherit}`,
											}}
											component='span'
											onClick={() => downloadDocument(item, 'Assignment')}
										>
											{this.cleanDocName(item.metaData.name)}
										</Button>
									) : (
										<Button
											classes={{
												label: `${classes.clickableLink} ${classes.textInherit}`,
											}}
											component='span'
											onClick={() => this.viewAndSignButtonHandler(item)}
										>
											{this.cleanDocName(item.metaData.name)}
										</Button>
									)}
								</TableCell>

								<TableCell>{this.renderDateForItem(item)}</TableCell>
								<TableCell>{this.renderStatus(classes, t, item)}</TableCell>
								<TableCell>
									<AssignmentDocumentActionMenu
										viewAndSignButtonHandler={this.viewAndSignButtonHandler}
										downloadtHandler={this.downloadHandler}
										document={item}
										reloadDocuments={this.reloadDocuments}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}

	renderDocumentMobileCard() {
		const { classes, t } = this.props;
		const { assignmentDocuments } = this.state;
		return assignmentDocuments.map((item, index) => (
			<div key={`mobileCard-${index + 1}`} className={classes.mobileDocumentCard}>
				<div className={classes.mobileLabelContentWrapper}>
					<span className={classes.mobileTitle}>{t('assignments:DOCUMENTS.NAME')}</span>
					{this.checkDocumentType(item) ||
					this.checkDocumentStatus(item) ||
					getNestedProperty(item, DOCUMENT_FIELD_NAME.Status) === 'SIGNED' ? (
						<Button
							classes={{
								root: `${classes.mobileButtonRoot}`,
								label: `${classes.clickableLink} ${classes.textInherit}`,
							}}
							component='span'
							onClick={() => downloadDocument(item, 'Assignment')}
						>
							<span className={classes.mobileData}>
								{this.cleanDocName(item.metaData.name)}
							</span>
						</Button>
					) : (
						<Button
							classes={{
								root: `${classes.mobileButtonRoot}`,
								label: `${classes.clickableLink} ${classes.textInherit}`,
							}}
							component='span'
							onClick={() => this.viewAndSignButtonHandler(item)}
						>
							<span className={classes.mobileData}>
								{this.cleanDocName(item.metaData.name)}
							</span>
						</Button>
					)}
				</div>
				<div className={classes.mobileLabelContentWrapper}>
					<span className={classes.mobileTitle}>{this.renderDateTitleItem(t, item)}</span>
					<span className={classes.mobileData}>{this.renderDateForItem(item)}</span>
				</div>
				<div className={classes.mobileLabelContentWrapper}>
					<span className={classes.mobileTitle}>{t('assignments:DOCUMENTS.STATUS')}</span>
					<span className={classes.mobileData}>
						{this.renderStatus(classes, t, item)}
					</span>
				</div>
				<div className={classes.mobileLabelContentWrapper}>
					<span className={classes.mobileTitle}>
						{t('assignments:DOCUMENTS.ACTIONS')}
					</span>
					<span className={classes.mobileData}>
						{' '}
						<AssignmentDocumentActionMenu
							viewAndSignButtonHandler={this.viewAndSignButtonHandler}
							downloadtHandler={this.downloadHandler}
							document={item}
						/>
					</span>
				</div>
			</div>
		));
	}

	renderDetails() {
		const { assignmentDocuments, isLoading, alertMessage } = this.state;
		const { t, classes } = this.props;
		if (isLoading) {
			return <CircularProgress />;
		}
		if (!assignmentDocuments || assignmentDocuments.length === 0) {
			return this.renderNoDocuments();
		}
		return (
			<>
				<Hidden only={['xs', 'sm']}>
					{alertMessage && (
						<div className={classes.alertMessageDiv}>
							<Alert
								severity='info'
								className={classes.alertMessage}
								onClose={() => {
									this.setState({ alertMessage: false });
								}}
							>
								{t('assignments:DOCUMENTS.STATUS_ALERT')}
							</Alert>
						</div>
					)}

					{this.renderDesktopTable()}
				</Hidden>
				<Hidden only={['md', 'lg', 'xl']}>
					{alertMessage && (
						<div className={classes.alertMessageDiv}>
							<Alert
								severity='info'
								className={classes.alertMessage}
								onClose={() => {
									this.setState({ alertMessage: false });
								}}
							>
								{t('assignments:DOCUMENTS.STATUS_ALERT')}
							</Alert>
						</div>
					)}
					{this.renderDocumentMobileCard()}
				</Hidden>
			</>
		);
	}

	renderNoDocuments() {
		const { classes, t } = this.props;
		return (
			<h1 className={`${classes.header} ${classes.noDocuments}`}>
				{t('assignments:DOCUMENTS.NO_DOCUMENTS')}
			</h1>
		);
	}

	render() {
		const { classes, t } = this.props;

		return (
			<>
				<Paper classes={{ root: classes.documentPaperRoot }}>
					<ExpansionPanel>
						<ExpansionPanelSummary
							classes={{ root: classes.expansionPanelSummaryRoot }}
							expandIcon={<ExpandMore classes={{ root: classes.chevronRoot }} />}
							aria-controls='documentPanelContent'
							id='documentPanelHeader'
						>
							<span className={classes.panelHeader}>
								{t('assignments:DOCUMENTS.DOCUMENTS_TITLE')}
							</span>
						</ExpansionPanelSummary>
						{this.renderDetails()}
					</ExpansionPanel>
				</Paper>
				{this.adobeSignDocumentModal()}
			</>
		);
	}
}
AssignmentDocumentComponentV2.contextType = UserContext;
export default WithContexts(withTranslation()(withStyles(styles)(AssignmentDocumentComponentV2)));
