import React, { Component, Fragment } from 'react';
import { menuNavigationProps } from '../../../types/centralizedProps';
import { withStyles, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import UserContext from '../../../contexts/userContext';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { COLORS } from '../../../utils/Application_Constants';
import IconButton from '@material-ui/core/IconButton';
import msSalService from '../../../services/msSalService';
import Menu from '@material-ui/core/Menu';

const styles = (theme) => ({
	menuListRoot: {
		display: 'flex',
	},
	logoWrapper: {
		maxWidth: '243px',
	},
	supportNumberMenuListRoot: {
		display: 'flex',
		marginLeft: '80px',
		paddingLeft: '5px',
	},
	navbarWrapper: {
		padding: '0 28px 0 28px',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		color: `${COLORS.DENIM}`,
	},
	fontBoldHeader: {
		fontWeight: '700',
	},
	paperPopper: {
		zIndex: '1302',
	},
	subNavRoot: {
		fontWeight: '700',
		color: `${COLORS.TRUE_BLUE}`,
		fontSize: '14px',
		'&:hover': {
			background: COLORS.TRUE_BLUE,
			color: '#ffffff',
		},
	},
	gridFlexWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	supportNavNumber: {
		fontWeight: '700',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
	},
	initials: {
		color: 'white',
		fontWeight: '700',
		backgroundColor: COLORS.TRUE_BLUE,
		width: '30px',
		height: '30px',
		fontSize: '14px',
	},
	initialsButton: {
		padding: '12px 0px 12px 12px',
		'&:hover': {
			background: 'none',
		},
	},
});

class ProfileNavComponent extends Component {
	constructor(props, context) {
		super(props);
		this.context = context;
		this.state = {
			childMenuOpenCloseController: {},
			anchorEl: null,
		};
		this.anchorRef = React.createRef(null);
	}

	getInitials(name) {
		var initials = name.match(/\b\w/g) || [];
		initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
		return initials;
	}

	renderNavigationItem = (navigationItem) => {
		/*{const { classes, toggleDrawer } = this.props;
        const { id, parentUrlLink } = navigationItem;
        return (
            <>
                <NavLink exact key={id.toString()} to={{ pathname: parentUrlLink }}  >
                    <IconButton
                        edge="end"
                        aria-label="account of current user"

                        aria-haspopup="true"

                        color="inherit"
                    >    <Avatar className={classes.initials}> <MenuItem className={classes.fontBoldHeader} onClick={toggleDrawer}>{this.getInitials(this.context.account.name)}</MenuItem></Avatar>
                    </IconButton>
        </NavLink>

            </>
        );}*/
	};

	authenticationLogOut = async () => {
		this.handleClose();
		localStorage.removeItem('LocalStorage_UserSignedIN');
		localStorage.removeItem('LocalStorage_FirstTimeUserLogin');
		localStorage.removeItem('LocalStorage_PersonalInfoDisclaimerAlert');
		localStorage.removeItem('snoozeNotification');

		try {
			msSalService.setInitialRedirectUri();
			await msSalService.logOut();
		} catch (error) {
			Error(error);
		}
	};

	navigationItemMenuLinks = (item) => {
		const { classes } = this.props;

		// const reset_password_link = encodeURI(`${process.env.REACT_APP_B2C_BASE_URI}/b2c_1_password_reset/oauth2/v2.0/authorize?response_type=id_token&scope=openid profile&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.origin}&nonce=dummy&state=12345&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=1.2.1&response_mode=fragment`)

		if (item.id === 2 || item.id === 3 || item.id === 4) {
			return (
				<NavLink exact key={item.id.toString()} to={{ pathname: item.link }}>
					<MenuItem classes={{ root: classes.subNavRoot }} onClick={this.handleClose}>
						{item.name}
					</MenuItem>
				</NavLink>
			);
		}

		if (item.id === 5) {
			return (
				<MenuItem
					key={item.id}
					classes={{ root: classes.subNavRoot }}
					onClick={this.authenticationLogOut}
				>
					{item.name}
				</MenuItem>
			);
		}
	};

	renderMenu(navigationItem) {
		const { classes } = this.props;
		let name = '';
		if (this.context != null && this.context.account != null) {
			const rawName =
				this.context.account.idTokenClaims.given_name +
				' ' +
				this.context.account.idTokenClaims.family_name;
			name = this.getInitials(rawName);
		}
		return (
			<div>
				<IconButton
					className={classes.initialsButton}
					aria-label='show 17 new notifications'
					color='inherit'
					onClick={this.handleClick}
				>
					<Avatar className={classes.initials}>{name}</Avatar>
				</IconButton>
				<span className='caret'></span>
				<Menu
					id='simple-menu'
					anchorEl={this.state.anchorEl}
					keepMounted
					open={Boolean(this.state.anchorEl)}
					onClose={this.handleClose}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					{navigationItem.children.map((item, index) =>
						this.navigationItemMenuLinks(item)
					)}
				</Menu>
			</div>
		);
	}

	handleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};
	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	renderMenuItem() {
		const { profileNavigation } = this.props;

		return profileNavigation.map((item) => {
			if (item.children.length > 0) {
				return <Fragment key={item.id}>{this.renderMenu(item)}</Fragment>;
			}
			return <Fragment key={item.id}>{this.renderNavigationItem(item)}</Fragment>;
		});
	}
	render() {
		return this.renderMenuItem();
	}
}

ProfileNavComponent.propTypes = {
	profileNavigation: menuNavigationProps.isRequired,
	toggleDrawer: PropTypes.func,
};

ProfileNavComponent.contextType = UserContext;
export default withStyles(styles)(ProfileNavComponent);
