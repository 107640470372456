import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Grid, Paper, Typography, withStyles, Button } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';
import WithContexts from '../../contexts/withContexts';
import { COLORS } from '../../utils/Application_Constants';
import { useLocation } from 'react-router-dom';

const styles = (theme) => ({
	root: {
		background: COLORS.LT_MIDNIGHTBG1,
		minHeight: '600px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	GridRoot: {
		minHeight: '300px',
	},
	doneIcon: {
		color: COLORS.LT_EMERALD,
		background: COLORS.LT_EMERALD10,
		borderRadius: '50%',
		padding: '16px',
	},
});

const handleAppcuesTrack = () => {
	window.Appcues.track("Confirm successful submission of timesheet (Done)", {
		key: "Click the Done button",
		description: "A user hits Done after they've submitted their survey"
	})
}

function TimesheetSubmit({ classes, t }) {
	const location = useLocation();
	const { timesheetPreviouslySubmitted } = location.state || {};
	return (
		<div className={classes.root}>
			<Container maxWidth='lg'>
				<Grid container justifyContent='center' alignItems='center'>
					<Grid item xs={10} sm={8} md={4}>
						<Paper elevation={3}>
							<Grid
								container
								direction='column'
								spacing={2}
								justifyContent='center'
								alignItems='center'
								className={classes.GridRoot}
							>
								<Grid item>
									<DoneIcon
										fontSize='large'
										className={classes.doneIcon}
									></DoneIcon>
								</Grid>
								<Grid item>
									<Typography variant='h5'>
										{timesheetPreviouslySubmitted
											? t('submitTimesheet:HEADERS.TIMESHEET_RESUBMITTED')
											: t('submitTimesheet:HEADERS.TIMESHEET_SUBMITTED')}
									</Typography>
								</Grid>
								<Grid item>
									<Link to='/timesheets'>
										<Button variant='contained' color='primary' onClick={handleAppcuesTrack}>
											{t('submitTimesheet:BUTTONS.DONE')}
										</Button>
									</Link>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(TimesheetSubmit))));
