import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import AssignmentListItem from '../AssignmentListItem/AssignmentListItem';
import AssignmentListEmptyState from '../AssignmentListItem/AssignmentListEmptyState';

const styles = (theme) => ({
	header: {
		color: COLORS.WATERLOO,
		fontWeight: '700',
		fontSize: '18px',
		width: '90%',
		paddingTop: '30px',
		textAlign: 'left',
		marginLeft: '6%',
	},
});

class PastAssignmentList extends Component {
	renderAssignments = () => {
		const { assignments, toggleAssignmentView } = this.props;
		if (!assignments || assignments.length === 0) {
			return (
				<div style={{ width: '100%' }}>
					<AssignmentListEmptyState assignmentType='past' />
				</div>
			);
		}
		return (
			<Grid container>
				{assignments.map((item) => (
					<AssignmentListItem
						type='past'
						key={item.name}
						assignment={item}
						toggleAssignmentView={toggleAssignmentView}
					/>
				))}
			</Grid>
		);
	};

	renderAssignmentTitleConditonally = () => {
		const { assignments, totalAssignment } = this.props;
		if (!assignments || assignments.length === 0) {
			return <span className='tileHeader'>Past Assignments</span>;
		}
		return (
			<span className='tileHeader'>
				Past Assignments(
				{totalAssignment})
			</span>
		);
	};

	render() {
		return (
			<>
				<div>
					{/* {this.renderAssignmentTitleConditonally()} */}
					<Grid container>{this.renderAssignments()}</Grid>
				</div>
			</>
		);
	}
}

export default withStyles(styles)(PastAssignmentList);
