import React, { Component } from 'react';
import { Grid, Container, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ltdomain } from '../../../utils/helpers';

const styles = () => ({
	root: {
		textAlign: 'center',
	},
	containerPadding: {
		paddingTop: '40px',
		paddingBottom: '40px',
	},
	listItemSpacing: {
		paddingBottom: '12px',
		paddingTop: '0',
	},
	fontBoldFooter: {
		fontWeight: '900',
	},
	footerList: {
		padding: '0px',
		listStyleType: 'none',
	},
});

class DesktopFooter extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { classes } = this.props;
		return (
			<footer className='desktop'>
				<Container maxWidth='lg' className={classes.containerPadding}>
					<Grid container>
						<Grid item md={3} className={classes.root}>
							<h4 className={classes.fontBoldFooter}>CLINICIANS</h4>
							<ul className={classes.footerList}>
								<li>
									<a href='/#/dashboard'>Dashboard</a>
								</li>
								<li>
									<a href={ltdomain() + '/Resources/JobSearch/QuickSearch'}>
										Search jobs
									</a>
								</li>
								<li>
									<a href={ltdomain() + '/locum-agency'}>Find a recruiter</a>
								</li>
							</ul>
						</Grid>

						<Grid item md={3} className={classes.root}>
							<h4 className={classes.fontBoldFooter}>RESOURCES</h4>
							<ul className={classes.footerList}>
								<li>
									<a href={ltdomain() + '/resource-center/'}>Resource center</a>
								</li>
								<li>
									<a href={ltdomain() + '/physician-resources/'}>
										Physician resources
									</a>
								</li>
								<li>
									<a href={ltdomain() + '/telehealth/'}>Telehealth resources</a>
								</li>
								<li>
									<a href={ltdomain() + '/resource-center/career-center/'}>
										Career centers
									</a>
								</li>
							</ul>
						</Grid>

						<Grid item md={3} className={classes.root}>
							<h4 className={classes.fontBoldFooter}>COMPANY</h4>
							<ul className={classes.footerList}>
								<li>
									<a
										href={
											ltdomain() +
											'/about-us/who-we-are/about-locumtenenscom/'
										}
									>
										About us
									</a>
								</li>
								<li>
									<a href='https://aspire.locumtenens.com'>Careers</a>
								</li>
								<li>
									<a href={ltdomain() + '/locum-agency'}>Locum agencies</a>
								</li>
							</ul>
						</Grid>

						<Grid item md={3} className={classes.root}>
							<h4 className={classes.fontBoldFooter}>GET IN TOUCH</h4>
							<ul className={classes.footerList}>
								<li>
									<a
										target='iframe2'
										href='mailto:LT_OnlineServices@locumtenens.com'
									>
										Email us
									</a>
								</li>
								<li>
									<a href='tel:8005628663'>Call US</a>
								</li>
								<li>
									<a href={ltdomain() + '/Resources/ContactUs/ContactUs'}>
										Contact us
									</a>
								</li>
							</ul>
						</Grid>
						<Grid item md={4} className={classes.root}></Grid>
						<Grid item md={4} className={classes.root}>
							<img
								className='footer-image'
								src={ltdomain() + '/images/locumtenens_com_logo_tagline--white.svg'}
								alt='LocumTenens.com white logo'
							/>
						</Grid>
						<Grid item md={4} className={classes.root}></Grid>
					</Grid>
				</Container>
			</footer>
		);
	}
}

DesktopFooter.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
		containerPadding: PropTypes.string.isRequired,
		listItemSpacing: PropTypes.string.isRequired,
		fontBoldFooter: PropTypes.string.isRequired,
	}).isRequired,
};
export default withStyles(styles)(DesktopFooter);
