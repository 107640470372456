import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import AssignmentDetailCard from '../AssignmentDetailCard/AssignmentDetailCard';
import AssignmentDocumentComponent from '../AssignmentDocumentComponent/AssignmentDocumentComponent';
import AssignmentDocumentComponentV2 from '../AssignmentDocumentComponent/AssignmentDocumentComponentV2';
import Travel from '../Travel/Travel';
import TravelV2 from '../Travel/TravelV2';
import FeatureFlag from '../../GlobalComponents/FeatureFlagComponent/FeatureFlag';

const styles = (theme) => ({});

class PastAssignmentDetailView extends Component {
	render() {
		const { assignment, externalId } = this.props;
		return !assignment ? (
			<p>Error, Assignment not found</p>
		) : (
			<>
				<AssignmentDetailCard assignment={assignment} type='past' />
				<FeatureFlag name='ShowRewiredAssignmentDocs' isFlagEnabled='true'>
					<AssignmentDocumentComponentV2 assignmentName={assignment.name} />
				</FeatureFlag>
				<FeatureFlag name='ShowRewiredAssignmentDocs' isFlagEnabled='false'>
					<AssignmentDocumentComponent assignmentName={assignment.name} />
				</FeatureFlag>
				<FeatureFlag name='ShowRewiredAssignmentTravel' isFlagEnabled='true'>
					<TravelV2 assignment={assignment} externalId={externalId} filter='past' />
				</FeatureFlag>
				<FeatureFlag name='ShowRewiredAssignmentTravel' isFlagEnabled='false'>
					<Travel assignment={assignment} filter='past' />
				</FeatureFlag>
			</>
		);
	}
}

export default withStyles(styles)(PastAssignmentDetailView);
