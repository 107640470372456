import { getDocumentsForTypes } from '../services/DocumentService';
import {
	DOCUMENT_TYPE,
	DOCUMENT_FIELD_NAME,
	ACTIVE_PATHNAME_MD,
	ACTIVE_PATHNAME_AP,
	CRED_STATE,
	CRED_STATUS,
	CRED_APP_STATUS,
	CRED_APP_STATUS_MESSAGE,
	CRED_APP_STATUS_MESSAGE_V2,
	CRED_APP_STATUS_COLOR,
	CRED_RECIPE_TYPE,
	DEGREE,
} from './Application_Constants';
import { getNestedProperty } from './helpers';
import moment from 'moment';

function personalInformationSectionCheck(personalInfoObject) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};
	if (!personalInfoObject) return status;
	if (!personalInfoObject.titles) {
		status.missingFields.push(`titles`);
	}
	if (!personalInfoObject.firstName) {
		status.missingFields.push(`firstName`);
	}
	if (!personalInfoObject.lastName) {
		status.missingFields.push(`lastName`);
	}
	if (!personalInfoObject.homeAddress) {
		status.missingFields.push(`homeAddress`);
	}
	if (!personalInfoObject.city) {
		status.missingFields.push(`city`);
	}
	if (!personalInfoObject.state) {
		status.missingFields.push(`state`);
	}
	if (!personalInfoObject.zipCode) {
		status.missingFields.push(`zipCode`);
	}
	if (!personalInfoObject.npiNumber) {
		status.missingFields.push(`npiNumber`);
	}
	if (!personalInfoObject.birthCity) {
		status.missingFields.push(`birthCity`);
	}
	if (!personalInfoObject.birthCountry) {
		status.missingFields.push(`birthCountry`);
	}

	if (
		personalInfoObject.usCitizen === null ||
		personalInfoObject.usCitizen === `null` ||
		personalInfoObject.usCitizen === ''
	) {
		status.missingFields.push(`usCitizen`);
	}
	if (!personalInfoObject.email) {
		status.missingFields.push(`email`);
	}
	if (!personalInfoObject.dateOfBirth) {
		status.missingFields.push(`dateOfBirth`);
	}

	if (personalInfoObject.usCitizen === false || personalInfoObject.usCitizen === 'false') {
		if (!personalInfoObject.visaStatus) {
			status.missingFields.push(`visaStatus`);
		}
		if (!personalInfoObject.visaType) {
			status.missingFields.push(`visaType`);
		}
		if (!personalInfoObject.visaNumber) {
			status.missingFields.push(`visaNumber`);
		}
		if (
			personalInfoObject.visaStatus === 'Active' ||
			personalInfoObject.visaStatus === 'Expired'
		) {
			if (!personalInfoObject.visaExpirationDate) {
				status.missingFields.push(`visaExpirationDate`);
			}
		}
	}

	if (personalInfoObject.emergencyContactRelationship) {
		if (!personalInfoObject.emergencyContact) {
			status.missingFields.push(`emergencyContact`);
		}
		if (!personalInfoObject.emergencyContactPhone) {
			status.missingFields.push(`emergencyContactPhone`);
		}
	}

	// if birth country is US then check for birth state value
	if (
		personalInfoObject.birthCountry === 'US' ||
		personalInfoObject.birthCountry === 'U.S.' ||
		personalInfoObject.birthCountry === 'United States'
	) {
		if (!personalInfoObject.birthState) {
			status.missingFields.push(`birthState`);
		}
	}

	if (!personalInfoObject.homePhone && !personalInfoObject.cellPhone) {
		status.missingFields.push(`homePhone`);
		status.missingFields.push(`cellPhone`);
	}

	if (status.missingFields.length === 0) {
		status.status = 'complete';
	} else {
		status.status = 'incomplete';
	}

	return status;
}

//boardCertificationsStatusCheck
function boardCertificationsStatusCheck(providerSpecialties, activePath, segment, boardCertified) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};

	if (activePath === ACTIVE_PATHNAME_MD) {
		if (!providerSpecialties) return status;
		if (providerSpecialties.length > 0) {
			for (let obj = 0; obj < providerSpecialties.length; obj++) {
				if (!providerSpecialties[obj].specialty) {
					status.missingFields.push(`providerSpecialties[${obj}].specialty`);
				}
				if (!providerSpecialties[obj].boardStatus) {
					status.missingFields.push(`providerSpecialties[${obj}].boardStatus`);
				}
				if (providerSpecialties[obj].boardStatus === 'Board Certified') {
					if (!providerSpecialties[obj].issuingBoard) {
						status.missingFields.push(`providerSpecialties[${obj}].issuingBoard`);
					}
					//if (!providerSpecialties[obj].issueDate) { status.missingFields.push(`providerSpecialties[${obj}].issueDate`) }
					//if (!providerSpecialties[obj].lifetimeCertified) {
					//    if (!providerSpecialties[obj].expirationDate) { status.missingFields.push(`providerSpecialties[${obj}].expirationDate`) }
					//}
				}
				if (providerSpecialties[obj].boardStatus === 'Board Eligible') {
					if (!providerSpecialties[obj].passedWrittenExam) {
						status.missingFields.push(`providerSpecialties[${obj}].passedWrittenExam`);
					}
				}
			}
			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'incomplete';
			status.missingFields.push(`providerSpecialties[0].specialty`);
			status.missingFields.push(`providerSpecialties[0].boardStatus`);
		}
	}

	if (activePath === ACTIVE_PATHNAME_AP) {
		if (!boardCertified) {
			status.missingFields.push(`boardCertified`);
			return status;
		}

		if (boardCertified === 'No') {
			status.status = 'complete';
		} else {
			if (providerSpecialties.length > 0) {
				for (let obj = 0; obj < providerSpecialties.length; obj++) {
					if (!providerSpecialties[obj].issuingBoard) {
						status.missingFields.push(`providerSpecialties[${obj}].issuingBoard`);
					}
				}
				if (status.missingFields.length === 0) {
					status.status = 'complete';
				} else {
					status.status = 'incomplete';
				}
			} else {
				status.status = 'incomplete';
				status.missingFields.push(`providerSpecialties[0].issuingBoard`);
			}
		}
	}

	return status;
}

//Residency Form Check

function residencySectionCheck(trainings, activePath) {
	let status = {
		status: '',
		missingFields: [],
	};

	if (activePath === ACTIVE_PATHNAME_MD) {
		if (!!trainings && trainings.length > 0) {
			for (var obj = 0; obj < trainings.length; obj++) {
				if (!trainings[obj].hospital) {
					status.missingFields.push(`trainings[${obj}].hospital`);
				}
				if (!trainings[obj].city) {
					status.missingFields.push(`trainings[${obj}].city`);
				}
				if (!trainings[obj].specialty) {
					status.missingFields.push(`trainings[${obj}].specialty`);
				}
				if (!trainings[obj].dateFrom) {
					status.missingFields.push(`trainings[${obj}].dateFrom`);
				}
				//if (trainings[obj].completed === "null" || trainings[obj].completed === null || trainings[obj].completed === "") { status.missingFields.push(`trainings[${obj}].completed`) }

				if (!!trainings[obj].country) {
					if (trainings[obj].country === 'United States' && !trainings[obj].state) {
						status.missingFields.push(`trainings[${obj}].state`);
					}
				} else {
					status.missingFields.push(`trainings[${obj}].country`);
				}
				if (!trainings[obj].isCurrentlyInRole && !trainings[obj].dateTo) {
					status.missingFields.push(`trainings[${obj}].dateTo`);
				}
				if (!!trainings[obj].isCurrentlyInRole && !trainings[obj].expectedEndDate) {
					status.missingFields.push(`trainings[${obj}].expectedEndDate`);
					if (
						trainings[obj].completed === 'null' ||
						trainings[obj].completed === null ||
						trainings[obj].completed === ''
					) {
						status.missingFields.push(`trainings[${obj}].completed`);
					}
				}
			}
			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'notProvided';
		}
	} else {
		status.status = 'complete';
	}

	return status;
}

//hospitalAffiliationStatusCheck

function hospitalAffiliationSectionCheck(workHistories, activePath) {
	let status = {
		status: 'notProvided',
		missingFields: [],
	};

	if (!!workHistories && workHistories.length > 0) {
		for (var obj = 0; obj < workHistories.length; obj++) {
			if (!workHistories[obj].hospital) {
				status.missingFields.push(`workHistories[${obj}].hospital`);
			}
			if (!workHistories[obj].city) {
				status.missingFields.push(`workHistories[${obj}].city`);
			}
			if (!workHistories[obj].state) {
				status.missingFields.push(`workHistories[${obj}].state`);
			}
			if (!workHistories[obj].dateFrom) {
				status.missingFields.push(`workHistories[${obj}].dateFrom`);
			}
			//if (!workHistories[obj].locumsPosition) { status.missingFields.push(`workHistories[${obj}].locumsPosition`) }

			if (!workHistories[obj].isCurrentlyWorkingHere && !workHistories[obj].dateTo) {
				status.missingFields.push(`workHistories[${obj}].dateTo`);
			}
		}
		if (status.missingFields.length === 0) {
			status.status = 'complete';
		} else {
			status.status = 'incomplete';
		}
	} else {
		status.status = 'notProvided';
	}
	return status;
}

//practiceExperienc Form Check

function practiceExperienceSectionCheck(workHistories, activePath) {
	let status = {
		status: 'notProvided',
		missingFields: [],
	};

	if (!!workHistories && workHistories.length > 0) {
		for (var obj = 0; obj < workHistories.length; obj++) {
			if (!workHistories[obj].hospital) {
				status.missingFields.push(`workHistories[${obj}].hospital`);
			}
			if (!workHistories[obj].city) {
				status.missingFields.push(`workHistories[${obj}].city`);
			}
			if (!workHistories[obj].state) {
				status.missingFields.push(`workHistories[${obj}].state`);
			}
			if (!workHistories[obj].dateFrom) {
				status.missingFields.push(`workHistories[${obj}].dateFrom`);
			}
			//if (!workHistories[obj].locumsPosition) { status.missingFields.push(`workHistories[${obj}].locumsPosition`) }

			if (!workHistories[obj].isCurrentlyWorkingHere && !workHistories[obj].dateTo) {
				status.missingFields.push(`workHistories[${obj}].dateTo`);
			}
		}
		if (status.missingFields.length === 0) {
			status.status = 'complete';
		} else {
			status.status = 'incomplete';
		}
	} else {
		status.status = 'notProvided';
	}
	return status;
}

//additionalTraining Form Check

function additionalTrainingSectionCheck(trainings, activePath) {
	let status = {
		status: 'notProvided',
		missingFields: [],
	};

	if (!trainings) return status;

	if (activePath === ACTIVE_PATHNAME_AP) {
		if (trainings.length > 0) {
			for (var obj = 0; obj < trainings.length; obj++) {
				if (!trainings[obj].hospital) {
					status.missingFields.push(`trainings[${obj}].hospital`);
				}
				if (!trainings[obj].city) {
					status.missingFields.push(`trainings[${obj}].city`);
				}
				if (!trainings[obj].specialty) {
					status.missingFields.push(`trainings[${obj}].specialty`);
				}
				if (!trainings[obj].dateFrom) {
					status.missingFields.push(`trainings[${obj}].dateFrom`);
				}
				//if (trainings[obj].completed === "null" || trainings[obj].completed === null || trainings[obj].completed === "") { status.missingFields.push(`trainings[${obj}].completed`) }
				if (!!trainings[obj].country) {
					if (trainings[obj].country === 'United States' && !trainings[obj].state) {
						status.missingFields.push(`trainings[${obj}].state`);
					}
				} else {
					status.missingFields.push(`trainings[${obj}].country`);
				}
				if (!trainings[obj].isCurrentlyInRole && !trainings[obj].dateTo) {
					status.missingFields.push(`trainings[${obj}].dateTo`);
				}
				if (!!trainings[obj].isCurrentlyInRole && !trainings[obj].expectedEndDate) {
					status.missingFields.push(`trainings[${obj}].expectedEndDate`);
					if (
						trainings[obj].completed === 'null' ||
						trainings[obj].completed === null ||
						trainings[obj].completed === ''
					) {
						status.missingFields.push(`trainings[${obj}].completed`);
					}
				}
			}
			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'notProvided';
		}
	} else {
		status.status = 'complete';
	}
	return status;
}

//gapsInTrainingSectionCheck

function gapsInTrainingSectionCheck(gaps, activePath) {
	let status = {
		status: '',
		missingFields: [],
	};

	if (activePath === ACTIVE_PATHNAME_MD) {
		if (!!gaps && gaps.length > 0) {
			for (var obj = 0; obj < gaps.length; obj++) {
				if (!gaps[obj].startDate) {
					status.missingFields.push(`gaps[${obj}].startDate`);
				}
				if (!gaps[obj].endDate) {
					status.missingFields.push(`gaps[${obj}].endDate`);
				}
				if (gaps[obj].startDate && !gaps[obj].reason) {
					status.missingFields.push(`gaps[${obj}].reason`);
				}
				if (gaps[obj].reason && gaps[obj].reason === 'Other') {
					if (!gaps[obj].explanation) {
						status.missingFields.push(`gaps[${obj}].explanation`);
					}
				}
			}
			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'notProvided';
		}
	} else {
		status.status = 'complete';
	}

	return status;
}

//militaryExperienceSectionCheck
function militaryExperienceSectionCheck(experience) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};

	if (!experience) return status;

	if (
		!experience.dischargeDate &&
		!experience.militaryStatus &&
		!experience.serviceBranch &&
		(experience.isMilitaryExp === false ||
			experience.isMilitaryExp === 'false' ||
			experience.isMilitaryExp === 'undefined' ||
			experience.isMilitaryExp === undefined)
	) {
		status.status = 'notProvided';
	} else {
		if (!!experience.serviceBranch) {
			if (
				!!experience.militaryStatus &&
				(experience.militaryStatus === 'Dishonorable' ||
					experience.militaryStatus === 'Honorable')
			) {
				if (!experience.dischargeDate) {
					status.missingFields.push('dischargeDate');
				}
			} else {
				if (!experience.militaryStatus) {
					status.missingFields.push('militaryStatus');
				}
			}
		} else {
			status.missingFields.push('serviceBranch');
			if (
				(experience.militaryStatus === 'Dishonorable' ||
					experience.militaryStatus === 'Honorable') &&
				!experience.dischargeDate
			) {
				status.missingFields.push('dischargeDate');
			}
		}
		if (status.missingFields.length === 0) {
			status.status = 'complete';
		} else {
			status.status = 'incomplete';
		}
	}
	return status;
}

//gapsInWorkHistorySectionCheck
function gapsInWorkHistorySectionCheck(gaps, activePath) {
	let status = {
		status: 'notProvided',
		missingFields: [],
	};

	if (!!gaps && gaps.length > 0) {
		for (var obj = 0; obj < gaps.length; obj++) {
			if (!gaps[obj].startDate) {
				status.missingFields.push(`gaps[${obj}].startDate`);
			}
			if (!gaps[obj].endDate) {
				status.missingFields.push(`gaps[${obj}].endDate`);
			}

			if (gaps[obj].startDate && !gaps[obj].reason) {
				status.missingFields.push(`gaps[${obj}].reason`);
			}

			if (gaps[obj].reason && gaps[obj].reason === 'Other') {
				if (!gaps[obj].explanation) {
					status.missingFields.push(`gaps[${obj}].explanation`);
				}
			}
		}
		if (status.missingFields.length === 0) {
			status.status = 'complete';
		} else {
			status.status = 'incomplete';
		}
	} else {
		status.status = 'notProvided';
	}

	return status;
}

//fellowship Form Check

function fellowshipSectionCheck(trainings, activePath) {
	let status = {
		status: '',
		missingFields: [],
	};

	if (activePath === ACTIVE_PATHNAME_MD) {
		if (!!trainings && trainings.length > 0) {
			for (var obj = 0; obj < trainings.length; obj++) {
				if (!trainings[obj].hospital) {
					status.missingFields.push(`trainings[${obj}].hospital`);
				}
				if (!trainings[obj].city) {
					status.missingFields.push(`trainings[${obj}].city`);
				}
				if (!trainings[obj].specialty) {
					status.missingFields.push(`trainings[${obj}].specialty`);
				}
				if (!trainings[obj].dateFrom) {
					status.missingFields.push(`trainings[${obj}].dateFrom`);
				}
				//if (trainings[obj].completed === "null" || trainings[obj].completed === null || trainings[obj].completed === "") { status.missingFields.push(`trainings[${obj}].completed`) }
				if (!!trainings[obj].country) {
					if (trainings[obj].country === 'United States' && !trainings[obj].state) {
						status.missingFields.push(`trainings[${obj}].state`);
					}
				} else {
					status.missingFields.push(`trainings[${obj}].country`);
				}
				if (!trainings[obj].isCurrentlyInRole && !trainings[obj].dateTo) {
					status.missingFields.push(`trainings[${obj}].dateTo`);
				}
				if (!!trainings[obj].isCurrentlyInRole && !trainings[obj].expectedEndDate) {
					status.missingFields.push(`trainings[${obj}].expectedEndDate`);
					if (
						trainings[obj].completed === 'null' ||
						trainings[obj].completed === null ||
						trainings[obj].completed === ''
					) {
						status.missingFields.push(`trainings[${obj}].completed`);
					}
				}
			}
			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'notProvided';
		}
	} else {
		status.status = 'complete';
	}

	return status;
}

//educationSectionCheck

function educationSectionCheck(education, activePath, titles, segment) {
	let status = {
		status: '',
		missingFields: [],
	};

	if (activePath === ACTIVE_PATHNAME_MD) {
		if (!!education && !!education.educations) {
			education.educations = education.educations.filter(
				(ed) => ed.educationLevel === 'Medical School'
			);
		}
		if (!!education && !!education.educations && education.educations.length > 0) {
			for (var obj = 0; obj < education.educations.length; obj++) {
				if (education.educations[obj].educationLevel === 'Medical School') {
					if (!education.educations[obj].medicalSchool) {
						status.missingFields.push(`educations[${obj}].medicalSchool`);
					}
					if (!education.educations[obj].city) {
						status.missingFields.push(`educations[${obj}].city`);
					}
					if (!education.educations[obj].country) {
						status.missingFields.push(`educations[${obj}].country`);
					}
					if (
						education.educations[obj].completed === true ||
						education.educations[obj].completed === 'true'
					) {
						if (!education.educations[obj].educationDegree) {
							status.missingFields.push(`educations[${obj}].educationDegree`);
						}
					}
					if (!education.educations[obj].dateFrom) {
						status.missingFields.push(`educations[${obj}].dateFrom`);
					}
					if (!education.educations[obj].dateTo) {
						status.missingFields.push(`educations[${obj}].dateTo`);
					}
					if (
						education.educations[obj].completed === 'null' ||
						education.educations[obj].completed === null ||
						education.educations[obj].completed === ''
					) {
						status.missingFields.push(`educations[${obj}].completed`);
					}

					if (
						education.educations[obj].country === 'US' ||
						education.educations[obj].country === 'U.S.' ||
						education.educations[obj].country === 'United States'
					) {
						if (!education.educations[obj].state) {
							status.missingFields.push(`educations[${obj}].state`);
						}
					}

					if (
						education.educations[obj].country !== 'US' &&
						education.educations[obj].country !== 'U.S.' &&
						education.educations[obj].country !== 'United States' &&
						education.educations[obj].country !== 'Canada' &&
						education.educations[obj].country !== '' &&
						!!education.educations[obj].country
					) {
						if (!education.ecfmgDateIssued) {
							status.missingFields.push(`education.ecfmgDateIssued`);
						}
					}
				}
				if (status.missingFields.length === 0) {
					status.status = 'complete';
				} else {
					status.status = 'incomplete';
				}
			}
		} else {
			status.status = 'incomplete';
		}
	}

	if (activePath === ACTIVE_PATHNAME_AP) {
		if (!!education && !!education.educations && education.educations.length > 0) {
			for (obj = 0; obj < education.educations.length; obj++) {
				if (
					education.educations[obj].educationLevel === 'Undergraduate' ||
					education.educations[obj].educationLevel === 'Medical School'
				) {
					if (!education.educations[obj].medicalSchool) {
						status.missingFields.push(`educations[${obj}].medicalSchool`);
					}
					if (!education.educations[obj].city) {
						status.missingFields.push(`educations[${obj}].city`);
					}
					if (!education.educations[obj].country) {
						status.missingFields.push(`educations[${obj}].country`);
					}
					if (
						education.educations[obj].completed === true ||
						education.educations[obj].completed === 'true'
					) {
						if (!education.educations[obj].educationDegree) {
							status.missingFields.push(`educations[${obj}].educationDegree`);
						}
					}
					if (!education.educations[obj].dateFrom) {
						status.missingFields.push(`educations[${obj}].dateFrom`);
					}
					if (!education.educations[obj].dateTo) {
						status.missingFields.push(`educations[${obj}].dateTo`);
					}
					if (
						education.educations[obj].completed === 'null' ||
						education.educations[obj].completed === null ||
						education.educations[obj].completed === ''
					) {
						status.missingFields.push(`educations[${obj}].completed`);
					}

					if (
						education.educations[obj].country === 'US' ||
						education.educations[obj].country === 'U.S.' ||
						education.educations[obj].country === 'United States'
					) {
						if (!education.educations[obj].state) {
							status.missingFields.push(`educations[${obj}].state`);
						}
					}
				}

				if (education.educations[obj].educationLevel === 'Professional') {
					if (!education.educations[obj].medicalSchool) {
						status.missingFields.push(`educations[${obj}].medicalSchool`);
					}
					if (!education.educations[obj].city) {
						status.missingFields.push(`educations[${obj}].city`);
					}
					if (!education.educations[obj].country) {
						status.missingFields.push(`educations[${obj}].country`);
					}
					if (
						education.educations[obj].completed === true ||
						education.educations[obj].completed === 'true'
					) {
						if (!education.educations[obj].educationDegree) {
							status.missingFields.push(`educations[${obj}].educationDegree`);
						}
					}
					if (!education.educations[obj].dateFrom) {
						status.missingFields.push(`educations[${obj}].dateFrom`);
					}
					if (!education.educations[obj].dateTo) {
						status.missingFields.push(`educations[${obj}].dateTo`);
					}
					if (
						education.educations[obj].completed === 'null' ||
						education.educations[obj].completed === null ||
						education.educations[obj].completed === ''
					) {
						status.missingFields.push(`educations[${obj}].completed`);
					}

					if (
						education.educations[obj].country === 'US' ||
						education.educations[obj].country === 'U.S.' ||
						education.educations[obj].country === 'United States'
					) {
						if (!education.educations[obj].state) {
							status.missingFields.push(`educations[${obj}].state`);
						}
					}
				}

				if (status.missingFields.length === 0) {
					status.status = 'complete';
				} else {
					status.status = 'incomplete';
				}
			}
		} else {
			status.status = 'incomplete';
		}
	}

	return status;
}

//malpracticeClaimsHistorySectionCheck
function reAttestMalpracticeClaimsHistorySectionCheck(
	malpracticeClaimHistory,
	activePath,
	updateRequired
) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};

	if (!malpracticeClaimHistory) return status;

	if (
		malpracticeClaimHistory.qa1Denied === 'Yes' ||
		malpracticeClaimHistory.qa2MalpracticeClaim === 'Yes' ||
		malpracticeClaimHistory.qa3PendingClaim === 'Yes'
	) {
		if (
			!!malpracticeClaimHistory &&
			malpracticeClaimHistory.malpracticeClaimHistories.length > 0
		) {
			for (
				var obj = 0;
				obj < malpracticeClaimHistory.malpracticeClaimHistories.length;
				obj++
			) {
				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].plaintiffLastName) {
					status.missingFields.push(
						`malpracticeClaimHistories[${obj}].plaintiffLastName`
					);
				}
				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].insuranceCarrier) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].insuranceCarrier`);
				}

				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].state) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].state`);
				}

				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].status) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].status`);
				}

				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].incidentDate) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].incidentDate`);
				}
			}

			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'incomplete';
		}
	} else {
		status.status = 'complete';
	}

	if (
		!malpracticeClaimHistory.qa1Denied ||
		!malpracticeClaimHistory.qa2MalpracticeClaim ||
		!malpracticeClaimHistory.qa3PendingClaim
	) {
		if (!!updateRequired) {
			if (!malpracticeClaimHistory.qa1Denied && updateRequired.updateQa1Denied === true) {
				status.missingFields.push('qa1Denied');
			}

			if (
				!malpracticeClaimHistory.qa2MalpracticeClaim &&
				updateRequired.updateQa2MalpracticeClaim === true
			) {
				status.missingFields.push('qa2MalpracticeClaim');
			}

			if (
				!malpracticeClaimHistory.qa3PendingClaim &&
				updateRequired.qa3PendingClaim === true
			) {
				status.missingFields.push('qa3PendingClaim');
			}
		}
		status.status = 'incomplete';
	}

	if (
		malpracticeClaimHistory.qa1Denied === 'No' &&
		malpracticeClaimHistory.qa2MalpracticeClaim === 'No' &&
		malpracticeClaimHistory.qa3PendingClaim === 'No'
	) {
		status.status = 'complete';
	}
	return status;
}

function malpracticeClaimsHistorySectionCheck(malpracticeClaimHistory, activePath) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};

	if (!malpracticeClaimHistory) return status;

	if (
		malpracticeClaimHistory.qa1Denied === 'Yes' ||
		malpracticeClaimHistory.qa2MalpracticeClaim === 'Yes' ||
		malpracticeClaimHistory.qa3PendingClaim === 'Yes'
	) {
		if (
			!!malpracticeClaimHistory &&
			malpracticeClaimHistory.malpracticeClaimHistories.length > 0
		) {
			for (
				var obj = 0;
				obj < malpracticeClaimHistory.malpracticeClaimHistories.length;
				obj++
			) {
				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].plaintiffLastName) {
					status.missingFields.push(
						`malpracticeClaimHistories[${obj}].plaintiffLastName`
					);
				}
				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].insuranceCarrier) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].insuranceCarrier`);
				}

				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].state) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].state`);
				}

				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].status) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].status`);
				}

				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].incidentDate) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].incidentDate`);
				}
			}

			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'incomplete';
		}
	} else {
		status.status = 'complete';
	}

	if (
		!malpracticeClaimHistory.qa1Denied ||
		!malpracticeClaimHistory.qa2MalpracticeClaim ||
		!malpracticeClaimHistory.qa3PendingClaim
	) {
		if (!malpracticeClaimHistory.qa1Denied) {
			status.missingFields.push('qa1Denied');
		}

		if (!malpracticeClaimHistory.qa2MalpracticeClaim) {
			status.missingFields.push('qa2MalpracticeClaim');
		}

		if (!malpracticeClaimHistory.qa3PendingClaim) {
			status.missingFields.push('qa3PendingClaim');
		}

		status.status = 'incomplete';
	}

	if (
		malpracticeClaimHistory.qa1Denied === 'No' &&
		malpracticeClaimHistory.qa2MalpracticeClaim === 'No' &&
		malpracticeClaimHistory.qa3PendingClaim === 'No'
	) {
		status.status = 'complete';
	}
	return status;
}

//Internship form check
function internshipSectionCheck(trainings, activePath) {
	let status = {
		status: '',
		missingFields: [],
	};

	if (activePath === ACTIVE_PATHNAME_MD) {
		if (!!trainings && trainings.length > 0) {
			for (var obj = 0; obj < trainings.length; obj++) {
				if (!trainings[obj].hospital) {
					status.missingFields.push(`trainings[${obj}].hospital`);
				}
				if (!trainings[obj].city) {
					status.missingFields.push(`trainings[${obj}].city`);
				}
				if (!trainings[obj].specialty) {
					status.missingFields.push(`trainings[${obj}].specialty`);
				}
				if (!trainings[obj].dateFrom) {
					status.missingFields.push(`trainings[${obj}].dateFrom`);
				}
				//if (trainings[obj].completed === "null" || trainings[obj].completed === null || trainings[obj].completed === "") { status.missingFields.push(`trainings[${obj}].completed`) }
				if (!!trainings[obj].country) {
					if (trainings[obj].country === 'United States' && !trainings[obj].state) {
						status.missingFields.push(`trainings[${obj}].state`);
					}
				} else {
					status.missingFields.push(`trainings[${obj}].country`);
				}
				if (!trainings[obj].isCurrentlyInRole && !trainings[obj].dateTo) {
					status.missingFields.push(`trainings[${obj}].dateTo`);
				}
				if (!!trainings[obj].isCurrentlyInRole && !trainings[obj].expectedEndDate) {
					status.missingFields.push(`trainings[${obj}].expectedEndDate`);
					if (
						trainings[obj].completed === 'null' ||
						trainings[obj].completed === null ||
						trainings[obj].completed === ''
					) {
						status.missingFields.push(`trainings[${obj}].completed`);
					}
				}
			}
			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'notProvided';
		}
	} else {
		status.status = 'complete';
	}

	return status;
}

function referenceDateToValidation(date) {
	if (date !== null) {
		var dateTo = moment(new Date(date));
		var today = moment();
		var twoYearsAgoToday = today.subtract(2, 'year');
		//TDS-4387 ‘Date of Contact To': It should be from the past 2 years from current date
		if (twoYearsAgoToday.isSameOrBefore(dateTo, 'day')) {
			return true;
		} else {
			return false;
		}
	} else {
		return true;
	}
}

function referencesStatusCheck(reference) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};

	if (!reference) return status;

	if (
		reference.haveReferenceInTwoYear === null ||
		reference.haveReferenceInTwoYear === 'null' ||
		reference.haveReferenceInTwoYear === '' ||
		reference.haveReferenceInTwoYear === undefined
	) {
		status.missingFields.push('haveReferenceInTwoYear');
		return status;
	}
	if (reference.haveReferenceInTwoYear === true || reference.haveReferenceInTwoYear === 'true') {
		if (!!reference.references && reference.references.length > 1) {
			for (var obj = 0; obj < reference.references.length; obj++) {
				if (!reference.references[obj].firstName) {
					status.missingFields.push(`references[${obj}].firstName`);
				}
				if (!reference.references[obj].lastName) {
					status.missingFields.push(`references[${obj}].lastName`);
				}
				if (!reference.references[obj].salutation) {
					status.missingFields.push(`references[${obj}].salutation`);
				}
				if (!reference.references[obj].specialty) {
					status.missingFields.push(`references[${obj}].specialty`);
				}
				// if (!reference.references[obj].dateContactFrom) { status.missingFields.push(`references[${obj}].dateContactFrom`) }

				if (
					reference.references[obj].currentlyWorkingWith === false ||
					reference.references[obj].currentlyWorkingWith === null ||
					reference.references[obj].currentlyWorkingWith === undefined
				) {
					if (
						!reference.references[obj].dateContactTo ||
						!referenceDateToValidation(reference.references[obj].dateContactTo)
					) {
						status.missingFields.push(`references[${obj}].dateContactTo`);
					}
				}
				if (!reference.references[obj].email) {
					if (!reference.references[obj].phone) {
						status.missingFields.push(`references[${obj}].phone`);
					}
				}

				if (!reference.references[obj].phone) {
					if (!reference.references[obj].email) {
						status.missingFields.push(`references[${obj}].email`);
					}
				}
			}

			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'incomplete';
		}
	} else {
		status.status = 'complete';
	}
	return status;
}

function documentDateValidation(date) {
	if (date !== null) {
		var dateTo = moment(new Date(date));
		var today = moment();
		var sixMonthsAgoToday = today.subtract(6, 'month');
		//TDS-3908 ‘Document Date': It should be from the past 6 momnths from current date
		if (sixMonthsAgoToday.isSameOrBefore(dateTo, 'day')) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}

// documentLibraryStatusCheck - This function checks all required documents based on document types
async function documentLibraryStatusCheck(externalId) {
	let status = {
		status: '',
		missingFields: [],
	};
	//all required document types
	let docTypes = [
		DOCUMENT_TYPE.CurriculumVitae,
		DOCUMENT_TYPE.ContinuingEducation,
		DOCUMENT_TYPE.LifeSupportCerts,
		DOCUMENT_TYPE.PhotoId,
		DOCUMENT_TYPE.Photo,
	];

	const docList = {};

	await Promise.all([getDocumentsForTypes(externalId, docTypes)]).then(([docs]) => {
		if (!!docs) {
			docs.forEach((d) => {
				const docType = getNestedProperty(d, DOCUMENT_FIELD_NAME.DocumentType);
				if (!docList[docType]) docList[docType] = [d];
				else docList[docType].push(d);
			});
		}
	});

	if (Object.keys(docList).length > 0) {
		let docListArray = [];

		docTypes.forEach((t) => (docListArray = docListArray.concat(docList[t] ?? [])));

		let curriculumVitae = 0;
		let cmrORLifeSupportCerts = 0;
		let licenseOrPassport = 0;
		let headshotPhoto = 0;

		docListArray.forEach((doc) => {
			const docType = getNestedProperty(doc, DOCUMENT_FIELD_NAME.DocumentType);
			const dateModified = getNestedProperty(doc, DOCUMENT_FIELD_NAME.Modified);

			if (docType === DOCUMENT_TYPE.CurriculumVitae && documentDateValidation(dateModified)) {
				curriculumVitae = curriculumVitae + 1;
			} else if (
				(docType === DOCUMENT_TYPE.ContinuingEducation ||
					docType === DOCUMENT_TYPE.LifeSupportCerts) &&
				documentDateValidation(dateModified)
			) {
				cmrORLifeSupportCerts = cmrORLifeSupportCerts + 1;
			} else if (docType === DOCUMENT_TYPE.PhotoId && documentDateValidation(dateModified)) {
				licenseOrPassport = licenseOrPassport + 1;
			} else if (docType === DOCUMENT_TYPE.Photo && documentDateValidation(dateModified)) {
				headshotPhoto = headshotPhoto + 1;
			}
		});
		if (curriculumVitae === 0) {
			status.missingFields.push(`Current CV (in Month/Year format)`);
		}
		if (cmrORLifeSupportCerts === 0) {
			status.missingFields.push(`CME or Life Support Certifications for the past two years`);
		}
		if (licenseOrPassport === 0) {
			status.missingFields.push(`Color copy of your current Driver’s License or Passport`);
		}
		if (headshotPhoto === 0) {
			status.missingFields.push(`Current color headshot photo`);
		}

		if (status.missingFields.length === 0) {
			status.status = 'complete';
		} else {
			status.status = 'incomplete';
		}
	} else {
		status.missingFields.push(`Current CV (in Month/Year format)`);
		status.missingFields.push(`CME or Life Support Certifications for the past two years`);
		status.missingFields.push(`Color copy of your current Driver’s License or Passport`);
		status.missingFields.push(`Current color headshot photo`);
		status.status = 'incomplete';
	}

	return status;
}

//licensure Check
function licensureStatusCheck(licensures, profession, activePath) {
	let status = {
		status: 'notProvided',
		missingFields: [],
	};

	if (!!licensures && licensures.length > 0) {
		if (profession === 'SFA') {
			for (let obj = 0; obj < licensures.length; obj++) {
				if (licensures[obj].licenseType) {
					if (!licensures[obj].licenseTitle) {
						status.missingFields.push(`licensures[${obj}].licenseTitle`);
					}
					if (!licensures[obj].state) {
						status.missingFields.push(`licensures[${obj}].state`);
					}
					if (!licensures[obj].licenseStatus) {
						status.missingFields.push(`licensures[${obj}].licenseStatus`);
					}
				}
			}
		} else {
			for (let obj = 0; obj < licensures.length; obj++) {
				if (!licensures[obj].licenseType) {
					status.missingFields.push(`licensures[${obj}].licenseType`);
				}
				if (!licensures[obj].licenseTitle) {
					status.missingFields.push(`licensures[${obj}].licenseTitle`);
				}
				if (!licensures[obj].state) {
					status.missingFields.push(`licensures[${obj}].state`);
				}
				if (!licensures[obj].licenseStatus) {
					status.missingFields.push(`licensures[${obj}].licenseStatus`);
				}
				//if (!licensures[obj].dateIssued) { status.missingFields.push(`licensures[${obj}].dateIssued`) }
			}
		}
		if (status.missingFields.length === 0) {
			status.status = 'complete';
		} else {
			status.status = 'incomplete';
		}
	} else {
		if (profession === 'SFA') {
			status.status = 'notProvided';
		} else {
			status.status = 'incomplete';
		}
	}

	return status;
}

async function otherCertificationsStatusCheck(otherCertificationsObject) {
	let status = '';
	if (!!otherCertificationsObject && otherCertificationsObject.length > 0) {
		status = 'complete';
	} else {
		//If nothing filled on the page and not any object then set status - notprovided
		status = 'notProvided';
	}

	return status;
}

function certificationExamsStatusCheck(examinationObject) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};

	if (!!examinationObject && examinationObject.length > 0) {
		status.status = 'complete';

		examinationObject.forEach(function (item, index) {
			// if (item.examStatus === 'Passed' && item.datePassed === null) {
			//     //status.status = 'incomplete';
			//     //status.missingFields.push(`examinations[${index}].datePassed`);
			// } else
			if (
				examinationObject.length === 1 &&
				!item.examStatus &&
				!item.examinationType &&
				!item.explainOther &&
				!item.timesTaken &&
				!item.datePassed
			) {
				status.status = 'notProvided';
			}
		});
	} else {
		//If nothing filled on the page and not any object then set status - notprovided
		status.status = 'notProvided';
	}
	return status;
}

function malpracticeCarriersStatusCheck(malpracticeCarrier) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};

	if (!malpracticeCarrier) return status;

	let malpracticeCarriersObject = malpracticeCarrier.malpracticeCarriers;
	if (!malpracticeCarrier.currentMalpracticeInsurance) {
		status.missingFields.push('currentMalpracticeInsurance');
		return status;
	}
	if (malpracticeCarrier.currentMalpracticeInsurance === 'Yes') {
		if (!!malpracticeCarriersObject && malpracticeCarriersObject.length > 0) {
			for (var obj = 0; obj < malpracticeCarriersObject.length; obj++) {
				if (!malpracticeCarriersObject[obj].dateFrom) {
					status.missingFields.push(`malpracticeCarriers[${obj}].dateFrom`);
				}
				if (!malpracticeCarriersObject[obj].dateTo) {
					status.missingFields.push(`malpracticeCarriers[${obj}].dateTo`);
				}
				if (!malpracticeCarriersObject[obj].coverageLimit) {
					status.missingFields.push(`malpracticeCarriers[${obj}].coverageLimit`);
				}
				if (!malpracticeCarriersObject[obj].name) {
					status.missingFields.push(`malpracticeCarriers[${obj}].name`);
				}
			}
			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'incomplete';
		}
	} else {
		status.status = 'complete';
	}
	return status;
}

function disciplinaryActionQuestionsStatusCheck(questions) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};

	if (!questions) return status;
	const disciplinaryQuestions = [
		'Had disciplinary action on medical license',
		'Had disciplinary action on DEA registration',
		'Had disciplinary action on other professional registration',
		'Had disciplinary action on clinical privileges',
		'Had disciplinary action on membership',
		'Had disciplinary action on institutional affiliation',
		'Had disciplinary acton on professional society membership',
		'Has professional sanction',
		'Had disciplinary action on govt health insurance',
	];

	let questionsObj = questions.map((questions) => {
		if (questions.hasOwnProperty('Question')) {
			if (!!questions.Answer) return questions.Question;
		}
		return questions.question;
	});

	if (!questions || questions.length === 0) {
		// In this case add all questions to missing field list.
		for (let que = 0; que < disciplinaryQuestions.length; que++) {
			status.missingFields.push(`questions[${que}].Answer`);
		}
	} else if (!!questions && questions.length > 0) {
		for (let que = 0; que < disciplinaryQuestions.length; que++) {
			var a = questionsObj.indexOf(disciplinaryQuestions[que]);
			if (a < 0) {
				status.missingFields.push(`questions[${que}].Answer`);
			}
		}
	}

	if (status.missingFields.length === 0) {
		status.status = 'complete';
	} else {
		status.status = 'incomplete';
	}

	console.log('Status returned is ' + JSON.stringify(status));
	return status;
}

function disclosureQuestionsStatusCheck(questions) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};

	if (!questions) return status;
	const disclosureQuestions = [
		'Has used drugs illegally',
		'Has significant physical or mental condition',
		'Unable to safely perform the essential functions',
		'Has criminal charges',
		'Has been arrested for child related sexual offense',
		'Has been convicted or plead out of a criminal offense',
		'Has significant issue affecting their ability to perform',
		'Has been reported to NPDB or HIPDB',
	];

	let questionsObj = questions.map((questions) => {
		if (questions.hasOwnProperty('Question')) {
			if (!!questions.Answer) return questions.Question;
		}
		return questions.question;
	});

	if (!questions || questions.length === 0) {
		// In this case add all questions to missing field list.
		for (var que = 0; que < disclosureQuestions.length; que++) {
			status.missingFields.push(`questions[${que}].Answer`);
		}
	} else if (!!questions && questions.length > 0) {
		for (que = 0; que < disclosureQuestions.length; que++) {
			var a = questionsObj.indexOf(disclosureQuestions[que]);
			if (a < 0) {
				status.missingFields.push(`questions[${que}].Answer`);
			}
		}
	}

	if (status.missingFields.length === 0) {
		status.status = 'complete';
	} else {
		status.status = 'incomplete';
	}
	return status;
}

///**** Percentage Complete calcs

async function additionalTrainingSectionPointsCheck(trainings, activePath, sectionCompletion) {
	if (!trainings) return 0;

	let status = await additionalTrainingSectionCheck(trainings, activePath);
	let points = 0;
	let possiblePoints;
	if (activePath === ACTIVE_PATHNAME_AP) {
		possiblePoints = sectionCompletion?.training?.additionalTraining?.total ?? 0;
	} else {
		possiblePoints = 0;
	}
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function personalInformationSectionPointsCheck(personalInfoObject, sectionCompletion) {
	if (!personalInfoObject) return 0;

	let status = await personalInformationSectionCheck(personalInfoObject);
	let points = 0;
	let possiblePoints = sectionCompletion?.personalInformation?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function documentLibraryStatusPointsCheck(externalId, sectionCompletion) {
	if (!externalId) return 0;

	let status = await documentLibraryStatusCheck(externalId);
	let points = 0;
	let possiblePoints = sectionCompletion?.documentLibrary?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function otherCertificationsStatusPointsCheck(otherCertificationsObject, sectionCompletion) {
	if (!otherCertificationsObject) return 0;

	let status = await otherCertificationsStatusCheck(otherCertificationsObject);
	let points = 0;
	let possiblePoints = sectionCompletion?.certifications?.otherCertifications?.total ?? 0;
	if (status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function certificationExamsStatusPointsCheck(examinationObject, sectionCompletion) {
	if (!examinationObject) return 0;

	let status = await certificationExamsStatusCheck(examinationObject);
	let points = 0;
	let possiblePoints = sectionCompletion?.certifications?.certificationExams?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function malpracticeCarriersStatusPointsCheck(malpracticeCarrier, sectionCompletion) {
	if (!malpracticeCarrier) return 0;

	let status = await malpracticeCarriersStatusCheck(malpracticeCarrier);
	let points = 0;
	let possiblePoints = sectionCompletion?.malpracticeInsurance?.malpracticeCarriers?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function disciplinaryActionQuestionsStatusPointsCheck(questions, sectionCompletion) {
	if (!questions) return 0;

	let status = await disciplinaryActionQuestionsStatusCheck(questions);
	let points = 0;
	let possiblePoints =
		sectionCompletion?.attestationQuestions?.disciplinaryActionQuestions?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function disclosureQuestionsStatusPointsCheck(questions, sectionCompletion) {
	if (!questions) return 0;

	let status = await disclosureQuestionsStatusCheck(questions);
	let points = 0;
	let possiblePoints = sectionCompletion?.attestationQuestions?.disclosureQuestions?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function licensureStatusPointsCheck(licensures, sectionCompletion, profession, activePath) {
	if (!licensures) return 0;

	let status = await licensureStatusCheck(licensures, profession, activePath);
	let points = 0;
	let possiblePoints = sectionCompletion?.certifications?.licensure?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function referencesStatusPointsCheck(references, sectionCompletion) {
	if (!references) return 0;

	let status = await referencesStatusCheck(references);
	let points = 0;
	let possiblePoints = sectionCompletion?.references?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function internshipSectionPointsCheck(trainings, activePath, sectionCompletion) {
	if (!trainings) return 0;

	let status = await internshipSectionCheck(trainings, activePath);
	let points = 0;
	let possiblePoints;
	activePath === ACTIVE_PATHNAME_MD
		? (possiblePoints = sectionCompletion?.training?.internship?.total ?? 0)
		: (possiblePoints = 0);
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function residencySectionPointsCheck(trainings, activePath, sectionCompletion) {
	if (!trainings) return 0;

	let status = await residencySectionCheck(trainings, activePath);
	let points = 0;
	let possiblePoints;
	activePath === ACTIVE_PATHNAME_MD
		? (possiblePoints = sectionCompletion?.training?.residency?.total ?? 0)
		: (possiblePoints = 0);
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function fellowshipSectionPointsCheck(trainings, activePath, sectionCompletion) {
	if (!trainings) return 0;

	let status = await fellowshipSectionCheck(trainings, activePath);
	let points = 0;
	let possiblePoints;
	activePath === ACTIVE_PATHNAME_MD
		? (possiblePoints = sectionCompletion?.training?.fellowship?.total ?? 0)
		: (possiblePoints = 0);
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function practiceExperienceSectionPointsCheck(workHistories, activePath, sectionCompletion) {
	if (!workHistories) return 0;

	let status = await practiceExperienceSectionCheck(workHistories, activePath);
	let points = 0;
	let possiblePoints = sectionCompletion?.workHistory?.practiceExperience?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function hospitalAffiliationSectionPointsCheck(workHistories, activePath, sectionCompletion) {
	if (!workHistories) return 0;

	let status = await hospitalAffiliationSectionCheck(workHistories, activePath);
	let points = 0;
	let possiblePoints = sectionCompletion?.workHistory?.hospitalAffiliations?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function gapsInWorkHistorySectionPointsCheck(gaps, activePath, sectionCompletion) {
	if (!gaps) return 0;

	let status = await gapsInWorkHistorySectionCheck(gaps, activePath);
	let points = 0;
	let possiblePoints = sectionCompletion?.workHistory?.gapsInWorkHistory?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function gapsInTrainingSectionPointsCheck(gaps, activePath, sectionCompletion) {
	if (!gaps) return 0;

	let status = await gapsInTrainingSectionCheck(gaps, activePath);
	let points = 0;
	let possiblePoints = sectionCompletion?.training?.gapsInTraining?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function militaryExperienceSectionPointsCheck(experience, sectionCompletion) {
	if (!experience) return 0;
	let status = await militaryExperienceSectionCheck(experience);
	let points = 0;
	let possiblePoints = sectionCompletion?.workHistory?.militaryExperience?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function malpracticeClaimsHistorySectionPointsCheck(
	malpracticeClaimHistory,
	activePath,
	sectionCompletion
) {
	if (!malpracticeClaimHistory) return 0;

	let status = await malpracticeClaimsHistorySectionCheck(malpracticeClaimHistory, activePath);
	let points = 0;
	let possiblePoints =
		sectionCompletion?.malpracticeInsurance?.malpracticeClaimsHistory?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function educationSectionPointsCheck(
	education,
	activePath,
	titles,
	segment,
	sectionCompletion
) {
	if (!education) return 0;

	let status = await educationSectionCheck(education, activePath, titles, segment);
	let points = 0;
	let possiblePoints = sectionCompletion?.education?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

async function boardCertificationsStatusPointsCheck(
	boardCert,
	activePath,
	sectionCompletion,
	segment,
	boardCertified
) {
	if (activePath === ACTIVE_PATHNAME_MD) {
		if (!boardCert) return 0;
	}

	let status = await boardCertificationsStatusCheck(
		boardCert,
		activePath,
		segment,
		boardCertified
	);
	let points = 0;
	let possiblePoints = sectionCompletion?.certifications?.boardCertifications?.total ?? 0;
	if (status.status === 'complete') {
		points = possiblePoints;
	}
	return points;
}

function calcCredState(credStatus) {
	let credState = CRED_STATE.OPEN;
	switch (credStatus) {
		case CRED_STATUS.ApplicationStarted:
		case CRED_STATUS.Incomplete:
		case CRED_STATUS.PermProvider:
		case CRED_STATUS.Expiring:
			credState = CRED_STATE.OPEN;
			break;
		case CRED_STATUS.ApplicationReceived:
		case CRED_STATUS.InProcess:
		case CRED_STATUS.TempApproved:
		case CRED_STATUS.Hold:
			credState = CRED_STATE.CLOSED;
			break;
		case CRED_STATUS.Approved:
		case CRED_STATUS.ApprovedConditions:
		case CRED_STATUS.NotApproved:
		case CRED_STATUS.Expired:
			credState = CRED_STATE.PARTIALLY_OPEN;
			break;
		default:
			credState = CRED_STATE.OPEN;
	}
	return credState;
}

function getCredAppStatusObject(credStatus) {
	let credState = {};
	switch (credStatus) {
		case CRED_STATUS.ApplicationStarted:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
			break;
		case CRED_STATUS.ApplicationSent:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
			break;
		case CRED_STATUS.ApplicationReceived:
			credState.status = CRED_APP_STATUS.Signed;
			credState.message = CRED_APP_STATUS_MESSAGE.SignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.SignedColor;
			break;
		case CRED_STATUS.InProcess:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.Approved:
			credState.status = CRED_APP_STATUS.Complete;
			credState.message = CRED_APP_STATUS_MESSAGE.CompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.CompleteColor;
			break;
		case CRED_STATUS.ApprovedConditions:
			credState.status = CRED_APP_STATUS.Complete;
			credState.message = CRED_APP_STATUS_MESSAGE.CompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.CompleteColor;
			break;
		case CRED_STATUS.TempApproved:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.NotApproved:
			credState.status = CRED_APP_STATUS.VerificationComplete;
			credState.message = CRED_APP_STATUS_MESSAGE.VerificationCompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.VerificationCompleteColor;
			break;
		case CRED_STATUS.Incomplete:
			credState.status = CRED_APP_STATUS.Incomplete;
			credState.message = CRED_APP_STATUS_MESSAGE.IncompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.IncompleteColor;
			break;
		case CRED_STATUS.Hold:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.Expired:
			credState.status = CRED_APP_STATUS.Expired;
			credState.message = CRED_APP_STATUS_MESSAGE.ExpiredMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.ExpiredColor;
			break;
		case CRED_STATUS.Expiring:
			credState.status = CRED_APP_STATUS.Expiring;
			credState.message = CRED_APP_STATUS_MESSAGE.ExpiringMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.ExpiringColor;
			break;
		default:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
	}
	return credState;
}
function getCredAppStatusObjectV2(credStatus) {
	let credState = {};
	switch (credStatus) {
		case CRED_STATUS.ApplicationStarted:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
			break;
		case CRED_STATUS.ApplicationSent:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
			break;
		case CRED_STATUS.ApplicationReceived:
			credState.status = CRED_APP_STATUS.Signed;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.SignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.SignedColor;
			break;
		case CRED_STATUS.InProcess:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.Approved:
			credState.status = CRED_APP_STATUS.Complete;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.CompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.CompleteColor;
			break;
		case CRED_STATUS.ApprovedConditions:
			credState.status = CRED_APP_STATUS.Complete;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.CompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.CompleteColor;
			break;
		case CRED_STATUS.TempApproved:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.NotApproved:
			credState.status = CRED_APP_STATUS.VerificationComplete;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.VerificationCompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.VerificationCompleteColor;
			break;
		case CRED_STATUS.PermProvider:
		case CRED_STATUS.Incomplete:
			credState.status = CRED_APP_STATUS.Incomplete;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.IncompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.IncompleteColor;
			break;
		case CRED_STATUS.Hold:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.Expired:
			credState.status = CRED_APP_STATUS.Expired;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.ExpiredMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.ExpiredColor;
			break;
		case CRED_STATUS.Expiring:
			credState.status = CRED_APP_STATUS.Expiring;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.ExpiringMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.ExpiringColor;
			break;
		default:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
	}
	return credState;
}

 function validateNpi(str) {
	const pattern = /^[0-9]{10}$/;

	if (!pattern.test(str)) {
		return false;
	}

	const digits = str.split('').map(Number);
	const npiSum = calculateNpiSum(digits.slice(0, 9));

	return (npiSum + digits[9]) % 10 === 0;
}

function calculateNpiSum(digits) {
	return digits.reduce(processNpiDigit, 24);
}

function processNpiDigit(currentSum, currentDigit, currentIndex) {
	if (currentIndex % 2 === 1) {
		return currentSum + currentDigit;
	}

	return currentSum + sumDigits(currentDigit * 2);
}

function sumDigits(num) {
	const digits = num.toString().split('').map(Number);

	return digits.reduce((currentSum, currentDigit) => currentSum + currentDigit, 0);
}

export {
	personalInformationSectionCheck,
	documentLibraryStatusCheck,
	otherCertificationsStatusCheck,
	certificationExamsStatusCheck,
	malpracticeCarriersStatusCheck,
	disciplinaryActionQuestionsStatusCheck,
	disclosureQuestionsStatusCheck,
	licensureStatusCheck,
	referencesStatusCheck,
	internshipSectionCheck,
	residencySectionCheck,
	fellowshipSectionCheck,
	additionalTrainingSectionCheck,
	practiceExperienceSectionCheck,
	hospitalAffiliationSectionCheck,
	gapsInWorkHistorySectionCheck,
	gapsInTrainingSectionCheck,
	militaryExperienceSectionCheck,
	malpracticeClaimsHistorySectionCheck,
	educationSectionCheck,
	boardCertificationsStatusCheck,
	calcCredState,
	personalInformationSectionPointsCheck,
	documentLibraryStatusPointsCheck,
	otherCertificationsStatusPointsCheck,
	certificationExamsStatusPointsCheck,
	malpracticeCarriersStatusPointsCheck,
	disciplinaryActionQuestionsStatusPointsCheck,
	disclosureQuestionsStatusPointsCheck,
	licensureStatusPointsCheck,
	referencesStatusPointsCheck,
	internshipSectionPointsCheck,
	residencySectionPointsCheck,
	fellowshipSectionPointsCheck,
	additionalTrainingSectionPointsCheck,
	practiceExperienceSectionPointsCheck,
	hospitalAffiliationSectionPointsCheck,
	gapsInWorkHistorySectionPointsCheck,
	gapsInTrainingSectionPointsCheck,
	militaryExperienceSectionPointsCheck,
	malpracticeClaimsHistorySectionPointsCheck,
	educationSectionPointsCheck,
	boardCertificationsStatusPointsCheck,
	getCredAppStatusObject,
	getCredAppStatusObjectV2,
	referenceDateToValidation,
	documentDateValidation,
	reAttestMalpracticeClaimsHistorySectionCheck,
	validateNpi,
};

// Active path check
// Segments check
// titles check - for one and more title

//requiredFiledCheck() || ConditionalRequiredFieldCheck() || EmptyObjectCheck()

// IF PATH === MD
//Page -->Internship
//Required Fieled
//	Hospital
// City
// State
// Specialty
// Dates (mm/yy) Attended From
// Completed Program?

//Conditionally Required	-->Required if "Currently Training" checkbox is NOT CHECKED
// Dates (mm/yy) Attended To
