import React, { createContext, useEffect, useState } from 'react';
import { validateToken } from '../Services/AuthService';
import useQuery from '../../utils/useQuery';

export const ClientAuthContext = createContext();

export function ClientAuthProvider(props) {
	const { children } = props;
	const [clientUser, setClientUser] = useState(null);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLoadingAuth, setIsLoadingAuth] = useState(true);
	const [rejectedAuthReason, setRejectedAuthReason] = useState('');
	const query = useQuery();
	const token = query.get('token')
		? query.get('token')
		: localStorage.getItem('token')
		? localStorage.getItem('token')
		: null;

	const value = {
		clientUser,
		setClientUser,
		isAuthenticated,
		setIsAuthenticated,
		isLoadingAuth,
		setIsLoadingAuth,
		rejectedAuthReason,
	};

	useEffect(() => {
		getIsAuthenticated();
	}, []);

	async function getIsAuthenticated() {
		setIsLoadingAuth(true);
		try {
			if (token) {
				const user = await validateToken(token);
				if (user) {
					setClientUser(user);
					localStorage.setItem('token', token);
					setIsAuthenticated(true);
				}
			} else {
				setIsAuthenticated(false);
				throw 'No token';
			}
			setIsLoadingAuth(false);
		} catch (error) {
			console.error(error);
			localStorage.clear();
			setRejectedAuthReason(error);
			setIsAuthenticated(false);
			setIsLoadingAuth(false);
		}
	}

	return <ClientAuthContext.Provider value={value}>{children}</ClientAuthContext.Provider>;
}
