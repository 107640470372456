import { Grid, IconButton, Typography, withStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import React from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import ProgressBarAnimation from '../Expense/ProgressBarAnimation';
import DescriptionIcon from '@material-ui/icons/Description';

const styles = (theme) => ({
	preview: {
		border: `1px solid ${COLORS.LT_MIDNIGHT10}`,
		padding: 12,
		borderRadius: 4,
	},
	fileThumbnail: {
		width: 40,
		height: 40,
		borderRadius: '4px',
		display: 'block',
		objectFit: 'cover',
		cursor: 'pointer',

		'&.skeleton': {
			background: COLORS.LT_MIDNIGHT25,
			animation: '$pulse 2s cubic-bezier(.4,0,.6,1) infinite',
		},
	},
	pdfThumbnail: {
		width: 40,
		height: 40,
		borderRadius: '4px',
		background: COLORS.LT_JOURNEY75,
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	fileName: {
		fontWeight: 'bold',
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
		whiteSpace: 'nowrap',
	},
	fileSize: {
		fontWeight: 'bold',
		color: COLORS.LT_MIDNIGHT50,
	},
	'@keyframes pulse': {
		'0%': {
			opacity: 1,
		},
		'50%': {
			opacity: 0.5,
		},
		'100%': {
			opacity: 1,
		},
	},
});

const FilePreview = (props) => {
	const { file, removeFile, loadingState, isDisabled, fileIndex, classes } = props;
	const initialLoadingState = { isLoading: false, duration: 0 };
	const { isLoading, duration } = loadingState[file.name] || initialLoadingState;
	const isPdf = file.type === 'application/pdf';
	const isCsvOrExcel =
		file.type === 'text/csv' ||
		file.type === 'application/vnd.ms-excel' ||
		file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const bytesToMegaBytes = (bytes) => (bytes / (1024 * 1024)).toFixed(1);
	const bytesToKiloBytes = (bytes) => (bytes / 1024).toFixed(1);

	return (
		<Grid
			container
			alignItems='center'
			justifyContent='space-between'
			key={file.name}
			className={classes.preview}
		>
			<Grid item xs={7}>
				<Grid container alignItems='center' spacing={1}>
					<Grid item>
						{!isLoading && !isPdf && !isCsvOrExcel && (
							<img
								className={classes.fileThumbnail}
								src={file.preview}
								onClick={() => window.open(file.preview, '_blank')}
							/>
						)}
						{!isLoading && isPdf && (
							<a href={file.preview} target='blank'>
								<div className={classes.pdfThumbnail}>
									<PictureAsPdfIcon />
								</div>
							</a>
						)}
						{!isLoading && isCsvOrExcel && (
							<div className={classes.pdfThumbnail}>
								<DescriptionIcon />
							</div>
						)}
						{!!isLoading && <img className={`${classes.fileThumbnail} skeleton`} />}
					</Grid>
					<Grid item xs={7}>
						<div className={classes.fileName}>
							<Typography variant='caption' title={file.name}>
								{file.name}
							</Typography>
							{isLoading && <ProgressBarAnimation durationSeconds={duration} />}
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container alignItems='center' spacing={1}>
					<Grid item>
						<Typography variant='caption' className={classes.fileSize}>
							{bytesToMegaBytes(file.size) < 0.1
								? bytesToKiloBytes(file.size) + 'KB'
								: bytesToMegaBytes(file.size) + 'MB'}
						</Typography>
					</Grid>
					<Grid item>
						<IconButton onClick={() => removeFile(fileIndex)} disabled={isDisabled}>
							<ClearIcon fontSize='small' />
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(FilePreview);
