import React from 'react';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { COLORS } from '../../utils/Application_Constants';

const TimeoutModal = ({ showModal, togglePopup, handleSignin }) => {
	return (
		<Dialog
			open={showModal}
			scroll='body'
			maxWidth={'sm'}
			fullWidth
			onClose={() => togglePopup(false)}
		>
			<DialogTitle style={{ backgroundColor: COLORS.LT_JOURNEY }}>
				You have been Logged out.
			</DialogTitle>
			<DialogContent>
				For security reasons you are automatically logged out when you have been inactive
				for more than 15 minutes.
			</DialogContent>
			<DialogActions>
				<Button color='primary' onClick={handleSignin}>
					Log In
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default TimeoutModal;
