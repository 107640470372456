import React, { Component } from 'react';
import { withStyles, Grid, Container, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ChevronLeft } from '@material-ui/icons';
import { isNullOrUndefined } from 'util';
import moment from 'moment';
import UserContext from '../../../contexts/userContext';
import BannerLogo from '../../../assets/logo/locumtenens_com_logo_mark--white.svg';
import { COLORS } from '../../../utils/Application_Constants';
import { withOktaAuth } from '@okta/okta-react';

const styles = (theme) => ({
	root: {
		width: '100%',
		height: 80,
		position: 'relative',
		color: 'white',
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		backgroundColor: COLORS.DENIM,
		backgroundImage: `url(${BannerLogo})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right center',
		backgroundSize: '285px 250px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0',
			verticalAlign: 'center',
			textAlign: 'center',
		},
	},
	pointer: {
		cursor: 'pointer',
	},
	noPadding: {
		padding: 0,
		margin: 0,
	},
	bannerButton: {
		color: 'white',
		fontSize: '16px',
	},
	alignLeft: {
		textAlign: 'left',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	alignRight: {
		textAlign: 'right',
	},
	date: {
		fontWeight: 'normal',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12pt',
		},
	},
	subHeading: {
		fontWeight: '300',
	},
});

class Banner extends Component {
	constructor(props, context) {
		super(props);
		this.context = context;
		this.state = {
			oktaUser: null,
			clientUser: null,
			authState: null,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			nextProps.clientUser !== prevState.clientUser ||
			nextProps.authState !== prevState.authState
		) {
			if (nextProps.clientUser) {
				const clientUser = nextProps.clientUser;
				return { ...prevState, clientUser };
			}
			if (nextProps.authState) {
				const oktaUser = nextProps.authState.idToken?.claims
					? nextProps.authState.idToken.claims
					: null;
				return { ...prevState, oktaUser, authState: nextProps.authState };
			}
		}

		return null;
	}

	goBack = () => {
		this.props.history.goBack();
	};

	setTabName = () => {
		const { t } = this.props;
		const pathName = this.props.history.location.pathname;
		switch (pathName) {
			case '/profile/preference':
				return `${t('tabs:PROFILE.PREFERENCES')} - ${t(
					'profile:PREFERENCES.ASSIGNMENT_PREFERENCES'
				)}`;
			case '/profile/communication':
				return `${t('tabs:PROFILE.PREFERENCES')} - ${t(
					'profile:PREFERENCES.COMMUNICATION_PREFERENCES'
				)}`;
			case '/profile/travel':
				return `${t('tabs:PROFILE.PREFERENCES')} - ${t(
					'profile:PREFERENCES.TRAVEL_PREFERENCES'
				)}`;
			case '/profile/paymentElections':
				return `${t('tabs:PROFILE.PREFERENCES')} - ${t(
					'profile:PREFERENCES.PAYMENT_ELECTION_PREFERENCES'
				)}`;
			default:
				return '';
		}
	};

	async getOktaUser() {
		const oktaUser = await this.props.oktaAuth.token.getUserInfo();
		this.setState({ ...this.state, oktaUser });
	}

	render() {
		const { history, classes, pageName, t } = this.props;
		const { clientUser, oktaUser } = this.state;

		if (history.location.pathname === '/' && !clientUser && !oktaUser) {
			return null;
		}
		if (pageName === '' || pageName === 'Time and Pay') {
			if (
				(isNullOrUndefined(this.context) ||
					isNullOrUndefined(this.context.account) ||
					isNullOrUndefined(this.context.account.idTokenClaims.given_name) ||
					isNullOrUndefined(this.context.account.idTokenClaims.family_name)) &&
				isNullOrUndefined(clientUser) &&
				isNullOrUndefined(oktaUser)
			) {
				return (
					<div className={classes.root}>
						<Container maxWidth='xl'>
							<Grid container className={classes.noPadding}>
								<Grid item>
									{t('common:MESSAGES.WELCOME')}
									<br />
									<span className={classes.date}>
										{moment().format('dddd, MMMM Do')}
									</span>
								</Grid>
							</Grid>
						</Container>
					</div>
				);
			}
			return (
				<div className={classes.root}>
					<Container maxWidth='xl'>
						<Grid container>
							<Grid item className={classes.alignLeft}>
								{clientUser ? (
									<Typography variant='body1'>
										{`${t('common:MESSAGES.WELCOME')},
									${clientUser.firstName}
									${clientUser.lastName}`}
									</Typography>
								) : oktaUser ? (
									<Typography variant='body1'>
										{`${t('common:MESSAGES.WELCOME')},
									${oktaUser.name}`}
									</Typography>
								) : (
									<Typography variant='body1'>
										{`${t('common:MESSAGES.WELCOME')},
									${this.context.account.idTokenClaims.given_name}
									${this.context.account.idTokenClaims.family_name}`}
									</Typography>
								)}
								<Typography variant='body1' className={classes.subHeading}>
									{moment().format('dddd, MMMM Do')}
								</Typography>
							</Grid>
						</Grid>
					</Container>
				</div>
			);
		}
		return (
			<div className={classes.root}>
				<Container maxWidth='xl'>
					<Grid container>
						<Grid item>
							<Button
								className={classes.bannerButton}
								onClick={this.goBack}
								variant='text'
								color='primary'
								startIcon={<ChevronLeft />}
							>
								{pageName === 'Profile' ? this.setTabName() : pageName}
							</Button>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
}

Banner.contextType = UserContext;

Banner.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
		backgroundColor: PropTypes.string,
		image: PropTypes.string,
	}).isRequired,
	pageName: PropTypes.string.isRequired,
};

export default withRouter(withTranslation()(withStyles(styles)(withOktaAuth(Banner))));
