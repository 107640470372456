import React, { useState } from 'react';
import { Button, Grid, withStyles } from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useFormContext } from 'react-hook-form';
import CancelDialog from '../Dialog/CancelDialog';
import ClearDialog from '../Dialog/ClearDialog';

const styles = (theme) => ({
	cancelButton: {
		backgroundColor: COLORS.LT_PEPPER10,
		color: COLORS.LT_PEPPER,
		'&:hover': {
			backgroundColor: COLORS.LT_PEPPER25,
		},
	},
	submitButton: {
		backgroundColor: COLORS.LT_EMERALD,
		'&:hover': {
			backgroundColor: COLORS.LT_EMERALD75,
		},
		color: 'white',
	},
	primaryLite: {
		backgroundColor: COLORS.LT_JOURNEY10,
		'&:hover': {
			backgroundColor: COLORS.LT_JOURNEY25,
		},
		color: COLORS.LT_JOURNEY,
	},
});

const ExpenseButtons = (props) => {
	const { classes, validateSubmit, validateSave, defaultValues, setActiveTabIndex,expensesPath } = props;
	const {
		formState: { isDirty },
		reset,
	} = useFormContext();
	const history = useHistory();
	const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
	const [clearDialogOpen, setClearDialogOpen] = useState(false);
	const [cleared, setCleared] = useState(false);
	const { id } = useParams();

	const handleCancel = () => {
		if (isDirty || cleared) {
			return setCancelDialogOpen(true);
		}
		history.push(expensesPath);
	};

	const handleClear = () => {
		setCleared(true);
		reset(defaultValues);
		setClearDialogOpen(false);
		setActiveTabIndex(0);
	};

	return (
		<Grid container spacing={3}>
			{!id && (
				<Grid item>
					<Button
						variant='contained'
						className={classes.submitButton}
						color='primary'
						onClick={validateSubmit}
					>
						Submit Expense
					</Button>
				</Grid>
			)}
			{id && (
				<Grid item>
					<Button variant='contained' 
						className={classes.primaryLite} 
						color='primary'
						onClick={validateSave}
						>
						Save
					</Button>
				</Grid>
			)}
			<Grid item>
				<Button
					onClick={handleCancel}
					variant='contained'
					className={classes.cancelButton}
					color='primary'
				>
					Cancel
				</Button>
			</Grid>
			{!id && (
				<Grid item>
					<Button
						variant='contained'
						color='default'
						onClick={() => setClearDialogOpen(true)}
					>
						Clear
					</Button>
				</Grid>
			)}
			<CancelDialog
				open={cancelDialogOpen}
				setOpen={setCancelDialogOpen}
				nextPath={expensesPath}
			/>
			<ClearDialog
				open={clearDialogOpen}
				setOpen={setClearDialogOpen}
				handleClear={handleClear}
			/>
		</Grid>
	);
};

export default withStyles(styles)(ExpenseButtons);
