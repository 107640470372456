import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../utils/Application_Constants';
import { Controller, useForm } from 'react-hook-form';

const styles = (theme) => ({
	dialogPaperRoot: {
		padding: '40px',
		gap: '16px',
	},
	dialogTitleRoot: {
		color: COLORS.LT_FOUNDATION,
		fontSize: '24px',
		fontWeight: '700',
		padding: '0',
	},
	dialogContentRoot: {
		padding: '0',
		'&:first-child': {
			paddingTop: '0',
		},
	},
	footNote: { color: COLORS.LT_MIDNIGHT50, fontStyle: 'italic', fontSize: '12px' },
	dialogGrid: {
		gap: '24px',
	},
	dialogActionsRoot: {
		padding: '0',
	},
	cancelButton: {
		backgroundColor: COLORS.LT_PEPPER10,
		color: COLORS.LT_PEPPER,
		'&:hover': {
			backgroundColor: COLORS.LT_PEPPER25,
		},
	},
	underlinedButton: {
		textDecoration: 'underline',
	},
	formControl: {
		marginBottom: '16px',
	},
});

const RejectionDialog = (props) => {
	const {
		classes,
		title,
		message,
		onClose,
		onExited,
		handleAction,
		open,
		name,
		setRejectionReason,
	} = props;

	const {
		errors,
		control,
		formState: { isDirty, isValid },
	} = useForm({ mode: 'onChange' });

	return (
		<form>
			<Dialog
				open={open}
				onClose={onClose}
				TransitionProps={{ onExited }}
				aria-labelledby={`${name}-dialog-title`}
				aria-describedby={`${name}-dialog-description`}
				fullWidth
				maxWidth='xs'
				classes={{ paper: classes.dialogPaperRoot }}
			>
				<DialogTitle
					id='clear-dialog-title'
					classes={{ root: classes.dialogTitleRoot }}
					disableTypography
				>
					{title}
				</DialogTitle>
				<DialogContent classes={{ root: classes.dialogContentRoot }}>
					<Controller
						render={({ onChange, value, ref }) => (
							<TextField
								minRows={5}
								className={classes.formControl}
								multiline
								value={value}
								ref={ref}
								onChange={(e) => {
									setRejectionReason(e.target.value);
									onChange(e);
								}}
								error={errors?.rejectionReason ? true : false}
								helperText={errors?.rejectionReason?.message}
							/>
						)}
						rules={{
							maxLength: {
								value: 1000,
								message: 'Must be less than 1,000 characters',
							},
							minLength: 1,
							required: true,
							pattern: /^[^A-Za-z0-9]*[A-Za-z0-9][wW]*/g,
						}}
						name='rejectionReason'
						control={control}
						defaultValue=''
					/>
					<Typography variant='body2' className={classes.footNote}>
						{message}
					</Typography>
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActionsRoot }}>
					<Button color='primary' className={classes.underlinedButton} onClick={onClose}>
						Cancel
					</Button>
					<Button
						color='primary'
						className={classes.cancelButton}
						variant='contained'
						disabled={!isDirty || !isValid}
						onClick={handleAction}
					>
						Reject Timesheet
					</Button>
				</DialogActions>
			</Dialog>
		</form>
	);
};

export default withStyles(styles)(RejectionDialog);
