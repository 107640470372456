import React from 'react';
import AdminLayout from './Components/AdminLayout.js';
import useDocumentTitle from '../utils/useDocumentTitle.js';
import { oktaAuthConfig } from './Configurations/OktaConfig.js';
import { useHistory } from 'react-router-dom';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { AdminAuthProvider } from './Contexts/AdminAuthContext.js';
import { Security } from '@okta/okta-react';

const oktaAuth = new OktaAuth({
	...oktaAuthConfig,
	redirectUri: window.location.origin + '/',
});

const AdminApp = (props) => {
	useDocumentTitle('LTOE Timesheet and Expense');
	const history = useHistory();
	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		let uri = '/login/callback';
		history.replace(toRelativeUrl(uri, window.location.origin));
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<AdminAuthProvider>
				<AdminLayout />
			</AdminAuthProvider>
		</Security>
	);
};

export default AdminApp;
