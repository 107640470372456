import React from 'react';
import { Redirect } from 'react-router-dom';
import { appInsights } from '../AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import * as Msal from '../components/msal/msal.min';
import { MSAL_CONFIG } from '../utils/Application_Constants';
import { isExpired, browserName } from '../utils/helpers';

function getState() {
	var route = window.location.hash.split('#')[1];

	if (route !== undefined) {
		return route;
	} else {
		return '/dashboard';
	}
}

class MsalSerivice {
	loginUrl = '/';

	constructor() {
		this.myMSALObj = new Msal.UserAgentApplication(MSAL_CONFIG);
		this.myMSALObj.handleRedirectCallback(this.redirectCallback);
		this.loginUrl = process.env.REACT_APP_LOGIN_URL;
		if (window.addEventListener) {
			window.addEventListener('storage', this.localStorage_event, false);
		} else {
			window.attachEvent('onstorage', this.localStorage_event);
		}
	}

	signIn = () => {
		try {
			var aState = getState();
			this.myMSALObj.config.auth.redirectUri = window.location.origin;
			this.myMSALObj.loginRedirect({
				scopes: [process.env.REACT_APP_CLIENT_ID],
				forceRefresh: true,
				state: aState,
			});
		} catch (error) {
			console.error(error);
			appInsights.trackException({
				exception: error,
				properties: { method: 'signIn', severityLevel: SeverityLevel.Error },
			});
		}
	};

	redirectToSignIn = () => {
		try {
			var aState = '/dashboard';
			this.myMSALObj.config.auth.redirectUri = window.location.origin;
			this.myMSALObj.loginRedirect({
				scopes: [process.env.REACT_APP_CLIENT_ID],
				forceRefresh: true,
				state: aState,
			});
		} catch (error) {
			console.error(error);
			appInsights.trackException({
				exception: error,
				properties: { method: 'signIn', severityLevel: SeverityLevel.Error },
			});
		}
	};

	silentReferesh = async () => {
		if (this.getAccount !== null) {
			try {
				//await this.myMSALObj.acquireTokenSilent({ scopes: [process.env.REACT_APP_CLIENT_ID], forceRefresh: true });
				//https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/FAQs
				this.myMSALObj.config.auth.redirectUri = `${window.location.origin}/redirect.html`;
				await this.myMSALObj.acquireTokenSilent({
					scopes: [process.env.REACT_APP_CLIENT_ID],
				});
			} catch (error) {
				console.log('error.errorCode: ' + error.errorCode);
				appInsights.trackException({
					exception: error,
					properties: { method: 'silentReferesh', severityLevel: SeverityLevel.Error },
				});
				if (
					error &&
					!(
						error.errorCode === 'block_token_requests' ||
						error.errorCode === 'interaction_required'
					)
				) {
					//AADB2C90077
					throw error;
				}
			}
		} else {
			var error = 'Account is null';
			appInsights.trackException({
				exception: new Error(error),
				properties: {
					method: 'silentReferesh-Account is null',
					severityLevel: SeverityLevel.Error,
				},
			});
			throw error;
		}
	};

	/**
	 * Redirects user to the dashboard when login is successful.
	 */
	redirectCallback = (error, result) => {
		if (!error) {
			const { uniqueId } = result;
			const { name, extension_SfExternalId, emails } = result.idTokenClaims;
			let firstEmail = null;
			if (!!emails && emails.length > 0) firstEmail = emails[0];
			appInsights.trackEvent({
				name: 'Login',
				properties: {
					name: name,
					clinicianId: extension_SfExternalId,
					email: firstEmail,
					b2cId: uniqueId,
					browser: browserName(),
					os: navigator.platform,
					cookiesEnabled: navigator.cookieEnabled,
				},
			});
		}
		return <Redirect to='{this.loginUrl}' />;
	};

	setNewRedirectUri = () => {
		this.myMSALObj.config.auth.postLogoutRedirectUri = `${window.location.origin}/#/logout?action=sessiontimeout`;
	};

	setInitialRedirectUri = () => {
		this.myMSALObj.config.auth.postLogoutRedirectUri = `${window.location.origin}/#/logout?action=user`;
	};

	setJobboardRedirectUri = (url) => {
		this.myMSALObj.config.auth.postLogoutRedirectUri = `${window.location.origin}/#/logout?action=jobboardRedirect&url=${url}`;
	};

	setRedirectUri = (url) => {
		this.myMSALObj.config.auth.postLogoutRedirectUri = `${url}`;
	};

	get getMSALObj() {
		return this.myMSALObj;
	}

	get isAuthenticated() {
		if (!this.myMSALObj.getAccount()) {
			console.error('id token has expired...');
			appInsights.trackException({
				exception: new Error('ID Token Expired'),
				properties: { method: 'isAuthenticated', severityLevel: SeverityLevel.Error },
			});
			return false;
		}

		return true;
	}

	get getAccount() {
		var account = null;
		//Make sure the account is not returned when the Token has expired
		if (isExpired() === false) {
			account = this.myMSALObj.getAccount();
		}

		return account;
	}

	get getRoute() {
		var returnRoute = null;
		//Make sure the Route is not returned when the Token has expired
		if (isExpired() === false) {
			returnRoute = this.myMSALObj.getAccountRoute();
		}

		return returnRoute;
	}

	removeRoute = () => {
		this.myMSALObj.removeAccountRoute();
	};

	localStorage_event = (event) => {
		if (!event) {
			event = window.event;
		}
		if (!event.newValue) return; // this is the remove event (ignore)

		if (event.key === 'loggedOut') {
			this.finishLoggingOut();
		}
	};

	fireLogOutEvent = () => {
		localStorage.setItem('loggedOut', 'anything will work here');
		localStorage.removeItem('loggedOut', 'anything will work here');
		localStorage.removeItem('LocalStorage_FirstTimeUserLogin');
		localStorage.removeItem('LocalStorage_PersonalInfoDisclaimerAlert');
		localStorage.removeItem('LocalStorage_UserSignedIN');
		localStorage.removeItem('snoozeNotification');

		const acct = this.getAccount;
		if (!!acct) {
			const { accountIdentifier } = acct;
			const { name, extension_SfExternalId, emails } = acct.idTokenClaims;
			let firstEmail = null;
			if (!!emails && emails.length > 0) firstEmail = emails[0];
			if (!!name) {
				appInsights.trackEvent({
					name: 'LogOut',
					properties: {
						name: name,
						clinicianId: extension_SfExternalId,
						email: firstEmail,
						b2cId: accountIdentifier,
					},
				});
			}
		}
	};

	pullEmailFromAccount = () => {
		const account = this.getAccount;
		let primaryEmail = null;
		if (!!account) {
			const { emails } = account.idTokenClaims;
			if (!!emails && emails.length > 0) primaryEmail = emails[0];
		}
		return primaryEmail;
	};

	logOut = () => {
		console.log(' Fire log Out event');
		this.fireLogOutEvent();
		// Function to allow normal logout to raise logout event but not to raise it if logging out because of the event
		this.finishLoggingOut();
	};

	clearAuthInfo = () => {
		//window.localStorage.clear();
		this.myMSALObj.clearCache();
	};

	clearMSALCache = () => {
		this.myMSALObj.clearCache();
	};
	finishLoggingOut = () => {
		this.clearAuthInfo();
		this.myMSALObj.logout();
	};
}

export default new MsalSerivice();
