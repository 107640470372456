/* eslint-disable react-hooks/exhaustive-deps */
import { useOktaAuth } from '@okta/okta-react';
import React, { createContext, useEffect, useState } from 'react';
import {
	ExpenseUserGroupEnum,
	ExpenseUserGroups,
	TimesheetUserGroupEnum,
	TimesheetUserGroups,
	ExpenseUserGroupProdEnum,
	ExpenseUserProdGroups,
	TimesheetUserGroupProdEnum,
	TimesheetUserProdGroups,
} from '../Configurations/OktaUserGroup.enum';

export const AdminAuthContext = createContext();

export function AdminAuthProvider(props) {
	const { children } = props;
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [adminUser, setAdminUser] = useState('');
	const { oktaAuth, authState } = useOktaAuth();
	const [isLTAssociateUser, setIsLTAssociateUser] = useState(false);
	const [isLTExpenseAssociateUser, setIsLTExpenseAssociateUser] = useState(false);
	const [isTimesheetUser, setIsTimesheetUser] = useState(false);
	const [isExpenseUser, setIsExpenseUser] = useState(false);
	const [isExpenseAdminUser, setIsExpenseAdminUser] = useState(false);
	const [accessDenied, setAccessDenied] = useState(true);
	const [isExpenseSystemAdministratorUser, setIsExpenseSystemAdministratorUser] = useState(false);

	const value = {
		adminUser,
		isAuthenticated,
		isLTAssociateUser,
		isLTExpenseAssociateUser,
		isTimesheetUser,
		isExpenseUser,
		accessDenied,
		isExpenseSystemAdministratorUser,
		isExpenseAdminUser,
	};

	useEffect(() => {
		getUserInfo();
	}, [oktaAuth, authState]);

	const getUserInfo = async () => {
		if (authState?.isAuthenticated) {
			try {
				const info = await oktaAuth?.getUser();
				const { groups } = info;
				const isProd = window.location.origin.indexOf('prd-') > -1 ? true : false;

				if (groups) {
					setAccessDenied(false);
					const hasExpenseAccess = groups.some((g) => isProd ? 
						ExpenseUserProdGroups.includes(g)
						: ExpenseUserGroups.includes(g)
					);
					const hasTimesheetAccess = groups.some((g) => isProd ? 
						TimesheetUserProdGroups.includes(g)
						: TimesheetUserGroups.includes(g)
					);
					const isExpenseSystemAdministrator = groups.some((group) => isProd ?
						group === ExpenseUserGroupProdEnum.ExpenseSystemAdministratorPROD
						: group === ExpenseUserGroupEnum.ExpenseSystemAdministrator
					);
					const isExpenseAdmin = groups.some((group) => isProd ?
						group === ExpenseUserGroupProdEnum.ExpenseAdministratorPROD
						: group === ExpenseUserGroupEnum.ExpenseAdministrator
					);
					const isLTAssociate = groups.some((group) => isProd ?
						group === TimesheetUserGroupProdEnum.LTAssociatePROD
						: group === TimesheetUserGroupEnum.LTAssociate
					);
					const isLTExpenseAssociate = groups.some((group) => isProd ? 
						group === ExpenseUserGroupProdEnum.LTExpenseAssociatePROD
						: group === ExpenseUserGroupEnum.LTExpenseAssociate
					);
					setIsExpenseAdminUser(isExpenseAdmin);
					setIsExpenseSystemAdministratorUser(isExpenseSystemAdministrator);
					setIsLTAssociateUser(isLTAssociate);
					setIsLTExpenseAssociateUser(isLTExpenseAssociate);
					setIsExpenseUser(hasExpenseAccess);
					setIsTimesheetUser(hasTimesheetAccess);
				}
				setAdminUser(info);
				setIsAuthenticated(true);
			} catch (error) {
				console.error(error);
			}
		}
	};

	return <AdminAuthContext.Provider value={value}>{children}</AdminAuthContext.Provider>;
}
