import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Radio, RadioGroup, FormControlLabel, FormControl, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { COLORS, CRED_STATE } from '../../../../../utils/Application_Constants';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '10px',
		paddingBottom: '22px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
		paddingLeft: '30px',
	},
	secondaryHeaderArea: {
		// alignItems: 'center',
		// maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '46px',
		// marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	form: {
		width: '100%',
		margin: theme.spacing(1, 0, 5),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	// inputLabel: {
	//     top: '5px',
	//     position: 'relative',
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     textTransform: 'uppercase',
	// },
	// selectInputLabel: {
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	// },
	// cssOutlinedInput: {
	//     '&$cssFocused $notchedOutline': {
	//         borderColor: `${COLORS.RAVEN}`,
	//         borderWidth: 1,
	//         borderRadius: 1,
	//     },
	//     fontSize: '16px',
	//     color: COLORS.RAVEN,
	//     padding: '18.5px 14px',
	//     '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //default
	//     },
	//     '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //hovered
	//     },
	// },

	cssFocused: {},
	disabled: {},
	focused: {},
	marginLeft12: {
		marginLeft: 12,
	},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	noTopPad: {
		paddingTop: '0 !important',
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	radioGroup: {
		marginLeft: '8px',
	},
	// inputRadio: {
	//     color: COLORS.RAVEN,
	//     '&.Mui-focused': {
	//         color: COLORS.RAVEN
	//     },
	//     textAlign: 'left',
	//     fontWeight: '800',
	//     textTransform: 'uppercase',
	//     lineHeight: '20px',
	//     marginTop: theme.spacing(-1),
	// },
	setWidthSelectGroup: {
		top: '-23px',
		width: 'calc(100% - 1px)',
		marginTop: '10px',
		marginRight: '10px',
	},
	setWidthSelects: {
		minWidth: '100%',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	selectContainerOffset: {
		padding: '0 16px 16px 16px !important',
	},
	selectContainer: {
		padding: '3px !important',
	},
	selectContainerGroup: {
		[theme.breakpoints.down('sm')]: {
			padding: '3px 10px 0px 10px !important',
		},
		minWidth: '97%',
	},
	multiGridOffset: {
		paddingTop: '13px !important',
	},
	leftPad30: {
		paddingLeft: '30px !important',
	},
	// instructions: {
	//     paddingTop: '6px',
	//     paddingBottom: '6px',
	//     textAlign: 'left',
	// },
	uploadComponent: {
		paddingLeft: '30px !important',
		paddingBottom: '30px',
	},
	containerSM: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% + 13px) !important',
		},
	},
	marginBottom20Neg: {
		marginBottom: -20,
	},
	disabledInput: {
		opacity: 0.6,
	},
}));

function ReAttestDisciplinaryQuestion({
	t,
	myQuestions,
	constantType,
	errors,
	watch,
	register,
	item,
	index,
	credState,
	highlights,
	updateHighLight,
	listConstant,
}) {
	const classes = styles();
	const lockFields = credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN;

	// To fix the issue of showng reason field on load.
	useEffect(() => {
		updateHighLight();
	}, []);

	const showExplanation = (item, index) => {
		return (
			<Grid container>
				<Grid xs={12}>{t(`credentials:${constantType}.EXPLANATION_TEXT`)}</Grid>
				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor={`${listConstant}[${index}].Explanation`}
							classes={{ root: classes.inputLabel }}
							error={
								(errors?.myQuestions &&
									errors.myQuestions[`${index}`].Explanation) ||
								(!!highlights &&
									highlights.includes(`myQuestions[${index}].Explanation`))
							}
						>
							{t(`credentials:${constantType}.EXPLANATION`)}
						</InputLabel>
						<TextField
							id={`${listConstant}[${index}].Explanation`}
							name={`${listConstant}[${index}].Explanation`}
							placeholder={
								'Provide an explanation here or upload/select a document at the bottom of the page'
							}
							margin='normal'
							variant='outlined'
							multiline
							rows={4}
							inputRef={register({
								maxLength: {
									value: 8192,
									message: t('common:MESSAGES.CHARACTER_LIMIT'),
								},
							})}
							helperText={
								(!!errors?.myQuestions &&
									errors?.myQuestions[`${index}`].Explanation &&
									errors?.myQuestions[`${index}`].Explanation['type'] !==
										'maxLength') ||
								(!!highlights &&
									highlights.includes(`myQuestions[${index}].Explanation`))
									? ''
									: !!errors?.myQuestions &&
									  errors?.myQuestions[`${index}`].Explanation &&
									  errors?.myQuestions[`${index}`].Explanation['type'] ===
											'maxLength'
									? t('common:MESSAGES.CHARACTER_LIMIT')
									: ''
							}
							FormHelperTextProps={{ className: classes.errorColor }}
							defaultValue={item.Explanation}
							autoComplete='off'
							className={lockFields ? classes.disabledInput : classes.textField}
							InputProps={{
								readOnly: lockFields,
								classes: {
									root: classes.cssOutlinedInput,
									focused: classes.cssFocused,
									notchedOutline: classes.notchedOutline,
									error: classes.error,
								},
							}}
						/>
					</FormControl>
				</Grid>
			</Grid>
		);
	};

	if (!myQuestions.includes(item.Question)) return null;
	return (
		<Grid item xs={12}>
			<div style={{ display: 'none' }}>
				<TextField
					id={`${listConstant}[${index}].Question`}
					name={`${listConstant}[${index}].Question`}
					defaultValue={item.Question}
					inputRef={register({})}
				/>
			</div>
			<InputLabel
				shrink
				htmlFor={item.Question}
				classes={{ root: classes.inputRadio }}
				error={
					(errors?.myQuestions && errors.myQuestions[`${index}`]) ||
					(!!highlights && highlights.includes(`myQuestions[${index}].Answer`))
				}
				className={
					errors?.myQuestions && errors.myQuestions[`${index}`] && classes.errorColor
				}
				required
			>
				{t(`credentials:${constantType}.${item.Question}`)}
			</InputLabel>
			<RadioGroup
				row
				aria-label='completed'
				name={`${listConstant}[${index}].Answer`}
				defaultValue={item.Answer}
				onChange={() => {
					updateHighLight();
				}}
				onBlur={() => {
					updateHighLight();
				}}
			>
				<FormControlLabel
					value='Yes'
					control={
						<Radio
							color='primary'
							style={{ color: COLORS.RAVEN }}
							inputRef={register()}
							disableRipple
							size='small'
							disabled={lockFields}
							className={lockFields ? classes.disabledInput : classes.normalInput}
						/>
					}
					label={
						<span
							style={{
								fontSize: '16px',
								color: COLORS.RAVEN,
							}}
						>
							Yes
						</span>
					}
				/>
				<FormControlLabel
					value='No'
					control={
						<Radio
							color='primary'
							style={{ color: COLORS.RAVEN }}
							inputRef={register()}
							disableRipple
							size='small'
							disabled={lockFields}
							className={lockFields ? classes.disabledInput : classes.normalInput}
						/>
					}
					label={
						<span
							style={{
								fontSize: '16px',
								color: COLORS.RAVEN,
							}}
						>
							No
						</span>
					}
				/>
			</RadioGroup>
			{(errors?.myQuestions && errors.myQuestions[`${index}`]?.Answer) ||
				(!!highlights && highlights.includes(`${listConstant}[${index}].Answer`) && (
					<span className={classes.cssLabel}>
						{t('credentials:VALIDATION_MESSAGE.ATTESTATION_QUESTION')}
					</span>
				))}
			{watch(`${listConstant}[${index}].Answer`) === 'Yes'
				? showExplanation(item, index)
				: null}
		</Grid>
	);
}
export default ReAttestDisciplinaryQuestion;
