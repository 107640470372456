import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import UtcUtils from '../../../components/GlobalComponents/UtcUtils/UtcUtils';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { textFieldStyles } from '../../Configurations/ExpenseEntryConfig';
import { COLORS } from '../../../utils/Application_Constants';

const styles = makeStyles((theme) => {
	const baseStyles = textFieldStyles(theme);
	return {
		...baseStyles,
		formControlRoot: {
			marginBottom: 0,
		},
		root: {
			...baseStyles.root,
			'& .MuiIconButton-root': {
				color: COLORS.LT_JOURNEY,
			},
		},
		inputRoot: {
			...baseStyles.inputRoot,
			'&$disabled .MuiIconButton-root': {
				background: COLORS.LT_MIDNIGHTBG2,
				color: COLORS.LT_MIDNIGHT33,
				borderRadius: 0,
			},
		},
		positionEnd: {
			marginLeft: 0,
		},
	};
});

const ExpenseDatePicker = (props) => {
	const classes = styles();

	const handleKeyPress = (event) => {
		const inputValue = event.target.value;
		const inputChar = String.fromCharCode(event.keyCode || event.which);
		const caretPosition = event.target.selectionStart;

		const isValidInput = validateInput(inputValue, inputChar, caretPosition);

		if (!isValidInput) {
			event.preventDefault();
		}
	};

	const validateInput = (inputValue, inputChar, caretPosition) => {
		const parts = inputValue.split('/');
		let [month, day, year] = parts;
		const isSlash = caretPosition === 2 || caretPosition === 5;
		caretPosition = isSlash ? caretPosition + 1 : caretPosition;
		const isMonth = caretPosition < 2;
		const isDay = caretPosition >= 2 && caretPosition <= 4;
		const isYear = caretPosition > 4;
		let isValid = true;

		if (isMonth) {
			month = inputValue.slice(0, caretPosition) + inputChar;
			const length = month.length;
			const regex = length === 1 ? /^[01]$/ : /^(0?[1-9]|1[0-2])$/;
			isValid = regex.test(month);
		}

		if (isDay) {
			day = inputValue.slice(3, caretPosition) + inputChar;
			const length = day.length;
			const regex = length === 1 ? /^[0-3]$/ : /^(0[1-9]|[12]\d|3[01])$/;
			isValid = regex.test(day);
		}

		if (isYear) {
			year = inputValue.slice(6, caretPosition) + inputChar;
			const length = year.length;
			let regex;
			switch (length) {
				case 1:
					regex = /^[1-3]$/;
					break;
				case 2:
					regex = /^(1[9-9]|2[0-9]|30)$/;
					break;
				case 3:
					regex = /^(19\d{1,2}|2\d{2}|300)$/;
					break;
				case 4:
					regex = /^(19\d{2}|2\d{3}|3000)$/;
					break;
				default:
					isValid = false;
			}

			if (regex) {
				isValid = regex.test(year);
			}
		}

		return isValid;
	};

	return (
		<MuiPickersUtilsProvider utils={UtcUtils} moment={moment}>
			<KeyboardDatePicker
				onKeyPress={handleKeyPress}
				disableToolbar
				variant='inline'
				autoOk='true'
				format='MM/DD/YYYY'
				placeholder='mm/dd/yyyy'
				margin='none'
				className={classes.root}
				InputLabelProps={{
					shrink: true,
					classes: {
						asterisk: classes.inputLabelAsterisk,
					},
				}}
				InputProps={{
					classes: {
						root: classes.inputRoot,
						input: classes.input,
						focused: classes.focused,
						multiline: classes.multiline,
						error: classes.error,
						disabled: classes.disabled,
					},
				}}
				InputAdornmentProps={{ classes: { positionEnd: classes.positionEnd } }}
				KeyboardButtonProps={{
					'aria-label': props.label && props.label,
				}}
				{...props}
			/>
		</MuiPickersUtilsProvider>
	);
};

export default ExpenseDatePicker;
