import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useForm, useFieldArray } from 'react-hook-form';
import { CircularProgress, Container } from '@material-ui/core';
import { COLORS, CRED_STATE } from '../../../../../utils/Application_Constants';
import {
	getDisclosureQuestions,
	patchDisclosureQuestions,
} from '../../../../../services/Credentialing';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import { compareEntryStrings } from '../../../../../utils/helpers';
import Alert from '@material-ui/lab/Alert';
import ReAttestDisciplinaryQuestion from './ReAttestDisciplinaryQuestion';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '10px',
		paddingBottom: '0px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
		paddingLeft: '30px',
	},
	secondaryHeaderArea: {
		// alignItems: 'center',
		// maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '46px',
		// marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	form: {
		width: '100%',
		margin: theme.spacing(1, 0, 5),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	// inputLabel: {
	//     top: '5px',
	//     position: 'relative',
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     textTransform: 'uppercase',
	// },
	// selectInputLabel: {
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	// },
	// cssOutlinedInput: {
	//     '&$cssFocused $notchedOutline': {
	//         borderColor: `${COLORS.RAVEN}`,
	//         borderWidth: 1,
	//         borderRadius: 1,
	//     },
	//     fontSize: '16px',
	//     color: COLORS.RAVEN,
	//     padding: '18.5px 14px',
	//     '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //default
	//     },
	//     '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //hovered
	//     },
	// },

	cssFocused: {},
	disabled: {},
	focused: {},
	marginLeft12: {
		marginLeft: 12,
	},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	noTopPad: {
		paddingTop: '0 !important',
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	radioGroup: {
		marginLeft: '8px',
	},
	// inputRadio: {
	//     color: COLORS.RAVEN,
	//     '&.Mui-focused': {
	//         color: COLORS.RAVEN
	//     },
	//     textAlign: 'left',
	//     fontWeight: '800',
	//     textTransform: 'uppercase',
	//     lineHeight: '20px',
	//     marginTop: theme.spacing(-1),
	// },
	setWidthSelectGroup: {
		top: '-23px',
		width: 'calc(100% - 1px)',
		marginTop: '10px',
		marginRight: '10px',
	},
	setWidthSelects: {
		minWidth: '100%',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	selectContainerOffset: {
		padding: '0 16px 16px 16px !important',
	},
	selectContainer: {
		padding: '3px !important',
	},
	selectContainerGroup: {
		[theme.breakpoints.down('sm')]: {
			padding: '3px 10px 0px 10px !important',
		},
		minWidth: '97%',
	},
	multiGridOffset: {
		paddingTop: '13px !important',
	},
	leftPad30: {
		paddingLeft: '30px !important',
	},
	// instructions: {
	//     paddingTop: '6px',
	//     paddingBottom: '6px',
	//     paddingLeft: '10px',
	//     textAlign: 'left',
	// },
	uploadComponent: {
		paddingLeft: '30px !important',
		paddingBottom: '30px',
	},
	containerSM: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% + 13px) !important',
		},
	},
	marginBottom20Neg: {
		marginBottom: -20,
	},
	headerText: {
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '32px',
		paddingTop: '16px',
		fontSize: '14px',
		fontWeight: '500',
		color: COLORS.RAVEN,
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingBottom: '0px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
}));

function ReAttestationQuestionsForm({
	t,
	setSaveHandler,
	myQuestionsCombined,
	myQuestionsDisciplinary,
	myQuestionsDisclosure,
	blockedBackNavigation,
	UserContext,
	setCredAppError,
	flags,
	updateFlags,
	activePath,
	credState,
	constantType,
}) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [displayDisciplinary, setDisplayDisciplinary] = useState(false);
	const [displayDisclousre, setDisplayDisclousre] = useState(false);
	const [origValues, setOrigValues] = useState(null);
	const [disclipinaryData, setDisclipinaryData] = useState(null); // for displaying section on form
	const [disclosureData, setDisclosureData] = useState(null); // for displaying section on form

	const [highlights, setHighlights] = useState([]);
	const [, setDisclousreData] = useState([]);
	const [, setDisciplinaryData] = useState([]);

	const { register, control, handleSubmit, errors, getValues, reset, formState, watch } = useForm(
		{
			mode: 'all',
			reValidateMode: 'onChange',
			defaultValues: {},
		}
	);
	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			setSaveHandler(
				ReAttestationQuestionsForm,
				'ReAttestation Questions',
				handleSubmit(async (data) => await onSubmit(data))
			);

			await getDisclosureQuestions(externalId)
				.then(function (data) {
					let { questions = [] } = data;
					const origValues = [];
					const discloseureQuestions = [];
					const disciplinaryQuestions = [];

					questions.forEach((item) => {
						if (item.UpdatedAnswerNeeded === true) {
							origValues.push({
								Question: item.Question,
								Answer: item.Answer,
								Explanation: item.Explanation,
							}); // push copies of original values
						}
					});

					setOrigValues(origValues);

					if (myQuestionsCombined.length > 0) {
						myQuestionsCombined.forEach((item, index) => {
							questions.map((question) => {
								if (question.UpdatedAnswerNeeded === true) {
									if (item === question.Question) {
										if (question.Explanation === null) {
											question.Explanation = '';
										}
										insert(index, question);
									}
								}
							});
							return null; // make lint happy
						});
					}

					// After shortlisting questions from response segregate them.
					if (myQuestionsCombined.length > 0 && origValues.length > 0) {
						myQuestionsCombined.forEach((combine, index) => {
							origValues.map((item) => {
								if (combine === item.Question) {
									if (myQuestionsDisciplinary.includes(item.Question)) {
										disciplinaryQuestions.push({
											Question: item.Question,
											Answer: item.Answer,
											Explanation: item.Explanation,
										});
										setDisplayDisciplinary(true);
									} else if (myQuestionsDisclosure.includes(item.Question)) {
										discloseureQuestions.push({
											Question: item.Question,
											Answer: item.Answer,
											Explanation: item.Explanation,
										});
										setDisplayDisclousre(true);
									}
								}
							});
							return null; // make lint happy
						});
					}

					setDisclipinaryData(disciplinaryQuestions);
					setDisclosureData(discloseureQuestions);
					console.log('Value of getValue is type: ' + getValues());
					flags.dataSaved = false;
					//updateFlags(flags)
					//reset(data);
					setIsLoaded(true);
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);
	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { insert } = useFieldArray({
		control,
		name: 'questions',
	});

	const updateHighlight = () => {
		let status = {
			status: 'incomplete',
			missingFields: [],
		};
		if (constantType === 'DISCIPLINARY_QUESTIONS') {
			setDisciplinaryData(watch('questions'));
			//status = disclosureQuestionsStatusCheck(watch('questions'))
		} else if (constantType === 'DISCLOSURE_QUESTIONS') {
			setDisclousreData(watch('questions'));
			//status = disclosureQuestionsStatusCheck(watch('questions'))
		}
		setHighlights(status.missingFields);
	};

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			if (origValues !== null) {
				const questions = watch('questions');
				let isDirty = false;
				if (!!questions) {
					questions.forEach((item) => {
						const orig = origValues.find((v) => {
							if (v.Question === item.Question) return v;
						});
						if (!orig) {
							if (!!item.Answer) isDirty = true;
						} else if (
							!compareEntryStrings(orig?.Answer, item?.Answer) ||
							!compareEntryStrings(orig?.Explanation, item?.Explanation)
						) {
							isDirty = true;
						}
					});
				}
				if (flags.isDirty !== isDirty) {
					flags.isDirty = true;
				}
			}
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('questions')) {
			for (let i = 0; i < errors.questions.length; i++) {
				if (!!errors?.questions[i]) {
					missingRequiredToSaveFields = Object.values(errors.questions[i]).filter(
						(anError) => {
							return anError.hasOwnProperty('type');
						}
					);
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	const classes = styles();

	async function onSubmit(data) {
		let success = false;

		const { externalId } = UserContext;

		if (data) {
			data['clinicianId'] = externalId;
			data['questions'] = [];

			if (data['myQuestions']) {
				for (var i = 0; i < data['myQuestions'].length; i++) {
					data['questions'].push(data['myQuestions'][i]);
				}
			}

			if (data['myQuestionsDisc']) {
				for (i = 0; i < data['myQuestionsDisc'].length; i++) {
					data['questions'].push(data['myQuestionsDisc'][i]);
				}
			}

			const jsonData = JSON.stringify(data);
			console.log(' jsonData post payload is:', jsonData);

			await patchDisclosureQuestions(externalId, jsonData).catch((e) => {
				setCredAppError(e);
			});

			reset(data);
			flags.dataSaved = true;
			flags.isDirty = false;
			success = true;
			updateFlags(flags);
			return success;
		}
	}

	const renderReAttestQuestion = (item, index, constantType, listConstant, dataList) => {
		return (
			<ReAttestDisciplinaryQuestion
				t={t}
				myQuestions={dataList}
				constantType={constantType}
				errors={errors}
				watch={watch}
				register={register}
				activePath={activePath}
				credState={credState}
				item={item}
				index={index}
				highlights={highlights}
				updateHighLight={updateHighlight}
				listConstant={listConstant}
			/>
		);
	};

	const renderForm = (constantType, data) => {
		if (!isLoaded) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}
		return (
			<div className={classes.profileWrapper}>
				<Grid container>
					<div>
						<Grid item md={12} spacing={4} className={classes.secondaryHeaderArea}>
							<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
								{
									<RouteDeparturePrompt
										blockedBackNavigation={blockedBackNavigation}
										when={!flags.dataSaved ? !!flags.isDirty : false}
									/>
								}

								{
									constantType === 'DISCIPLINARY_QUESTIONS' ? (
										<Grid container spacing={4}>
											{data.map((item, index) =>
												renderReAttestQuestion(
													item,
													index,
													constantType,
													'myQuestions',
													myQuestionsDisciplinary
												)
											)}
										</Grid>
									) : (
										<Grid container spacing={4}>
											{data.map((item, index) =>
												renderReAttestQuestion(
													item,
													index,
													constantType,
													'myQuestionsDisc',
													myQuestionsDisclosure
												)
											)}
										</Grid>
									)
									//null
								}
								{/* <Grid container spacing={4}>
                                    {data.map((item, index) => renderReAttestQuestion(item, index,constantType))}
                                </Grid> */}
							</form>
						</Grid>
					</div>
				</Grid>
			</div>
		);
	};

	const renderSection = (constantType, dataToDisplay) => {
		let descriptonDisc = false;
		if (constantType === 'DISCIPLINARY_QUESTIONS') {
			descriptonDisc = true;
		}
		return (
			<>
				<Container>
					<Grid container className='cr-mt-4'>
						<Grid item xs={12}>
							<h3>{t(`credentials:${constantType}.TITLE`)}</h3>
						</Grid>

						{credState === CRED_STATE.CLOSED ||
						credState === CRED_STATE.PARTIALLY_OPEN ? (
							<Grid item xs={12} md={10} className={classes.messageGrid}>
								<Alert
									severity='error'
									className={classes.alertMessage}
									icon={false}
								>
									{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
								</Alert>
							</Grid>
						) : null}

						<Grid item xs={12}>
							<p>{t(`credentials:${constantType}.INFO_MESSAGE`)}</p>
						</Grid>

						{!descriptonDisc ? null : (
							<p
								className={classes.instructions}
								dangerouslySetInnerHTML={{
									__html: t(`credentials:${constantType}.DESCRIPTION`),
								}}
							/>
						)}
						{renderForm(constantType, dataToDisplay)}
					</Grid>
				</Container>
			</>
		);
	};

	if (!isLoaded) {
		return (
			<div className={classes.progress}>
				<CircularProgress />
			</div>
		);
	} else {
		return (
			<div>
				{displayDisciplinary
					? renderSection('DISCIPLINARY_QUESTIONS', disclipinaryData)
					: null}
				{displayDisclousre ? renderSection('DISCLOSURE_QUESTIONS', disclosureData) : null}
			</div>
		);
	}
}

export default ReAttestationQuestionsForm;
