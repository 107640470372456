const availabilityOptions = [
	{
		label: 'No Immediate Availability',
		value: 'No Immediate Availability',
	},
	{
		label: '7 on / 7 off',
		value: '7 on / 7 off',
	},
	{
		label: '1-7 shifts per month',
		value: '1-7 shifts per month',
	},
	{
		label: '8-14 shifts per month',
		value: '8-14 shifts per month',
	},
	{
		label: '15-21 shifts per month',
		value: '15-21 shifts per month',
	},
	{
		label: '22+ shifts per month',
		value: '22+ shifts per month',
	},
	{
		label: '1 Week a Month',
		value: '1 Week a Month',
	},
	{
		label: '2 Weeks a Month',
		value: '2 Weeks a Month',
	},
	{
		label: '3 Weeks a Month',
		value: '3 Weeks a Month',
	},
	{
		label: 'Full Time / 4+ weeks a Month',
		value: 'Full Time / 4+ weeks a Month',
	},
	{
		label: '1 weekend a Month',
		value: '1 weekend a Month',
	},
	{
		label: '2 weekends a Month',
		value: '2 weekends a Month',
	},
	{
		label: '3 weekends a Month',
		value: '3 weekends a Month',
	},
	{
		label: 'All Weekends',
		value: 'All Weekends',
	},
	{
		label: 'Holiday',
		value: 'Holiday',
	},
	{
		label: 'Part Time',
		value: 'Part Time',
	},
];

const assignmentTypeOptions = [
	{
		label: 'Clinic',
		value: 'Clinic',
	},
	{
		label: 'ER',
		value: 'ER',
	},
	{
		label: 'Inpatient',
		value: 'Inpatient',
	},
	{
		label: 'OR',
		value: 'OR',
	},
	{
		label: 'Outpatient',
		value: 'Outpatient',
	},
	{
		label: 'Residential Treatment',
		value: 'Residential Treatment',
	},
	{
		label: 'Urgent Care',
		value: 'Urgent Care',
	},
	{
		label: 'Full Scope',
		value: 'Full Scope',
	},
];

const workInterestsOptions = [
	{
		label: 'Government',
		value: 'governmentWork',
	},
	{
		label: 'Mission',
		value: 'missionWork',
	},
	{
		label: 'Telehealth',
		value: 'telehealth',
	},
];

const shiftTypeOptions = [
	{
		label: 'Day Shift',
		value: 'Day Shift',
	},
	{
		label: 'Night Shift',
		value: 'Night Shift',
	},
	{
		label: '24-Hour Shift',
		value: '24-Hour Shift',
	},
	{
		label: 'Call',
		value: 'Call',
	},
	{
		label: '24-Hour Call',
		value: '24-Hour Call',
	},
	{
		label: 'In-House Call',
		value: 'In-House Call',
	},
];

const jobDurationOptions = [
	{
		label: 'Locums',
		value: 'Locums',
	},
	{
		label: 'Locums to Permanent',
		value: 'Locums to Perm',
	},
	{
		label: 'Permanent',
		value: 'Perm',
	},
];

const locationTypeOptions = [
	{
		label: 'On-Site',
		value: 'On-Site',
	},
	{
		label: 'Telehealth',
		value: 'Telehealth',
	},
];
const governmentWorkOptions = [
	{
		label: 'Corrections',
		value: 'Corrections',
	},
	{
		label: 'Indian Health',
		value: 'Indian Health',
	},
	{
		label: 'VetPro Active within the last year',
		value: 'VetPro',
	},
	{
		label: 'DOD',
		value: 'DOD',
	},
	{
		label: 'Veteran Affairs',
		value: 'Veteran Affairs',
	},
	{
		label: 'iGov',
		value: 'iGov',
	},
	{
		label: 'Not Applicable',
		value: 'Not Applicable',
	},
	{
		label: 'Unspecified',
		value: 'Unspecified',
	},
];

const secondLanguageOptions = [
	{
		label: 'No Second Language',
		value: 'No Second Language',
	},
	{
		label: 'American Sign Language',
		value: 'American Sign Language',
	},
	{
		label: 'Arabic',
		value: 'Arabic',
	},
	{
		label: 'Armenian',
		value: 'Armenian',
	},
	{
		label: 'Bulgarian',
		value: 'Bulgarian',
	},
	{
		label: 'Cantonese',
		value: 'Cantonese',
	},
	{
		label: 'Chinese',
		value: 'Chinese',
	},
	{
		label: 'Croatian',
		value: 'Croatian',
	},
	{
		label: 'Czech',
		value: 'Czech',
	},
	{
		label: 'Dutch',
		value: 'Dutch',
	},
	{
		label: 'English',
		value: 'English',
	},
	{
		label: 'Farsi',
		value: 'Farsi',
	},
	{
		label: 'Farsi (Persian)',
		value: 'Farsi (Persian)',
	},
	{
		label: 'Filipino',
		value: 'Filipino',
	},
	{
		label: 'French',
		value: 'French',
	},
	{
		label: 'German',
		value: 'German',
	},
	{
		label: 'Greek',
		value: 'Greek',
	},
	{
		label: 'Gujarati',
		value: 'Gujarati',
	},
	{
		label: 'Hebrew',
		value: 'Hebrew',
	},
	{
		label: 'Hindi',
		value: 'Hindi',
	},
	{
		label: 'Italian',
		value: 'Italian',
	},
	{
		label: 'Japanese',
		value: 'Japanese',
	},
	{
		label: 'Korean',
		value: 'Korean',
	},
	{
		label: 'Mandarin',
		value: 'Mandarin',
	},
	{
		label: 'Marathi',
		value: 'Marathi',
	},
	{
		label: 'Other',
		value: 'Other',
	},
	{
		label: 'Polish',
		value: 'Polish',
	},
	{
		label: 'Portuguese',
		value: 'Portuguese',
	},
	{
		label: 'Punjabi',
		value: 'Punjabi',
	},
	{
		label: 'Russian',
		value: 'Russian',
	},
	{
		label: 'Spanish',
		value: 'Spanish',
	},
	{
		label: 'Taiwanese',
		value: 'Taiwanese',
	},
	{
		label: 'Turkish',
		value: 'Turkish',
	},
	{
		label: 'Ukranian',
		value: 'Ukranian',
	},
	{
		label: 'Vietnamese',
		value: 'Vietnamese',
	},
];

const QUESITON_LIST_PREFERENCE_CENTER = [
	'assignmentType',
	'locationPreference',
	'shiftType',
	'availability',
	'nextAvailableDate',
	'locationType',
];

export {
	availabilityOptions,
	assignmentTypeOptions,
	shiftTypeOptions,
	workInterestsOptions,
	jobDurationOptions,
	locationTypeOptions,
	governmentWorkOptions,
	QUESITON_LIST_PREFERENCE_CENTER,
	secondLanguageOptions,
};
