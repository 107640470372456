/**
 * @file due to the logic for secondary numbers,
 * cleaning up the account logic by creating number logic into it's own
 * component
 * @TODO add proptypes
 */
import React, { Component } from 'react';
import {
	Grid,
	FormControl,
	InputLabel,
	withStyles,
	Button,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormHelperText,
	IconButton,
} from '@material-ui/core';
import TextFieldComponent from '../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import EditIcon from '@material-ui/icons/Edit';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { COLORS } from '../../../utils/Application_Constants';

const styles = (theme) => ({
	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
	},
	buttonFont: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		textTransform: 'capitalize',
	},
	addSecondaryPhoneButtonMargin: {
		[theme.breakpoints.up('md')]: {
			marginBottom: '25px',
		},
	},
	removeSecondaryPhoneButtonMargin: {
		[theme.breakpoints.up('md')]: {
			marginTop: '15px',
		},
	},
	editLabel: {
		color: COLORS.DENIM,
		fontSize: '14px',
		marginTop: '10px',
	},
	errorLabel: {
		color: 'red !important',
		fontSize: '14px',
		marginTop: '10px',
	},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	radioGroup: {
		marginLeft: '32px',
		marginBottom: '10px',
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px',
		},
	},
	radioGroupLabel: {
		whitespace: 'wrap',
		textAlign: 'left',
	},
	checkboxWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginBottom: '15px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
			textAlign: 'left',
		},
	},
	setWidthTo100OnMobile: {
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	disabledInput: {
		opacity: 0.6,
	},
	infoStyle: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '16px',
		textAlign: 'left',
		textTransform: 'none',
	},
	dateGrid: {
		padding: '0px',
	},
	infoAlert: {
		color: COLORS.LT_PEPPER,
		fontWeight: '500',
		fontSize: '16px',
		textAlign: 'left',
		textTransform: 'none',
		justifyContent: 'flex-start',
		marginLeft: '10px',
	},
});

class PhoneComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSecondaryPhoneNumber: false,
		};
	}

	componentDidMount() {
		const { otherPhone } = this.props;
		const state = { ...this.state };
		if (otherPhone) {
			state.showSecondaryPhoneNumber = true;
		}
		this.setState({ ...state });
	}

	addSecondaryPhoneNumberToggle = () => {
		const { showSecondaryPhoneNumber } = this.state;
		const { classes } = this.props;
		if (showSecondaryPhoneNumber) {
			return null;
		}
		return (
			<Button
				component='span'
				onClick={this.toggleSecondaryPhoneNumber}
				classes={{ text: classes.buttonFont, root: classes.addSecondaryPhoneButtonMargin }}
			>
				+ Add Secondary Number
			</Button>
		);
	};

	removeSecondaryPhoneNumberToggle = () => {
		const { showSecondaryPhoneNumber } = this.state;
		const { classes } = this.props;
		if (!showSecondaryPhoneNumber) {
			return null;
		}
		return (
			<Button
				component='span'
				onClick={this.toggleSecondaryPhoneNumber}
				classes={{
					text: classes.buttonFont,
					root: classes.removeSecondaryPhoneButtonMargin,
				}}
			>
				- Remove Secondary Phone Number
			</Button>
		);
	};

	toggleSecondaryPhoneNumber = () => {
		const state = { ...this.state };
		state.showSecondaryPhoneNumber = !state.showSecondaryPhoneNumber;
		this.setState({ ...state });
	};

	secondaryPhoneNumberToggle = () => {
		const { showSecondaryPhoneNumber } = this.state;
		const { classes, otherPhone, textFieldChangeHandler } = this.props;
		if (!showSecondaryPhoneNumber) {
			return null;
		}
		return (
			<>
				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={12}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor='Secondary Phone'
							classes={{ root: classes.inputLabel }}
						>
							Secondary Phone Number
						</InputLabel>
						<TextFieldComponent
							id='otherPhone'
							required
							classes={{ input: classes.setWidthTo100OnMobile }}
							onChange={textFieldChangeHandler}
							name='otherPhone'
							placeholder='(xxx) xxx-xxxx'
							value={otherPhone}
						/>
					</FormControl>
					{this.removeSecondaryPhoneNumberToggle()}
				</Grid>
			</>
		);
	};

	render() {
		const {
			classes,
			textFieldChangeHandler,
			handleRadioChange,
			handleCancelMobile,
			handleEditMobile,
			phoneNumber,
			mobilePhone,
			hasOptedOutOfSms,
			disableMobile,
			t,
			showOptOutAlert,
		} = this.props;
		return (
			<>
				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={12}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor='Primary Phone'
							classes={{ root: classes.inputLabel }}
						>
							Primary Phone
						</InputLabel>
						<TextFieldComponent
							id='phoneNumber'
							classes={{ input: classes.setWidthTo100OnMobile }}
							onChange={textFieldChangeHandler}
							name='phoneNumber'
							placeholder='(xxx) xxx-xxxx'
							value={phoneNumber || ''}
						/>
					</FormControl>
					{this.addSecondaryPhoneNumberToggle()}
				</Grid>

				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={12}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor='Mobile Phone'
							classes={{ root: classes.inputLabel }}
						>
							Mobile Phone
						</InputLabel>
						<TextFieldComponent
							id='mobilePhone'
							classes={{
								input: classes.setWidthTo100OnMobile,
								disabled: classes.disabledInput,
							}}
							onChange={textFieldChangeHandler}
							name='mobilePhone'
							placeholder='(xxx) xxx-xxxx'
							value={mobilePhone || ''}
							disabled={disableMobile}
						/>
					</FormControl>
					{disableMobile ? (
						<IconButton
							aria-label='edit mobile number'
							className={classes.editLabel}
							onClick={handleEditMobile}
						>
							<EditIcon color='primary' fontSize='small' />
							Edit
						</IconButton>
					) : (
						<IconButton
							aria-label='cancel mobile number'
							className={classes.errorLabel}
							onClick={handleCancelMobile}
						>
							<CancelOutlinedIcon color='secondary' fontSize='small' />
							Cancel
						</IconButton>
					)}
				</Grid>
				{!!mobilePhone && (
					<Grid item xs={12} md={6} container className={classes.radioGroup}>
						<FormControl error={!hasOptedOutOfSms}>
							<RadioGroup
								aria-label='hasOptedOutOfSms'
								name='hasOptedOutOfSms'
								value={`${hasOptedOutOfSms}`}
								defaultValue={`${hasOptedOutOfSms}`}
								onChange={handleRadioChange}
								className={classes.radioGroupLabel}
							>
								<FormControlLabel
									value='false'
									control={
										<Radio
											color='primary'
											style={{ color: COLORS.RAVEN, alignSelf: 'flex-start' }}
											disableRipple
											size='small'
										/>
									}
									label={
										<span className={classes.infoStyle}>
											{t('profile:COMMUNICATION.CONSENT')}
										</span>
									}
								/>
								<FormControlLabel
									value='true'
									control={
										<Radio
											color='primary'
											style={{ color: COLORS.RAVEN, alignSelf: 'flex-start' }}
											disableRipple
											size='small'
										/>
									}
									label={
										<span className={classes.infoStyle}>
											{t('profile:COMMUNICATION.DENY_CONSENT')}
										</span>
									}
								/>
							</RadioGroup>
						</FormControl>
						{hasOptedOutOfSms === null && (
							<FormHelperText className={classes.cssLabel}>
								{t('profile:COMMUNICATION.VALIDATION_MESSAGE')}
							</FormHelperText>
						)}

						{showOptOutAlert === true || showOptOutAlert === 'true' ? (
							<Grid item xs={12}>
								<div className={classes.infoAlert}>
									{t('profile:COMMUNICATION.OPTOUT_ALERT')}
								</div>
							</Grid>
						) : null}
					</Grid>
				)}
				{this.secondaryPhoneNumberToggle()}
			</>
		);
	}
}
export default withStyles(styles)(PhoneComponent);
