import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseDatePicker from './ExpenseDatePicker';
import moment from 'moment';

const ExpenseTransactionDate = (props) => {
    const { index, expense, isReimbursement, mode } = props;
    const { control, watch, errors } = useFormContext();
    const minDate = moment(new Date()).startOf('day').subtract(6, 'months');
    const maxDate = moment(new Date()).startOf('day');
    const expenseCategory = watch(`expenseEntries[${index}].category`);
    const billType = watch(`expenseEntries[${index}].billType`);
    const [required, setRequired] = useState({ required: (mode==='cc'?false:true)});
    const handleDateChange = (e) =>
        e && e.startOf('day') ? e.startOf('day').format('YYYY-MM-DDTHH:mm:ss') : e;

    return (
        <Grid item xs={12} md={6}>
            <Controller
                control={control}
                name={`expenseEntries[${index}].transactionDate`}
                defaultValue={expense.transactionDate || null}
                rules={{
                    required: required.required ? 'Transaction date required' : false,
                    validate: {
                        invalid: (value) => moment(value).isValid() || 'Invalid date',
                        maxDate: (value) =>
                            moment(value).isSameOrBefore(maxDate) || 'Max date reached',
                        minDate: (value) =>
                            moment(value).isSameOrAfter(minDate) || 'Min date reached',
                    },
                }}
                render={({ onChange, value }) => {
                    const isTransactionDateInImportedData = expense.importedData && 'Transaction Date' in expense.importedData;

                    return(
                        <ExpenseDatePicker
                            onChange={(e) => onChange(handleDateChange(e))}
                            value={value}
                            id='transaction-date'
                            label='Transaction date'
                            disabled={!expenseCategory || !billType || (isTransactionDateInImportedData && !isReimbursement)}
                            {...required}
                            minDate={minDate}
                            maxDate={maxDate}
                            error={Boolean(errors.expenseEntries?.[index]?.transactionDate)}
                            helperText={errors.expenseEntries?.[index]?.transactionDate?.message}
                        />
                    )
                }}
            />
        </Grid>
    );
};

export default ExpenseTransactionDate;
