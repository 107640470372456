import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';
const styles = (theme) => ({
	root: {
		width: '100%',
	},
	noTeamSpan: {
		fontWeight: '500',
		fontSize: '14px',
	},
	avatar: {
		cursor: 'pointer',
	},
});

class YourTeamPreview extends React.Component {
	render() {
		const { yourteam, assignmentLink, classes, history } = this.props;
		const items = [];

		if (!yourteam || yourteam.length === 0) {
			return (
				<>
					<span className={classes.noTeamSpan}>Your team will be assigned soon.</span>
				</>
			);
		}

		for (let i = 0; i < yourteam.length; i++) {
			items.push(<Avatar key={`avatar-${i + 1}`} src={yourteam[i].image} />);
		}

		return (
			<>
				<AvatarGroup
					onClick={(e) => (assignmentLink ? history.push(assignmentLink) : () => false)}
					spacing='medium'
					max={4}
					classes={{ avatar: classes.avatar }}
					className="assignmentSupportTeam"
				>
					{items}
				</AvatarGroup>
			</>
		);
	}
}

YourTeamPreview.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(withTranslation()(withStyles(styles)(YourTeamPreview)));
