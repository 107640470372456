import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid, withStyles, CircularProgress } from '@material-ui/core';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import ExpenseButtons from '../Components/Expense/ExpenseButtons';
import ExpenseEntryHeader from '../Components/Expense/ExpenseEntryHeader';
import ExpenseReceiptUpload from '../Components/Expense/ExpenseReceiptUpload';
import {
    createPendingCCExpense,
    updatePendingCCExpense,
    updateExpense,
    createExpense,
    createExpenseImage,
    getExpenseById,
    getDivisions,
    getPendingCCExpenseById,
} from '../Services/ExpenseService';
import { formatErrorMessage } from '../Utils/ErrorMessage';
import AddExpenseTab from '../Components/Expense/Tabs/AddExpenseTab';
import ExpenseTabs from '../Components/Expense/Tabs/ExpenseTabs';
import ExpenseTab from '../Components/Expense/Tabs/ExpenseTab';
import ExpenseTabLabel from '../Components/Expense/Tabs/ExpenseTabLabel';
import ExpenseTabPanel from '../Components/Expense/Tabs/ExpenseTabPanel';
import AddIcon from '@material-ui/icons/Add';
import BookingDetailsForm from '../Components/Expense/BookingDetailsForm';
import PreCodedExpenseDetailsForm from '../Components/Expense/PreCodedExpenseDetailsForm';
import Alert from '../Components/Alert';
import ExpenseSummary from '../Components/Expense/ExpenseSummary';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import InternalNotesForm from '../Components/Expense/InternalNotesForm';
import ReasonForSplittingForm from '../Components/Expense/ReasonForSplittingForm';
import AdditionalExpenseDetails from '../Components/Expense/AdditionalExpenseDetails';
import { AdminAuthContext } from '../../Admin/Contexts/AdminAuthContext';
import { PreCodedStatusEnum } from '../Components/Expense/PreCodedStatus.enum';
import { ExpenseStatusEnum } from '../Components/Expense/ExpenseStatus.enum';
import ExpenseNeedsReviewAlert from '../Components/Expense/ExpenseNeedsReviewAlert';
import _ from 'lodash';

const styles = (theme) => ({
    ContainerRoot: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        paddingTop: '1.5rem',
        paddingBottom: '5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
    },
    formGridContainer: {
        gap: 32,
    },
});

const defaultPendingExpense = {
    label: 'Expense',
    amount: '',
    billType: '',
    bookingId: null,
    category: '',
    clientId: '',
    clinicianFirstName: '',
    clinicianId: '',
    clinicianLastName: '',
    comments: '',
    creditCardId: '',
    currency: 'USD',
    division: '',
    merchantName: '',
    serviceFee: '',
    state: '',
    totalExpense: '',
    type: '',
    status: '',
    salesperson: '',
    recruiter: '',
    payDate: null,
    expenseClass: 4,
    splitTransactionComment: '',
    bookingType: '',
    bookingDivision: '',
    bookingCategory: '',
    sgaType: '',
    sgaDivision: '',
    sgaCategory: '',
};

const defaultCCExpense = {
    label: 'Expense',
    amount: '',
    billType: '',
    bookingId: null,
    category: '',
    clientId: '',
    clinicianFirstName: '',
    clinicianId: '',
    clinicianLastName: '',
    comments: '',
    creditCardId: '',
    currency: 'USD',
    division: '',
    merchantName: '',
    serviceFee: '',
    state: '',
    totalExpense: '',
    type: '',
    status: '',
    salesperson: '',
    recruiter: '',
    payDate: null,
    expenseClass: 0,
    splitTransactionComment: '',
    bookingType: '',
    bookingDivision: '',
    bookingCategory: '',
    sgaType: '',
    sgaDivision: '',
    sgaCategory: '',
};

const mergeFields = {
    creditCardId: 'Ingested',
    transactionDate: 'Ingested',
    amount: 'Ingested',
    merchantName: 'Ingested',
    division: 'Pending CC/Pre-Code',
    category: 'Pending CC/Pre-Code',
    type: 'Pending CC/Pre-Code',
    startDate: 'Pending CC/Pre-Code',
    endDate: 'Pending CC/Pre-Code',
    bookingId: 'Pending CC/Pre-Code',
    billType: 'Pending CC/Pre-Code',
    clinicianId: 'Pending CC/Pre-Code',
    clinicianFirstName: 'Pending CC/Pre-Code',
    clinicianLastName: 'Pending CC/Pre-Code',
    state: 'Pending CC/Pre-Code',
};

const PreCoded = (props) => {
    const { classes, mode } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [divisions, setDivisions] = useState([]);
    const [divisionsSGA, setDivisionsSGA] = useState([]);
    const { id } = useParams();
    const [alert, setAlert] = useState();
    const history = useHistory();
    const defaultValues = (mode == 'cc') ? { fileUpload: [], expenseEntries: [defaultCCExpense] } : { fileUpload: [], expenseEntries: [defaultPendingExpense] };
    const methods = useForm({ shouldUnregister: false, defaultValues: defaultValues });
    const { handleSubmit, reset, control, getValues,watch,setValue,clearErrors } = methods;
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const backLink = id ? 'All Expenses' : 'Expense Types';
    const title = id ? 'Credit Card Transaction' : 'Pending CC Expense Entry';
    const expensesPath = id ? (mode === 'pending-cc' ? '/expenses/pending-cc/review' : '/expenses/review') : '/expenses';
    const { isExpenseSystemAdministratorUser, isExpenseAdminUser } = useContext(AdminAuthContext);
    const watchExpenseEntries = watch(`expenseEntries`);
    const [isSGA, setIsSGA] = React.useState([false,false,false,false,false]);
    const {
        fields: expenseEntries,
        append,
        remove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'expenseEntries', // unique name for your Field Array
        keyName: 'expenseEntryId',
    });

    useEffect(() => {
        Promise.all([getDivisions(true,true)])
            .then(async ([divsSGA]) => {
                setDivisions(divsSGA.filter(val => val.isSGA === false));
                setDivisionsSGA(divsSGA);
            })
            .catch((error) => {
                console.error(error);
            });
        fetchExpenseById();

    }, []);

    useEffect(() => {
        if (watchExpenseEntries.length === 1 && watchExpenseEntries[0].status != "Posted"){
            clearErrors(`expenseEntries[0].splitTransactionComment`);
            setValue(`expenseEntries[0].splitTransactionComment`, '');
        }
	}, [watchExpenseEntries.length, setValue])

    const fetchExpenseById = async () => {
        try {
            setIsLoading(true);
            // TODO: Get all split/duplicate expense entires instead of singular once API is complete
            const expense = mode === 'cc' ? await getExpenseById(id) : await getPendingCCExpenseById(id);
            //Sets expenseEntries[0] backup defaults
            if (expense.expenseClass === 'SalesGeneralAndAdmin'){
                expense.bookingType = '';
                expense.bookingDivision = '';
                expense.bookingCategory = '';
                expense.sgaType = expense.type;
                expense.sgaDivision = expense.division;
                expense.sgaCategory = expense.category;
                isSGA[0] = true;
                setIsSGA(isSGA);
            }else{
                expense.bookingType = expense.type;
                expense.bookingDivision = expense.division;
                expense.bookingCategory = expense.category;
                expense.sgaType = '';
                expense.sgaDivision = '';
                expense.sgaCategory = '';
                isSGA[0] = false;
                setIsSGA(isSGA);
            }
            let expenseEntries = [];
            let formattedValues;
            if (expense.matchedId) {
                const matchedExpense = mode === 'cc' ? await getExpenseById(expense.matchedId) : await getPendingCCExpenseById(expense.matchedId);
                const matchedValues = mergeMatchedValues(matchedExpense, expense);
                formattedValues = formatResponse(matchedValues);
            } else {
                formattedValues = formatResponse(expense);
            }
            //Reset back to Null as empty string on the as the default was causing issues
            formattedValues.payDate = (_.isString(formattedValues.payDate) && formattedValues.payDate.length === 0) ? null : formattedValues.payDate.substring(0, 10);
            expenseEntries = [{ ...formattedValues, label: 'Expense' }];
            const defaultValues = { fileUpload: [], expenseEntries };
            reset(defaultValues);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const mergeMatchedValues = (matchedExpense, defaultExpense) =>
        Object.fromEntries(
            Object.entries(mergeFields).map(([key, value]) => {
                let mergedValue = [key];
                let sourceOfTruth = value === 'Ingested' ? matchedExpense : defaultExpense;

                if (
                    matchedExpense[key] &&
                    defaultExpense[key] &&
                    matchedExpense[key] !== defaultExpense[key]
                ) {
                    mergedValue = [...mergedValue, sourceOfTruth[key]];
                } else {
                    mergedValue = [...mergedValue, defaultExpense[key]];
                }

                return mergedValue;
            })
        );

    const formatResponse = (res) =>
        Object.fromEntries(
            Object.entries(res).map(([key, value]) => [key, value === null ? '' : value])
        );

    const validateSubmit = async () => {
        handleSubmit(onSubmit)();
    };

    const validateSave = async () => {
        if (mode === 'cc') {
            handleSubmit(onSaveCC)();
        } else {
            //TODO: add when needed
            //handleSubmit(onSave)();
        }

    };

    const getStatusKey = (value) => {
        if (mode === 'cc'){
            return Object.keys(ExpenseStatusEnum).find((key) => ExpenseStatusEnum[key].value === value);
        }else{
            return Object.keys(PreCodedStatusEnum).find((key) => PreCodedStatusEnum[key].value === value);
        }
    };

    const prepareExpense = (expense) => {
        expense.bookingId = parseInt(expense.bookingId, 10);
        expense.clientId = expense.clientId !== '' ? expense.clientId : null;
        expense.serviceFee = expense.serviceFee !== '' ? expense.serviceFee : null;
        expense.creditCardId = parseInt(expense.creditCardId, 10);
        expense.status = getStatusKey(expense.status);
        if (expense.billType == 'ClinicianDeduction') {
            if (expense.category == "") { expense.category = null; }
            if (expense.type == "") { expense.type = null; }
            const date = new Date;
            if (expense.transactionDate == "" || expense.transactionDate == null) { expense.transactionDate = date.toISOString(); }
        }
        Object.keys(expense).forEach((i) => {
            if (_.isString(expense[i]) && expense[i].length === 0) {
                expense[i] = null;
            }
        }
        );

        return expense;
    };

    const onSaveCC = async (data) => {
        try {
            setIsLoading(true);
            const expenses = data.expenseEntries.map(prepareExpense);
            const expenseResponse = await updateExpense(expenses[0].id, expenses[0]);
            const remainingExpenses = expenses.slice(1);
            const promises = remainingExpenses.map((expense) => {
                if (expense.id && expense.id > 0) {
                    updateExpense(expense.id, expense)
                } else {
                    createExpense(expense)
                }
            }
            );
            await Promise.all(promises);
            //Image handling onSave commented out for now
            /*
            if (data.fileUpload.length > 0) {
                const expenseId = expenseResponse.id;
                let imgData = new FormData();
                const files = data.fileUpload;
                if (Array.isArray(files)) {
                    files.forEach((file) => {
                        imgData.append('Images', file);
                    });
                } else {
                    imgData.append('Images', files);
                }
                imgData.append('Id', expenseId);
                imgData.append('IsPrecoded', true);
                await createExpenseImage(imgData);
            }
            */
            history.push({
                pathname: '/expenses/submit',
                state: { prevPage: mode === 'cc' ? '/expenses/review' : '/expenses/pending-cc/review' }
            });

        } catch (error) {
            console.error('Error onSaveCC Pending CC Expense Entry');
            reset({ expenseEntries: expenseEntries, fileUpload: data.fileUpload });
            const message = formatErrorMessage(error);
            setAlert({
                message,
                type: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    //Submit will only be used for mode pending 
    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            const expenses = data.expenseEntries.map(prepareExpense);
            const expenseResponse = await createPendingCCExpense(expenses[0]);
            const remainingExpenses = expenses.slice(1);
            const promises = remainingExpenses.map((expense) => createPendingCCExpense(expense));
            await Promise.all(promises);
            if (data.fileUpload.length > 0) {
                const expenseId = expenseResponse.id;
                let imgData = new FormData();
                const files = data.fileUpload;
                if (Array.isArray(files)) {
                    files.forEach((file) => {
                        imgData.append('Images', file);
                    });
                } else {
                    imgData.append('Images', files);
                }
                imgData.append('Id', expenseId);
                imgData.append('IsPrecoded', true);
                await createExpenseImage(imgData);
            }
            history.push({
                pathname: '/expenses/submit',
                state: { prevPage: '/expenses/pending-cc' }
            });
        } catch (error) {
            console.error('Error onSubmit Pending CC Expense Entry');
            reset({ expenseEntries: data.expenseEntries, fileUpload: data.fileUpload });
            const message = formatErrorMessage(error);
            setAlert({
                message,
                type: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddExpense = () => {
        const newExpense = {
            ...getValues('expenseEntries[0]'),
            label: 'New Expense',
            amount: '',
            billType: '',
            id: null,
            created: null,
            lastModified: null,
        };
        if (newExpense.hasOwnProperty('errors')) {
            delete newExpense.errors;
        }
        if (newExpense.hasOwnProperty('importedData')) {
            delete newExpense.importedData;
        }
        if (isSGA[0]){
            newExpense.sgaType = newExpense.type;
            newExpense.sgaDivision = newExpense.division;
            newExpense.sgaCategory = newExpense.category;
            newExpense.sgaExpenseClass = newExpense.expenseClass;
        }else{
            newExpense.bookingType = newExpense.type;
            newExpense.bookingDivision = newExpense.division;
            newExpense.bookingCategory = newExpense.category;
            newExpense.bookingExpenseClass = newExpense.expenseClass;
        }
        isSGA[expenseEntries.length] = isSGA[0];
        setIsSGA(isSGA);
        append(newExpense);
        setActiveTabIndex(expenseEntries.length);
    };

    const handleRemoveExpense = (index) => {
        remove(index);
        setActiveTabIndex(index - 1);
    };

    return (
        <Container maxWidth='xl' className={classes.ContainerRoot}>
            <FormProvider {...methods}>
                <Grid container direction='column' spacing={2}>
                    <ExpenseEntryHeader
                        title={title}
                        backLink={backLink}
                        expensesPath={expensesPath}
                    />
                    {isLoading ? (
                        <CircularProgress color='primary' />
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={4}>
                                        <Grid item sm={7} md={8} lg={9} xs={12}>
                                            <ExpenseTabs value={activeTabIndex}>
                                                {watchExpenseEntries.map((expense, index) => (
                                                    <ExpenseTab
                                                        key={expense.expenseEntryId}
                                                        id={`expense-tab-${index}`}
                                                        label={
                                                            <ExpenseTabLabel
                                                                isActive={activeTabIndex === index}
                                                                index={index}
                                                                handleRemoveTab={() =>
                                                                    handleRemoveExpense(index)
                                                                }
                                                                setValue={setActiveTabIndex}
                                                                tab={expense}
                                                            />
                                                        }
                                                    />
                                                ))}
                                                <AddExpenseTab
                                                    label={<AddIcon color='primary' />}
                                                    disabled={watchExpenseEntries.length === 5}
                                                    onClick={handleAddExpense}
                                                    id='expense-tab-add'
                                                />
                                            </ExpenseTabs>
                                            {watchExpenseEntries.map((expense, index) => (
                                                <ExpenseTabPanel
                                                    className={classes.tabPanel}
                                                    key={`${expense.expenseEntryId}-panel`}
                                                    value={activeTabIndex}
                                                    index={index}
                                                    id={`expense-panel-${index}`}
                                                >
                                                    {expense.errors && expense.errors.length > 0 && (
                                                        <ExpenseNeedsReviewAlert expense={expense} />
                                                    )}
                                                    <Grid
                                                        container
                                                        direction='column'
                                                        className={classes.formGridContainer}
                                                    >

                                                        <Grid item xs={12}>
                                                            { !isSGA[index] && 
                                                            <BookingDetailsForm
                                                            index={index}
                                                            expense={expense}
                                                            divisions={divisions}
                                                            mode={mode}
                                                            setIsSGA={setIsSGA}
                                                            isSGA={isSGA}
                                                            />}
                                                            
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <PreCodedExpenseDetailsForm
                                                                index={index}
                                                                expense={expense}
                                                                divisions={isSGA[index] ? divisionsSGA : divisions}
                                                                mode={mode}
                                                                setIsSGA={setIsSGA}
                                                                isSGA={isSGA}
                                                            />
                                                        </Grid>
                                                        {id &&
                                                            (isExpenseSystemAdministratorUser ||
                                                                isExpenseAdminUser) &&
                                                            expense.importedData && (
                                                                <Grid item xs={12}>
                                                                    <AdditionalExpenseDetails
                                                                        index={index}
                                                                        expense={expense}
                                                                        mode={mode}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        <Grid item xs={12}>
                                                            <InternalNotesForm
                                                                index={index}
                                                                expense={expense}
                                                                mode={mode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ReasonForSplittingForm
                                                                index={index}
                                                                expense={expense}
                                                                mode={mode}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </ExpenseTabPanel>
                                            ))}
                                        </Grid>
                                        <Grid item md={4} sm={5} lg={3} xs={12}>
                                            <ExpenseReceiptUpload mode={mode} />
                                            <ExpenseSummary mode={mode} />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid item xs={12}>
                                <ExpenseButtons
                                    validateSubmit={validateSubmit}
                                    validateSave={validateSave}
                                    defaultValues={defaultValues}
                                    setActiveTabIndex={setActiveTabIndex}
                                    expensesPath={id ? (mode === 'pending-cc' ? '/expenses/pending-cc/review' : '/expenses/review') : '/expenses'}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </FormProvider>
            <Alert alert={alert} setAlert={setAlert} />
        </Container>
    );
};

export default withStyles(styles)(PreCoded);
