import React, { Component } from 'react';
import {
	withStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import { COLORS, DOCUMENT_TYPES } from '../../../utils/Application_Constants';
import { getSamlResponse } from '../../../services/peoplenetSsoService';
import NotifaitionImg from '../../../assets/images/notification.svg';

const styles = (theme) => ({
	contentRoot: {
		height: '100vh',
	},
	dialogHeaderRoot: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE_LILAC,
		justifyContent: 'left',
		paddingTop: '16px',
		paddingBottom: '10px',
		position: 'relative',
		zIndex: '1',
		overflow: 'hidden',
		'&::after': {
			content: `''`,
			backgroundImage: `url(${NotifaitionImg})`,
			width: '300px',
			height: '300px',
			position: 'absolute',
			top: '-40px',
			right: '-80px',
			zIndex: '-1',
			opacity: '.2',
		},
	},

	dialogContentRoot: {
		padding: '0',
		margin: '24px',
		borderRadius: '5px',
		color: `${COLORS.RAVEN}`,
	},
	buttonRoot: {
		margin: '0 85px 15px',
		width: '200px',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			margin: '0 0 0 10px',
		},
	},
	buttonRoot1: {
		marginTop: '25px',
		maxWidth: '80%',
	},
	timeOutHeader: {
		fontWeight: '500',
		opacity: '0.5',
	},
	closeIconButtonRoot: {
		color: `${COLORS.WHITE_LILAC}`,
		position: 'absolute',
		right: '0',
		top: '7px',
		zIndex: '2',
	},
	popUpMsg: {
		textAlign: 'center',
		fontSize: '15px',
		fontWeight: '600',
		paddingTop: '10px',
		paddingBottom: '10px',
	},
	notificationMsg: {
		textAlign: 'center',
		fontSize: '15px',
		fontWeight: '600',
		color: `${COLORS.MILANO_RED}`,
		paddingTop: '10px',
		paddingBottom: '10px',
	},
	notificationImg: {
		width: '160px',
		heigh: 'auto',
		margin: '0 auto',
	},
});

class NotificationPopUpForW9DD extends Component {
	constructor(props, context) {
		super(props);
	}

	async componentDidMount() {}

	filterNotificationsForDocType = (notifications) => {
		const uniqueNotifications = [];
		const uniqueDocTypes = new Set();

		notifications.forEach((item) => {
			const docType = item.payload ? JSON.parse(item.payload).docType : null;
			if (docType && !uniqueDocTypes.has(docType)) {
				uniqueDocTypes.add(docType);
				uniqueNotifications.push(item);
			}
		});
		return uniqueNotifications;
	};

	renderDesktopTable() {
		const { classes, t, notifications, documentNotification } = this.props;
		const filteredNotifications = this.filterNotificationsForDocType(notifications);

		return (
			<>
				<div className={classes.popUpMsg}>
					{t('assignments:NOTIFICATIONS.TIMESHEET_POPUP_MSG')}
				</div>
				{documentNotification ? (
					<>
						<div className={classes.notificationMsg}>
							{t('assignments:NOTIFICATIONS.DOCUMENT_MESSAGE')}
						</div>

						{filteredNotifications.map((notification, index) =>
							this.renderDocumentSignBtn(notification)
						)}
					</>
				) : null}
			</>
		);
	}

	manageTimeSheet = () => {
		getSamlResponse();
		this.props.toggleHandler(false);
	};

	manageViewAndSign = (notification) => {
		this.props.toggleHandler(false);
		this.props.signDocumentHandler(notification);
	};

	getDocumentTypeFromPayload = (notification) => {
		const payload = JSON.parse(notification.payload);
		if (payload.docType === DOCUMENT_TYPES.ADDENDUM) {
			return DOCUMENT_TYPES.ADDENDUM;
		} else if (payload.docType === DOCUMENT_TYPES.W9 || payload.docType === DOCUMENT_TYPES.DD) {
			return payload.docType;
		} else {
			return DOCUMENT_TYPES.AGREEMENT;
		}
	};

	renderDocumentSignBtn = (notification) => {
		const { classes, history } = this.props;
		var aDocType = this.getDocumentTypeFromPayload(notification);
		if (aDocType === DOCUMENT_TYPES.W9 || aDocType === DOCUMENT_TYPES.DD) {
			return (
				<Button
					variant='contained'
					color='primary'
					classes={{ root: classes.buttonRoot }}
					onClick={() => history.push('/profile/paymentElections')}
				>
					Payment Elections - <br /> {aDocType}
				</Button>
			);
		}
	};

	render() {
		const { classes, toggleHandler, openDialog } = this.props;

		return (
			<div className={classes.contentRoot}>
				<Dialog
					open={openDialog}
					fullWidth
					onClose={() => toggleHandler(false)}
					scroll='body'
					maxWidth={'xs'}
				>
					<IconButton
						onClick={() => toggleHandler(false)}
						classes={{ root: classes.closeIconButtonRoot }}
					>
						<Close />
					</IconButton>
					<div className='notification-head'>
						<DialogTitle classes={{ root: classes.dialogHeaderRoot }}>
							<div className={classes.notificationImg}>
								<img
									style={{ zIndex: '5' }}
									alt='Notification'
									src={NotifaitionImg}
								/>
							</div>
						</DialogTitle>
					</div>
					<DialogContent classes={{ root: classes.dialogContentRoot }}>
						{this.renderDesktopTable()}
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}

export default withRouter(
	WithContexts(withTranslation()(withStyles(styles)(NotificationPopUpForW9DD)))
);
