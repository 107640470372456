import {
	Button,
	CircularProgress,
	Container,
	FormControlLabel,
	Grid,
	Paper,
	Snackbar,
	Switch,
	Typography,
	makeStyles,
} from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../utils/Application_Constants';
import TableBody from '../Components/CreditCardTable/TableBody';
import TableHeader from '../Components/CreditCardTable/TableHeader';
import { actions, tableColumns } from '../Configurations/CreditCardTableConfig';
import { getCreditCards } from '../Services/CreditCardService';

const useStyles = makeStyles((theme) => ({
	PaperRoot: {
		padding: '1.5rem 2rem',
		display: 'flex',
		gap: '24px',
		flexDirection: 'column',
	},
	ContainerRoot: {
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '16px',
			paddingRight: '16px',
		},
		paddingTop: '2.5rem',
		paddingBottom: '5rem',
		display: 'flex',
		flexDirection: 'column',
		gap: '32px',
	},
	header: {
		fontWeight: '700',
		margin: 0,
	},
	successButton: {
		background: COLORS.LT_EMERALD,
		color: 'white',

		'&:hover': {
			background: COLORS.LT_EMERALD75,
		},
	},
}));

const CreditCards = () => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [creditCards, setCreditCards] = useState([]);
	const [alert, setAlert] = useState();
	const [showAllCards, setShowAllCards] = useState(false);

	useEffect(() => {
		getData();

		return () => {};
	}, []);

	const getData = async (request) => {
		setIsLoading(true);
		try {
			const response = await getCreditCards(request);
			setCreditCards(response);
		} catch (error) {
			const message = formatErrorMessage(error);
			setAlert({
				message,
				type: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleAlertClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert(null);
	};

	const handleChange = (event) => {
		setShowAllCards(event.target.checked);
	};

	const renderAlert = () => (
		<Snackbar
			open={Boolean(alert)}
			autoHideDuration={6000}
			onClose={handleAlertClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert onClose={handleAlertClose} severity={alert?.type}>
				{alert?.message}
			</Alert>
		</Snackbar>
	);

	return (
		<Container maxWidth='xl' className={classes.ContainerRoot}>
			{renderAlert()}

			<Grid container justifyContent='space-between' alignItems='center'>
				<Grid item>
					<Typography variant='h3' component='h3' className={classes.header}>
						Credit Card Management
					</Typography>
				</Grid>
				<Grid item>
					<Grid container alignItems='center' spacing={3}>
						<Grid item>
							<FormControlLabel
								control={
									<Switch
										checked={showAllCards}
										color='primary'
										onChange={handleChange}
										name='show-all-cards'
									/>
								}
								label='Show all cards'
								labelPlacement='start'
							/>
						</Grid>
						<Grid item>
							<Button
								startIcon={<CreditCardIcon />}
								variant='contained'
								className={classes.successButton}
							>
								Add card
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Paper classes={{ root: classes.PaperRoot }}>
				<TableHeader tableColumns={tableColumns} />
				{isLoading && <CircularProgress color='primary' />}
				{!isLoading && creditCards?.length > 0 && (
					<TableBody items={creditCards} tableColumns={tableColumns} actions={actions} />
				)}
				{!isLoading && (creditCards?.length === 0 || !creditCards) && (
					<Typography variant='body2'>No results found</Typography>
				)}
			</Paper>
		</Container>
	);
};

export default CreditCards;
