import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import { COLORS } from '../../utils/Application_Constants';
import { TimesheetStatusEnumApi } from '../../components/ContentComponents/Timesheets/Status.enum';

const tableColumns = [
	{ name: 'Clinician', key: 'name', size: 2, sortKey: 'clinicianLastName' },
	{ name: 'Assignment/Location', key: 'location', size: 4, sortKey: 'location' },
	{ name: 'Hours', key: 'hours', size: 1 },
	{ name: 'Units', key: 'units', size: 1 },
	{ name: 'Mileage', key: 'miles', size: 1 },
	{ name: 'Status', key: 'status', size: 2, sortKey: 'status' },
	{ name: 'Actions', key: 'actions', size: 1 },
];

const availableStatuses = [
	TimesheetStatusEnumApi.Approved,
	TimesheetStatusEnumApi.Rejected,
	TimesheetStatusEnumApi.Submitted,
];

const filters = ['Status', 'Week Ending'];

const actions = [
	{
		name: 'Approve',
		requestedStatus: TimesheetStatusEnumApi.Approved,
		icon: <DoneIcon fontSize='small' style={{ color: COLORS.LT_EMERALD }} />,
	},
	{
		name: 'Reject',
		requestedStatus: TimesheetStatusEnumApi.Rejected,
		icon: <ClearIcon fontSize='small' style={{ color: COLORS.LT_PEPPER }} />,
	},
	{ name: 'View', requestedStatus: 'View', icon: <VisibilityIcon fontSize='small' /> },
];

const errorCodes = {
	'00013': 'Approved',
	'00014': 'Rejected',
	'00015': 'Recalled',
};

const getViewUrl = ({ clinicianId, id, locationId }) => {
	return `/clinician/${clinicianId}/timesheet/${id}/review/${locationId}`;
};

export const config = {
	tableColumns,
	availableStatuses,
	actions,
	filters,
	errorCodes,
	getViewUrl,
};
