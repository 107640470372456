import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import TimesheetsDashboard from '../../components/ContentComponents/Timesheets/TimesheetsDashboard';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../contexts/withContexts';
const styles = (theme) => ({});

function Timesheets(props) {
	return (
		<>
			<TimesheetsDashboard {...props} />
		</>
	);
}

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(Timesheets))));
