import React, { Component } from 'react';
import { withStyles, Grid, Paper } from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import { ltdomain } from '../../../utils/helpers';

const styles = (theme) => ({
	paper: {
		padding: '2rem',
		textAlign: 'center',
		width: 'auto',
	},
	header: {
		textAlign: 'left',
		fontWeight: '700',
		letterSpacing: '0',
		fontSize: '20px',
		color: COLORS.RAVEN,
		opacity: '0.3',
	},
	headerLevel2: {
		textAlign: 'center',
		fontWeight: '700',
		letterSpacing: '0',
		fontSize: '21px',
		color: COLORS.LT_STEEL,
		opacity: '1',
		paddingTop: '2rem',
	},
	assignmentListContainerRoot: {
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		width: '100%',
		padding: '0 20px',
		margin: '0px 20px 20px',
		textAlign: 'left',
		borderRadius: '3px',
		position: 'relative',
	},
	assignmentListPaper: {
		width: '100%',
	},
	linkJobsandRecruiter: {
		textAlign: 'center',
		paddingBottom: '30px',
		fontWeight: '700',
		letterSpacing: '0',
		fontSize: '21px',
		color: COLORS.LT_STEEL,
	},
	linkJobsandRecruiterURL: {
		fontWeight: '700',
		letterSpacing: '0',
		fontSize: '21px',
		color: COLORS.LT_JOURNEY,
		opacity: '1',
	},
});

class AssignmentListEmptyState extends Component {
	renderText = () => {
		debugger;
		const { error, assignmentType } = this.props;
		if (!error) {
			return `You have no ${assignmentType} assignments`;
		}
		return `There has been an error retrieving ${assignmentType} assignments - please contact your recruiter`;
	};

	renderTextLevel2 = () => {
		const { classes } = this.props;
		const { error, assignmentType } = this.props;
		if (!error) {
			return (
				<Paper classes={{ root: classes.paper }}>
					<p>
						<strong>
							You have no {assignmentType} assignments. Searching for a new job?
						</strong>
					</p>

					<p>
						<a
							className='textLink'
							target='_Self'
							href={ltdomain() + '/Resources/JobSearch/QuickSearch/'}
						>
							Search our jobs{' '}
						</a>{' '}
						or{' '}
						<a
							className='textLink'
							target='_Self'
							href={ltdomain() + '/contact-a-recruiter/'}
						>
							{' '}
							contact a recruiter
						</a>
					</p>
				</Paper>
			);
		}
		return `There has been an error retrieving ${assignmentType} assignments - please contact your recruiter`;
	};

	render() {
		return (
			<>
				<Grid container>
					<Grid item xs={12}>
						{this.renderTextLevel2()}
					</Grid>
				</Grid>
			</>
		);
	}
}

export default withStyles(styles)(AssignmentListEmptyState);
