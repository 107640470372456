import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Drawer, List } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Menu, Close } from '@material-ui/icons';
import MobileDrawerNavigation from '../MobileDrawerNavigation/MobileDrawerNavigation';
import { COLORS } from '../../../utils/Application_Constants';
import Logo from '../../../assets/logo/locumtenens_com_logo.svg';
import { menuNavigationProps } from '../../../types/centralizedProps';
import { withTranslation } from 'react-i18next';
import ProfileNavComponent from '../../ContentComponents/ProfileNavigation/ProfileNavComponent';
import NavigationService from '../../../services/NavigationService';
import IconButton from '@material-ui/core/IconButton';
import UserContext from '../../../contexts/userContext';
import Notifications from '../../HeaderComponents/Notifications/Notifications';

const styles = (theme) => ({
	gridWrapper: {
		display: 'flex',
		// padding: '18px 16px 18px 16px',
	},
	imageWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		padding: '16px 10px 18px 10px',
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 5px 18px 16px',
	},
	drawerPaper: {
		width: '100%',
		backgroundColor: 'none',
		boxShadow: 'none',
		marginTop: '60px',
	},
	logo: {
		width: '173px',
	},
	icon: {
		fill: COLORS.DENIM,
		fontSize: 30,
	},
	toggleIcon: {
		display: 'flex',
		justifyContent: 'flex-start',
		padding: '18px 10px 18px 10px',
	},
	mobileNavigation: {
		color: COLORS.RAVEN,
		height: '80vh',
		overflow: 'scroll',
	},
	logoutNavigationList: {
		position: 'absolute',
		width: '100%',
		fontWeight: '700',
		bottom: '60px',
		backgroundColor: COLORS.WHITE,
		paddingTop: 0,
	},
	logoutNavigationItemText: {
		fontWeight: '700',
		color: COLORS.DENIM,
		display: 'flex',
	},
	supportNavigationList: {
		position: 'absolute',
		width: '100%',
		fontWeight: '700',
		bottom: '200px',
		backgroundColor: COLORS.WHITE,
		paddingTop: 0,
	},
	profileNavigationList: {
		position: 'absolute',
		width: '100%',
		fontWeight: '700',
		bottom: '150px',
		backgroundColor: COLORS.WHITE,
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		paddingTop: 0,
		padding: '0 28px 0 28px',
	},
	supportNavNumber: {
		fontWeight: '700',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
	},
});

class MobileNavbar extends Component {
	constructor(props, context) {
		super(props);
		this.context = context;
		this.state = {
			drawerToggled: false,
			profileNavigation: [],
			isAdminApp: process.env.REACT_APP_TYPE === 'Admin',
			isClientApp: process.env.REACT_APP_TYPE === 'Client',
		};
	}
	componentDidMount() {
		const profileNavigation = NavigationService.getProfileNavigation();
		this.setState({ profileNavigation: profileNavigation.profile });
	}

	drawerToggle = () => {
		const { drawerToggled } = this.state;
		this.setState({ drawerToggled: !drawerToggled });
	};

	renderSupportNumber = () => {
		const { t, classes } = this.props;
		return (
			<a href={t('common:INFORMATION.HELP_NUMBER_LINK')} className={classes.supportNavNumber}>
				<IconButton
					aria-label='account of current user'
					aria-haspopup='true'
					color='inherit'
				>
					<i className='fa fa-phone'></i>
				</IconButton>
			</a>
		);
	};

	render() {
		const { classes, menuNavigation,refreshNotifications } = this.props;
		const { drawerToggled, profileNavigation, isClientApp, isAdminApp } = this.state;
		const { account } = this.context;

		return (
			<Grid item className={classes.gridWrapper}>
				<Grid item className={classes.toggleIcon} xs={1}>
					{drawerToggled ? (
						<Close onClick={this.drawerToggle} className={classes.icon} />
					) : (
						<Menu onClick={this.drawerToggle} className={classes.icon} />
					)}
					<Drawer
						variant='persistent'
						classes={{ paper: classes.drawerPaper }}
						open={drawerToggled}
					>
						<List className={classes.mobileNavigation}>
							<MobileDrawerNavigation
								toggleDrawer={this.drawerToggle}
								menuNavigation={menuNavigation}
							/>
						</List>
						{/* Login funcitonlaity is added in profile navigation 
            <List classes={{ root: classes.logoutNavigationList }}>
              <LoginComponent isMobile />
            </List>
               */}
					</Drawer>
				</Grid>

				<Grid item className={classes.imageWrapper} xs={5}>
					<a href='/'>
						<img className={classes.logo} src={Logo} alt='locumtenens logo' />
					</a>
				</Grid>

				<Grid item className={classes.buttonWrapper} xs={6}>
					<span>{this.renderSupportNumber()}</span>
					{!isAdminApp && !isClientApp && (
						<span>
							<Notifications refreshNotifications = {refreshNotifications} device='mobile' />
						</span>
					)}
					<span>
						<ProfileNavComponent profileNavigation={account ? profileNavigation : []} />
					</span>
				</Grid>
			</Grid>
		);
	}
}

MobileNavbar.propTypes = {
	classes: PropTypes.shape({
		gridWrapper: PropTypes.string.isRequired,
		imageWrapper: PropTypes.string.isRequired,
		drawerPaper: PropTypes.string.isRequired,
		toggleIcon: PropTypes.string.isRequired,
		mobileNavigation: PropTypes.string.isRequired,
		icon: PropTypes.string.isRequired,
		logo: PropTypes.string.isRequired,
		logoutNavigationList: PropTypes.string.isRequired,
		logoutNavigationItemText: PropTypes.string.isRequired,
	}).isRequired,
	menuNavigation: menuNavigationProps.isRequired,
};
MobileNavbar.contextType = UserContext;
export default withTranslation()(withStyles(styles)(MobileNavbar));
