import React from 'react';
import { Paper } from '@material-ui/core';
import { COLORS } from '../../../../../utils/Application_Constants';
import { Grid, makeStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { NavLink } from 'react-router-dom';

const styles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
	},
	root: {
		padding: '20px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		maxWidth: '100%',
		//backgroundColor: COLORS.BLACK_HAZE,
	},
	ContainerRoot: {
		[theme.breakpoints.up('md')]: {
			//    paddingLeft: 70,
			//    paddingRight: 70
		},
	},
	helpPanelContainerRoot: {
		//padding: '30px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	linkHeader: {
		color: COLORS.DENIM,
		textDecoration: 'none',
		display: 'flex',
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		justifyContent: 'center',
	},
	header: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '700',
		fontSize: '24px',
	},
	info: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '16px',
		textAlign: 'center',
		marginBottom: '10px',
	},
	checkStatusIcon: {
		color: COLORS.DENIM,
		fontSize: '22px',
		marginLeft: '4px',
		marginTop: '3px;',
	},
	sectionStyle: {
		color: COLORS.DENIM,
		fontWeight: '700',
		display: 'flex',
		textAlign: 'center',
	},
	textLink: {
		color: COLORS.RICH_ELECTRIC_BLUE,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'center',
	},
	topTileRoot: {
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		paddingTop: '30px',
	},
}));

function DocumentsTile({ t }) {
	const classes = styles();

	return (
		<div>
			<Paper className={classes.paper}>
				<Grid container>
					<Grid item xs={12}>
						<NavLink to={'/credentials/documents'}>
							<h4 className='cr-journey'>
								{t('credentials:CRED_OVERVIEW.DOCUMENTS')}
								<LaunchIcon className={classes.checkStatusIcon} />
							</h4>
						</NavLink>

						<p>
							{t(`credentials:CRED_OVERVIEW.DOCUMENTS_INFO`)}
							<NavLink className='textLink cr-journey' to={'/credentials/documents'}>
								{' '}
								here
							</NavLink>
							.
						</p>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
}

export default DocumentsTile;
