import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { textFieldStyles } from '../../Configurations/ExpenseEntryConfig';

const styles = makeStyles((theme) => textFieldStyles(theme));

const ExpenseTextField = (props) => {
	const classes = styles();
	const { startAdornment, endAdornment } = props;
	const excludedProps = ['startAdornment', 'endAdornment'];
	const includedProps = Object.keys(props).reduce((result, key) => {
		if (!excludedProps.includes(key)) {
			result[key] = props[key];
		}
		return result;
	}, {});

	return (
		<TextField
			classes={{ root: classes.root }}
			InputProps={{
				classes: {
					root: classes.inputRoot,
					input: classes.input,
					focused: classes.focused,
					multiline: classes.multiline,
					error: classes.error,
					disabled: classes.disabled,
					cursor: classes.cursor,
				},
				disableUnderline: true,
				startAdornment: startAdornment && startAdornment,
				endAdornment: endAdornment && endAdornment,
			}}
			InputLabelProps={{
				shrink: true,
				classes: {
					asterisk: classes.inputLabelAsterisk,
				},
			}}
			SelectProps={{
				classes: { icon: classes.selectIcon },
				IconComponent: ExpandMore,
				displayEmpty: true,
				MenuProps: { MenuListProps: { id: props.id && `${props.id}-menu` } },
			}}
			{...includedProps}
		>
			{props.select && props.children}
		</TextField>
	);
};

export default ExpenseTextField;
