import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { CircularProgress, ThemeProvider, NativeSelect } from '@material-ui/core';
import TextFieldComponent from '../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import {
	ACTIVE_PATHNAME_MD,
	ACTIVE_PATHNAME_AP,
	SEGMENT_NURSING,
	SEGMENT_PA,
	SEGMENT_ALLIED,
	SEGMENT_SFA,
	COLORS,
	PHYSICIAN_SPECIALTY_BOARD,
	NURSING_AP_SPECIALTY_BOARD,
	NP_ISSUING_BOARD,
	CNS_ISSUING_BOARD,
	CNM_ISSUING_BOARD,
	CRNA_ISSUING_BOARD,
	NURSING_CERTIFICATION_PICKLIST,
	NP_CERTIFICATION_PICKLIST,
	CNS_CERTIFICATION_PICKLIST,
	CNM_CERTIFICATION_PICKLIST,
	CRNA_CERTIFACTION_PICKLIST,
	CRED_STATE,
	DNP_PhD_SFA_RN_ISSUING_BOARD,
	DNAP_ISSUING_BOARD,
	SEGMENT_PERFUSIONIST,
} from '../../../../../utils/Application_Constants';
import createTheme from '@material-ui/core/styles/createTheme';
import DatePickerComponent from '../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	getBoardCertifications,
	postBoardCertifications,
	deleteOtherCertifications,
} from '../../../../../services/Credentialing';
import Input from '@material-ui/core/Input';
import DateFnsUtils from '@date-io/date-fns';
import { convertToDateIgnoringTime, matchesTemplate } from '../../../../../utils/helpers';
import {
	boardCertificationsStatusPointsCheck,
	boardCertificationsStatusCheck,
} from '../../../../../utils/CredentialingAppUtility';
import Alert from '@material-ui/lab/Alert';
import RequiredQuestionDialog from '../../../../GlobalComponents/Dialog/RequiredQuestionDialog';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '10px',
		paddingBottom: '5px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
	},
	secondaryHeaderArea: {
		alignItems: 'center',
		maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '46px',
		marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	form: {
		width: '100%',
		margin: theme.spacing(1, 0, 5),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	// inputLabel: {
	//     top: '-5px',
	//     position: 'relative',
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	//     marginTop: theme.spacing(-1),
	// },
	// checkboxInputLabel: {
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	// },
	// selectInputLabel: {
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	// },
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: `${COLORS.RAVEN} `,
			borderWidth: 1,
		},
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		paddingRight: '0px',
		paddingTop: '5px',
		paddingBottom: '5px',
		paddingLeft: '0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
	},
	cssFocused: {},

	disabled: {},
	focused: {},
	marginLeft12: {
		marginLeft: 12,
	},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	noTopPad: {
		paddingTop: '0 !important',
	},
	tabNoTopPad: {
		paddingTop: '0px !important',
		paddingLeft: '30px !important',
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	radioGroup: {
		marginLeft: '8px',
	},
	checkbox: {
		marginTop: '10px',
		marginBottom: '-10px',
	},
	inputRadio: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
	},
	setWidthSelectGroup: {
		top: '-23px',
		width: 'calc(100% - 1px)',
		marginTop: '10px',
		marginRight: '10px',
	},
	setWidthSelects: {
		minWidth: '100%',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	selectContainerOffset: {
		padding: '0 16px 16px 16px !important',
	},
	selectContainer: {
		padding: '3px !important',
	},
	selectContainerGroup: {
		[theme.breakpoints.down('sm')]: {
			padding: '3px 10px 0px 10px !important',
		},
		minWidth: '97%',
	},
	multiGridOffset: {
		paddingTop: '13px !important',
	},
	addMoreArea: {
		color: COLORS.TRUE_BLUE,
		backgroundColor: COLORS.CONCRETE,
		paddingTop: '8px',
		paddingBottom: '8px',
		textAlign: 'center',
		cursor: 'pointer',
		width: '100%',
		height: '35px',
	},
	leftPad30: {
		paddingLeft: '30px !important',
	},
	containerSM: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% + 13px) !important',
		},
	},
	marginBottom20Neg: {
		marginBottom: -20,
	},
	hideDeleteBtn: {
		display: 'none',
	},
	disabledInput: {
		opacity: 0.6,
	},
	normalInput: {
		opacity: 1,
	},
	disabledSelect: {
		opacity: 0.6,
		cursor: 'default',
	},
	credAppFirstHeader: {
		padding: '20px 0px',
		borderTop: '1px solid #e7e7e7',
		borderBottom: '1px solid #e7e7e7',
		margin: '30px',
		marginTop: '-15px',
	},
	infoMessage: {
		color: COLORS.RAVEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '14px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '10px !important',
		paddingBottom: '15px',
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
}));

function BoardCertificationsFormAp({
	t,
	setSaveHandler,
	blockedBackNavigation,
	activePath,
	credState,
	segment,
	setCredAppError,
	titles,
	UserContext,
	flags,
	updateFlags,
	type,
	constantType,
	apiType,
	sectionCompletion,
	updateSectionCompletion,
	shouldHighlightFields,
	updateHighlightFieldsState,
}) {
	const normalTheme = createTheme({
		overrides: {
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					// height: '18px',
					// borderRadius: '1px !important',
					// "&$disabled": {
					//     opacity: '0.6'
					// }
				},
			},
			MuiFormControlLabel: {
				root: {
					'&$disabled': {
						opacity: '0.6',
					},
				},
			},
		},
	});
	const errorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '17px',
					borderRadius: '1px !important',
					border: '2px solid red !important',
				},
			},
		},
	});

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [isLoaded, setIsLoaded] = useState(false);
	const [isRequired, setIsRequired] = useState(false);

	const [issuingBoardPicklist, setIssuingBoardPicklist] = useState([{}]);

	const [boardCertified, setBoardCertified] = useState(null);

	const [certificationTypePicklist, setCertificationTypePicklist] = useState([{}]);

	const [deleteRecordIndex, setDeleteRecordIndex] = useState();
	const [deleteRecordId, setDeleteRecordId] = useState();
	const [deleteRecords, setDeleteRecords] = useState([]);

	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);

	const itemTemplate = {
		id: null,
		specialty: '',
		boardStatus: '',
		passedWrittenExam: '',
		issuingBoard: '',
		certificationNumber: '',
		certificationType: '',
		lifetimeCertified: false,
		issueDate: null,
		expirationDate: null,
		isVerified: false,
		explainOther: '',
	};

	const {
		register,
		control,
		handleSubmit,
		errors,
		reset,
		formState,
		watch,
		trigger,
		unregister,
		getValues,
	} = useForm({
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			id: null,
			specialty: '',
			boardStatus: '',
			passedWrittenExam: '',
			issuingBoard: '',
			certificationNumber: '',
			certificationType: '',
			lifetimeCertified: false,
			issueDate: null,
			expirationDate: null,
			isVerified: false,
			explainOther: '',
		},
	});
	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			await getBoardCertifications(externalId, apiType)
				.then(function (data) {
					//const { providerSpecialties = [] } = data;
					console.log('response is', data);

					setSaveHandler(
						BoardCertificationsFormAp,
						'Board Certifications',
						handleSubmit(async (data) => await onSubmit(data))
					);

					data['providerSpecialties'] = data['providerSpecialties'].filter(
						(item) =>
							![
								'Burn Surgery',
								'Hospitalist',
								'Hospice and Palliative Care',
								'Urgent Care',
								'Urogynecology',
								'Women\u{2019}s Imaging',
								'Wound Care',
							].includes(item.specialty)
					);

					if (data['providerSpecialties'].length === 0) {
						append({
							id: null,
							specialty: '',
							boardStatus: '',
							passedWrittenExam: '',
							issuingBoard: '',
							certificationNumber: '',
							certificationType: '',
							lifetimeCertified: false,
							issueDate: null,
							expirationDate: null,
							isVerified: false,
							explainOther: '',
						});
						data['providerSpecialties'].push({
							id: null,
							specialty: '',
							boardStatus: '',
							passedWrittenExam: '',
							issuingBoard: '',
							certificationNumber: '',
							certificationType: '',
							lifetimeCertified: false,
							issueDate: null,
							expirationDate: null,
							isVerified: false,
							explainOther: '',
						});
					} else {
						data['providerSpecialties'].map((item) => {
							if (item.specialty === null) {
								item.specialty = '';
							}
							if (item.boardStatus === null) {
								item.boardStatus = '';
							}
							if (item.passedWrittenExam === null) {
								item.passedWrittenExam = '';
							}
							if (item.issuingBoard === null) {
								item.issuingBoard = '';
							}
							if (item.certificationNumber === null) {
								item.certificationNumber = '';
							}
							if (item.certificationType === null) {
								item.certificationType = '';
							}
							if (item.explainOther === null) {
								item.explainOther = '';
							}

							//TDS-3251 temparory disable check
							item.isVerified = false;

							return item;
						});
					}

					setIssuingBoardPicklist(getIssuingBoardPicklist());
					setCertificationTypePicklist(getCertificationTypePicklist());
					setBoardCertified(data['boardCertified']);
					flags.dataSaved = false;
					updateFlags(flags);
					reset(data);
					setIsLoaded(true);

					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status = {
							status: 'incomplete',
							missingFields: [],
						};

						status = boardCertificationsStatusCheck(
							data['providerSpecialties'],
							activePath,
							segment,
							data['boardCertified']
						);

						if (!!status.missingFields) {
							setHighlights(status.missingFields);
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);
	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'providerSpecialties',
		keyName: 'internalId',
	});

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
			setIsRequired(flags.isDirty);
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('providerSpecialties')) {
			for (let i = 0; i < errors.providerSpecialties.length; i++) {
				if (!!errors?.providerSpecialties[i]) {
					//This would be 'return anError.type !== 'manual'', but manually set errors return a malformed error object with no 'type' field (the contents of the type field are inserted as an array of chars on the main object)
					missingRequiredToSaveFields = Object.values(
						errors.providerSpecialties[i]
					).filter((anError) => {
						return anError;
					});
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	const classes = styles();

	async function onSubmit(data) {
		let success = false;

		const { externalId } = UserContext;
		data['clinicianId'] = externalId;

		if (!!data['providerSpecialties']) {
			data['providerSpecialties'] = data['providerSpecialties']
				.map((item, index) => {
					if (item.id === 'null' || item.id === null || item.id === 'null') {
						item.id = null;
					}
					if (
						item.issueDate === 'null' ||
						item.issueDate === null ||
						item.issueDate === undefined
					) {
						unregister(`providerSpecialties[${index}].issueDate`);
					} else {
						item.issueDate = moment(item.issueDate).toISOString(true);
					}
					if (
						item.expirationDate === 'null' ||
						item.expirationDate === null ||
						item.expirationDate === undefined
					) {
						unregister(`providerSpecialties[${index}].expirationDate`);
					} else {
						item.expirationDate = moment(item.expirationDate).toISOString(true);
					}

					if (item.lifetimeCertified === true) {
						item.expirationDate = null;
					}
					if (item.issuingBoard !== 'Other') {
						item.explainOther = '';
					}

					if (activePath === ACTIVE_PATHNAME_AP) {
						item.lifetimeCertified = false;
					}

					if (item.boardStatus === 'Non Board Certified' || item.boardStatus === 'No') {
						item.passedWrittenExam = '';
						item.issuingBoard = '';
						item.certificationNumber = '';
						item.certificationType = '';
						item.lifetimeCertified = false;
						item.issueDate = null;
						item.expirationDate = null;
					} else if (item.boardStatus === 'Board Eligible') {
						item.issuingBoard = '';
						item.certificationNumber = '';
						item.certificationType = '';
						item.lifetimeCertified = false;
						item.issueDate = null;
						item.expirationDate = null;
					} else {
						item.passedWrittenExam = '';
					}

					if (matchesTemplate(item, itemTemplate)) {
						return null;
					}

					return item;
				})
				.filter((d) => d !== null);
		}
		if (deleteRecords.length > 0) {
			const deleteRecordsNumbers = deleteRecords.filter(function (element) {
				return element !== 'null';
			});

			await deleteOtherCertifications(externalId, deleteRecordsNumbers).catch((e) => {
				setCredAppError(e);
			});
		}

		console.log('before json', data);
		const jsonData = JSON.stringify(data);
		console.log('post payload is:', jsonData);
		await postBoardCertifications(externalId, jsonData, apiType);
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;

		sectionCompletion.certifications.boardCertifications.current =
			await boardCertificationsStatusPointsCheck(
				data['providerSpecialties'],
				activePath,
				sectionCompletion,
				segment,
				data['boardCertified']
			);
		updateSectionCompletion(sectionCompletion);

		updateFlags(flags);
		return success;
	}

	function updateHighlight() {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};

			status = boardCertificationsStatusCheck(
				watch('providerSpecialties'),
				activePath,
				segment,
				watch('boardCertified')
			);

			// alert(`missingFields: ${status.missingFields}`)
			setHighlights(status.missingFields);
		}
	}

	const deleteEntry = (btnPressed, index) => {
		if (btnPressed === 'Yes') {
			if (deleteRecordId !== null) {
				deleteRecords.push(deleteRecordId);
				setDeleteRecords(deleteRecords);
				setShowDeleteModal(false);
			}
			remove(deleteRecordIndex);
			setShowDeleteModal(false);
		}
		setShowDeleteModal(false);
	};

	const openDeleteConfirmation = (item, index) => {
		setDeleteRecordIndex(index);
		setDeleteRecordId(item.id);
		setShowDeleteModal(true);
	};

	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	const conditionalDateValidation = (date, index) => {
		if (getValues(`providerSpecialties[${index}].lifetimeCertified`) !== true) {
			return !isNaN(new Date(date).getTime()) === true;
		} else {
			return true;
		}
	};

	const getBoardStatusPicklist = (index) => {
		if (activePath === ACTIVE_PATHNAME_MD) {
			if (index === 0) {
				return (
					<>
						<option key={'Board Certified'} value={'Board Certified'}>
							Board Certified
						</option>
						<option key={'Board Eligible'} value={'Board Eligible'}>
							Board Eligible
						</option>
						<option key={'Non Board Certified'} value={'Non Board Certified'}>
							Non Board Certified
						</option>
						<option key={'Expired/Inactive'} value={'Expired/Inactive'}>
							Expired/Inactive
						</option>
					</>
				);
			} else {
				return (
					<>
						<option key={'Board Certified'} value={'Board Certified'}>
							Board Certified
						</option>
					</>
				);
			}
		} else if (activePath === ACTIVE_PATHNAME_AP) {
			if (index === 0) {
				return (
					<>
						<option key={'Yes'} value={'Yes'}>
							Yes
						</option>
						<option key={'No'} value={'No'}>
							No
						</option>
					</>
				);
			} else {
				return (
					<>
						<option key={'Yes'} value={'Yes'}>
							Yes
						</option>
					</>
				);
			}
		}
	};

	const getIssuingBoardPicklist = () => {
		let unfilteredOutput = [];
		if (activePath === ACTIVE_PATHNAME_AP) {
			if (segment === SEGMENT_NURSING) {
				if (titles.includes('NP')) {
					unfilteredOutput = [...unfilteredOutput, ...NP_ISSUING_BOARD];
				}
				if (titles.includes('CNS')) {
					unfilteredOutput = [...unfilteredOutput, ...CNS_ISSUING_BOARD];
				}
				if (titles.includes('CNM')) {
					unfilteredOutput = [...unfilteredOutput, ...CNM_ISSUING_BOARD];
				}
				if (titles.includes('CRNA')) {
					unfilteredOutput = [...unfilteredOutput, ...CRNA_ISSUING_BOARD];
				}
				if (titles.includes('DNAP')) {
					unfilteredOutput = [...unfilteredOutput, ...DNAP_ISSUING_BOARD];
				}
			} else if (
				segment === SEGMENT_PA ||
				segment === SEGMENT_SFA ||
				segment === SEGMENT_ALLIED ||
				segment === SEGMENT_PERFUSIONIST
			) {
				unfilteredOutput = [...unfilteredOutput, ...NURSING_AP_SPECIALTY_BOARD];
			}
			if (
				titles.includes('DNP') ||
				titles.includes('PhD') ||
				titles.includes('SFA') ||
				titles.includes('RN')
			) {
				unfilteredOutput = [...unfilteredOutput, ...DNP_PhD_SFA_RN_ISSUING_BOARD];
			}
		} else if (activePath === ACTIVE_PATHNAME_MD) {
			unfilteredOutput = [...unfilteredOutput, ...PHYSICIAN_SPECIALTY_BOARD];
		}

		let jsonOutput = unfilteredOutput.map(JSON.stringify);
		let uniqueSet = new Set(jsonOutput);
		let output = Array.from(uniqueSet).map(JSON.parse);
		return output;
	};

	const getCertificationTypePicklist = () => {
		let unfilteredOutput = [];
		if (activePath === ACTIVE_PATHNAME_AP) {
			if (segment === SEGMENT_NURSING) {
				if (titles.includes('NP')) {
					unfilteredOutput = [...unfilteredOutput, ...NP_CERTIFICATION_PICKLIST];
				}
				if (titles.includes('CNS')) {
					unfilteredOutput = [...unfilteredOutput, ...CNS_CERTIFICATION_PICKLIST];
				}
				if (titles.includes('CNM')) {
					unfilteredOutput = [...unfilteredOutput, ...CNM_CERTIFICATION_PICKLIST];
				}
				if (titles.includes('CRNA') || titles.includes('DNAP')) {
					unfilteredOutput = [...unfilteredOutput, ...CRNA_CERTIFACTION_PICKLIST];
				}
			} else if (
				segment === SEGMENT_PA ||
				segment === SEGMENT_SFA ||
				segment === SEGMENT_ALLIED ||
				segment === SEGMENT_PERFUSIONIST
			) {
				unfilteredOutput = [...unfilteredOutput, ...NURSING_CERTIFICATION_PICKLIST];
			}
		} else if (activePath === ACTIVE_PATHNAME_MD) {
		}

		let jsonOutput = unfilteredOutput.map(JSON.stringify);
		let uniqueSet = new Set(jsonOutput);
		let output = Array.from(uniqueSet).map(JSON.parse);
		return output;
	};

	const renderForm = (item, index) => {
		const lockFields =
			!!item.id &&
			((!!item.isVerified && credState === CRED_STATE.OPEN) ||
				(!!item.isVerified &&
					CRED_STATE.PARTIALLY_OPEN &&
					activePath === ACTIVE_PATHNAME_AP) ||
				(credState === CRED_STATE.PARTIALLY_OPEN && activePath === ACTIVE_PATHNAME_MD) ||
				credState === CRED_STATE.CLOSED);
		return (
			<div className={classes.profileWrapper}>
				<Grid container>
					<Grid
						container
						className={
							index === 0 ? classes.credAppFirstHeader : 'credAppAdditionalHeader'
						}
					>
						<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
							{index === 0
								? 'Board Certification'
								: t(`credentials:${constantType}.ADDITIONAL_CERTIFICATION`)}
						</Grid>
						<Grid
							item
							xs={3}
							className={
								item.isVerified || credState === CRED_STATE.CLOSED
									? classes.hideDeleteBtn
									: 'credAppAdditionalDelete'
							}
							onClick={() => {
								openDeleteConfirmation(item, index);
							}}
						>
							{t(`credentials:${constantType}.DELETE_BTN`)}
						</Grid>
					</Grid>
				</Grid>
				<Container component='main' maxWidth='md'>
					<CssBaseline />
					<div>
						<Grid container item md={12} className={classes.containerSM}>
							<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
								<RouteDeparturePrompt
									blockedBackNavigation={blockedBackNavigation}
									when={
										!flags.dataSaved
											? !!Object.keys(formState.dirtyFields).length
											: false
									}
								/>
								<FormControl style={{ display: 'none' }}>
									<Input
										autoComplete='off'
										variant='outlined'
										style={{ display: 'none' }}
										name={`providerSpecialties[${index}].id`}
										fullWidth
										defaultValue={`${item.id}` || null}
										inputRef={register()}
									/>
								</FormControl>

								<Grid container>
									{watch(`boardCertified`) === 'Yes' ? (
										<Grid item xs={12} className={classes.tabNoTopPad}>
											<FormControl
												classes={{ root: classes.setWidthTo100OnMobile }}
											>
												<InputLabel
													shrink
													required={
														segment === SEGMENT_NURSING ||
														activePath === ACTIVE_PATHNAME_MD
													}
													htmlFor={`providerSpecialties[${index}].issuingBoard`}
													classes={{ root: classes.selectInputLabel }}
													className={
														((errors?.providerSpecialties &&
															errors?.providerSpecialties[`${index}`]
																?.issuingBoard) ||
															(!!highlights &&
																highlights.includes(
																	`providerSpecialties[${index}].issuingBoard`
																))) &&
														classes.errorColor
													}
												>
													{t(`credentials:${constantType}.ISSUING_BOARD`)}
												</InputLabel>
												<ThemeProvider
													theme={
														(errors?.providerSpecialties &&
															errors?.providerSpecialties[`${index}`]
																?.issuingBoard) ||
														(!!highlights &&
															highlights.includes(
																`providerSpecialties[${index}].issuingBoard`
															))
															? errorTheme
															: normalTheme
													}
												>
													<NativeSelect
														defaultValue={`${item.issuingBoard}`}
														inputRef={register({
															required:
																watch(`boardCertified`) === 'Yes',
														})}
														fullWidth
														name={`providerSpecialties[${index}].issuingBoard`}
														className={
															lockFields
																? classes.disabledSelect
																: classes.normalInput
														}
														onBlur={() => {
															updateHighlight();
														}}
														onChange={(event) => {
															let fieldsToTrigger = [];

															if (event.target.value === `Other`) {
																fieldsToTrigger.push(
																	`providerSpecialties[${index}].explainOther`
																);
															}

															if (fieldsToTrigger.length > 0) {
																setTimeout(() => {
																	trigger(fieldsToTrigger);
																}, 100);
															}
														}}
														disabled={lockFields}
														input={
															<TextFieldComponent
																style={{
																	border: `0px solid ${COLORS.RAVEN}`,
																}}
																classes={{
																	input: classes.setWidthTo100OnMobile,
																}}
															/>
														}
													>
														<option key={''} value={null}></option>
														{issuingBoardPicklist.map((item) => (
															<option
																key={item.value}
																value={item.value}
															>
																{item.label}
															</option>
														))}
														<optgroup label=''></optgroup>
													</NativeSelect>
												</ThemeProvider>
												{((errors?.providerSpecialties &&
													errors?.providerSpecialties[`${index}`]
														?.issuingBoard) ||
													(!!highlights &&
														highlights.includes(
															`providerSpecialties[${index}].issuingBoard`
														))) && (
													<span className={classes.cssLabel}>
														{t(
															'credentials:VALIDATION_MESSAGE.ISSUING_BOARD'
														)}
													</span>
												)}
											</FormControl>
											{lockFields ? (
												<Input
													style={{ display: 'none' }}
													name={`providerSpecialties[${index}].issuingBoard`}
													value={`${item.issuingBoard}`}
													inputRef={register()}
												/>
											) : null}
										</Grid>
									) : null}

									{watch(`providerSpecialties[${index}].issuingBoard`) ===
										'Other' && watch(`boardCertified`) === 'Yes' ? (
										<Grid item xs={12} classes={{ root: classes.tabNoTopPad }}>
											<FormControl
												classes={{ root: classes.setWidthTo100OnMobile }}
											>
												<InputLabel
													shrink
													htmlFor={`providerSpecialties[${index}].explainOther`}
													classes={{ root: classes.selectInputLabel }}
													required
													error={
														(errors?.providerSpecialties &&
															errors?.providerSpecialties[`${index}`]
																?.explainOther) ||
														(!!highlights &&
															highlights.includes(
																`providerSpecialties[${index}].explainOther`
															))
													}
												>
													{t(`credentials:${constantType}.EXPLAIN_OTHER`)}
												</InputLabel>
												<TextField
													id={`providerSpecialties[${index}].explainOther`}
													name={`providerSpecialties[${index}].explainOther`}
													className={classes.textField}
													margin='normal'
													variant='outlined'
													disabled={
														!!item.id && credState === CRED_STATE.CLOSED
													}
													onBlur={() => {
														updateHighlight();
													}}
													defaultValue={`${item.explainOther}`}
													error={
														(errors?.providerSpecialties &&
															errors?.providerSpecialties[`${index}`]
																?.explainOther) ||
														(!!highlights &&
															highlights.includes(
																`providerSpecialties[${index}].explainOther`
															))
													}
													inputRef={register({
														required:
															watch(
																`providerSpecialties[${index}].issuingBoard`
															) === 'Other',
														maxLength: {
															value: 255,
															message: t(
																'common:MESSAGES.CHARACTER_LIMIT'
															),
														},
													})}
													autoComplete='off'
													InputProps={{
														classes: {
															root: classes.cssOutlinedInput,
															focused: classes.cssFocused,
															notchedOutline: classes.notchedOutline,
															error: classes.error,
														},
													}}
												/>
												{((errors?.providerSpecialties &&
													errors?.providerSpecialties[`${index}`]
														?.explainOther &&
													errors?.providerSpecialties[`${index}`]
														?.explainOther['type'] === 'maxLength') ||
													(!!highlights &&
														highlights.includes(
															`providerSpecialties[${index}].explainOther`
														))) && (
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{
															errors?.providerSpecialties[`${index}`]
																?.explainOther.message
														}
													</span>
												)}
												{((errors?.providerSpecialties &&
													errors?.providerSpecialties[`${index}`]
														?.explainOther &&
													errors?.providerSpecialties[`${index}`]
														?.explainOther['type'] !== 'maxLength') ||
													(!!highlights &&
														highlights.includes(
															`providerSpecialties[${index}].explainOther`
														))) && (
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t(
															'credentials:VALIDATION_MESSAGE.EXPLAIN_OTHER'
														)}
													</span>
												)}
											</FormControl>
										</Grid>
									) : null}

									{watch(`boardCertified`) === 'Yes' &&
									segment === SEGMENT_NURSING ? (
										<Grid item xs={12} className={classes.tabNoTopPad}>
											<FormControl
												classes={{ root: classes.setWidthTo100OnMobile }}
											>
												<InputLabel
													shrink
													htmlFor={`providerSpecialties[${index}].certificationType`}
													classes={{ root: classes.selectInputLabel }}
													className={
														((errors?.providerSpecialties &&
															errors?.providerSpecialties[`${index}`]
																?.certificationType) ||
															(!!highlights &&
																highlights.includes(
																	`providerSpecialties[${index}].certificationType`
																))) &&
														classes.errorColor
													}
												>
													{t(
														`credentials:${constantType}.CERTIFICATION_TYPE`
													)}
												</InputLabel>
												<ThemeProvider
													theme={
														(errors?.providerSpecialties &&
															errors?.providerSpecialties[`${index}`]
																?.certificationType) ||
														(!!highlights &&
															highlights.includes(
																`providerSpecialties[${index}].certificationType`
															))
															? errorTheme
															: normalTheme
													}
												>
													<NativeSelect
														defaultValue={`${item.certificationType}`}
														inputRef={register()}
														fullWidth
														name={`providerSpecialties[${index}].certificationType`}
														className={
															lockFields
																? classes.disabledSelect
																: classes.normalInput
														}
														onBlur={() => {
															updateHighlight();
														}}
														disabled={lockFields}
														input={
															<TextFieldComponent
																style={{
																	border: `0px solid ${COLORS.RAVEN}`,
																}}
																classes={{
																	input: classes.setWidthTo100OnMobile,
																}}
															/>
														}
													>
														<option key={''} value={null}></option>
														{certificationTypePicklist.map((item) => (
															<option
																key={item.value}
																value={item.value}
															>
																{item.label}
															</option>
														))}
													</NativeSelect>
												</ThemeProvider>
												{((errors?.providerSpecialties &&
													errors?.providerSpecialties[`${index}`]
														?.certificationType) ||
													(!!highlights &&
														highlights.includes(
															`providerSpecialties[${index}].certificationType`
														))) && (
													<span className={classes.cssLabel}>
														{t(
															'credentials:VALIDATION_MESSAGE.CERTIFICATION_TYPE'
														)}
													</span>
												)}
											</FormControl>
											{lockFields ? (
												<Input
													style={{ display: 'none' }}
													name={`providerSpecialties[${index}].certificationType`}
													value={`${item.certificationType}`}
													inputRef={register()}
												/>
											) : null}
										</Grid>
									) : null}

									{watch(`boardCertified`) === 'Yes' ? (
										<Grid item xs={12} className={classes.tabNoTopPad}>
											<InputLabel
												shrink
												htmlFor={`providerSpecialties[${index}].certificationNumber`}
												className={classes.selectInputLabel}
												error={
													(errors?.providerSpecialties &&
														errors?.providerSpecialties[`${index}`]
															?.certificationNumber) ||
													(!!highlights &&
														highlights.includes(
															`providerSpecialties[${index}].certificationNumber`
														))
												}
											>
												{t(
													`credentials:${constantType}.CERTIFICATION_NUMBER`
												)}
											</InputLabel>
											<TextField
												autoComplete='off'
												variant='outlined'
												fullWidth
												defaultValue={`${item.certificationNumber}`}
												onBlur={() => {
													updateHighlight();
												}}
												name={`providerSpecialties[${index}].certificationNumber`}
												inputRef={register({
													maxLength: 20,
													message: t('common:MESSAGES.CHARACTER_LIMIT'),
												})}
												helperText={
													(errors?.providerSpecialties &&
														errors?.providerSpecialties[`${index}`]
															?.certificationNumber &&
														errors?.providerSpecialties[`${index}`]
															?.certificationNumber['type'] ===
															'maxLength') ||
													(!!highlights &&
														highlights.includes(
															`providerSpecialties[${index}].certificationNumber`
														))
														? t('common:MESSAGES.CHARACTER_LIMIT')
														: null
												}
												className={
													credState === CRED_STATE.PARTIALLY_OPEN ||
													credState === CRED_STATE.CLOSED
														? classes.disabledInput
														: classes.normalInput
												}
												error={
													(errors?.providerSpecialties &&
														errors?.providerSpecialties[`${index}`]
															?.certificationNumber) ||
													(!!highlights &&
														highlights.includes(
															`providerSpecialties[${index}].certificationNumber`
														))
												}
												InputProps={{
													readOnly:
														credState === CRED_STATE.PARTIALLY_OPEN ||
														credState === CRED_STATE.CLOSED,
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
											/>
										</Grid>
									) : null}

									{watch(`boardCertified`) === 'Yes' ? (
										<Grid item xs={12} md={6} className={classes.tabNoTopPad}>
											<FormControl
												classes={{ root: classes.setWidthTo100OnMobile }}
											>
												<InputLabel
													shrink
													htmlFor={`providerSpecialties[${index}].issueDate`}
													classes={{ root: classes.selectInputLabel }}
													//required={watch(`boardCertified`) === 'Board Certified'}
													className={
														((errors?.providerSpecialties &&
															errors?.providerSpecialties[`${index}`]
																?.issueDate) ||
															(!!highlights &&
																highlights.includes(
																	`providerSpecialties[${index}].issueDate`
																))) &&
														classes.errorColor
													}
												>
													{t(`credentials:${constantType}.ISSUE_DATE`)}
												</InputLabel>
												<ThemeProvider theme={datePickerTheme}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<Controller
															control={control}
															rules={{
																validate: (date) =>
																	dateValidation(date),
															}}
															name={`providerSpecialties[${index}].issueDate`}
															defaultValue={
																`${item.issueDate}` === 'null'
																	? null
																	: convertToDateIgnoringTime(
																			`${item.issueDate}`
																	  )
															}
															render={({ onChange, value, name }) => (
																<DatePickerComponent
																	onChange={onChange}
																	onClose={() => {
																		updateHighlight();
																	}}
																	name={name}
																	onBlur={() => {
																		updateHighlight();
																	}}
																	value={value}
																	format='MM/dd/yyyy'
																	placeholder='MM/DD/YYYY'
																	clearable
																	disabled={lockFields}
																	variant='inline'
																	autoOk='true'
																	minDateMessage={
																		<span
																			className={`${classes.cssLabel} ${classes.marginLeft12}`}
																		>
																			{t(
																				`credentials:VALIDATION_MESSSAGE.DATE`
																			)}
																		</span>
																	}
																	maxDateMessage={
																		<span
																			className={`${classes.cssLabel} ${classes.marginLeft12}`}
																		>
																			{t(
																				`credentials:VALIDATION_MESSSAGE.DATE`
																			)}
																		</span>
																	}
																	invalidDateMessage={``}
																	margin='normal'
																	theme={datePickerTheme}
																	error={
																		(errors?.providerSpecialties &&
																			errors
																				?.providerSpecialties[
																				`${index}`
																			]?.issueDate) ||
																		(!!highlights &&
																			highlights.includes(
																				`providerSpecialties[${index}].issueDate`
																			))
																	}
																	InputProps={{
																		classes: {
																			disabled:
																				classes.disabledInput,
																			root: classes.cssOutlinedInput,
																		},
																	}}
																	KeyboardButtonProps={{
																		'aria-label': t(
																			'credentials:OTHER_CERTIFICATIONS.EXPIRATION'
																		),
																	}}
																	inputVariant='outlined'
																/>
															)}
														/>
													</MuiPickersUtilsProvider>
												</ThemeProvider>
											</FormControl>
											{((errors?.providerSpecialties &&
												errors?.providerSpecialties[`${index}`]
													?.issueDate) ||
												(!!highlights &&
													highlights.includes(
														`providerSpecialties[${index}].issueDate`
													))) && (
												<span
													className={`${classes.cssLabel} ${classes.marginLeft12}`}
												>
													{t(`credentials:VALIDATION_MESSAGE.DATE`)}
												</span>
											)}
										</Grid>
									) : null}

									<Grid
										item
										xs={12}
										md={6}
										className={classes.tabNoTopPad}
										style={{
											display:
												watch(`boardCertified`) === 'Yes' &&
												(watch(
													`providerSpecialties[${index}].lifetimeCertified`
												) === false ||
													watch(
														`providerSpecialties[${index}].lifetimeCertified`
													) === undefined ||
													watch(
														`providerSpecialties[${index}].lifetimeCertified`
													) === null)
													? 'block'
													: 'none',
										}}
									>
										<FormControl
											classes={{ root: classes.setWidthTo100OnMobile }}
										>
											<InputLabel
												shrink
												htmlFor={`providerSpecialties[${index}].expirationDate`}
												// required={(
												//     (watch(`boardCertified`) === 'Board Certified')
												//     &&
												//     (watch(`providerSpecialties[${index}].lifetimeCertified`) === false || watch(`providerSpecialties[${index}].lifetimeCertified`) === undefined || watch(`providerSpecialties[${index}].lifetimeCertified`) === null)
												// )}
												classes={{ root: classes.selectInputLabel }}
												className={
													((errors?.providerSpecialties &&
														errors?.providerSpecialties[`${index}`]
															?.expirationDate) ||
														(!!highlights &&
															highlights.includes(
																`providerSpecialties[${index}].expirationDate`
															))) &&
													classes.errorColor
												}
											>
												{t(`credentials:${constantType}.EXPIRATION_DATE`)}
											</InputLabel>
											<ThemeProvider theme={datePickerTheme}>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<Controller
														control={control}
														rules={{
															validate: (date) =>
																conditionalDateValidation(
																	date,
																	index
																),
														}}
														name={`providerSpecialties[${index}].expirationDate`}
														defaultValue={
															`${item.expirationDate}` === 'null'
																? null
																: convertToDateIgnoringTime(
																		`${item.expirationDate}`
																  )
														}
														render={({ onChange, value, name }) => (
															<DatePickerComponent
																onChange={onChange}
																onClose={() => {
																	updateHighlight();
																}}
																name={name}
																value={value}
																format='MM/dd/yyyy'
																placeholder='MM/DD/YYYY'
																clearable
																onBlur={() => {
																	updateHighlight();
																}}
																disabled={
																	credState ===
																		CRED_STATE.CLOSED ||
																	(activePath ===
																		ACTIVE_PATHNAME_MD &&
																		item.isVerified &&
																		credState ===
																			CRED_STATE.OPEN)
																}
																variant='inline'
																autoOk='true'
																minDateMessage={
																	<span
																		className={`${classes.cssLabel} ${classes.marginLeft12}`}
																	>
																		{t(
																			`credentials:VALIDATION_MESSAGE.DATE`
																		)}
																	</span>
																}
																maxDateMessage={
																	<span
																		className={`${classes.cssLabel} ${classes.marginLeft12}`}
																	>
																		{t(
																			`credentials:VALIDATION_MESSAGE.DATE`
																		)}
																	</span>
																}
																invalidDateMessage={``}
																margin='normal'
																theme={datePickerTheme}
																error={
																	(errors?.providerSpecialties &&
																		errors?.providerSpecialties[
																			`${index}`
																		]?.expirationDate) ||
																	(!!highlights &&
																		highlights.includes(
																			`providerSpecialties[${index}].expirationDate`
																		))
																}
																InputProps={{
																	classes: {
																		disabled:
																			classes.disabledInput,
																		root: classes.cssOutlinedInput,
																	},
																}}
																KeyboardButtonProps={{
																	'aria-label': t(
																		'credentials:OTHER_CERTIFICATIONS.EXPIRATION'
																	),
																}}
																inputVariant='outlined'
															/>
														)}
													/>
												</MuiPickersUtilsProvider>
											</ThemeProvider>
										</FormControl>
										{((errors?.providerSpecialties &&
											errors?.providerSpecialties[`${index}`]
												?.expirationDate) ||
											(!!highlights &&
												highlights.includes(
													`providerSpecialties[${index}].expirationDate`
												))) && (
											<span
												className={`${classes.cssLabel} ${classes.marginLeft12}`}
											>
												{t(`credentials:VALIDATION_MESSAGE.DATE`)}
											</span>
										)}
									</Grid>
								</Grid>
							</form>
						</Grid>
					</div>
				</Container>
			</div>
		);
	};

	const boardCertifiedComponent = () => {
		return (
			<>
				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={6}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								required
								htmlFor={`boardCertified`}
								classes={{ root: classes.selectInputLabel }}
								className={
									(errors?.boardCertified ||
										(!!highlights && highlights.includes(`boardCertified`))) &&
									classes.errorColor
								}
							>
								{t(`credentials:${constantType}.BOARD_STATUS_AP`)}
							</InputLabel>
							<ThemeProvider
								theme={
									errors?.boardCertified ||
									(!!highlights && highlights.includes(`boardCertified`))
										? errorTheme
										: normalTheme
								}
							>
								<NativeSelect
									defaultValue={`${boardCertified}`}
									inputRef={register({ required: isRequired })}
									fullWidth
									name={`boardCertified`}
									className={
										credState === CRED_STATE.CLOSED
											? classes.disabledSelect
											: classes.normalInput
									}
									disabled={credState === CRED_STATE.CLOSED > 1}
									onBlur={() => {
										updateHighlight();
									}}
									input={
										<TextFieldComponent
											style={{ border: `0px solid ${COLORS.RAVEN}` }}
											classes={{ input: classes.setWidthTo100OnMobile }}
										/>
									}
								>
									<option key={''} value={null}></option>
									{getBoardStatusPicklist(0)}
								</NativeSelect>
							</ThemeProvider>
							{(errors?.boardCertified ||
								(!!highlights && highlights.includes(`boardCertified`))) && (
								<span className={classes.cssLabel}>
									{t('credentials:VALIDATION_MESSAGE.COMPLETED')}
								</span>
							)}
							{credState === CRED_STATE.CLOSED ? (
								<FormControl style={{ display: 'none' }}>
									<Input
										style={{ display: 'none' }}
										name={`boardCertified`}
										defaultValue={`${boardCertified}`}
										inputRef={register()}
									/>
								</FormControl>
							) : null}
						</FormControl>
					</Grid>
				</Grid>
			</>
		);
	};

	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}
		return fields.map((item, index) => (
			<div key={item.internalId}>
				{renderForm(item, index)}
				<RequiredQuestionDialog
					open={showDeleteModal}
					title={t(`credentials:${constantType}.CONFIRM_DELETE_TITLE`)}
					text={t(`credentials:${constantType}.CONFIRM_DELETE_MESSAGE`).replace(
						`(${type})`,
						`${type}`
					)}
					buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
					buttonIDs='Yes;No'
					disableBackgroundClick={false}
					onClose={(btnPressed) => {
						deleteEntry(btnPressed, index);
					}}
				/>
			</div>
		));
	};

	return (
		<div className={classes.setWidthSelects}>
			<Grid className={classes.leftPad30}>
				<Grid item xs={12}>
					<h3 className={classes.header}>{t(`credentials:${constantType}.TITLE`)}</h3>
					{credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN ? (
						<Grid item xs={12} md={10} className={classes.messageGrid}>
							<Alert severity='error' className={classes.alertMessage} icon={false}>
								{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
							</Alert>
						</Grid>
					) : null}
					<h3 className={classes.infoMessage}>
						{t(`credentials:${constantType}.INFO_MESSAGE`)}
					</h3>
				</Grid>

				<Grid item xs={12} md={12}>
					{isLoaded ? boardCertifiedComponent() : null}
				</Grid>
				{watch(`boardCertified`) === 'Yes' ? renderForms() : null}
			</Grid>

			{isLoaded && credState !== CRED_STATE.CLOSED && watch(`boardCertified`) === 'Yes' ? (
				<div
					className={'credAppAddMoreButton'}
					onClick={() =>
						append({
							id: null,
							specialty: '',
							boardStatus: '',
							passedWrittenExam: '',
							issuingBoard: '',
							certificationNumber: '',
							certificationType: '',
							lifetimeCertified: false,
							issueDate: null,
							expirationDate: null,
							isVerified: false,
							explainOther: '',
						})
					}
				>
					{t(`credentials:${constantType}.ADD_MORE_CERTIFICATIONS`)}
				</div>
			) : null}
		</div>
	);
}

export default BoardCertificationsFormAp;
