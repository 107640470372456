import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import WithContexts from '../../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import { NativeSelect, ThemeProvider, CircularProgress, Input } from '@material-ui/core';
import { COLORS, CRED_STATE } from '../../../../../../utils/Application_Constants';
import TextFieldComponent from '../../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import createTheme from '@material-ui/core/styles/createTheme';
import DatePickerComponent from '../../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import RouteDeparturePrompt from '../../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	getCertificationExams,
	postCertificationExams,
	deleteCertificationExams,
} from '../../../../../../services/Credentialing';
import { withStyles } from '@material-ui/core';
import { convertToDateIgnoringTime, matchesTemplate } from '../../../../../../utils/helpers';
import { examTypes } from './CertificationExamsOptions';
import { examStatuses } from './CertificationExamsStatus';
import DateFnsUtils from '@date-io/date-fns';
import _ from 'lodash';
import {
	certificationExamsStatusPointsCheck,
	certificationExamsStatusCheck,
} from '../../../../../../utils/CredentialingAppUtility';
import Alert from '@material-ui/lab/Alert';
import RequiredQuestionDialog from '../../../../../GlobalComponents/Dialog/RequiredQuestionDialog';

const styles = (theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	// inputRadio: {
	//     color: COLORS.RAVEN,
	//     "&.Mui-focused": {
	//         color: COLORS.RAVEN
	//     },

	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',

	// },
	credAppFirstHeader: {
		padding: '20px 0px',
		borderTop: '1px solid #e7e7e7',
		borderBottom: '1px solid #e7e7e7',
		margin: '30px',
		marginTop: '-15px',
	},

	// inputLabel: {
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase'
	// },
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	completedIcon: {
		color: 'green',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		'& input': {
			height: '32px',
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.RAVEN,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},

	cssOutlinedInput: {
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
		'&$cssFocused $notchedOutline': {
			borderColor: '#6D707E', //focused
			borderWidth: '1px',
			borderRadius: 1,
		},
	},

	cssFocused: {},

	disabled: {},
	disabledInput: {
		opacity: 0.6,
	},
	disabledSelect: {
		opacity: 0.6,
		pointerEvents: 'none',
		cursor: 'default',
	},
	focused: {},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		[theme.breakpoints.up('md')]: {
			marginTop: '25vh',
			marginLeft: '30vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '20vh',
			marginLeft: '25vh',
		},
	},
	successGrid: {
		marginTop: '25px',
	},
	iconButtonMargin: {
		marginRight: '10px',
	},
	marginLeft12: {
		marginLeft: 12,
	},
	hideDeleteBtn: {
		display: 'none',
	},
	infoMessage: {
		color: COLORS.RAVEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '14px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '30px !important',
		paddingBottom: '15px',
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '32px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
});

function CertificationExamsForm({
	classes,
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	setCredAppError,
	updateFlags,
	activePath,
	credState,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	const normalTheme = createTheme({
		overrides: {
			MuiSelect: {
				select: {},
			},
		},
	});

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const [formTemplate] = useState({
		id: 'null',
		timesTaken: '',
		examinationType: '',
		datePassed: null,
		explainOther: '',
	});

	const itemTemplate = {
		id: null,
		timesTaken: '',
		examinationType: '',
		datePassed: null,
		explainOther: '',
	};

	const [deleteRecordIndex, setDeleteRecordIndex] = useState();
	const [deleteRecordId, setDeleteRecordId] = useState();
	const [deleteRecords, setDeleteRecords] = useState([]);

	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);

	const { register, control, handleSubmit, errors, reset, setValue, watch, formState, trigger } =
		useForm({
			mode: 'all',
			defaultValues: {
				id: null,
				timesTaken: '',
				examinationType: '',
				datePassed: null,
				explainOther: '',
			},
		});
	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			setSaveHandler(
				CertificationExamsForm,
				'Certification Exams',
				handleSubmit(async (response) => await onSubmit(response))
			);

			await getCertificationExams(externalId)
				.then(function (response) {
					const { examinations = [] } = response;

					console.log('response is', response);

					if (examinations.length === 0) {
						append({
							id: null,
							timesTaken: '',
							examinationType: '',
							datePassed: null,
							explainOther: '',
						});
						response['examinations'].push({
							id: null,
							timesTaken: '',
							examinationType: '',
							datePassed: null,
							explainOther: '',
						});
					} else {
						response['examinations'].map((item) => {
							if (item.examinationType === null) {
								item.examinationType = '';
							}
							if (item.timesTaken === null) {
								item.timesTaken = '';
							}
							if (item.explainOther === null) {
								item.explainOther = '';
							}
							return item;
						});
					}
					flags.dataSaved = false;
					updateFlags(flags);
					reset(response);
					setIsLoaded(true);

					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status = {
							status: 'incomplete',
							missingFields: [],
						};
						status = certificationExamsStatusCheck(examinations);
						if (!!status.missingFields) {
							setHighlights(status.missingFields);
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);

	function updateHighlight() {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};

			let data = watch();
			status = certificationExamsStatusCheck(data);

			//alert(`missingFields: ${status.missingFields}`)
			setHighlights(status.missingFields);
		}
	}

	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'examinations',
		keyName: 'internalId',
	});

	const dateValidation = (date) => {
		console.log(' return !isNaN((new Date(d)).getTime());' + !isNaN(new Date(date).getTime()));
		return !isNaN(new Date(date).getTime()) === true ? true : false;
	};

	useEffect(() => {
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}
		if (formState.errors.hasOwnProperty('examinations') && flags.validationError === false) {
			flags.validationError = true;
			updateFlags(flags);
		} else {
			if (
				!formState.errors.hasOwnProperty('examinations') &&
				flags.validationError === true
			) {
				flags.validationError = false;
				updateFlags(flags);
			}
		}
	}, [formState]);

	async function onSubmit(data) {
		let success = false;

		const { examinations } = data;
		const { externalId } = UserContext;
		let indexes = _.keys(_.pickBy(examinations, formTemplate));
		for (let i = 0; i < indexes.length; i++) {
			examinations.splice(indexes[i], 1);
		}
		data['clinicianId'] = externalId;
		if (!!data['examinations']) {
			data['examinations'] = data['examinations']
				.map((item) => {
					if (item.id === 'null') {
						item.id = null;
					}

					if (item.examStatus === 'Passed') {
						item.datePassed = moment(item.datePassed).toISOString(true);
					} else {
						item.datePassed = null;
					}

					if (item.examinationType !== 'Other') {
						item.explainOther = '';
					}

					if (matchesTemplate(item, itemTemplate)) {
						return null;
					}

					return item;
				})
				.filter((d) => d !== null);
		}

		if (deleteRecords.length > 0) {
			const deleteRecordsNumbers = deleteRecords.filter(function (element) {
				return element !== 'null';
			});

			await deleteCertificationExams(externalId, deleteRecordsNumbers).catch((e) => {
				setCredAppError(e);
			});
		}

		console.log('before json', data);
		const jsonData = JSON.stringify(data);
		console.log('post payload is:', jsonData);
		await postCertificationExams(externalId, jsonData);
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;

		sectionCompletion.certifications.certificationExams.current =
			await certificationExamsStatusPointsCheck(data['examinations'], sectionCompletion);
		updateSectionCompletion(sectionCompletion);

		updateFlags(flags);
		return success;
	}

	const deleteEntry = (btnPressed, index) => {
		if (btnPressed === 'Yes') {
			if (deleteRecordId !== null) {
				deleteRecords.push(deleteRecordId);
				setDeleteRecords(deleteRecords);
				setShowDeleteModal(false);
			}
			remove(deleteRecordIndex);
			setShowDeleteModal(false);
		}
		setShowDeleteModal(false);
	};

	const openDeleteConfirmation = (item, index) => {
		setDeleteRecordIndex(index);
		setDeleteRecordId(item.id);
		setShowDeleteModal(true);
	};

	const examStatusOnChange = (event, index) => {
		let fieldsToTrigger = [];

		if (event.target.value === `Passed`) {
			fieldsToTrigger.push(`examinations[${index}].datePassed`);

			if (localShouldHighlightFields) {
				setHighlights(fieldsToTrigger);
			}
		}
	};

	const showPassedDate = (item, index) => {
		return (
			<Grid classes={{ root: classes.gridRoot }} item xs={12} md={5}>
				<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
					<InputLabel
						shrink
						htmlFor={`examinations[${index}].datePassed`}
						classes={{ root: classes.inputLabel }}
						error={
							(errors?.examinations &&
								errors?.examinations[`${index}`]?.datePassed) ||
							(!!highlights &&
								highlights.includes(`examinations[${index}].datePassed`))
						}
						//required
					>
						{t('credentials:CERTIFICATION_EXAMS.DATE_PASSED')}
					</InputLabel>
					<ThemeProvider theme={datePickerTheme}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Controller
								as={<DatePickerComponent />}
								control={control}
								rules={{
									validate: (date) => dateValidation(date),
								}}
								name={`examinations[${index}].datePassed`}
								clearable
								disabled={!!item.id && credState === CRED_STATE.CLOSED}
								value={watch('datePassed')}
								defaultValue={
									`${item.datePassed}` === 'null'
										? null
										: convertToDateIgnoringTime(`${item.datePassed}`)
								}
								variant='inline'
								autoOk='true'
								format='MM/dd/yyyy'
								placeholder='MM/DD/YYYY'
								minDateMessage={
									<span className={`${classes.cssLabel} ${classes.marginLeft12}`}>
										min date error
									</span>
								}
								maxDateMessage={
									<span className={`${classes.cssLabel} ${classes.marginLeft12}`}>
										max date errr
									</span>
								}
								invalidDateMessage={
									<span className={`${classes.cssLabel} ${classes.marginLeft12}`}>
										{t('credentials:VALIDATION_MESSAGE.DATE')}
									</span>
								}
								margin='dense'
								theme={datePickerTheme}
								InputProps={{
									classes: {
										disabled: classes.disabledInput,
										root: classes.cssOutlinedInput,
									},
								}}
								error={
									(errors?.examinations &&
										errors?.examinations[`${index}`]?.datePassed) ||
									(!!highlights &&
										highlights.includes(`examinations[${index}].datePassed`))
								}
								onChange={(date) => {
									setValue(`examinations[${index}].datePassed`, date);
								}}
								KeyboardButtonProps={{
									'aria-label': t('credentials:CERTIFICATION_EXAMS.DATE_PASSED'),
								}}
								inputVariant='outlined'
								onClose={() => {
									updateHighlight();
								}}
								onBlur={() => {
									updateHighlight();
								}}
							/>
						</MuiPickersUtilsProvider>
					</ThemeProvider>
				</FormControl>
			</Grid>
		);
	};

	const renderForm = (item, index) => {
		return (
			<>
				<Grid container>
					<Grid
						container
						className={
							index === 0 ? classes.credAppFirstHeader : 'credAppAdditionalHeader'
						}
					>
						<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
							{index === 0
								? 'Certification Exam'
								: t(
										'credentials:CERTIFICATION_EXAMS.ADDITIONAL_CERTIFICATION_EXAMS'
								  )}
						</Grid>
						<Grid
							item
							xs={3}
							className={
								item.isVerified || credState === CRED_STATE.CLOSED
									? classes.hideDeleteBtn
									: 'credAppAdditionalDelete'
							}
							onClick={() => {
								openDeleteConfirmation(item, index);
							}}
						>
							{t('credentials:CERTIFICATION_EXAMS.DELETE_BTN')}
						</Grid>
					</Grid>
				</Grid>

				<form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
					<RouteDeparturePrompt
						blockedBackNavigation={blockedBackNavigation}
						when={
							!flags.dataSaved ? !!Object.keys(formState.dirtyFields).length : false
						}
					/>
					<Input
						autoComplete='off'
						variant='outlined'
						style={{ display: 'none' }}
						name={`examinations[${index}].id`}
						fullWidth
						value={`${item.id}` || null}
						defaultValue={`${item.id}`}
						inputRef={register()}
					/>

					<Grid container direction='row'>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={5}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor={`examinations[${index}].examinationType`}
									classes={{ root: classes.inputLabel }}
								>
									{t('credentials:CERTIFICATION_EXAMS.EXAM_NAME')}
								</InputLabel>
								<ThemeProvider theme={normalTheme}>
									<NativeSelect
										id={`examinations[${index}].examinationType`}
										name={`examinations[${index}].examinationType`}
										defaultValue={`${item.examinationType}`}
										disabled={
											!!item.id &&
											(credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN)
										}
										className={
											!!item.id &&
											(credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN)
												? classes.disabledSelect
												: classes.normalInput
										}
										inputRef={register({})}
										input={
											<TextFieldComponent
												style={{ border: `0px solid ${COLORS.RAVEN}` }}
												classes={{ input: classes.setWidthTo100OnMobile }}
											/>
										}
										onChange={(event) => {
											if (event.target.value === `Other`) {
												setTimeout(() => {
													trigger(`examinations[${index}].explainOther`);
												}, 100);
											}
										}}
									>
										<option key={''} value={null}></option>
										{examTypes.map((item) => (
											<option key={item.value} value={item.value}>
												{item.label}
											</option>
										))}
									</NativeSelect>
								</ThemeProvider>
							</FormControl>
						</Grid>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={5}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor={`examinations[${index}].timesTaken`}
									classes={{ root: classes.inputLabel }}
								>
									{t('credentials:CERTIFICATION_EXAMS.NUMBER_OF_TIME_TAKEN')}
								</InputLabel>
								<TextField
									id={`examinations[${index}].timesTaken`}
									name={`examinations[${index}].timesTaken`}
									className={classes.textField}
									margin='dense'
									variant='outlined'
									disabled={!!item.id && credState === CRED_STATE.CLOSED}
									defaultValue={`${item.timesTaken}`}
									error={
										!!errors?.examinations &&
										errors?.examinations[`${index}`]?.timesTaken
									}
									inputRef={register({
										maxLength: {
											value: 3,
											message: t(
												`credentials:VALIDATION_MESSAGE.NUMBER_OF_TIME_TAKEN`
											),
										},
										pattern: {
											value: /^(0|[1-9][0-9]*)$/,
											message: t(
												`credentials:VALIDATION_MESSAGE.NUMBER_REQUIRED`
											),
										},
									})}
									autoComplete='off'
									InputProps={{
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
									}}
								/>
								{errors?.examinations &&
									errors?.examinations[`${index}`]?.timesTaken && (
										<span
											className={`${classes.cssLabel} ${classes.marginLeft12}`}
										>
											{errors?.examinations &&
												errors?.examinations[`${index}`]?.timesTaken &&
												errors?.examinations[`${index}`]?.timesTaken
													.message}
										</span>
									)}
							</FormControl>
						</Grid>
					</Grid>

					{watch(`examinations[${index}].examinationType`) === 'Other' ? (
						<Grid item xs={12} md={10} classes={{ root: classes.gridRoot }}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor={`examinations[${index}].explainOther`}
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.examinations &&
										errors?.examinations[`${index}`]?.explainOther
									}
								>
									{t(`credentials:CERTIFICATION_EXAMS.EXPLAIN_OTHER`)}
								</InputLabel>
								<TextField
									id={`examinations[${index}].explainOther`}
									name={`examinations[${index}].explainOther`}
									className={classes.textField}
									margin='dense'
									variant='outlined'
									disabled={!!item.id && credState === CRED_STATE.CLOSED}
									defaultValue={`${item.explainOther}`}
									error={
										!!errors?.examinations &&
										errors?.examinations[`${index}`]?.explainOther
									}
									inputRef={register({
										required:
											watch(`examinations[${index}].examinationType`) ===
											'Other',
										maxLength: {
											value: 255,
											message: t('common:MESSAGES.CHARACTER_LIMIT'),
										},
									})}
									autoComplete='off'
									InputProps={{
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
									}}
								/>
								{errors?.examinations &&
									errors?.examinations[`${index}`]?.explainOther &&
									errors?.examinations[`${index}`]?.explainOther['type'] ===
										'maxLength' && (
										<span className={classes.cssLabel}>
											{errors?.examinations &&
												errors?.examinations[`${index}`]?.explainOther &&
												errors?.examinations[`${index}`]?.explainOther
													.message}
										</span>
									)}
								{errors?.examinations &&
									errors?.examinations[`${index}`]?.explainOther &&
									errors?.examinations[`${index}`]?.explainOther['type'] !==
										'maxLength' && (
										<span
											className={`${classes.cssLabel} ${classes.marginLeft12}`}
										>
											{t('credentials:VALIDATION_MESSAGE.EXPLAIN_OTHER')}
										</span>
									)}
							</FormControl>
						</Grid>
					) : null}

					<Grid container direction='row'>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={5}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor={`examinations[${index}].examStatus`}
									classes={{ root: classes.inputLabel }}
								>
									{t('credentials:CERTIFICATION_EXAMS.EXAM_STATUS')}
								</InputLabel>
								<ThemeProvider theme={normalTheme}>
									<NativeSelect
										id={`examinations[${index}].examStatus`}
										name={`examinations[${index}].examStatus`}
										defaultValue={`${item.examStatus}`}
										disabled={
											!!item.id &&
											(credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN)
										}
										className={
											!!item.id &&
											(credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN)
												? classes.disabledSelect
												: classes.normalInput
										}
										inputRef={register({})}
										input={
											<TextFieldComponent
												style={{ border: `0px solid ${COLORS.RAVEN}` }}
												classes={{ input: classes.setWidthTo100OnMobile }}
											/>
										}
										onChange={(e) => examStatusOnChange(e, index)}
									>
										<option key={''} value={null}></option>
										{examStatuses.map((item) => (
											<option key={item.value} value={item.value}>
												{item.label}
											</option>
										))}
									</NativeSelect>
								</ThemeProvider>
							</FormControl>
						</Grid>
						{watch(`examinations[${index}].examStatus`) === 'Passed'
							? showPassedDate(item, index)
							: null}
					</Grid>
				</form>
			</>
		);
	};
	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}

		return fields.map((item, index) => (
			<div key={item.internalId}>
				{renderForm(item, index)}
				<RequiredQuestionDialog
					open={showDeleteModal}
					title={t('credentials:CERTIFICATION_EXAMS.CONFIRM_DELETE_TITLE')}
					text={t('credentials:CERTIFICATION_EXAMS.CONFIRM_DELETE_MESSAGE').replace(
						'(Certification Exams)',
						'Certification Exams'
					)}
					buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
					buttonIDs='Yes;No'
					disableBackgroundClick={false}
					onClose={(btnPressed) => {
						deleteEntry(btnPressed, index);
					}}
				/>
			</div>
		));
	};

	return (
		<>
			<div className={classes.profileWrapper}>
				<Grid container>
					<Grid item xs={12} md={12}>
						<h3 className={classes.header}>
							{t('credentials:CERTIFICATION_EXAMS.TITLE')}
						</h3>
						{credState === CRED_STATE.CLOSED ||
						credState === CRED_STATE.PARTIALLY_OPEN ? (
							<Grid item xs={12} md={10} className={classes.messageGrid}>
								<Alert
									severity='error'
									className={classes.alertMessage}
									icon={false}
								>
									{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
								</Alert>
							</Grid>
						) : null}
						<h3 className={classes.infoMessage}>
							{t(`credentials:CERTIFICATION_EXAMS.INFO_MESSAGE`)}
						</h3>
						<h3 className={classes.infoMessage}>
							{t(`credentials:OTHER_CERTIFICATIONS.NON_REQUIRED`)}
						</h3>
					</Grid>

					<Grid item xs={12} md={12}>
						{renderForms()}
					</Grid>
					<Grid item xs={12} md={12}>
						{isLoaded && credState !== CRED_STATE.CLOSED ? (
							<div
								className={'credAppAddMoreButton'}
								onClick={() =>
									append({
										id: null,
										timesTaken: '',
										examinationType: '',
										datePassed: null,
										explainOther: '',
									})
								}
							>
								{t('credentials:CERTIFICATION_EXAMS.ADD_MORE_CERTIFICATION_EXAMS')}
							</div>
						) : null}
					</Grid>
				</Grid>
			</div>
		</>
	);
}

export default WithContexts(withTranslation()(withStyles(styles)(CertificationExamsForm)));
