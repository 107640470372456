import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { COLORS } from '../../../../utils/Application_Constants';

const AddExpenseTab = withStyles((theme) => ({
	root: {
		borderTopRightRadius: 8,
		borderTopLeftRadius: 8,
		minHeight: 0,
		opacity: 1,
		minWidth: 0,
		flexShrink: 0,
		background: COLORS.LT_JOURNEY10,
	},

	selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default AddExpenseTab;
