import React from 'react';

import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const LoginCallback = ({ homePath, loadingElement }) => {
	const { oktaAuth, authState } = useOktaAuth();
	const history = useHistory();
	const [callbackError, setCallbackError] = React.useState(null);

	React.useEffect(() => {
		const handleLoginRedirect = async () => {
			await oktaAuth.handleLoginRedirect();
		};

		if (oktaAuth.token.isLoginRedirect()) {
			// store redirectUri, it's cleared after `handleLoginRedirect` call
			const redirectUri = oktaAuth.getOriginalUri();
			handleLoginRedirect()
				.then(() => {
					if (!redirectUri) {
						// manual redirect when redirectUri is not set
						// otherwise allow default behavior
						history.replace(homePath);
					}
				})
				.catch((e) => {
					// TODO: handle error state(s)
					console.error(e);
					if (e.errorCode === 'access_denied') {
						setCallbackError(
							'Please reach out to your manager to request access to the Time & Expense App'
						);
					} else {
						setCallbackError(e.message);
					}
				});
		} else {
			// we landed on root path, but do not have login callback params in url query string
			history.replace(homePath);
		}
	}, [oktaAuth, history, homePath]);

	const authError = authState?.error;
	const displayError = callbackError || authError;
	if (displayError) {
		return (
			<Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert severity='error'>{displayError}</Alert>
			</Snackbar>
		);
	}

	return loadingElement;
};

export default LoginCallback;
