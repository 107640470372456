import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import moment from 'moment';

import { COLORS } from '../../../utils/Application_Constants';

const styles = () => ({
	MobileCard__Root: {
		display: 'flex',
		alignItems: 'center',
		width: '95%',
		margin: '0 auto',
		minHeight: '150px',
		flexFlow: 'column',
		padding: '25px 0',
		border: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	MobileCard__AccountName: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		padding: '25px 0',
		fontSize: '16px',
	},
	MobileCard__PresentFont: {
		color: COLORS.RAVEN,
		fontWeight: '300',
		fontSize: '14px',
		padding: '25px 0',
		textAlign: 'center',
	},
	MobileCard_InsideWrapper: {
		display: 'flex',
		flexFlow: 'column',
		width: '85%',
	},
	MobileCard__PresentDetailWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px 0',
		width: '100%',
	},
	MobileCard__PresentDetailFontTitle: {
		fontWeight: '500',
		fontSize: '16px',
		color: COLORS.RAVEN,
	},
	MobileCard_PresentDetailFontInfo: {
		fontWeight: '300',
		color: COLORS.RAVEN,
		opacity: 0.8,
		fontSize: '16px',
	},
	MobileCard__AccountNameWrapper: {
		width: '100%',
		textAlign: 'left',
		padding: '10px 0',
	},
});

class PresentsTableMobileCard extends Component {
	emptyState = () => {
		const { classes } = this.props;
		return (
			<div className={classes.MobileCard__Root}>
				<div className={classes.MobileCard_InsideWrapper}>
					<span className={classes.MobileCard__AccountName}>No Presents</span>
				</div>
			</div>
		);
	};

	render() {
		const { present, classes, isEmpty } = this.props;
		if (isEmpty) {
			return this.emptyState();
		}
		return (
			<div className={classes.MobileCard__Root}>
				<div className={classes.MobileCard_InsideWrapper}>
					<div className={classes.MobileCard__AccountNameWrapper}>
						<span className={classes.MobileCard__AccountName}>
							{present.accountName}
						</span>
					</div>

					<div className={classes.MobileCard__PresentDetailWrapper}>
						<span className={classes.MobileCard__PresentDetailFontTitle}>City</span>
						<span className={classes.MobileCard_PresentDetailFontInfo}>
							{present.city}
						</span>
					</div>
					<div className={classes.MobileCard__PresentDetailWrapper}>
						<span className={classes.MobileCard__PresentDetailFontTitle}>State</span>
						<span className={classes.MobileCard_PresentDetailFontInfo}>
							{present.state}
						</span>
					</div>
					<div className={classes.MobileCard__PresentDetailWrapper}>
						<span className={classes.MobileCard__PresentDetailFontTitle}>
							Present Date
						</span>
						<span className={classes.MobileCard_PresentDetailFontInfo}>
							{moment.utc(present.presentDate).format('MMM D, YYYY')}
						</span>
					</div>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(PresentsTableMobileCard);
