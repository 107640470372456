import React, { useState } from 'react';

import BgImage from '../../assets/images/start-credentialing-bg-2.jpg';
import PoweredByAxualImage from '../../assets/images/PoweredbyAxuall--horizontal.png';
import WithContexts from '../../contexts/withContexts';
import { COLORS } from '../../utils/Application_Constants';
import { Grid, Link, withStyles, CircularProgress } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import ErrorDialog from '../../components/GlobalComponents/Dialog/ErrorDialog';
import {setProviderRecipe} from "../../services/CredentialVerificationService";
import {postAzureB2cUserClaims} from "../../services/Credentialing";

const styles = () => ({
	root: {
		backgroundColor: 'linear-gradient(to right, #4370a000, #3f6a9900)',
		backgroundImage: `url(${BgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top center',
		backgroundSize: 'cover',
	},
	poweredByAxual: {
		maxWidth: '200px',
	},
	header: {
		color: COLORS.MARINER,
		fontSize: '35px',
		fontWeight: 'bold',
		marginBottom: '40px',
	},
	subHeader: {
		color: COLORS.MARINER,
	},
	mainCopy: {
		maxWidth: '600px',
		textAlign: 'center',
	},
	mainButton: {
		color: '#fff',
		fontSize: '1rem',
		padding: '16px',
		minWidth: '400px',
		marginTop: '40px',
		backgroundColor: COLORS.KELLY_GREEN,
		borderRadius: '4px',
	},
});

function CredInProgress({ classes, userContext, personalInformation, credAppStatus, priorCredAppStatus }) {
	const [buttonLoading, setButtonLoading] = useState(false);
	const [loginUrlError, setLoginUrlError] = useState(null);

	function welcomeClinician() {	
		const greetingName = personalInformation.titles.includes('MD' || 'DO') ? `Doctor ${personalInformation.lastName}` : personalInformation.firstName;
		return `Welcome Back, ${greetingName}!`;
	};

	//Authenticated Page
	const content = () => {
	
		if (personalInformation.credAppIdVerification === true) {
			return {
				buttonCopy: 'Return to your application',
				mainCopy: `
                    You're almost done with your credentialing journey!
                    To view your credentialing progress and to submit your
                    completed application, click the button below.
                `,
			};
		}
		//Unauthenticated Page
		return {
			buttonCopy: 'Authenticate your account',
			mainCopy: `
                It looks like your account needs to be authenticated in
                order to start the credentialing process. To authenticate,
                please click the button below.
            `,
		};
	};

	const handleButtonClick = async (event) => {
		event.preventDefault();
		setButtonLoading(true);

		const postAzureB2cUserClaimsRequest = {
			b2cId: userContext.account.accountIdentifier,
			npiNumber: personalInformation.npiNumber,
			providerTitles: personalInformation.titles
		};

		await postAzureB2cUserClaims(postAzureB2cUserClaimsRequest);

		try {
			await setProviderRecipe(personalInformation.npiNumber, userContext.degree, credAppStatus, priorCredAppStatus);
			window.open(process.env.REACT_APP_AXUALL_REDIRECTURL, '_blank');
		} catch (error) {
			setLoginUrlError(error);
		} finally {
			setButtonLoading(false);
		}
	};

	const clearLoginUrlError = () => {
		setLoginUrlError(null);
	};

	return (
		<div className={classes.root}>
			<ErrorDialog
				open={!!loginUrlError}
				onClose={clearLoginUrlError}
				title={loginUrlError?.title}
				description={loginUrlError?.message}
				errorId={loginUrlError?.errorId}
			/>
			<Grid
				container
				spacing={0}
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{ minHeight: '70vh' }}
			>
				<img src={PoweredByAxualImage} alt='' className={classes.poweredByAxual} />
				<h1 className={classes.header}>LocumTenens.com Credentialing</h1>
				<h2 className={classes.subHeader}>
					{ welcomeClinician() }
				</h2>
				<p className={classes.mainCopy}>{content().mainCopy}</p>
				<Link
					component='button'
					className={classes.mainButton}
					to='#'
					onClick={handleButtonClick}
					disabled={buttonLoading}
				>
					{buttonLoading ? (
						<CircularProgress color='inherit' size='0.9em' />
					) : (
						content().buttonCopy
					)}
				</Link>
			</Grid>
		</div>
	);
}

export default WithContexts(withTranslation()(withStyles(styles)(CredInProgress)));
