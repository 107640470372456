import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../../../utils/Application_Constants';
import { Link } from 'react-router-dom';
import { Grid, makeStyles, Paper, Button } from '@material-ui/core';
import { getCredAppStatus } from '../../../../../services/Credentialing';
import { useForm, FormProvider } from 'react-hook-form';

const styles = makeStyles((theme) => ({
	// credStatusExpireHeader: {
	//     color: COLORS.LT_STEEL,
	//     marginTop: theme.spacing(1),
	// },
	test: {
		background: 'red',
	},
	// credStatusApprovedUntil: {
	//     marginBottom: '0px',
	// },
	credStatusPriorDaysLeft: {
		color: COLORS.LT_STEEL,
	},
	mainButtonStyle: {
		marginTop: theme.spacing(1),
	},

	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'center',
		fontSize: '22px',
		fontWeight: '700',
		marginTop: '30px',
		marginLeft: '30px',
	},
	root: {
		padding: '20px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		maxWidth: '100%',
		//backgroundColor: COLORS.BLACK_HAZE,
	},
	// helpPanelContainerRoot: {
	//     //padding: '20px',
	//     display: 'flex',
	//     flexWrap: 'wrap',
	//     [theme.breakpoints.down('sm')]: {
	//         padding:'0px'
	//     },

	// },
	linkHeader: {
		color: COLORS.DENIM,
		textDecoration: 'none',
		display: 'flex',
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
	},
	sectionStyle: {
		color: COLORS.DENIM,
		fontWeight: '700',
		display: 'flex',
	},
	info: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'left',
	},
	columnInfoLeft: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'left',
		margin: '20px 0 10px 0',
		display: 'inline-block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '350px',
		[theme.breakpoints.down('md')]: {
			width: '195px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100px',
		},
	},
	columnInfoCenter: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'left',
		margin: '20px 0 10px 0',
		display: 'inline-block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '350px',
		[theme.breakpoints.down('md')]: {
			width: '195px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100px',
		},
	},
	columnInfoRight: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'right',
		margin: '20px 0 10px 0',
	},
	indicatorGreen: {
		color: 'green',
		verticalAlign: 'middle',
		marginRight: '20px',
		paddingRight: '5px',
	},
	indicatorYellow: {
		color: 'orange',
		verticalAlign: 'middle',
		marginRight: '20px',
		paddingRight: '5px',
	},
	indicatorRed: {
		color: 'red',
		verticalAlign: 'middle',
		marginRight: '20px',
		paddingRight: '5px',
	},
	lifeTime: {
		marginRight: '29px',
	},
	columnHeaderLeft: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
		marginTop: '20px',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	columnHeaderCenter: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
		marginTop: '20px',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	columnHeaderRight: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'right',
		marginTop: '20px',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	checkStatusIcon: {
		color: COLORS.DENIM,
		fontSize: '22px',
		marginLeft: '4px',
		marginTop: '3px;',
	},
	noMaxWidth: {
		maxWidth: 'none',
	},
	topTileRoot: {
		//marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	expirationDate: {
		textAlign: 'right',
	},
	expirationDateHeader: {
		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		textAlign: 'right',
	},
	statusExpired: {
		borderWidth: '1px',
		borderColor: COLORS.LT_PEPPER,
		color: COLORS.LT_PEPPER,
		borderRadius: '25px',
		background: COLORS.LT_PEPPER10,
		paddingLeft: '5px',
		textAlign: 'center',
	},
	statusExpiresSoon: {
		borderWidth: 'thin',
		borderColor: '#FFB733',
		color: '#FFB733',
		borderRadius: '25px',
		background: '#FEF8C0',
		paddingLeft: '5px',
		textAlign: 'center',
	},
	statusComplete: {
		borderWidth: 'thin',
		borderColor: '#048E17',
		color: '#048E17',
		borderRadius: '25px',
		background: '#7ED589',
		paddingLeft: '5px',
		textAlign: 'center',
	},
	statusInReview: {
		borderWidth: 'thin',
		borderColor: '#0853AE',
		color: '#0853AE',
		borderRadius: '25px',
		background: '#98EDFE',
		paddingLeft: '5px',
		textAlign: 'center',
	},
	dateInReview: {
		borderStyle: 'none',
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'center',
		paddingLeft: '5px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	statusNotApproved: {
		borderWidth: 'thin',
		borderColor: '#FFB733',
		color: '#FFB733',
		borderRadius: '25px',
		background: '#FEF8C0',
		paddingLeft: '5px',
		textAlign: 'center',
	},
	statusIncomplete: {
		borderWidth: 'thin',
		borderColor: '#FF5733',
		color: '#FF5733',
		borderRadius: '25px',
		background: '#FEACAC',
		paddingLeft: '5px',
		textAlign: 'center',
	},
	textLink: {
		color: 'white',
		marginTop: '.5rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	darktextLink: {
		color: COLORS.LT_JOURNEY,
		marginTop: '.5rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	daysLeft: {
		borderStyle: 'none',
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'center',
	},
}));

function CredentialingStatusCard({ t, UserContext, setCredAppError, setIsLoaded }) {
	const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
		timeZone: 'UTC',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
	const [approvedUntilFormatted, setApprovedUntilFormatted] = useState('');
	const [priorApprovedUntilFormatted, setPriorApprovedUntilFormatted] = useState('');
	const [credPackReceivedDateFormatted, setCredPackReceivedDateFormatted] = useState('');
	const [, setDaysLeft] = useState('');
	const [priorDaysLeft, setPriorDaysLeft] = useState('');
	const classes = styles();
	const methods = useForm({
		mode: 'all',
		defaultValues: {
			id: null,
			clinicianId: null,
			status: null,
			credAppFileName: null,
			createdDate: null,
			lastModifiedDate: null,
			credAppPercentComplete: null,
			credPackReceivedDate: null,
			approvedUntil: null,
			haveReferenceInTwoYear: null,
			credentialingType: null,
			priorApprovedUntil: null,
			priorStatus: null,
			priorFileName: null,
		},
	});

	const approvedUntil = methods.watch('approvedUntil');
	const priorApprovedUntil = methods.watch('priorApprovedUntil');
	const status = methods.watch('status');
	const priorStatus = methods.watch('priorStatus');
	const credPackReceivedDate = methods.watch('credPackReceivedDate');

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;
			await getCredAppStatus(externalId)
				.then(function (response) {
					console.log('credentialing response ', response);
					methods.reset(response);
					setIsLoaded(true);
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, []);

	useEffect(() => {
		if (!!credPackReceivedDate) {
			const credPackReceivedDateSplit = credPackReceivedDate.split('T')[0];
			var date = new Date(credPackReceivedDateSplit);
			setCredPackReceivedDateFormatted(longEnUSFormatter.format(date));
		}
	}, [credPackReceivedDate]);

	useEffect(() => {
		if (!!priorApprovedUntil) {
			const priorApprovedUntilDate = priorApprovedUntil.split('T')[0];
			var date = new Date(priorApprovedUntilDate);
			setPriorApprovedUntilFormatted(longEnUSFormatter.format(date));
			var today = new Date();
			var days = Math.ceil((date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
			setPriorDaysLeft('( ' + days + ' days )');
		}
	}, [priorApprovedUntil]);

	useEffect(() => {
		if (!!approvedUntil) {
			const approvedUntilDate = approvedUntil.split('T')[0];
			var date = new Date(approvedUntilDate);
			setApprovedUntilFormatted(longEnUSFormatter.format(date));
			var today = new Date();
			var days = Math.ceil((date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
			setDaysLeft('( ' + days + ' days )');
		}
	}, [approvedUntil]);

	const renderExpired = () => {
		return (
			<div>
				<Paper className='cr-bg-pepper--gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0 cr-white'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12}>
							<span className='cr-white' style={{ marginRight: '8px' }}>
								Status:
							</span>
							<span className='cr-chip cr-chip--statusPepper'>Expired</span>
						</Grid>
						<Grid item xs={12}>
							<h6 className='cr-pepper10 cr-mb-1 cr-mt-2'>
								YOUR CREDENTIALS EXPIRED ON:
							</h6>
							<h4 className='cr-mb-0 cr-white'>{priorApprovedUntilFormatted}</h4>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant='contained'
								color='primary'
								className={classes.mainButtonStyle}
								disableElevation
								component={Link}
								to='/credentials/personalInformation'
							>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS_START_RECRED_BUTTON`)}
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	const renderExpiresSoon = () => {
		return (
			<div>
				<Paper className='cr-bg-sunflower--gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12}>
							<span style={{ marginRight: '8px' }}>Status:</span>
							<span className='cr-chip cr-chip--statusSunflower'>Expires soon</span>
						</Grid>
						<Grid item xs={12}>
							<h6 className='cr-slate cr-mb-1 cr-mt-2'>
								YOUR CREDENTIALS EXPIRE ON:
							</h6>
							<h4 style={{ marginBottom: '4px' }}>{priorApprovedUntilFormatted}</h4>
							<span>
								<em>{priorDaysLeft}</em>
							</span>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant='contained'
								color='primary'
								className={classes.mainButtonStyle}
								disableElevation
								component={Link}
								to='/credentials/personalInformation'
							>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS_START_RECRED_BUTTON`)}
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	const renderComplete = () => {
		return (
			<div>
				<Paper className='cr-bg-emerald-gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0 cr-white'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12}>
							<span className='cr-white' style={{ marginRight: '8px' }}>
								Status:
							</span>
							<span className='cr-chip cr-chip--statusEmerald'>Complete</span>
						</Grid>
						<Grid item xs={12}>
							<h6 className='cr-emerald10 cr-mb-1 cr-mt-2'>
								YOUR CREDENTIALS EXPIRE ON:
							</h6>
							<h4 className='cr-mb-0 cr-white'>{approvedUntilFormatted}</h4>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	const renderInReview = () => {
		return (
			<div>
				<Paper className='cr-bg-journey--gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0 cr-white'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12}>
							<span className='cr-white' style={{ marginRight: '8px' }}>
								Status:
							</span>
							<span className='cr-chip cr-chip--statusJourney'>In review</span>
						</Grid>
						<Grid item xs={12}>
							<h6 className='cr-journey10 cr-mb-1 cr-mt-2'>
								LAST COMPLETED APPLICATION:
							</h6>
							<h4 className='cr-mb-0 cr-white'>{credPackReceivedDateFormatted}</h4>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	const renderIncomplete = () => {
		return (
			<div>
				<Paper className='cr-bg-pepper--gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0 cr-white'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12}>
							<span className='cr-white' style={{ marginRight: '8px' }}>
								Status:
							</span>
							<span className='cr-chip cr-chip--statusPepper'>Incomplete</span>
						</Grid>
						<Grid item xs={12}>
							<h6 className='cr-pepper10 cr-mb-1 cr-mt-2'>
								Your application is incomplete. Please update your information and
								sign.
							</h6>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant='contained'
								color='primary'
								className={classes.mainButtonStyle}
								disableElevation
								component={Link}
								to='/credentials/personalInformation'
							>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS_GOTO_APP_BUTTON`)}
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	const renderNotApproved = () => {
		return (
			<div>
				<Paper className='cr-bg-slate--gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0 cr-white'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<span className='cr-white' style={{ marginRight: '8px' }}>
								Status:
							</span>
							<span className='cr-chip cr-chip--statusSlate'>
								Contact your recruiter
							</span>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	return (
		<div>
			<FormProvider {...methods}>
				<form>
					{((status === 'App Sent' && priorStatus === 'Expiring') ||
						(status === 'Application Started' && priorStatus === 'Expiring')) &&
						renderExpiresSoon()}
					{((status === 'App Sent' && priorStatus === 'Expired') ||
						(status === 'Application Started' && priorStatus === 'Expired')) &&
						renderExpired()}{' '}
					{/*renderExpired*/}
					{status === 'Incomplete' && renderIncomplete()}
					{(status === 'Approved' || status === 'Approved Conditions') &&
						renderComplete()}
					{(status === 'In Process' ||
						status === 'App Received' ||
						status === 'Temp Approved' ||
						status === 'Hold') &&
						renderInReview()}
					{status === 'Not Approved' && renderNotApproved()}
				</form>
			</FormProvider>
		</div>
	);
}

export default CredentialingStatusCard;
