import { DOCUMENT_TYPE, DOCUMENT_TYPE_LABEL } from '../../../../../utils/Application_Constants';

const commonOtherDocTypes = [
	{
		value: DOCUMENT_TYPE.BirthCertificate,
		label: DOCUMENT_TYPE_LABEL.BirthCertificate,
	},
	{
		value: DOCUMENT_TYPE.ChildAbuseClearance,
		label: DOCUMENT_TYPE_LABEL.ChildAbuseClearance,
	},
	{
		value: DOCUMENT_TYPE.CourtBoardDocuments,
		label: DOCUMENT_TYPE_LABEL.CourtBoardDocuments,
	},
	{
		value: DOCUMENT_TYPE.BoardCertificate,
		label: DOCUMENT_TYPE_LABEL.BoardCertificate,
	},
	{
		value: DOCUMENT_TYPE.ECFMG,
		label: DOCUMENT_TYPE_LABEL.ECFMG,
	},
	{
		value: DOCUMENT_TYPE.GovtTraining,
		label: DOCUMENT_TYPE_LABEL.GovtTraining,
	},
	{
		value: DOCUMENT_TYPE.MalpracticeCarrier,
		label: DOCUMENT_TYPE_LABEL.MalpracticeCarrier,
	},
	{
		value: DOCUMENT_TYPE.MammoNumbers,
		label: DOCUMENT_TYPE_LABEL.MammoNumbers,
	},
	{
		value: DOCUMENT_TYPE.NuclearMaterialsLicense,
		label: DOCUMENT_TYPE_LABEL.NuclearMaterialsLicense,
	},
	{
		value: DOCUMENT_TYPE.RadioactiveMaterialsLicense,
		label: DOCUMENT_TYPE_LABEL.RadioactiveMaterialsLicense,
	},
	{
		value: DOCUMENT_TYPE.Transcripts,
		label: DOCUMENT_TYPE_LABEL.Transcripts,
	},
	{
		value: DOCUMENT_TYPE.Undergraduate,
		label: DOCUMENT_TYPE_LABEL.Undergraduate,
	},
	{
		value: DOCUMENT_TYPE.XRay,
		label: DOCUMENT_TYPE_LABEL.XRay,
	},
	{
		value: DOCUMENT_TYPE.FCVS,
		label: DOCUMENT_TYPE_LABEL.FCVS,
	},
	{
		value: DOCUMENT_TYPE.FellowshipCertificate,
		label: DOCUMENT_TYPE_LABEL.FellowshipCertificate,
	},
	{
		value: DOCUMENT_TYPE.FifthPathway,
		label: DOCUMENT_TYPE_LABEL.FifthPathway,
	},
	{
		value: DOCUMENT_TYPE.InternshipCertificate,
		label: DOCUMENT_TYPE_LABEL.InternshipCertificate,
	},
	{
		value: DOCUMENT_TYPE.Professional,
		label: DOCUMENT_TYPE_LABEL.Professional,
	},
	{
		value: DOCUMENT_TYPE.ResidencyCertificate,
		label: DOCUMENT_TYPE_LABEL.ResidencyCertificate,
	},
	{
		value: DOCUMENT_TYPE.Aviation,
		label: DOCUMENT_TYPE_LABEL.Aviation,
	},
	{
		value: DOCUMENT_TYPE.AwardCert,
		label: DOCUMENT_TYPE_LABEL.AwardCert,
	},
	{
		value: DOCUMENT_TYPE.InfectionControlCertificate,
		label: DOCUMENT_TYPE_LABEL.InfectionControlCertificate,
	},
	{
		value: DOCUMENT_TYPE.LicenseBoardExams,
		label: DOCUMENT_TYPE_LABEL.LicenseBoardExams,
	},
	{
		value: DOCUMENT_TYPE.LicensureNonUS,
		label: DOCUMENT_TYPE_LABEL.LicensureNonUS,
	},
	{
		value: DOCUMENT_TYPE.Oklahoma,
		label: DOCUMENT_TYPE_LABEL.Oklahoma,
	},
	{
		value: DOCUMENT_TYPE.SpecialCertifications,
		label: DOCUMENT_TYPE_LABEL.SpecialCertifications,
	},
	{
		value: DOCUMENT_TYPE.MammoLetter,
		label: DOCUMENT_TYPE_LABEL.MammoLetter,
	},
	{
		value: DOCUMENT_TYPE.MarriageCert,
		label: DOCUMENT_TYPE_LABEL.MarriageCert,
	},
	{
		value: DOCUMENT_TYPE.Medicare,
		label: DOCUMENT_TYPE_LABEL.Medicare,
	},
	{
		value: DOCUMENT_TYPE.Membership,
		label: DOCUMENT_TYPE_LABEL.Membership,
	},
	{
		value: DOCUMENT_TYPE.Military,
		label: DOCUMENT_TYPE_LABEL.Military,
	},
	{
		value: DOCUMENT_TYPE.MQSA,
		label: DOCUMENT_TYPE_LABEL.MQSA,
	},
	{
		value: DOCUMENT_TYPE.NonLTCOI,
		label: DOCUMENT_TYPE_LABEL.NonLTCOI,
	},
	{
		value: DOCUMENT_TYPE.Appt,
		label: DOCUMENT_TYPE_LABEL.Appt,
	},
	{
		value: DOCUMENT_TYPE.NonMedical,
		label: DOCUMENT_TYPE_LABEL.NonMedical,
	},
	{
		value: DOCUMENT_TYPE.CAQHAPP,
		label: DOCUMENT_TYPE_LABEL.CAQHAPP,
	},
	{
		value: DOCUMENT_TYPE.COMPCREDAPP,
		label: DOCUMENT_TYPE_LABEL.COMPCREDAPP,
	},
	{
		value: DOCUMENT_TYPE.COMPEHOSPAPP,
		label: DOCUMENT_TYPE_LABEL.COMPEHOSPAPP,
	},
	{
		value: DOCUMENT_TYPE.COMPLICAPP,
		label: DOCUMENT_TYPE_LABEL.COMPLICAPP,
	},
	{
		value: DOCUMENT_TYPE.DEAAP,
		label: DOCUMENT_TYPE_LABEL.DEAAP,
	},
	{
		value: DOCUMENT_TYPE.RECREDAPP,
		label: DOCUMENT_TYPE_LABEL.RECREDAPP,
	},
	{
		value: DOCUMENT_TYPE.RELEASEFORM,
		label: DOCUMENT_TYPE_LABEL.RELEASEFORM,
	},
	{
		value: DOCUMENT_TYPE.DEAPOA,
		label: DOCUMENT_TYPE_LABEL.DEAPOA,
	},
	{
		value: DOCUMENT_TYPE.COVID19,
		label: DOCUMENT_TYPE_LABEL.COVID19,
	},
];

const apOtherDocTypes = [
	{
		value: DOCUMENT_TYPE.AANA,
		label: DOCUMENT_TYPE_LABEL.AANA,
	},
];

const mdOtherDocTypes = [];

export { commonOtherDocTypes, mdOtherDocTypes, apOtherDocTypes };
