import React, { Component } from 'react';
import {
	withStyles,
	Container,
	Grid,
	Paper,
	Hidden,
	Drawer,
	CircularProgress,
} from '@material-ui/core';
import SideNavigation from '../../components/ContentComponents/Credentials/SideNavigationControl/SideNavigation';
import {
	COLORS,
	PROFESSIONS_MD,
	ACTIVE_PATHNAME_AP,
	SEGMENT_MEDICAL,
	SEGMENT_NURSING,
	SEGMENT_PA,
	SEGMENT_ALLIED,
	SEGMENT_SFA,
	ACTIVE_PATHNAME_MD,
	CRED_STATE,
	CRED_STATUS,
	CRED_TYPE,
	SEGMENT_PERFUSIONIST,
} from '../../utils/Application_Constants';
import { calcCredState } from '../../utils/CredentialingAppUtility';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
	getPersonalInformation,
	postPersonalInformation,
	getCredAppStatus,
	postCredAppStatus,
	getClinicianCredApp,
	patchPercentComplete,
	patchCredAppStatus,
} from '../../services/Credentialing';
import Documents from '../../components/ContentComponents/Credentials/PageComponenets/Documents';
import PersonalInformation from '../../components/ContentComponents/Credentials/PageComponenets/PersonalInformation';
import Education from '../../components/ContentComponents/Credentials/PageComponenets/Education';
import References from '../../components/ContentComponents/Credentials/PageComponenets/References';
import CheckStatusAndSign from '../../components/ContentComponents/Credentials/PageComponenets/CheckStatusAndSign';
import Internship from '../../components/ContentComponents/Credentials/PageComponenets/training/Internship/Internship';
import Residency from '../../components/ContentComponents/Credentials/PageComponenets/training/Residency/Residency';
import Fellowship from '../../components/ContentComponents/Credentials/PageComponenets/training/Fellowship/Fellowship';
import GapsInTraining from '../../components/ContentComponents/Credentials/PageComponenets/training/GapsInTraining';
import BoardCertifications from '../../components/ContentComponents/Credentials/PageComponenets/certifications/BoardCertifications';
import OtherCertifications from '../../components/ContentComponents/Credentials/PageComponenets/certifications/OtherCertifications';
import CertificationExams from '../../components/ContentComponents/Credentials/PageComponenets/certifications/CertificationExams';
import Licensure from '../../components/ContentComponents/Credentials/PageComponenets/Licensure';
import HospitalAffiliation from '../../components/ContentComponents/Credentials/PageComponenets/workhistory/HospitalAffiliation';
import PracticeExperience from '../../components/ContentComponents/Credentials/PageComponenets/workhistory/PracticeExperience';
import MilitaryExperience from '../../components/ContentComponents/Credentials/PageComponenets/workhistory/MilitaryExperience';
import GapsInWorkHistory from '../../components/ContentComponents/Credentials/PageComponenets/workhistory/GapsInWorkHistory';
import MalpracticeClaimsHistory from '../../components/ContentComponents/Credentials/PageComponenets/malpracticeinsurance/MalpracticeClaimsHistory';
import MalpracticeCarriers from '../../components/ContentComponents/Credentials/PageComponenets/malpracticeinsurance/MalpracticeCarriers';
import DisciplinaryActionQuestions from '../../components/ContentComponents/Credentials/PageComponenets/attestationquestions/DisciplinaryActionQuestions';
import DisclosureQuestions from '../../components/ContentComponents/Credentials/PageComponenets/attestationquestions/DisclosureQuestions';
import menuListforNavigation from '../../configuration/navigation/credentials/menuItemListForNavigation.json';
import menuItemListForNavigation_AP_path from '../../configuration/navigation/credentials/menuItemListForNavigation_AP_path.json';
import NavigationButton from '../../components/ContentComponents/Credentials/SideNavigationControl/NavigationButton';
import { withRouter } from 'react-router-dom';
import CustomCircularProgress from '../../components/GlobalComponents/CircularProgress/CustomCircularProgress';
import ErrorDialog from '../../components/GlobalComponents/Dialog/ErrorDialog';
import WithContexts from '../../contexts/withContexts';
import { Close } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import AdditionalTraining from '../../components/ContentComponents/Credentials/PageComponenets/training/AdditionalTraining';
import StartCredentials from './StartCredentials';
import { appInsights, reactPlugin } from '../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import {
	personalInformationSectionPointsCheck,
	documentLibraryStatusPointsCheck,
	otherCertificationsStatusPointsCheck,
	certificationExamsStatusPointsCheck,
	malpracticeCarriersStatusPointsCheck,
	disciplinaryActionQuestionsStatusPointsCheck,
	disclosureQuestionsStatusPointsCheck,
	licensureStatusPointsCheck,
	referencesStatusPointsCheck,
	internshipSectionPointsCheck,
	residencySectionPointsCheck,
	fellowshipSectionPointsCheck,
	additionalTrainingSectionPointsCheck,
	practiceExperienceSectionPointsCheck,
	hospitalAffiliationSectionPointsCheck,
	gapsInWorkHistorySectionPointsCheck,
	gapsInTrainingSectionPointsCheck,
	militaryExperienceSectionPointsCheck,
	malpracticeClaimsHistorySectionPointsCheck,
	educationSectionPointsCheck,
	boardCertificationsStatusPointsCheck,
	internshipSectionCheck,
	residencySectionCheck,
	fellowshipSectionCheck,
	additionalTrainingSectionCheck,
	hospitalAffiliationSectionCheck,
	practiceExperienceSectionCheck,
	otherCertificationsStatusCheck,
	certificationExamsStatusCheck,
	gapsInWorkHistorySectionCheck,
	militaryExperienceSectionCheck,
	gapsInTrainingSectionCheck,
} from '../../utils/CredentialingAppUtility';

import CredentialsOverview from '../../components/ContentComponents/Credentials/PageComponenets/CredentialsOverview';

const styles = (theme) => ({
	root: {
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		backgroundColor: COLORS.BLACK_HAZE,
	},
	containerStyle: {
		paddingLeft: '0px',
		paddingRight: '0px',
	},
	sidebarRoot: {
		// marginTop: '30px',
		// padding: '30px 0',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		minHeight: '800px',
		boxShadow: 'none',
	},
	contentRoot: {
		// marginTop: '30px',
		//  padding: '30px 0',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '0px',
		},
	},
	credentialsContent: {
		zIndex: '10',
	},
	sidePanelContainerRoot: {
		//padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	mainPanelContainerRoot: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	circularProgressHeader: {
		//marginBottom: '10px',
		paddingTop: '30px',
		background: '#f7f7f7',
		textAlign: 'center',
	},
	helpPanelLinks: {
		textAlign: 'left',
	},
	navigationButtonWrapper: {
		position: 'relative',
		bottom: '0px',
		display: 'flex',
		justifyContent: 'flex-start',
		marginLeft: '20px',
		marginBottom: '30px',
	},
	navigationButton: {
		marginTop: '10px',
		marginRight: '30px',
		width: '300px',
	},
	buttonBlock: {
		display: 'block',
	},
	buttonNone: {
		display: 'none',
	},
	gridControl: {
		textAlign: 'left',
		padding: '20px 0px 0px 0px',
		justifyContent: 'center',
	},
	gridCircularProgress: {
		textAlign: 'end',
		justifyContent: 'center',
		padding: '10px 0px',
	},
	credAnchorTag: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		cursor: 'pointer',
		marginBottom: '15px',
		borderBottom: `1px solid ${COLORS.TRUE_BLUE}`,
	},
	credAnchorTagClosure: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		cursor: 'pointer',
		marginBottom: '10px',
		marginTop: '15px',
		textAlign: 'left',
		marginLeft: '10px',
	},
	drawerPaper: {
		width: '100%',
		backgroundColor: 'none',
		boxShadow: 'none',
		marginTop: '60px',
		overflow: 'scroll',
	},
	mobileNavigation: {
		color: COLORS.RAVEN,
		height: '80vh',
		overflow: 'scroll',
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		[theme.breakpoints.up('md')]: {
			marginTop: '25vh',
			marginLeft: '30vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '20vh',
			marginLeft: '25vh',
		},
	},
});

class CredentialsV1 extends Component {
	constructor(props) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName('');

		this.state = {
			isLoading: true,
			activePath: null,
			segment: null,
			currentSelectedLinkId: 0,
			toggleSideNavDrawer: false,
			credState: CRED_STATE.OPEN,
			// Save related data
			handleSubmit: null,
			childTab: null,
			childName: null,
			flags: {
				missingData: false,
				dataSaved: false,
				isDirty: false,
				showSaveChanges: false,
				isPendingSave: false,
				targetTab: 0,
				validationError: false,
				navDisabled: false,
				isError: false,
			},
			personalInformationObj: {},
			sectionCompletion: {},
			totalCompletion: 0,
			totalCompletionKey: 0,
			redirectFlag: true,
			navigationFlag: false,
			credAppStatus: '',
			hasCredRecord: false,
			navDisabled: false,
			credType: '',
		};
		this.updateTitles = this.updateTitles.bind(this);
	}

	sideNavdrawerToggle = () => {
		const { toggleSideNavDrawer } = this.state;
		this.setState({ toggleSideNavDrawer: !toggleSideNavDrawer });
	};

	getSelectdLinkIdByPathName = async (activePath) => {
		const menuList =
			activePath === ACTIVE_PATHNAME_AP
				? menuItemListForNavigation_AP_path.data
				: menuListforNavigation.data;
		console.log('this.props.location.pathname ' + this.props.location.pathname);
		let obj = menuList.find((o) => o.parentUrlLink === this.props.location.pathname);

		let currentId;
		if (obj === undefined) {
			currentId = 0;
		} else {
			currentId = obj.id - 1;
		}

		console.log('this.props.location.pathname currentId ' + currentId);
		return currentId;
	};

	getSelectdLinkId = async () => {
		//const activePath = this.state.activePath
		//const menuList = activePath === ACTIVE_PATHNAME_AP ? menuItemListForNavigation_AP_path.data : menuListforNavigation.data
		//let obj = menuList.find(o => o.parentUrlLink === this.props.location.pathname);
		this.setState({ navigationFlag: false });
	};

	componentDidUpdate() {
		if (this.state.currentSelectedLinkId !== 0) {
			if (this.props.location.pathname === '/credentials/personalInformation') {
				this.setState({ currentSelectedLinkId: 0 });
			}
		}
	}

	async componentDidMount() {
		const { externalId } = this.props.UserContext;
		await this.LoadAppFor(externalId);
	}

	async LoadAppFor(externalId) {
		await Promise.all([getCredAppStatus(externalId), getClinicianCredApp(externalId)])
			.then(async ([statusObj, credAppObject]) => {
				// const { featureFlags } = this.state.featureFlags.axuallCredententialing;
				//const applicationStarted = featureFlags.axuallCredententialing ? CRED_STATUS.ApplicationStarted : CRED_STATUS.ApplicationStarted;
				const status = !!statusObj ? statusObj.status : CRED_STATUS.ApplicationStarted;

				// credentialingType
				const credType = !!statusObj ? statusObj.credentialingType : CRED_TYPE.INITIAL;

				const personalInformationObj =
					credAppObject !== null
						? credAppObject.personal
						: await getPersonalInformation(externalId);
				const activePath =
					!!personalInformationObj &&
					PROFESSIONS_MD.includes(personalInformationObj.profession)
						? ACTIVE_PATHNAME_MD
						: ACTIVE_PATHNAME_AP;
				let segment = SEGMENT_MEDICAL;

				if (!!personalInformationObj && activePath === ACTIVE_PATHNAME_AP) {
					segment = this.getNursingSegment(personalInformationObj.profession);
				}
				const newState = {};
				newState.hasCredRecord = !!statusObj && !!statusObj.id;
				newState.currentSelectedLinkId = await this.getSelectdLinkIdByPathName(activePath);
				newState.oldLinkId = newState.currentSelectedLinkId;
				newState.activePath = activePath;
				newState.segment = segment;
				if (!!personalInformationObj) {
					newState.titles = personalInformationObj.titles;
					newState.personalInformationObj = personalInformationObj;
				}
				newState.credState = calcCredState(status);
				newState.credAppStatus = status;
				newState.credType = credType;
				newState.statusObj = statusObj;
				newState.shouldHighlightFields = false;
				this.setState({ ...newState });

				const state = { ...this.state };
				if (!!personalInformationObj) {
					state.profession = personalInformationObj.profession; //this is here to function like isLoaded = true;
				}
				state.sectionCompletion = !!credAppObject
					? await this.setInitialPercentComplete(credAppObject)
					: {};
				await this.getCurrentCompletionStatus(state.sectionCompletion);
				this.setState({
					profession: state.profession,
					sectionCompletion: state.sectionCompletion,
					isLoading: false,
				});
			})
			.catch((e) => {
				this.setCredAppError(e);
			});
	}

	async setInitialPercentComplete(credObject) {
		const state = { ...this.state };
		const { externalId } = this.props.UserContext;
		if (!externalId || !credObject) {
			console.log('*** externalId or cred obj NOT set ***');
			return {};
		}

		if (state.activePath === ACTIVE_PATHNAME_AP) {
			state.sectionCompletion = {
				personalInformation: { total: 7, current: 0 },
				education: { total: 16, current: 0 },
				training: {
					total: 5,
					additionalTraining: { total: 4, current: 0 },
					gapsInTraining: { total: 1, current: 0 },
				},
				certifications: {
					total: 20,
					boardCertifications: { total: 7, current: 0 },
					otherCertifications: { total: 3, current: 0 },
					certificationExams: { total: 2, current: 0 },
					licensure: { total: 8, current: 0 },
				},
				workHistory: {
					total: 10,
					hospitalAffiliations: { total: 4, current: 0 },
					practiceExperience: { total: 4, current: 0 },
					militaryExperience: { total: 1, current: 0 },
					gapsInWorkHistory: { total: 1, current: 0 },
				},
				references: { total: 9, current: 0 },
				malpracticeInsurance: {
					total: 10,
					malpracticeClaimsHistory: { total: 5, current: 0 },
					malpracticeCarriers: { total: 5, current: 0 },
				},
				attestationQuestions: {
					total: 20,
					disciplinaryActionQuestions: { total: 10, current: 0 },
					disclosureQuestions: { total: 10, current: 0 },
				},
				reviewAndSign: { total: 0, current: 0 },
				documentLibrary: { total: 3, current: 0 },
			};
		} else if (state.activePath === ACTIVE_PATHNAME_MD) {
			state.sectionCompletion = {
				personalInformation: { total: 7, current: 0 },
				education: { total: 5, current: 0 },
				training: {
					total: 16,
					internship: { total: 7, current: 0 },
					residency: { total: 7, current: 0 },
					fellowship: { total: 1, current: 0 },
					gapsInTraining: { total: 1, current: 0 },
				},
				certifications: {
					total: 20,
					boardCertifications: { total: 7, current: 0 },
					otherCertifications: { total: 3, current: 0 },
					certificationExams: { total: 2, current: 0 },
					licensure: { total: 8, current: 0 },
				},
				workHistory: {
					total: 10,
					hospitalAffiliations: { total: 4, current: 0 },
					practiceExperience: { total: 4, current: 0 },
					militaryExperience: { total: 1, current: 0 },
					gapsInWorkHistory: { total: 1, current: 0 },
				},
				references: { total: 9, current: 0 },
				malpracticeInsurance: {
					total: 10,
					malpracticeClaimsHistory: { total: 5, current: 0 },
					malpracticeCarriers: { total: 5, current: 0 },
				},
				attestationQuestions: {
					total: 20,
					disciplinaryActionQuestions: { total: 10, current: 0 },
					disclosureQuestions: { total: 10, current: 0 },
				},
				reviewAndSign: { total: 0, current: 0 },
				documentLibrary: { total: 3, current: 0 },
			};
		}

		const [
			personalInfoPoints,
			educationPoints,
			internshipPoints,
			residencyPoints,
			fellowshipPoints,
			hospitalAffiliationPoints,
			practiceExperiencePoints,
			additionalTrainingPoints,
			documentLibraryPoints,
			referencesPoints,
			licensurePoints,
			otherCertificationsPoints,
			certificationExamsPoints,
			boardCertificationsPoints,
			malpracticeCarriersPoints,
			malpracticeClaimsHistoryPoints,
			gapsInTrainingPoints,
			gapsinWorkHistoryPoints,
			militaryExperiencePoints,
			disciplinaryActionQuestionsPoints,
			disclosureQuestionsPoints,
		] = await Promise.all([
			personalInformationSectionPointsCheck(credObject.personal, state.sectionCompletion),
			educationSectionPointsCheck(
				credObject.education,
				state.activePath,
				state.titles,
				state.segment,
				state.sectionCompletion
			),
			internshipSectionPointsCheck(
				!!credObject.internship ? credObject.internship.trainings : [],
				state.activePath,
				state.sectionCompletion
			),
			residencySectionPointsCheck(
				!!credObject.residency ? credObject.residency.trainings : [],
				state.activePath,
				state.sectionCompletion
			),
			fellowshipSectionPointsCheck(
				!!credObject.fellowship ? credObject.fellowship.trainings : [],
				state.activePath,
				state.sectionCompletion
			),
			hospitalAffiliationSectionPointsCheck(
				!!credObject.affiliation ? credObject.affiliation.workHistories : [],
				state.activePath,
				state.sectionCompletion
			),
			practiceExperienceSectionPointsCheck(
				!!credObject.practiceExperience ? credObject.practiceExperience.workHistories : [],
				state.activePath,
				state.sectionCompletion
			),
			additionalTrainingSectionPointsCheck(
				!!credObject.additionalTraining ? credObject.additionalTraining.trainings : [],
				state.activePath,
				state.sectionCompletion
			),
			documentLibraryStatusPointsCheck(externalId, state.sectionCompletion),
			referencesStatusPointsCheck(
				!!credObject.reference ? credObject.reference : [],
				state.sectionCompletion
			),
			licensureStatusPointsCheck(
				!!credObject.licensure ? credObject.licensure.licensures : [],
				state.sectionCompletion,
				state.profession,
				state.activePath
			),
			otherCertificationsStatusPointsCheck(
				!!credObject.certification ? credObject.certification.certifications : [],
				state.sectionCompletion
			),
			certificationExamsStatusPointsCheck(
				!!credObject.examination ? credObject.examination.examinations : [],
				state.sectionCompletion
			),
			boardCertificationsStatusPointsCheck(
				!!credObject.boardCertification
					? credObject.boardCertification.providerSpecialties
					: [],
				state.activePath,
				state.sectionCompletion,
				state.segment,
				!!credObject.boardCertification
					? credObject.boardCertification.boardCertified
					: null
			),
			malpracticeCarriersStatusPointsCheck(
				!!credObject.malpracticeCarrier ? credObject.malpracticeCarrier : [],
				state.sectionCompletion
			),
			malpracticeClaimsHistorySectionPointsCheck(
				credObject.malpracticeClaimHistory,
				state.activePath,
				state.sectionCompletion
			),
			gapsInTrainingSectionPointsCheck(
				!!credObject.trainingGap ? credObject.trainingGap.gaps : [],
				state.activePath,
				state.sectionCompletion
			),
			gapsInWorkHistorySectionPointsCheck(
				!!credObject.workGap ? credObject.workGap.gaps : [],
				state.activePath,
				state.sectionCompletion
			),
			militaryExperienceSectionPointsCheck(
				credObject.militaryExperience,
				state.sectionCompletion
			),
			disciplinaryActionQuestionsStatusPointsCheck(
				!!credObject.attestation ? credObject.attestation.questions : [],
				state.sectionCompletion
			),
			disclosureQuestionsStatusPointsCheck(
				!!credObject.attestation ? credObject.attestation.questions : [],
				state.sectionCompletion
			),
		]);
		state.sectionCompletion.personalInformation.current = personalInfoPoints;
		state.sectionCompletion.education.current = educationPoints;
		if (state.activePath === ACTIVE_PATHNAME_MD) {
			state.sectionCompletion.training.internship.current = internshipPoints;
			state.sectionCompletion.training.residency.current = residencyPoints;
			state.sectionCompletion.training.fellowship.current = fellowshipPoints;
			state.sectionCompletion.training.gapsInTraining.current = gapsInTrainingPoints;
		} else if (state.activePath === ACTIVE_PATHNAME_AP) {
			state.sectionCompletion.training.gapsInTraining.current = gapsInTrainingPoints;
			state.sectionCompletion.training.additionalTraining.current = additionalTrainingPoints;
		}
		state.sectionCompletion.certifications.boardCertifications.current =
			boardCertificationsPoints;
		state.sectionCompletion.certifications.otherCertifications.current =
			otherCertificationsPoints;
		state.sectionCompletion.certifications.certificationExams.current =
			certificationExamsPoints;
		state.sectionCompletion.certifications.licensure.current = licensurePoints;
		state.sectionCompletion.workHistory.hospitalAffiliations.current =
			hospitalAffiliationPoints;
		state.sectionCompletion.workHistory.practiceExperience.current = practiceExperiencePoints;
		state.sectionCompletion.workHistory.militaryExperience.current = militaryExperiencePoints;
		state.sectionCompletion.workHistory.gapsInWorkHistory.current = gapsinWorkHistoryPoints;
		state.sectionCompletion.references.current = referencesPoints;
		state.sectionCompletion.malpracticeInsurance.malpracticeClaimsHistory.current =
			malpracticeClaimsHistoryPoints;
		state.sectionCompletion.malpracticeInsurance.malpracticeCarriers.current =
			malpracticeCarriersPoints;
		state.sectionCompletion.attestationQuestions.disciplinaryActionQuestions.current =
			disciplinaryActionQuestionsPoints;
		state.sectionCompletion.attestationQuestions.disclosureQuestions.current =
			disclosureQuestionsPoints;
		state.sectionCompletion.documentLibrary.current = documentLibraryPoints;

		await this.setState({
			sectionCompletion: state.sectionCompletion,
		});
		if (!!state.statusObj) {
			const oldPerComplete = state.statusObj.credAppPercentComplete;
			state.statusObj.credAppPercentComplete = await this.getCurrentCompletionStatus(
				state.sectionCompletion
			);
			if (
				state.statusObj.id !== null &&
				oldPerComplete !== state.statusObj.credAppPercentComplete &&
				state.statusObj.credAppPercentComplete !== 0
			) {
				await patchPercentComplete(
					{
						clinicianId: `${externalId}`,
						credAppPercentComplete: state.statusObj.credAppPercentComplete,
					},
					externalId
				).catch((e) => {
					this.setCredAppError(e);
				});
			}
			this.setState({
				statusObj: state.statusObj,
			});
		}
		return state.sectionCompletion;
	}

	getCurrentCompletionStatus = async (sectionCompletion) => {
		const state = { ...this.state };

		const { externalId } = this.props.UserContext;

		const credObject = await getClinicianCredApp(externalId);

		const [
			internshipStatus,
			gapsInWorkHistoryStatus,
			gapsInTrainingStatus,
			militaryExperienceStatus,
			residencyStatus,
			fellowshipStatus,
			additionalTrainingStatus,
			hospitalAffiliationStatus,
			practiceExperienceStatus,
			otherCertificationsStatus,
			certificationExamsStatus,
			documentLibraryPoints,
		] = await Promise.all([
			internshipSectionCheck(
				!!credObject.internship ? credObject.internship.trainings : [],
				state.activePath
			),
			gapsInWorkHistorySectionCheck(
				!!credObject.workGap ? credObject.workGap.gaps : [],
				state.activePath
			),
			gapsInTrainingSectionCheck(
				!!credObject.trainingGap ? credObject.trainingGap.gaps : [],
				state.activePath
			),
			militaryExperienceSectionCheck(credObject.militaryExperience),
			residencySectionCheck(
				!!credObject.residency ? credObject.residency.trainings : [],
				state.activePath
			),
			fellowshipSectionCheck(
				!!credObject.fellowship ? credObject.fellowship.trainings : [],
				state.activePath
			),
			additionalTrainingSectionCheck(
				!!credObject.additionalTraining ? credObject.additionalTraining.trainings : [],
				state.activePath
			),
			hospitalAffiliationSectionCheck(
				!!credObject.affiliation ? credObject.affiliation.workHistories : [],
				state.activePath
			),
			practiceExperienceSectionCheck(
				!!credObject.practiceExperience ? credObject.practiceExperience.workHistories : [],
				state.activePath
			),
			otherCertificationsStatusCheck(
				!!credObject.certification ? credObject.certification.certifications : []
			),
			certificationExamsStatusCheck(
				!!credObject.examination ? credObject.examination.examinations : []
			),
			documentLibraryStatusPointsCheck(externalId, state.sectionCompletion),
		]);

		sectionCompletion.documentLibrary.current = documentLibraryPoints;
		let pointsPossible = 0;
		if (state.activePath === ACTIVE_PATHNAME_MD) {
			pointsPossible += 69;
			if (internshipStatus.status !== 'notProvided') {
				pointsPossible += 7;
			}
			if (residencyStatus.status !== 'notProvided') {
				pointsPossible += 7;
			}
			if (fellowshipStatus.status !== 'notProvided') {
				pointsPossible += 1;
			}
		} else if (state.activePath === ACTIVE_PATHNAME_AP) {
			pointsPossible += 79;
			if (additionalTrainingStatus.status !== 'notProvided') {
				pointsPossible += 4;
			}
		}

		if (hospitalAffiliationStatus.status !== 'notProvided') {
			pointsPossible += 4;
		}
		if (practiceExperienceStatus.status !== 'notProvided') {
			pointsPossible += 4;
		}
		if (otherCertificationsStatus !== 'notProvided') {
			pointsPossible += 3;
		}
		if (certificationExamsStatus.status !== 'notProvided') {
			pointsPossible += 2;
		}

		if (gapsInWorkHistoryStatus.status !== 'notProvided') {
			pointsPossible += 1;
		}
		if (gapsInTrainingStatus.status !== 'notProvided') {
			pointsPossible += 1;
		}
		if (militaryExperienceStatus.status !== 'notProvided') {
			pointsPossible += 1;
		}
		//let pointsPossible2 = 0;
		let pointsComplete = 0;
		for (const section in sectionCompletion) {
			if (sectionCompletion[section].hasOwnProperty('current')) {
				pointsComplete += sectionCompletion[section]?.current ?? 0;
			} else {
				for (const page in sectionCompletion[section]) {
					if (!(page === 'gapsInTraining' && state.activePath === ACTIVE_PATHNAME_AP)) {
						pointsComplete += sectionCompletion[section][page]?.current ?? 0;
					}
				}
			}
		}

		let percentComplete = Math.round((pointsComplete / pointsPossible) * 100);

		if (state.totalCompletion !== percentComplete) {
			this.setState({
				totalCompletion: percentComplete,
				totalCompletionKey: state.totalCompletionKey + 1,
			});
		}
		return percentComplete;
	};

	updateSectionCompletion = async (sectionCompletion) => {
		const state = { ...this.state };
		const { externalId } = this.props.UserContext;

		state.sectionCompletion = sectionCompletion;
		this.setState({ sectionCompletion: state.sectionCompletion });

		const oldComplete = state.totalCompletion;
		const perComplete = await this.getCurrentCompletionStatus(state.sectionCompletion);
		if (oldComplete !== perComplete) {
			state.statusObj.credAppPercentComplete = perComplete;
			this.setState({
				statusObj: state.statusObj,
			});

			await patchPercentComplete(
				{
					clinicianId: `${externalId}`,
					credAppPercentComplete: perComplete,
				},
				externalId
			).catch((e) => {
				this.setCredAppError(e);
			});
		}
	};

	getNursingSegment(profession) {
		if (!profession) return SEGMENT_NURSING;

		if (profession === 'PA') return SEGMENT_PA;
		if (profession === 'SFA') return SEGMENT_SFA;
		if (profession === 'Perfusionist') return SEGMENT_PERFUSIONIST;
		if (profession === 'Psychologist') return SEGMENT_ALLIED;
		return SEGMENT_NURSING;
	}

	//Setting currnet tab state based on selction of left side navigation link from SideNavigation Component
	//Getting menuObject from leftnav and fetching ID for button navigation
	setCurrentTabHandler = (menuItem) => {
		if (menuItem.id !== 0) {
			const { toggleSideNavDrawer, activePath, currentSelectedLinkId } = this.state;

			//This is to check if item is parent element or child element and set id
			const link =
				menuItem.parentUrlLink !== undefined ? menuItem.parentUrlLink : menuItem.link;
			const menuList =
				activePath === ACTIVE_PATHNAME_AP
					? menuItemListForNavigation_AP_path.data
					: menuListforNavigation.data;
			let obj = menuList.find((o) => o.parentUrlLink === link);
			const state = { ...this.state };
			state.flags.validationError = false;
			state.currentSelectedLinkId = obj.id - 1;
			state.toggleSideNavDrawer = !toggleSideNavDrawer;
			state.navigationFlag = true;
			state.oldLinkId = currentSelectedLinkId;
			state.flags.navDisabled = false;
			this.setState({ ...state });
		}
		//Setting state to current selected Tab from left nav
		//this.setState({ currentSelectedLinkId: obj.id - 1, toggleSideNavDrawer: !toggleSideNavDrawer })
	};

	setTabHandlerFromDialog = () => {
		this.setState({ currentSelectedLinkId: 1 });
	};
	setSaveHandler = (child, tabName, handler) => {
		const state = { ...this.state };
		state.childTab = child;
		state.childName = tabName;
		state.handleSubmit = handler;
		// Reset save flags
		state.flags.missingData = false;
		state.flags.dataSaved = false;
		state.flags.isDirty = false;
		state.flags.showSaveChanges = false;
		state.flags.isPendingSave = false;
		state.flags.errorMessage = null;
		state.flags.apiError = null;
		state.backButton = null;
		this.setState({ ...state });
	};

	updateFlags = (flags) => {
		console.log('Updating flags');
		this.setState({ flags: flags });
	};

	updateHighlightFieldsState = async (value, item) => {
		console.log(`Updating shouldHighlightFields: ${value}`);
		await this.setState({ shouldHighlightFields: value });
		if (!!item) {
			this.setCurrentTabHandler(item);
			this.sideNavdrawerToggle();
		}
	};

	getStarted = async (profession) => {
		const { history } = this.props;
		const { externalId, account } = this.props.UserContext;
		const state = { ...this.state };
		state.personalInformationObj.profession = profession;
		state.profession = profession;
		const activePath = PROFESSIONS_MD.includes(profession)
			? ACTIVE_PATHNAME_MD
			: ACTIVE_PATHNAME_AP;
		let segment = SEGMENT_MEDICAL;
		if (activePath === ACTIVE_PATHNAME_AP) {
			segment = this.getNursingSegment(profession);
		}

		state.activePath = activePath;
		state.segment = segment;
		state.redirectFlag = false;
		//this.setState({ ...state })
		this.setState({
			personalInformationObj: state.personalInformationObj,
			profession: state.profession,
			activePath: state.activePath,
			segment: state.segment,
			redirectFlag: state.redirectFlag,
		});
		await postPersonalInformation(state.personalInformationObj, externalId);

		appInsights.trackEvent({
			name: 'CredAppStarted',
			properties: {
				externalId: externalId,
				b2cId: account.accountIdentifier,
			},
		});

		const dto = { clinicianId: externalId }; // All that is needed to start
		await Promise.all([
			postPersonalInformation(state.personalInformationObj, externalId),
			postCredAppStatus(externalId, dto),
		])
			.then(async () => {
				if (this.showHideCredOverviewPage()) {
					history.push('/credentials/overview');
				} else {
					history.push('/credentials/personalInformation');
				}

				await this.LoadAppFor(externalId);
			})
			.catch((e) => {
				this.setCredAppError(e);
			});
	};
	async updateTitles(values) {
		setTimeout(() => {
			this.setState({ titles: values });
		}, 0);
	}

	setCredAppError = (e) => {
		const { flags, totalCompletionKey, sectionCompletion } = { ...this.state };
		flags.isError = true;
		if (e.hasOwnProperty('title') && e.hasOwnProperty('message')) {
			flags.apiError = e;
		} else {
			flags.errorMessage = 'Error loading page';
		}
		flags.isLoading = false;
		flags.isPendingSave = false;
		//const newTotalCompletion = this.getCurrentCompletionStatus(sectionCompletion);
		this.getCurrentCompletionStatus(sectionCompletion).then((response) => {
			this.setState({ isLoading: false });
			this.setState({
				flags: flags,
				totalCompletion: response,
				totalCompletionKey: totalCompletionKey + 1,
			});
		});
	};

	blockedBackNavigation = () => {
		const { backButton, oldLinkId } = this.state;
		if (!!backButton) {
			backButton.blockedBackNavigation();
			this.setState({ backButton: null });
		} else {
			this.setState({ currentSelectedLinkId: oldLinkId, navigationFlag: true });
		}
	};

	pushBackToOverview = () => {
		const { history } = this.props;

		history.push('/credentials/overview');
	};

	handleBackButtonActions = (backButton) => {
		const state = { ...this.state };
		state.flags.validationError = false;
		state.backButton = backButton;
		this.setState({ ...state });
	};

	async onCallSubmit() {
		const state = { ...this.state };
		const { externalId, account } = this.props.UserContext;
		const status = state.credAppStatus;
		const credType = state.credType;

		if (!state.handleSubmit || !state.childTab) {
			state.flags.dataSaved = true; // no save method
			state.flags.validationError = false;
			state.flags.isPendingSave = false;
			state.totalCompletion = await this.getCurrentCompletionStatus(state.sectionCompletion);
			state.totalCompletionKey++;
		} else {
			if (!!state.childName) {
				appInsights.trackEvent({
					name: 'CredAppSave',
					properties: {
						tab: state.childName,
						externalId: externalId,
						b2cId: account.accountIdentifier,
					},
				});
			}
			state.flags.isPendingSave = true;
			this.setState({ flags: state.flags });

			console.log('calling childTab.handleSubmit');
			try {
				await state.handleSubmit.call(state.childTab);
			} catch (error) {
				appInsights.trackException({
					exception: error,
					properties: {
						method: 'Credentials.onCallSubmit',
						severityLevel: SeverityLevel.Error,
					},
				});
				console.log('error=' + JSON.stringify(error));
				// error is an ApiError
				if (error.hasOwnProperty('title') && error.hasOwnProperty('message')) {
					state.flags.apiError = error;
				} else {
					state.flags.errorMessage = error;
				}
			}
			state.flags.isPendingSave = false;
			state.totalCompletion = await this.getCurrentCompletionStatus(state.sectionCompletion);
			state.totalCompletionKey++;
		}
		if (
			credType === 'Re-cred' &&
			status === 'App Sent' &&
			(state.flags.dataSaved === true || state.flags.isPendingSave === false)
		) {
			let dto = { clinicianId: externalId, status: 'Application Started' };
			let result = null;
			result = await patchCredAppStatus(externalId, dto).catch((e) => {
				this.setCredAppError(e);
			});
			if (result && result.statusText === 'OK') {
				state.credAppStatus = 'Application Started';
				appInsights.trackEvent({
					name: 'ReCredAppStarted',
					properties: {
						externalId: externalId,
						b2cId: account.accountIdentifier,
					},
				});
				this.setState({
					credAppStatus: state.credAppStatus,
				});
			}
		}

		this.setState({
			flags: state.flags,
			totalCompletion: state.totalCompletion,
			totalCompletionKey: state.totalCompletionKey,
		});
		return state.flags.dataSaved;
	}

	closeError = () => {
		const state = { ...this.state };
		state.flags.apiError = null;
		//state.flags.isError = false;
		this.setState({ ...state });
	};

	showHideCredOverviewPage = () => {
		const { credType, credAppStatus, redirectFlag } = this.state;
		if (redirectFlag === false) {
			this.setState({ redirectFlag: true });
		}

		if (
			credType === CRED_TYPE.RECRED ||
			(credType === CRED_TYPE.INITIAL &&
				credAppStatus !== CRED_STATUS.ApplicationSent &&
				credAppStatus !== CRED_STATUS.ApplicationStarted &&
				credAppStatus !== CRED_STATUS.PermProvider)
		) {
			return true;
		} else {
			return false;
		}
	};

	render() {
		const { classes, t } = this.props;
		const {
			hasCredRecord,
			activePath,
			segment,
			flags,
			titles,
			toggleSideNavDrawer,
			profession,
			credState,
			credType,
			sectionCompletion,
			credAppStatus,
			shouldHighlightFields,
		} = { ...this.state };

		if (this.state.isLoading && !flags.apiError) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		} else {
			if (
				(!hasCredRecord ||
					profession === null ||
					credAppStatus === CRED_STATUS.Expired ||
					credAppStatus === CRED_STATUS.Expiring) &&
				!flags.isError
			) {
				return (
					<>
						<AppInsightsErrorBoundary
							onError={() => (
								<h1>I believe something went wrong with Start Credentials</h1>
							)}
							appInsights={reactPlugin}
						>
							<StartCredentials
								initialProfession={profession}
								getStarted={this.getStarted}
							/>
						</AppInsightsErrorBoundary>
					</>
				);
			} else {
				return (
					<AppInsightsErrorBoundary
						onError={() => <h1>I believe something went wrong with Credentials</h1>}
						appInsights={reactPlugin}
					>
						<div className={classes.root}>
							<ErrorDialog
								open={!!flags.apiError}
								onClose={this.closeError}
								title={!flags.apiError ? null : flags.apiError.title}
								description={!flags.apiError ? null : flags.apiError.message}
								errorId={!flags.apiError ? null : flags.apiError.errorId}
							/>
							<Container maxWidth='xl' className={classes.containerStyle}>
								<Grid item xs={12}>
									<Grid container justify='space-evenly'>
										<Hidden only={['md', 'lg', 'xl']}>
											<Grid
												classes={{ root: classes.gridControl }}
												item
												xs={6}
											>
												{toggleSideNavDrawer ? null : (
													<span
														className={classes.credAnchorTag}
														onClick={this.sideNavdrawerToggle}
													>
														{' '}
														{t(
															'credentials:CREDENTIALS.YOUR_APPLICATION'
														)}
													</span>
												)}
											</Grid>
											<Grid
												classes={{ root: classes.gridCircularProgress }}
												item
												xs={4}
											>
												{toggleSideNavDrawer ? null : (
													<div
														key={`mobilePercent-${this.state.totalCompletionKey}`}
													>
														<CustomCircularProgress
															size={50}
															thickness={5}
															progressType='mobile'
															progress={this.state.totalCompletion}
														/>
													</div>
												)}
											</Grid>
											<Drawer
												variant='persistent'
												classes={{ paper: classes.drawerPaper }}
												open={toggleSideNavDrawer}
											>
												<span
													className={classes.credAnchorTagClosure}
													onClick={() => this.sideNavdrawerToggle()}
												>
													<Close
														style={{
															position: 'relative',
															top: '6px',
															fontSize: '20px',
														}}
													/>
													{t('credentials:CREDENTIALS.RETURN_TO_APP')}
												</span>
												<SideNavigation
													tabHandler={this.setCurrentTabHandler}
													activePath={activePath}
													showHideCredOverviewPage={
														this.showHideCredOverviewPage
													}
												/>
											</Drawer>
										</Hidden>
										<Hidden only={['xs', 'sm']}>
											<Grid item xs={12} md={3}>
												<Paper
													classes={{ root: classes.sidebarRoot }}
													square
												>
													<Grid
														container
														classes={{
															root: classes.sidePanelContainerRoot,
														}}
													>
														<Grid
															item
															xs={12}
															classes={{
																root: classes.circularProgressHeader,
															}}
														>
															<div
																key={`desktopPercent-${this.state.totalCompletionKey}`}
															>
																<CustomCircularProgress
																	size={120}
																	thickness={6}
																	progressType='desktop'
																	progress={
																		this.state.totalCompletion
																	}
																/>
															</div>
														</Grid>
														<Grid
															item
															xs={12}
															classes={{
																root: classes.helpPanelLinks,
															}}
														>
															<SideNavigation
																tabHandler={
																	this.setCurrentTabHandler
																}
																activePath={activePath}
																showHideCredOverviewPage={
																	this.showHideCredOverviewPage
																}
															/>
														</Grid>
													</Grid>
												</Paper>
											</Grid>
										</Hidden>

										<Grid
											item
											xs={12}
											md={9}
											classes={{ root: classes.credentialsContent }}
										>
											<Paper
												classes={{ root: classes.contentRoot }}
												elevation={2}
												square
											>
												<Grid
													container
													classes={{
														root: classes.mainPanelContainerRoot,
													}}
												>
													<Switch>
														<Route exact path='/credentials'>
															{this.state.redirectFlag && (
																<Redirect
																	to={
																		this.showHideCredOverviewPage()
																			? '/credentials/overview'
																			: '/credentials/personalInformation'
																	}
																/>
															)}
														</Route>
													</Switch>
													<Switch>
														<Route
															exact
															path='/credentials/overview'
															render={(props) => (
																<CredentialsOverview
																	key={'Overview'}
																	activePath={activePath}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	updateTitles={this.updateTitles}
																	profession={profession}
																	credState={credState}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															exact
															path='/credentials/personalInformation'
															render={(props) => (
																<PersonalInformation
																	key={'PersonalInformation'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	updateTitles={this.updateTitles}
																	setCredAppError={
																		this.setCredAppError
																	}
																	profession={profession}
																	credState={credState}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	setTabHandlerFromDialog={
																		this.setTabHandlerFromDialog
																	}
																/>
															)}
														></Route>

														<Route
															exact
															path='/credentials/documents'
															render={(props) => (
																<Documents
																	key={'Documents'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	credState={credState}
																	updateFlags={this.updateFlags}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																/>
															)}
														></Route>

														<Route
															path='/credentials/education'
															render={(props) => (
																<Education
																	segment={segment}
																	titles={titles}
																	key={'Education'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/references'
															render={(props) => (
																<References
																	key={'References'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	credType={credType}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/checkStatusAndSign'
															render={(props) => (
																<CheckStatusAndSign
																	key={'CheckStatusAndSign'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	titles={titles}
																	segment={segment}
																	credState={credState}
																	credAppStatus={credAppStatus}
																	tabHandler={
																		this.setCurrentTabHandler
																	}
																	profession={profession}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																	closeError={this.closeError}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	sectionCompletion={
																		sectionCompletion
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/training/internship'
															render={(props) => (
																<Internship
																	key={'Internship'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/training/residency'
															render={(props) => (
																<Residency
																	key={'Residency'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/training/fellowship'
															render={(props) => (
																<Fellowship
																	key={'Fellowship'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/training/gapsInTraining'
															render={(props) => (
																<GapsInTraining
																	key={'GapsInTraining'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/training/additionalTraining'
															render={(props) => (
																<AdditionalTraining
																	key={'AdditionalTraining'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/certifications/boardCertifications'
															render={(props) => (
																<BoardCertifications
																	key={'BoardCertifications'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	segment={segment}
																	titles={titles}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/certifications/otherCertifications'
															render={(props) => (
																<OtherCertifications
																	key={'OtherCertifications'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/certifications/certificationExams'
															render={(props) => (
																<CertificationExams
																	key={'CertificationExams'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/licensure'
															render={(props) => (
																<Licensure
																	key={'Licensure'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	titles={titles}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	profession={profession}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>

														<Route
															path='/credentials/workhistory/hospitalAffiliation'
															render={(props) => (
																<HospitalAffiliation
																	key={'HospitalAffiliation'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/workhistory/practiceExperience'
															render={(props) => (
																<PracticeExperience
																	key={'PracticeExperience'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/workhistory/militaryExperience'
															render={(props) => (
																<MilitaryExperience
																	key={'MilitaryExperience'}
																	UserContext={
																		this.props.UserContext
																	}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/workhistory/gapsinWorkHistory'
															render={(props) => (
																<GapsInWorkHistory
																	key={'GapsInWorkHistory'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>

														<Route
															path='/credentials/malpracticeinsurance/malpracticeClaimsHistory'
															render={(props) => (
																<MalpracticeClaimsHistory
																	key={'MalpracticeClaimsHistory'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/malpracticeinsurance/malpracticeCarriers'
															render={(props) => (
																<MalpracticeCarriers
																	key={'MalpracticeCarriers'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>

														<Route
															path='/credentials/attestationquestions/disciplinaryActionQuestions'
															render={(props) => (
																<DisciplinaryActionQuestions
																	key={
																		'DisciplinaryActionQuestions'
																	}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
														<Route
															path='/credentials/attestationquestions/disclosureQuestions'
															render={(props) => (
																<DisclosureQuestions
																	key={'DisclosureQuestions'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	updateFlags={this.updateFlags}
																	credState={credState}
																	sectionCompletion={
																		sectionCompletion
																	}
																	updateSectionCompletion={
																		this.updateSectionCompletion
																	}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																	setCredAppError={
																		this.setCredAppError
																	}
																/>
															)}
														></Route>
													</Switch>
												</Grid>
												<Grid
													item
													xs={12}
													md={12}
													classes={{
														root: classes.navigationButtonWrapper,
													}}
												>
													<NavigationButton
														menuNavigation={
															activePath === ACTIVE_PATHNAME_AP
																? menuItemListForNavigation_AP_path.data
																: menuListforNavigation.data
														}
														currentSelectedLinkId={
															this.state.currentSelectedLinkId
														}
														parent={this}
														flags={flags}
														hasDataToSave={!!this.state.handleSubmit}
														onSave={this.onCallSubmit}
														handleBackButtonActions={
															this.handleBackButtonActions
														}
														getSelectdLinkId={this.getSelectdLinkId}
														navigationFlag={this.state.navigationFlag}
													/>
												</Grid>
											</Paper>
										</Grid>
									</Grid>
								</Grid>
							</Container>
						</div>
					</AppInsightsErrorBoundary>
				);
			}
		}
	}
}

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(CredentialsV1))));
