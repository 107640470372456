import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useForm, useFieldArray } from 'react-hook-form';
import AttestationQuestion from './AttestationQuestion';
import { CircularProgress } from '@material-ui/core';
import { COLORS, DOCUMENT_TYPE, CRED_STATE } from '../../../../../utils/Application_Constants';
import {
	getDisclosureQuestions,
	postDisclosureQuestions,
} from '../../../../../services/Credentialing';
import UploadClinicianDocument from '../DocumentComponents/UploadClinicianDocument';
import UploadClinicianDocumentV2 from '../DocumentComponents/UploadClinicianDocumentV2';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	disciplinaryActionQuestionsStatusPointsCheck,
	disclosureQuestionsStatusPointsCheck,
	disclosureQuestionsStatusCheck,
	disciplinaryActionQuestionsStatusCheck,
} from '../../../../../utils/CredentialingAppUtility';
import { compareEntryStrings } from '../../../../../utils/helpers';
import Alert from '@material-ui/lab/Alert';
import FeatureFlag from '../../../../GlobalComponents/FeatureFlagComponent/FeatureFlag';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '10px',
		paddingBottom: '0px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
		paddingLeft: '30px',
	},
	secondaryHeaderArea: {
		// alignItems: 'center',
		// maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '46px',
		// marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	form: {
		width: '100%',
		margin: theme.spacing(1, 0, 5),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	// inputLabel: {
	//     top: '5px',
	//     position: 'relative',
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     textTransform: 'uppercase',
	// },
	// selectInputLabel: {
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	// },
	// cssOutlinedInput: {
	//     '&$cssFocused $notchedOutline': {
	//         borderColor: `${COLORS.RAVEN}`,
	//         borderWidth: 1,
	//         borderRadius: 1,
	//     },
	//     fontSize: '16px',
	//     color: COLORS.RAVEN,
	//     padding: '18.5px 14px',
	//     '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //default
	//     },
	//     '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //hovered
	//     },
	// },

	cssFocused: {},
	disabled: {},
	focused: {},
	marginLeft12: {
		marginLeft: 12,
	},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	noTopPad: {
		paddingTop: '0 !important',
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	radioGroup: {
		marginLeft: '8px',
	},
	// inputRadio: {
	//     color: COLORS.RAVEN,
	//     '&.Mui-focused': {
	//         color: COLORS.RAVEN
	//     },
	//     textAlign: 'left',
	//     fontWeight: '800',
	//     textTransform: 'uppercase',
	//     lineHeight: '20px',
	//     marginTop: theme.spacing(-1),
	// },
	setWidthSelectGroup: {
		top: '-23px',
		width: 'calc(100% - 1px)',
		marginTop: '10px',
		marginRight: '10px',
	},
	setWidthSelects: {
		minWidth: '100%',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	selectContainerOffset: {
		padding: '0 16px 16px 16px !important',
	},
	selectContainer: {
		padding: '3px !important',
	},
	selectContainerGroup: {
		[theme.breakpoints.down('sm')]: {
			padding: '3px 10px 0px 10px !important',
		},
		minWidth: '97%',
	},
	multiGridOffset: {
		paddingTop: '13px !important',
	},
	leftPad30: {
		paddingLeft: '30px !important',
	},
	// instructions: {
	//     paddingTop: '6px',
	//     paddingBottom: '6px',
	//     paddingLeft: '10px',
	//     textAlign: 'left',
	// },
	uploadComponent: {
		paddingLeft: '30px !important',
		paddingBottom: '30px',
	},
	containerSM: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% + 13px) !important',
		},
	},
	marginBottom20Neg: {
		marginBottom: -20,
	},
	headerText: {
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '32px',
		paddingTop: '16px',
		fontSize: '14px',
		fontWeight: '500',
		color: COLORS.RAVEN,
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingBottom: '0px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
}));

function AttestationQuestionsForm({
	myQuestions,
	description,
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	setCredAppError,
	flags,
	updateFlags,
	activePath,
	credState,
	constantType,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [origValues, setOrigValues] = useState(null);

	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);

	const { register, control, handleSubmit, errors, getValues, reset, formState, watch } = useForm(
		{
			mode: 'all',
			reValidateMode: 'onChange',
			defaultValues: {},
		}
	);
	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			setSaveHandler(
				AttestationQuestionsForm,
				'Attestation Questions',
				handleSubmit(async (data) => await onSubmit(data))
			);

			await getDisclosureQuestions(externalId)
				.then(function (data) {
					const { questions = [] } = data;

					const origValues = [];
					questions.forEach((item) => {
						origValues.push({
							Question: item.Question,
							Answer: item.Answer,
							Explanation: item.Explanation,
						}); // push copies of original values
					});
					setOrigValues(origValues);

					myQuestions.forEach((item, index) => {
						let found = false;
						questions.map((question) => {
							if (item === question.Question) {
								found = true;
								if (question.Explanation === null) {
									question.Explanation = '';
								}
								insert(index, question);
							}
						});
						if (found === false) {
							insert(index, {
								Question: item,
								Answer: null,
								Explanation: null,
							});
						}
						return null; // make lint happy
					});
					console.log('Value of getValue is type: ' + getValues());
					//purge questions since I just constructed the data manually to preserve sort from myQuestions
					// data[`questions`] = [];

					updateSectionCompletion(sectionCompletion);
					flags.dataSaved = false;
					updateFlags(flags);
					// reset(data);
					setIsLoaded(true);
					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status = {
							status: 'incomplete',
							missingFields: [],
						};

						if (constantType === 'DISCIPLINARY_QUESTIONS') {
							status = disciplinaryActionQuestionsStatusCheck(questions);
						} else if (constantType === 'DISCLOSURE_QUESTIONS') {
							status = disclosureQuestionsStatusCheck(questions);
						}

						if (!!status.missingFields) {
							setHighlights(status.missingFields);
						}
					}

					console.log('response is', data);
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);
	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, insert } = useFieldArray({
		control,
		name: 'questions',
	});

	function updateHighlight() {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};
			if (constantType === 'DISCIPLINARY_QUESTIONS') {
				status = disciplinaryActionQuestionsStatusCheck(watch('questions'));
			} else if (constantType === 'DISCLOSURE_QUESTIONS') {
				status = disclosureQuestionsStatusCheck(watch('questions'));
			}
			setHighlights(status.missingFields);
		}
	}

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);

		if (!flags.dataSaved) {
			if (origValues !== null) {
				const questions = watch('questions');
				let isDirty = false;
				if (!!questions) {
					questions.forEach((item) => {
						const orig = origValues.find((v) => {
							if (v.Question === item.Question) return v;
						});
						if (!orig) {
							if (!!item.Answer) isDirty = true;
						} else if (
							!compareEntryStrings(orig?.Answer, item?.Answer) ||
							!compareEntryStrings(orig?.Explanation, item?.Explanation)
						) {
							isDirty = true;
						}
					});
				}
				if (flags.isDirty !== isDirty) {
					flags.isDirty = true;
				}
			}
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('questions')) {
			for (let i = 0; i < errors.questions.length; i++) {
				if (!!errors?.questions[i]) {
					missingRequiredToSaveFields = Object.values(errors.questions[i]).filter(
						(anError) => {
							return anError.hasOwnProperty('type');
						}
					);
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	const DocTypes = [
		{
			value: DOCUMENT_TYPE.CourtBoardDocuments,
			label: DOCUMENT_TYPE.CourtBoardDocuments,
		},
		{
			value: DOCUMENT_TYPE.Explanation,
			label: DOCUMENT_TYPE.Explanation,
		},
		{
			value: DOCUMENT_TYPE.Claims,
			label: DOCUMENT_TYPE.Claims,
		},
	];

	const classes = styles();

	async function onSubmit(data) {
		let success = false;

		const { externalId } = UserContext;
		data['clinicianId'] = externalId;
		data[`questions`] = data[`questions`].filter(Boolean);
		data[`questions`] = data[`questions`].filter((item) => item.Answer !== '');

		console.log('before json', data);
		const jsonData = JSON.stringify(data);
		console.log('post payload is:', jsonData);
		await postDisclosureQuestions(externalId, jsonData);
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;

		if (constantType === 'DISCIPLINARY_QUESTIONS') {
			sectionCompletion.attestationQuestions.disciplinaryActionQuestions.current =
				await disciplinaryActionQuestionsStatusPointsCheck(
					data[`questions`],
					sectionCompletion
				);
			updateSectionCompletion(sectionCompletion);
		} else if (constantType === 'DISCLOSURE_QUESTIONS') {
			sectionCompletion.attestationQuestions.disclosureQuestions.current =
				await disclosureQuestionsStatusPointsCheck(data[`questions`], sectionCompletion);
			updateSectionCompletion(sectionCompletion);
		}

		updateFlags(flags);
		return success;
	}

	const renderQuestion = (item, index) => {
		return (
			<AttestationQuestion
				t={t}
				myQuestions={myQuestions}
				constantType={constantType}
				errors={errors}
				watch={watch}
				register={register}
				activePath={activePath}
				credState={credState}
				item={item}
				index={index}
				highlights={highlights}
				updateHighLight={updateHighlight}
			/>
		);
	};

	const renderForm = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}
		return (
			<div className={classes.profileWrapper}>
				<Grid container>
					<div>
						<Grid
							container
							item
							md={12}
							spacing={4}
							className={classes.secondaryHeaderArea}
						>
							<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
								{
									<RouteDeparturePrompt
										blockedBackNavigation={blockedBackNavigation}
										when={!flags.dataSaved ? !!flags.isDirty : false}
									/>
								}
								<Grid container spacing={4}>
									{fields.map((item, index) => renderQuestion(item, index))}
								</Grid>
							</form>
						</Grid>
					</div>
				</Grid>
			</div>
		);
	};

	return (
		<div>
			<Grid className={classes.leftPad30}>
				<Grid item xs={12}>
					<h3>{t(`credentials:${constantType}.TITLE`)}</h3>
				</Grid>
				{credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN ? (
					<Grid item xs={12} md={10} className={classes.messageGrid}>
						<Alert severity='error' className={classes.alertMessage} icon={false}>
							{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
						</Alert>
					</Grid>
				) : null}
				<Grid item xs={12}>
					<p>{t(`credentials:${constantType}.INFO_MESSAGE`)}</p>
				</Grid>

				{!description ? null : (
					<p
						className={classes.instructions}
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				)}
				{renderForm()}
			</Grid>
			{isLoaded ? (
				<Grid className={classes.leftPad30}>
					<Grid className={classes.UploaderHeaderArea}>
						<Grid item xs={12} classes={{ root: classes.secondaryHeader }}>
							Upload Documents
						</Grid>
					</Grid>
					<Grid item xs={12} className={classes.uploadComponent}>
						<FeatureFlag name='EnableHasSharepointDocs' isFlagEnabled='true'>
							<UploadClinicianDocumentV2
								docTypeList={DocTypes}
								showDocuments={true}
								credState={credState}
							/>
						</FeatureFlag>
						<FeatureFlag name='EnableHasSharepointDocs' isFlagEnabled='false'>
							<UploadClinicianDocument
								docTypeList={DocTypes}
								showDocuments={true}
								credState={credState}
							/>
						</FeatureFlag>
					</Grid>
				</Grid>
			) : null}
		</div>
	);
}

export default AttestationQuestionsForm;
