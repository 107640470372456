import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { COLORS } from '../../utils/Application_Constants';

const ToolTipComponent = withStyles({
	tooltip: {
		color: COLORS.WHITE,
		backgroundColor: COLORS.TRUE_BLUE,
		fontSize: '12px',
		borderRadius: 0,
		width: '250px',
		padding: '10px',
	},
})(Tooltip);

export default ToolTipComponent;
