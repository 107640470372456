import moment from 'moment-timezone';

const ics = require('ics');

const statusMapper = new Map([
	['Booked', 'CONFIRMED'],
	['Cancelled', 'CANCELLED'],
]);

function dateConvertor(date) {
	const newDate = new Date(date);
	const year = moment(newDate).utc().format('YYYY');
	const month = moment(newDate).utc().format('MM');
	const day = moment(newDate).utc().format('DD');
	const hour = moment(newDate).utc().format('H');
	const minute = moment(newDate).utc().format('m');
	return [year, month, day, hour, minute];
}

function generateLocationString(location, address) {
	return `${location} 
  ${address.addressLine1 || ''} 
  ${address.addressLine2 || ''}
  ${address.city || ''}, ${address.state || ''} 
  ${address.zip || ''}`;
}

function constructEvent(start, end, location, url, id, address, status) {
	const newEvent = {
		start: dateConvertor(start),
		end: dateConvertor(end),
		location: generateLocationString(location, address),
		url,
		uid: id,
		title: location,
		startInputType: 'utc',
		endInputType: 'utc',
	};
	if (!status) {
		console.warn('Booking currently has no status.');
		return newEvent;
	}
	newEvent.status = statusMapper.get(status);
	return newEvent;
}

function generateMultipleIcsEvents(shifts, location, url, address) {
	const formattedShifts = [];
	shifts.forEach((item) => {
		const { startDateTime, endDateTime, id, status } = item;
		formattedShifts.push(
			constructEvent(startDateTime, endDateTime, location, url, id, address, status)
		);
	});
	return ics.createEvents(formattedShifts, (error, value) => {
		if (error) {
			Error(error);
		}
		window.open(`data:text/calendar;charset=utf8,${escape(value)}`);
	});
}

function generateSingleIcsEvent(shifts, location, url, address) {
	const { startDateTime, endDateTime, id, status } = shifts[0];
	const event = constructEvent(startDateTime, endDateTime, location, url, id, address, status);
	return ics.createEvent(event, (error, value) => {
		if (error) {
			Error(error);
		}
		window.open(`data:text/calendar;charset=utf8,${escape(value)}`);
	});
}

function generateIcsEvent(shifts, location, url, address) {
	if (shifts.length === 1) {
		return generateSingleIcsEvent(shifts, location, url, address);
	}
	return generateMultipleIcsEvents(shifts, location, url, address);
}

export default generateIcsEvent;
