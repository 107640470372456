import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, Button } from '@material-ui/core';

const styles = (theme) => ({
	navigationButtonWrapper: {
		display: 'flex',
		justifyContent: 'flex-satrt',
	},
	navigationButton: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
			marginRight: '30px',
			width: '300px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '10px',
			marginRight: '10px',
			width: '140px',
			fontSize: '10px',
		},
	},
	error: {
		color: 'red',
		fontWeight: 'bold',
	},
	buttonBlock: {
		display: 'block',
	},
	buttonNone: {
		display: 'none',
	},
});

class NavigationButton extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			index: 0,
			disabledNext: false,
			disabledPrev: true,
			prevbtnLabel: '',
			prevRedirectURL: '',
			nextRedirectURL: '',
			stateBeforeBack: null,
		};
	}

	componentDidUpdate(prevProps) {
		const { currentSelectedLinkId, onSave } = this.props;
		if (prevProps.currentSelectedLinkId !== this.props.currentSelectedLinkId) {
			const state = { ...this.state };
			state.onSave = onSave;
			if (currentSelectedLinkId === 0) {
				let nextRedirectURL =
					this.props.menuNavigation[currentSelectedLinkId + 1].parentUrlLink;
				state.index = currentSelectedLinkId;
				state.disabledPrev = true;
				state.nextRedirectURL = nextRedirectURL;
				state.disabledNext = false;
				this.setState({ ...state });
			} else {
				let disabledNext = currentSelectedLinkId === this.props.menuNavigation.length - 1;
				state.disabledNext = disabledNext;
				state.disabledPrev = false;
				state.index = currentSelectedLinkId;
				let prevRedirectURL =
					this.props.menuNavigation[currentSelectedLinkId - 1].parentUrlLink;
				let prevLabel = this.props.menuNavigation[currentSelectedLinkId - 1].name;
				state.prevbtnLabel = prevLabel;
				state.prevRedirectURL = prevRedirectURL;
				let nextRedirectURL = disabledNext
					? ''
					: this.props.menuNavigation[currentSelectedLinkId + 1].parentUrlLink;
				state.nextRedirectURL = nextRedirectURL;
				this.setState({ ...state });
			}
		}

		if (prevProps.navigationFlag !== this.props.navigationFlag) {
			const state = { ...this.state };
			state.onSave = onSave;
			if (currentSelectedLinkId === 0) {
				let nextRedirectURL =
					this.props.menuNavigation[currentSelectedLinkId + 1].parentUrlLink;
				state.index = currentSelectedLinkId;
				state.disabledPrev = true;
				state.nextRedirectURL = nextRedirectURL;
				state.disabledNext = false;
				this.setState({ ...state });
			} else {
				let disabledNext = currentSelectedLinkId === this.props.menuNavigation.length - 1;
				state.disabledNext = disabledNext;
				state.disabledPrev = false;
				state.index = currentSelectedLinkId;
				let prevRedirectURL =
					this.props.menuNavigation[currentSelectedLinkId - 1].parentUrlLink;
				let prevLabel = this.props.menuNavigation[currentSelectedLinkId - 1].name;
				state.prevbtnLabel = prevLabel;
				state.prevRedirectURL = prevRedirectURL;
				let nextRedirectURL = disabledNext
					? ''
					: this.props.menuNavigation[currentSelectedLinkId + 1].parentUrlLink;
				state.nextRedirectURL = nextRedirectURL;
				this.setState({ ...state });
			}
		}
	}

	async componentDidMount() {
		const { currentSelectedLinkId, onSave } = this.props;
		const state = { ...this.state };
		state.onSave = onSave;
		if (currentSelectedLinkId === 0) {
			let nextRedirectURL =
				this.props.menuNavigation[currentSelectedLinkId + 1].parentUrlLink;
			state.index = currentSelectedLinkId;
			state.disabledNext = false;
			state.disabledPrev = true;
			state.nextRedirectURL = nextRedirectURL;
			this.setState({ ...state });
		} else {
			let disabledNext = currentSelectedLinkId === this.props.menuNavigation.length - 1;
			state.disabledPrev = false;
			state.disabledNext = disabledNext;
			state.index = currentSelectedLinkId;
			let prevRedirectURL =
				this.props.menuNavigation[currentSelectedLinkId - 1].parentUrlLink;
			let prevLabel = this.props.menuNavigation[currentSelectedLinkId - 1].name;
			state.prevbtnLabel = prevLabel;
			state.prevRedirectURL = prevRedirectURL;
			let nextRedirectURL = disabledNext
				? ''
				: this.props.menuNavigation[currentSelectedLinkId + 1].parentUrlLink;
			state.nextRedirectURL = nextRedirectURL;
			this.setState({ ...state });
		}
	}

	getPathUrl() {
		const { pathname } = this.props.location;
		return pathname;
	}
	togglePrev(e) {
		this.setState({ stateBeforeBack: null });
		const oldState = { ...this.state };
		const { history, handleBackButtonActions } = this.props;
		handleBackButtonActions(this);
		let index = this.state.index - 1;
		let disabledPrev = index === 0;
		let prevRedirectURL = this.props.menuNavigation[index].parentUrlLink;
		let nextRedirectURL = this.props.menuNavigation[index + 1].parentUrlLink;
		if (index !== 0) {
			let prevLabel = this.props.menuNavigation[index - 1].name;
			this.setState({ prevbtnLabel: prevLabel });
		}
		this.setState({
			index: index,
			disabledPrev: disabledPrev,
			disabledNext: false,
			prevRedirectURL: prevRedirectURL,
			nextRedirectURL: nextRedirectURL,
			stateBeforeBack: oldState,
		});
		history.push(prevRedirectURL);
	}

	blockedBackNavigation() {
		const oldState = this.state.stateBeforeBack;
		if (!!oldState) {
			this.setState({
				index: oldState.index,
				prevbtnLabel: oldState.prevbtnLabel,
				disabledNext: oldState.disabledNext,
				disabledPrev: oldState.disabledPrev,
				nextRedirectURL: oldState.nextRedirectURL,
				prevRedirectURL: oldState.prevRedirectURL,
				stateBeforeBack: null,
			});
		}
	}

	async toggleNext(e) {
		await this.props.getSelectdLinkId();
		const { onSave, parent } = this.props;
		let didSave = false;
		if (onSave) {
			await Promise.all([onSave.call(parent)]).then(([retVal]) => {
				didSave = retVal;
			});
		}
		if (didSave) {
			const { history } = this.props;
			let index = this.state.index + 1;
			let disabledNext = index === this.props.menuNavigation.length - 1;
			let nextRedirectURL = this.props.menuNavigation[index].parentUrlLink;
			let prevRedirectURL = this.props.menuNavigation[index - 1].parentUrlLink;
			if (index !== 0) {
				let prevLabel = this.props.menuNavigation[index - 1].name;
				this.setState({ prevbtnLabel: prevLabel });
			}
			this.setState({
				index: index,
				disabledNext: disabledNext,
				disabledPrev: false,
				nextRedirectURL: nextRedirectURL,
				prevRedirectURL: prevRedirectURL,
			});
			history.push(nextRedirectURL);
		}
	}

	render() {
		const { index, disabledNext, disabledPrev, prevbtnLabel } = this.state;
		const menuItems = this.props.menuNavigation ? this.props.menuNavigation[index] : null;
		const { classes, flags, hasDataToSave } = this.props;
		const pathname = this.getPathUrl();
		if (menuItems) {
			if (
				pathname === '/credentials' ||
				pathname === '/credentials/checkStatusAndSign' ||
				pathname === '/credentials/overview'
			) {
				return null;
			} else {
				if (flags.navDisabled) {
					return null;
				} else {
					return (
						<>
							<Prev
								toggle={(e) => this.togglePrev(e)}
								flags={flags}
								hasDataToSave={hasDataToSave}
								disabled={disabledPrev}
								prevbtnLabel={prevbtnLabel}
								classes={classes}
							/>
							<Next
								toggle={(e) => this.toggleNext(e)}
								flags={flags}
								hasDataToSave={hasDataToSave}
								disabled={disabledNext}
								prevbtnLabel={prevbtnLabel}
								classes={classes}
							/>
							<h1 dangerouslySetInnerHTML={{ __html: flags.errorMessage }} />
						</>
					);
				}
			}
		} else {
			return <span>error</span>;
		}
	}
}

function Prev(props) {
	return (
		<div className={props.disabled ? props.classes.buttonNone : props.classes.buttonBlock}>
			<Button
				onClick={props.toggle}
				disabled={props.disabled || props.flags.isPendingSave}
				className={props.classes.navigationButton}
				variant='contained'
				color='primary'
			>
				Back to {props.prevbtnLabel}
			</Button>
		</div>
	);
}

function Next(props) {
	let label = 'Save & Continue';
	if (!props.hasDataToSave) label = 'Continue';
	return (
		<div className={props.classes.buttonBlock}>
			<Button
				onClick={props.toggle}
				disabled={
					props.disabled || props.flags.isPendingSave || props.flags.validationError
				}
				className={props.classes.navigationButton}
				variant='contained'
				color='primary'
			>
				{label}
			</Button>
		</div>
	);
}

export default withRouter(withStyles(styles)(NavigationButton));
