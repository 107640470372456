const types = [
	{
		value: 'Advanced Burn Life Support - ABLS',
		label: 'Advanced Burn Life Support - ABLS',
	},
	{
		value: 'Advanced Cardiac Life Support - ACLS',
		label: 'Advanced Cardiac Life Support - ACLS',
	},
	{
		value: 'Advanced Life Support in Obstetrics - ALSO',
		label: 'Advanced Life Support in Obstetrics - ALSO',
	},
	{
		value: 'Advanced Trauma Life Support - ATLS',
		label: 'Advanced Trauma Life Support - ATLS',
	},
	{
		value: 'Basic Cardiac Life Support - BCLS',
		label: 'Basic Cardiac Life Support - BCLS',
	},
	{
		value: 'Basic Disaster Life Support - BDLS',
		label: 'Basic Disaster Life Support - BDLS',
	},
	{
		value: 'Basic Life Support - BLS',
		label: 'Basic Life Support - BLS',
	},
	{
		value: 'CA Fluroscopy Supervisor and Operator',
		label: 'CA Fluroscopy Supervisor and Operator',
	},
	{
		value: 'Comprehensive Advanced Life Support - CALS',
		label: 'Comprehensive Advanced Life Support - CALS',
	},
	{
		value: 'CPR/AED',
		label: 'CPR/AED',
	},
	{
		value: 'CSFA',
		label: 'CSFA',
	},
	{
		value: 'Electronic Fetal Medicine - C-EFM',
		label: 'Electronic Fetal Medicine - C-EFM',
	},
	{
		value: 'Emergency Neurological Life Support - ENLS',
		label: 'Emergency Neurological Life Support - ENLS',
	},
	{
		value: 'Fundamental Critical Care Support - FCCS',
		label: 'Fundamental Critical Care Support - FCCS',
	},
	{
		value: 'Neonatal Advanced Life Support - NALS',
		label: 'Neonatal Advanced Life Support - NALS',
	},
	{
		value: 'Neonatal Neuro-Intensive Care - C-NNIC',
		label: 'Neonatal Neuro-Intensive Care - C-NNIC',
	},
	{
		value: 'Neonatal Nurse Practitioner - NNP-BC',
		label: 'Neonatal Nurse Practitioner - NNP-BC',
	},
	{
		value: 'Neonatal Resuscitation Program - NRP',
		label: 'Neonatal Resuscitation Program - NRP',
	},
	{
		value: 'Non-Surgical Pain Management',
		label: 'Non-Surgical Pain Management',
	},
	{
		value: 'Nuclear Materials License',
		label: 'Nuclear Materials License',
	},
	{
		value: 'Other',
		label: 'Other',
	},
	{
		value: 'Pediatric Advanced Life Support - PALS',
		label: 'Pediatric Advanced Life Support - PALS',
	},
	{
		value: 'Radioactive Materials License',
		label: 'Radioactive Materials License',
	},
	{
		value: 'Specialty Certification',
		label: 'Specialty Certification',
	},
	{
		value: 'S.T.A.B.L.E.',
		label: 'S.T.A.B.L.E.',
	},
	{
		value: 'Transesophageal Echocardiography - TEE',
		label: 'Transesophageal Echocardiography - TEE',
	},
	{
		value: "Women's Healthcare Nurse Practitioner - WHNP-BC",
		label: "Women's Healthcare Nurse Practitioner - WHNP-BC",
	},
	{
		value: 'X-RAY Permit/Certificate',
		label: 'X-RAY Permit/Certificate',
	},
	{
		value: 'X-Ray Supervisor and Operator License',
		label: 'X-Ray Supervisor and Operator License',
	},
];

export { types };
