import React from 'react';
import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	Radio,
	RadioGroup,
	withStyles,
} from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import { Controller, useFormContext } from 'react-hook-form';

const styles = (theme) => ({
	radioLabel: {
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '20px',
	},
	formControl: {
		marginBottom: 0,
	},
	asterisk: {
		color: COLORS.LT_PEPPER,
	},
});

const ExpensesSourceRadioGroup = (props) => {
	const { classes } = props;
	const { control, errors } = useFormContext();

	return (
		<FormControl component='fieldset' className={classes.formControl}>
			<InputLabel classes={{ asterisk: classes.asterisk }} shrink htmlFor='comments' required>
				Source
			</InputLabel>
			<Controller
				control={control}
				rules={{ required: 'Source is required' }}
				defaultValue=''
				name='importer'
				render={({ onChange, value }) => (
					<RadioGroup aria-label='importer' row onChange={onChange} value={value}>
						<FormControlLabel
							classes={{ label: classes.radioLabel }}
							value='corpay'
							control={<Radio color='primary' />}
							label='Corpay'
						/>
						<FormControlLabel
							classes={{ label: classes.radioLabel }}
							value='conferma'
							control={<Radio color='primary' />}
							label='Conferma'
						/>
						<FormControlLabel
							classes={{ label: classes.radioLabel }}
							value='travelinc'
							control={<Radio color='primary' />}
							label='Travel Inc'
						/>
					</RadioGroup>
				)}
			/>
			<FormHelperText error>
				{errors && errors.importer && errors.importer.message}
			</FormHelperText>
		</FormControl>
	);
};

export default withStyles(styles)(ExpensesSourceRadioGroup);
