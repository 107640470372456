import React, { Component } from 'react';
import { withStyles, Dialog, DialogContent, IconButton, CircularProgress } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import { Close } from '@material-ui/icons';

const styles = (theme) => ({
	contentRoot: {
		height: '100vh',
	},
	dialogContentRoot: {
		padding: '45px',
		[theme.breakpoints.down('sm')]: {
			padding: '50px 0px',
		},
		margin: '0',
		borderRadius: '5px',
		color: `${COLORS.RAVEN}`,
		backgroundColor: `${COLORS.BLACK_HAZE}`,
	},
	buttonRoot: {
		border: `1px solid ${COLORS.TRUE_BLUE}`,
		margin: '0',
		width: '100%',
		textTransform: 'none',
		fontSize: '16px',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			margin: '0',
		},
	},

	timeOutHeader: {
		fontWeight: '500',
		opacity: '0.5',
	},
	closeIconButtonRoot: {
		color: '#000000',
		position: 'absolute',
		right: '0',
		top: '7px',
	},
	popUpMsg: {
		textAlign: 'left',
		//fontSize: '15px',
		//fontWeight: '600'
	},
	progressWrapper: {
		//  height: '80vh',
	},
	progressRoot: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10vh',
			marginLeft: '15vh',
			marginBottom: '10vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '10vh',
			marginLeft: '15vh',
			marginBottom: '10vh',
		},
	},
	modalMessage: {
		alignText: 'center',
		textAlign: 'center',
	},
});

class AdobeSignDocumentModal extends Component {
	renderDialog() {
		const { classes, signingUrl, responseTimeout } = this.props;

		if (signingUrl) {
			return (
				<>
					<div className={classes.popUpMsg}>
						<iframe
							title='AdobeSign'
							width='100%'
							height='600'
							frameBorder='0'
							src={signingUrl}
						/>
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className={classes.modalMessage}>
						<h2 style={{ fontWeight: '900' }}>
							Generating your credentialing application
						</h2>
					</div>
					<div className={classes.progressWrapper}>
						{responseTimeout === '' ? (
							<CircularProgress
								classes={{ root: classes.progressRoot }}
								color='primary'
								size={120}
								thickness={5}
							/>
						) : (
							<div className={classes.modalMessage}>
								<h3 style={{ fontWeight: '900' }}>
									Error occurred, please try again later.
								</h3>
							</div>
						)}
					</div>
				</>
			);
		}
	}

	render() {
		const { classes, toggleHandler, openDialog } = this.props;

		return (
			<div className={classes.contentRoot}>
				<Dialog
					open={openDialog}
					fullWidth
					scroll='body'
					disableBackdropClick
					disableEscapeKeyDown
					maxWidth={this.props.signingUrl === '' ? 'xs' : 'md'}
					onClose={() => toggleHandler(false)}
				>
					<IconButton
						onClick={() => toggleHandler(false)}
						classes={{ root: classes.closeIconButtonRoot }}
					>
						<Close />
					</IconButton>

					<DialogContent classes={{ root: classes.dialogContentRoot }}>
						{this.renderDialog()}
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(AdobeSignDocumentModal));
