const airlineOptions = [
	{
		label: 'Select',
		value: '',
	},
	{
		label: 'Alaska',
		value: 'Alaska',
	},
	{
		label: 'Allegiant',
		value: 'Allegiant',
	},
	{
		label: 'American Airlines',
		value: 'American Airlines',
	},
	{
		label: 'Delta',
		value: 'Delta',
	},
	{
		label: 'Frontier',
		value: 'Frontier',
	},
	{
		label: 'JetBlue',
		value: 'JetBlue',
	},
	{
		label: 'Hawaiian',
		value: 'Hawaiian',
	},
	{
		label: 'Southwest',
		value: 'Southwest',
	},
	{
		label: 'Spirit',
		value: 'Spirit',
	},
	{
		label: 'Sun Country',
		value: 'Sun Country',
	},
	{
		label: 'United',
		value: 'United',
	},
];

const seatPreferenceOptions = [
	{
		label: '',
		value: '',
	},
	{
		label: 'Aisle',
		value: 'Aisle',
	},
	{
		label: 'Middle',
		value: 'Middle',
	},
	{
		label: 'Window',
		value: 'Window',
	},
];

const carRentalCompanyOptions = [
	{
		label: 'Avis',
		value: 'Avis',
	},
	{
		label: 'Enterprise',
		value: 'Enterprise',
	},
	{
		label: 'National',
		value: 'National',
	},
	{
		label: 'Other',
		value: 'Other',
	},
];

export { airlineOptions, seatPreferenceOptions, carRentalCompanyOptions };
