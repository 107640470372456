const presentsFilterOptions = [
	{
		label: 'Last 3 Months',
		value: '3',
	},
	{
		label: 'Last 12 Months',
		value: '12',
	},
	{
		label: 'Last 24 Months',
		value: '24',
	},
];

export default presentsFilterOptions;
