import React, { Fragment, Component } from 'react';
import { Grid, withStyles, ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore, Hotel, Room } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../../utils/Application_Constants';
import moment from 'moment';
import { dateRange } from '../../../../utils/helpers';

const styles = (theme) => ({
	expansionPanelSummaryRoot: {
		padding: '8px 20px',
	},
	clickableLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},

	clickableDirectionMapSpan: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			marginLeft: '10px',
		},
	},
	header: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '16px',
		paddingTop: '15px',
		margin: '0 0 0 15px',
	},
	addressLine: {
		fontWeight: '500',
		color: COLORS.RAVEN,
		fontSize: '14px',
		margin: '0 0 15px 15px',
	},
	hotelTime: {
		fontWeight: 'bold',
		color: COLORS.RAVEN,
		fontSize: '11px',
		textAlign: 'center',
	},
	alignLeft: {
		width: '50%',
		float: 'left',
		textAlign: 'left',
	},
	helpNumberWithNoHotel: {
		width: '50%',
		float: 'right',
		textAlign: 'right',
		paddingRight: '35px',
		color: COLORS.TRUE_BLUE,
	},
	helpNumber: {
		width: '50%',
		float: 'right',
		textAlign: 'right',
		color: COLORS.TRUE_BLUE,
	},
	bold: {
		fontWeight: 'bold',
	},
	iconClass: {
		verticalAlign: 'middle',
		paddingRight: '6px',
		color: COLORS.TRUE_BLUE,
	},
	hotelCard: {
		marginTop: '16px',
		backgroundColor: COLORS.DENIM,
		minWidth: '100%',
	},
	fullWidth: {
		minWidth: '100%',
	},
	cellConfHeader: {
		color: COLORS.WHITE,
		padding: '10px 8px 10px 8px',
		textAlign: 'right',
	},
	cellDateHeader: {
		color: COLORS.WHITE,
		fontWeight: 'bold',
		padding: '10px 8px 10px 8px',
		textAlign: 'left',
	},
	cellHeading: {
		color: COLORS.RAVEN,
		fontWeight: 'bold',
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		border: 'none',
	},
	cellRoot: {
		color: COLORS.RAVEN,
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		border: 'none',
	},
	cellGrid: {
		padding: '0 0 10px 0',
	},
	cellRightBorder: {
		color: COLORS.RAVEN,
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		borderRight: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	cellTopBorder: {
		borderRight: `1px solid ${COLORS.WHITE_LILAC}`,
		borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	bottomBorder: {
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	iconRoot: {
		width: '16px',
		marginRight: '5px',
		marginBottom: '-6px',
		color: COLORS.TRUE_BLUE,
	},
	cellItinerary: {
		color: COLORS.RAVEN,
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
			borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		},
	},
});

class Lodging extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	mapsSelectorForTravel = (addressInfo) => {
		if (
			navigator.platform.indexOf('iPhone') !== -1 ||
			navigator.platform.indexOf('iPad') !== -1 ||
			navigator.platform.indexOf('iPod') !== -1
		) {
			window.open(`https://maps.apple.com/?q=${this.hotelAddressParser(addressInfo)}}`);
			return;
		}
		window.open(
			`https://google.com/maps/search/?api=1&query=${this.hotelAddressParser(addressInfo)}}`
		);
	};

	hotelAddressParser = (addressInfo) => {
		let encodedAddress = '';
		const addressString = `${addressInfo.propertyAddress1} ${addressInfo.propertyCity} ${addressInfo.propertyState} ${addressInfo.propertyZip}`;
		encodedAddress = encodeURI(addressString);
		return encodedAddress;
	};

	justHotels = (travelInfo) => {
		return travelInfo.filter((item) => item.segmentType === 'H');
	};

	renderNoHotels = (travelInfo, classes, t) => {
		return (
			<ExpansionPanel>
				<ExpansionPanelSummary
					classes={{ root: classes.expansionPanelSummaryRoot }}
					aria-controls='travelPanelContent'
					id='travelPanelHeader'
				>
					<div className={classes.alignLeft}>
						<Hotel classes={{ root: classes.iconClass }} />
						<span className={classes.panelHeader}>
							{t('assignments:TRAVEL.LODGING_TITLE')} (
							{this.justHotels(travelInfo).length})
						</span>
					</div>
				</ExpansionPanelSummary>
			</ExpansionPanel>
		);
	};

	render() {
		const { t, classes, travelInfo } = this.props;

		if (this.justHotels(travelInfo).length === 0) {
			return this.renderNoHotels(travelInfo, classes, t);
		}
		return (
			<ExpansionPanel>
				<ExpansionPanelSummary
					classes={{ root: classes.expansionPanelSummaryRoot }}
					expandIcon={<ExpandMore classes={{ root: classes.chevronRoot }} />}
					aria-controls='travelPanelContent'
					id='travelPanelHeader'
				>
					<div className={classes.alignLeft}>
						<Hotel classes={{ root: classes.iconClass }} />
						<span className={classes.panelHeader}>
							{t('assignments:TRAVEL.LODGING_TITLE')} (
							{this.justHotels(travelInfo).length})
						</span>
					</div>
				</ExpansionPanelSummary>
				{this.justHotels(travelInfo).map((item) => (
					<Fragment key={item.tripKey + '-' + item.confirmationNumber}>
						<Grid container direction='row' classes={{ root: classes.hotelCard }}>
							<Grid item xs={6} classes={{ root: classes.cellDateHeader }}>
								{dateRange(item)}
							</Grid>
							<Grid item xs={6} classes={{ root: classes.cellConfHeader }}>
								{t('assignments:TRAVEL.CONFIRMATION_NO')}: {item.confirmationNumber}
							</Grid>
						</Grid>
						<Grid container>
							<Grid container item xs={12} direction='column'>
								<Grid
									container
									direction='row'
									classes={{ root: classes.bottomBorder }}
								>
									<Grid item xs={12}>
										<h1 className={classes.header}>{item.propertyName}</h1>
									</Grid>
									<Grid item xs={12} classes={{ root: classes.cellGrid }}>
										<span className={classes.addressLine}>
											{' '}
											{item.propertyAddress1} {item.propertyCity},{' '}
											{item.propertyState} {item.propertyZip}{' '}
											<span className={classes.clickableDirectionMapSpan}>
												<Room classes={{ root: classes.iconRoot }} />
												<span
													className={classes.clickableLink}
													onClick={() => this.mapsSelectorForTravel(item)}
												>
													Get directions
												</span>
											</span>
										</span>
									</Grid>
								</Grid>
								<Grid container classes={{ root: classes.bottomBorder }}>
									<Grid
										item
										container
										xs={6}
										md={4}
										direction='column'
										classes={{ root: classes.cellRightBorder }}
									>
										<span className={classes.hotelTime}>
											{t('assignments:TRAVEL.CHECK-IN')}
										</span>
										<span className={classes.addressLine}>
											{`${moment(item.originatedDate).format(
												'MMM D'
											)}, ${moment(item.originatedDate).format('YYYY')} `}
										</span>
									</Grid>
									<Grid
										item
										container
										xs={6}
										md={4}
										direction='column'
										classes={{ root: classes.cellRightBorder }}
									>
										<span className={classes.hotelTime}>
											{t('assignments:TRAVEL.CHECK-OUT')}
										</span>
										<span className={classes.addressLine}>
											{`${moment(item.completedDate).format(
												'MMM D'
											)}, ${moment(item.completedDate).format('YYYY')} `}
										</span>
									</Grid>
									<Grid item container xs={12} md={4} direction='row'>
										<Grid
											item
											xs={12}
											classes={{ root: classes.cellItinerary }}
										>
											<a
												href={item.eItinLink}
												className={classes.clickableLink}
												target='travel'
											>
												{t('assignments:TRAVEL.VIEW_ITENERARY_LINE')}
											</a>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Fragment>
				))}
			</ExpansionPanel>
		);
	}
}

export default withTranslation()(withStyles(styles)(Lodging));
