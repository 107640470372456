import React from 'react';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import DisclosureQuestionsForm from './DisclosureQuestionsForm';
import { reactPlugin } from '../../../../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBoundaryPage from '../../../../ContentComponents/Credentials/PageComponenets/ErrorBoundaryPage';

function DisclosureQuestions({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	setCredAppError,
	flags,
	updateFlags,
	activePath,
	credState,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	return (
		<AppInsightsErrorBoundary
			onError={() => (
				<ErrorBoundaryPage errorCode='500' flags={flags} updateFlags={updateFlags} />
			)}
			appInsights={reactPlugin}
		>
			<DisclosureQuestionsForm
				t={t}
				setSaveHandler={setSaveHandler}
				blockedBackNavigation={blockedBackNavigation}
				UserContext={UserContext}
				flags={flags}
				updateFlags={updateFlags}
				activePath={activePath}
				credState={credState}
				sectionCompletion={sectionCompletion}
				updateSectionCompletion={updateSectionCompletion}
				updateHighlightFieldsState={updateHighlightFieldsState}
				shouldHighlightFields={shouldHighlightFields}
				setCredAppError={setCredAppError}
			/>
		</AppInsightsErrorBoundary>
	);
}

export default WithContexts(withTranslation()(DisclosureQuestions));
