const RateDisplayNameEnum = {
    HolidayShift: 'Holiday Shift',
    Regular: 'Regular',
    RegularOnsite: 'Regular (Onsite)',
    RegularTele: 'Regular (Tele)',
    DayShift: 'Day Shift',
    DayShiftOnsite: 'Day Shift (Onsite)',
    DayShiftTele: 'Day Shift (Tele)',
    NightShift: 'Night Shift',
    NightShiftOnsite: 'Night Shift (Onsite)',
    NightShiftTele: 'Night Shift (Tele)',
    Orientation: 'Orientation',
    OrientationOnsite: 'Orientation (Onsite)',
    OrientationTele: 'Orientation (Tele)',
};

export default RateDisplayNameEnum;
