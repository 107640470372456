import React, { Component, Fragment } from 'react';
import {
	withStyles,
	Button,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableContainer,
	Hidden,
	CircularProgress,
	TableHead,
} from '@material-ui/core';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import {
	COLORS,
	DOCUMENT_FIELD_NAME,
	CRED_STATE,
	DOCUMENT_HIDE_DELETE_BUTTON,
} from '../../../../../utils/Application_Constants';
import { downloadDocument } from '../../../../../services/DocumentService';
import {
	getNestedProperty,
	getNestedDocumentTypePropertyLabel,
} from '../../../../../utils/helpers';
import { deleteDocument } from '../../../../../services/Credentialing';
import deleteIcon from '../../../../../assets/images/delete-icon.svg';
import Alert from '@material-ui/lab/Alert';
import RequiredQuestionDialog from '../../../../GlobalComponents/Dialog/RequiredQuestionDialog';
import AlertDialog from '../../../../GlobalComponents/Dialog/AlertDialog';

const styles = (theme) => ({
	tableContainerRoot: {
		width: '100%',
		marginLeft: '20px',
		marginTop: '15px',
		backgroundColor: COLORS.BLACK_HAZE,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	tableRowHeaderFont: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '14px',
		padding: '4px',
	},
	centerRowHeader: {
		textAlign: 'center',
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '14px',
	},
	fileDetailWrapper: {
		display: 'flex',
		flexDirection: 'column',
	},
	mobileDocumentCard: {
		margin: '0 10px',
		backgroundColor: COLORS.BLACK_HAZE,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		textAlign: 'left',
	},
	mobileLabelContentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		padding: '11px',
	},
	mobileTitle: {
		fontWeight: '900',
		textTransform: 'uppercase',
		fontSize: '12px',
		color: COLORS.RAVEN,
	},
	fileNameBlockWrapper: {
		display: 'flex',
	},
	docField: {
		padding: '4px',
	},
	centerText: {
		textAlign: 'center',
		padding: '4px',
	},
	deleteButton: {
		width: '24px',
		cursor: 'pointer',
	},
	hideDeleteBtn: {
		display: 'none',
	},
	clickableLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		cursor: 'pointer',
		wordBreak: 'break-all',
	},
	textInherit: {
		textTransform: 'initial',
		[theme.breakpoints.down('sm')]: {
			width: 'auto',
		},
	},
	mobileButtonRoot: {
		padding: 0,
		display: 'flex',
		justifyContent: 'left',
	},
	progressWrapper: {
		width: '100%',
		textAlign: 'center',
		padding: '15px 0',
	},
});
class ClinicianDocumentTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			documents: props.documents ? props.documents : [],
			errorMessage: null,
			showDeleteModal: false,
			revision: 0,
			isLoading: false,
			docJustDeleted: null,
			docToDelete: null,
			showDownloadError: false,
			docToDownload: null,
			downloadInProgress: false,
		};
	}

	async deleteEntry(btnPressed) {
		const state = { ...this.state };
		const { t, documents, updateSectionCompletion, sectionCompletion } = this.props;
		state.showDeleteModal = false;
		const docType = getNestedProperty(state.docToDelete, DOCUMENT_FIELD_NAME.DocumentType);
		if (btnPressed === 'Yes') {
			try {
				await this.setState({ isLoading: true });

				const { externalId } = this.props.UserContext;

				// const documentName = getNestedProperty(state.docToDelete, DOCUMENT_FIELD_NAME.Name);

				const errMessage = await deleteDocument(
					externalId,
					state.docToDelete.sharepointUniqueId,
					getNestedProperty(state.docToDelete, DOCUMENT_FIELD_NAME.Name),
					docType
				);
				if (!!errMessage) {
					console.log('Error deleting:' + errMessage);
					if (docType === 'CV' || docType === 'Curriculum Vitae') {
						state.errorMessage = t('credentials:DOCUMENTS.CV_UPLOAD_ERROR_MESSAGE');
					} else {
						state.errorMessage = errMessage;
					}
				} else {
					state.docJustDeleted = state.docToDelete; // There is a delay on SP reporting the update
					await this.props.reloadDocuments(docType);
					this.props.deleteHandle(this.props.sectionTitle, documents.length);
					++state.revision;
					state.errorMessage = null;
					if (!!updateSectionCompletion) updateSectionCompletion(sectionCompletion);
				}
			} catch (ex) {
				console.log('Exception thrown while deleting:' + ex.message);
				state.errorMessage = ex.message;
			}
		}
		await this.setState({
			errorMessage: state.errorMessage,
			revision: state.revision,
			docJustDeleted: state.docJustDeleted,
			isLoading: false,
			docToDelete: null,
		});
	}

	async toggleShowDownloadError() {
		await this.setState({
			showDownloadError: false,
		});
	}
	async doDeleteDocument(doc) {
		const state = { ...this.state };
		state.docToDelete = doc;
		await this.setState({
			docToDelete: state.docToDelete,
			docJustDeleted: null,
			showDeleteModal: true,
		});
	}

	hideDeleteButton(documentType) {
		var hideDocTypes = DOCUMENT_HIDE_DELETE_BUTTON.slice();
		var a = hideDocTypes.indexOf(documentType);
		if (a >= 0) {
			return true;
		} else {
			return false;
		}
	}
	async handleDownloadDocument(item, document) {
		this.setState({
			docToDownload: item.metaData.name,
			downloadInProgress: true,
			showDownloadError: false,
		});
		await downloadDocument(item, document).catch((e) => {
			this.setState({ showDownloadError: true });
			this.setState({ downloadInProgress: false });
		});
		this.setState({ downloadInProgress: false });
	}
	renderDeleteDialog() {
		const { t } = this.props;
		const state = { ...this.state };
		if (!state.docToDelete) return null;
		return (
			<RequiredQuestionDialog
				open={state.showDeleteModal}
				title={t('credentials:DOCUMENTS.CONFIRM_DELETE_TITLE')}
				text={t('credentials:DOCUMENTS.CONFIRM_DELETE_MESSAGE').replace(
					'(document)',
					getNestedProperty(state.docToDelete, DOCUMENT_FIELD_NAME.Name)
				)}
				buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
				buttonIDs='Yes;No'
				disableBackgroundClick={false}
				onClose={async (btnPressed) => this.deleteEntry(btnPressed)}
			/>
		);
	}

	renderDocDownloadFailedDialog() {
		const { t } = this.props;
		const state = { ...this.state };
		if (!state.docToDownload) return null;
		return (
			<AlertDialog
				open={state.showDownloadError}
				title={t('credentials:DOCUMENTS.DOCUMENT_DOWNLOAD')}
				text={t('credentials:DOCUMENTS.DOCUMENT_DOWNLOAD_ERROR_MESSAGE').replace(
					'(document)',
					state.docToDownload
				)}
				disableBackgroundClick={false}
				onClose={async () => this.toggleShowDownloadError()}
			/>
		);
	}

	renderDesktopTable(documents, revision) {
		const { classes, credState } = this.props;
		const { downloadInProgress } = this.state;

		return (
			<TableContainer key={revision} classes={{ root: classes.tableContainerRoot }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								classes={{ root: classes.tableRowHeaderFont }}
								style={{ width: '20%' }}
							>
								DOCUMENT TYPE
							</TableCell>
							<TableCell
								classes={{ root: classes.tableRowHeaderFont }}
								style={{ width: '50%' }}
							>
								DOCUMENT
							</TableCell>
							<TableCell
								classes={{ root: classes.tableRowHeaderFont }}
								style={{ width: '15%' }}
							>
								DATE ADDED
							</TableCell>
							<TableCell
								classes={{ root: classes.centerRowHeader }}
								style={{ width: '15%' }}
							>
								ACTION
							</TableCell>
						</TableRow>
					</TableHead>
					{downloadInProgress ? (
						<div className={classes.progressWrapper}>
							<CircularProgress />
						</div>
					) : (
						<TableBody>
							{documents.map((item, index) => (
								<Fragment key={item.id}>
									<TableRow>
										<TableCell className={classes.docField}>
											{getNestedDocumentTypePropertyLabel(
												item,
												DOCUMENT_FIELD_NAME.DocumentType
											)}
										</TableCell>
										<TableCell className={classes.docField}>
											<span
												className={classes.clickableLink}
												onClick={() => {
													this.handleDownloadDocument(
														item,
														getNestedProperty(
															item,
															DOCUMENT_FIELD_NAME.DocumentType
														)
													);
												}}
											>
												{item.metaData.name}
											</span>
										</TableCell>
										<TableCell className={classes.docField}>
											{moment
												.utc(
													getNestedProperty(
														item,
														DOCUMENT_FIELD_NAME.Modified
													)
												)
												.local()
												.format('MMM D, YYYY')}
										</TableCell>
										<TableCell className={classes.centerText}>
											<img
												alt='Delete'
												src={deleteIcon}
												onClick={() =>
													this.doDeleteDocument(item, documents)
												}
												className={
													credState === CRED_STATE.CLOSED ||
													this.hideDeleteButton(
														getNestedProperty(
															item,
															DOCUMENT_FIELD_NAME.DocumentType
														)
													)
														? classes.hideDeleteBtn
														: classes.deleteButton
												}
											/>
										</TableCell>
									</TableRow>
								</Fragment>
							))}
						</TableBody>
					)}
				</Table>
			</TableContainer>
		);
	}

	renderMobileDocumentCards(documents, revision) {
		const { classes, credState } = this.props;
		const { downloadInProgress } = this.state;
		return documents.map((item, index) => (
			<div key={`documentCard-${index}-${revision}`} className={classes.mobileDocumentCard}>
				<div className={classes.mobileLabelContentWrapper}>
					<span className={classes.mobileTitle}>DOCUMENT TYPE</span>
					<span>
						{getNestedDocumentTypePropertyLabel(item, DOCUMENT_FIELD_NAME.DocumentType)}
					</span>
				</div>
				<div className={classes.fileNameBlockWrapper}>
					<div className={classes.mobileLabelContentWrapper}>
						<div className={classes.fileDetailWrapper}>
							<span className={classes.mobileTitle}>Filename</span>
							{downloadInProgress ? (
								<div className={classes.progressWrapper}>
									<CircularProgress />
								</div>
							) : (
								<Button
									classes={{
										label: `${classes.clickableLink} ${classes.textInherit}`,
										root: classes.mobileButtonRoot,
									}}
									component='span'
									onClick={() => {
										this.handleDownloadDocument(
											item,
											getNestedProperty(
												item,
												DOCUMENT_FIELD_NAME.DocumentType
											)
										);
									}}
								>
									{item.metaData.name}
								</Button>
							)}
						</div>
					</div>
				</div>
				<div className={classes.mobileLabelContentWrapper}>
					<span className={classes.mobileTitle}>Date Added</span>
					<span>
						{moment
							.utc(getNestedProperty(item, DOCUMENT_FIELD_NAME.Modified))
							.local()
							.format('MMM D, YYYY')}
					</span>
				</div>
				<div className={classes.mobileLabelContentWrapper}>
					<span className={classes.mobileTitle}>Action</span>
					<img
						alt='Delete'
						src={deleteIcon}
						onClick={() =>
							this.doDeleteDocument(
								item,
								getNestedProperty(item, DOCUMENT_FIELD_NAME.DocumentType)
							)
						}
						className={
							credState === CRED_STATE.CLOSED
								? classes.hideDeleteBtn
								: classes.deleteButton
						}
					/>
				</div>
			</div>
		));
	}

	render() {
		const { documents, errorMessage, classes } = this.props;
		const { revision, isLoading, docJustDeleted } = this.state;
		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress />
				</div>
			);
		}
		if (!documents || documents.length === 0) {
			return null;
		}
		const validDocs = documents.filter((d) => d !== docJustDeleted);
		return (
			<div>
				{this.renderDeleteDialog(validDocs.length)}
				{this.renderDocDownloadFailedDialog()}
				{!errorMessage ? null : (
					<div className={classes.errorMessage}>
						<Alert
							variant='filled'
							severity='error'
							className={classes.alertMessage}
							onClose={() => {
								this.setState({ errorMessage: false });
							}}
						>
							{errorMessage}
						</Alert>
					</div>
				)}
				<Hidden only={['xs', 'sm']}>{this.renderDesktopTable(validDocs, revision)}</Hidden>
				<Hidden only={['md', 'lg', 'xl']}>
					{this.renderMobileDocumentCards(validDocs, revision)}
				</Hidden>
			</div>
		);
	}
}

export default WithContexts(withTranslation()(withStyles(styles)(ClinicianDocumentTable)));
