import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Hidden, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import NavigationService from '../../../services/NavigationService';
import MobileNavbar from '../MobileNavbar/MobileNavbar';
import DesktopNavigation from '../DesktopNavigation/DesktopNavigation';
import UserContext from '../../../contexts/userContext';
import { default as ActiveTokenRefresh } from '../../GlobalComponents/ActiveTokenRefresh/ActiveTokenRefresh';

const styles = (theme) => ({
	root: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		borderRadius: 3,
		boxShadow: '0px 3px 12px #0000000D',
		color: 'black',
		height: '60px',
		zIndex: 1300,
		[theme.breakpoints.up('md')]: {
			height: '80px',
		},
	},
	filler: {
		height: '80px',
		[theme.breakpoints.down('sm')]: {
			height: '60px',
		},
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		marginTop: '25vh',
	},
});

class Navbar extends Component {
	constructor(props, context) {
		super(props);
		this.state = {
			menuNavigation: [],
			userLevelHasProcessed: false, //default the state to false that the user-level has not yet finidhed retireving
			showV2HasProcessed: false
		};
		this.context = context;

		//Poll context user level until it has been retrieved
		var intervalID = setInterval(
			function (parent) {
				//Check to see if the promised has finished retrieving the userLevel
				if (parent.context.userLevel.isFulfilled()) {
					//callback to parent to inform of completion
					parent.userLevelFulfilled();
					//stop polling
					clearInterval(intervalID);
				}
			},
			250,
			this
		);

		var showV2IntervalId = setInterval(
			function (parent) {
				if (parent.context.showV2.isFulfilled()) {
					parent.showV2Fulfilled();
					clearInterval(showV2IntervalId);
				}
			},
			250,
			this
		);
	}

	componentDidMount() {
		ActiveTokenRefresh.start();
	}

	componentWillUnmount() {
		ActiveTokenRefresh.stop();
	}

	//callback to tell the component the userLevel value is ready by setting state
	userLevelFulfilled() {
		this.setState({ userLevelHasProcessed: true });
	}

	showV2Fulfilled() {
		this.setState({ showV2HasProcessed: true });
	}

	render() {
		const { history,refreshNotifications } = this.props;
		if (history.location.pathname === '/') {
			return null;
		}
		const { classes } = this.props;
		const { userLevelHasProcessed, showV2HasProcessed } = this.state;
		const { account } = this.context;

		if (userLevelHasProcessed === false || showV2HasProcessed === false) {
			return (
				<>
					<AppBar className={classes.root}>
						<div className={classes.progressWrapper}>
							<CircularProgress color='primary' />
						</div>
					</AppBar>
					<div className={classes.filler} />
				</>
			);
		}
		if (userLevelHasProcessed === true && showV2HasProcessed === true) {
			return (
				<>
					<AppBar className={classes.root}>
						<Hidden only={['md', 'lg', 'xl']}>
							<MobileNavbar
							    refreshNotifications = {refreshNotifications}
								menuNavigation={
									account
										? NavigationService.getHeaderLevelNavigation(
											this.context.userLevel.value(),
											this.context.showV2.value()
										).header
										: []
								}
							/>
						</Hidden>
						<Hidden only={['xs', 'sm']}>
							<DesktopNavigation
								refreshNotifications = {refreshNotifications}
								menuNavigation={
									account
										? NavigationService.getHeaderLevelNavigation(
											this.context.userLevel.value(),
											this.context.showV2.value()
										).header
										: []
								}
							/>
						</Hidden>
					</AppBar>
					<div className={classes.filler} />
				</>
			);
		}
	}
}

Navbar.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
	}).isRequired,
};

Navbar.contextType = UserContext;

export default withRouter(withStyles(styles)(Navbar));
