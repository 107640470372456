const RateTypeCategoryEnum = {
	Hours: 'Hours',
	SingleUnit: 'SingleUnit',
	Units: 'Units',
	TeleHours: 'TeleHours',
	TeleSingleUnit: 'TeleSingleUnit',
	TeleUnits: 'TeleUnits'
};

export default RateTypeCategoryEnum;
