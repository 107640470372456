import React, { useEffect, useState } from 'react';
import {
	Button,
	CircularProgress,
	Collapse,
	Divider,
	Drawer,
	Grid,
	IconButton,
	Snackbar,
	Typography,
	withStyles,
} from '@material-ui/core';
import { COLORS } from '../../utils/Application_Constants';
import { ArrowDropDown, ArrowDropUp, CloseOutlined, HistoryOutlined } from '@material-ui/icons';
import { getTimesheetHistory } from '../../Shared/Services/TimesheetsService';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useQuery from '../../utils/useQuery';
import { Alert } from '@material-ui/lab';
import { formatErrorMessage } from '../../Shared/Utils/ErrorMessage';

const styles = (theme) => ({
	DrawerPaper: {
		padding: '16px 40px 40px 40px',
		maxWidth: '400px',
		[theme.breakpoints.up('md')]: {
			width: '400px',
		},
	},
	historyHeader: {
		color: COLORS.LT_MIDNIGHT50,
		padding: '4px !important',
	},
	historyEvents: {
		paddingBottom: '64px',
	},
	historyEventDetail: {
		paddingLeft: '16px',
	},
	detailLabel: {
		fontWeight: '600',
		display: 'flex',
		cursor: 'pointer',
	},
	progressRoot: {
		margin: '40px auto',
	},
});

const TimesheetHistory = (props) => {
	const { classes, locationId } = props;
	const { id, clinicianId } = useParams();
	const query = useQuery();
	const clinicianIdQuery = query.get('clinicianId');
	const clinicianIdParam = clinicianId ? clinicianId : clinicianIdQuery;
	const [open, setOpen] = useState(false);
	const [eventDetailOpen, setEventDetailOpen] = useState();
	const [timesheetHistory, setTimesheetHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [alert, setAlert] = useState();

	async function fetchTimesheetHistory() {
		setIsLoading(true);
		try {
			const history = await getTimesheetHistory(clinicianIdParam, id, locationId);
			setTimesheetHistory(history);
		} catch (error) {
			const message = formatErrorMessage(error);
			setAlert({
				message,
				type: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	}

	const handleAlertClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert(null);
	};

	const handleDrawerOpen = () => {
		setOpen(true);
		fetchTimesheetHistory();
	};

	const handleDrawerClose = () => {
		setOpen(false);
		setEventDetailOpen(null);
		setTimesheetHistory([]);
	};

	const handleEventDetail = (index) => {
		const eventDetails = index === eventDetailOpen ? null : index;
		setEventDetailOpen(eventDetails);
	};

	const renderAlert = () => (
		<Snackbar
			open={Boolean(alert)}
			autoHideDuration={6000}
			onClose={handleAlertClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert onClose={handleAlertClose} severity={alert?.type}>
				{alert?.message}
			</Alert>
		</Snackbar>
	);

	const renderTimesheetHistoryDrawer = () => (
		<Drawer
			anchor='right'
			open={open}
			onClose={handleDrawerClose}
			classes={{ paper: classes.DrawerPaper }}
		>
			<Grid container direction='column' spacing={2} className={classes.historyEvents}>
				<Grid item className={classes.historyHeader}>
					<Grid container justifyContent='space-between' alignItems='center'>
						<Grid item>
							<Grid container spacing={1} alignItems='center'>
								<HistoryOutlined fontSize='small' />
								<Grid item>
									<Typography variant='overline' display='block'>
										<b>Timesheet History</b>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<IconButton onClick={handleDrawerClose}>
								<CloseOutlined />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				<Divider />
				{renderTimesheetHistoryEvents()}
			</Grid>
		</Drawer>
	);

	const renderTimesheetHistoryEvent = (event, index) => (
		<Grid container direction='column' spacing={2}>
			<Grid item>
				<Typography variant='body2'>
					<b>{event.headingOne}</b>
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant='body2'>{event.headingTwo}</Typography>
			</Grid>
			{event.details.length > 0 && (
				<Grid item>
					<Typography
						onClick={() => handleEventDetail(index)}
						variant='caption'
						className={classes.detailLabel}
						gutterBottom
					>
						Details
						{index !== eventDetailOpen ? (
							<ArrowDropDown fontSize='small' />
						) : (
							<ArrowDropUp fontSize='small' />
						)}
					</Typography>
					<Collapse in={index === eventDetailOpen} timeout='auto' unmountOnExit>
						<Grid container spacing={2}>
							{event.details.map((detail, index) => (
								<Grid item xs={12} key={index}>
									<Typography
										variant='body2'
										className={classes.historyEventDetail}
									>
										{detail.detail}
									</Typography>
								</Grid>
							))}
						</Grid>
					</Collapse>
				</Grid>
			)}
		</Grid>
	);

	const renderTimesheetHistoryEvents = () => (
		<>
			{isLoading && (
				<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
			)}
			{!isLoading && timesheetHistory.length > 0 && (
				<>
					{timesheetHistory.map((event, index) => (
						<React.Fragment key={index}>
							<Grid item className={classes.historyEvent}>
								{renderTimesheetHistoryEvent(event, index)}
							</Grid>
							{index !== timesheetHistory.length - 1 && (
								<Grid item>
									<Divider />
								</Grid>
							)}
						</React.Fragment>
					))}
				</>
			)}
			{!isLoading && timesheetHistory.length === 0 && (
				<Grid item>
					<Typography variant='body2'>No history available.</Typography>
				</Grid>
			)}
		</>
	);

	return (
		<>
			<Button onClick={handleDrawerOpen} startIcon={<HistoryOutlined />} color='default'>
				Timesheet History
			</Button>
			{renderTimesheetHistoryDrawer()}
			{renderAlert()}
		</>
	);
};

export default withStyles(styles)(TimesheetHistory);
