import React from 'react';
import AdminApp from './Admin/AdminApp';
import ClinicianApp from './ClinicianApp';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { COLORS } from './utils/Application_Constants';
import ClientApp from './Client/ClientApp';

const theme = createTheme({
	typography: {
		fontFamily: ['museo-sans', 'sans-serif'].join(','),
		h3: {
			fontSize: '32px',
			fontWeight: '600',
			lineHeight: '40px',
		},
		h4: {
			fontWeight: '600',
		},
		h5: {
			fontWeight: '600',
			fontSize: '20px',
			lineHeight: '28px',
		},
		body1: {
			fontWeight: '600',
			fontSize: '16px',
			lineHeight: '24px',
		},
		body2: {
			fontWeight: '400',
			fontSize: '14px',
			lineHeight: '20px',
		},
	},
	spacing: 8,
	palette: {
		primary: { 500: COLORS.LT_JOURNEY },
		secondary: { main: COLORS.MILANO_RED },
		text: {
			primary: COLORS.LT_MIDNIGHT,
			secondary: COLORS.LT_STEEL,
		},
	},
	overrides: {
		MuiPaper: {
			root: {
				color: 'inherit',
			},
		},
		MuiButton: {
			root: {
				fontWeight: '600',
				boxShadow: 'none',
				textTransform: 'none',
				color: 'inherit',
				minWidth: '100px',
			},
			contained: {
				boxShadow: 'none',
				'&:hover': {
					boxShadow: 'none',
				},
				color: COLORS.LT_MIDNIGHT75,
				background: COLORS.LT_MIDNIGHT5,
			},
			containedPrimary: {
				backgroundColor: COLORS.LT_JOURNEY,
				'&:hover': {
					backgroundColor: COLORS.LT_FOUNDATION,
				},
			},
			outlinedPrimary: {
				backround: 'transparent',
				border: `1px solid ${COLORS.LT_JOURNEY}`,
				color: COLORS.LT_JOURNEY,
			},
			text: {
				padding: '.5rem 1rem',
			},
		},

		MuiInputBase: {
			root: {
				color: COLORS.LT_MIDNIGHT,
				fontFamily: 'museo-sans',
				fontWeight: '500',
				'&:focus': {
					borderColor: COLORS.LT_JOURNEY,
				},
				width: '100%',
			},
			input: {
				color: COLORS.LT_MIDNIGHT,
				background: 'white',
			},
			multiline: {
				border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
				borderRadius: '8px',
				padding: '16px 24px',
			},
		},

		MuiInputLabel: {
			root: {
				color: COLORS.LT_MIDNIGHT,
				fontSize: '16px',
				fontWeight: '700',
				marginLeft: '0px',
				fontFamily: 'museo-sans',
			},
			formControl: {
				color: COLORS.LT_MIDNIGHT,
				transform: 'none',
				position: 'inherit!important',
			},
		},

		MuiOutlinedInput: {
			root: {
				borderRadius: '0px',
				'& $notchedOutline': {
					borderColor: 'transparent',
					transition: 'all 0.3s',
				},
				'&:hover:not($disabled) $notchedOutline': {
					border: `1px solid ${COLORS.LT_JOURNEY}`,
				},
				'&$focused $notchedOutline': {
					border: `1px solid ${COLORS.LT_JOURNEY}`,
				},

				'&&& $input': {
					padding: '12px',
				},
			},
			multiline: {
				padding: '0px',
			},
		},

		MuiInput: {
			underline: {
				'&:before': {
					content: 'none',
				},
				'&:after': {
					content: 'none',
				},
			},
		},
		MuiFormControl: {
			root: {
				width: '100%',
				marginBottom: '1.5rem',
			},
		},
		MuiChip: {
			root: {
				fontWeight: '500',
				border: '1px solid',
				padding: '.25rem',
				borderRadius: '8px',
				textAlign: 'center',
			},
			label: {
				textTransform: 'uppercase',
			},
		},

		MuiTableRow: {
			root: {
				'&:last-child td': {
					borderBottom: 'none',
				},
			},
		},

		MuiTableCell: {
			root: {
				borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
			},
			body: {
				color: COLORS.LT_MIDNIGHT75,
			},
		},
		MuiCircularProgress: {
			root: {
				margin: '0 auto',
			},
		},
		MuiMenuItem: {
			root: {
				fontWeight: 'normal',
			},
		},
		MuiMenu: {
			list: {
				paddingTop: '16px',
				paddingBottom: '16px',
				minWidth: '150px',
			},
		},
		MuiAlert: {
			standardWarning: {
				color: '#AD7829',
				background: COLORS.LT_SUNFLOWER25,
				'& $icon': {
					color: '#AD7829',
				},
			},
			standardError: {
				color: COLORS.LT_PEPPER,
				background: COLORS.LT_PEPPER10,
				'& $icon': {
					color: COLORS.LT_PEPPER,
				},
			},
			standardInfo: {
				color: COLORS.LT_JOURNEY,
				background: COLORS.LT_JOURNEY10,
				'& $icon': {
					color: COLORS.LT_JOURNEY,
				},
			},
		},
	},
});

const App = () => {
	const isAdminApp = process.env.REACT_APP_TYPE === 'Admin';
	const isClinicianApp = process.env.REACT_APP_TYPE === 'Clinician';
	const isClientApp = process.env.REACT_APP_TYPE === 'Client';
	return (
		<ThemeProvider theme={theme}>
			{isAdminApp && <AdminApp />}
			{isClinicianApp && <ClinicianApp />}
			{isClientApp && <ClientApp />}
		</ThemeProvider>
	);
};

export default App;
