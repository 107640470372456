import React, { Component } from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import AssignmentDetailCard from '../AssignmentDetailCard/AssignmentDetailCard';
import AssignmentDocumentComponent from '../AssignmentDocumentComponent/AssignmentDocumentComponent';
import AssignmentDocumentComponentV2 from '../AssignmentDocumentComponent/AssignmentDocumentComponentV2';
import Travel from '../Travel/Travel';
import TravelV2 from '../Travel/TravelV2';
import FeatureFlag from '../../GlobalComponents/FeatureFlagComponent/FeatureFlag';
import YourTeamGrid from '../YourTeam/YourTeamGrid.js';
import { getAssignmentTeam } from '../../../services/AssignmentsService';

const styles = (theme) => ({});

class UpcomingAssignmentDetailView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignmentTeam: [],
			isLoading: true,
		};
	}

	async componentDidMount() {
		const { assignment, externalId } = this.props;

		await this.setState({ isLoading: true });
		const assignmentTeam = await getAssignmentTeam(externalId, assignment.id, 'upcoming');
		this.setState({
			assignmentTeam: assignmentTeam,
			isLoading: false,
		});
	}

	render() {
		const { assignment, externalId } = this.props;
		const { assignmentTeam, isLoading } = this.state;

		if (isLoading) {
			return (
				<div>
					<CircularProgress color='primary' />
				</div>
			);
		}

		return !assignment ? (
			<p>Error, Assignment not found</p>
		) : (
			<>
				<AssignmentDetailCard assignment={assignment} type='upcoming' />
				<FeatureFlag name='ShowYourTeam' isFlagEnabled='true'>
					<YourTeamGrid yourteam={assignmentTeam} />
				</FeatureFlag>
				<FeatureFlag name='ShowYourTeam' isFlagEnabled='false'>
					<FeatureFlag name='ShowRewiredAssignmentDocs' isFlagEnabled='true'>
						<AssignmentDocumentComponentV2 assignmentName={assignment.name} />
					</FeatureFlag>
					<FeatureFlag name='ShowRewiredAssignmentDocs' isFlagEnabled='false'>
						<AssignmentDocumentComponent assignmentName={assignment.name} />
					</FeatureFlag>
					<FeatureFlag name='ShowRewiredAssignmentTravel' isFlagEnabled='true'>
						<TravelV2 assignment={assignment} externalId={externalId} filter='active' />
					</FeatureFlag>
					<FeatureFlag name='ShowRewiredAssignmentTravel' isFlagEnabled='false'>
						<Travel assignment={assignment} filter='active' />
					</FeatureFlag>
				</FeatureFlag>
			</>
		);
	}
}

export default withStyles(styles)(UpcomingAssignmentDetailView);
