import React, { Component } from 'react';
import CredentialsV1 from './CredentialsV1';
import CredentialsV2 from './CredentialsV2';
import FeatureFlag from '../../components/GlobalComponents/FeatureFlagComponent/FeatureFlag';
import { withTranslation } from 'react-i18next';
import { withStyles, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../contexts/withContexts';
import {
    getClinicianCredApp
} from '../../services/Credentialing';
const styles = (theme) => ({});
class Credentials extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            credAppObject: null
        };
    }

    async componentDidMount() {
        const { externalId } = this.props.UserContext;
        const credAppObject = await getClinicianCredApp(externalId);
        this.setState({
            isLoading: false,
            credAppObject: credAppObject
        });
    }

    render() {
        if (this.state.isLoading !== false) {
            return <div className={this.props.classes.progressWrapper}>
                <CircularProgress classes={{ root: this.props.classes.progressRoot }} color='primary' />
            </div>
        }

        if (this.state.credAppObject?.personal?.useLegacyCredApp === true) {
            return <CredentialsV1 {...this.props} />
        }
        
        return <>
            <FeatureFlag name='AxuallCredentialing' isFlagEnabled='True'>
                <CredentialsV2 {...this.props} />
            </FeatureFlag>

            <FeatureFlag name='AxuallCredentialing' isFlagEnabled='False'>
                <CredentialsV1 {...this.props} />
            </FeatureFlag>
        </>
    }
}
export default WithContexts(withRouter(withTranslation()(withStyles(styles)(Credentials))));