import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { BillTypeEnum } from '../Expense/BillType.enum';
import ExpenseDatePicker from './ExpenseDatePicker';
import moment from 'moment';

const ExpenseDateRange = (props) => {
    const { index, expense, mode } = props;
    const { control, errors, watch } = useFormContext();
    const billType = watch(`expenseEntries[${index}].billType`);
    const isClinicianDeduction = billType === 'ClinicianDeduction';
    const [required, setRequired] = useState({ required: (mode==='cc'?false:!isClinicianDeduction)});
    const nonBillable =
        billType &&
        (billType === BillTypeEnum.NonBillTeam || billType === BillTypeEnum.NonBillProducer);
    const startDate = watch(`expenseEntries[${index}].startDate`);
    const endDate = watch(`expenseEntries[${index}].endDate`);
    const minStartDate = nonBillable
        ? moment(new Date()).startOf('day').subtract(1, 'year')
        : moment(new Date()).startOf('day').subtract(6, 'months');
    const maxStartDate = endDate ? endDate : moment(new Date()).startOf('day').add(31, 'days');
    const minEndDate = startDate
        ? startDate
        : moment(new Date()).startOf('day').subtract(6, 'months');
    const maxEndDate = moment(new Date()).startOf('day').add(6, 'months');

    const handleDateChange = (e) =>
        e && e.startOf('day') ? e.startOf('day').format('YYYY-MM-DDTHH:mm:ss') : e;

    return (
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].startDate`}
                        defaultValue={expense.startDate || null}
                        rules={{
                            required:
                                required.required ? ((!billType && 'Bill type is required') || 'Start date required') : false,
                            validate: required.required ? ({
                                invalid: (value) => moment(value).isValid() || 'Invalid date',
                                maxDate: (value) =>
                                    moment(value).isSameOrBefore(maxStartDate) ||
                                    'Start date must be before end date',
                                minDate: (value) =>
                                    moment(value).isSameOrAfter(minStartDate) || 'Min date reached',
                            }) : false,
                        }}
                        render={({ onChange, value }) => (
                            <ExpenseDatePicker
                                onChange={(e) => onChange(handleDateChange(e))}
                                value={value}
                                id='start-date'
                                label='Start date'
                                {...required}
                                minDate={minStartDate}
                                maxDate={maxStartDate}
                                disabled={!billType}
                                error={Boolean(errors.expenseEntries?.[index]?.startDate)}
                                helperText={errors.expenseEntries?.[index]?.startDate?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].endDate`}
                        defaultValue={expense.endDate || null}
                        rules={{
                            required: required.required ? ((!billType && 'Bill type is required') || 'End date required') : false,
                            validate: required.required ? {
                                invalid: (value) => moment(value).isValid() || 'Invalid date',
                                maxDate: (value) =>
                                    moment(value).isSameOrBefore(maxEndDate) || 'Max date reached',
                                minDate: (value) =>
                                    moment(value).isSameOrAfter(minEndDate) ||
                                    'End date must be after start date',
                            } : false,
                        }}
                        render={({ onChange, value }) => (
                            <ExpenseDatePicker
                                onChange={(e) => onChange(handleDateChange(e))}
                                value={value}
                                id='end-date'
                                label='End date'
                                {...required}
                                minDate={minEndDate}
                                maxDate={maxEndDate}
                                disabled={!billType}
                                error={Boolean(errors.expenseEntries?.[index]?.endDate)}
                                helperText={errors.expenseEntries?.[index]?.endDate?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ExpenseDateRange;
