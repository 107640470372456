const DivisionEnum = {
    Anesthesia: 'Anesthesia',
    Corporate: 'Corporate',
    CustomerCare: 'CustomerCare',
    CVO: 'CVO',
    EmergencyMedicine: 'EmergencyMedicine',
    Government: 'Government',
    HospitalMedicine: 'HospitalMedicine',
    InternalMedicineSubSpecialties: 'InternalMedicineSubSpecialties',
    Malpractice: 'Malpractice',
    Neurology: 'Neurology',
    PrimaryCare: 'PrimaryCare',
    Psychiatry: 'Psychiatry',
    Radiology: 'Radiology',
    SurgeryClinicBased: 'SurgeryClinicBased',
    SurgeryHospitalBased: 'SurgeryHospitalBased'
};

const TypeEnum = {
    CustomerCareGiftsFlowers: 'CustomerCareGiftsFlowers',
    MalpracticeCompFundStateCompfunds: 'MalpracticeCompFundStateCompfunds',
    PSYCMalpracticeCompFund: 'PSYCMalpracticeCompFund',
    UnidentifiedLicensing: 'UnidentifiedLicensing',
    LodgingSecurityDeposit: 'LodgingSecurityDeposit',
    AssociateTravelPrepaid: 'AssociateTravelPrepaid',
    AssociateTravelAccrued: 'AssociateTravelAccrued',
    Disputed: 'Disputed',
    IncidentalsRounding: 'IncidentalsRounding',
    Rebate: 'Rebate'
};

export { DivisionEnum,TypeEnum };