import React, { useEffect, useState } from 'react';
import {
	COLORS,
	COUNTRIES,
	CRED_STATE,
	ACTIVE_PATHNAME_AP,
} from '../../../../../utils/Application_Constants';
import { withTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
	Grid,
	FormControl,
	FormHelperText,
	InputLabel,
	Input,
	ThemeProvider,
	CircularProgress,
	TextField,
	Chip,
	InputAdornment,
	NativeSelect,
	RadioGroup,
	Radio,
	FormControlLabel,
	IconButton,
	makeStyles,
} from '@material-ui/core';
import TextFieldComponent from '../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { birthstates } from '../../../Account/accountOptions';
import {
	emergencyContactRelationship,
	visaTypes,
	visaStatus,
	Dentist_Titles,
	Physician_Titles,
	Podiatrist_Titles,
	CNM_Titles,
	CNS_Titles,
	CRNA_Titles,
	NP_Titles,
	PA_Titles,
	SFA_Titles,
	Psychologist_Titles,
	PERFUSIONIST_Titles,
} from './personalInformationOptions';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import datePickerTheme from '../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import DatePickerComponent from '../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import moment from 'moment';
import { Event } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HelpIcon from '@material-ui/icons/Help';
import ToolTipComponent from '../../../../GlobalComponents/ToolTipComponent';
import InputMask from 'react-input-mask';
import {
	getPersonalInformation,
	postPersonalInformation,
} from '../../../../../services/Credentialing';
import WithContexts from '../../../../../contexts/withContexts';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
	personalInformationSectionPointsCheck,
	personalInformationSectionCheck,
} from '../../../../../utils/CredentialingAppUtility';
import { convertToDateIgnoringTime } from '../../../../../utils/helpers';
import DateFnsUtils from '@date-io/date-fns';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import { createTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { ltdomain } from '../../../../../utils/helpers';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.LT_MIDNIGHT,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	inputRadio: {
		// color: COLORS.LT_JOURNEY,
		'&.Mui-focused': {
			color: COLORS.LT_JOURNEY,
		},

		// fontWeight: '900',
		// fontSize: '12px',
		// whiteSpace: 'nowrap',
		// textTransform: 'uppercase',
	},

	inputLabel: {
		// color: COLORS.RAVEN,
		// fontWeight: '900',
		// fontSize: '12px',
		// whiteSpace: 'nowrap',
		// textTransform: 'uppercase'
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			// marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			// margin: '5px 10px',
		},
	},
	completedIcon: {
		color: 'green',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},

	typeAheadInput: {
		'label + &': {
			// marginTop: theme.spacing(2),
		},
		borderRadius: 0,
		padding: '12px',
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.LT_MIDNIGHT,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: '16px',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			padding: '5px',
		},
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			// boxShadow: COLORS.WHITE_LILAC,
			border: `1px solid ${COLORS.LT_JOURNEY}!important`,
			background: 'rgba(0,119,200, .1)!important',
		},
		'&:hover': {
			border: `1px solid ${COLORS.LT_JOURNEY}!important`,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
	},

	cssOutlinedInput: {
		height: 45,
		fontFamily: 'museo-sans',
		padding: '0px 0px',

		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
			transition: 'all 0.3s',
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			border: `1px solid ${COLORS.LT_JOURNEY}!important`, //hovered
		},
		'&$cssFocused $notchedOutline': {
			border: `1px solid ${COLORS.LT_JOURNEY}!important`,
		},
	},

	cssFocused: {},

	disabled: {},
	disabledSelect: {
		opacity: 0.6,
		cursor: 'default',
	},
	disabledInput: {
		opacity: 0.6,
	},
	focused: {},
	error: {
		borderColor: COLORS.LT_PEPPER, //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		[theme.breakpoints.up('md')]: {
			marginTop: '25vh',
			marginLeft: '30vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '20vh',
			marginLeft: '25vh',
		},
	},
	successGrid: {
		marginTop: '25px',
	},
	iconButtonMargin: {
		marginRight: '10px',
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '32px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
}));

function PersonalInformationForm({
	t,
	UserContext,
	setSaveHandler,
	credState,
	activePath,
	profession,
	blockedBackNavigation,
	flags,
	updateFlags,
	setCredAppError,
	sectionCompletion,
	updateSectionCompletion,
	setTabHandlerFromDialog,
	shouldHighlightFields,
	updateHighlightFieldsState,
}) {
	const normalTheme = createTheme({
		overrides: {
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '18px',
					borderRadius: '1px !important',
					'&$disabled': {
						opacity: '0.6',
					},
				},
			},
			MuiFormControlLabel: {
				root: {
					'&$disabled': {
						opacity: '0.6',
					},
				},
			},
		},
	});
	const errorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					// height: '17px',
					borderRadius: '1px !important',
					border: '2px solid red !important',
				},
			},
		},
	});

	const classes = styles();

	const [isLoaded, setIsLoaded] = useState(false);
	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);
	const [titles, setTitles] = useState(null);
	const [showSSN, setShowSSN] = useState(false);
	const [editableSSN, setEditableSSN] = useState(false);

	const getTitlesByProfession = (profession) => {
		if (profession === 'Dentist') return Dentist_Titles;
		if (profession === 'Physician') return Physician_Titles;
		if (profession === 'Podiatrist') return Podiatrist_Titles;
		if (profession === 'Psychologist') return Psychologist_Titles;
		if (profession === 'CNM') return CNM_Titles;
		if (profession === 'CNS') return CNS_Titles;
		if (profession === 'CRNA') return CRNA_Titles;
		if (profession === 'NP') return NP_Titles;
		if (profession === 'PA') return PA_Titles;
		if (profession === 'SFA') return SFA_Titles;
		if (profession === 'Perfusionist') return PERFUSIONIST_Titles;
	};

	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	const numberValidation = (value) => {
		//console.log(`numberValidation: ${value}`)
		if (!!!value) {
			return true;
		}

		const number = value.replace(/-/g, '').trim();
		//console.log('inside validate')
		if (!number) return true;
		return number.length === 10 && !isNaN(number) ? true : false;
	};

	const {
		register,
		control,
		handleSubmit,
		reset,
		errors,
		getValues,
		setValue,
		formState,
		watch,
		setError,
		clearErrors,
	} = useForm({
		mode: 'all',
		defaultValues: {
			titles: [],
			firstName: '',
			lastName: '',
			homeAddress: '',
			city: '',
			state: '',
			zipCode: '',
			homePhone: '',
			cellPhone: '',
			email: '',
			birthCity: '',
			birthState: '',
			birthCountry: '',
			ssn: '',
			dateOfBirth: null,
			otherName: '',
			usCitizen: `null`,
			medicaidNumber: '',
			medicareNumber: '',
			federalTaxId: '',
			caqhNumber: '',
			emergencyContactRelationship: '',
			emergencyContact: '',
			emergencyContactPhone: '',
			visaStatus: '',
			visaExpirationDate: null,
			visaType: '',
			visaNumber: '',
			clinicianId: '',
			npiNumber: '',
			profession: '',
		},
	});

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;
			setSaveHandler(
				PersonalInformationForm,
				'Personal Information',
				handleSubmit(async (response) => await onSubmit(response))
			);

			await getPersonalInformation(externalId)
				.then(function (response) {
					console.log('response is', response);

					if (null != response) {
						if (response['homePhone'] === null) {
							response['homePhone'] = '';
						}
						if (response['cellPhone'] === null) {
							response['cellPhone'] = '';
						}
						if (response['emergencyContactPhone'] === null) {
							response['emergencyContactPhone'] = '';
						}
					}

					if (response['visaStatus'] === null) {
						response['visaStatus'] = '';
					}

					if (response['ssn'] === null) {
						setEditableSSN(true);
					}
					response['ssn'] = SSNNumberFormatter(response['ssn']);

					response['usCitizen'] = `${response['usCitizen']}`;

					response['visaExpirationDate'] = convertToDateIgnoringTime(
						response['visaExpirationDate']
					);
					response['dateOfBirth'] = convertToDateIgnoringTime(response['dateOfBirth']);
					response['profession'] = profession;
					setTitles(getTitlesByProfession(profession));

					flags.dataSaved = false;
					updateFlags(flags);
					reset(response);
					setIsLoaded(true);

					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status = {
							status: 'incomplete',
							missingFields: [],
						};
						status = personalInformationSectionCheck(response);
						if (!!status.missingFields) {
							setHighlights(status.missingFields);
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);

	function updateHighlight() {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};
			status = personalInformationSectionCheck(getValues());
			setHighlights(status.missingFields);
		}
	}

	const filterTitleOptionsFromValue = (selectedValue) => {
		const selectedState = titles.filter((item) => item.value === selectedValue);
		if (!selectedState[0]) return selectedValue;
		return selectedState[0].label;
	};

	const autoCompleteDelete = (index, name) => {
		let values = watch(name);
		values.splice(index, 1);
		setValue(name, values);
	};

	const autoCompleteHandler = (newValue, name) => {
		const newValues = [];
		flags.isDirty = true;
		updateFlags(flags);
		newValue.forEach((item) => {
			if (typeof item === 'string') {
				newValues.push(item);
				return;
			}
			newValues.push(item.value);
		});
		if (watch(name) !== newValues) {
			setValue(name, newValues);
		}
	};

	function SSNNumberFormatter(number) {
		if (!number) return number;

		// only allows 0-9 inputs
		const currentValue = number.replace(/[^\d]/g, '');

		const cvLength = currentValue.length;
		if (cvLength >= 0) {
			if (cvLength < 4) return currentValue;
			if (cvLength < 6) return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}`;
			return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}-${currentValue.slice(
				5,
				9
			)}`;
		} else {
			return currentValue;
		}
	}

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}

		let missingRequiredToSaveFields = [];
		if (!!errors) {
			//This would be 'return anError.type !== 'manual'', but manually set errors return a malformed error object with no 'type' field (the contents of the type field are inserted as an array of chars on the main object)
			missingRequiredToSaveFields = Object.values(errors).filter((anError) => {
				var aRtn = anError.hasOwnProperty('type');
				return aRtn;
			});
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	async function onSubmit(data) {
		let success = false;

		const { externalId } = UserContext;
		data['clinicianId'] = externalId;

		if (data['usCitizen'] === true) {
			data['visaStatus'] = null;
			data['visaExpirationDate'] = null;
			data['visaType'] = null;
			data['visaNumber'] = null;
		} else if (
			data['usCitizen'] === false &&
			(data['visaStatus'] === '' ||
				data['visaStatus'] === null ||
				data['visaStatus'] === 'In Process')
		) {
			data['visaExpirationDate'] = null;
		}
		data['titles'] = getValues(`titles`);

		if (data['visaExpirationDate'] !== null) {
			data['visaExpirationDate'] = moment(data['visaExpirationDate']).toISOString(true);
		}

		if (data['dateOfBirth'] !== null) {
			data['dateOfBirth'] = moment(data['dateOfBirth']).toISOString(true);
		}

		const jsonData = JSON.stringify(data);
		await postPersonalInformation(jsonData, externalId);
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;

		sectionCompletion.personalInformation.current = await personalInformationSectionPointsCheck(
			data,
			sectionCompletion
		);
		updateSectionCompletion(sectionCompletion);

		updateFlags(flags);
		return success;
	}

	const handleClickShowSSN = () => {
		setShowSSN(!showSSN);
	};

	const handleMouseDownSSN = (event) => {
		event.preventDefault();
	};

	const ssnTextFieldChangeHandler = (event) => {
		const { value } = event.target;
		if (!!getValues(`ssn`)) {
			setValue(`ssn`, normalizeSSNInput(value));
		}
	};

	const socialSecurityNumberValidation = () => {
		const ssn = getValues(`ssn`);

		if (!!ssn) {
			var ssnVal = ssn.replace(/-/g, '').trim();
			if (ssnVal.length === 0 || (ssnVal.length === 9 && !isNaN(ssnVal))) {
				return true;
			} else {
				return false;
			}
		}
	};

	const normalizeSSNInput = (value, previousValue) => {
		if (!value) return value;

		const onlyNums = value.replace(/[^\d]/g, ''); // only allows 0-9
		if (!previousValue || value.length > previousValue.length) {
			if (onlyNums.length === 3) return `${onlyNums}`;
			if (onlyNums.length === 5) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-`;

			if (onlyNums.length <= 3) return onlyNums;
			if (onlyNums.length <= 5) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}`;

			return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(5, 9)}`;
		}
		return value;
	};

	const titlesValidation = () => {
		const varTitles = getValues(`titles`);
		if (!varTitles || varTitles.length === 0) {
			setError('titles', {
				type: 'manual',
				message: t('credentials:VALIDATION_MESSAGE.TITLES'),
			});
		} else if (varTitles.length === 1) {
			if (activePath === ACTIVE_PATHNAME_AP) {
				if (varTitles[0] === 'RN') {
					setError('titles', {
						type: 'manual',
						message: t('credentials:VALIDATION_MESSAGE.TITLES_RN'),
					});
				} else {
					clearErrors('titles');
				}
			} else {
				clearErrors('titles');
			}
		} else {
			clearErrors('titles');
		}
	};

	const usCitizenDecisionNode = () => {
		const normalTheme = createTheme({
			overrides: {
				MuiNativeSelect: {
					// Name of the rule
					select: {
						// Some CSS
						// height: '18px',
						borderRadius: '1px !important',
						'&$disabled': {
							opacity: '0.6',
						},
					},
				},
				MuiFormControlLabel: {
					root: {
						'&$disabled': {
							opacity: '0.6',
						},
					},
				},
			},
		});
		const errorTheme = createTheme({
			overrides: {
				// Style sheet name
				MuiNativeSelect: {
					// Name of the rule
					select: {
						// Some CSS
						height: '17px',
						borderRadius: '1px !important',
						border: '2px solid red !important',
					},
				},
			},
		});

		return (
			<Grid container className={classes.nestetdContainer}>
				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={12}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor='VisaType'
							classes={{ root: classes.inputLabel }}
							required
							error={
								errors?.visaType ||
								(!!highlights && highlights.includes(`visaType`))
							}
						>
							{t('credentials:PERSONAL_INFORMATION.VISA_TYPE')}
						</InputLabel>
						<ThemeProvider
							theme={
								errors?.visaType ||
								(!!highlights && highlights.includes(`visaType`))
									? errorTheme
									: normalTheme
							}
						>
							<NativeSelect
								id='VisaType'
								name='visaType'
								//onChange={(event) => { this.textFieldChangeHandler(event); }}
								onBlur={() => {
									updateHighlight();
								}}
								onChange={() => {
									updateHighlight();
								}}
								inputRef={register()}
								defaultValue={watch(`visaType`)}
								className={
									credState === CRED_STATE.CLOSED
										? classes.disabledSelect
										: classes.normalInput
								}
								disabled={credState === CRED_STATE.CLOSED}
								input={
									<TextFieldComponent
										style={{ border: `0px solid ${COLORS.RAVEN}` }}
										classes={{ input: classes.setWidthTo100OnMobile }}
									/>
								}
							>
								{visaTypes.map((item) => (
									<option key={item.value} value={item.value}>
										{item.label}
									</option>
								))}
							</NativeSelect>
						</ThemeProvider>
					</FormControl>
					{credState === CRED_STATE.CLOSED ? (
						<Input
							style={{ display: 'none' }}
							name={'visaType'}
							value={watch(`visaType`)}
						/>
					) : null}
				</Grid>
				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={12}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor='VisaStatus'
							classes={{ root: classes.inputLabel }}
							required
							error={
								errors?.visaStatus ||
								(!!highlights && highlights.includes(`visaStatus`))
							}
						>
							{t('credentials:PERSONAL_INFORMATION.VISA_STATUS')}
						</InputLabel>
						<ThemeProvider
							theme={
								errors?.visaStatus ||
								(!!highlights && highlights.includes(`visaStatus`))
									? errorTheme
									: normalTheme
							}
						>
							<NativeSelect
								id='VisaStatus'
								name='visaStatus'
								//onChange={(event) => { this.textFieldChangeHandler(event); }}
								onBlur={() => {
									updateHighlight();
								}}
								onChange={() => {
									updateHighlight();
								}}
								inputRef={register()}
								defaultValue={`${watch(`visaStatus`)}`}
								className={
									credState === CRED_STATE.CLOSED
										? classes.disabledSelect
										: classes.normalInput
								}
								disabled={credState === CRED_STATE.CLOSED}
								input={
									<TextFieldComponent
										style={{ border: `0px solid ${COLORS.RAVEN}` }}
										classes={{ input: classes.setWidthTo100OnMobile }}
									/>
								}
							>
								{visaStatus.map((item) => (
									<option key={item.value} value={item.value}>
										{item.label}
									</option>
								))}
							</NativeSelect>
						</ThemeProvider>
					</FormControl>
					{credState === CRED_STATE.CLOSED ? (
						<Input
							style={{ display: 'none' }}
							name={'visaStatus'}
							value={`${watch(`visaStatus`)}`}
						/>
					) : null}
				</Grid>
				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={12}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor='VisaNumber'
							classes={{ root: classes.inputLabel }}
							required
							error={
								errors?.visaNumber ||
								(!!highlights && highlights.includes(`visaNumber`))
							}
						>
							{t('credentials:PERSONAL_INFORMATION.VISA_NUMBER')}
						</InputLabel>
						<TextField
							id='VisaNumber'
							name='visaNumber'
							variant='outlined'
							inputRef={register({
								maxLength: {
									value: 255,
									message: t('common:MESSAGES.CHARACTER_LIMIT'),
								},
							})}
							error={
								errors?.visaNumber ||
								(!!highlights && highlights.includes(`visaNumber`))
							}
							onChange={() => {
								updateHighlight();
							}}
							onBlur={() => {
								updateHighlight();
							}}
							helperText={
								(!!errors?.visaNumber &&
									errors?.visaNumber['type'] !== 'maxLength') ||
								(!!highlights && highlights.includes(`visaNumber`))
									? ''
									: !!errors?.visaNumber &&
									  errors?.visaNumber['type'] === 'maxLength'
									? t('common:MESSAGES.CHARACTER_LIMIT')
									: ''
							}
							className={
								credState === CRED_STATE.CLOSED
									? classes.disabledInput
									: classes.textField
							}
							InputProps={{
								readOnly: credState === CRED_STATE.CLOSED,
								classes: {
									root: classes.cssOutlinedInput,
									focused: classes.cssFocused,
									notchedOutline: classes.notchedOutline,
									error: classes.error,
								},
							}}
						/>
					</FormControl>
				</Grid>
				{watch(`visaStatus`) === 'Active' || watch(`visaStatus`) === 'Expired' ? (
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={12}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor='VisaExpDate'
								classes={{ root: classes.inputLabel }}
								error={
									errors?.visaExpirationDate ||
									(!!highlights && highlights.includes(`visaExpirationDate`))
								}
								required
							>
								{t('credentials:PERSONAL_INFORMATION.VISA_EXP_DATE')}
							</InputLabel>
							<ThemeProvider theme={datePickerTheme}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Controller
										control={control}
										rules={{
											validate: (date) => dateValidation(date),
										}}
										defaultValue={
											`${watch(`visaExpirationDate`)}` === null
												? null
												: `${watch(`visaExpirationDate`)}`
										}
										name={`visaExpirationDate`}
										render={({ onChange, onBlur, value, name }) => (
											<DatePickerComponent
												theme={datePickerTheme}
												error={
													errors?.visaExpirationDate ||
													(!!highlights &&
														highlights.includes(`visaExpirationDate`))
												}
												variant='inline'
												autoOk='true'
												clearable
												format='MM/dd/yyyy'
												placeholder='MM/DD/YYYY'
												name={name}
												value={value}
												onClose={() => {
													updateHighlight();
												}}
												minDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												maxDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												invalidDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												disabled={credState === CRED_STATE.CLOSED}
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<Event />
														</InputAdornment>
													),
													classes: {
														root: classes.cssOutlinedInput,
														error: classes.error,
													},
												}}
												id='VisaExpDate'
												//value={this.datePickerHelper(visaExpirationDate)}
												// onChange={(date, parsedDate) => { this.dateChangeHandler(date, parsedDate, 'visaExpirationDate'); }}
												onChange={onChange}
												onBlur={() => {
													onBlur(updateHighlight());
												}}
												helperText={
													errors?.visaExpirationDate &&
													t('credentials:VALIDATION_MESSAGE.DATE')
												}
												KeyboardButtonProps={{
													'aria-label': 'Visa Expiration Date',
												}}
												inputVariant='outlined'
											/>
										)}
									/>
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</FormControl>
					</Grid>
				) : null}
			</Grid>
		);
	};

	const renderBirthState = (country) => {
		if (country !== 'US' && country !== 'U.S.' && country !== 'United States') {
			return null;
		}
		const normalTheme = createTheme({
			overrides: {
				MuiNativeSelect: {
					// Name of the rule
					select: {
						// Some CSS
						height: '18px',
						borderRadius: '1px !important',
						'&$disabled': {
							opacity: '0.6',
						},
					},
				},
				MuiFormControlLabel: {
					root: {
						'&$disabled': {
							opacity: '0.6',
						},
					},
				},
			},
		});
		const errorTheme = createTheme({
			overrides: {
				// Style sheet name
				MuiNativeSelect: {
					// Name of the rule
					select: {
						// Some CSS
						height: '17px',
						borderRadius: '1px !important',
						border: '2px solid red !important',
					},
				},
			},
		});
		return (
			<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
				<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
					<InputLabel
						shrink
						htmlFor='BirthState'
						classes={{ root: classes.inputLabel }}
						required
						error={
							errors?.birthState ||
							(!!highlights && highlights.includes(`birthState`))
						}
					>
						{t('credentials:PERSONAL_INFORMATION.BIRTH_STATE')}
					</InputLabel>
					<ThemeProvider
						theme={
							errors?.birthState ||
							(!!highlights && highlights.includes(`birthState`))
								? errorTheme
								: normalTheme
						}
					>
						<NativeSelect
							id='BirthState'
							name='birthState'
							//onChange={this.textFieldChangeHandler}
							onBlur={() => {
								updateHighlight();
							}}
							onChange={() => {
								updateHighlight();
							}}
							defaultValue={`${watch(`birthState`)}`}
							inputRef={register()}
							className={
								credState === CRED_STATE.CLOSED ||
								credState === CRED_STATE.PARTIALLY_OPEN
									? classes.disabledSelect
									: classes.normalInput
							}
							disabled={
								credState === CRED_STATE.CLOSED ||
								credState === CRED_STATE.PARTIALLY_OPEN
							}
							input={
								<TextFieldComponent
									style={{ border: `0px solid ${COLORS.RAVEN}` }}
									classes={{ input: classes.setWidthTo100OnMobile }}
								/>
							}
						>
							<option key={''} value={null}></option>

							{birthstates.map((item) => (
								<option key={item.value} value={item.value}>
									{item.label}
								</option>
							))}
						</NativeSelect>
					</ThemeProvider>
				</FormControl>
				{credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN ? (
					<Input
						style={{ display: 'none' }}
						name={'birthState'}
						value={`${watch(`birthState`)}`}
					/>
				) : null}
			</Grid>
		);
	};

	const emergencyContactNode = () => {
		return (
			<Grid container className={classes.nestetdContainer}>
				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={12}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor='EmergencyContactName'
							classes={{ root: classes.inputLabel }}
							required
							error={
								errors?.emergencyContact ||
								(!!highlights && highlights.includes(`emergencyContact`))
							}
						>
							{t('credentials:PERSONAL_INFORMATION.EMERGENCY_CONTACT_NAME')}
						</InputLabel>
						<TextField
							id='EmergencyContactName'
							name='emergencyContact'
							className={classes.textField}
							value={watch(`emergencyContact`)}
							variant='outlined'
							//onChange={this.textFieldChangeHandler}
							onBlur={() => {
								updateHighlight();
							}}
							onChange={() => {
								updateHighlight();
							}}
							inputRef={register({
								maxLength: {
									value: 100,
									message: t('common:MESSAGES.CHARACTER_LIMIT'),
								},
							})}
							error={
								errors?.emergencyContact ||
								(!!highlights && highlights.includes(`emergencyContact`))
							}
							helperText={
								(!!errors?.emergencyContact &&
									errors?.emergencyContact['type'] !== 'maxLength') ||
								(!!highlights && highlights.includes(`emergencyContact`))
									? ''
									: !!errors?.emergencyContact &&
									  errors?.emergencyContact['type'] === 'maxLength'
									? t('common:MESSAGES.CHARACTER_LIMIT')
									: ''
							}
							InputProps={{
								classes: {
									root: classes.cssOutlinedInput,
									focused: classes.cssFocused,
									notchedOutline: classes.notchedOutline,
									error: classes.error,
								},
							}}
							// helperText={
							//     validationFlags.emergencyContactNameFlag &&
							//     t('credentials:VALIDATION_MESSAGE.EMERGENCY_CONTACT_NAME')
							// }
						/>
					</FormControl>
				</Grid>
				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={12}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor='EmergencyContactPhone'
							classes={{ root: classes.inputLabel }}
							error={
								errors?.emergencyContactPhone ||
								(!!highlights && highlights.includes(`emergencyContactPhone`))
							}
							required
						>
							{t('credentials:PERSONAL_INFORMATION.EMERGENCY_CONTACT_PHONE')}
						</InputLabel>
						<Controller
							control={control}
							name={`emergencyContactPhone`}
							rules={{
								validate: (value) => numberValidation(value),
							}}
							render={({ onChange, name, onBlur, value, ref }) => (
								<InputMask
									name={name}
									mask='999-999-9999'
									value={watch(`emergencyContactPhone`)}
									disabled={false}
									maskChar=' '
									onChange={onChange}
									onBlur={() => {
										updateHighlight();
									}}
								>
									{() => (
										<TextField
											id='EmergencyContactPhone'
											name={name}
											className={classes.textField}
											defaultValue={watch(`emergencyContactPhone`)}
											variant='outlined'
											placeholder='XXX-XXX-XXXX'
											InputProps={{
												classes: {
													root: classes.cssOutlinedInput,
													focused: classes.cssFocused,
													notchedOutline: classes.notchedOutline,
													error: classes.error,
												},
											}}
											error={
												errors?.emergencyContactPhone ||
												(!!highlights &&
													highlights.includes(`emergencyContactPhone`))
											}
											helperText={
												(errors?.emergencyContactPhone ||
													(!!highlights &&
														highlights.includes(
															`emergencyContactPhone`
														))) &&
												t(
													'credentials:VALIDATION_MESSAGE.EMERGENCY_CONTACT_NUMBER'
												)
											}
										/>
									)}
								</InputMask>
							)}
						/>
					</FormControl>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			{!isLoaded ? (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			) : (
				<div className={classes.profileWrapper}>
					<RouteDeparturePrompt
						blockedBackNavigation={blockedBackNavigation}
						when={flags.isDirty}
						setTabHandlerFromDialog={setTabHandlerFromDialog}
					/>
					<Grid container>
						<Grid item xs={12}>
							<h3 className={classes.header}>
								{t('credentials:PERSONAL_INFORMATION.HEADER_TITLE')}
							</h3>
						</Grid>
						{credState === CRED_STATE.CLOSED ||
						credState === CRED_STATE.PARTIALLY_OPEN ? (
							<Grid item xs={12} md={10} className={classes.messageGrid}>
								<Alert
									severity='error'
									className={classes.alertMessage}
									icon={false}
								>
									{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
								</Alert>
							</Grid>
						) : null}
					</Grid>
					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={7}>
							<Input
								style={{ display: 'none' }}
								name={`profession`}
								value={`${watch(`profession`)}`}
								inputRef={register()}
							/>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='Title'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.titles ||
										(!!highlights && highlights.includes(`titles`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.TITLES')}
								</InputLabel>
								<ThemeProvider theme={!!errors?.titles ? errorTheme : normalTheme}>
									<Controller
										name={`titles`}
										control={control}
										render={({ onChange, name, onBlur, value, ref }) => (
											<Autocomplete
												multiple
												options={titles.map((item) => {
													return item.label;
												})}
												getOptionLabel={(option) =>
													filterTitleOptionsFromValue(option)
												}
												id='title'
												name={name}
												inputRef={ref}
												value={value}
												filterSelectedOptions
												onChange={(event, newValue) => {
													autoCompleteHandler(newValue, `titles`);
													titlesValidation();
												}}
												disabled={credState === CRED_STATE.CLOSED}
												blurOnSelect={true}
												renderTags={(value, { className }) =>
													value.map((option, index) => (
														<Chip
															key={`chip-${index + 1}`}
															variant='outlined'
															classes={{
																root: classes.chipRoot,
																label: classes.chipLabel,
															}}
															data-tag-index={index}
															tabIndex={-1}
															label={filterTitleOptionsFromValue(
																option
															)}
															className={className}
															onDelete={() => {
																autoCompleteDelete(index, 'titles');
																titlesValidation();
															}}
															disabled={
																credState === CRED_STATE.CLOSED
															}
														/>
													))
												}
												renderInput={(params) => (
													<TextField
														margin='dense'
														{...params}
														ref={params.InputProps.ref}
														classes={{
															root: classes.typeAheadInput,
															error: classes.error,
														}}
														variant='standard'
														fullWidth
														error={!!errors?.titles}
													/>
												)}
												onBlur={() => {
													titlesValidation();
													updateHighlight();
												}}
											/>
										)}
									/>
								</ThemeProvider>

								{(!!errors?.titles ||
									(!!highlights && highlights.includes(`titles`))) && (
									<FormHelperText className={'Mui-error'}>
										{errors.titles && <p>{errors.titles.message}</p>}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={5}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='FirstName'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.firstName ||
										(!!highlights && highlights.includes(`firstName`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.FIRST_NAME')}
								</InputLabel>
								<ThemeProvider
									theme={
										!!errors?.firstName ||
										(!!highlights && highlights.includes(`firstName`))
											? errorTheme
											: normalTheme
									}
								>
									<TextField
										margin='dense'
										id='FirstName'
										name='firstName'
										className={
											credState === CRED_STATE.CLOSED ||
											credState === CRED_STATE.PARTIALLY_OPEN
												? classes.disabledInput
												: classes.textField
										}
										required
										variant='outlined'
										error={
											!!errors?.firstName ||
											(!!highlights && highlights.includes(`firstName`))
										}
										inputRef={register({
											required: true,
											maxLength: {
												value: 100,
												message: t('common:MESSAGES.CHARACTER_LIMIT'),
											},
										})}
										onBlur={() => {
											updateHighlight();
										}}
										helperText={
											(!!errors?.firstName &&
												errors?.firstName['type'] !== 'maxLength') ||
											(!!highlights && highlights.includes(`firstName`))
												? t('credentials:VALIDATION_MESSAGE.FIRST_NAME')
												: !!errors?.firstName &&
												  errors?.firstName['type'] === 'maxLength'
												? t('common:MESSAGES.CHARACTER_LIMIT')
												: ''
										}
										InputProps={{
											readOnly:
												credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN,
											classes: {
												root: classes.cssOutlinedInput,
												focused: classes.cssFocused,
												notchedOutline: classes.notchedOutline,
												error: classes.error,
											},
										}}
									/>
								</ThemeProvider>
							</FormControl>
						</Grid>
						{/* <Grid item xs={1} md={1} classes={{ root: classes.successGrid }}>
                            {this.showOrHideSuccessIcon(completedFlags.firstNameCompleted)}
                        </Grid> */}

						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={5}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='LastName'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.lastName ||
										(!!highlights && highlights.includes(`lastName`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.LAST_NAME')}
								</InputLabel>
								<ThemeProvider
									theme={
										!!errors?.lastName ||
										(!!highlights && highlights.includes(`lastName`))
											? errorTheme
											: normalTheme
									}
								>
									<TextField
										margin='dense'
										id='LastName'
										name='lastName'
										className={
											credState === CRED_STATE.CLOSED ||
											credState === CRED_STATE.PARTIALLY_OPEN
												? classes.disabledInput
												: classes.textField
										}
										inputRef={register({
											required: true,
											maxLength: {
												value: 100,
												message: t('common:MESSAGES.CHARACTER_LIMIT'),
											},
										})}
										onBlur={() => {
											updateHighlight();
										}}
										variant='outlined'
										error={
											!!errors?.lastName ||
											(!!highlights && highlights.includes(`lastName`))
										}
										helperText={
											(!!errors?.lastName &&
												errors?.lastName['type'] !== 'maxLength') ||
											(!!highlights && highlights.includes(`lastName`))
												? t('credentials:VALIDATION_MESSAGE.LAST_NAME')
												: !!errors?.lastName &&
												  errors?.lastName['type'] === 'maxLength'
												? t('common:MESSAGES.CHARACTER_LIMIT')
												: ''
										}
										InputProps={{
											readOnly:
												credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN,
											classes: {
												root: classes.cssOutlinedInput,
												focused: classes.cssFocused,
												notchedOutline: classes.notchedOutline,
												error: classes.error,
											},
										}}
									/>
								</ThemeProvider>
							</FormControl>
						</Grid>
						{/* <Grid item xs={1} md={1} classes={{ root: classes.successGrid }}>
                            {this.showOrHideSuccessIcon(completedFlags.lastNameCompleted)}
                        </Grid> */}
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={7}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='OtherName'
									classes={{ root: classes.inputLabel }}
									error={
										!!errors?.otherName ||
										(!!highlights && highlights.includes(`otherName`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.PREVIOUS_NAME')}
								</InputLabel>
								<TextField
									margin='dense'
									id='OtherName'
									name='otherName'
									className={
										credState === CRED_STATE.CLOSED
											? classes.disabledInput
											: classes.textField
									}
									required
									variant='outlined'
									error={
										!!errors?.otherName ||
										(!!highlights && highlights.includes(`otherName`))
									}
									inputRef={register({
										maxLength: {
											value: 100,
											message: t('common:MESSAGES.CHARACTER_LIMIT'),
										},
									})}
									onBlur={() => {
										updateHighlight();
									}}
									helperText={
										(!!errors?.otherName &&
											errors?.otherName['type'] !== 'maxLength') ||
										(!!highlights && highlights.includes(`otherName`))
											? t('credentials:VALIDATION_MESSAGE.PREVIOUS_NAME')
											: !!errors?.otherName &&
											  errors?.otherName['type'] === 'maxLength'
											? t('common:MESSAGES.CHARACTER_LIMIT')
											: ''
									}
									InputProps={{
										readOnly: credState === CRED_STATE.CLOSED,
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
									}}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={7}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='HomeAddress'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.homeAddress ||
										(!!highlights && highlights.includes(`homeAddress`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.HOME_ADDRESS')}
								</InputLabel>
								<ThemeProvider
									theme={!!errors?.homeAddress ? errorTheme : normalTheme}
								>
									<TextField
										margin='dense'
										id='HomeAddress'
										name='homeAddress'
										className={classes.textField}
										variant='outlined'
										error={
											!!errors?.homeAddress ||
											(!!highlights && highlights.includes(`homeAddress`))
										}
										inputRef={register({
											maxLength: {
												value: 255,
												message: t('common:MESSAGES.CHARACTER_LIMIT'),
											},
										})}
										onBlur={() => {
											updateHighlight();
										}}
										helperText={
											(!!errors?.homeAddress &&
												errors?.homeAddress['type'] !== 'maxLength') ||
											(!!highlights && highlights.includes(`homeAddress`))
												? t('credentials:VALIDATION_MESSAGE.HOME_ADDRESS')
												: !!errors?.homeAddress &&
												  errors?.homeAddress['type'] === 'maxLength'
												? t('common:MESSAGES.CHARACTER_LIMIT')
												: ''
										}
										InputProps={{
											classes: {
												root: classes.cssOutlinedInput,
												focused: classes.cssFocused,
												notchedOutline: classes.notchedOutline,
												error: classes.error,
											},
										}}
									/>
								</ThemeProvider>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='City'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.city ||
										(!!highlights && highlights.includes(`city`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.CITY')}
								</InputLabel>
								<ThemeProvider
									theme={
										!!errors?.city ||
										(!!highlights && highlights.includes(`city`))
											? errorTheme
											: normalTheme
									}
								>
									<TextField
										margin='dense'
										id='City'
										name='city'
										className={classes.textField}
										error={
											!!errors?.city ||
											(!!highlights && highlights.includes(`city`))
										}
										inputRef={register({
											required: true,
											maxLength: {
												value: 40,
												message: t('common:MESSAGES.CHARACTER_LIMIT'),
											},
										})}
										variant='outlined'
										onBlur={() => {
											updateHighlight();
										}}
										helperText={
											(!!errors?.city &&
												errors?.city['type'] !== 'maxLength') ||
											(!!highlights && highlights.includes(`city`))
												? t('credentials:VALIDATION_MESSAGE.CITY')
												: !!errors?.city &&
												  errors?.city['type'] === 'maxLength'
												? t('common:MESSAGES.CHARACTER_LIMIT')
												: ''
										}
										InputProps={{
											classes: {
												root: classes.cssOutlinedInput,
												focused: classes.cssFocused,
												notchedOutline: classes.notchedOutline,
												error: classes.error,
											},
										}}
									/>
								</ThemeProvider>
							</FormControl>
						</Grid>

						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='State'
									required
									error={
										!!errors?.state ||
										(!!highlights && highlights.includes(`state`))
									}
									className={
										((errors?.state && classes.errorColor) ||
											(!!highlights && highlights.includes(`state`))) &&
										classes.errorColor
									}
								>
									{t('credentials:PERSONAL_INFORMATION.STATE')}
								</InputLabel>
								<ThemeProvider
									theme={
										!!errors?.state ||
										(!!highlights && highlights.includes(`state`))
											? errorTheme
											: normalTheme
									}
								>
									<NativeSelect
										id='State'
										name='state'
										//inputRef={register()}
										inputRef={register({
											required: true,
										})}
										onBlur={() => {
											updateHighlight();
										}}
										onChange={() => {
											updateHighlight();
										}}
										input={
											<TextFieldComponent
												style={{ border: `0px solid ${COLORS.RAVEN}` }}
												classes={{
													input: classes.setWidthTo100OnMobile,
													error: classes.error,
												}}
											/>
										}
									>
										<option key={''} value={null}></option>
										{birthstates.map((item) => (
											<option key={item.value} value={item.value}>
												{item.label}
											</option>
										))}
									</NativeSelect>
								</ThemeProvider>
								{(errors?.state ||
									(!!highlights && highlights.includes(`state`))) && (
									<FormHelperText className={'Mui-error'}>
										{t('credentials:VALIDATION_MESSAGE.STATE')}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>

						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='ZipCode'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.zipCode ||
										(!!highlights && highlights.includes(`zipCode`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.ZIP_CODE')}
								</InputLabel>
								<ThemeProvider
									theme={
										!!errors?.zipCode ||
										(!!highlights && highlights.includes(`zipCode`))
											? errorTheme
											: normalTheme
									}
								>
									<TextField
										margin='dense'
										id='ZipCode'
										name='zipCode'
										className={classes.textField}
										variant='outlined'
										error={
											!!errors?.zipCode ||
											(!!highlights && highlights.includes(`zipCode`))
										}
										onBlur={() => {
											updateHighlight();
										}}
										inputRef={register({
											maxLength: {
												value: 5,
												message: t('common:MESSAGES.CHARACTER_LIMIT'),
											},
											minLength: 5,
										})}
										helperText={
											(!!errors?.zipCode ||
												(!!highlights && highlights.includes(`zipCode`))) &&
											t('credentials:VALIDATION_MESSAGE.ZIP_CODE')
										}
										InputProps={{
											classes: {
												root: classes.cssOutlinedInput,
												focused: classes.cssFocused,
												notchedOutline: classes.notchedOutline,
												error: classes.error,
											},
										}}
									/>
								</ThemeProvider>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='HomePhone'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.homePhone ||
										(!!highlights && highlights.includes(`homePhone`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.HOME_PHONE')}
								</InputLabel>
								<Controller
									control={control}
									name={`homePhone`}
									rules={{
										validate: (value) => numberValidation(value),
									}}
									render={({ onChange, name, onBlur, value, ref }) => (
										<InputMask
											name={name}
											mask='999-999-9999'
											disabled={false}
											maskChar=' '
											onBlur={() => {
												updateHighlight();
											}}
											onChange={onChange}
											defaultValue={`${watch(`homePhone`)}`}
										>
											{() => (
												<TextField
													margin='dense'
													name={name}
													id='HomePhone'
													className={classes.textField}
													variant='outlined'
													placeholder='XXX-XXX-XXXX'
													error={
														!!errors?.homePhone ||
														(!!highlights &&
															highlights.includes(`homePhone`))
													}
													helperText={
														(!!errors?.homePhone ||
															(!!highlights &&
																highlights.includes(
																	`homePhone`
																))) &&
														t(
															'credentials:VALIDATION_MESSAGE.HOME_PHONE'
														)
													}
													InputProps={{
														classes: {
															root: classes.cssOutlinedInput,
															focused: classes.cssFocused,
															notchedOutline: classes.notchedOutline,
															error: classes.error,
														},
													}}
												/>
											)}
										</InputMask>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='MobilePhone'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.cellPhone ||
										(!!highlights && highlights.includes(`cellPhone`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.MOBILE_PHONE')}
								</InputLabel>
								<Controller
									control={control}
									name={`cellPhone`}
									rules={{
										validate: (value) => numberValidation(value),
									}}
									render={({ onChange, name, onBlur, value, ref }) => (
										<InputMask
											name={name}
											mask='999-999-9999'
											disabled={false}
											maskChar=' '
											onChange={onChange}
											onBlur={() => {
												updateHighlight();
											}}
											defaultValue={`${watch(`cellPhone`)}`}
										>
											{() => (
												<TextField
													margin='dense'
													id='MobilePhone'
													name={name}
													className={classes.textField}
													variant='outlined'
													placeholder='XXX-XXX-XXXX'
													error={
														!!errors?.cellPhone ||
														(!!highlights &&
															highlights.includes(`cellPhone`))
													}
													helperText={
														(!!errors?.cellPhone ||
															(!!highlights &&
																highlights.includes(
																	`cellPhone`
																))) &&
														t(
															'credentials:VALIDATION_MESSAGE.MOBILE_PHONE'
														)
													}
													InputProps={{
														classes: {
															root: classes.cssOutlinedInput,
															focused: classes.cssFocused,
															notchedOutline: classes.notchedOutline,
															error: classes.error,
														},
													}}
												/>
											)}
										</InputMask>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={7}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='EmailAddress'
									classes={{ root: classes.inputLabel }}
									required
									error={
										errors?.email ||
										(!!highlights && highlights.includes(`email`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.EMAIL_ADDRESS')}
								</InputLabel>
								<TextField
									margin='dense'
									id='EmailAddress'
									name='email'
									className={classes.disabledInput}
									variant='outlined'
									onBlur={() => {
										updateHighlight();
									}}
									inputRef={register({
										required: true,
										maxLength: {
											value: 255,
											message: t('common:MESSAGES.CHARACTER_LIMIT'),
										},
									})}
									error={
										errors?.email ||
										(!!highlights && highlights.includes(`email`))
									}
									InputProps={{
										readOnly: true,
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
										},
									}}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={7}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='BirthCity'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.birthCity ||
										(!!highlights && highlights.includes(`birthCity`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.BIRTH_CITY')}
								</InputLabel>
								<TextField
									margin='dense'
									id='BirthCity'
									name='birthCity'
									className={
										credState === CRED_STATE.CLOSED ||
										credState === CRED_STATE.PARTIALLY_OPEN
											? classes.disabledInput
											: classes.textField
									}
									variant='outlined'
									error={
										!!errors?.birthCity ||
										(!!highlights && highlights.includes(`birthCity`))
									}
									inputRef={register({
										maxLength: {
											value: 255,
											message: t('common:MESSAGES.CHARACTER_LIMIT'),
										},
									})}
									onBlur={() => {
										updateHighlight();
									}}
									helperText={
										(!!errors?.birthCity &&
											errors?.birthCity['type'] !== 'maxLength') ||
										(!!highlights && highlights.includes(`birthCity`))
											? ''
											: !!errors?.birthCity &&
											  errors?.birthCity['type'] === 'maxLength'
											? t('common:MESSAGES.CHARACTER_LIMIT')
											: ''
									}
									InputProps={{
										readOnly:
											credState === CRED_STATE.CLOSED ||
											credState === CRED_STATE.PARTIALLY_OPEN,
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
									}}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='BirthCountry'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.birthCountry ||
										(!!highlights && highlights.includes(`birthCountry`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.BIRTH_COUNTRY')}
								</InputLabel>
								<ThemeProvider
									theme={
										!!errors?.birthCountry ||
										(!!highlights && highlights.includes(`birthCountry`))
											? errorTheme
											: normalTheme
									}
								>
									<NativeSelect
										id='BirthCountry'
										name='birthCountry'
										inputRef={register()}
										onBlur={() => {
											updateHighlight();
										}}
										onChange={() => {
											updateHighlight();
										}}
										className={
											credState === CRED_STATE.CLOSED ||
											credState === CRED_STATE.PARTIALLY_OPEN
												? classes.disabledSelect
												: classes.normalInput
										}
										disabled={
											credState === CRED_STATE.CLOSED ||
											credState === CRED_STATE.PARTIALLY_OPEN
										}
										input={
											<TextFieldComponent
												style={{ border: `0px solid ${COLORS.RAVEN}` }}
												classes={{ input: classes.setWidthTo100OnMobile }}
											/>
										}
									>
										{' '}
										<option key={''} value={null}></option>
										{COUNTRIES.map((item) => (
											<option key={item} value={item}>
												{item}
											</option>
										))}
									</NativeSelect>
								</ThemeProvider>
							</FormControl>
							{credState === CRED_STATE.CLOSED ||
							credState === CRED_STATE.PARTIALLY_OPEN ? (
								<Input style={{ display: 'none' }} name={'birthCountry'} />
							) : null}
						</Grid>
						{renderBirthState(watch(`birthCountry`))}
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='DateOfBirth'
									classes={{ root: classes.inputLabel }}
									error={
										!!errors?.dateOfBirth ||
										(!!highlights && highlights.includes(`dateOfBirth`))
									}
									required
								>
									{t('credentials:PERSONAL_INFORMATION.DATE_OF_BIRTH')}
								</InputLabel>
								<ThemeProvider theme={datePickerTheme}>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<Controller
											control={control}
											rules={{
												validate: (date) => dateValidation(date),
											}}
											defaultValue={
												`${watch(`dateOfBirth`)}` === 'null'
													? null
													: `${watch(`dateOfBirth`)}`
											}
											name={`dateOfBirth`}
											render={({ onChange, onBlur, value, name }) => (
												<DatePickerComponent
													margin='dense'
													theme={datePickerTheme}
													error={
														!!errors?.dateOfBirth ||
														(!!highlights &&
															highlights.includes(`dateOfBirth`))
													}
													variant='inline'
													autoOk='true'
													clearable={true}
													format='MM/dd/yyyy'
													placeholder='MM/DD/YYYY'
													name={name}
													value={value}
													onClose={() => {
														updateHighlight();
													}}
													minDateMessage={
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.DATE'
															)}
														</span>
													}
													maxDateMessage={
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.DATE'
															)}
														</span>
													}
													invalidDateMessage={
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.DATE'
															)}
														</span>
													}
													disabled={
														credState === CRED_STATE.CLOSED ||
														credState === CRED_STATE.PARTIALLY_OPEN
													}
													InputProps={{
														endAdornment: (
															<InputAdornment position='end'>
																<Event />
															</InputAdornment>
														),
														classe: {
															root: classes.cssOutlinedInput,
															error: classes.error,
														},
													}}
													id='DateOfBirth'
													onBlur={() => {
														onBlur(updateHighlight());
													}}
													onChange={onChange}
													helperText={
														!!errors?.dateOfBirth &&
														t('credentials:VALIDATION_MESSAGE.DATE')
													}
													KeyboardButtonProps={{
														'aria-label': 'Date of Birth',
													}}
													inputVariant='outlined'
												/>
											)}
										/>
									</MuiPickersUtilsProvider>
								</ThemeProvider>
							</FormControl>
						</Grid>

						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='NPINumber'
									classes={{ root: classes.inputLabel }}
									required
									error={
										!!errors?.npiNumber ||
										(!!highlights && highlights.includes(`npiNumber`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.NPI')}
								</InputLabel>
								<TextField
									margin='dense'
									id='NPINumber'
									name='npiNumber'
									className={
										credState === CRED_STATE.CLOSED ||
										credState === CRED_STATE.PARTIALLY_OPEN
											? classes.disabledInput
											: classes.textField
									}
									variant='outlined'
									error={
										!!errors?.npiNumber ||
										(!!highlights && highlights.includes(`npiNumber`))
									}
									inputRef={register({
										maxLength: {
											value: 20,
											message: t('common:MESSAGES.CHARACTER_LIMIT'),
										},
									})}
									onBlur={() => {
										updateHighlight();
									}}
									helperText={
										(!!errors?.npiNumber &&
											errors?.npiNumber['type'] !== 'maxLength') ||
										(!!highlights && highlights.includes(`npiNumber`))
											? t('credentials:VALIDATION_MESSAGE.NPI_NUMBER')
											: !!errors?.npiNumber &&
											  errors?.npiNumber['type'] === 'maxLength'
											? t('common:MESSAGES.CHARACTER_LIMIT')
											: ''
									}
									InputProps={{
										readOnly:
											credState === CRED_STATE.CLOSED ||
											credState === CRED_STATE.PARTIALLY_OPEN,
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
									}}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='SocialSecurityNumber'
									classes={{ root: classes.inputLabel }}
									error={
										!!errors?.ssn ||
										(!!highlights && highlights.includes(`ssn`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.SOCIAL_SECURITY_NUMBER')}

									<ToolTipComponent
										disableFocusListener
										enterTouchDelay={150}
										interactive
										placement='right'
										title={
											<>
												<span>
													We require your social security number solely in
													order to verify your identity.{' '}
													<a
														target='_blank'
														rel='noopener noreferrer'
														href={ltdomain()}
													>
														<u>LocumTenens.com</u>
													</a>{' '}
													uses a variety of methods to ensure that your
													data is safe and secure. If you do not provide
													your SSN, we will be in touch via phone. Please
													see our{' '}
													<a
														target='_blank'
														rel='noopener noreferrer'
														href={ltdomain() + '/privacy/'}
													>
														<u>privacy policy</u>
													</a>{' '}
													for additional information.
												</span>
											</>
										}
									>
										<HelpIcon
											style={{
												marginTop: '-10px',
												fontSize: '16px',
												cursor: 'pointer',
												position: 'relative',
												left: '8px',
												bottom: '-3',
											}}
											color={!!errors?.ssn ? 'error' : 'action'}
										/>
									</ToolTipComponent>
								</InputLabel>

								<TextField
									margin='dense'
									id='SocialSecurityNumber'
									name='ssn'
									className={
										credState === CRED_STATE.CLOSED ||
										credState === CRED_STATE.PARTIALLY_OPEN ||
										!editableSSN
											? classes.disabledInput
											: classes.textField
									}
									type={showSSN ? 'text' : 'password'}
									variant='outlined'
									placeholder='XXX-XX-XXXX'
									error={
										!!errors?.ssn ||
										(!!highlights && highlights.includes(`ssn`))
									}
									onBlur={() => {
										updateHighlight();
									}}
									onChange={ssnTextFieldChangeHandler}
									inputRef={register({
										validate: socialSecurityNumberValidation,
									})}
									helperText={
										(!!errors?.ssn ||
											(!!highlights && highlights.includes(`ssn`))) &&
										t('credentials:VALIDATION_MESSAGE.SOCIAL_SECURITY_NUMBER')
									}
									InputProps={{
										readOnly:
											credState === CRED_STATE.CLOSED ||
											credState === CRED_STATE.PARTIALLY_OPEN ||
											!editableSSN,
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle SSN visibility'
													onClick={handleClickShowSSN}
													onMouseDown={handleMouseDownSSN}
													edge='end'
													className={classes.iconButtonMargin}
												>
													{showSSN ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>

						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='FederalTaxIdNumber'
									classes={{ root: classes.inputLabel }}
									error={
										!!errors?.federalTaxId ||
										(!!highlights && highlights.includes(`federalTaxId`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.FEDERAL_TAX_ID_NUM')}
								</InputLabel>
								<TextField
									margin='dense'
									id='FederalTaxIdNumber'
									name='federalTaxId'
									className={
										credState === CRED_STATE.CLOSED
											? classes.disabledInput
											: classes.textField
									}
									variant='outlined'
									error={
										!!errors?.federalTaxId ||
										(!!highlights && highlights.includes(`federalTaxId`))
									}
									inputRef={register({
										maxLength: {
											value: 50,
											message: t('common:MESSAGES.CHARACTER_LIMIT'),
										},
									})}
									onBlur={() => {
										updateHighlight();
									}}
									helperText={
										(!!errors?.federalTaxId &&
											errors?.federalTaxId['type'] !== 'maxLength') ||
										(!!highlights && highlights.includes(`federalTaxId`))
											? t('credentials:VALIDATION_MESSAGE.FEDERAL_TAX_ID_NUM')
											: !!errors?.federalTaxId &&
											  errors?.federalTaxId['type'] === 'maxLength'
											? t('common:MESSAGES.CHARACTER_LIMIT')
											: ''
									}
									InputProps={{
										readOnly: credState === CRED_STATE.CLOSED,
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
									}}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={7}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='USCitizen'
									classes={{ root: classes.inputRadio }}
									className={classes.textField}
									required
									error={
										!!errors?.usCitizen ||
										(!!highlights && highlights.includes(`usCitizen`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.US_CITIZEN')}
								</InputLabel>
								<div style={{ marginTop: '10px' }}>
									<RadioGroup
										row
										aria-label='USCitizen'
										name={`usCitizen`}
										// value={(usCitizen === true) ? 'true' : (usCitizen === false) ? 'false' : ''}
										// onChange={this.handleUSCitizenChange}>
										defaultValue={`${watch(`usCitizen`)}`}
										onChange={() => {
											updateHighlight();
										}}
										onBlur={() => {
											updateHighlight();
										}}
									>
										<FormControlLabel
											value='true'
											control={
												<Radio
													color='primary'
													inputRef={register()}
													disabled={credState === CRED_STATE.CLOSED}
													className={
														credState === CRED_STATE.CLOSED
															? classes.disabledInput
															: classes.normalInput
													}
													disableRipple
													size='small'
												/>
											}
											label={
												<span
													style={{
														fontSize: '16px',
														color: COLORS.RAVEN,
													}}
												>
													Yes
												</span>
											}
										/>
										<FormControlLabel
											value='false'
											control={
												<Radio
													color='primary'
													inputRef={register()}
													disableRipple
													size='small'
												/>
											}
											disabled={credState === CRED_STATE.CLOSED}
											className={
												credState === CRED_STATE.CLOSED
													? classes.disabledInput
													: classes.normalInput
											}
											label={
												<span
													style={{
														fontSize: '16px',
														color: COLORS.RAVEN,
													}}
												>
													No
												</span>
											}
										/>
									</RadioGroup>
									{watch(`usCitizen`) === `false`
										? usCitizenDecisionNode()
										: null}
								</div>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='MedicaidNumber'
									classes={{ root: classes.inputLabel }}
									error={
										!!errors.medicaidNumber ||
										(!!highlights && highlights.includes(`medicaidNumber`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.MEDICAID_NUMBER')}
								</InputLabel>
								<TextField
									margin='dense'
									id='MedicaidNumber'
									name='medicaidNumber'
									className={
										credState === CRED_STATE.CLOSED
											? classes.disabledInput
											: classes.textField
									}
									variant='outlined'
									onBlur={() => {
										updateHighlight();
									}}
									error={
										!!errors.medicaidNumber ||
										(!!highlights && highlights.includes(`medicaidNumber`))
									}
									inputRef={register({
										maxLength: {
											value: 50,
											message: t('common:MESSAGES.CHARACTER_LIMIT'),
										},
									})}
									helperText={
										(!!errors?.medicaidNumber &&
											errors?.medicaidNumber['type'] !== 'maxLength') ||
										(!!highlights && highlights.includes(`otherName`))
											? t('credentials:VALIDATION_MESSAGE.MEDICAID_NUMBER')
											: !!errors?.medicaidNumber &&
											  errors?.medicaidNumber['type'] === 'maxLength'
											? t('common:MESSAGES.CHARACTER_LIMIT')
											: ''
									}
									InputProps={{
										readOnly: credState === CRED_STATE.CLOSED,
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
									}}
								/>
							</FormControl>
						</Grid>

						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='MedicareNumber'
									classes={{ root: classes.inputLabel }}
									error={
										!!errors.medicareNumber ||
										(!!highlights && highlights.includes(`medicareNumber`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.MEDICARE_NUMBER')}
								</InputLabel>
								<TextField
									margin='dense'
									id='MedicareNumber'
									name='medicareNumber'
									className={
										credState === CRED_STATE.CLOSED
											? classes.disabledInput
											: classes.textField
									}
									variant='outlined'
									error={
										!!errors.medicareNumber ||
										(!!highlights && highlights.includes(`medicareNumber`))
									}
									onBlur={() => {
										updateHighlight();
									}}
									inputRef={register({
										maxLength: {
											value: 50,
											message: t('common:MESSAGES.CHARACTER_LIMIT'),
										},
									})}
									helperText={
										(!!errors?.medicareNumber &&
											errors?.medicareNumber['type'] !== 'maxLength') ||
										(!!highlights && highlights.includes(`medicareNumber`))
											? t('credentials:VALIDATION_MESSAGE.MEDICARE_NUMBER')
											: !!errors?.medicareNumber &&
											  errors?.medicareNumber['type'] === 'maxLength'
											? t('common:MESSAGES.CHARACTER_LIMIT')
											: ''
									}
									InputProps={{
										readOnly: credState === CRED_STATE.CLOSED,
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
									}}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={7}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='CAQHNumber'
									classes={{ root: classes.inputLabel }}
									error={
										!!errors?.caqhNumber ||
										(!!highlights && highlights.includes(`caqhNumber`))
									}
								>
									{t('credentials:PERSONAL_INFORMATION.CAQH_NUMBER')}
								</InputLabel>
								<TextField
									margin='dense'
									id='CAQHNumber'
									name='caqhNumber'
									className={
										credState === CRED_STATE.CLOSED
											? classes.disabledInput
											: classes.textField
									}
									variant='outlined'
									error={
										!!errors?.caqhNumber ||
										(!!highlights && highlights.includes(`caqhNumber`))
									}
									inputRef={register({
										maxLength: {
											value: 50,
											message: t('common:MESSAGES.CHARACTER_LIMIT'),
										},
									})}
									helperText={
										(!!errors?.caqhNumber &&
											errors?.caqhNumber['type'] !== 'maxLength') ||
										(!!highlights && highlights.includes(`caqhNumber`))
											? ''
											: !!errors?.caqhNumber &&
											  errors?.caqhNumber['type'] === 'maxLength'
											? t('common:MESSAGES.CHARACTER_LIMIT')
											: ''
									}
									onBlur={() => {
										updateHighlight();
									}}
									InputProps={{
										readOnly: credState === CRED_STATE.CLOSED,
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
									}}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={7}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='emergencyContactRelationship'
									classes={{ root: classes.inputLabel }}
									// error={!!errors.emergencyContact || (!!highlights && highlights.includes(`emergencyContactRelationship`))}
								>
									{t('credentials:PERSONAL_INFORMATION.EMERGENCY_CONTACT')}
								</InputLabel>
								<ThemeProvider
									// theme={(!!errors.emergencyContact || (!!highlights && highlights.includes(`emergencyContactRelationship`))) ? errorTheme : normalTheme}>
									theme={normalTheme}
								>
									<NativeSelect
										id='EmergencyContact'
										name='emergencyContactRelationship'
										inputRef={register()}
										onBlur={() => {
											updateHighlight();
										}}
										onChange={() => {
											updateHighlight();
										}}
										input={
											<TextFieldComponent
												style={{ border: `0px solid ${COLORS.RAVEN}` }}
												classes={{ input: classes.setWidthTo100OnMobile }}
											/>
										}
									>
										{emergencyContactRelationship.map((item) => (
											<option key={item.value} value={item.value}>
												{item.label}
											</option>
										))}
									</NativeSelect>
								</ThemeProvider>
								{!!watch('emergencyContactRelationship')
									? emergencyContactNode()
									: null}
							</FormControl>
						</Grid>
					</Grid>
				</div>
			)}
		</>
	);
}

export default WithContexts(withTranslation()(PersonalInformationForm));
