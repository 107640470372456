import React, { useEffect, useState, memo, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { useForm, Controller, useFieldArray, FormProvider, useFormContext } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import {
	NativeSelect,
	ThemeProvider,
	FormHelperText,
	CircularProgress,
	Input,
} from '@material-ui/core';
import { COLORS, ACTIVE_PATHNAME_MD, CRED_STATE } from '../../../../../utils/Application_Constants';
import TextFieldComponent from '../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { statesForLicense } from '../../../Account/accountOptions';
import createTheme from '@material-ui/core/styles/createTheme';
import DatePickerComponent from '../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	getLicensure,
	postLicensure,
	deleteLicensure,
} from '../../../../../services/Credentialing';
import { withStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { convertToDateIgnoringTime, matchesTemplate } from '../../../../../utils/helpers';
import _ from 'lodash';
import {
	licenseStatusOption,
	licenseTitleMD,
	licenseTitleAP,
	licenseTypeMD,
	licenseTypeAP,
} from './LicensureOptions';
import {
	licensureStatusCheck,
	licensureStatusPointsCheck,
} from '../../../../../utils/CredentialingAppUtility';
import HelpIcon from '@material-ui/icons/Help';
import ToolTipComponent from '../../../../GlobalComponents/ToolTipComponent';
import Alert from '@material-ui/lab/Alert';
import RequiredQuestionDialog from '../../../../GlobalComponents/Dialog/RequiredQuestionDialog';

const styles = (theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	inputRadio: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
	},

	// inputLabel: {
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase'
	// },

	// LOQInputLabel: {

	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	//     paddingBottom: '50px'
	// },
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	completedIcon: {
		color: 'green',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		'& input': {
			height: '32px',
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.RAVEN,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	credAppFirstHeader: {
		padding: '20px 0px',
		borderTop: '1px solid #e7e7e7',
		borderBottom: '1px solid #e7e7e7',
		margin: '30px',
		marginTop: '-15px',
	},

	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: `${COLORS.RAVEN}`,
			borderWidth: 1,
			borderRadius: 1,
		},
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
	},

	cssFocused: {},

	disabled: {},
	focused: {},
	error: {
		borderColor: '#6D707E', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		[theme.breakpoints.up('md')]: {
			marginTop: '25vh',
			marginLeft: '30vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '20vh',
			marginLeft: '25vh',
		},
	},
	successGrid: {
		marginTop: '25px',
	},
	iconButtonMargin: {
		marginRight: '10px',
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
	},
	secondaryHeaderArea: {
		alignItems: 'center',
		maxWidth: '100%',
		marginTop: '5px',
		marginBottom: '10px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	marginLeft12: {
		marginLeft: 12,
	},
	hideDeleteBtn: {
		display: 'none',
	},
	disabledInput: {
		opacity: 0.6,
	},
	normalInput: {
		opacity: 1,
	},
	disabledSelect: {
		opacity: 0.6,
		cursor: 'default',
	},
	externallyVerifiedText: {
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingRight: '32px',
		paddingBottom: '16px',
		fontSize: '14px',
		fontWeight: '700',
		color: COLORS.RAVEN,
	},
	headerText: {
		//    color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingRight: '32px',
		paddingTop: '16px',
		fontSize: '14px',
		fontWeight: '500',
		color: COLORS.RAVEN,
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '32px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
});

const NestedInput = memo(
	({
		item,
		index,
		renderIndex,
		firstRender,
		values,
		isLicenseEmpty,
		isRequired,
		children,
		register,
		errors,
		formState: { isDirty, dirtyFields },
	}) => {
		return <div>{children}</div>;
	},
	(prevProps, nextProps) => {
		const { licensures } = nextProps.values;

		var render = nextProps.firstRender.current;
		nextProps.firstRender.current = false;

		if (nextProps.index === 0) {
			//Used to ensure that a form with a single Blank Item won't block on validation run this check only on first item in list for performance reasons.
			nextProps.isLicenseEmpty(nextProps.index, nextProps.isRequired);
		}

		if (
			!licensures ||
			nextProps.renderIndex === nextProps.index ||
			nextProps.index + 1 === licensures.length ||
			nextProps.index === 0
		) {
			return false; // render
		}

		if (nextProps.renderIndex !== nextProps.index && !render) {
			return true; // dont render
		}

		return false; // render
	}
);

export const NestedInputContainer = ({
	item,
	index,
	renderIndex,
	values,
	isLicenseEmpty,
	isRequired,
	children,
}) => {
	const methods = useFormContext();
	const firstRender = useRef(true);

	return (
		<NestedInput
			item={item}
			index={index}
			renderIndex={renderIndex}
			firstRender={firstRender}
			values={values}
			isLicenseEmpty={isLicenseEmpty}
			isRequired={isRequired}
			{...methods}
		>
			{children}
		</NestedInput>
	);
};

function LicensureForm({
	classes,
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	setCredAppError,
	updateFlags,
	activePath,
	credState,
	titles,
	sectionCompletion,
	updateSectionCompletion,
	profession,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	const normalTheme = createTheme({
		overrides: {
			MuiSelect: {
				select: {},
			},
			MuiFormControlLabel: {
				root: {
					'&$disabled': {
						opacity: '0.6',
					},
				},
			},
		},
	});
	const errorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS

					borderRadius: '3px !important',
					border: '1px solid red !important',
				},
			},
		},
	});

	const [formTemplate] = useState({
		id: 'null',
		licenseType: '',
		licenseTitle: '',
		state: '',
		licenseNumber: '',
		dateIssued: null,
		dateExpired: null,
		licenseStatus: '',
		isVerified: false,
		isExternallyVerified: false,
	});

	const itemTemplate = {
		id: null,
		licenseType: '',
		licenseTitle: '',
		state: '',
		licenseNumber: '',
		dateIssued: null,
		dateExpired: null,
		licenseStatus: '',
		isVerified: false,
		isExternallyVerified: false,
	};

	const itemValidationTemplate = {
		id: 'null',
		licenseType: '',
		licenseTitle: '',
		state: '',
		licenseNumber: '',
		dateIssued: null,
		dateExpired: null,
		licenseStatus: '',
	};

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isRequired, setIsRequired] = useState(false);
	const [licenseTitleOption, setLicenseTitleOption] = useState([]);
	const [licenseTypeOption, setlicenseTypeOption] = useState([]);
	const [dateUpdateFlag] = useState(false);
	const [deleteRecordIndex, setDeleteRecordIndex] = useState();
	const [deleteRecordId, setDeleteRecordId] = useState();
	const [deleteRecords, setDeleteRecords] = useState([]);
	const [triggerToggle, setTriggerToggle] = useState([false]);
	const [, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);
	const [renderIndex, setRenderIndex] = useState(null);

	const methods = useForm({
		mode: 'all',
		defaultValues: {
			id: null,
			licenseType: '',
			licenseTitle: '',
			state: '',
			licenseNumber: '',
			dateIssued: null,
			dateExpired: null,
			licenseStatus: '',
			isVerified: false,
			isExternallyVerified: false,
		},
	});

	const { register, control, handleSubmit, errors, reset, getValues, formState, trigger } =
		methods;
	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;
			setSaveHandler(
				LicensureForm,
				'Licensure',
				handleSubmit(async (response) => await onSubmit(response))
			);

			await getLicensure(externalId)
				.then(function (response) {
					const { licensures = [] } = response;

					console.log('response is', response);
					if (response.loqExpirationDate !== null) {
						let timeZoneOffsetHours = new Date().getTimezoneOffset() / 60;
						let loqDate = new Date(Date.parse(response.loqExpirationDate));
						loqDate.setHours(loqDate.getHours() + timeZoneOffsetHours);
						response['loqExpirationDate'] = loqDate;
					}
					if (licensures.length === 0) {
						append({
							id: null,
							licenseType: '',
							licenseTitle: '',
							state: '',
							licenseNumber: '',
							dateIssued: null,
							dateExpired: null,
							licenseStatus: '',
							isVerified: false,
							isExternallyVerified: false,
						});
						response['licensures'].push({
							id: null,
							licenseType: '',
							licenseTitle: '',
							state: '',
							licenseNumber: '',
							dateIssued: null,
							dateExpired: null,
							licenseStatus: '',
							isVerified: false,
							isExternallyVerified: false,
						});
					} else {
						response['licensures'].map((item) => {
							if (item.licenseType === null) {
								item.licenseType = '';
							}
							if (item.licenseTitle === null) {
								item.licenseTitle = '';
							}
							if (item.state === null) {
								item.state = '';
							}
							if (item.licenseNumber === null) {
								item.licenseNumber = '';
							}
							if (item.licenseStatus === null) {
								item.licenseStatus = '';
							}

							//TDS-3251 temparory disable check
							item.isVerified = false;

							return item;
						});
					}
					updateSectionCompletion(sectionCompletion);
					flags.dataSaved = false;
					updateFlags(flags);
					reset(response);
					setIsLoaded(true);

					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status = {
							status: 'incomplete',
							missingFields: [],
						};

						status = licensureStatusCheck(
							response[`licensures`],
							profession,
							activePath
						);

						if (!!status.missingFields && status.missingFields.length > 0) {
							setHighlights(status.missingFields);
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, []);

	useEffect(() => {
		isLoaded && trigger();
	}, [isLoaded, isRequired, triggerToggle]);

	function checkHighlights(value) {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};

			var checkValues = getValues();

			status = licensureStatusCheck(checkValues.licensures, profession, activePath);

			var rtn = status.missingFields.includes(value);
			return rtn;
		}
		return false;
	}

	useEffect(() => {
		if (activePath === ACTIVE_PATHNAME_MD) {
			setLicenseTitleOption(licenseTitleMD);
			setlicenseTypeOption(licenseTypeMD);
		} else {
			setLicenseTitleOption(licenseTitleAP);
			setlicenseTypeOption(licenseTypeAP);
		}
	}, []);

	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'licensures',
		keyName: 'internalId',
	});

	const isLicenseEmpty = async (index, required) => {
		const { licensures } = getValues();
		if (!!licensures) {
			if (licensures.length > 1 && required !== true) {
				setIsRequired(true);
				return true;
			} else if (licensures.length > 1 && required === true) {
				return false;
			} else {
				var aEmpty = _.isEqual(licensures[index], itemValidationTemplate);
				if (aEmpty) {
					if (required !== false) {
						setIsRequired(false);
						return true;
					}
				} else {
					if (required !== true) {
						setIsRequired(true);
						return true;
					}
				}
			}
		}
		return false;
	};

	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('licensures')) {
			for (let i = 0; i < errors.licensures.length; i++) {
				if (!!errors?.licensures[i]) {
					//This would be 'return anError.type !== 'manual'', but manually set errors return a malformed error object with no 'type' field (the contents of the type field are inserted as an array of chars on the main object)
					missingRequiredToSaveFields = Object.values(errors.licensures[i]).filter(
						(anError) => {
							return anError.hasOwnProperty('type');
						}
					);
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (
			missingRequiredToSaveFields.length === 0 &&
			flags.validationError === true &&
			!dateUpdateFlag
		) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	async function onSubmit(data) {
		let success = false;

		const { licensures, loqExpirationDate } = data;
		let indexes = _.keys(_.pickBy(licensures, formTemplate));
		for (let i = 0; i < indexes.length; i++) {
			licensures.splice(indexes[i], 1);
		}

		const { externalId } = UserContext;
		data['clinicianExternalId'] = externalId;
		data['loqExpirationDate'] =
			activePath === ACTIVE_PATHNAME_MD ? moment(loqExpirationDate).toISOString(true) : null;
		if (!!data['licensures']) {
			data['licensures'] = data['licensures']
				.map((item) => {
					if (item.id === 'null') {
						item.id = null;
					}

					if (matchesTemplate(item, itemTemplate)) {
						return null;
					}

					item.dateIssued = moment(item.dateIssued).toISOString(true);
					item.dateExpired = moment(item.dateExpired).toISOString(true);

					return item;
				})
				.filter((d) => d !== null);
		}

		if (!data.hasOwnProperty('licensures')) {
			data['licensures'] = [];
		}

		if (deleteRecords.length > 0) {
			const deleteRecordsNumbers = deleteRecords.filter(function (element) {
				return element !== 'null';
			});
			await deleteLicensure(externalId, deleteRecordsNumbers);
		}

		if (deleteRecords.length > 0) {
			const deleteRecordsNumbers = deleteRecords.filter(function (element) {
				return element !== 'null';
			});
			await deleteLicensure(externalId, deleteRecordsNumbers).catch((e) => {
				setCredAppError(e);
			});
		}

		const jsonData = JSON.stringify(data);
		console.log(`JsonData: ${jsonData}`);
		await postLicensure(externalId, jsonData);

		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;

		sectionCompletion.certifications.licensure.current = await licensureStatusPointsCheck(
			data['licensures'],
			sectionCompletion,
			profession,
			activePath
		);
		updateSectionCompletion(sectionCompletion);
		updateFlags(flags);
		setIsLoaded(false);
		return success;
	}

	const deleteEntry = (btnPressed, index) => {
		if (btnPressed === 'Yes') {
			if (deleteRecordId !== null) {
				deleteRecords.push(deleteRecordId);
				setDeleteRecords(deleteRecords);
				setShowDeleteModal(false);
			}
			remove(deleteRecordIndex);
			setShowDeleteModal(false);
			setTriggerToggle(!triggerToggle);
		}
		setShowDeleteModal(false);
	};

	const openDeleteConfirmation = (item, index) => {
		setDeleteRecordIndex(index);
		setDeleteRecordId(item.id);
		setShowDeleteModal(true);
	};

	const loqExpirationDateComponent = () => {
		return (
			<>
				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={6}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<div style={{ marginBottom: '5px' }}>
								<InputLabel
									shrink
									htmlFor='loqExpirationDate'
									classes={{ root: classes.LOQInputLabel }}
									error={errors?.loqExpirationDate}
								>
									{t('credentials:LICENSURE.LOQ_EXPIRATION_DATE')}
									<ToolTipComponent
										disableFocusListener
										enterTouchDelay={150}
										interactive
										placement='right'
										title={
											<>
												<span>
													Letter Of Qualification Expiration Date is
													applicable to physicians who meet the
													requirements of the IMLC in order to obtain a
													compact license.{' '}
												</span>
											</>
										}
									>
										<HelpIcon
											style={{
												margin: '0px',
												fontSize: '15px',
												cursor: 'pointer',
												position: 'relative',
												left: '3px',
												bottom: '-3',
											}}
											color={'action'}
										/>
									</ToolTipComponent>
								</InputLabel>
							</div>
							<ThemeProvider theme={datePickerTheme}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Controller
										control={control}
										rules={{
											validate: (date) => dateValidation(date),
										}}
										defaultValue={null}
										name='loqExpirationDate'
										render={({ onChange, onBlur, value, name }) => (
											<DatePickerComponent
												onChange={onChange}
												name={name}
												value={value}
												format='MM/dd/yyyy'
												placeholder='MM/DD/YYYY'
												clearable
												disabled={credState === CRED_STATE.CLOSED}
												variant='inline'
												autoOk='true'
												minDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												maxDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												invalidDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												margin='dense'
												theme={datePickerTheme}
												error={errors?.loqExpirationDate}
												InputProps={{
													classes: {
														disabled: classes.disabledInput,
														root: classes.cssOutlinedInput,
														error: classes.error,
													},
												}}
												KeyboardButtonProps={{
													'aria-label': t(
														'credentials:LICENSURE.LOQ_EXPIRATION_DATE'
													),
												}}
												inputVariant='outlined'
											/>
										)}
									/>
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</FormControl>
					</Grid>
				</Grid>
			</>
		);
	};

	const renderForm = (item, index) => {
		const lockFields =
			!!item.id &&
			((!!item.isVerified &&
				(credState === CRED_STATE.OPEN || credState === CRED_STATE.PARTIALLY_OPEN)) ||
				credState === CRED_STATE.CLOSED ||
				item.isExternallyVerified);

		return (
			<>
				<Grid container>
					<Grid
						container
						className={
							index === 0 ? classes.credAppFirstHeader : 'credAppAdditionalHeader'
						}
					>
						<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
							{index === 0
								? 'Licensure'
								: t(`credentials:LICENSURE.ADDITIONAL_LICENSURE`)}
						</Grid>
						<Grid
							item
							xs={3}
							className={
								item.isVerified ||
								credState === CRED_STATE.CLOSED ||
								item.isExternallyVerified
									? classes.hideDeleteBtn
									: 'credAppAdditionalDelete'
							}
							onClick={() => {
								openDeleteConfirmation(item, index);
							}}
						>
							{t('credentials:LICENSURE.DELETE_BTN')}
						</Grid>
					</Grid>
				</Grid>
				<CssBaseline />
				<RouteDeparturePrompt
					blockedBackNavigation={blockedBackNavigation}
					when={!flags.dataSaved ? !!Object.keys(formState.dirtyFields).length : false}
				/>
				<Input
					autoComplete='off'
					variant='outlined'
					style={{ display: 'none' }}
					name={`licensures[${index}].id`}
					fullWidth
					value={`${item.id}` || null}
					defaultValue={`${item.id}`}
					inputRef={register()}
				/>

				{item.isExternallyVerified && (
					<FormHelperText className={classes.externallyVerifiedText}>
						{t('credentials:LICENSURE.EXTERNALLY_VERIFIED')}
					</FormHelperText>
				)}

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={6}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`licensures[${index}].state`}
								classes={{ root: classes.inputLabel }}
								required
								//className={errors?.licensures && errors?.licensures[`${index}`]?.state && classes.errorColor}
								error={
									(errors?.licensures && errors?.licensures[`${index}`]?.state) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].state`))
								}
							>
								{t('credentials:LICENSURE.STATE')}
							</InputLabel>
							<ThemeProvider
								theme={
									(errors?.licensures && errors?.licensures[`${index}`]?.state) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].state`))
										? errorTheme
										: normalTheme
								}
							>
								<NativeSelect
									id={`licensures[${index}].state`}
									name={`licensures[${index}].state`}
									defaultValue={`${item.state}`}
									inputRef={register({ required: isRequired })}
									onFocus={() => setRenderIndex(index)}
									className={
										lockFields ? classes.disabledSelect : classes.normalInput
									}
									disabled={lockFields}
									input={
										<TextFieldComponent
											style={{ border: `0px solid ${COLORS.RAVEN}` }}
											classes={{ input: classes.setWidthTo100OnMobile }}
										/>
									}
								>
									<option key={''} value={null}></option>
									{statesForLicense.map((item) => (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									))}
								</NativeSelect>
							</ThemeProvider>
							{((errors?.licensures && errors?.licensures[`${index}`]?.state) ||
								(localShouldHighlightFields &&
									checkHighlights(`licensures[${index}].state`))) && (
								<FormHelperText className={'Mui-error'}>
									{t('credentials:VALIDATION_MESSAGE.STATE')}
								</FormHelperText>
							)}
						</FormControl>
						{lockFields ? (
							<Input
								style={{ display: 'none' }}
								name={`licensures[${index}].state`}
								value={`${item.state}`}
								inputRef={register()}
							/>
						) : null}
					</Grid>
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={8}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`licensures[${index}].licenseTitle`}
								classes={{ root: classes.inputLabel }}
								required
								//className={errors?.licensures && errors?.licensures[`${index}`]?.licenseTitle && classes.errorColor}
								error={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.licenseTitle) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].licenseTitle`))
								}
							>
								{t('credentials:LICENSURE.LICENSE_TITLE')}
							</InputLabel>
							<ThemeProvider
								theme={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.licenseTitle) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].licenseTitle`))
										? errorTheme
										: normalTheme
								}
							>
								<NativeSelect
									id={`licensures[${index}].licenseTitle`}
									name={`licensures[${index}].licenseTitle`}
									defaultValue={`${item.licenseTitle}`}
									className={
										item.licenseTitle !== '' && lockFields
											? classes.disabledSelect
											: classes.normalInput
									}
									disabled={item.licenseTitle !== '' && lockFields}
									inputRef={register()}
									onFocus={() => setRenderIndex(index)}
									input={
										<TextFieldComponent
											style={{ border: `0px solid ${COLORS.RAVEN}` }}
											classes={{ input: classes.setWidthTo100OnMobile }}
										/>
									}
								>
									<option key={''} value={null}></option>
									{licenseTitleOption.map((item) => (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									))}
								</NativeSelect>
							</ThemeProvider>
							{((errors?.licensures &&
								errors?.licensures[`${index}`]?.licenseTitle) ||
								(localShouldHighlightFields &&
									checkHighlights(`licensures[${index}].licenseTitle`))) && (
								<FormHelperText className={'Mui-error'}>
									{t('credentials:VALIDATION_MESSAGE.LICENSE_TITLE')}
								</FormHelperText>
							)}
						</FormControl>
						{item.licenseTitle !== '' && lockFields ? (
							<Input
								style={{ display: 'none' }}
								name={`licensures[${index}].licenseTitle`}
								value={`${item.licenseTitle}`}
								inputRef={register()}
							/>
						) : null}
					</Grid>
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={8}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`licensures[${index}].licenseType`}
								classes={{ root: classes.inputLabel }}
								required
								//className={errors?.licensures && errors?.licensures[`${index}`]?.licenseType && classes.errorColor}
								error={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.licenseType) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].licenseType`))
								}
							>
								{t('credentials:LICENSURE.LICENSE_TYPE')}
							</InputLabel>
							<ThemeProvider
								theme={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.licenseType) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].licenseType`))
										? errorTheme
										: normalTheme
								}
							>
								<NativeSelect
									id={`licensures[${index}].licenseType`}
									name={`licensures[${index}].licenseType`}
									defaultValue={`${item.licenseType}`}
									className={
										lockFields ? classes.disabledSelect : classes.normalInput
									}
									disabled={lockFields}
									inputRef={register({ required: isRequired })}
									onFocus={() => setRenderIndex(index)}
									input={
										<TextFieldComponent
											style={{ border: `0px solid ${COLORS.RAVEN}` }}
											classes={{ input: classes.setWidthTo100OnMobile }}
										/>
									}
								>
									<option key={''} value={null}></option>
									{licenseTypeOption.map((item) => (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									))}
								</NativeSelect>
							</ThemeProvider>
							{((errors?.licensures && errors?.licensures[`${index}`]?.licenseType) ||
								(localShouldHighlightFields &&
									checkHighlights(`licensures[${index}].licenseType`))) && (
								<FormHelperText className={'Mui-error'}>
									{t('credentials:VALIDATION_MESSAGE.LICENSE_TYPE')}
								</FormHelperText>
							)}
						</FormControl>
						{lockFields ? (
							<Input
								style={{ display: 'none' }}
								name={`licensures[${index}].licenseType`}
								value={`${item.licenseType}`}
								inputRef={register()}
							/>
						) : null}
					</Grid>
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={8}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`licensures[${index}].licenseNumber`}
								classes={{ root: classes.inputLabel }}
								error={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.licenseNumber) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].licenseNumber`))
								}
							>
								{t('credentials:LICENSURE.NUMBER')}
							</InputLabel>
							<TextField
								id={`licensures[${index}].licenseNumber`}
								name={`licensures[${index}].licenseNumber`}
								className={
									item.isVerified || item.isExternallyVerified
										? classes.disabledInput
										: classes.normalInput
								}
								margin='dense'
								variant='outlined'
								defaultValue={
									`${item.licenseNumber}` === 'null'
										? ''
										: `${item.licenseNumber}`
								}
								inputRef={register({
									maxLength: {
										value: 20,
										message: t('common:MESSAGES.CHARACTER_LIMIT'),
									},
								})}
								onFocus={() => setRenderIndex(index)}
								helperText={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.licenseNumber &&
										errors?.licensures[`${index}`]?.licenseNumber['type'] ===
											'maxLength') ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].licenseNumber`))
										? t('common:MESSAGES.CHARACTER_LIMIT')
										: null
								}
								autoComplete='off'
								error={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.licenseNumber) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].licenseNumber`))
								}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
										error: classes.error,
									},
									readOnly: lockFields,
								}}
							/>
						</FormControl>
					</Grid>
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`licensures[${index}].dateIssued`}
								classes={{ root: classes.inputLabel }}
								//required
								error={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.dateIssued) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].dateIssued`))
								}
							>
								{t('credentials:LICENSURE.DATE_ISSUED')}
							</InputLabel>
							<ThemeProvider theme={datePickerTheme}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Controller
										control={control}
										rules={{
											validate: (date) => dateValidation(date),
										}}
										defaultValue={
											`${item.dateIssued}` === 'null'
												? null
												: convertToDateIgnoringTime(`${item.dateIssued}`)
										}
										name={`licensures[${index}].dateIssued`}
										render={({ onChange, onBlur, value, name }) => (
											<DatePickerComponent
												onChange={onChange}
												name={name}
												value={value}
												format='MM/dd/yyyy'
												placeholder='MM/DD/YYYY'
												clearable
												disabled={lockFields}
												variant='inline'
												autoOk='true'
												minDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												maxDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												invalidDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												margin='dense'
												theme={datePickerTheme}
												error={
													(errors?.licensures &&
														errors?.licensures[`${index}`]
															?.dateIssued) ||
													(localShouldHighlightFields &&
														checkHighlights(
															`licensures[${index}].dateIssued`
														))
												}
												InputProps={{
													classes: {
														disabled: classes.disabledInput,
														root: classes.cssOutlinedInput,
													},
												}}
												KeyboardButtonProps={{
													'aria-label': t(
														'credentials:LICENSURE.DATE_ISSUED'
													),
												}}
												inputVariant='outlined'
											/>
										)}
									/>
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</FormControl>
					</Grid>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`licensures[${index}].dateExpired`}
								classes={{ root: classes.inputLabel }}
								error={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.dateExpired) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].dateExpired`))
								}
							>
								{t('credentials:LICENSURE.EXPIRATION_DATE')}
							</InputLabel>
							<ThemeProvider theme={datePickerTheme}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Controller
										control={control}
										rules={{
											validate: (date) => dateValidation(date),
										}}
										defaultValue={
											`${item.dateExpired}` === 'null'
												? null
												: convertToDateIgnoringTime(`${item.dateExpired}`)
										}
										name={`licensures[${index}].dateExpired`}
										render={({ onChange, onBlur, value, name }) => (
											<DatePickerComponent
												onChange={onChange}
												name={name}
												value={value}
												format='MM/dd/yyyy'
												placeholder='MM/DD/YYYY'
												clearable
												variant='inline'
												autoOk='true'
												disabled={
													!!item.id &&
													((item.isVerified &&
														activePath === ACTIVE_PATHNAME_MD &&
														credState === CRED_STATE.OPEN) ||
														credState === CRED_STATE.CLOSED ||
														item.isExternallyVerified)
												}
												minDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												maxDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												invalidDateMessage={
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t('credentials:VALIDATION_MESSAGE.DATE')}
													</span>
												}
												margin='dense'
												theme={datePickerTheme}
												error={
													(errors?.licensures &&
														errors?.licensures[`${index}`]
															?.dateExpired) ||
													(localShouldHighlightFields &&
														checkHighlights(
															`licensures[${index}].dateExpired`
														))
												}
												KeyboardButtonProps={{
													'aria-label': t(
														'credentials:LICENSURE.EXPIRATION_DATE'
													),
												}}
												InputProps={{
													classes: {
														disabled: classes.disabledInput,
														root: classes.cssOutlinedInput,
													},
												}}
												inputVariant='outlined'
											/>
										)}
									/>
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</FormControl>
					</Grid>
				</Grid>

				<Grid container>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={8}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`licensures[${index}].licenseStatus`}
								classes={{ root: classes.inputLabel }}
								required
								//className={errors?.licensures && errors?.licensures[`${index}`]?.licenseStatus && classes.errorColor}
								error={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.licenseStatus) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].licenseStatus`))
								}
							>
								{t('credentials:LICENSURE.LICENSE_STATUS')}
							</InputLabel>
							<ThemeProvider
								theme={
									(errors?.licensures &&
										errors?.licensures[`${index}`]?.licenseStatus) ||
									(localShouldHighlightFields &&
										checkHighlights(`licensures[${index}].licenseStatus`))
										? errorTheme
										: normalTheme
								}
							>
								<NativeSelect
									id={`licensures[${index}].licenseStatus`}
									name={`licensures[${index}].licenseStatus`}
									defaultValue={`${item.licenseStatus}`}
									inputRef={register()}
									onFocus={() => setRenderIndex(index)}
									className={
										!!item.id &&
										((item.isVerified &&
											activePath === ACTIVE_PATHNAME_MD &&
											credState === CRED_STATE.OPEN) ||
											credState === CRED_STATE.CLOSED ||
											(item.isExternallyVerified &&
												item.licenseStatus !== ''))
											? classes.disabledSelect
											: classes.normalInput
									}
									disabled={
										!!item.id &&
										((item.isVerified &&
											activePath === ACTIVE_PATHNAME_MD &&
											credState === CRED_STATE.OPEN) ||
											credState === CRED_STATE.CLOSED ||
											(item.isExternallyVerified &&
												item.licenseStatus !== ''))
									}
									input={
										<TextFieldComponent
											style={{ border: `0px solid ${COLORS.RAVEN}` }}
											classes={{ input: classes.setWidthTo100OnMobile }}
										/>
									}
								>
									<option key={''} value={null}></option>
									{licenseStatusOption.map((item) => (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									))}
								</NativeSelect>
							</ThemeProvider>
							{((errors?.licensures &&
								errors?.licensures[`${index}`]?.licenseStatus) ||
								(localShouldHighlightFields &&
									checkHighlights(`licensures[${index}].licenseStatus`))) && (
								<FormHelperText className={'Mui-error'}>
									{t('credentials:VALIDATION_MESSAGE.LICENSE_STATUS')}
								</FormHelperText>
							)}
						</FormControl>
						{!!item.id &&
						((item.isVerified &&
							activePath === ACTIVE_PATHNAME_MD &&
							credState === CRED_STATE.OPEN) ||
							credState === CRED_STATE.CLOSED ||
							(item.isExternallyVerified && item.licenseStatus !== '')) ? (
							<Input
								style={{ display: 'none' }}
								name={`licensures[${index}].licenseStatus`}
								value={`${item.licenseStatus}`}
								inputRef={register()}
							/>
						) : null}
					</Grid>
				</Grid>
			</>
		);
	};

	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}

		return fields.map((item, index) => (
			<div key={item.internalId}>
				<NestedInputContainer
					item={item}
					index={index}
					renderIndex={renderIndex}
					values={getValues()}
					isLicenseEmpty={isLicenseEmpty}
					isRequired={isRequired}
				>
					{isLoaded ? renderForm(item, index) : null}
					<RequiredQuestionDialog
						open={showDeleteModal}
						title={t(`credentials:LICENSURE.CONFIRM_DELETE_TITLE_LICENSURE`)}
						text={t(`credentials:LICENSURE.CONFIRM_DELETE_MESSAGE_LICENSURE`)}
						buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
						buttonIDs='Yes;No'
						disableBackgroundClick={false}
						onClose={(btnPressed) => {
							deleteEntry(btnPressed, index);
						}}
					/>
				</NestedInputContainer>
			</div>
		));
	};

	return (
		<>
			<div className={classes.profileWrapper}>
				<Grid container>
					<Grid item xs={12} md={12}>
						<h3 className={classes.header}>
							{t(`credentials:LICENSURE.TITLE_LICENSURE`)}
						</h3>
						{credState === CRED_STATE.CLOSED ||
						credState === CRED_STATE.PARTIALLY_OPEN ? (
							<Grid item xs={12} md={10} className={classes.messageGrid}>
								<Alert
									severity='error'
									className={classes.alertMessage}
									icon={false}
								>
									{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
								</Alert>
							</Grid>
						) : null}
						<h3 className={classes.headerText}>
							{t(`credentials:LICENSURE.HEADER_TEXT`)}
						</h3>
					</Grid>

					<Grid item xs={12} md={12}>
						{isLoaded &&
						(titles.includes('DDS') ||
							titles.includes('DMD') ||
							titles.includes('MD') ||
							titles.includes('DO') ||
							titles.includes('Resident') ||
							titles.includes('DPM') ||
							titles.includes('PhD') ||
							titles.includes('PsyD'))
							? loqExpirationDateComponent()
							: null}
					</Grid>
					<Grid item xs={12} md={12}>
						<FormProvider {...methods}>
							<form
								className={classes.form}
								noValidate
								onSubmit={handleSubmit(onSubmit)}
							>
								{renderForms()}
							</form>
						</FormProvider>
					</Grid>
					<Grid item xs={12} md={12}>
						{isLoaded && credState !== CRED_STATE.CLOSED ? (
							<div
								className={'credAppAddMoreButton'}
								onClick={() => {
									append({
										id: null,
										licenseType: '',
										licenseTitle: '',
										state: '',
										licenseNumber: '',
										dateIssued: null,
										dateExpired: null,
										licenseStatus: '',
									});
									setTriggerToggle(!triggerToggle);
								}}
							>
								{t(`credentials:LICENSURE.ADD_MORE_LICENSURE`)}
							</div>
						) : null}
					</Grid>
				</Grid>
			</div>
		</>
	);
}

export default WithContexts(withTranslation()(withStyles(styles)(LicensureForm)));
