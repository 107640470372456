import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import { CircularProgress, NativeSelect } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { types } from './OtherCertificationsOptions';
import { COLORS, CRED_STATE } from '../../../../../utils/Application_Constants';
import TextFieldComponent from '../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import DatePickerComponent from '../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import RequiredQuestionDialog from '../../../../GlobalComponents/Dialog/RequiredQuestionDialog';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	getOtherCertifications,
	postOtherCertifications,
	deleteOtherCertifications,
} from '../../../../../services/Credentialing';
import { convertToDateIgnoringTime, matchesTemplate } from '../../../../../utils/helpers';
import _ from 'lodash';
import { otherCertificationsStatusPointsCheck } from '../../../../../utils/CredentialingAppUtility';
import Alert from '@material-ui/lab/Alert';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		textAlign: 'left',
		paddingLeft: '10px',
		paddingBottom: '5px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	form: {
		width: '100%',
		margin: theme.spacing(1, 0, 5),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	credAppFirstHeader: {
		padding: '20px 0px',
		borderTop: '1px solid #e7e7e7',
		borderBottom: '1px solid #e7e7e7',
		margin: '30px',
		marginTop: '-15px',
	},
	// inputLabel: {
	//     top: '-5px',
	//     position: 'relative',
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	//     marginTop: theme.spacing(-1),
	// },
	// inputLebelText: {
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase'
	// },
	// selectInputLabel: {
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	// },
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: `${COLORS.RAVEN} `,
			borderWidth: 1,
		},
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
	},
	cssFocused: {},
	error: {
		border: '0.5px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	disabled: {},
	disabledSelect: {
		opacity: 0.6,
		cursor: 'default',
	},
	disabledInput: {
		opacity: 0.6,
	},
	focused: {},
	marginLeft12: {
		marginLeft: 12,
	},
	noTopPad: {
		paddingTop: '0 !important',
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	radioGroup: {
		marginLeft: '8px',
	},
	inputRadio: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
	},
	setWidthSelectGroup: {
		top: '-23px',
		width: 'calc(100% - 1px)',
		marginTop: '10px',
		marginRight: '10px',
	},
	setWidthSelects: {
		minWidth: '100%',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
	},
	// selectContainerOffset: {
	//     padding: '0 16px 16px 16px !important'
	// },
	selectContainer: {
		padding: '3px !important',
	},
	selectContainerGroup: {
		[theme.breakpoints.down('sm')]: {
			padding: '3px 10px 0px 10px !important',
		},
		minWidth: '97%',
	},
	multiGridOffset: {
		paddingTop: '13px !important',
	},
	leftPad30: {
		paddingLeft: '30px !important',
	},
	hideDeleteBtn: {
		display: 'none',
	},
	containerSM: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% + 13px) !important',
		},
	},
	marginBottom20Neg: {
		marginBottom: -20,
	},
	infoMessage: {
		color: COLORS.RAVEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '14px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '10px !important',
		paddingBottom: '15px',
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingBottom: '10px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
}));

function CertificationForm({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	updateFlags,
	credState,
	setCredAppError,
	sectionCompletion,
	updateSectionCompletion,
}) {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [deleteRecordIndex, setDeleteRecordIndex] = useState();
	const [deleteRecordId, setDeleteRecordId] = useState();
	const [deleteRecords, setDeleteRecords] = useState([]);

	const [formTemplate] = useState({
		id: 'null',
		certificationType: '',
		expirationDate: null,
		explainOther: '',
	});

	const itemTemplate = {
		id: null,
		certificationType: '',
		expirationDate: null,
		explainOther: '',
	};

	const {
		register,
		control,
		handleSubmit,
		errors,
		watch,
		reset,
		setValue,
		getValues,
		formState,
		trigger,
	} = useForm({
		mode: 'all',
		defaultValues: {
			id: null,
			certificationType: '',
			expirationDate: null,
			explainOther: '',
		},
	});

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			setSaveHandler(
				CertificationForm,
				'Other Certifications',
				handleSubmit(async (data) => await onSubmit(data))
			);

			await getOtherCertifications(externalId)
				.then(function (data) {
					console.log('response is', data);
					const { certifications = [] } = data;

					if (certifications.length === 0) {
						append({
							id: null,
							certificationType: '',
							expirationDate: null,
							explainOther: '',
						});
						data['certifications'].push({
							id: null,
							certificationType: '',
							expirationDate: null,
							explainOther: '',
						});
					} else {
						data['certifications'].map((item) => {
							if (item.certificationType === null) {
								item.certificationType = '';
							}
							if (item.explainOther === null) {
								item.explainOther = '';
							}
							return item;
						});
					}
					flags.dataSaved = false;
					updateFlags(flags);
					reset(data);
					setIsLoaded(true);
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);

	useEffect(() => {
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}
		if (formState.errors.hasOwnProperty('certifications') && flags.validationError === false) {
			flags.validationError = true;
			updateFlags(flags);
		} else {
			if (
				!formState.errors.hasOwnProperty('certifications') &&
				flags.validationError === true
			) {
				flags.validationError = false;
				updateFlags(flags);
			}
		}
	}, [formState]);

	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'certifications',
		keyName: 'internalId',
	});

	const classes = styles();

	const onSubmit = async (data) => {
		let success = false;

		const { certifications } = data;
		const { externalId } = UserContext;
		let indexes = _.keys(_.pickBy(certifications, formTemplate));
		for (let i = 0; i < indexes.length; i++) {
			certifications.splice(indexes[i], 1);
		}
		// Transform data
		data['clinicianId'] = externalId;
		if (!!data['certifications']) {
			data['certifications'] = data['certifications']
				.map((item) => {
					if (item.id === 'null') {
						item.id = null;
					}
					if (item.expirationDate === 'null') item.expirationDate = null;
					else item.expirationDate = moment(item.expirationDate).toISOString(true);
					if (item.certificationType !== 'Other') {
						item.explainOther = '';
					}

					if (matchesTemplate(item, itemTemplate)) {
						return null;
					}

					return item;
				})
				.filter((d) => d !== null);
		}

		if (deleteRecords.length > 0) {
			const deleteRecordsNumbers = deleteRecords.filter(function (element) {
				return element !== 'null';
			});
			await deleteOtherCertifications(externalId, deleteRecordsNumbers).catch((e) => {
				setCredAppError(e);
			});
		}

		console.log('before json', data);
		const jsonData = JSON.stringify(data);
		console.log('post payload is:', jsonData);
		await postOtherCertifications(externalId, jsonData);
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;

		sectionCompletion.certifications.otherCertifications.current =
			await otherCertificationsStatusPointsCheck(data['certifications'], sectionCompletion);
		updateSectionCompletion(sectionCompletion);

		updateFlags(flags);
		return success;
	};

	const dateValidation = (date) => {
		console.log(' return !isNaN((new Date(d)).getTime());' + !isNaN(new Date(date).getTime()));
		return !isNaN(new Date(date).getTime()) === true;
	};

	const deleteEntry = (btnPressed, index) => {
		if (btnPressed === 'Yes') {
			if (deleteRecordId !== null) {
				deleteRecords.push(deleteRecordId);
				setDeleteRecords(deleteRecords);
				setShowDeleteModal(false);
			}
			remove(deleteRecordIndex);
			setShowDeleteModal(false);
		}
		setShowDeleteModal(false);
	};

	const openDeleteConfirmation = (item, index) => {
		setDeleteRecordIndex(index);
		setDeleteRecordId(item.id);
		setShowDeleteModal(true);
	};

	const renderForm = (item, index) => {
		const lockFields =
			credState === CRED_STATE.PARTIALLY_OPEN || credState === CRED_STATE.CLOSED;

		return (
			<div className={classes.profileWrapper}>
				<Grid container>
					<Grid
						container
						className={
							index === 0 ? classes.credAppFirstHeader : 'credAppAdditionalHeader'
						}
					>
						<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
							{index === 0
								? 'Certifications'
								: t('credentials:OTHER_CERTIFICATIONS.ADDITIONAL_CERTIFICATIONS')}
						</Grid>
						<Grid
							item
							xs={3}
							className={
								item.isVerified || credState === CRED_STATE.CLOSED
									? classes.hideDeleteBtn
									: 'credAppAdditionalDelete'
							}
							onClick={() => {
								openDeleteConfirmation(item, index);
							}}
						>
							{t('credentials:EDUCATION.DELETE_BTN')}
						</Grid>
					</Grid>
				</Grid>
				<Container component='main' maxWidth='md'>
					<CssBaseline />
					<div>
						<Grid container item md={12} className={classes.containerSM}>
							<form
								className={classes.form}
								noValidate
								onSubmit={handleSubmit(onSubmit)}
							>
								<RouteDeparturePrompt
									blockedBackNavigation={blockedBackNavigation}
									when={
										!flags.dataSaved
											? !!Object.keys(formState.dirtyFields).length
											: false
									}
								/>
								<Input
									autoComplete='off'
									variant='outlined'
									style={{ display: 'none' }}
									name={`certifications[${index}].id`}
									fullWidth
									value={`${item.id}` || null}
									defaultValue={`${item.id}`}
									inputRef={register()}
								/>
								<Grid container>
									<Grid
										item
										xs={12}
										classes={{ root: classes.selectContainerOffset }}
									>
										<FormControl
											classes={{ root: classes.setWidthTo100OnMobile }}
										>
											<InputLabel
												shrink
												htmlFor={`certifications[${index}].certificationType`}
												classes={{ root: classes.selectInputLabel }}
											>
												{t('credentials:OTHER_CERTIFICATIONS.NAME')}
											</InputLabel>
											<NativeSelect
												defaultValue={`${item.certificationType}`}
												inputRef={register()}
												name={`certifications[${index}].certificationType`}
												className={
													lockFields
														? classes.disabledSelect
														: classes.normalInput
												}
												disabled={
													lockFields
														? classes.disabledSelect
														: classes.normalInput
												}
												onChange={(event) => {
													if (event.target.value === `Other`) {
														setTimeout(() => {
															trigger(
																`certifications[${index}].explainOther`
															);
														}, 100);
													}
												}}
												input={
													<TextFieldComponent
														style={{
															border: `0px solid ${COLORS.RAVEN}`,
														}}
														classes={{
															input: classes.setWidthTo100OnMobile,
														}}
													/>
												}
											>
												<option key={''} value={null}></option>
												{types.map((item) => (
													<option key={item.value} value={item.value}>
														{item.label}
													</option>
												))}
												<optgroup label=''></optgroup>
											</NativeSelect>
										</FormControl>
										{lockFields ? (
											<Input
												style={{ display: 'none' }}
												name={`certifications[${index}].certificationType`}
												value={`${item.certificationType}`}
												inputRef={register()}
											/>
										) : null}
									</Grid>

									{watch(`certifications[${index}].certificationType`) ===
									'Other' ? (
										<Grid item xs={12} classes={{ root: classes.gridRoot }}>
											<FormControl
												classes={{ root: classes.setWidthTo100OnMobile }}
											>
												<InputLabel
													shrink
													htmlFor={`certifications[${index}].explainOther`}
													classes={{ root: classes.inputLebelText }}
													required
													error={
														!!errors?.certifications &&
														errors?.certifications[`${index}`]
															?.explainOther
													}
												>
													{t(
														'credentials:OTHER_CERTIFICATIONS.EXPLAIN_OTHER'
													)}
												</InputLabel>
												<TextField
													id={`certifications[${index}].explainOther`}
													name={`certifications[${index}].explainOther`}
													margin='normal'
													variant='outlined'
													//disabled={lockFields}
													className={
														lockFields
															? classes.disabledSelect
															: classes.textField
													}
													defaultValue={`${item.explainOther}`}
													error={
														!!errors?.certifications &&
														errors?.certifications[`${index}`]
															?.explainOther
													}
													inputRef={register({
														required:
															watch(
																`certifications[${index}].certificationType`
															) === 'Other',
														maxLength: {
															value: 255,
															message: t(
																'common:MESSAGES.CHARACTER_LIMIT'
															),
														},
													})}
													autoComplete='off'
													InputProps={{
														classes: {
															root: classes.cssOutlinedInput,
															focused: classes.cssFocused,
															notchedOutline: classes.notchedOutline,
															error: classes.error,
														},
													}}
												/>
												{errors?.certifications &&
													errors?.certifications[`${index}`]
														?.explainOther &&
													errors?.certifications[`${index}`]
														?.explainOther['type'] === 'maxLength' && (
														<span className={classes.cssLabel}>
															{errors?.certifications &&
																errors?.certifications[`${index}`]
																	?.explainOther &&
																errors?.certifications[`${index}`]
																	?.explainOther.message}
														</span>
													)}
												{errors?.certifications &&
													errors?.certifications[`${index}`]
														?.explainOther &&
													errors?.certifications[`${index}`]
														?.explainOther['type'] !== 'maxLength' && (
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.EXPLAIN_OTHER'
															)}
														</span>
													)}
											</FormControl>
										</Grid>
									) : null}
									<Grid item xs={8} className={classes.noTopPad}>
										<FormControl
											classes={{ root: classes.setWidthTo100OnMobile }}
										>
											<InputLabel
												shrink
												htmlFor={`certifications[${index}].expirationDate`}
												classes={{ root: classes.selectInputLabel }}
											>
												{t('credentials:OTHER_CERTIFICATIONS.EXPIRATION')}
											</InputLabel>
											<ThemeProvider theme={datePickerTheme}>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<Controller
														as={<DatePickerComponent />}
														control={control}
														rules={{
															validate: (date) =>
																dateValidation(date),
														}}
														name={`certifications[${index}].expirationDate`}
														clearable
														value={watch('expirationDate')}
														defaultValue={
															`${item.expirationDate}` === 'null'
																? null
																: convertToDateIgnoringTime(
																		`${item.expirationDate}`
																  )
														}
														variant='inline'
														autoOk='true'
														format='MM/dd/yyyy'
														placeholder='MM/DD/YYYY'
														minDateMessage={
															<span
																className={`${classes.cssLabel} ${classes.marginLeft12}`}
															>
																{t(
																	'credentials:VALIDATION_MESSAGE.DATE'
																)}
															</span>
														}
														maxDateMessage={
															<span
																className={`${classes.cssLabel} ${classes.marginLeft12}`}
															>
																{t(
																	'credentials:VALIDATION_MESSAGE.DATE'
																)}
															</span>
														}
														invalidDateMessage={
															<span
																className={`${classes.cssLabel} ${classes.marginLeft12}`}
															>
																{t(
																	'credentials:VALIDATION_MESSAGE.DATE'
																)}
															</span>
														}
														margin='normal'
														theme={datePickerTheme}
														disabled={credState === CRED_STATE.CLOSED}
														InputProps={{
															classes: {
																disabled: classes.disabledInput,
																root: classes.cssOutlinedInput,
															},
														}}
														error={
															!!getValues(
																`certifications[${index}].expirationDate`
															) &&
															!!errors?.certifications &&
															errors?.certifications[`${index}`]
																?.expirationDate
														}
														onChange={(date) => {
															setValue(
																`certifications[${index}].expirationDate`,
																date
															);
														}}
														KeyboardButtonProps={{
															'aria-label': t(
																'credentials:OTHER_CERTIFICATIONS.EXPIRATION'
															),
														}}
														inputVariant='outlined'
													/>
												</MuiPickersUtilsProvider>
											</ThemeProvider>
										</FormControl>
									</Grid>
								</Grid>
							</form>
						</Grid>
					</div>
				</Container>
			</div>
		);
	};
	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}
		return fields.map((item, index) => (
			<div key={item.internalId}>
				{renderForm(item, index)}
				<RequiredQuestionDialog
					open={showDeleteModal}
					title={t('credentials:OTHER_CERTIFICATIONS.CONFIRM_DELETE_TITLE')}
					text={t('credentials:OTHER_CERTIFICATIONS.CONFIRM_DELETE_MESSAGE').replace(
						'(certification)',
						'certification'
					)}
					buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
					buttonIDs='Yes;No'
					disableBackgroundClick={false}
					onClose={(btnPressed) => {
						deleteEntry(btnPressed, index);
					}}
				/>
			</div>
		));
	};
	return (
		<div>
			<Grid className={classes.leftPad30}>
				<Grid item xs={12}>
					<h3 className={classes.header}>
						{t('credentials:OTHER_CERTIFICATIONS.TITLE')}
					</h3>
					{credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN ? (
						<Grid item xs={12} md={10} className={classes.messageGrid}>
							<Alert severity='error' className={classes.alertMessage} icon={false}>
								{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
							</Alert>
						</Grid>
					) : null}
					<h3 className={classes.infoMessage}>
						{t(`credentials:OTHER_CERTIFICATIONS.INFO_MESSAGE`)}
					</h3>
					<br />
					<br />
					<h3 className={classes.infoMessage}>
						{t(`credentials:OTHER_CERTIFICATIONS.NON_REQUIRED`)}
					</h3>
				</Grid>
				{renderForms()}
			</Grid>
			{isLoaded && credState !== CRED_STATE.CLOSED ? (
				<div
					className={'credAppAddMoreButton'}
					onClick={() =>
						append({
							id: null,
							certificationType: '',
							expirationDate: null,
							explainOther: '',
						})
					}
				>
					{t('credentials:OTHER_CERTIFICATIONS.ADD_MORE')}
				</div>
			) : null}
		</div>
	);
}

export default WithContexts(withTranslation()(CertificationForm));
