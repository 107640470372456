import React, { Component, Fragment } from 'react';
import { withStyles, Grid, Button, Chip, Paper, CircularProgress } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import YourTeamPreview from '../YourTeam/YourTeamPreview.js';
import FeatureFlag from '../../../components/GlobalComponents/FeatureFlagComponent/FeatureFlag';
import { getAssignmentTeam } from '../../../services/AssignmentsService';

const styles = (theme) => ({
	headerDash: {
		color: COLORS.LT_JOURNEY,
		paddingTop: '25px',
		margin: 0,
	},
	headerInt: {
		paddingTop: '25px',
		margin: 0,
	},

	paper: {
		padding: '1.5rem',
		marginBottom: '1.5rem',
	},
	header: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '20px',
		margin: 0,
	},
	assignmentListContainerRoot: {
		border: `1px solid ${COLORS.LT_PEPPER}`,
		width: '100%',
		padding: '1.5rem',
		textAlign: 'left',
		borderRadius: '3px',
		position: 'relative',
	},
	nextShiftRoot: {
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	chipRoot: {
		border: `1px solid ${COLORS.ASTRAL}`,
		color: COLORS.ASTRAL,
		backgroundColor: COLORS.MYSTIC,
		marginLeft: '10px',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
		},
	},
	chipLabel: {
		fontWeight: '300',
		fontSize: '14px',
	},
	assignmentDateRange: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '16px',
		marginTop: '5px',
		marginBottom: '5px',
		lineHeight: 'revert',
	},
	assignmentBookingID: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '14px',
		marginTop: '5px',
		fontStyle: 'italic',
		lineHeight: 'revert',
	},
	yourTeamPreviewFont: {
		fontWeight: '900',
		color: COLORS.RAVEN,
		fontSize: '12px',
		display: 'flex',
	},
	nextShiftFont: {
		fontWeight: '900',
		color: COLORS.RAVEN,
		fontSize: '12px',
	},
	nextAssignmentDate: {
		margin: '5px 0 0',
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '18px',
	},
	noShifts: {
		margin: '5px 0 0',
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '18px',
		opacity: '0.7',
	},
	chevronRoot: {
		color: COLORS.LT_JOURNEY,
		position: 'absolute',
		right: '24px',
	},
	chevronWithAssignment: {
		top: '45%',
	},
	chevronWithNoAssignment: {
		top: '39%',
	},
	buttonRoot: {
		lineHeight: 'inherit',
		padding: 0,
		display: 'block',
		// width: '90%',
		// borderRadius: '0px',
		// textTransform: 'none',
		// boxShadow:'none',
	},
	dateError: {
		marginTop: '5px',
		fontWeight: '500',
		fontSize: '18px',
		color: COLORS.MILANO_RED,
	},
});

class AssignmentListItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasNextAssignment: true,
			assignmentTeam: [],
			isLoading: true,
		};
	}

	async componentDidMount() {
		const { assignment, type } = this.props;

		await this.setState({ isLoading: true });
		const assignmentTeam = await getAssignmentTeam(assignment.clinicianId, assignment.id, type);
		this.setState({
			assignmentTeam: assignmentTeam,
			isLoading: false,
		});
	}

	renderChevronCssBasedOnAssignment = () => {
		const { classes } = this.props;
		const { hasNextAssignment } = this.state;
		if (hasNextAssignment) {
			return classes.chevronWithAssignment;
		}
		return classes.chevronWithNoAssignment;
	};

	doesAssignmentsContainShifts = () => {
		const { assignment } = this.props;
		if (!assignment.shifts || assignment.shifts.length === 0) {
			return false;
		}
		return true;
	};

	renderShiftHeading = () => {
		const { type, assignment, classes, t } = this.props;
		if (type === 'past' || !this.showShiftTimes(assignment)) return null;
		return (
			<Grid item xs={12}>
				<span className={classes.nextShiftFont}>
					{t('common:GENERIC_PHRASES.NEXT_SHIFTS')}
				</span>
			</Grid>
		);
	};

	showShiftTimes = (assignment) => {
		if (
			assignment === undefined ||
			assignment.division === undefined ||
			process.env.REACT_APP_HIDE_SHIFTS_FOR === undefined
		)
			return true;
		return !process.env.REACT_APP_HIDE_SHIFTS_FOR.includes(assignment.division);
	};

	/**
	 * This logic exists to render Your Team section
	 */
	renderYourTeam = () => {
		const { classes, type, t } = this.props;

		const { assignmentTeam, isLoading } = this.state;

		if (type === 'past') {
			return null;
		}

		if (isLoading) {
			return (
				<div>
					<CircularProgress color='primary' />
				</div>
			);
		}

		return (
			<>
				<Grid item xs={12}>
					<span className={classes.yourTeamPreviewFont}>
						{t('assignments:YOURTEAM.PREVIEW_TITLE')}
					</span>
				</Grid>
				<YourTeamPreview yourteam={assignmentTeam} />
			</>
		);
	};

	/**
	 * This logic exists because some assignments might not have a
	 * next shift date.
	 */
	renderNextShift = () => {
		const { assignment, classes, type, t } = this.props;
		if (type === 'past' || !this.showShiftTimes(assignment)) {
			return null;
		}
		return (
			<>
				{this.renderShiftHeading()}
				<Grid item xs={12} classes={{ root: classes.nextShiftRoot }}>
					{this.doesAssignmentsContainShifts() ? (
						assignment.shifts.map((item, index) => {
							if (item.timeZoneId) {
								return (
									<Fragment key={`g-${index + 1}`}>
										{index === 0 ? (
											<h3
												key={`asssignmentShift-${index + 1}`}
												className={classes.nextAssignmentDate}
											>
												{`${moment(item.start)
													.tz(item.timeZoneId)
													.format('dddd, MMM D ')} at `}
											</h3>
										) : null}
										<Chip
											classes={{
												root: classes.chipRoot,
												label: classes.chipLabel,
											}}
											label={`${moment(item.start)
												.tz(item.timeZoneId)
												.format('h:mma')} - ${moment(item.end)
												.tz(item.timeZoneId)
												.format('h:mma')}`}
										/>
									</Fragment>
								);
							}
							return (
								<h3
									key={`asssignmentShift-${index + 1}`}
									className={classes.dateError}
								>
									{t('assignments:DATE.TIMEZONE_MISSING')}
								</h3>
							);
						})
					) : (
						<h3 className={classes.noShifts}>{t('common:GENERIC_PHRASES.NONE')}</h3>
					)}
				</Grid>
			</>
		);
	};

	renderAssigmentDates = () => {
		const { classes, assignment, t } = this.props;

		return (
			<h3 className={classes.assignmentDateRange}>
				{assignment.startDate && assignment.endDate ? (
					<span>
						{moment.utc(assignment.startDate).format('MMM DD, YYYY')}
						&nbsp;- &nbsp;
						{moment.utc(assignment.endDate).format('MMM DD, YYYY')}
					</span>
				) : (
					t('assignments:DATE.TBD')
				)}
			</h3>
		);
	};

	renderAssigmentBookingID = () => {
		const { classes, assignment, t } = this.props;

		return (
			<h3 className={classes.assignmentBookingID}>
				{assignment.name && assignment.startDate && assignment.endDate ? (
					<span>
						{t('assignments:BOOKING.BOOKING_ID')}: {assignment.name}
					</span>
				) : null}
			</h3>
		);
	};

	render() {
		const { classes, toggleAssignmentView, assignment } = this.props;
		return (
			<Grid item xs={12}>
				<Button
					component='span'
					classes={{ root: classes.buttonRoot }}
					className="assignmentListItemCard"
					onClick={() => {
						toggleAssignmentView(assignment.id);
					}}
				>
					<Paper className={classes.paper}>
						<Grid container>
							<Grid item xs={11}>
								<h3 className='cr-journey'>{assignment.displayName}</h3>
								{this.renderAssigmentDates()}
								{this.renderAssigmentBookingID()}
								{this.renderNextShift()}
								<FeatureFlag name='ShowYourTeam' isFlagEnabled='true'>
									{this.renderYourTeam()}
								</FeatureFlag>
							</Grid>
							<Grid item xs={1}>
								<ChevronRight
									classes={{
										root: `${
											classes.chevronRoot
										} ${this.renderChevronCssBasedOnAssignment()}`,
									}}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Button>
			</Grid>
		);
	}
}

export default withTranslation()(withStyles(styles)(AssignmentListItem));
