import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { COLORS } from '../../utils/Application_Constants';
import { ltdomain } from '../../utils/helpers';

const styles = (theme) => ({
	footerBottom: {
		color: COLORS.WHITE,
		width: '100%',
		background: COLORS.ST_TROPAZ,
		padding: '5px 0px',
	},
});

const Footer = (props) => {
	const { classes } = props;
	const currentYear = new Date().getFullYear();

	return (
		<div className={classes.footerBottom}>
			<Grid container>
				<Grid item md={8}>
					<ul className='list-inline footer-bottom-info'>
						<li>&copy; {` ${currentYear} LocumTenens.com`}</li>
						<li>
							<a href={ltdomain() + '/privacy/'}>Privacy policy</a>
						</li>
					</ul>
				</Grid>
			</Grid>
		</div>
	);
};

export default withRouter(withStyles(styles)(Footer));
