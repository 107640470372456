import { COLORS } from '../../../utils/Application_Constants';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Icon,
} from '@material-ui/core';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import msSalService from '../../../services/msSalService';

const styles = (theme) => ({
	text: {
		color: COLORS.DENIM,
		fontWeight: '700',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
		},
	},
	header: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '30px',
		margin: '10px 0 0',
		[theme.breakpoints.down('sm')]: {
			margin: '0px',
		},
	},
	errorId: {
		marginTop: '10px',
		fontWeight: '700',
		marginLeft: '30px',
	},
	helpMsg: {
		marginTop: '10px',
		marginLeft: '30px',
	},
	description: {
		marginLeft: '30px',
	},
	errorNumber: {
		marginTop: '10px',
		fontWeight: '500',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
	},
});

class ErrorDialog extends Component {
	constructor(props, context) {
		super(props);
	}

	handleClose = () => {
		this.props.onClose();
	};

	render = () => {
		const { classes } = this.props;
		const date = new Date().toLocaleString();
		const userName = msSalService.pullEmailFromAccount();
		const emailLink = `mailto: lt_onlineservices@locumtenens.com?subject=Clinician Portal Error&body=${userName} had error ${this.props.errorId} on ${date}:%0A%0A${this.props.description}`;
		return (
			<Dialog
				open={this.props.open}
				onClose={this.handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				className={classes.text}
			>
				{this.props.title !== '' ? (
					<DialogTitle disableTypography className={classes.header}>
						<Icon className='fa fa-times-circle' color='secondary' />
						{`Error: ${this.props.title}`}
					</DialogTitle>
				) : (
					<DialogTitle disableTypography className={classes.header}>
						<Icon className='fa fa-times-circle' color='secondary' />
						{`Error`}
					</DialogTitle>
				)}
				<DialogContent>
					<DialogContentText>
						<div className={classes.description}>{this.props.description}</div>
						<div className={classes.errorId}>
							Error ID:{' '}
							<span className={classes.errorNumber}>{this.props.errorId}</span>
						</div>
						<div className={classes.helpMsg}>
							If the issue persists, please contact technical support at
							<a href={emailLink} className={classes.clickableSpan}>
								{' '}
								lt_onlineservices@locumtenens.com
							</a>
							.
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} variant='contained' color='primary'>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	};
}

ErrorDialog.propTypes = {
	classes: PropTypes.shape({
		text: PropTypes.string.isRequired,
	}).isRequired,
	onClose: PropTypes.shape(PropTypes.func).isRequired,
	text: PropTypes.shape(PropTypes.string).isRequired,
	title: PropTypes.string,
};

export default withStyles(styles)(ErrorDialog);
