import React, { useRef, useState, useEffect } from 'react';
import {
    Button,
    Grid,
    Menu,
    MenuItem,
    TextField,
    Typography,
    withStyles,
    Drawer,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { COLORS } from '../../../utils/Application_Constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useDebounce from '../../../utils/useDebounce';
import { config } from '../Expense/ExpenseReviewConfig';
import ExpenseFilterCheckbox from '../Expense/ExpenseFilterCheckbox';
import ExpenseFilterDate from '../Expense/ExpenseFilterDate';
import { useFormContext } from 'react-hook-form';
import FilterAltIcon from '../../../assets/icons/FilterAltIcon.js';
import { ExpenseStatusEnum } from '../Expense/ExpenseStatus.enum.js';
import { PreCodedStatusEnum } from '../Expense/PreCodedStatus.enum.js';
import { ExpenseBillTypeEnum } from '../Expense/BillType.enum.js';
import { getNeedsReviewReasons } from '../../Services/ExpenseService';

const styles = (theme) => ({
    formControl: {
        marginBottom: '0',
        minWidth: '215px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '6px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        '& $disabled': {
            borderRadius: '6px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            background: COLORS.LT_MIDNIGHTBG2,
            color: COLORS.LT_MIDNIGHT33,
        },
        borderRadius: '6px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    inputRoot: {
        '&$disabled $notchedOutline': {
            borderColor: 'transparent',
        },

        '&$disabled $input': {
            background: COLORS.LT_MIDNIGHTBG2,
            color: COLORS.LT_MIDNIGHT33,
        },
    },
    disabled: {},
    notchedOutline: {},
    input: {
        border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
        borderRadius: '6px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        padding: '8px 16px !important',
        height: 'auto',
        fontSize: '14px',
        lineHeight: '20px',
        '&$disabled': {
            background: COLORS.LT_MIDNIGHTBG2,
        },
    },
    searchKeyButton: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        height: '37px',
        minWidth: '130px',
        justifyContent: 'space-between',
    },
    searchHelperText: {
        position: 'absolute',
        bottom: '-24px',
        marginLeft: 0,
    },
    filterSpacing: {
        paddingTop: '2em',
    },
    title: {
        padding: '2em',
    },
    container: {
        maxHeight: '90em',
        overflow: 'auto',
        paddingLeft: '2em',
        paddingRight: '2em',
        position: 'relative',
        height: '90em',
    },
    header: {
        fontSize: '1.5em',
        color: '#336195',
        padding: '2em',
    },
    exit: {
        marginLeft: '13em',
        marginBottom: '3em',
        cursor: 'pointer',
    },
    filterButton: {
        color: COLORS.LT_JOURNEY,
        background: COLORS.LT_JOURNEY10,
    },
});

const ExpenseFiltersForm = (props) => {
    const {
        classes,
        criteria,
        fetchData,
        setIsLoading,
        setOffset,
        setPage,
        limit,
        sortBy,
        sortDirection,
        expenseCategories,
        availableDivisions,
        pendingCCReview
    } = props;
    const { watch, getValues, reset } = useFormContext();
    const statusEnum = pendingCCReview ? PreCodedStatusEnum : ExpenseStatusEnum;
    const statusList = Object.values(statusEnum);
    const filteredStatusList = statusList.filter(status => status.value !== 'New');
    const billTypeList = Object.values(ExpenseBillTypeEnum);
    const filteredBillTypeList = pendingCCReview ? billTypeList.filter(billType => billType !== 'Clinician Deduction') : billTypeList;
    const allFormValues = watch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const countTotalNumFilters = (obj) => {
        var count = 0;
        var dateRanges = new Set([
            'StartStartDate', 'StartEndDate',
            'EndStartDate', 'EndEndDate',
            'PostStartDate', 'PostEndDate',
            'PeriodEndStart', 'PeriodEndEnd',
            'TransactionStartDate', 'TransactionEndDate'
        ]);
        var dateRangesCounted = new Set();

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                var value = obj[key];
                if (value !== null && !(Array.isArray(value) && value.length === 0) && !!value) {
                    if (dateRanges.has(key)) {
                        // Get the base name of the date range
                        var baseName = key;
                        if (key.startsWith('PeriodEnd')) {
                            baseName = 'PeriodEnd';
                        } else {
                            baseName = key.replace(/(StartDate|EndDate)/, '');
                        }
                        if (!dateRangesCounted.has(baseName)) {
                            dateRangesCounted.add(baseName);
                            count++;
                        }
                    } else {
                        count++;
                    }
                }
            }
        }
        return count;
    };
    const [lastAppliedFilterCount, setLastAppliedFilterCount] = useState(countTotalNumFilters(allFormValues));

    const toggleDrawer = (newOpen) => {
        setDrawerOpen(newOpen);
        if (newOpen) {
            const criteria = pendingCCReview ? 'pendingCCCriteria' : 'expenseCriteria';
            const savedFilterValues = localStorage.getItem(criteria);
            const filterValues = JSON.parse(savedFilterValues);
            reset({
                Statuses: filterValues ? filterValues.statuses : [],
                Divisions: filterValues ? filterValues.divisions : [],
                'Expense Types': filterValues && filterValues.expenseTypes ? filterValues.expenseTypes : [],
                'Bill Types': filterValues && filterValues.billTypes ? filterValues.billTypes : [],
                Sources: filterValues && filterValues.sources ? filterValues.sources : [],
                'Needs Review Reasons': filterValues && filterValues.needsReviewReasons ? filterValues.needsReviewReasons : [],
                'Merchant States': filterValues && filterValues.merchantStates ? filterValues.merchantStates : [],
                StartStartDate: filterValues && filterValues.startStartDate ? filterValues.startStartDate : null,
                StartEndDate: filterValues && filterValues.startEndDate ? filterValues.startEndDate : null,
                EndStartDate: filterValues && filterValues.endStartDate ? filterValues.endStartDate : null,
                EndEndDate: filterValues && filterValues.endEndDate ? filterValues.endEndDate : null,
                PostStartDate: filterValues && filterValues.postStartDate ? filterValues.postStartDate : null,
                PostEndDate: filterValues && filterValues.postEndDate ? filterValues.postEndDate : null,
                PeriodEndStart: filterValues && filterValues.periodEndStart ? filterValues.periodEndStart : null,
                PeriodEndEnd: filterValues && filterValues.periodEndEnd ? filterValues.periodEndEnd : null,
                TransactionStartDate:
                    filterValues && filterValues.transactionStartDate ? filterValues.transactionStartDate : null,
                TransactionEndDate:
                    filterValues && filterValues.transactionEndDate ? filterValues.transactionEndDate : null,
            });
        }
    };
    const states = config.states.map((state) => state.value);

    const [needsReviewReasonsDictionary, setNeedsReviewReasonsDictionary] = useState([]);
    useEffect(() => {
        const fetchNeedsReviewReasons = async () => {
            try {
                const needsReviewReasonsDictionary = await getNeedsReviewReasons();
                setNeedsReviewReasonsDictionary(needsReviewReasonsDictionary);
            } catch (error) {
                console.error(error);
            }
        };
        fetchNeedsReviewReasons();
    }, []);
    const needsReviewReasons = Object.values(needsReviewReasonsDictionary);

    const [search, setSearch] = useState();
    const [debouncedSearch, setDebouncedSearch] = useState(search);

    const [searchKey, setSearchKey] = useState(config.searchKeys[0]);
    const searchRef = useRef(null);

    const debouncedRequest = useDebounce(() => {
        if (search?.length >= 3 && search?.length <= 200) {
            setDebouncedSearch(search);
            handleSearch(search);
        } else if (debouncedSearch !== '') {
            setDebouncedSearch('');
            handleSearch('');
        }
    });

    const onCloseDrawer = () => {
        toggleDrawer(false);
    };

    const onClear = () => {
        reset({
            Statuses: [],
            Divisions: [],
            'Expense Types': [],
            'Bill Types': [],
            Sources: [],
            'Needs Review Reasons': [],
            'Merchant States': [],
            StartStartDate: null,
            StartEndDate: null,
            EndStartDate: null,
            EndEndDate: null,
            PostStartDate: null,
            PostEndDate: null,
            PeriodEndStart: null,
            PeriodEndEnd: null,
            TransactionStartDate: null,
            TransactionEndDate: null,
        });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterApply = async () => {
        setLastAppliedFilterCount(countTotalNumFilters(getValues()));
        setIsLoading(true);
        const newOffset = 0;
        const newPage = 1;
        setOffset(newOffset);
        setPage(newPage);
        const request = {
            offset: newOffset,
            sortBy,
            sortDirection,
            limit: 10,
            statuses: getValues('Statuses'),
            divisions: getValues('Divisions'),
            transactionStartDate: getValues('TransactionStartDate'),
            transactionEndDate: getValues('TransactionEndDate'),
            expenseTypes: getValues('Expense Types'),
            sources: getValues('Sources'),
            needsReviewReasons: getValues('Needs Review Reasons'),
            billTypes: getValues('Bill Types'),
            startStartDate: getValues('StartStartDate'),
            startEndDate: getValues('StartEndDate'),
            endStartDate: getValues('EndStartDate'),
            endEndDate: getValues('EndEndDate'),
            postStartDate: getValues('PostStartDate'),
            postEndDate: getValues('PostEndDate'),
            periodEndStart: getValues('PeriodEndStart'),
            periodEndEnd: getValues('PeriodEndEnd'),
            merchantStates: getValues('Merchant States'),
        };
        toggleDrawer(false);
        await fetchData(request);
    };

    const renderFilterTitle = () => {
        return (
            <Grid container alignItems='center' spacing={1}>
                <Grid item>
                    <Typography variant='h5' component='h5' className={classes.header}>
                        Filters ({countTotalNumFilters(allFormValues)})
                    </Typography>
                </Grid>
                <Grid item className={classes.exit}>
                    <CloseIcon onClick={() => toggleDrawer(false)} />
                </Grid>
            </Grid>
        );
    };

    const renderFilterButtons = () => {
        return (
            <Grid container spacing={1} className={classes.title}>
                <Grid item>
                    <Button variant='contained' color='default' onClick={() => onClear()}>
                        Clear All
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        aria-controls='search-key'
                        aria-haspopup='true'
                        onClick={() => handleFilterApply()}
                        variant='contained'
                        color='primary'
                        id='search-key'
                    >
                        Apply Filters
                    </Button>
                </Grid>
            </Grid>
        );
    };

    const renderDrawer = () => {
        return (
            <Drawer open={drawerOpen} onClose={() => onCloseDrawer()}>
                {renderFilterTitle()}
                <Grid className={classes.container}>
                    <ExpenseFilterCheckbox filterName={'Statuses'} list={filteredStatusList} />
                    {!pendingCCReview &&
                        (<ExpenseFilterDate
                            filterName={'Period End Date'}
                            displayStartDate={'PeriodEndStart'}
                            displayEndDate={'PeriodEndEnd'}
                        />)}
                    <ExpenseFilterDate
                        filterName={'Transaction Date'}
                        displayStartDate={'TransactionStartDate'}
                        displayEndDate={'TransactionEndDate'}
                    />
                    {!pendingCCReview &&
                        (<ExpenseFilterDate
                            filterName={'Post Date'}
                            displayStartDate={'PostStartDate'}
                            displayEndDate={'PostEndDate'}
                        />)}
                    {!pendingCCReview &&
                        (<ExpenseFilterCheckbox filterName={'Merchant States'} list={states} />)}
                    <ExpenseFilterDate
                        filterName={'Start Date'}
                        displayStartDate={'StartStartDate'}
                        displayEndDate={'StartEndDate'}
                    />
                    <ExpenseFilterDate
                        filterName={'End Date'}
                        displayStartDate={'EndStartDate'}
                        displayEndDate={'EndEndDate'}
                    />

                    <ExpenseFilterCheckbox filterName={'Bill Types'} list={filteredBillTypeList} />
                    <ExpenseFilterCheckbox
                        filterName={'Divisions'}
                        list={availableDivisions.map(a => a.description)}
                    />

                    <ExpenseFilterCheckbox
                        filterName={'Expense Types'}
                        list={expenseCategories}
                        isExpenseType={true}
                    />
                    {!pendingCCReview &&
                        (<ExpenseFilterCheckbox filterName={'Sources'} list={config.source} />)}
                    {!pendingCCReview &&
                        (<ExpenseFilterCheckbox
                            filterName={'Needs Review Reasons'}
                            list={needsReviewReasons}
                        />)}
                </Grid>
                {renderFilterButtons()}
            </Drawer>
        );
    };

    const renderFilters = () => (
        <Grid item style={{ flex: 1 }}>
            <Button onClick={() => toggleDrawer(true)} className={classes.filterButton}>
                <FilterAltIcon />
                Filters ({lastAppliedFilterCount})
            </Button>
            {renderDrawer()}
        </Grid>
    );

    const renderSearch = () => (
        <Grid item>
            <Grid container>
                <Grid item>
                    <Button
                        aria-controls='search-key'
                        aria-haspopup='true'
                        onClick={handleClick}
                        variant='contained'
                        color='primary'
                        id='search-key'
                        endIcon={<ExpandMoreIcon fontSize='small' />}
                        className={classes.searchKeyButton}
                    >
                        {searchKey?.name}
                    </Button>
                    <Menu
                        id='search-key-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl?.id === 'search-key')}
                        onClose={handleClose}
                    >
                        {config.searchKeys.map((key) => (
                            <MenuItem
                                key={key.name}
                                selected={key === searchKey}
                                onClick={() => handleSearchKey(key)}
                            >
                                {key.name}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item>
                    <TextField
                        id='search'
                        type='search'
                        variant='outlined'
                        defaultValue={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            debouncedRequest();
                        }}
                        className={classes.formControl}
                        InputProps={{
                            classes: {
                                root: classes.inputRoot,
                                input: classes.input,
                                disabled: classes.disabled,
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                        inputRef={searchRef}
                        FormHelperTextProps={{ className: classes.searchHelperText }}
                        helperText={
                            search && search?.length < 3
                                ? 'Please enter 3 or more characters'
                                : search && search?.length > 200
                                    ? 'Must be less than 200 characters'
                                    : ''
                        }
                        error={Boolean(search && search?.length > 200)}
                        placeholder='Search expenses'
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid
            container
            justifyContent='space-between'
            spacing={2}
            className={classes.filterSpacing}
        >
            {renderFilters()}
            {!pendingCCReview && (renderSearch())}
        </Grid>
    );
};

export default withStyles(styles)(ExpenseFiltersForm);
