import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles, Button, Hidden } from '@material-ui/core';
import {
	COLORS,
	ACTIVE_PATHNAME_AP,
	ACTIVE_PATHNAME_MD,
	CRED_STATE,
	CRED_APP_STATUS,
} from '../../../../../utils/Application_Constants';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import menuNavigation from '../../../../../configuration/navigation/credentials/menuItems.json';
import menuItems_AP_path from '../../../../../configuration/navigation/credentials/menuItems_AP_path.json';
import SectionAndStatusComponent from './SectionAndStatusComponent';
import ViewAndSignActionMenu from './ViewAndSignActionMenu';
import {
	getClinicianCredApp,
	getClinicianCredAppCreatePdf,
	getClinicianCredAppPdfStatus,
	getClinicianCredAppSigningUrl,
	getCredAppStatus,
	getClinicianCredAppDocumentURL,
} from '../../../../../services/Credentialing';
import {
	personalInformationSectionCheck,
	documentLibraryStatusCheck,
	otherCertificationsStatusCheck,
	certificationExamsStatusCheck,
	malpracticeCarriersStatusCheck,
	disciplinaryActionQuestionsStatusCheck,
	disclosureQuestionsStatusCheck,
	licensureStatusCheck,
	referencesStatusCheck,
	internshipSectionCheck,
	residencySectionCheck,
	fellowshipSectionCheck,
	additionalTrainingSectionCheck,
	practiceExperienceSectionCheck,
	hospitalAffiliationSectionCheck,
	gapsInWorkHistorySectionCheck,
	gapsInTrainingSectionCheck,
	militaryExperienceSectionCheck,
	malpracticeClaimsHistorySectionCheck,
	educationSectionCheck,
	boardCertificationsStatusCheck,
	getCredAppStatusObject,
} from '../../../../../utils/CredentialingAppUtility';
import ToolTipComponent from '../../../../GlobalComponents/ToolTipComponent';
import HelpIcon from '@material-ui/icons/Help';
import AdobeSignDocumentModal from '../../../AdobeSignModal/AdobeSignDocumentModal';
import Lottie from 'lottie-react';
import credentialsLottie from '../../../../../assets/images/startCredentialing-lottie.json';
import moment from 'moment';
import { appInsights } from '../../../../../AppInsights';

const styles = (theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	inputRadio: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
	},

	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
			textAlign: 'left',
		},
	},
	gridRootStatus: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
			marginBottom: '10px',
			textAlign: 'left',
			marginLeft: '2px',
		},
	},
	gridSignedStatus: {
		[theme.breakpoints.up('md')]: {
			fontSize: '13px',
			paddingTop: '12px',
			fontWeight: '500',
			marginLeft: '24px',
			textAlign: 'left',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '18px',
			margin: '5px 10px',
		},
	},
	docGridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '22px',
			marginBottom: '10px',
			textAlign: 'left',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
			textAlign: 'left',
		},
	},
	docGridRootFileName: {
		[theme.breakpoints.up('md')]: {
			paddingTop: '12px',
			display: 'flex',
			marginLeft: '20px',
			marginBottom: '10px',
			textAlign: 'left',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
			marginLeft: '20px',
		},
	},
	docGridRootAction: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '74px',
			marginBottom: '10px',
			textAlign: 'left',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
			marginLeft: '20px',
		},
	},
	gridRootForButton: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	completedIcon: {
		color: 'green',
	},
	clearFloat: {
		float: 'none',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		'& input': {
			height: '32px',
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.RAVEN,
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},

	cssOutlinedInput: {
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '18.5px 14px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
		'&$cssFocused $notchedOutline': {
			borderColor: '#6D707E', //focused
			borderWidth: '1px',
			borderRadius: 1,
		},
	},

	cssFocused: {},

	disabled: {},
	focused: {},
	error: {
		borderColor: '#6D707E', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	progressWrapper: {
		paddingLeft: '40px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '10px',
			paddingTop: '10px',
		},
	},
	progressRoot: {
		[theme.breakpoints.up('md')]: {
			marginTop: '25vh',
			marginLeft: '30vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '20vh',
			marginLeft: '25vh',
		},
	},
	successGrid: {
		marginTop: '25px',
	},
	iconButtonMargin: {
		marginRight: '10px',
	},
	addMoreArea: {
		color: COLORS.TRUE_BLUE,
		backgroundColor: COLORS.CONCRETE,
		paddingTop: '8px',
		paddingBottom: '8px',
		textAlign: 'center',
		cursor: 'pointer',
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
	},
	secondaryHeaderArea: {
		alignItems: 'center',
		maxWidth: '100%',
		marginTop: '5px',
		marginBottom: '10px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	marginLeft12: {
		marginLeft: 12,
	},
	gapTitle: {
		color: COLORS.ST_TROPAZ,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 0 0px 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		fontSize: '17px',
		fontWeight: '700',
	},
	gapMessage: {
		color: COLORS.RAVEN,
		float: 'left',
		textAlign: 'left',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '32px',
		paddingBottom: '15px',
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '32px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	disabeldInput: {
		opacity: 0.6,
	},
	successMessage: {
		color: COLORS.KELLY_GREEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '13px',
		fontWeight: '700',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '32px',
		paddingBottom: '15px',
	},
	successMessageLine1: {
		color: COLORS.KELLY_GREEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '18px',
		fontWeight: '700',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '32px',
		paddingBottom: '15px',
	},
	errorMessage: {
		color: COLORS.MILANO_RED,
		float: 'left',
		textAlign: 'left',
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '32px',
		paddingBottom: '15px',
	},
	title: {
		fontSize: '13px',
		fontWeight: '900',
	},
	titleGrid: {
		borderBottom: 'solid 1px #e7e7e7',
		marginLeft: '32px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '20px',
		},
	},
	viewAndSignGrid: { marginLeft: '-20px' },
	noApplicationMessage: {
		fontSize: '13px',
		fontWeight: '300',
		color: COLORS.RAVEN,
		fontStyle: 'italic',
		padding: '20px 0px',
	},
	chipRootGreen: {
		border: `1px solid #009639`,
		color: COLORS.KELLY_GREEN,
		backgroundColor: '#f0f8ff',
		//marginLeft: '15px',
		//marginTop: '15px',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
		},
	},
	chipRootRed: {
		border: `1px solid ${COLORS.MILANO_RED}`,
		color: COLORS.MILANO_RED,
		backgroundColor: '#ffcccb',
		//marginLeft: '15px',
		//marginTop: '15px',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
		},
	},
	chipRootYellow: {
		border: `1px solid #ffc40c`,
		color: '#ffc40c',
		backgroundColor: '#fffacd',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
		},
	},
	chipLabel: {
		fontWeight: '300',
		fontSize: '12px',
		//padding: '0px 20px',
		cursor: 'pointer',
	},
	dateStyle: {
		fontSize: '13px',
		fontWeight: '500',
		color: COLORS.RAVEN,
	},
	dateSignedStyle: {
		[theme.breakpoints.up('md')]: {
			fontSize: '13px',
			paddingTop: '12px',
			fontWeight: '500',
			marginLeft: '22px',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '26px',
		},
	},
	documentLabel: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
	},
	viewAndSignButtonStyle: {
		backgroundColor: COLORS.KELLY_GREEN,
		color: '#ffffff',
		fontSize: '13px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: COLORS.KELLY_GREEN,
		},
	},
	documentLink: {
		fontSize: '13px',
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		padding: '20px 0px',
		cursor: 'pointer',
	},
	statusComplete: {
		// Green
		color: '#009639',
	},
	statusIncomplete: {
		// Yellow
		color: '#c7cc00',
	},
	statusNotSigned: {
		// RED
		color: '#EA3219',
	},
	disableViewandSignMessage: {
		color: COLORS.LT_PEPPER,
		float: 'left',
		textAlign: 'left',
		fontSize: '13px',
		fontWeight: '700',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '30px',
		paddingBottom: '10px',
	},
});

const CheckStatusAndSignForm = ({
	classes,
	t,
	UserContext,
	activePath,
	credState,
	setCredAppError,
	titles,
	segment,
	tabHandler,
	credAppStatus,
	profession,
	shouldHighlightFields,
	updateHighlightFieldsState,
	flags,
	closeError,
	updateSectionCompletion,
	sectionCompletion,
}) => {
	const credentialingApp = {
		personalInformation: 'incomplete',
		education: 'incomplete',
		internship: 'incomplete',
		residency: 'incomplete',
		fellowship: 'incomplete',
		gapsInTraining: 'incomplete',
		additionalTraining: 'incomplete',
		licensure: 'incomplete',
		boardCertifications: 'incomplete',
		otherCertifications: 'notProvided',
		certificationExams: 'notProvided',
		hospitalAffiliation: 'incomplete',
		practiceExperience: 'incomplete',
		militaryExperience: 'incomplete',
		gapsInWorkHistory: 'incomplete',
		references: 'incomplete',
		malpracticeClaimsHistory: 'incomplete',
		malpracticeCarriers: 'notProvided',
		disciplinaryActionQuestions: 'incomplete',
		disclosureQuestions: 'incomplete',
		documentLibrary: 'incomplete',
	};

	const navigationPath =
		activePath === ACTIVE_PATHNAME_AP ? menuItems_AP_path.data : menuNavigation.data;
	//const [credentialingObject, setCredentialingObject] = useState({})
	const [isLoaded, setIsLoaded] = useState(false);
	const [credentialingAppObject, setCredentialingAppObject] = useState(credentialingApp);
	const [credAppStatusObject, setCredAppStatusObject] = useState({});
	const [delay] = useState(1000);
	const [isRunning, setIsRunning] = useState(true);
	const [statusCheck, setStatusCheck] = useState(true);
	const [downloadStatusCheck, setDownloadStatusCheck] = useState(true);
	const [pollApplicationStatus, setPollApplicationStatus] = useState(false);
	const [pollCredDocStatus, setPollCredDocStatus] = useState(false);
	const { externalId, account } = UserContext;
	const [externalUserId] = useState(externalId);
	const [credentialingId, setCredentialingId] = useState('');
	const [credentialingStatus, setCredentialingStatus] = useState('');
	const [credentialingSigningUrl, setCredentialingSigningUrl] = useState('');
	const [isViewAndSign, setIsViewAndSign] = useState(false);
	const [viewAndSignBtnClicked, setViewAndSignBtnClicked] = useState(true);
	const [timerId, setTimerId] = useState(null);
	const [responseTimeout, setResponseTimeout] = useState('');
	const [credAppSignedDocument, setCredAppSignedDocument] = useState({});
	const [disableSignandView, setDisableSignandView] = useState(false);

	const viewAndSignButtonHandler = async () => {
		//setCredentialingStatus('')
		//setCredentialingSigningUrl('')
		//setViewAndSignBtnClicked(false)
		setResponseTimeout('');
		setIsViewAndSign(!isViewAndSign);
		setIsRunning(true);

		//load credentialingId
		const newCredentialingId = await getClinicianCredAppCreatePdf(externalId).catch((e) => {
			setIsLoaded(true);
			setCredAppError(e);
		});
		setCredentialingId(newCredentialingId);
	};

	// const chipRootColorClass = (colorCode) => {
	// 	if (colorCode === 'Red') {
	// 		return classes.chipRootRed;
	// 	} else if (colorCode === 'Yellow') {
	// 		return classes.chipRootYellow;
	// 	} else if (colorCode === 'Green') {
	// 		return classes.chipRootGreen;
	// 	}
	// };

	const disableViewandSignButton = () => {
		var startTime = localStorage.getItem('LocalStorgae_startTimerViewandSign');
		console.log(' useInterval startTime' + startTime);
		if (startTime) {
			var currentTime = moment();
			console.log(' useInterval currentTime' + currentTime);
			const diff = currentTime.diff(startTime, 'minutes');
			console.log(' useInterval Minutes Difference use Interval Diff :', diff);
			if (diff && diff >= 3) {
				setDisableSignandView(false);
				localStorage.removeItem('LocalStorgae_startTimerViewandSign');
			} else {
				setDisableSignandView(true);
			}
		}
	};

	const reDisplayViewandSign = async () => {
		setDisableSignandView(true);
		let appStatus = null;

		setTimeout(async function () {
			appStatus = await getCredAppStatus(externalId).catch((e) => {
				setIsLoaded(true);
				setCredAppError(e);
			}); // this will be called after 30 seconds.
			const credAppStatusObj = await getCredAppStatusObject(appStatus.status);
			console.log(
				' reDisplayViewandSign credAppStatusObj ' + JSON.stringify(credAppStatusObj)
			);

			if (credAppStatusObj && credAppStatusObj.status !== 'Signed') {
				setViewAndSignBtnClicked(true);
			} else if (
				credAppStatusObj &&
				(credAppStatusObj.status === 'Signed' || credAppStatusObj.status === 'SIGNED')
			) {
				console.log(
					` reDisplayViewandSign document is signed now ` +
						JSON.stringify(credAppStatusObj)
				);
				setPollCredDocStatus(true);
				setPollApplicationStatus(true);
				setDownloadStatusCheck(true);
				setViewAndSignBtnClicked(false);
				setStatusCheck(true);
				//setCredAppStatusObject(credAppStatusObj);
			}
		}, 180000);
	};

	const toggleViewAndSignModal = async (isViewable) => {
		setIsViewAndSign(isViewable);
		setPollApplicationStatus(true);
		setPollCredDocStatus(true);

		if (isViewable === false) {
			//setTimeout(function() {
			reDisplayViewandSign(); // this will be called after 30 seconds.
			//}.bind(this), 30000)
		}
		var startTime = localStorage.getItem('LocalStorgae_startTimerViewandSign');
		if (!startTime) {
			startTime = moment();
			localStorage.setItem('LocalStorgae_startTimerViewandSign', startTime);
		}
	};

	useInterval(async () => {
		disableViewandSignButton();
	}, 30000);

	useInterval(
		async () => {
			if (pollApplicationStatus) {
				let appStatus = await getCredAppStatus(externalId).catch((e) => {
					setIsLoaded(true);
					setCredAppError(e);
				});
				//Time interval for check - 1 mins
				setTimeout(function () {
					setStatusCheck(false);
					setPollApplicationStatus(false);
				}, 60000);

				if (appStatus.status !== credAppStatus) {
					console.log('Different Status found' + appStatus.status);
					const credAppStatusObj = await getCredAppStatusObject(appStatus.status);
					setCredAppStatusObject(credAppStatusObj);
					setPollApplicationStatus(false);
					setStatusCheck(false);
				}
			}
		},
		statusCheck ? delay : null
	);

	//Download API Polling
	useInterval(
		async () => {
			const thankYouPage = localStorage.getItem('LocalStorage_Thankyou');
			if (pollCredDocStatus || (thankYouPage && thankYouPage === 'YES')) {
				let downloadDocObj = await getClinicianCredAppDocumentURL(externalId).catch((e) => {
					setIsLoaded(true);
					setCredAppError(e);
				});
				//Time interval for check - 1 mins
				setTimeout(function () {
					setDownloadStatusCheck(false);
					setPollCredDocStatus(false);
					setCredAppSignedDocument(downloadDocObj);
					localStorage.setItem('LocalStorage_Thankyou', 'NO');
				}, 90000);

				if (downloadDocObj.adobeSignDocumentStatus === 'SIGNED') {
					setCredAppSignedDocument(downloadDocObj);
					setPollCredDocStatus(false);
					setDownloadStatusCheck(false);

					appInsights.trackEvent({
						name: 'CredAppSigned',
						properties: {
							externalId: externalId,
							b2cId: account.accountIdentifier,
						},
					});
				}
			}
		},
		downloadStatusCheck ? delay : null
	);

	const loadCredObject = async (externalId) => {
		let credObject = await getClinicianCredApp(externalId).catch((e) => {
			setIsLoaded(true);
			setCredAppError(e);
		});
		return credObject;
	};

	const getCredentialingStatus = async () => {
		let credentialingStatus = await getClinicianCredAppPdfStatus(
			externalUserId,
			credentialingId
		).catch((e) => {
			setIsLoaded(true);
			setCredAppError(e);
		});

		return credentialingStatus;
	};

	const loadCredSigningUrl = async (externalId, attachmentId) => {
		let credentialingSigningUrl = await getClinicianCredAppSigningUrl(
			externalId,
			attachmentId
		).catch((e) => {
			setIsLoaded(true);
			setCredAppError(e);
		});
		return credentialingSigningUrl;
	};

	useInterval(
		async () => {
			if (externalUserId && credentialingId && isViewAndSign) {
				let statusID;
				if (credentialingStatus === '') {
					statusID = await getCredentialingStatus();

					//Time interval for check - 3 mins
					setTimeout(function () {
						if (statusID === '') {
							console.error('Did not get response in 3 mins');
							setResponseTimeout('Error occurred, please try again later.');
							// clearInterval(timerId)
							setIsRunning(false);
						}
					}, 180000);
				}

				if (statusID !== '' && statusID !== undefined) {
					console.log('TImeer ID from usetInterval' + timerId);
					// clearInterval(timerId)
					setCredentialingStatus(statusID);
					if (credentialingSigningUrl === '') {
						let signingUrl = await loadCredSigningUrl(externalUserId, statusID);
						setCredentialingSigningUrl(signingUrl);
					}

					setIsRunning(false);
				}
			}
		},
		isRunning ? delay : null
	);

	function useInterval(callback, delay) {
		const savedCallback = useRef();

		// Remember the latest function.
		useEffect(() => {
			savedCallback.current = callback;
		}, [callback]);

		// Set up the interval.
		useEffect(() => {
			function tick() {
				savedCallback.current();
			}
			if (delay !== null) {
				let id = setInterval(tick, delay);
				setTimerId(id);
				console.log('TImeer ID' + timerId);
				return () => clearInterval(id);
			}
		}, [delay]);
	}

	//This function checks cred object for 'incomplete' value and return for incomplete message on the page
	const checkCredApplicationSections = () => {
		let checkFlag;
		const obj = credentialingAppObject;
		if (activePath === ACTIVE_PATHNAME_MD) {
			delete obj.additionalTraining;
		} else if (activePath === ACTIVE_PATHNAME_AP) {
			delete obj.internship;
			delete obj.residency;
			delete obj.fellowship;
			delete obj.gapsInTraining;
		}

		for (var key in obj) {
			if (obj.hasOwnProperty(key)) {
				if (obj[key] === 'incomplete') {
					checkFlag = false;
					break;
				} else {
					checkFlag = true;
				}
			}
		}
		return checkFlag;
	};

	useEffect(() => {
		if (!flags.isError) {
			(async function fetchData() {
				const { externalId } = UserContext;
				const credObject = await loadCredObject(externalId);
				const credAppStatusObj = await getCredAppStatusObject(credAppStatus);
				setCredAppStatusObject(credAppStatusObj);

				const [
					personalInfoCheck,
					educationCheck,
					internshipCheck,
					residencyCheck,
					fellowshipCheck,
					hospitalAffiliationCheck,
					practiceExperienceCheck,
					additionalTrainingCheck,
					documentLibraryCheck,
					referencesCheck,
					licensureCheck,
					otherCertificationsCheck,
					certificationExamsCheck,
					boardCertificationsCheck,
					malpracticeCarriersCheck,
					malpracticeClaimsHistoryCheck,
					gapsInTrainingCheck,
					gapsInWorkHistoryCheck,
					militaryExperienceCheck,
					disciplinaryActionQuestionsCheck,
					disclosureQuestionsCheck,
					credAppDocumentURL,
				] = await Promise.all([
					personalInformationSectionCheck(credObject.personal),
					educationSectionCheck(credObject.education, activePath, titles, segment),
					internshipSectionCheck(
						!!credObject.internship ? credObject.internship.trainings : [],
						activePath
					),
					residencySectionCheck(
						!!credObject.residency ? credObject.residency.trainings : [],
						activePath
					),
					fellowshipSectionCheck(
						!!credObject.fellowship ? credObject.fellowship.trainings : [],
						activePath
					),
					hospitalAffiliationSectionCheck(
						!!credObject.affiliation ? credObject.affiliation.workHistories : [],
						activePath
					),
					practiceExperienceSectionCheck(
						!!credObject.practiceExperience
							? credObject.practiceExperience.workHistories
							: [],
						activePath
					),
					additionalTrainingSectionCheck(
						!!credObject.additionalTraining
							? credObject.additionalTraining.trainings
							: [],
						activePath
					),
					documentLibraryStatusCheck(externalId),
					referencesStatusCheck(!!credObject.reference ? credObject.reference : []),
					licensureStatusCheck(
						!!credObject.licensure ? credObject.licensure.licensures : [],
						profession,
						activePath
					),
					otherCertificationsStatusCheck(
						!!credObject.certification ? credObject.certification.certifications : []
					),
					certificationExamsStatusCheck(
						!!credObject.examination ? credObject.examination.examinations : []
					),
					boardCertificationsStatusCheck(
						!!credObject.boardCertification
							? credObject.boardCertification.providerSpecialties
							: [],
						activePath,
						segment,
						!!credObject.boardCertification
							? credObject.boardCertification.boardCertified
							: null
					),
					malpracticeCarriersStatusCheck(
						!!credObject.malpracticeCarrier ? credObject.malpracticeCarrier : []
					),
					malpracticeClaimsHistorySectionCheck(
						credObject.malpracticeClaimHistory,
						activePath
					),
					gapsInTrainingSectionCheck(
						!!credObject.trainingGap ? credObject.trainingGap.gaps : [],
						activePath
					),
					gapsInWorkHistorySectionCheck(
						!!credObject.workGap ? credObject.workGap.gaps : [],
						activePath
					),
					militaryExperienceSectionCheck(credObject.militaryExperience),
					disciplinaryActionQuestionsStatusCheck(
						!!credObject.attestation ? credObject.attestation.questions : []
					),
					disclosureQuestionsStatusCheck(
						!!credObject.attestation ? credObject.attestation.questions : []
					),
					getClinicianCredAppDocumentURL(externalId),
				]);

				const newObj = { ...credentialingAppObject };
				newObj.personalInformation = personalInfoCheck.status;
				newObj.education = educationCheck.status;
				newObj.internship = internshipCheck.status;
				newObj.residency = residencyCheck.status;
				newObj.documentLibrary = documentLibraryCheck.status;
				newObj.references = referencesCheck.status;
				newObj.licensure = licensureCheck.status;
				newObj.fellowship = fellowshipCheck.status;
				newObj.hospitalAffiliation = hospitalAffiliationCheck.status;
				newObj.practiceExperience = practiceExperienceCheck.status;
				newObj.additionalTraining = additionalTrainingCheck.status;
				newObj.boardCertifications = boardCertificationsCheck.status;
				newObj.otherCertifications = otherCertificationsCheck;
				newObj.certificationExams = certificationExamsCheck.status;
				newObj.malpracticeCarriers = malpracticeCarriersCheck.status;
				newObj.gapsInTraining = gapsInTrainingCheck.status;
				newObj.malpracticeClaimsHistory = malpracticeClaimsHistoryCheck.status;
				newObj.gapsInWorkHistory = gapsInWorkHistoryCheck.status;
				newObj.militaryExperience = militaryExperienceCheck.status;
				newObj.disciplinaryActionQuestions = disciplinaryActionQuestionsCheck.status;
				newObj.disclosureQuestions = disclosureQuestionsCheck.status;
				setCredentialingAppObject(newObj);
				updateSectionCompletion(sectionCompletion);
				setCredAppSignedDocument(credAppDocumentURL);
				setIsLoaded(true);
			})();
			updateHighlightFieldsState(true);
			disableViewandSignButton();
		}
	}, []);

	const download = () => {
		//setAnchorEl(null);
		//const downloadDocURL = ( (credAppSignedDocument !== undefined && credAppSignedDocument !== null && credAppSignedDocument.adobeSignDocumentStatus === 'SIGNED')
		//    || ((!!credAppSignedDocument.name && credAppSignedDocument.name.includes('Amended')))) ? credAppSignedDocument.downloadUrl : ''

		const downloadDocURL =
			(credAppSignedDocument !== undefined &&
				credAppSignedDocument !== null &&
				credAppSignedDocument.adobeSignDocumentStatus === 'SIGNED') ||
			(!!credAppSignedDocument.name && credAppSignedDocument.name.includes('Amended'))
				? credAppSignedDocument.downloadUrl
				: '';

		if (downloadDocURL) {
			window.open(downloadDocURL);
		} else {
			alert('Document not available for download!');
		}
	};

	const renderForm = () => {
		return (
			<>
				<CssBaseline />

				<Grid className={classes.titleGrid} item xs={12}>
					<Grid container>
						<Grid classes={{ root: classes.gridRoot }} item xs={6} md={6}>
							<span className={classes.title}>
								{t(`credentials:STATUSANDSIGN.SECTION`)}
							</span>
						</Grid>
						<Grid classes={{ root: classes.gridRootStatus }} item xs={4} md={4}>
							<div align='center'>
								<span className={classes.title}>
									{t(`credentials:STATUSANDSIGN.STATUS`)}
								</span>
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Grid className={classes.titleGrid} item xs={12}>
					{/* <SectionAndStatusComponent menuNavigation={navigationPath} personalInformation={personalInformation} /> */}
					<SectionAndStatusComponent
						tabHandler={tabHandler}
						menuNavigation={navigationPath}
						credentialingAppObject={credentialingAppObject}
						updateHighlightFieldsState={updateHighlightFieldsState}
					/>
				</Grid>
			</>
		);
	};

	const adobeSignDocumentModal = () => {
		if (isViewAndSign === true) {
			return (
				<AdobeSignDocumentModal
					toggleHandler={toggleViewAndSignModal}
					openDialog={isViewAndSign}
					signingUrl={credentialingSigningUrl}
					responseTimeout={responseTimeout}
				/>
			);
		}

		return null;
	};

	const viewAndSignComponent = () => {
		const statusFlag =
			credState !== CRED_STATE.CLOSED &&
			checkCredApplicationSections() &&
			credAppStatusObject.status !== 'Signed';

		const downloadFlag =
			credAppSignedDocument !== undefined &&
			credAppSignedDocument !== null &&
			(credAppSignedDocument.adobeSignDocumentStatus === 'SIGNED' ||
				(!!credAppSignedDocument.name && credAppSignedDocument.name.includes('Amended'))) &&
			(credAppStatusObject.status === 'Signed' || credAppStatusObject.status === 'Complete');

		const downloadDocURL =
			credAppSignedDocument !== undefined &&
			credAppSignedDocument !== null &&
			(credAppSignedDocument.adobeSignDocumentStatus === 'SIGNED' ||
				(!!credAppSignedDocument.name && credAppSignedDocument.name.includes('Amended')))
				? credAppSignedDocument.downloadUrl
				: '';

		const showViewandSign = displayViewandSign();

		console.log(
			' ##### credAppSignedDocument 1 ' + JSON.stringify(credAppSignedDocument) + downloadFlag
		);
		if (true) {
			return (
				<Grid container className={classes.viewAndSignGrid}>
					<Grid classes={{ root: classes.docGridRootFileName }} item xs={2} md={3}>
						{!downloadFlag && (
							<span className={classes.documentLabel}>No Applicaton Signed</span>
						)}
						{downloadFlag && (
							<span className={classes.documentLink} onClick={download}>
								{credAppSignedDocument.name}
							</span>
						)}
					</Grid>
					<Grid classes={{ root: classes.dateSignedStyle }} item xs={2} md={2}>
						{downloadFlag && (
							<span className={classes.documentLabel}>
								{!!credAppSignedDocument.name &&
								credAppSignedDocument.name.includes('Amended')
									? ''
									: moment
											.utc(credAppSignedDocument.adobeSignDocumentSignedDate)
											.format('MMM DD, YYYY')}
							</span>
						)}
					</Grid>
					<Grid classes={{ root: classes.gridSignedStatus }} item xs={2} md={2}>
						{credAppStatusObject.colorCode === 'Yellow' ? (
							<div align='center' className='credStatus'>
								<span className={classes.statusIncomplete}>
									{credAppStatusObject.status}
								</span>
							</div>
						) : credAppStatusObject.colorCode === 'Red' ? (
							<div align='center' className='credStatus'>
								<span className={classes.statusNotSigned}>
									{credAppStatusObject.status}
								</span>
							</div>
						) : credAppStatusObject.colorCode === 'Green' ? (
							<div align='center' className='credStatus'>
								<span className={classes.statusComplete}>
									{credAppStatusObject.status}
								</span>
							</div>
						) : (
							<div align='center' className='credStatus'>
								<span>{credAppStatusObject.status}</span>
							</div>
						)}
					</Grid>
					<Grid classes={{ root: classes.docGridRootAction }} item xs={2} md={2}>
						<ViewAndSignActionMenu
							statusFlag={statusFlag}
							viewAndSignButtonHandler={viewAndSignButtonHandler}
							viewAndSignBtnClick={viewAndSignBtnClicked}
							credentialingSigningUrl={credentialingSigningUrl}
							downloadFlag={downloadFlag}
							downloadDocURL={downloadDocURL}
							disableSignandView={disableSignandView}
							showViewandSign={showViewandSign}
						/>
					</Grid>
				</Grid>
			);
		} else {
			return (
				<Grid classes={{ root: classes.gridRoot }} item xs={12} md={12}>
					<span className={classes.noApplicationMessage}>
						{t(`credentials:STATUSANDSIGN.NO_APPLICATION`)}
					</span>
				</Grid>
			);
		}
	};

	const viewAndSignForm = () => {
		return (
			<>
				<CssBaseline />

				<Grid container className={classes.titleGrid}>
					<Grid classes={{ root: classes.gridRoot }} item xs={2} md={3}>
						<span className={classes.title}>
							{t(`credentials:STATUSANDSIGN.SECTION`)}
						</span>
					</Grid>
					<Grid classes={{ root: classes.gridRoot }} item xs={2} md={2}>
						<span className={classes.title}>
							{t(`credentials:STATUSANDSIGN.DATE_SIGNED`)}
						</span>
					</Grid>
					<Grid classes={{ root: classes.gridRoot }} item xs={2} md={2}>
						<span className={classes.title}>
							{t(`credentials:STATUSANDSIGN.STATUS`)}
							<ToolTipComponent
								disableFocusListener
								enterTouchDelay={150}
								interactive
								placement='right'
								title={<>{<span>{credAppStatusObject.message}</span>}</>}
							>
								<HelpIcon
									style={{
										margin: '0px',
										fontSize: '15px',
										cursor: 'pointer',
										position: 'relative',
										left: '10px',
										top: '3px',
									}}
									color={'action'}
								/>
							</ToolTipComponent>
						</span>
					</Grid>
					<Grid classes={{ root: classes.gridRoot }} item xs={2} md={2}>
						<span className={classes.title}>
							{t(`credentials:STATUSANDSIGN.ACTIONS`)}
						</span>
					</Grid>
				</Grid>
				<Grid className={classes.titleGrid} xs={12}>
					<Grid classes={{ root: classes.gridRoot }} item xs={12}>
						{viewAndSignComponent()}
					</Grid>
				</Grid>

				{adobeSignDocumentModal()}
			</>
		);
	};
	const renderForms = () => {
		if (!isLoaded && !flags.isError) {
			return (
				<div className={classes.progressWrapper}>
					<Hidden only={['xs', 'sm']}>
						<Lottie
							animationData={credentialsLottie}
							width={500}
							loop={true}
							autoplay={true}
							rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
						/>
					</Hidden>
					<Hidden only={['md', 'lg', 'xl']}>
						<Lottie
							animationData={credentialsLottie}
							width={300}
							loop={true}
							autoplay={true}
							rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
						/>
					</Hidden>
				</div>
			);
		} else {
			return renderForm();
		}
	};

	const displayViewandSign = () => {
		console.log('Checkstatusandsign form ' + credAppStatusObject.status);
		if (credAppStatusObject && credAppStatusObject.status === CRED_APP_STATUS.NotSigned) {
			return true;
		}
		return false;
	};

	const applicationSectionStatus = checkCredApplicationSections();
	const enableSigning = credState !== CRED_STATE.CLOSED && applicationSectionStatus;
	const alreadySigned = credAppStatusObject.status === 'Signed';
	const showViewandSign = displayViewandSign();

	console.log('Checkstatusandsign form 3' + showViewandSign);

	return (
		<>
			<Grid container>
				<Grid item xs={12} md={12}>
					<h3 className={classes.header}>{t(`credentials:STATUSANDSIGN.TITLE`)}</h3>
				</Grid>

				<Grid item xs={12} md={11}>
					<span className={`${classes.gapMessage}`}>
						{' '}
						{t(`credentials:STATUSANDSIGN.INFO`)}{' '}
						<i>{t(`credentials:STATUSANDSIGN.INFO1`)}</i>{' '}
						{t(`credentials:STATUSANDSIGN.INFO2`)}
					</span>
				</Grid>

				{isLoaded && (
					<div>
						{enableSigning &&
							!alreadySigned &&
							viewAndSignBtnClicked &&
							showViewandSign && (
								<>
									<Grid item xs={12} md={11}>
										<span className={`${classes.successMessageLine1}`}>
											{' '}
											Congrats!
										</span>
									</Grid>
									<Grid item xs={12} md={11}>
										<span className={`${classes.successMessage}`}>
											{' '}
											{t(`credentials:STATUSANDSIGN.COMPLETE_STATUS_MESSAGE`)}
										</span>
									</Grid>
									<Grid item xs={12} md={11}>
										<span className={`${classes.successMessageLine1}`}>
											<Button
												variant='contained'
												className={`${classes.viewAndSignButtonStyle}`}
												onClick={viewAndSignButtonHandler}
												disabled={disableSignandView}
											>
												{t(`credentials:STATUSANDSIGN.VIEWANDSIGN`)}
											</Button>
										</span>
										{disableSignandView ? (
											<span
												className={`${classes.disableViewandSignMessage}`}
											>
												{t(`credentials:STATUSANDSIGN.WAIT_MESSAGE`)}
											</span>
										) : null}
									</Grid>
								</>
							)}
						{!enableSigning && !applicationSectionStatus && (
							<Grid item xs={12} md={11}>
								<span className={`${classes.errorMessage}`}>
									{' '}
									{t(`credentials:STATUSANDSIGN.INCOMPLETE_STATUS_MESSAGE`)}
								</span>
							</Grid>
						)}
					</div>
				)}

				<Grid item xs={12} md={10}>
					{renderForms()}
				</Grid>

				{isLoaded && (
					<Grid item xs={12} md={11}>
						<h3 className={classes.header}>
							{t(`credentials:STATUSANDSIGN.VIEWANDSIGN`)}
						</h3>
					</Grid>
				)}

				{isLoaded &&
					enableSigning &&
					!alreadySigned &&
					viewAndSignBtnClicked &&
					showViewandSign && (
						<Grid item xs={12} md={11}>
							{checkCredApplicationSections() ? (
								<>
									<span className={`${classes.successMessage}`}>
										{' '}
										{t(`credentials:STATUSANDSIGN.COMPLETE_STATUS_MESSAGE`)}
									</span>
									<div className={`${classes.clearFloat}`}>
										<span className={`${classes.successMessageLine1}`}>
											<Button
												variant='contained'
												className={`${classes.viewAndSignButtonStyle}`}
												disabled={disableSignandView}
												onClick={viewAndSignButtonHandler}
											>
												{t(`credentials:STATUSANDSIGN.VIEWANDSIGN`)}
											</Button>
										</span>
										{disableSignandView ? (
											<span
												className={`${classes.disableViewandSignMessage}`}
											>
												{t(`credentials:STATUSANDSIGN.WAIT_MESSAGE`)}
											</span>
										) : null}
									</div>
								</>
							) : (
								<span className={`${classes.errorMessage}`}>
									{' '}
									{t(`credentials:STATUSANDSIGN.INCOMPLETE_STATUS_MESSAGE`)}
								</span>
							)}
						</Grid>
					)}
				<Grid item xs={12} md={10}>
					{isLoaded && viewAndSignForm()}
				</Grid>
			</Grid>
		</>
	);
};
export default WithContexts(withTranslation()(withStyles(styles)(CheckStatusAndSignForm)));
