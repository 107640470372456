import { Box } from '@material-ui/core';
import React from 'react';

const ExpenseTabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box p={3} boxShadow={1} borderRadius={4} bgcolor={'white'}>
				{children}
			</Box>
		</div>
	);
};

export default ExpenseTabPanel;
