import React, { Component } from 'react';
import { withStyles, Grid, Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import WithContexts from '../../contexts/withContexts';
import { COLORS } from '../../utils/Application_Constants';
import msSalService from '../../services/msSalService';
const styles = (theme) => ({
	root: {
		justifyContent: 'center',
		textAlign: 'center',
		padding: '50px 0',
		color: COLORS.RAVEN,
	},
	header: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '700',
		fontSize: '45px',
		margin: '30px 0 0',
		[theme.breakpoints.down('sm')]: {
			margin: '0px',
		},
	},
	innerMessage: {
		fontWeight: '700',
		fontSize: '16x',
	},
	graphicContainer: {
		margin: '20px',
	},
	graphic: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '75%',
		},
	},
	notFoundGraphic: {
		width: '70%',
		[theme.breakpoints.down('sm')]: {
			width: '95%',
		},
	},
	homeButton: {
		fontWeight: '700',
		marginTop: '10px',
	},
	helpText: {
		fontWeight: '500',
		fontSize: '16px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
});

class InvalidUserPage extends Component {
	logOut() {
		msSalService.logOut();
	}

	renderNextSteps() {
		const { classes, t } = this.props;
		return (
			<>
				<Grid item xs={10}>
					<Button
						className={classes.homeButton}
						variant='contained'
						color='primary'
						href='#/'
						onClick={this.logOut}
					>
						{t('error:BUTTON.LOGIN')}
					</Button>
				</Grid>
			</>
		);
	}

	render() {
		const { classes } = this.props;

		return (
			<Grid container className={classes.root}>
				<Grid item xs={6}>
					<h3 className={classes.innerMessage}>
						For security purposes, you have been logged out. You have not been set up
						with the appropriate permissions.
					</h3>
					<h3 className={classes.innerMessage}>
						Please contact your Recruiter for further assistance.
					</h3>
				</Grid>

				{this.renderNextSteps()}
			</Grid>
		);
	}
}

export default WithContexts(withTranslation()(withStyles(styles)(InvalidUserPage)));
