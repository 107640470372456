import { axiosLtonlineInstance } from '../configuration/axios/axiosConfiguration';

/**
 * Verified the Token and returns the Clinician ID
 * @param aToken must match the enumeration under Model/Documents/DocumentType.cs
 */
async function verifyToken(aToken) {
	let response;
	try {
		response = await axiosLtonlineInstance.post(`authn/verify`, aToken);
	} catch (error) {
		return Error(error);
	}
	return response.data;
}

export { verifyToken };
