import React from 'react';
import WithContexts from '../../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import WorkHistoriesForm from '../WorkHistoriesComponent/WorkHistoriesForm';

function HospitalAffiliationForm({
	t,
	setSaveHandler,
	blockedBackNavigation,
	setCredAppError,
	activePath,
	credState,
	UserContext,
	flags,
	updateFlags,
	type = 'hospitalAffiliations',
	constantType = 'HOSPITAL_AFFILIATIONS',
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	return (
		<WorkHistoriesForm
			t={t}
			setSaveHandler={setSaveHandler}
			blockedBackNavigation={blockedBackNavigation}
			activePath={activePath}
			credState={credState}
			UserContext={UserContext}
			flags={flags}
			updateFlags={updateFlags}
			type={type}
			constantType={constantType}
			sectionCompletion={sectionCompletion}
			updateSectionCompletion={updateSectionCompletion}
			updateHighlightFieldsState={updateHighlightFieldsState}
			shouldHighlightFields={shouldHighlightFields}
			setCredAppError={setCredAppError}
		/>
	);
}

export default WithContexts(withTranslation()(HospitalAffiliationForm));
