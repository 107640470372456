import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {
	CircularProgress,
	InputAdornment,
	Radio,
	RadioGroup,
	FormControlLabel,
} from '@material-ui/core';
import { COLORS, CRED_STATE } from '../../../../../utils/Application_Constants';
import DatePickerComponent from '../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	getMalpracticeCarriers,
	postMalpracticeCarriers,
	deleteMalpracticeCarriers,
} from '../../../../../services/Credentialing';
import Input from '@material-ui/core/Input';
import DateFnsUtils from '@date-io/date-fns';
import { convertToDateIgnoringTime, matchesTemplate } from '../../../../../utils/helpers';
import {
	malpracticeCarriersStatusPointsCheck,
	malpracticeCarriersStatusCheck,
} from '../../../../../utils/CredentialingAppUtility';
import Alert from '@material-ui/lab/Alert';
import RequiredQuestionDialog from '../../../../GlobalComponents/Dialog/RequiredQuestionDialog';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '10px',
		paddingBottom: '10px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
	},
	secondaryHeaderArea: {
		alignItems: 'center',
		maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '46px',
		marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	form: {
		width: '100%',
		margin: theme.spacing(1, 0, 5),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	// inputLabel: {
	//     top: '-5px',
	//     position: 'relative',
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	//     marginTop: theme.spacing(-1),
	// },
	// selectInputLabel: {
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	// },
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: `${COLORS.RAVEN} `,
			borderWidth: 1,
		},
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		paddingRight: '0px',
		paddingTop: '5px',
		paddingBottom: '5px',
		paddingLeft: '10px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
	},
	cssFocused: {},

	disabled: {},
	disabledInput: {
		opacity: 0.6,
	},
	focused: {},
	marginLeft12: {
		marginLeft: 12,
	},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	noTopPad: {
		paddingTop: '0 !important',
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	radioGroup: {
		marginLeft: '8px',
	},
	// inputRadio: {
	//     color: COLORS.RAVEN,
	//     '&.Mui-focused': {
	//         color: COLORS.RAVEN
	//     },

	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',

	// },
	setWidthSelectGroup: {
		top: '-23px',
		width: 'calc(100% - 1px)',
		marginTop: '10px',
		marginRight: '10px',
	},
	setWidthSelects: {
		minWidth: '100%',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},
	hideDeleteBtn: {
		display: 'none',
	},
	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	selectContainerOffset: {
		padding: '0 16px 16px 16px !important',
	},
	selectContainer: {
		padding: '3px !important',
	},
	selectContainerGroup: {
		[theme.breakpoints.down('sm')]: {
			padding: '3px 10px 0px 10px !important',
		},
		minWidth: '97%',
	},
	multiGridOffset: {
		paddingTop: '13px !important',
	},
	leftPad30: {
		paddingLeft: '30px !important',
	},
	containerSM: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% + 13px) !important',
		},
	},
	marginBottom20Neg: {
		marginBottom: -20,
	},
	infoMessage: {
		color: COLORS.RAVEN,

		textAlign: 'left',
		fontSize: '14px',
		fontWeight: '500',
		paddingLeft: '10px !important',
		paddingBottom: '15px',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '5px',
			paddingBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 5px',
		},
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
}));

function MalpracticeCarrierForm({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	updateFlags,
	setCredAppError,
	activePath,
	credState,
	type,
	constantType,
	apiType,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [isLoaded, setIsLoaded] = useState(false);

	const [deleteRecordIndex, setDeleteRecordIndex] = useState();
	const [deleteRecordId, setDeleteRecordId] = useState();
	const [deleteRecords, setDeleteRecords] = useState([]);
	const [itemArray, setItemArray] = useState([]);

	const [currentMalpracticeInsurance, setCurrentMalpracticeInsurance] = useState(null);
	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);

	const itemTemplate = {
		id: null,
		name: '',
		carrierId: null,
		policyNumber: '',
		dateFrom: null,
		dateTo: null,
		coverageLimit: '',
	};

	const { register, control, handleSubmit, errors, reset, getValues, formState, watch } = useForm(
		{
			mode: 'all',
			reValidateMode: 'onChange',
			defaultValues: {
				id: null,
				name: '',
				carrierId: null,
				policyNumber: '',
				dateFrom: null,
				dateTo: null,
				coverageLimit: '',
			},
		}
	);
	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			setSaveHandler(
				MalpracticeCarrierForm,
				'Malpractice Carrier',
				handleSubmit(async (data) => await onSubmit(data))
			);

			await getMalpracticeCarriers(externalId, apiType)
				.then(function (data) {
					const { malpracticeCarriers = [], currentMalpracticeInsurance } = data;

					setCurrentMalpracticeInsurance(currentMalpracticeInsurance);
					console.log('response is', data);
					if (malpracticeCarriers.length === 0) {
						append({
							id: null,
							name: '',
							carrierId: null,
							policyNumber: '',
							dateFrom: null,
							dateTo: null,
							coverageLimit: '',
						});
						data['malpracticeCarriers'].push({
							id: null,
							name: '',
							carrierId: null,
							policyNumber: '',
							dateFrom: null,
							dateTo: null,
							coverageLimit: '',
						});
					} else {
						data['malpracticeCarriers'].map((item) => {
							if (item.name === null) {
								item.name = '';
							}
							if (item.policyNumber === null) {
								item.policyNumber = '';
							}
							if (item.coverageLimit === null) {
								item.coverageLimit = '';
							}
							itemArray.push(item.id);
							setItemArray(itemArray);
							return item;
						});
					}
					flags.dataSaved = false;
					updateFlags(flags);
					reset(data);
					setIsLoaded(true);

					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status = {
							status: 'incomplete',
							missingFields: [],
						};
						status = malpracticeCarriersStatusCheck(data);
						if (!!status.missingFields) {
							setHighlights(status.missingFields);
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);

	function updateHighlight() {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};

			let data = watch();
			status = malpracticeCarriersStatusCheck(data);

			//alert(`missingFields: ${status.missingFields}`)
			setHighlights(status.missingFields);
		}
	}

	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'malpracticeCarriers',
	});

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('malpracticeCarriers')) {
			for (let i = 0; i < errors.malpracticeCarriers.length; i++) {
				if (!!errors?.malpracticeCarriers[i]) {
					missingRequiredToSaveFields = Object.values(
						errors.malpracticeCarriers[i]
					).filter((anError) => {
						return anError.hasOwnProperty('type');
					});
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	const classes = styles();

	async function onSubmit(data) {
		let success = false;

		const { externalId } = UserContext;
		data['clinicianId'] = externalId;

		if (watch('currentMalpracticeInsurance') === 'Yes') {
			data['malpracticeCarriers'] = data['malpracticeCarriers']
				.map((item) => {
					if (item.id === 'null') {
						item.id = null;
					}

					if (matchesTemplate(item, itemTemplate)) {
						return null;
					}

					item.dateFrom = moment(item.dateFrom).toISOString(true);
					item.dateTo = moment(item.dateTo).toISOString(true);

					return item;
				})
				.filter((d) => d !== null);
		} else {
			await deleteMalpracticeCarriers(externalId, itemArray, apiType).catch((e) => {
				setCredAppError(e);
			});
			data['malpracticeCarriers'] = [];
		}

		if (deleteRecords.length > 0) {
			const deleteRecordsNumbers = deleteRecords.filter(function (element) {
				return element !== 'null';
			});
			await deleteMalpracticeCarriers(externalId, deleteRecordsNumbers, apiType).catch(
				(e) => {
					setCredAppError(e);
				}
			);
		}

		console.log('before json', data);
		const jsonData = JSON.stringify(data);
		console.log('post payload is:', jsonData);
		await postMalpracticeCarriers(externalId, jsonData, apiType);
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;
		sectionCompletion.malpracticeInsurance.malpracticeCarriers.current =
			await malpracticeCarriersStatusPointsCheck(data, sectionCompletion);
		updateSectionCompletion(sectionCompletion);

		updateFlags(flags);
		return success;
	}

	const deleteEntry = (btnPressed, index) => {
		if (btnPressed === 'Yes') {
			if (deleteRecordId !== null) {
				deleteRecords.push(deleteRecordId);
				setDeleteRecords(deleteRecords);
				setShowDeleteModal(false);
			}
			remove(deleteRecordIndex);
			setShowDeleteModal(false);
		}
		setShowDeleteModal(false);
	};

	const openDeleteConfirmation = (item, index) => {
		setDeleteRecordIndex(index);
		setDeleteRecordId(item.id);
		setShowDeleteModal(true);
	};

	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	const renderForm = (item, index) => {
		const lockFields = !!item.id && credState === CRED_STATE.CLOSED;

		const areAnyFieldsFilled = () => {
			let anyFilled = false;
			if (
				getValues(`malpracticeCarriers[${index}].name`) !== '' ||
				getValues(`malpracticeCarriers[${index}].policyNumber`) !== '' ||
				getValues(`malpracticeCarriers[${index}].dateFrom`) !== null ||
				getValues(`malpracticeCarriers[${index}].dateTo`) !== null ||
				getValues(`malpracticeCarriers[${index}].coverageLimit`) !== ''
			) {
				anyFilled = true;
			}
			return anyFilled;
		};

		return (
			<div className={classes.profileWrapper}>
				<Grid container>
					{index === 0 ? (
						<Grid item xs={12} className={classes.gridRoot}>
							<FormControl
								classes={{ root: classes.setWidthTo100OnMobile }}
								className={classes.radioGroup}
							>
								<InputLabel
									shrink
									htmlFor={`currentMalpracticeInsurance`}
									classes={{ root: classes.inputRadio }}
									className={
										(errors?.currentMalpracticeInsurance ||
											(!!highlights &&
												highlights.includes(
													`currentMalpracticeInsurance`
												))) &&
										classes.errorColor
									}
									required
								>
									{t(`credentials:${constantType}.INSURANCE_COVERAGE_QUESTION`)}
								</InputLabel>
								<br />
								<div>
									<RadioGroup
										row
										aria-label='completed'
										name={`currentMalpracticeInsurance`}
										defaultValue={currentMalpracticeInsurance}
										classes={{ root: classes.radioGroupLabel }}
										onBlur={() => {
											updateHighlight();
										}}
										onChange={() => {
											setTimeout(() => {
												updateHighlight();
											}, 50);
										}}
									>
										<FormControlLabel
											value='Yes'
											control={
												<Radio
													color='primary'
													style={{ color: COLORS.RAVEN }}
													inputRef={register({
														//required: true
													})}
													disableRipple
													size='small'
													disabled={lockFields}
													className={
														lockFields
															? classes.disabledInput
															: classes.normalInput
													}
												/>
											}
											label={
												<span
													style={{
														fontSize: '16px',
														color: COLORS.RAVEN,
													}}
												>
													Yes
												</span>
											}
										/>
										<FormControlLabel
											value='No'
											control={
												<Radio
													color='primary'
													style={{ color: COLORS.RAVEN }}
													inputRef={register({
														//required: true
													})}
													disableRipple
													size='small'
													disabled={lockFields}
													className={
														lockFields
															? classes.disabledInput
															: classes.normalInput
													}
												/>
											}
											label={
												<span
													style={{
														fontSize: '16px',
														color: COLORS.RAVEN,
													}}
												>
													No
												</span>
											}
										/>
									</RadioGroup>
									{(errors?.currentMalpracticeInsurance ||
										(!!highlights &&
											highlights.includes(
												`currentMalpracticeInsurance`
											))) && (
										<span className={classes.cssLabel}>
											{t(
												'credentials:VALIDATION_MESSAGE.ATTESTATION_QUESTION'
											)}
										</span>
									)}
									<br />
								</div>
							</FormControl>
						</Grid>
					) : null}

					{index === 0 ? (
						''
					) : watch('currentMalpracticeInsurance') === 'Yes' ? (
						<Grid container className={'credAppAdditionalHeader'}>
							<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
								{t(`credentials:${constantType}.ADDITIONAL_CARRIER`)}
							</Grid>
							<Grid
								item
								xs={3}
								className={
									credState === CRED_STATE.CLOSED
										? classes.hideDeleteBtn
										: 'credAppAdditionalDelete'
								}
								onClick={() => {
									openDeleteConfirmation(item, index);
								}}
							>
								{t(`credentials:${constantType}.DELETE_BTN`)}
							</Grid>
						</Grid>
					) : null}
				</Grid>
				{watch('currentMalpracticeInsurance') === 'Yes' ? (
					<Container component='main' maxWidth='md'>
						<CssBaseline />
						<div>
							<Grid
								container
								item
								md={12}
								spacing={4}
								className={classes.containerSM}
							>
								<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
									<RouteDeparturePrompt
										blockedBackNavigation={blockedBackNavigation}
										when={
											!flags.dataSaved
												? !!Object.keys(formState.dirtyFields).length
												: false
										}
									/>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											{index === 0 ? (
												<h3 className={classes.infoMessage}>
													{t(`credentials:${constantType}.INFO_MESSAGE`)}
												</h3>
											) : null}
										</Grid>
										<Grid item xs={12}>
											<InputLabel
												required
												shrink
												htmlFor={`malpracticeCarriers[${index}].name`}
												classes={{ root: classes.inputLabel }}
												className={
													((errors?.malpracticeCarriers &&
														errors?.malpracticeCarriers[`${index}`]
															?.name) ||
														(!!highlights &&
															highlights.includes(
																`malpracticeCarriers[${index}].name`
															))) &&
													classes.errorColor
												}
											>
												{t(`credentials:${constantType}.CARRIER`)}
											</InputLabel>
											<TextField
												autoComplete='off'
												variant='outlined'
												name={`malpracticeCarriers[${index}].name`}
												error={
													(!!errors?.malpracticeCarriers &&
														errors?.malpracticeCarriers[`${index}`]
															?.name) ||
													(!!highlights &&
														highlights.includes(
															`malpracticeCarriers[${index}].name`
														))
												}
												fullWidth
												onBlur={() => {
													updateHighlight();
												}}
												defaultValue={`${item.name}`}
												inputRef={register({
													validate: areAnyFieldsFilled(),
													maxLength: {
														value: 80,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
												})}
												className={
													lockFields
														? classes.disabledInput
														: classes.normalInput
												}
												InputProps={{
													readOnly: lockFields,
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
											/>
											<Input
												style={{ display: 'none' }}
												name={`malpracticeCarriers[${index}].id`}
												value={`${item.id}` || null}
												defaultValue={`${item.id}`}
												inputRef={register()}
											/>
											{((errors?.malpracticeCarriers &&
												errors?.malpracticeCarriers[`${index}`]?.name &&
												errors?.malpracticeCarriers[`${index}`]?.name[
													'type'
												] === 'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`malpracticeCarriers[${index}].name`
													))) && (
												<span className={classes.cssLabel}>
													{errors?.malpracticeCarriers &&
														errors?.malpracticeCarriers[`${index}`]
															?.name &&
														errors?.malpracticeCarriers[`${index}`]
															?.name.message}
												</span>
											)}
											{errors?.malpracticeCarriers &&
												errors?.malpracticeCarriers[`${index}`]?.name &&
												errors?.malpracticeCarriers[`${index}`]?.name[
													'type'
												] !== 'maxLength' && (
													<span className={classes.cssLabel}>
														{t(
															'credentials:VALIDATION_MESSAGE.CARRIER'
														)}
													</span>
												)}
										</Grid>

										<Grid item xs={12}>
											<InputLabel
												shrink
												htmlFor={`malpracticeCarriers[${index}].policyNumber`}
												className={classes.inputLabel}
												error={
													(!!errors?.malpracticeCarriers &&
														errors?.malpracticeCarriers[`${index}`]
															?.policyNumber) ||
													(!!highlights &&
														highlights.includes(
															`malpracticeCarriers[${index}].policyNumber`
														))
												}
											>
												{t(`credentials:${constantType}.POLICY_NUMBER`)}
											</InputLabel>
											<TextField
												autoComplete='off'
												variant='outlined'
												fullWidth
												defaultValue={`${item.policyNumber}`}
												name={`malpracticeCarriers[${index}].policyNumber`}
												inputRef={register({
													validate: areAnyFieldsFilled(),
													maxLength: {
														value: 80,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
												})}
												helperText={
													(errors?.malpracticeCarriers &&
														errors?.malpracticeCarriers[`${index}`]
															?.policyNumber &&
														errors?.malpracticeCarriers[`${index}`]
															?.policyNumber['type'] ===
															'maxLength') ||
													(!!highlights &&
														highlights.includes(
															`malpracticeCarriers[${index}].policyNumber`
														))
														? t('common:MESSAGES.CHARACTER_LIMIT')
														: null
												}
												className={
													lockFields
														? classes.disabledInput
														: classes.normalInput
												}
												error={
													(!!errors?.malpracticeCarriers &&
														errors?.malpracticeCarriers[`${index}`]
															?.policyNumber) ||
													(!!highlights &&
														highlights.includes(
															`malpracticeCarriers[${index}].policyNumber`
														))
												}
												InputProps={{
													readOnly: lockFields,
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
											/>
										</Grid>

										<Grid item xs={12} md={6} className={classes.noTopPad}>
											<FormControl
												classes={{ root: classes.setWidthTo100OnMobile }}
											>
												<InputLabel
													shrink
													htmlFor={`malpracticeCarriers[${index}].dateFrom`}
													classes={{ root: classes.selectInputLabel }}
													className={
														((errors?.malpracticeCarriers &&
															errors?.malpracticeCarriers[`${index}`]
																?.dateFrom) ||
															(!!highlights &&
																highlights.includes(
																	`malpracticeCarriers[${index}].dateFrom`
																))) &&
														classes.errorColor
													}
													required
												>
													{t(`credentials:${constantType}.DATEFROM`)}
												</InputLabel>
												<ThemeProvider theme={datePickerTheme}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<Controller
															control={control}
															rules={{
																validate: (date) =>
																	dateValidation(date),
															}}
															name={`malpracticeCarriers[${index}].dateFrom`}
															defaultValue={
																`${item.dateFrom}` === 'null'
																	? null
																	: convertToDateIgnoringTime(
																			`${item.dateFrom}`
																	  )
															}
															render={({
																onChange,
																onBlur,
																value,
																name,
															}) => (
																<DatePickerComponent
																	onChange={onChange}
																	name={name}
																	value={value}
																	format='MM/dd/yyyy'
																	placeholder='MM/DD/YYYY'
																	clearable
																	variant='inline'
																	autoOk='true'
																	disabled={lockFields}
																	minDateMessage={
																		<span
																			className={`${classes.cssLabel} ${classes.marginLeft12}`}
																		>
																			{t(
																				`credentials:${constantType}.DATE_VALIDATION`
																			)}
																		</span>
																	}
																	maxDateMessage={
																		<span
																			className={`${classes.cssLabel} ${classes.marginLeft12}`}
																		>
																			{t(
																				`credentials:${constantType}.DATE_VALIDATION`
																			)}
																		</span>
																	}
																	invalidDateMessage={``}
																	margin='normal'
																	theme={datePickerTheme}
																	error={
																		(errors?.malpracticeCarriers &&
																			errors
																				?.malpracticeCarriers[
																				`${index}`
																			]?.dateFrom) ||
																		(!!highlights &&
																			highlights.includes(
																				`malpracticeCarriers[${index}].dateFrom`
																			))
																	}
																	KeyboardButtonProps={{
																		'aria-label': t(
																			'credentials:OTHER_CERTIFICATIONS.EXPIRATION'
																		),
																	}}
																	InputProps={{
																		classes: {
																			disabled:
																				classes.disabledInput,
																			root: classes.cssOutlinedInput,
																			error: classes.error,
																		},
																	}}
																	onClose={() => {
																		updateHighlight();
																	}}
																	onBlur={() => {
																		updateHighlight();
																	}}
																	inputVariant='outlined'
																/>
															)}
														/>
													</MuiPickersUtilsProvider>
												</ThemeProvider>
											</FormControl>
											{((errors?.malpracticeCarriers &&
												errors?.malpracticeCarriers[`${index}`]
													?.dateFrom) ||
												(!!highlights &&
													highlights.includes(
														`malpracticeCarriers[${index}].dateFrom`
													))) && (
												<span
													className={`${classes.cssLabel} ${classes.marginLeft12}`}
												>
													{t(
														`credentials:${constantType}.DATE_VALIDATION`
													)}
												</span>
											)}
										</Grid>

										<Grid item xs={12} md={6} className={classes.noTopPad}>
											<FormControl
												classes={{ root: classes.setWidthTo100OnMobile }}
											>
												<InputLabel
													shrink
													htmlFor={`carriers[${index}].dateTo`}
													classes={{ root: classes.selectInputLabel }}
													className={
														((errors?.malpracticeCarriers &&
															errors?.malpracticeCarriers[`${index}`]
																?.dateTo) ||
															(!!highlights &&
																highlights.includes(
																	`malpracticeCarriers[${index}].dateTo`
																))) &&
														classes.errorColor
													}
													required
												>
													{t(`credentials:${constantType}.DATETO`)}
												</InputLabel>
												<ThemeProvider theme={datePickerTheme}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<Controller
															control={control}
															rules={{
																validate: (date) =>
																	dateValidation(date),
															}}
															name={`malpracticeCarriers[${index}].dateTo`}
															defaultValue={
																`${item.dateTo}` === 'null'
																	? null
																	: convertToDateIgnoringTime(
																			`${item.dateTo}`
																	  )
															}
															render={({
																onChange,
																onBlur,
																value,
																name,
															}) => (
																<DatePickerComponent
																	onChange={onChange}
																	name={name}
																	value={value}
																	format='MM/dd/yyyy'
																	placeholder='MM/DD/YYYY'
																	clearable
																	variant='inline'
																	autoOk='true'
																	disabled={lockFields}
																	minDateMessage={
																		<span
																			className={`${classes.cssLabel} ${classes.marginLeft12}`}
																		>
																			{t(
																				`credentials:${constantType}.DATE_VALIDATION`
																			)}
																		</span>
																	}
																	maxDateMessage={
																		<span
																			className={`${classes.cssLabel} ${classes.marginLeft12}`}
																		>
																			{t(
																				`credentials:${constantType}.DATE_VALIDATION`
																			)}
																		</span>
																	}
																	invalidDateMessage={``}
																	margin='normal'
																	theme={datePickerTheme}
																	error={
																		(errors?.malpracticeCarriers &&
																			errors
																				?.malpracticeCarriers[
																				`${index}`
																			]?.dateTo) ||
																		(!!highlights &&
																			highlights.includes(
																				`malpracticeCarriers[${index}].dateTo`
																			))
																	}
																	KeyboardButtonProps={{
																		'aria-label': t(
																			'credentials:OTHER_CERTIFICATIONS.EXPIRATION'
																		),
																	}}
																	InputProps={{
																		classes: {
																			disabled:
																				classes.disabledInput,
																			root: classes.cssOutlinedInput,
																			error: classes.error,
																		},
																	}}
																	onClose={() => {
																		updateHighlight();
																	}}
																	onBlur={() => {
																		updateHighlight();
																	}}
																	inputVariant='outlined'
																/>
															)}
														/>
													</MuiPickersUtilsProvider>
												</ThemeProvider>
											</FormControl>
											{((errors?.malpracticeCarriers &&
												errors?.malpracticeCarriers[`${index}`]?.dateTo) ||
												(!!highlights &&
													highlights.includes(
														`malpracticeCarriers[${index}].dateTo`
													))) && (
												<span
													className={`${classes.cssLabel} ${classes.marginLeft12}`}
												>
													{t(
														`credentials:${constantType}.DATE_VALIDATION`
													)}
												</span>
											)}
										</Grid>

										<Grid item xs={12}>
											<InputLabel
												shrink
												htmlFor={`malpracticeCarriers[${index}].coverageLimit`}
												className={classes.inputLabel}
												error={
													(!!errors?.malpracticeCarriers &&
														errors?.malpracticeCarriers[`${index}`]
															?.coverageLimit) ||
													(!!highlights &&
														highlights.includes(
															`malpracticeCarriers[${index}].coverageLimit`
														))
												}
												required
											>
												{t(`credentials:${constantType}.COVERAGE_AMOUNT`)}
											</InputLabel>
											<TextField
												autoComplete='off'
												variant='outlined'
												fullWidth
												defaultValue={`${item.coverageLimit}`}
												error={
													(!!errors?.malpracticeCarriers &&
														errors?.malpracticeCarriers[`${index}`]
															?.coverageLimit) ||
													(!!highlights &&
														highlights.includes(
															`malpracticeCarriers[${index}].coverageLimit`
														))
												}
												name={`malpracticeCarriers[${index}].coverageLimit`}
												inputRef={register({
													validate: areAnyFieldsFilled(),
													maxLength: {
														value: 80,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
												})}
												className={
													lockFields
														? classes.disabledInput
														: classes.normalInput
												}
												InputProps={{
													readOnly: lockFields,
													startAdornment: (
														<InputAdornment position='start'>
															$
														</InputAdornment>
													),
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
												onBlur={() => {
													updateHighlight();
												}}
											/>
										</Grid>
										{((errors?.malpracticeCarriers &&
											errors?.malpracticeCarriers[`${index}`]
												?.coverageLimit &&
											errors?.malpracticeCarriers[`${index}`]?.coverageLimit[
												'type'
											] === 'maxLength') ||
											(!!highlights &&
												highlights.includes(
													`malpracticeCarriers[${index}].coverageLimit`
												))) && (
											<span className={classes.cssLabel}>
												{errors?.malpracticeCarriers &&
													errors?.malpracticeCarriers[`${index}`]
														?.coverageLimit &&
													errors?.malpracticeCarriers[`${index}`]
														?.coverageLimit.message}
											</span>
										)}
									</Grid>
								</form>
							</Grid>
						</div>
					</Container>
				) : watch('currentMalpracticeInsurance') === 'No' && index === 0 ? (
					<Container component='main' maxWidth='md'>
						<CssBaseline />
						<div>
							<Grid
								container
								item
								md={12}
								spacing={4}
								className={classes.containerSM}
							>
								<i>
									<h3 className={classes.infoMessage}>
										{t(
											`credentials:${constantType}.INSURANCE_COVERAGE_MESSAGE`
										)}
									</h3>
								</i>
							</Grid>
						</div>
					</Container>
				) : null}
			</div>
		);
	};

	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}
		return fields.map((item, index) => (
			<div key={item.id}>
				{renderForm(item, index)}
				<RequiredQuestionDialog
					open={showDeleteModal}
					title={t(`credentials:${constantType}.CONFIRM_DELETE_TITLE`)}
					text={t(`credentials:${constantType}.CONFIRM_DELETE_MESSAGE`).replace(
						`(${type})`,
						`${type}`
					)}
					buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
					buttonIDs='Yes;No'
					disableBackgroundClick={false}
					onClose={(btnPressed) => {
						deleteEntry(btnPressed, index);
					}}
				/>
			</div>
		));
	};

	return (
		<div>
			<Grid className={classes.leftPad30}>
				<Grid item xs={12}>
					<h3 className={classes.header}>{t(`credentials:${constantType}.TITLE`)}</h3>
				</Grid>
				{credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN ? (
					<Grid item xs={12} md={10} className={classes.messageGrid}>
						<Alert severity='error' className={classes.alertMessage} icon={false}>
							{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
						</Alert>
					</Grid>
				) : null}
				<Grid item xs={12} className={classes.container}>
					{renderForms()}
				</Grid>
			</Grid>

			{isLoaded &&
			credState !== CRED_STATE.CLOSED &&
			watch('currentMalpracticeInsurance') === 'Yes' ? (
				<div
					className={'credAppAddMoreButton'}
					onClick={() =>
						append({
							id: null,
							name: '',
							carrierId: null,
							policyNumber: '',
							dateFrom: null,
							dateTo: null,
							coverageLimit: '',
						})
					}
				>
					{t(`credentials:${constantType}.ADD_MORE_CARRIERS`)}
				</div>
			) : null}
		</div>
	);
}

export default MalpracticeCarrierForm;
