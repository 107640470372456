const mdDegrees = [
	{
		value: 'DDS',
		label: 'DDS',
	},
	{
		value: 'DMD',
		label: 'DMD',
	},
	{
		value: 'DO',
		label: 'DO',
	},
	{
		value: 'DPM',
		label: 'DPM',
	},
	{
		value: 'MBBS',
		label: 'MBBS',
	},
	{
		value: 'MD',
		label: 'MD',
	},
	{
		value: 'PhD',
		label: 'PhD',
	},
	{
		value: 'PsyD',
		label: 'PsyD',
	},
	{
		value: 'Post Baccalaureate',
		label: 'Post Baccalaureate',
	},
	{
		value: 'Externship',
		label: 'Externship',
	},
];

const nursingPostGrad = [
	{
		value: 'Certificate',
		label: 'Certificate',
	},
	{
		value: 'DNAP',
		label: 'DNAP',
	},
	{
		value: 'DNP',
		label: 'DNP',
	},
	{
		value: 'MA',
		label: 'MA',
	},
	{
		value: 'MS',
		label: 'MS',
	},
	{
		value: 'MSN',
		label: 'MSN',
	},
];

const nursingUnderGrad = [
	{
		value: 'ASN',
		label: 'ASN',
	},
	{
		value: 'BA',
		label: 'BA',
	},
	{
		value: 'BS',
		label: 'BS',
	},
	{
		value: 'BSN',
		label: 'BSN',
	},
	{
		value: 'Certificate',
		label: 'Certificate',
	},
];

const paPostGrad = [
	{
		value: 'Certificate',
		label: 'Certificate',
	},
	{
		value: 'DNAP',
		label: 'DNAP',
	},
	{
		value: 'MA',
		label: 'MA',
	},
	{
		value: 'MS',
		label: 'MS',
	},
	{
		value: 'PhD',
		label: 'PhD',
	},
];

const paUnderGrad = [
	{
		value: 'AA',
		label: 'AA',
	},
	{
		value: 'AS',
		label: 'AS',
	},
	{
		value: 'BA',
		label: 'BA',
	},
	{
		value: 'BS',
		label: 'BS',
	},
	{
		value: 'Certificate',
		label: 'Certificate',
	},
];

const psychPostGrad = [
	{
		value: 'Certificate',
		label: 'Certificate',
	},
	{
		value: 'DNAP',
		label: 'DNAP',
	},
	{
		value: 'MA',
		label: 'MA',
	},
	{
		value: 'MS',
		label: 'MS',
	},
	{
		value: 'PhD',
		label: 'PhD',
	},
	{
		value: 'PsyD',
		label: 'PsyD',
	},
];

const psychUnderGrad = [
	{
		value: 'AA',
		label: 'AA',
	},
	{
		value: 'AS',
		label: 'AS',
	},
	{
		value: 'BA',
		label: 'BA',
	},
	{
		value: 'BS',
		label: 'BS',
	},
	{
		value: 'Certificate',
		label: 'Certificate',
	},
];

const sfaPostGrad = [
	{
		value: 'Certificate',
		label: 'Certificate',
	},
	{
		value: 'DNAP',
		label: 'DNAP',
	},
	{
		value: 'MA',
		label: 'MA',
	},
	{
		value: 'MS',
		label: 'MS',
	},
	{
		value: 'PhD',
		label: 'PhD',
	},
];

const sfaUnderGrad = [
	{
		value: 'AA',
		label: 'AA',
	},
	{
		value: 'AS',
		label: 'AS',
	},
	{
		value: 'BA',
		label: 'BA',
	},
	{
		value: 'BS',
		label: 'BS',
	},
	{
		value: 'Certificate',
		label: 'Certificate',
	},
	{
		value: 'Baccalaureate',
		label: 'Baccalaureate',
	},
];

const perfusionistPostGrad = [
	{
		value: 'Certificate',
		label: 'Certificate',
	},
	{
		value: 'DNAP',
		label: 'DNAP',
	},
	{
		value: 'MA',
		label: 'MA',
	},
	{
		value: 'MS',
		label: 'MS',
	},
	{
		value: 'PhD',
		label: 'PhD',
	},
];

const perfusionistUnderGrad = [
	{
		value: 'AA',
		label: 'AA',
	},
	{
		value: 'AS',
		label: 'AS',
	},
	{
		value: 'BA',
		label: 'BA',
	},
	{
		value: 'BS',
		label: 'BS',
	},
	{
		value: 'Certificate',
		label: 'Certificate',
	},
];
export {
	mdDegrees,
	nursingPostGrad,
	nursingUnderGrad,
	paPostGrad,
	paUnderGrad,
	psychPostGrad,
	psychUnderGrad,
	sfaPostGrad,
	sfaUnderGrad,
	perfusionistPostGrad,
	perfusionistUnderGrad,
};
