const BillTypeEnum = {
    Billable: 'Billable',
    NonBillTeam: 'NonBillTeam',
    NonBillProducer: 'NonBillProducer',
    ClinicianDeduction: 'ClinicianDeduction',
};

const ExpenseBillTypeEnum = {
    0: 'Billable', // Description: "Billable"
    1: 'Non-Bill Team', // Description: "Non-Bill Team"
    2: 'Non-Bill Producer', // Description: "Non-Bill Producer"
    3: 'Clinician Deduction',
};

export { BillTypeEnum, ExpenseBillTypeEnum };
