import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { menuNavigationProps } from '../../../types/centralizedProps';
import { ltdomain } from '../../../utils/helpers';

const styles = () => ({
	root: {
		width: '100%',
		padding: '32px',
	},
	footerColumnMobile: {
		marginBottom: '2.5rem',
	},
	footerList: {
		padding: '0px',
		listStyleType: 'none',
	},
});

class MobileFooter extends Component {
	render() {
		const { classes } = this.props;
		return (
			<footer className={classes.mobile}>
				<div className={classes.root}>
					<div className={classes.footerColumnMobile}>
						<h4 className={classes.fontBoldFooter}>CLINICIANS</h4>
						<ul className={classes.footerList}>
							<li>
								<a href='/dashboard'>Dashboard</a>
							</li>
							<li>
								<a href={ltdomain() + '/Resources/JobSearch/QuickSearch'}>
									Search jobs
								</a>
							</li>
							<li>
								<a href={ltdomain() + '/locum-agency'}>Find a recruiter</a>
							</li>
						</ul>
					</div>

					<div className={classes.footerColumnMobile}>
						<h4 className={classes.fontBoldFooter}>RESOURCES</h4>
						<ul className={classes.footerList}>
							<li>
								<a href={ltdomain() + '/resource-center/'}>Resource center</a>
							</li>
							<li>
								<a href={ltdomain() + '/physician-resources/'}>
									Physician resources
								</a>
							</li>
							<li>
								<a href={ltdomain() + '/telehealth/'}>Telehealth resources</a>
							</li>
							<li>
								<a href={ltdomain() + '/resource-center/career-center/'}>
									Career centers
								</a>
							</li>
						</ul>
					</div>

					<div className={classes.footerColumnMobile}>
						<h4 className={classes.fontBoldFooter}>COMPANY</h4>
						<ul className={classes.footerList}>
							<li>
								<a href={ltdomain() + '/about-us/who-we-are/about-locumtenenscom/'}>
									About us
								</a>
							</li>
							<li>
								<a href='https://aspire.locumtenens.com'>Careers</a>
							</li>
							<li>
								<a href={ltdomain() + '/locum-agency'}>Locum agencies</a>
							</li>
						</ul>
					</div>

					<div className={classes.footerColumnMobile}>
						<h4 className={classes.fontBoldFooter}>GET IN TOUCH</h4>
						<ul className={classes.footerList}>
							<li>
								<a href='mailto:LT_OnlineServices@locumtenens.com'>Email us</a>
							</li>
							<li>
								<a href='tel:8005628663'>Call us</a>
							</li>
							<li>
								<a href={ltdomain() + '/Resources/ContactUs/ContactUs'}>
									Contact us
								</a>
							</li>
						</ul>
					</div>
				</div>
			</footer>
		);
	}
}

MobileFooter.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
		navigationWrapper: PropTypes.string.isRequired,
	}).isRequired,
	menuNavigation: menuNavigationProps.isRequired,
};
export default withStyles(styles)(MobileFooter);
