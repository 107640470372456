import React, { Component } from 'react';
import { withStyles, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import ContactCard from '../ContactCard/ContactCard';
import FacilityContactsViewDialog from '../FacilityContactsDialogs/FacilityContactsViewDialog';
import FacilityContactsAddDialog from '../FacilityContactsDialogs/FacilityContactsAddDialog';

const styles = (theme) => ({
	facilityWrapper: {
		paddingTop: '18px',
	},
	widgetHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '18px',
		paddingLeft: '20px',
		textAlign: 'left',
	},
	contactsHeaderContainer: {
		paddingRight: '15px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	// paperRoot: {
	//   [theme.breakpoints.down('sm')]: {
	//     marginTop: '20px',
	//   },
	//   boxShadow: '0px 3px 6px #00000008',
	// },
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	emptyStateWrapper: {
		display: 'flex',
	},
	innerCardEmptyWrapper: {
		margin: '10px 20px',
		display: 'flex',
		padding: '10px',
		borderRadius: '5px',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-around',
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		[theme.breakpoints.down('xs')]: {
			margin: '10px 10px',
		},
	},
	emptyStateHeader: {
		textAlign: 'left',
		fontWeight: '700',
		letterSpacing: '0',
		fontSize: '20px',
		color: COLORS.RAVEN,
		opacity: '0.3',
	},
	disabledClickableSpan: {
		borderBottom: '1px dashed',
		fontWeight: '500',
		fontSize: '16px',
		cursor: 'pointer',
		marginBottom: '0',
	},
});

class FacilityContacts extends Component {
	renderTitleConditonally = () => {
		const { contacts, classes, t } = this.props;
		if (!contacts || contacts.length === 0) {
			return <h1 className={classes.widgetHeader}>{t('contacts:FACILITY_CONTACTS')}</h1>;
		}
		return (
			<h1 className={classes.widgetHeader}>
				{t('contacts:FACILITY_CONTACTS')} ({contacts.length})
			</h1>
		);
	};

	emptyCard = () => {
		const { classes, t } = this.props;
		return (
			<div className={classes.emptyStateWrapper}>
				<div className={classes.innerCardEmptyWrapper}>
					<span className={classes.emptyStateHeader}>{t('contacts:NO_CONTACTS')}</span>
				</div>
			</div>
		);
	};

	renderContactCards() {
		const { contacts, facilityId, updateAssignments, primaryWorkLocation } = this.props;
		if (!contacts || contacts.length === 0) {
			return this.emptyCard();
		}
		return contacts.map((item, index) => {
			if (index >= 3) {
				return null;
			}
			return (
				<ContactCard
					facilityId={facilityId}
					contact={item}
					primaryWorkLocation={primaryWorkLocation}
					updateAssignments={updateAssignments}
					editable
					key={`facilityContact-${index + 1}`}
					past
				/>
			);
		});
	}

	render() {
		const { classes, contacts, past, facilityId, updateAssignments, primaryWorkLocation } =
			this.props;
		return (
			<Paper classes={{ root: classes.paperRoot }}>
				<div className={classes.facilityWrapper}>
					<div className={classes.contactsHeaderContainer}>
						{this.renderTitleConditonally()}
						<FacilityContactsViewDialog
							contacts={contacts}
							facilityId={facilityId}
							primaryWorkLocation={primaryWorkLocation}
							updateAssignments={updateAssignments}
						/>
					</div>
					{this.renderContactCards()}
					{past ? null : (
						<FacilityContactsAddDialog
							facilityId={facilityId}
							updateAssignments={updateAssignments}
						/>
					)}
				</div>
			</Paper>
		);
	}
}

export default withTranslation()(withStyles(styles)(FacilityContacts));
