import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import AmountCurrencyInputAdornment from './AmountCurrencyInputAdornment';
import { valueAsExpense } from '../../Utils/ValueAsUtils';

const ExpenseAmount = (props) => {
	const { disabled, index, expense, isReimbursement , mode} = props;
	const { control, errors } = useFormContext();
	const [required, setRequired] = useState({ required: (mode==='cc'?false:true)});
	return (
		<Grid item xs={12} md={6}>
			<Controller
				control={control}
				name={`expenseEntries[${index}].amount`}
				defaultValue={expense.amount}
				rules={{
					required: required.required ? 'Expense amount is required' : false,
				}}
				render={({ onChange, value }) => (
					<ExpenseTextField
						{...required}
						placeholder='0.00'
						id={`expense-amount`}
						onChange={(e) => onChange(valueAsExpense(e.target.value))}
						value={value}
						label='Expense amount'
						disabled={!!expense.id && !isReimbursement }
						endAdornment={
							<AmountCurrencyInputAdornment
								disabled={disabled || (!!expense.id && !isReimbursement)}
								index={index}
								expense={expense}
							/>
						}
						error={Boolean(errors.expenseEntries?.[index]?.amount)}
						helperText={errors.expenseEntries?.[index]?.amount?.message}
					/>
				)}
			/>
		</Grid>
	);
};

export default ExpenseAmount;
