import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../../../utils/Application_Constants';

const styles = makeStyles((theme) => ({
	menuItem: {
		fontSize: '13px',
		fontWeight: '600',
		color: COLORS.TRUE_BLUE,
		[theme.breakpoints.down('sm')]: {
			minHeight: '10px',
		},
	},
}));

export default function ViewAndSignActionMenu({
	statusFlag,
	viewAndSignButtonHandler,
	viewAndSignBtnClick,
	credentialingSigningUrl,
	downloadFlag,
	downloadDocURL,
	disableSignandView,
	showViewandSign,
}) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [viewAndSignBtn, setViewAndSignBtn] = React.useState(false);
	const open = Boolean(anchorEl);
	const classes = styles();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const viewAndSign = () => {
		setViewAndSignBtn(true);
		setAnchorEl(null);
		viewAndSignButtonHandler();
	};

	const download = () => {
		setAnchorEl(null);
		if (downloadDocURL) {
			window.open(downloadDocURL);
		} else {
			alert('Document not available for download!');
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		//TO-DO Changes disable flag for view and sign to !statusFlag
		<div>
			<IconButton
				aria-label='more'
				aria-controls='long-menu'
				aria-haspopup='true'
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id='long-menu'
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				PaperProps={{
					style: {
						maxHeight: '130px',
						width: '130px',
						boxShadow: 'none',
						border: '1px solid #d3d4d5',
						borderRadius: '0px',
					},
				}}
			>
				<MenuItem
					key={1}
					className={classes.menuItem}
					onClick={viewAndSign}
					disabled={
						!statusFlag ||
						viewAndSignBtn ||
						!viewAndSignBtnClick ||
						disableSignandView ||
						!showViewandSign
					}
				>
					View and Sign
				</MenuItem>
				<MenuItem
					key={2}
					className={classes.menuItem}
					onClick={download}
					disabled={!downloadFlag}
				>
					Download
				</MenuItem>
			</Menu>
		</div>
	);
}
