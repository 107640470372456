// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: process.env.REACT_APP_APP_INSIGHTS,
		extensions: [reactPlugin],
		enableAutoRouteTracking: true,
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory },
		},
	},
});
appInsights.loadAppInsights();
appInsights.trackPageView();

export { reactPlugin, appInsights };
