import React from 'react';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import TrainingForm from './TrainingComponent/TrainingForm';
import { withStyles } from '@material-ui/core';
import { reactPlugin } from '../../../../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBoundaryPage from '../../../../ContentComponents/Credentials/PageComponenets/ErrorBoundaryPage';
const styles = (theme) => ({
	pagePadding: {
		paddingBottom: '50px',
	},
});

function AdditionalTraining({
	t,
	classes,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	setCredAppError,
	flags,
	updateFlags,
	credState,
	activePath,
	sectionCompletion,
	updateSectionCompletion,
	shouldHighlightFields,
	updateHighlightFieldsState,
}) {
	return (
		<AppInsightsErrorBoundary
			onError={() => (
				<ErrorBoundaryPage errorCode='500' flags={flags} updateFlags={updateFlags} />
			)}
			appInsights={reactPlugin}
		>
			<div className={classes.pagePadding}>
				<TrainingForm
					t={t}
					setSaveHandler={setSaveHandler}
					blockedBackNavigation={blockedBackNavigation}
					UserContext={UserContext}
					activePath={activePath}
					credState={credState}
					flags={flags}
					updateFlags={updateFlags}
					type={'additionaltrainings'}
					constantType={'ADDITIONAL_TRAINING'}
					sectionCompletion={sectionCompletion}
					updateSectionCompletion={updateSectionCompletion}
					updateHighlightFieldsState={updateHighlightFieldsState}
					shouldHighlightFields={shouldHighlightFields}
					setCredAppError={setCredAppError}
				/>
			</div>
		</AppInsightsErrorBoundary>
	);
}

export default WithContexts(withTranslation()(withStyles(styles)(AdditionalTraining)));
