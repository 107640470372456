import React, { Component } from 'react';
import {
	withStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Checkbox,
	Grid,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import GetCredImg from '../../../assets/images/ltoe-get-credentialed-icon.svg';
import ViewImg from '../../../assets/images/ltoe-view-assignments-icon.svg';
import UpdatePrefsImg from '../../../assets/images/ltoe-update-preferences-icon.svg';
import SearchImg from '../../../assets/images/ltoe-search-jobs-icon.svg';
import { ltdomain } from '../../../utils/helpers';

const styles = (theme) => ({
	contentRoot: {
		height: '100vh',
	},
	bold: {
		fontWeight: 'bold',
	},
	center: {
		textAlign: 'center',
	},
	dialogHeaderRoot: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE_LILAC,
		justifyContent: 'center',
		textAlign: 'center',
		paddingTop: '16px',
		paddingBottom: '16px',
	},
	dialogContentRoot: {
		padding: '15px',
		margin: '0',
		borderRadius: '5px',
		color: `${COLORS.RAVEN}`,
		backgroundColor: `${COLORS.BLACK_HAZE}`,
	},
	imageAndText: {
		textAlign: 'center',
		alignContent: 'middle',
		justifyContent: 'center',
	},
	buttonRoot: {
		border: `1px solid ${COLORS.TRUE_BLUE}`,
		marginLeft: '30px',
		marginRight: '30px',
		marginBottom: '30px',
		width: '100%',
		textTransform: 'none',
		fontSize: '16px',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginLeft: '10px',
			marginRight: '10px',
		},
	},

	timeOutHeader: {
		fontWeight: '500',
		opacity: '0.5',
	},
	closeIconButtonRoot: {
		color: `${COLORS.WHITE_LILAC}`,
		position: 'absolute',
		right: '0',
		top: '7px',
	},
	popUpMsg: {
		textAlign: 'center',
		//fontSize: '15px',
		//fontWeight: '600'
	},
	notificationImg: {
		width: '100%',
		heigh: 'auto',
		margin: '0 auto',
		[theme.breakpoints.down('xs')]: {
			fontSize: '18px',
		},
	},
	checkboxWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		textAlign: 'left',
		marginTop: '30px',
	},
});

class AlertNotificationForFirstTimeUsers extends Component {
	state = {
		alertChecked: false,
	};

	handleCheck = () => {
		this.setState({ alertChecked: !this.state.alertChecked });
	};

	updateHideDialogFlag = () => {
		//Post call to update sign in user flag
		const { alertChecked } = this.state;
		this.props.toggleHandler(false, alertChecked);
	};

	render() {
		const { classes, toggleHandler, openDialog } = this.props;
		const { alertChecked } = this.state;
		return (
			<div className={classes.contentRoot}>
				<Dialog
					open={openDialog}
					fullWidth
					scroll='body'
					onClose={() => toggleHandler(false, alertChecked)}
				>
					<DialogTitle classes={{ root: classes.dialogHeaderRoot }}>
						<div className={classes.notificationImg}>
							Welcome to the LT Online Experience
						</div>
					</DialogTitle>

					<DialogContent classes={{ root: classes.dialogContentRoot }}>
						<div className={classes.popUpMsg}>
							<p>
								We've designed this site to be a one-stop shop for the physicians
								and advanced practitioners who are working with a LocumTenens.com
								recruiter.
							</p>
							<div className={classes.center}>
								<p className={classes.bold}>First time here?</p>
								<p>Let's get you to where you want to be:</p>
							</div>

							<br />
							<Grid container direction='row' justify='space-around'>
								<Grid item className='col-3 imageAndText'>
									<a href='/#/credentials'>
										<img src={GetCredImg} width='48px' alt='Get credentialed' />
										<br />
										Get
										<br />
										Credentialed
									</a>
								</Grid>
								<Grid item className='col-3 imageAndText'>
									<a href='/#/assignments/active'>
										<img src={ViewImg} width='48px' alt='View assignments' />
										<br />
										View
										<br />
										Assignments
									</a>
								</Grid>
								<Grid item className='col-3 imageAndText'>
									<a href='/#/profile/preference'>
										<img
											src={UpdatePrefsImg}
											width='48px'
											alt='Update preferences'
										/>
										<br />
										Update
										<br />
										Preferences
									</a>
								</Grid>
								<Grid item className='col-3 imageAndText'>
									<a href={ltdomain() + '/Resources/JobSearch/QuickSearch'}>
										<img src={SearchImg} width='48px' alt='Search jobs' />
										<br />
										Search
										<br />
										Jobs
									</a>
								</Grid>
							</Grid>

							<div className={classes.checkboxWrapper}>
								<Checkbox
									color='primary'
									name='alertCheckbox'
									checked={this.state.alertChecked}
									onChange={this.handleCheck}
								/>
								<span>Do not show this again</span>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							color='primary'
							classes={{ root: classes.buttonRoot }}
							onClick={this.updateHideDialogFlag}
						>
							CLOSE
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(AlertNotificationForFirstTimeUsers));
