/**
 * @file This is the route configuration file for all the protected routes
 * in the application.
 */
import TimesheetConfirmation from '../../Shared/Components/ConfirmationDialog';
import {
    default as ClinicianTimeReview,
    default as TimesheetReview,
} from '../../Shared/Components/TimesheetReview';
import Expenses from '../../Shared/Pages/Expenses';
import SubmittedDialog from '../../Shared/Components/Expense/SubmittedDialog';
import PreCoded from '../../Shared/Pages/PreCoded';
import Timesheets from '../../Shared/Pages/Timesheets';
import Timesheet from '../../pages/TimeAndPay/Timesheet';
import TimesheetSave from '../../pages/TimeAndPay/TimesheetSave';
import TimesheetSubmit from '../../pages/TimeAndPay/TimesheetSubmit';
import CreditCards from '../Pages/CreditCards';
import ExpenseReview from '../../Shared/Pages/ExpenseReview';
import ClinicianReimbursement from '../../Shared/Pages/ClinicianReimbursement';
import PendingCCReview from '../../Shared/Pages/PendingCCReview';

const adminRoutes = [
    {
        path: '/login/callback',
    },

    {
        path: '/timesheets',
        component: Timesheets,
    },
    {
        path: '/timesheet/:id?/view',
        component: Timesheet,
        mode: 'view',
    },
    {
        path: '/timesheet/:id?/edit',
        component: Timesheet,
        mode: 'edit',
    },
    {
        path: '/timesheet/create',
        component: Timesheet,
        mode: 'create',
    },
    {
        path: '/timesheet/save',
        component: TimesheetSave,
    },
    {
        path: '/timesheet/:id?/submit',
        component: TimesheetSubmit,
    },
    {
        path: '/clinician/:clinicianId?/timesheet/:id?/review/:locationId?',
        component: TimesheetReview,
    },
    {
        path: '/clinician/:clinicianId?/timesheet/:id?/review',
        component: ClinicianTimeReview,
    },
    {
        path: '/expenses',
        component: Expenses,
    },
    {
        path: '/expenses/review',
        component: ExpenseReview,
    },
    {
        path: '/expenses/pending-cc/review',
        component: PendingCCReview,
    },
    {
        path: '/expenses/submit',
        component: SubmittedDialog,
    },
    {
        path: '/expenses/clinician-reimbursement/:id?',
        component: ClinicianReimbursement,
    },
    {
        path: '/expenses/cc/:id?',
        component: (props) => <PreCoded mode='cc' {...props} />,
    },
    {
        path: '/expenses/pending-cc/:id?',
        component: (props) => <PreCoded mode='pending-cc' {...props} />,
    },
    {
        path: '/timesheet/confirmation/:mode?',
        component: TimesheetConfirmation,
    },
    {
        path: '/credit-cards',
        component: CreditCards,
    },
];

export default adminRoutes;
