/**
 * @file this is the address component for the profile account page
 * @TODO add proptypes.
 */
import React, { Component } from 'react';
import { withStyles, Grid, InputLabel, FormControl, NativeSelect } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import TextFieldComponent from '../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { COLORS, LENGTH_CONSTANTS } from '../../../utils/Application_Constants';
import { states } from '../Account/accountOptions';

const styles = (theme) => ({
	containerRoot: {
		paddingBottom: '15px',
	},
	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '12px',
	},
	buttonFont: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		textTransform: 'capitalize',
		[theme.breakpoints.up('md')]: {
			marginTop: '15px',
		},
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
			textAlign: 'left',
		},
	},
	setWidthTo100OnMobile: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	homeAddressWith: {
		width: '100%',
	},
});

class ProfileAddressComponent extends Component {
	constructor(props) {
		super(props);
		this.states = states;
	}

	render() {
		const { address, textFieldChangeHandler, classes, addressType, t } = this.props;
		return (
			<>
				<Grid container classes={{ root: classes.containerRoot }} spacing={2}>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
						<FormControl classes={{ root: classes.homeAddressWith }}>
							<InputLabel
								shrink
								htmlFor={`AddressLine1-${addressType}`}
								classes={{ root: classes.inputLabel }}
								required
								error={
									!!address.addressLine1 &&
									address.addressLine1.length >=
										LENGTH_CONSTANTS.LENGTH_ADDRESS_LINE1
								}
							>
								{t('profile:ACCOUNT.HOME_ADDRESS_LINE_ONE')}
							</InputLabel>
							<TextFieldComponent
								id={`AddressLine1-${addressType}`}
								required
								classes={{ input: classes.homeAddressWith }}
								onChange={(event) => {
									textFieldChangeHandler(event, addressType);
								}}
								name='addressLine1'
								value={address.addressLine1 || ''}
							/>
						</FormControl>
					</Grid>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={6}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`AddressLine2-${addressType}`}
								classes={{ root: classes.inputLabel }}
								error={
									!!address.addressLine2 &&
									address.addressLine2.length >=
										LENGTH_CONSTANTS.LENGTH_ADDRESS_LINE2
								}
							>
								{t('profile:ACCOUNT.HOME_ADDRESS_LINE_TWO')}
							</InputLabel>
							<TextFieldComponent
								id={`AddressLine2-${addressType}`}
								required
								classes={{ input: classes.setWidthTo100OnMobile }}
								onChange={(event) => {
									textFieldChangeHandler(event, addressType);
								}}
								name='addressLine2'
								value={address.addressLine2 || ''}
							/>
						</FormControl>
					</Grid>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`City-${addressType}`}
								classes={{ root: classes.inputLabel }}
								error={
									!!address.city &&
									address.city.length >= LENGTH_CONSTANTS.LENGTH_CITY
								}
							>
								{t('profile:ACCOUNT.CITY')}
							</InputLabel>
							<TextFieldComponent
								id={`City-${addressType}`}
								required
								classes={{ input: classes.setWidthTo100OnMobile }}
								onChange={(event) => {
									textFieldChangeHandler(event, addressType);
								}}
								name='city'
								value={address.city || ''}
							/>
						</FormControl>
					</Grid>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={3}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`State-${addressType}`}
								classes={{ root: classes.inputLabel }}
							>
								{t('profile:ACCOUNT.STATE')}
							</InputLabel>
							<NativeSelect
								id={`State-${addressType}`}
								onChange={(event) => {
									textFieldChangeHandler(event, addressType);
								}}
								name='state'
								value={address.state}
								input={
									<TextFieldComponent
										classes={{ input: classes.setWidthTo100OnMobile }}
									/>
								}
							>
								{this.states.map((item) => (
									<option key={item.value} value={item.value}>
										{item.label}
									</option>
								))}
							</NativeSelect>
						</FormControl>
					</Grid>
					<Grid classes={{ root: classes.gridRoot }} item xs={12} md={2}>
						<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
							<InputLabel
								shrink
								htmlFor={`Zip-${addressType}`}
								classes={{ root: classes.inputLabel }}
								error={
									!!address.zipCode &&
									address.zipCode.length > LENGTH_CONSTANTS.LENGTH_ZIP_CODE
								}
							>
								{t('profile:ACCOUNT.ZIP')}
							</InputLabel>
							<TextFieldComponent
								id={`Zip-${addressType}`}
								classes={{ input: classes.setWidthTo100OnMobile }}
								required
								onChange={(event) => {
									textFieldChangeHandler(event, addressType);
								}}
								name='zipCode'
								value={address.zipCode || ''}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default withTranslation()(withStyles(styles)(ProfileAddressComponent));
