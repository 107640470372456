import React from 'react';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import MalpracticeClaimsHistoryForm from './MalpracticeClaimsHistoryForm';
import { reactPlugin } from '../../../../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBoundaryPage from '../../../../ContentComponents/Credentials/PageComponenets/ErrorBoundaryPage';
function MalpracticeClaimsHistory({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	setCredAppError,
	flags,
	updateFlags,
	activePath,
	credState,
	type = 'Malpractice Claims History',
	constantType = 'MALPRACTICE_CLAIMS_HISTORY',
	apiType = 'malpracticeclaimhistories',
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	return (
		<AppInsightsErrorBoundary
			onError={() => (
				<ErrorBoundaryPage errorCode='500' flags={flags} updateFlags={updateFlags} />
			)}
			appInsights={reactPlugin}
		>
			<MalpracticeClaimsHistoryForm
				t={t}
				setSaveHandler={setSaveHandler}
				blockedBackNavigation={blockedBackNavigation}
				UserContext={UserContext}
				flags={flags}
				updateFlags={updateFlags}
				type={type}
				constantType={constantType}
				apiType={apiType}
				activePath={activePath}
				credState={credState}
				sectionCompletion={sectionCompletion}
				updateSectionCompletion={updateSectionCompletion}
				updateHighlightFieldsState={updateHighlightFieldsState}
				shouldHighlightFields={shouldHighlightFields}
				setCredAppError={setCredAppError}
			/>
		</AppInsightsErrorBoundary>
	);
}

export default WithContexts(withTranslation()(MalpracticeClaimsHistory));
