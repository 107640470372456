import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {
	CircularProgress,
	FormControlLabel,
	NativeSelect,
	Radio,
	RadioGroup,
	ThemeProvider,
} from '@material-ui/core';
import {
	COLORS,
	COUNTRIES,
	specialties,
	specialtiesItrnResidency,
	specialtiesFellowship,
	programSalutation,
	CRED_STATE,
	ACTIVE_PATHNAME_AP,
	ACTIVE_PATHNAME_MD,
} from '../../../../../../utils/Application_Constants';
import TextFieldComponent from '../../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { statesForLicense } from '../../../../Account/accountOptions';
import createTheme from '@material-ui/core/styles/createTheme';
import DatePickerComponent from '../../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import RouteDeparturePrompt from '../../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	getTraining,
	postTraining,
	deleteTraining,
} from '../../../../../../services/Credentialing';
import Input from '@material-ui/core/Input';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import { convertToDateIgnoringTime, matchesTemplate } from '../../../../../../utils/helpers';
import _ from 'lodash';
import Alert from '@material-ui/lab/Alert';
import {
	internshipSectionPointsCheck,
	residencySectionPointsCheck,
	fellowshipSectionPointsCheck,
	additionalTrainingSectionPointsCheck,
	internshipSectionCheck,
	residencySectionCheck,
	fellowshipSectionCheck,
	additionalTrainingSectionCheck,
} from '../../../../../../utils/CredentialingAppUtility';
import RequiredQuestionDialog from '../../../../../GlobalComponents/Dialog/RequiredQuestionDialog';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.LT_MIDNIGHT,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '10px',
		paddingBottom: '5px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
	},
	secondaryHeaderArea: {
		alignItems: 'center',
		maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '46px',
		marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	credAppFirstHeader: {
		padding: '20px 0px',
		borderTop: '1px solid #e7e7e7',
		borderBottom: '1px solid #e7e7e7',
		margin: '30px 10px',
		marginTop: '-15px',
	},
	pageWrapper: {
		paddingBottom: '50px',
		marginTop: '30px',
		borderBottom: `1px solid ${COLORS.SEASHELL}`,
	},
	form: {
		width: '100%',
		margin: theme.spacing(1, 0, 5),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	inputLabel: {
		position: 'relative',
		textAlign: 'left',
		whiteSpace: 'nowrap',
	},
	otherInputLabel: {
		// top: '-5px',
		position: 'relative',
		textAlign: 'left',
		// marginLeft: '5px',
		// fontWeight: '650',
		// fontSize: '12px',
		whiteSpace: 'nowrap',
		// textTransform: 'uppercase',
		marginTop: theme.spacing(-1),
	},
	checkboxInputLabel: {
		// color: COLORS.RAVEN,
		marginLeft: '6px',
		// fontWeight: '900',
		// fontSize: '12px',
		whiteSpace: 'nowrap',
		// textTransform: 'uppercase'
	},
	checkboxInput: {
		padding: '24px',
		justifyContent: 'left',
	},
	selectInputLabel: {
		textAlign: 'left',
		// marginLeft: '5px',
		// color: COLORS.RAVEN,
		// fontWeight: '900',
		// fontSize: '12px',
		whiteSpace: 'nowrap',
		// textTransform: 'uppercase',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '12px',
			marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	// cssOutlinedInputOther: {
	//     '&$cssFocused $notchedOutline': {
	//         borderColor: `${COLORS.RAVEN}`,
	//         borderWidth: 1,
	//         borderRadius: 1,
	//     },
	//     fontSize: '16px',
	//     // color: COLORS.RAVEN,
	//     padding: '18.5px 14px',
	//     '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //default
	//     },
	//     '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //hovered
	//     },
	// },

	// cssOutlinedInput: {
	//     '&$cssFocused $notchedOutline': {
	//         borderColor: `${COLORS.RAVEN} `,
	//         borderWidth: 1
	//     },
	//     height: 45,
	//     fontSize: '16px',
	//     // color: COLORS.RAVEN,
	//     padding: '0px 0px',
	//     '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //default
	//     },
	//     '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //hovered
	//     },

	// }, cssFocused: {},

	disabled: {},
	focused: {},
	marginLeft12: {
		// marginLeft: 12
	},
	error: {
		//border: `1px solid ${COLORS.LT_PEPPER} !important`,//focused
		//borderWidth: '1px',
		//borderRadius: 1,
		borderRadius: '1px !important',
		border: '2px solid red !important',
	},
	errorColor: {
		color: `${COLORS.LT_PEPPER} !important`,
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: `${COLORS.LT_PEPPER} !important`,
			},
		},
	},
	noTopPad: {
		paddingTop: '0 !important',
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	radioGroup: {
		marginLeft: '8px',
	},
	inputRadio: {
		// color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.LT_JOURNEY,
		},

		// fontWeight: '900',
		// fontSize: '12px',
		whiteSpace: 'nowrap',
		// textTransform: 'uppercase',
	},
	setWidthSelectGroup: {
		top: '-23px',
		width: 'calc(100% - 1px)',
		marginTop: '10px',
		marginRight: '10px',
	},
	setWidthSelects: {
		minWidth: '100%',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	checkbox: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '15px',
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '10px 10px',
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},

	cssLabel: {
		color: `${COLORS.LT_PEPPER}`,
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	// selectContainerOffset: {
	//     padding: '0 16px 16px 16px !important'
	// },
	// selectContainer: {
	//     padding: '3px !important'
	// },
	// selectContainerGroup: {
	//     [theme.breakpoints.down('sm')]: {
	//         padding: '3px 10px 0px 10px !important',
	//     },
	//     minWidth: '97%'

	// },
	// multiGridOffset: {
	//     paddingTop: '13px !important'
	// },
	leftPad30: {
		paddingLeft: '30px !important',
		background: '#FFFFFF',
	},
	containerSM: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% + 13px) !important',
		},
	},
	marginBottom20Neg: {
		marginBottom: -20,
	},
	hideDeleteBtn: {
		display: 'none',
	},
	disabledInput: {
		opacity: 0.6,
	},
	normalInput: {
		opacity: 1,
	},
	disabledSelect: {
		opacity: 0.6,
		cursor: 'default',
	},
	infoMessage: {
		// color: COLORS.RAVEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '14px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '10px !important',
		paddingBottom: '15px',
	},
	instructions: {
		paddingTop: '6px',
		paddingBottom: '6px',
		textAlign: 'left',
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
}));

function TrainingForm({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	updateFlags,
	activePath,
	type,
	constantType,
	setCredAppError,
	credState,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	const normalTheme = createTheme({
		overrides: {
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					// height: '18px',
					// borderRadius: '1px !important',
					'&$disabled': {
						opacity: '0.6',
					},
				},
			},
			MuiFormControlLabel: {
				root: {
					'&$disabled': {
						opacity: '0.6',
					},
				},
			},
		},
	});
	const errorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '17px',
					borderRadius: '1px !important',
					border: '2px solid red !important',
				},
			},
		},
	});

	const [formTemplate] = useState({
		hospital: '',
		id: 'null',
		hospitalId: 'null',
		streetAddress: '',
		city: '',
		country: '',
		specialty: '',
		completed: null,
		programDirectorSalutation: '',
		programDirectorFirstName: '',
		programDirectorLastName: '',
		isCurrentlyInRole: false,
		dateFrom: null,
		dateTo: null,
		expectedEndDate: null,
		research: false,
		isVerified: false,
		detailsRegardingOther: '',
	});

	const itemTemplate = {
		hospital: '',
		id: null,
		hospitalId: null,
		streetAddress: '',
		city: '',
		country: '',
		specialty: '',
		completed: null,
		programDirectorSalutation: '',
		programDirectorFirstName: '',
		programDirectorLastName: '',
		isCurrentlyInRole: false,
		dateFrom: null,
		dateTo: null,
		expectedEndDate: null,
		research: false,
		isVerified: false,
		detailsRegardingOther: '',
	};

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isRequired, setIsRequired] = useState(false);

	const [deleteRecordIndex, setDeleteRecordIndex] = useState();
	const [deleteRecordId, setDeleteRecordId] = useState();
	const [deleteRecords, setDeleteRecords] = useState([]);

	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);

	const { register, control, handleSubmit, errors, reset, getValues, formState, trigger, watch } =
		useForm({
			mode: 'onBlur',
			defaultValues: {
				id: null,
				hospital: '',
				hospitalId: null,
				streetAddress: '',
				city: '',
				state: '',
				zipCode: '',
				country: '',
				specialty: '',
				completed: null,
				programDirectorSalutation: '',
				programDirectorFirstName: '',
				programDirectorLastName: '',
				isCurrentlyInRole: false,
				dateFrom: null,
				dateTo: null,
				expectedEndDate: null,
				research: false,
				isVerified: false,
				detailsRegardingOther: '',
			},
		});
	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;
			setSaveHandler(
				TrainingForm,
				type,
				handleSubmit(async (data) => await onSubmit(data))
			);

			await getTraining(externalId, type)
				.then(function (data) {
					//const { trainings = [] } = data
					console.log('response is', data);

					data['trainings'] = data['trainings'].filter(
						(item) =>
							![
								'Burn Surgery',
								'Hospitalist',
								'Hospice and Palliative Care',
								'Urgent Care',
								'Urogynecology',
								'Women\u{2019}s Imaging',
								'Wound Care',
							].includes(item.specialty)
					);

					if (data['trainings'].length === 0) {
						append({
							id: null,
							hospital: '',
							hospitalId: null,
							streetAddress: '',
							city: '',
							state: '',
							zipCode: '',
							country: '',
							specialty: '',
							completed: null,
							programDirectorSalutation: '',
							programDirectorFirstName: '',
							programDirectorLastName: '',
							isCurrentlyInRole: false,
							dateFrom: null,
							dateTo: null,
							expectedEndDate: null,
							research: false,
							isVerified: false,
							detailsRegardingOther: '',
						});
						data['trainings'].push({
							id: null,
							hospital: '',
							hospitalId: null,
							streetAddress: '',
							city: '',
							state: '',
							zipCode: '',
							country: '',
							specialty: '',
							completed: null,
							programDirectorSalutation: '',
							programDirectorFirstName: '',
							programDirectorLastName: '',
							isCurrentlyInRole: false,
							dateFrom: null,
							dateTo: null,
							expectedEndDate: null,
							research: false,
							isVerified: false,
							detailsRegardingOther: '',
						});
					} else {
						data['trainings'].map((item) => {
							//item.isCurrentlyInRole = (item.dateTo === null && item.dateFrom !== null);
							if (item.isCurrentlyInRole === null) {
								item.isCurrentlyInRole = false;
							}
							if (item.hospital === null) {
								item.hospital = '';
							}
							if (item.streetAddress === null) {
								item.streetAddress = '';
							}
							if (item.city === null) {
								item.city = '';
							}
							if (item.state === null) {
								item.state = '';
							}
							if (item.zipCode === null) {
								item.zipCode = '';
							}
							if (item.country === null) {
								item.country = '';
							}
							if (item.specialty === null) {
								item.specialty = '';
							}
							if (item.programDirectorSalutation === null) {
								item.programDirectorSalutation = '';
							}
							if (item.programDirectorFirstName === null) {
								item.programDirectorFirstName = '';
							}
							if (item.programDirectorLastName === null) {
								item.programDirectorLastName = '';
							}

							item.completed = `${item.completed}`;

							//TDS-3251 temparory disable check
							item.isVerified = false;

							if (item.detailsRegardingOther === null) {
								item.detailsRegardingOther = '';
							}
							return item;
						});
					}
					updateSectionCompletion(sectionCompletion);
					flags.dataSaved = false;
					updateFlags(flags);
					reset(data);
					setIsLoaded(true);

					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status = {
							status: 'incomplete',
							missingFields: [],
						};
						if (type === 'internships') {
							status = internshipSectionCheck(data[`trainings`], activePath);
						} else if (type === 'residencies') {
							status = residencySectionCheck(data[`trainings`], activePath);
						} else if (type === 'fellowships') {
							status = fellowshipSectionCheck(data[`trainings`], activePath);
						} else if (type === 'additionaltrainings') {
							status = additionalTrainingSectionCheck(data[`trainings`], activePath);
						}
						if (!!status.missingFields) {
							setHighlights(status.missingFields);
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);

	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'trainings',
		keyName: 'internalId',
	});

	function updateHighlight() {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};
			if (type === 'internships') {
				status = internshipSectionCheck(watch(`trainings`), activePath);
			} else if (type === 'residencies') {
				status = residencySectionCheck(watch(`trainings`), activePath);
			} else if (type === 'fellowships') {
				status = fellowshipSectionCheck(watch(`trainings`), activePath);
			} else if (type === 'additionaltrainings') {
				status = additionalTrainingSectionCheck(watch(`trainings`), activePath);
			}
			setHighlights(status.missingFields);
		}
	}

	/**
	 * Check if empty form exists
	 * @param index
	 * @returns {boolean}
	 */

	const isEmptyForm = async (index) => {
		const { trainings } = getValues();
		console.log('*****isEmptyForm******');
		if (!!trainings) {
			await setIsRequired(!_.isEqual(trainings[index], formTemplate));

			var allTriggers = [
				`trainings[${index}].hospital`,
				`trainings[${index}].streetAddress`,
				`trainings[${index}].city`,
				`trainings[${index}].state`,
				`trainings[${index}].zipCode`,
				`trainings[${index}].country`,
				`trainings[${index}].specialty`,
				`trainings[${index}].completed`,
				`trainings[${index}].programDirectorSalutation`,
				`trainings[${index}].programDirectorFirstName`,
				`trainings[${index}].programDirectorLastName`,
				`trainings[${index}].isCurrentlyInRole`,
				`trainings[${index}].dateFrom`,
				`trainings[${index}].dateTo`,
				`trainings[${index}].expectedEndDate`,
				`trainings[${index}].research`,
				`trainings[${index}].detailsRegardingOther`,
			];
			var dataFields = Object.keys(trainings[index]);
			var runTriggers = allTriggers.filter((val) => {
				return dataFields.includes(val.substring(val.lastIndexOf('.') + 1));
			});

			trigger(runTriggers);
		}
	};
	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('trainings')) {
			for (let i = 0; i < errors.trainings.length; i++) {
				if (!!errors?.trainings[i]) {
					//This would be 'return anError.type !== 'manual'', but manually set errors return a malformed error object with no 'type' field (the contents of the type field are inserted as an array of chars on the main object)
					missingRequiredToSaveFields = Object.values(errors.trainings[i]).filter(
						(anError) => {
							return anError.hasOwnProperty('type');
						}
					);
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	const classes = styles();

	async function onSubmit(data) {
		let success = false;

		const { trainings } = data;

		let indexes = _.keys(_.pickBy(trainings, formTemplate));
		for (let i = 0; i < indexes.length; i++) {
			trainings.splice(indexes[i], 1);
		}

		const { externalId } = UserContext;
		data['clinicianId'] = externalId;
		if (!!data['trainings']) {
			data['trainings'] = data['trainings']
				.map((item) => {
					const thisItemTemplate = itemTemplate;
					if (item.id === 'null') {
						item.id = null;
					}
					if (item.hospitalId === 'null') {
						item.hospitalId = null;
					}
					if (item.specialty === 'null') {
						item.specialty = null;
					}

					if (
						item.completed !== null &&
						item.completed !== 'null' &&
						item.completed !== '' &&
						item.completed !== undefined
					) {
						item.completed = item.completed !== 'false';
					} else {
						item.completed = null;
					}

					if (item.dateFrom !== null) {
						let firstDay = moment(item.dateFrom).startOf('month').format('MM/DD/yyyy');
						item.dateFrom = !firstDay ? null : moment(firstDay).toISOString(true);
					}

					if (item.isCurrentlyInRole === true) {
						item.dateTo = null;
						let lastDay = moment(item.expectedEndDate)
							.endOf('month')
							.format('MM/DD/yyyy');
						item.expectedEndDate = !lastDay ? null : moment(lastDay).toISOString(true);
					} else {
						item.isCurrentlyInRole = false;
						item.expectedEndDate = null;
						let lastDay = moment(item.dateTo).endOf('month').format('MM/DD/yyyy');
						item.dateTo = !lastDay ? null : moment(lastDay).toISOString(true);
					}

					if (item.hasOwnProperty('research') && type !== 'fellowships') {
						delete item.research;
					}

					if (matchesTemplate(item, thisItemTemplate)) {
						return null;
					}

					if (type === 'additionaltrainings') {
						item.trainingType = 'Advanced Practitioner';
					}
					return item;
				})
				.filter((d) => d !== null);
		}
		if (deleteRecords.length > 0) {
			const deleteRecordsNumbers = deleteRecords.filter(function (element) {
				return element !== 'null';
			});
			await deleteTraining(externalId, deleteRecordsNumbers, type).catch((e) => {
				setCredAppError(e);
			});
		}

		console.log('before json', data);
		const jsonData = JSON.stringify(data);
		console.log('post payload is:', jsonData);
		await postTraining(externalId, jsonData, type);

		if (type === 'internships' && !!sectionCompletion?.training?.internship) {
			sectionCompletion.training.internship.current = await internshipSectionPointsCheck(
				data['trainings'],
				activePath,
				sectionCompletion
			);
			updateSectionCompletion(sectionCompletion);
		} else if (type === 'residencies' && !!sectionCompletion?.training?.residency) {
			sectionCompletion.training.residency.current = await residencySectionPointsCheck(
				data['trainings'],
				activePath,
				sectionCompletion
			);
			updateSectionCompletion(sectionCompletion);
		} else if (type === 'fellowships' && !!sectionCompletion?.training?.fellowship) {
			sectionCompletion.training.fellowship.current = await fellowshipSectionPointsCheck(
				data['trainings'],
				activePath,
				sectionCompletion
			);
			updateSectionCompletion(sectionCompletion);
		} else if (
			type === 'additionaltrainings' &&
			!!sectionCompletion?.training?.additionalTraining
		) {
			sectionCompletion.training.additionalTraining.current =
				await additionalTrainingSectionPointsCheck(
					data['trainings'],
					activePath,
					sectionCompletion
				);
			updateSectionCompletion(sectionCompletion);
		}
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		updateFlags(flags);
		success = true;
		setIsLoaded(true);

		updateFlags(flags);
		return success;
	}

	const deleteEntry = (btnPressed, index) => {
		if (btnPressed === 'Yes') {
			if (deleteRecordId !== null) {
				deleteRecords.push(deleteRecordId);
				setDeleteRecords(deleteRecords);
				setShowDeleteModal(false);
			}
			remove(deleteRecordIndex);
			setShowDeleteModal(false);
		}
		setShowDeleteModal(false);
	};

	const openDeleteConfirmation = (item, index) => {
		setDeleteRecordIndex(index);
		setDeleteRecordId(item.id);
		setShowDeleteModal(true);
	};

	const showOther = (item, index) => {
		let lockFields =
			!!item.id &&
			(credState === CRED_STATE.CLOSED ||
				(!!item.isVerified && credState === CRED_STATE.OPEN) ||
				(!!item.isVerified &&
					activePath === ACTIVE_PATHNAME_AP &&
					credState === CRED_STATE.PARTIALLY_OPEN) ||
				(!!item.isVerified &&
					(activePath === ACTIVE_PATHNAME_MD ||
						credState === CRED_STATE.PARTIALLY_OPEN)));

		if (type === 'additionaltrainings') {
			lockFields =
				!!item.id &&
				((!!item.isVerified &&
					(credState === CRED_STATE.OPEN || credState === CRED_STATE.PARTIALLY_OPEN)) ||
					credState === CRED_STATE.CLOSED);
		}

		return (
			<Grid container>
				<Grid xs={12} className={classes.instructions}>
					<br />
				</Grid>
				<Grid classes={{ root: classes.gridRoot }} xs={12} md={10}>
					<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
						<InputLabel
							shrink
							htmlFor={`trainings[${index}].detailsRegardingOther`}
							classes={{ root: classes.otherInputLabel }}
							className={
								errors?.trainings &&
								errors?.trainings[`${index}`]?.detailsRegardingOther &&
								classes.errorColor
							}
							required
							error={
								(errors?.trainings &&
									errors?.trainings[`${index}`]?.detailsRegardingOther) ||
								(!!highlights &&
									highlights.includes(
										`trainings[${index}].detailsRegardingOther`
									))
							}
						>
							{t('credentials:TRAINING.OTHER')}
						</InputLabel>
						<TextField
							margin='dense'
							id={`trainings[${index}].detailsRegardingOther`}
							name={`trainings[${index}].detailsRegardingOther`}
							placeholder={'Provide an other explanation here'}
							variant='outlined'
							error={
								(errors?.trainings &&
									errors?.trainings[`${index}`]?.detailsRegardingOther) ||
								(!!highlights &&
									highlights.includes(
										`trainings[${index}].detailsRegardingOther`
									))
							}
							multiline
							rows={4}
							onChange={() => isEmptyForm(index)}
							onBlur={() => {
								isEmptyForm(index);
								updateHighlight();
							}}
							inputRef={register({
								required: isRequired,
								maxLength: {
									value: 255,
									message: t('common:MESSAGES.CHARACTER_LIMIT'),
								},
								pattern: /^[A-Za-z]{3}/,
							})}
							defaultValue={item.detailsRegardingOther}
							autoComplete='off'
							className={lockFields ? classes.disabledInput : classes.textField}
							InputProps={{
								readOnly: lockFields,
								classes: {
									root: classes.cssOutlinedInputOther,
									focused: classes.cssFocused,
									notchedOutline: classes.notchedOutline,
									error: classes.error,
								},
							}}
						/>
						{((errors?.trainings &&
							errors?.trainings[`${index}`]?.detailsRegardingOther &&
							errors?.trainings[`${index}`]?.detailsRegardingOther['type'] ===
								'maxLength') ||
							(!!highlights &&
								highlights.includes(
									`trainings[${index}].detailsRegardingOther`
								))) && (
							<span className={classes.cssLabel}>
								{errors?.trainings &&
									errors?.trainings[`${index}`]?.detailsRegardingOther &&
									errors?.trainings[`${index}`]?.detailsRegardingOther.message}
							</span>
						)}
						{((errors?.trainings &&
							errors?.trainings[`${index}`]?.detailsRegardingOther &&
							errors?.trainings[`${index}`]?.detailsRegardingOther['type'] !==
								'maxLength') ||
							(!!highlights &&
								highlights.includes(
									`trainings[${index}].detailsRegardingOther`
								))) && (
							<span className={classes.cssLabel}>
								{t('credentials:VALIDATION_MESSAGE.OTHER')}
							</span>
						)}
					</FormControl>
				</Grid>
			</Grid>
		);
	};

	const renderForm = (item, index) => {
		let lockFields =
			!!item.id &&
			((!!item.isVerified && credState === CRED_STATE.OPEN) ||
				(!!item.isVerified &&
					CRED_STATE.PARTIALLY_OPEN &&
					activePath === ACTIVE_PATHNAME_AP) ||
				(credState === CRED_STATE.PARTIALLY_OPEN && activePath === ACTIVE_PATHNAME_MD) ||
				credState === CRED_STATE.CLOSED);

		if (type === 'additionaltrainings') {
			lockFields =
				!!item.id &&
				((!!item.isVerified &&
					(credState === CRED_STATE.OPEN || credState === CRED_STATE.PARTIALLY_OPEN)) ||
					credState === CRED_STATE.CLOSED);
		}

		return (
			<div>
				<Grid container>
					{
						{
							internships: (
								<Grid
									container
									className={
										index === 0
											? classes.credAppFirstHeader
											: 'credAppAdditionalHeader'
									}
								>
									<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
										{index === 0
											? 'Internship'
											: t(`credentials:TRAINING.ADDITIONAL_${constantType}`)}
									</Grid>
									<Grid
										item
										xs={3}
										className={
											item.isVerified || credState === CRED_STATE.CLOSED
												? classes.hideDeleteBtn
												: 'credAppAdditionalDelete'
										}
										onClick={() => {
											openDeleteConfirmation(item, index);
										}}
									>
										{t('credentials:TRAINING.DELETE_BTN')}
									</Grid>
								</Grid>
							),
							fellowships: (
								<Grid
									container
									className={
										index === 0
											? classes.credAppFirstHeader
											: 'credAppAdditionalHeader'
									}
								>
									<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
										{index === 0
											? 'Fellowship'
											: t(`credentials:TRAINING.ADDITIONAL_${constantType}`)}
									</Grid>
									<Grid
										item
										xs={3}
										className={
											item.isVerified || credState === CRED_STATE.CLOSED
												? classes.hideDeleteBtn
												: 'credAppAdditionalDelete'
										}
										onClick={() => {
											openDeleteConfirmation(item, index);
										}}
									>
										{t('credentials:TRAINING.DELETE_BTN')}
									</Grid>
								</Grid>
							),
							residencies: (
								<Grid
									container
									className={
										index === 0
											? classes.credAppFirstHeader
											: 'credAppAdditionalHeader'
									}
								>
									<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
										{index === 0
											? 'Residency'
											: t(`credentials:TRAINING.ADDITIONAL_${constantType}`)}
									</Grid>
									<Grid
										item
										xs={3}
										className={
											item.isVerified || credState === CRED_STATE.CLOSED
												? classes.hideDeleteBtn
												: 'credAppAdditionalDelete'
										}
										onClick={() => {
											openDeleteConfirmation(item, index);
										}}
									>
										{t('credentials:TRAINING.DELETE_BTN')}
									</Grid>
								</Grid>
							),
							additionaltrainings: (
								<Grid
									container
									className={
										index === 0
											? classes.credAppFirstHeader
											: 'credAppAdditionalHeader'
									}
								>
									<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
										{index === 0
											? 'Additional Training'
											: t(`credentials:TRAINING.ADDITIONAL_${constantType}`)}
									</Grid>
									<Grid
										item
										xs={3}
										className={
											item.isVerified || credState === CRED_STATE.CLOSED
												? classes.hideDeleteBtn
												: 'credAppAdditionalDelete'
										}
										onClick={() => {
											openDeleteConfirmation(item, index);
										}}
									>
										{t('credentials:TRAINING.DELETE_BTN')}
									</Grid>
								</Grid>
							),
						}[type]
					}
				</Grid>

				<Input
					autoComplete='off'
					variant='outlined'
					style={{ display: 'none' }}
					name={`trainings[${index}].id`}
					fullWidth
					value={`${item.id}` || null}
					defaultValue={`${item.id}`}
					inputRef={register()}
				/>
				<Input
					autoComplete='off'
					variant='outlined'
					name={`trainings[${index}].hospitalId`}
					style={{ display: 'none' }}
					fullWidth
					value={`${item.hospitalId}` || null}
					defaultValue={`${item.hospitalId}`}
					inputRef={register()}
				/>
				<Container component='main' maxWidth='md'>
					<CssBaseline />
					<div>
						<Grid container item md={12} className={classes.containerSM}>
							<form
								className={classes.form}
								noValidate
								onSubmit={handleSubmit(onSubmit)}
							>
								<RouteDeparturePrompt
									blockedBackNavigation={blockedBackNavigation}
									when={
										!flags.dataSaved
											? !!Object.keys(formState.dirtyFields).length
											: false
									}
								/>

								<Grid container>
									<Grid item xs={12}>
										<FormControl>
											<InputLabel
												shrink
												htmlFor={`trainings[${index}].hospital`}
												classes={{ root: classes.inputLabel }}
												className={
													((errors?.trainings &&
														errors?.trainings[`${index}`]?.hospital &&
														classes.errorColor) ||
														(!!highlights &&
															highlights.includes(
																`trainings[${index}].hospital`
															))) &&
													classes.errorColor
												}
												required
											>
												{type === 'additionaltrainings'
													? t('credentials:TRAINING.INSTITUTION')
													: t('credentials:TRAINING.HOSPITAL')}
											</InputLabel>
											<TextField
												margin='dense'
												autoComplete='off'
												variant='outlined'
												name={`trainings[${index}].hospital`}
												error={
													(errors?.trainings &&
														errors?.trainings[`${index}`]?.hospital &&
														classes.errorColor) ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].hospital`
														))
												}
												fullWidth
												defaultValue={`${item.hospital}`}
												inputRef={register({
													required: isRequired,
													maxLength: {
														value: 80,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
													pattern: /^[A-Za-z0-9][\s\S]{2,}/,
												})}
												onBlur={() => {
													isEmptyForm(index);
													updateHighlight();
												}}
												onChange={() => isEmptyForm(index)}
												className={
													lockFields
														? classes.disabledInput
														: classes.normalInput
												}
												InputProps={{
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
													readOnly: lockFields,
												}}
											/>
											{((errors?.trainings &&
												errors?.trainings[`${index}`]?.hospital &&
												errors?.trainings[`${index}`]?.hospital['type'] ===
													'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`trainings[${index}].hospital`
													))) && (
												<span className={classes.cssLabel}>
													{errors?.trainings &&
														errors?.trainings[`${index}`]?.hospital &&
														errors?.trainings[`${index}`]?.hospital
															.message}
												</span>
											)}
											{((errors?.trainings &&
												errors?.trainings[`${index}`]?.hospital &&
												errors?.trainings[`${index}`]?.hospital['type'] !==
													'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`trainings[${index}].hospital`
													))) && (
												<span className={classes.cssLabel}>
													{t('credentials:VALIDATION_MESSAGE.HOSPITAL')}
												</span>
											)}
										</FormControl>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={12}>
										<FormControl>
											<InputLabel
												shrink
												htmlFor={`trainings[${index}].country`}
												classes={{ root: classes.selectInputLabel }}
												required
												className={
													((errors?.trainings &&
														errors?.trainings[`${index}`]?.country &&
														classes.errorColor) ||
														(!!highlights &&
															highlights.includes(
																`trainings[${index}].country`
															))) &&
													classes.errorColor
												}
											>
												{t('credentials:TRAINING.COUNTRY')}
											</InputLabel>
											<ThemeProvider
												theme={
													(errors?.trainings &&
														errors?.trainings[`${index}`]?.country) ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].country`
														))
														? errorTheme
														: normalTheme
												}
											>
												<NativeSelect
													defaultValue={`${item.country}`}
													inputRef={register({ required: isRequired })}
													name={`trainings[${index}].country`}
													className={
														lockFields
															? classes.disabledSelect
															: classes.normalInput
													}
													onBlur={() => {
														isEmptyForm(index);
														updateHighlight();
													}}
													onChange={() => isEmptyForm(index)}
													disabled={lockFields}
													input={
														<TextFieldComponent
															// style={{ border: `0px solid ${COLORS.RAVEN}` }}
															classes={{
																input: classes.setWidthTo100OnMobile,
															}}
														/>
													}
												>
													<option key={''} value={null}></option>
													{COUNTRIES.map((item) => (
														<option key={item} value={item}>
															{item}
														</option>
													))}
												</NativeSelect>
											</ThemeProvider>
											{((errors?.trainings &&
												errors?.trainings[`${index}`]?.country) ||
												(!!highlights &&
													highlights.includes(
														`trainings[${index}].country`
													))) && (
												<span
													className={`${classes.cssLabel} ${classes.marginLeft12}`}
												>
													{t('credentials:VALIDATION_MESSAGE.COUNTRY')}
												</span>
											)}

											{lockFields ? (
												<Input
													style={{ display: 'none' }}
													name={`trainings[${index}].country`}
													value={`${item.country}`}
													inputRef={register()}
												/>
											) : null}
										</FormControl>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={12}>
										<FormControl>
											<InputLabel
												shrink
												htmlFor={`trainings[${index}].streetAddress`}
												className={classes.inputLabel}
												error={
													(errors?.trainings &&
														errors?.trainings[`${index}`]
															?.streetAddress) ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].streetAddress`
														))
												}
											>
												{t('credentials:TRAINING.ADDRESS')}
											</InputLabel>
											<TextField
												margin='dense'
												autoComplete='off'
												variant='outlined'
												fullWidth
												defaultValue={`${item.streetAddress}`}
												name={`trainings[${index}].streetAddress`}
												inputRef={register({
													maxLength: {
														value: 255,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
												})}
												helperText={
													(errors?.trainings &&
														errors?.trainings[`${index}`]
															?.streetAddress &&
														errors?.trainings[`${index}`]
															?.streetAddress['type'] ===
															'maxLength') ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].streetAddress`
														))
														? t('common:MESSAGES.CHARACTER_LIMIT')
														: null
												}
												onBlur={() => {
													isEmptyForm(index);
													updateHighlight();
												}}
												onChange={() => isEmptyForm(index)}
												className={
													lockFields
														? classes.disabledInput
														: classes.normalInput
												}
												error={
													(errors?.trainings &&
														errors?.trainings[`${index}`]
															?.streetAddress) ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].streetAddress`
														))
												}
												InputProps={{
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
													readOnly: lockFields,
												}}
											/>
										</FormControl>
									</Grid>
								</Grid>
								<Grid container spacing={4}>
									<Grid
										item
										xs={12}
										md={4}
										classes={{ root: classes.multiGridOffset }}
									>
										<FormControl>
											<InputLabel
												shrink
												htmlFor={`trainings[${index}].city`}
												classes={{ root: classes.inputLabel }}
												required
												className={
													((errors?.trainings &&
														errors?.trainings[`${index}`]?.city &&
														classes.errorColor) ||
														(!!highlights &&
															highlights.includes(
																`trainings[${index}].city`
															))) &&
													classes.errorColor
												}
											>
												{t('credentials:TRAINING.CITY')}
											</InputLabel>
											<TextField
												margin='dense'
												autoComplete='off'
												variant='outlined'
												fullWidth
												name={`trainings[${index}].city`}
												onBlur={() => {
													isEmptyForm(index);
													updateHighlight();
												}}
												error={
													(errors?.trainings &&
														errors?.trainings[`${index}`]?.city) ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].city`
														))
												}
												onChange={() => isEmptyForm(index)}
												className={
													lockFields
														? classes.disabledInput
														: classes.normalInput
												}
												defaultValue={`${item.city}`}
												inputRef={register({
													required: isRequired,
													maxLength: {
														value: 255,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
													pattern: /^[A-Za-z0-9]/,
												})}
												helperText={
													errors?.trainings &&
													errors?.trainings[`${index}`]?.city &&
													errors?.trainings[`${index}`]?.city['type'] ===
														'maxLength'
														? t('common:MESSAGES.CHARACTER_LIMIT')
														: (errors?.trainings &&
																errors?.trainings[`${index}`]
																	?.city) ||
														  (!!highlights &&
																highlights.includes(
																	`trainings[${index}].city`
																))
														? t('credentials:VALIDATION_MESSAGE.CITY')
														: null
												}
												InputProps={{
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
													readOnly: lockFields,
												}}
											/>
										</FormControl>
									</Grid>

									{watch(`trainings[${index}].country`) === 'United States' ? (
										<Grid container item md spacing={4} direction={'row'}>
											<Grid
												item
												xs={12}
												md={6}
												classes={{ root: classes.selectContainerOffset }}
											>
												<FormControl
													classes={{
														root: classes.setWidthTo100OnMobile,
													}}
												>
													<InputLabel
														shrink
														htmlFor={`trainings[${index}].state`}
														classes={{ root: classes.selectInputLabel }}
														required
														className={
															((errors?.trainings &&
																errors?.trainings[`${index}`]
																	?.state &&
																classes.errorColor) ||
																(!!highlights &&
																	highlights.includes(
																		`trainings[${index}].state`
																	))) &&
															classes.errorColor
														}
													>
														{t('credentials:TRAINING.STATE')}
													</InputLabel>
													<ThemeProvider
														theme={
															(errors?.trainings &&
																errors?.trainings[`${index}`]
																	?.state) ||
															(!!highlights &&
																highlights.includes(
																	`trainings[${index}].state`
																))
																? errorTheme
																: normalTheme
														}
													>
														<NativeSelect
															defaultValue={`${item.state}`}
															inputRef={register({
																required:
																	watch(
																		`trainings[${index}].country`
																	) === 'United States',
															})}
															onBlur={() => {
																isEmptyForm(index);
																updateHighlight();
															}}
															onChange={() => {
																isEmptyForm(index);
																updateHighlight();
															}}
															className={
																lockFields
																	? classes.disabledSelect
																	: classes.normalInput
															}
															error={
																(errors?.trainings &&
																	errors?.trainings[`${index}`]
																		?.state) ||
																(!!highlights &&
																	highlights.includes(
																		`trainings[${index}].state`
																	))
															}
															name={`trainings[${index}].state`}
															disabled={lockFields}
															input={
																<TextFieldComponent
																	// style={{ border: `0px solid lime` }}
																	classes={{
																		input: classes.setWidthTo100OnMobile,
																	}}
																/>
															}
														>
															<option key={''} value={null}></option>
															{statesForLicense.map((item) => (
																<option
																	key={item.value}
																	value={item.value}
																>
																	{item.label}
																</option>
															))}
														</NativeSelect>
													</ThemeProvider>
													{((errors?.trainings &&
														errors?.trainings[`${index}`]?.state) ||
														(!!highlights &&
															highlights.includes(
																`trainings[${index}].zipCode`
															))) && (
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.STATE'
															)}
														</span>
													)}

													{lockFields ? (
														<Input
															style={{ display: 'none' }}
															name={`trainings[${index}].state`}
															value={`${item.state}`}
															inputRef={register()}
														/>
													) : null}
												</FormControl>
											</Grid>

											<Grid
												item
												xs={12}
												md={6}
												classes={{ root: classes.multiGridOffset }}
											>
												<InputLabel
													shrink
													htmlFor={`trainings[${index}].zipCode`}
													classes={{ root: classes.inputLabel }}
													className={
														((errors?.trainings &&
															errors?.trainings[`${index}`]
																?.zipCode &&
															classes.errorColor) ||
															(!!highlights &&
																highlights.includes(
																	`trainings[${index}].zipCode`
																))) &&
														classes.errorColor
													}
												>
													{t('credentials:TRAINING.ZIP_CODE')}
												</InputLabel>
												<TextField
													margin='dense'
													autoComplete='off'
													variant='outlined'
													fullWidth
													defaultValue={`${item.zipCode}`}
													error={
														(errors?.trainings &&
															errors?.trainings[`${index}`]
																?.zipCode) ||
														(!!highlights &&
															highlights.includes(
																`trainings[${index}].zipCode`
															))
													}
													className={
														lockFields
															? classes.disabledInput
															: classes.normalInput
													}
													name={`trainings[${index}].zipCode`}
													onBlur={() => {
														isEmptyForm(index);
														updateHighlight();
													}}
													onChange={() => isEmptyForm(index)}
													inputRef={register({
														minLength: 5,
														maxLength: {
															value: 5,
															message: t(
																'common:MESSAGES.CHARACTER_LIMIT'
															),
														},
													})}
													InputProps={{
														classes: {
															root: classes.cssOutlinedInput,
															focused: classes.cssFocused,
															notchedOutline: classes.notchedOutline,
															error: classes.error,
														},
														readOnly: lockFields,
													}}
												/>

												{((errors?.trainings &&
													errors?.trainings[`${index}`]?.zipCode &&
													errors?.trainings &&
													errors?.trainings[`${index}`]?.zipCode[
														'type'
													] === 'minLength') ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].zipCode`
														))) && (
													<span className={classes.cssLabel}>
														{t(
															'credentials:VALIDATION_MESSAGE.ZIP_CODE'
														)}
													</span>
												)}
												{((errors?.trainings &&
													errors?.trainings[`${index}`]?.zipCode &&
													errors?.trainings &&
													errors?.trainings[`${index}`]?.zipCode[
														'type'
													] === 'maxLength') ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].zipCode`
														))) && (
													<span className={classes.cssLabel}>
														{t('common:MESSAGES.CHARACTER_LIMIT')}
													</span>
												)}
											</Grid>
										</Grid>
									) : null}
								</Grid>
								<Grid container>
									<Grid
										item
										xs={12}
										md={12}
										classes={{ root: classes.selectContainerGroup }}
									>
										<FormControl classes={{ root: classes.setWidthSelects }}>
											<InputLabel
												shrink
												htmlFor={`trainings[${index}].specialty`}
												classes={{ root: classes.selectInputLabel }}
												error={
													(errors?.trainings &&
														errors?.trainings[`${index}`]?.specialty) ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].specialty`
														))
												}
												required
											>
												{t('credentials:TRAINING.SPECIALITY')}
											</InputLabel>
											<ThemeProvider
												theme={
													(errors?.trainings &&
														errors?.trainings[`${index}`]?.specialty) ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].specialty`
														))
														? errorTheme
														: normalTheme
												}
											>
												<NativeSelect
													defaultValue={`${item.specialty}`}
													inputRef={register()}
													onBlur={() => {
														isEmptyForm(index);
														updateHighlight();
													}}
													onChange={() => {
														isEmptyForm(index);
														updateHighlight();
													}}
													name={`trainings[${index}].specialty`}
													className={
														lockFields
															? classes.disabledSelect
															: classes.normalInput
													}
													disabled={lockFields}
													input={
														<TextFieldComponent
															// style={{ border: `0px solid ${COLORS.RAVEN}` }}
															classes={{
																input: classes.setWidthTo100OnMobile,
															}}
														/>
													}
												>
													<option key={''} value={null}></option>
													{type === 'fellowships'
														? specialtiesFellowship
																.sort((a, b) =>
																	a.label > b.label
																		? 1
																		: b.label > a.label
																		? -1
																		: 0
																)
																.map((item) => (
																	<option
																		key={item.value}
																		value={item.value}
																	>
																		{item.label}
																	</option>
																))
														: type === 'internships' ||
														  type === 'residencies'
														? specialtiesItrnResidency
																.sort((a, b) =>
																	a.label > b.label
																		? 1
																		: b.label > a.label
																		? -1
																		: 0
																)
																.map((item) => (
																	<option
																		key={item.value}
																		value={item.value}
																	>
																		{item.label}
																	</option>
																))
														: specialties.map((item) => (
																<option
																	key={item.value}
																	value={item.value}
																>
																	{item.label}
																</option>
														  ))}
												</NativeSelect>
												{watch(`trainings[${index}].specialty`) === 'Other'
													? showOther(item, index)
													: null}
											</ThemeProvider>
										</FormControl>
										{lockFields ? (
											<Input
												style={{ display: 'none' }}
												name={`trainings[${index}].specialty`}
												value={`${item.specialty}`}
												inputRef={register()}
											/>
										) : null}
									</Grid>
								</Grid>

								<Grid
									container
									spacing={4}
									classes={{ root: classes.setWidthSelectGroup }}
								>
									<Grid item xs={12} md={4}>
										<FormControl
											classes={{ root: classes.setWidthTo100OnMobile }}
										>
											<InputLabel
												shrink
												htmlFor={`trainings[${index}].dateFrom`}
												classes={{ root: classes.selectInputLabel }}
												error={
													((errors?.trainings &&
														errors?.trainings[`${index}`]?.dateFrom) ||
														(!!highlights &&
															highlights.includes(
																`trainings[${index}].dateFrom`
															))) &&
													classes.errorColor
												}
												required
											>
												{t('credentials:TRAINING.FROM_DATE')}
											</InputLabel>
											<ThemeProvider theme={datePickerTheme}>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<Controller
														control={control}
														rules={{
															validate: (date) =>
																dateValidation(date),
														}}
														defaultValue={
															`${item.dateFrom}` === 'null'
																? null
																: convertToDateIgnoringTime(
																		`${item.dateFrom}`
																  )
														}
														name={`trainings[${index}].dateFrom`}
														render={({
															onChange,
															onBlur,
															value,
															name,
														}) => (
															<DatePickerComponent
																margin='dense'
																onBlur={() => {
																	onBlur(
																		isEmptyForm(index),
																		updateHighlight()
																	);
																}}
																onChange={onChange}
																onClose={() => {
																	updateHighlight();
																}}
																name={name}
																value={value}
																format='MM/yyyy'
																placeholder='MM/YYYY'
																clearable
																disabled={lockFields}
																variant='inline'
																autoOk='true'
																minDateMessage={
																	<span
																		className={`${classes.cssLabel} ${classes.marginLeft12}`}
																	>
																		{t(
																			'credentials:VALIDATION_MESSAGE.DATE'
																		)}
																	</span>
																}
																maxDateMessage={
																	<span
																		className={`${classes.cssLabel} ${classes.marginLeft12}`}
																	>
																		{t(
																			'credentials:VALIDATION_MESSAGE.DATE'
																		)}
																	</span>
																}
																invalidDateMessage={
																	<span
																		className={`${classes.cssLabel} ${classes.marginLeft12}`}
																	>
																		{t(
																			'credentials:VALIDATION_MESSAGE.DATE'
																		)}
																	</span>
																}
																theme={datePickerTheme}
																error={
																	(errors?.trainings &&
																		errors?.trainings[
																			`${index}`
																		]?.dateFrom) ||
																	(!!highlights &&
																		highlights.includes(
																			`trainings[${index}].dateFrom`
																		))
																}
																InputProps={{
																	classes: {
																		disabled:
																			classes.disabledInput,
																		root: classes.cssOutlinedInput,
																	},
																}}
																KeyboardButtonProps={{
																	'aria-label': t(
																		'credentials:OTHER_CERTIFICATIONS.EXPIRATION'
																	),
																}}
																inputVariant='outlined'
															/>
														)}
													/>
												</MuiPickersUtilsProvider>
											</ThemeProvider>
										</FormControl>
									</Grid>

									<Grid item xs={12} md={4}>
										<FormControl
											classes={{ root: classes.setWidthTo100OnMobile }}
										>
											{watch(`trainings[${index}].isCurrentlyInRole`) ===
											true ? (
												<>
													<InputLabel
														key='expectedDate-label'
														shrink
														htmlFor={`trainings[${index}].expectedEndDate`}
														classes={{ root: classes.selectInputLabel }}
														className={
															((errors?.trainings &&
																errors?.trainings[`${index}`]
																	?.expectedEndDate) ||
																(!!highlights &&
																	highlights.includes(
																		`trainings[${index}].expectedEndDate`
																	))) &&
															classes.errorColor
														}
														required
													>
														{t(
															'credentials:TRAINING.EXPECTED_COMPLETION'
														)}
													</InputLabel>
													<ThemeProvider theme={datePickerTheme}>
														<MuiPickersUtilsProvider
															utils={DateFnsUtils}
															key='expectedDate'
														>
															<Controller
																control={control}
																rules={{
																	validate: (date) =>
																		dateValidation(date),
																}}
																defaultValue={
																	`${item.expectedEndDate}` ===
																	'null'
																		? null
																		: convertToDateIgnoringTime(
																				`${item.expectedEndDate}`
																		  )
																}
																name={`trainings[${index}].expectedEndDate`}
																render={({
																	onChange,
																	onBlur,
																	value,
																	name,
																}) => (
																	<DatePickerComponent
																		margin='dense'
																		onBlur={() => {
																			onBlur(
																				isEmptyForm(index),
																				updateHighlight()
																			);
																		}}
																		onChange={onChange}
																		onClose={() => {
																			updateHighlight();
																		}}
																		name={name}
																		value={value}
																		format='MM/yyyy'
																		placeholder='MM/YYYY'
																		clearable
																		disabled={
																			!!item.id &&
																			(credState ===
																				CRED_STATE.CLOSED ||
																				(item.isVerified &&
																					credState ===
																						CRED_STATE.OPEN))
																		}
																		variant='inline'
																		autoOk='true'
																		minDateMessage={
																			<span
																				className={`${classes.cssLabel} ${classes.marginLeft12}`}
																			>
																				{t(
																					'credentials:VALIDATION_MESSAGE.DATE'
																				)}
																			</span>
																		}
																		maxDateMessage={
																			<span
																				className={`${classes.cssLabel} ${classes.marginLeft12}`}
																			>
																				{t(
																					'credentials:VALIDATION_MESSAGE.DATE'
																				)}
																			</span>
																		}
																		invalidDateMessage={
																			<span
																				className={`${classes.cssLabel} ${classes.marginLeft12}`}
																			>
																				{t(
																					'credentials:VALIDATION_MESSAGE.DATE'
																				)}
																			</span>
																		}
																		theme={datePickerTheme}
																		error={
																			(errors?.trainings &&
																				errors?.trainings[
																					`${index}`
																				]
																					?.expectedEndDate) ||
																			(!!highlights &&
																				highlights.includes(
																					`trainings[${index}].expectedEndDate`
																				))
																		}
																		InputProps={{
																			classes: {
																				disabled:
																					classes.disabledInput,
																				root: classes.cssOutlinedInput,
																			},
																		}}
																		KeyboardButtonProps={{
																			'aria-label': t(
																				'credentials:OTHER_CERTIFICATIONS.EXPIRATION'
																			),
																		}}
																		inputVariant='outlined'
																	/>
																)}
															/>
														</MuiPickersUtilsProvider>
													</ThemeProvider>
												</>
											) : (
												<>
													<InputLabel
														key='dateTo-label'
														shrink
														htmlFor={`trainings[${index}].dateTo`}
														classes={{ root: classes.selectInputLabel }}
														error={
															(errors?.trainings &&
																errors?.trainings[`${index}`]
																	?.dateTo) ||
															(!!highlights &&
																highlights.includes(
																	`trainings[${index}].dateTo`
																))
														}
														required
													>
														{t('credentials:TRAINING.TO_DATE')}
													</InputLabel>
													<ThemeProvider theme={datePickerTheme}>
														<MuiPickersUtilsProvider
															utils={DateFnsUtils}
															key='dateTo'
														>
															<Controller
																control={control}
																rules={{
																	validate: (date) =>
																		dateValidation(date),
																}}
																defaultValue={
																	`${item.dateTo}` === 'null'
																		? null
																		: convertToDateIgnoringTime(
																				`${item.dateTo}`
																		  )
																}
																name={`trainings[${index}].dateTo`}
																render={({
																	onChange,
																	onBlur,
																	value,
																	name,
																}) => (
																	<DatePickerComponent
																		margin='dense'
																		onBlur={() => {
																			onBlur(
																				isEmptyForm(index),
																				updateHighlight()
																			);
																		}}
																		onChange={onChange}
																		onClose={() => {
																			updateHighlight();
																		}}
																		name={name}
																		value={value}
																		format='MM/yyyy'
																		placeholder='MM/YYYY'
																		clearable
																		disabled={lockFields}
																		variant='inline'
																		autoOk='true'
																		minDateMessage={
																			<span
																				className={`${classes.cssLabel} ${classes.marginLeft12}`}
																			>
																				{t(
																					'credentials:VALIDATION_MESSAGE.DATE'
																				)}
																			</span>
																		}
																		maxDateMessage={
																			<span
																				className={`${classes.cssLabel} ${classes.marginLeft12}`}
																			>
																				{t(
																					'credentials:VALIDATION_MESSAGE.DATE'
																				)}
																			</span>
																		}
																		invalidDateMessage={
																			<span
																				className={`${classes.cssLabel} ${classes.marginLeft12}`}
																			>
																				{t(
																					'credentials:VALIDATION_MESSAGE.DATE'
																				)}
																			</span>
																		}
																		theme={datePickerTheme}
																		error={
																			(errors?.trainings &&
																				errors?.trainings[
																					`${index}`
																				]?.dateTo) ||
																			(!!highlights &&
																				highlights.includes(
																					`trainings[${index}].dateTo`
																				))
																		}
																		InputProps={{
																			classes: {
																				disabled:
																					classes.disabledInput,
																				root: classes.cssOutlinedInput,
																			},
																		}}
																		KeyboardButtonProps={{
																			'aria-label': t(
																				'credentials:OTHER_CERTIFICATIONS.EXPIRATION'
																			),
																		}}
																		inputVariant='outlined'
																	/>
																)}
															/>
														</MuiPickersUtilsProvider>
													</ThemeProvider>
												</>
											)}
										</FormControl>
									</Grid>

									{type === 'internships' ||
									type === 'fellowships' ||
									type === 'residencies' ||
									type === 'additionaltrainings' ? (
										<Grid container item>
											<Grid item xs={12} md={7}>
												<FormControl
													classes={{
														root: classes.setWidthTo100OnMobile,
													}}
												>
													<InputLabel
														shrink
														htmlFor={`trainings[${index}].isCurrentlyInRole`}
														classes={{
															root: classes.checkboxInputLabel,
															error: classes.error,
														}}
														error={
															(errors?.trainings &&
																errors?.trainings[`${index}`]
																	?.isCurrentlyInRole) ||
															(!!highlights &&
																highlights.includes(
																	`trainings[${index}].isCurrentlyInRole`
																))
														}
													>
														{t(
															'credentials:TRAINING.CURRENTLY_WORKING'
														)}
													</InputLabel>

													<Controller
														name={`trainings[${index}].isCurrentlyInRole`}
														control={control}
														rules={{}}
														defaultChecked={item.isCurrentlyInRole}
														render={({ onChange, value, name }) => (
															<Checkbox
																color='primary'
																onChange={(e) => {
																	onChange(e.target.checked);
																	setTimeout(() => {
																		updateHighlight();
																	}, 50);
																}}
																checked={value}
																name={name}
																className={classes.checkboxInput}
																disabled={lockFields}
															/>
														)}
													/>
												</FormControl>
											</Grid>
										</Grid>
									) : null}

									<Grid item xs={12}>
										<FormControl
											classes={{ root: classes.setWidthTo100OnMobile }}
											className={classes.radioGroup}
										>
											<InputLabel
												shrink
												htmlFor={`trainings[${index}].completed`}
												classes={{ root: classes.inputRadio }}
												error={
													(errors?.trainings &&
														errors?.trainings[`${index}`]?.completed) ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].completed`
														))
												}
												required={
													watch(
														`trainings[${index}].isCurrentlyInRole`
													) === true &&
													watch(`trainings[${index}].expectedEndDate`) ===
														null
												}
											>
												{t('credentials:TRAINING.COMPLETED')}
											</InputLabel>
											<div style={{ marginTop: '10px' }}>
												<RadioGroup
													row
													aria-label='completed'
													name={`trainings[${index}].completed`}
													defaultValue={`${item.completed}`}
													onChange={() => {
														isEmptyForm(index);
														updateHighlight();
													}}
												>
													<ThemeProvider theme={normalTheme}>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	color='primary'
																	style={{ color: COLORS.RAVEN }}
																	inputRef={register()}
																	disableRipple
																	size='small'
																	disabled={
																		!!item.id &&
																		((item.isVerified &&
																			credState ===
																				CRED_STATE.OPEN) ||
																			credState ===
																				CRED_STATE.PARTIALLY_OPEN ||
																			credState ===
																				CRED_STATE.CLOSED)
																	}
																	className={
																		!!item.id &&
																		((item.isVerified &&
																			credState ===
																				CRED_STATE.OPEN) ||
																			credState ===
																				CRED_STATE.PARTIALLY_OPEN ||
																			credState ===
																				CRED_STATE.CLOSED)
																			? classes.disabledInput
																			: classes.normalInput
																	}
																/>
															}
															label={
																<span
																	style={{
																		fontSize: '16px',
																		color: COLORS.RAVEN,
																	}}
																>
																	Yes
																</span>
															}
														/>
													</ThemeProvider>
													<ThemeProvider theme={normalTheme}>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	color='primary'
																	style={{ color: COLORS.RAVEN }}
																	inputRef={register()}
																	disableRipple
																	size='small'
																	disabled={
																		!!item.id &&
																		((item.isVerified &&
																			credState ===
																				CRED_STATE.OPEN) ||
																			credState ===
																				CRED_STATE.PARTIALLY_OPEN ||
																			credState ===
																				CRED_STATE.CLOSED)
																	}
																	className={
																		!!item.id &&
																		((item.isVerified &&
																			credState ===
																				CRED_STATE.OPEN) ||
																			credState ===
																				CRED_STATE.PARTIALLY_OPEN ||
																			credState ===
																				CRED_STATE.CLOSED)
																			? classes.disabledInput
																			: classes.normalInput
																	}
																/>
															}
															label={
																<span
																	style={{
																		fontSize: '16px',
																		color: COLORS.RAVEN,
																	}}
																>
																	No
																</span>
															}
														/>
													</ThemeProvider>
												</RadioGroup>
											</div>
										</FormControl>
									</Grid>
									<Grid item xs={12} className={classes.marginBottom20Neg}>
										<FormControl
											classes={{ root: classes.setWidthSelectGroup }}
										>
											<InputLabel
												shrink
												htmlFor={`trainings[${index}].programDirectorSalutation`}
												classes={{ root: classes.selectInputLabel }}
												error={
													(errors?.trainings &&
														errors?.trainings[`${index}`]
															?.programDirectorSalutation) ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].programDirectorSalutation`
														))
												}
											>
												{t(
													'credentials:TRAINING.PROGRAM_DIRECTOR_SALUTATION'
												)}
											</InputLabel>
											<ThemeProvider
												theme={
													(errors?.trainings &&
														errors?.trainings[`${index}`]
															?.programDirectorSalutation) ||
													(!!highlights &&
														highlights.includes(
															`trainings[${index}].programDirectorSalutation`
														))
														? errorTheme
														: normalTheme
												}
											>
												<NativeSelect
													defaultValue={`${item.programDirectorSalutation}`}
													name={`trainings[${index}].programDirectorSalutation`}
													className={
														!!item.id &&
														(credState === CRED_STATE.CLOSED ||
															credState === CRED_STATE.PARTIALLY_OPEN)
															? classes.disabledSelect
															: classes.normalInput
													}
													disabled={
														!!item.id &&
														activePath === ACTIVE_PATHNAME_MD &&
														(credState === CRED_STATE.CLOSED ||
															credState === CRED_STATE.PARTIALLY_OPEN)
													}
													input={
														<TextFieldComponent
															// style={{ border: `0px solid ${COLORS.RAVEN}` }}
															classes={{
																input: classes.setWidthTo100OnMobile,
															}}
														/>
													}
													inputRef={register()}
													onBlur={() => {
														isEmptyForm(index);
														updateHighlight();
													}}
													onChange={() => isEmptyForm(index)}
												>
													<option key={''} value={null}></option>
													{programSalutation.map((item) => (
														<option key={item.value} value={item.value}>
															{item.label}
														</option>
													))}
												</NativeSelect>
											</ThemeProvider>
										</FormControl>
										{lockFields && activePath !== ACTIVE_PATHNAME_AP ? (
											<Input
												style={{ display: 'none' }}
												name={`trainings[${index}].programDirectorSalutation`}
												value={`${item.programDirectorSalutation}`}
												inputRef={register()}
											/>
										) : null}
									</Grid>

									<Grid item xs={6}>
										<InputLabel
											shrink
											htmlFor={`trainings[${index}].programDirectorFirstName`}
											className={classes.inputLabel}
											error={
												(errors?.trainings &&
													errors?.trainings[`${index}`]
														?.programDirectorFirstName) ||
												(!!highlights &&
													highlights.includes(
														`trainings[${index}].programDirectorFirstName`
													))
											}
										>
											{t('credentials:TRAINING.PROGRAM_DIRECTOR_FIRST_NAME')}
										</InputLabel>
										<TextField
											margin='dense'
											autoComplete='off'
											variant='outlined'
											fullWidth
											defaultValue={`${item.programDirectorFirstName}`}
											name={`trainings[${index}].programDirectorFirstName`}
											inputRef={register({
												maxLength: {
													value: 100,
													message: t('common:MESSAGES.CHARACTER_LIMIT'),
												},
											})}
											helperText={
												(errors?.trainings &&
													errors?.trainings[`${index}`]
														?.programDirectorFirstName &&
													errors?.trainings[`${index}`]
														?.programDirectorFirstName['type'] ===
														'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`trainings[${index}].programDirectorFirstName`
													))
													? t('common:MESSAGES.CHARACTER_LIMIT')
													: null
											}
											disabled={
												!!item.id &&
												(credState === CRED_STATE.CLOSED ||
													credState === CRED_STATE.PARTIALLY_OPEN)
											}
											className={
												!!item.id &&
												(credState === CRED_STATE.CLOSED ||
													credState === CRED_STATE.PARTIALLY_OPEN)
													? classes.disabledInput
													: classes.normalInput
											}
											error={
												(errors?.trainings &&
													errors?.trainings[`${index}`]
														?.programDirectorFirstName) ||
												(!!highlights &&
													highlights.includes(
														`trainings[${index}].programDirectorFirstName`
													))
											}
											onBlur={() => {
												isEmptyForm(index);
												updateHighlight();
											}}
											onChange={() => isEmptyForm(index)}
											InputProps={{
												classes: {
													root: classes.cssOutlinedInput,
													focused: classes.cssFocused,
													notchedOutline: classes.notchedOutline,
													error: classes.error,
												},
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<InputLabel
											shrink
											htmlFor={`trainings[${index}].programDirectorLastName`}
											className={classes.inputLabel}
											error={
												(errors?.trainings &&
													errors?.trainings[`${index}`]
														?.programDirectorLastName) ||
												(!!highlights &&
													highlights.includes(
														`trainings[${index}].programDirectorLastName`
													))
											}
										>
											{t('credentials:TRAINING.PROGRAM_DIRECTOR_LAST_NAME')}
										</InputLabel>
										<TextField
											margin='dense'
											autoComplete='off'
											variant='outlined'
											fullWidth
											defaultValue={`${item.programDirectorLastName}`}
											name={`trainings[${index}].programDirectorLastName`}
											disabled={
												!!item.id &&
												(credState === CRED_STATE.CLOSED ||
													credState === CRED_STATE.PARTIALLY_OPEN)
											}
											className={
												!!item.id &&
												(credState === CRED_STATE.CLOSED ||
													credState === CRED_STATE.PARTIALLY_OPEN)
													? classes.disabledInput
													: classes.normalInput
											}
											error={
												(errors?.trainings &&
													errors?.trainings[`${index}`]
														?.programDirectorLastName) ||
												(!!highlights &&
													highlights.includes(
														`trainings[${index}].programDirectorLastName`
													))
											}
											inputRef={register({
												maxLength: {
													value: 100,
													message: t('common:MESSAGES.CHARACTER_LIMIT'),
												},
											})}
											helperText={
												(errors?.trainings &&
													errors?.trainings[`${index}`]
														?.programDirectorLastName &&
													errors?.trainings[`${index}`]
														?.programDirectorLastName['type'] ===
														'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`trainings[${index}].programDirectorLastName`
													))
													? t('common:MESSAGES.CHARACTER_LIMIT')
													: null
											}
											onBlur={() => {
												isEmptyForm(index);
												updateHighlight();
											}}
											onChange={() => isEmptyForm(index)}
											InputProps={{
												classes: {
													root: classes.cssOutlinedInput,
													focused: classes.cssFocused,
													notchedOutline: classes.notchedOutline,
													error: classes.error,
												},
											}}
										/>
									</Grid>
									<Grid
										item
										xs={1}
										style={{
											display: type === 'fellowships' ? 'block' : 'none',
										}}
									>
										<InputLabel
											shrink
											htmlFor={`trainings[${index}].research`}
											classes={{ root: classes.selectInputLabel }}
											error={
												(errors?.trainings &&
													errors?.trainings[`${index}`]?.research) ||
												(!!highlights &&
													highlights.includes(
														`trainings[${index}].research`
													))
											}
										>
											{t('credentials:TRAINING.RESEARCH')}
										</InputLabel>
										<Controller
											name={`trainings[${index}].research`}
											control={control}
											rules={{}}
											defaultChecked={`${item.research}`}
											render={({ onChange, onBlur, value, name }) => (
												<Checkbox
													color='primary'
													onChange={(e) => onChange(e.target.checked)}
													disabled={lockFields}
													checked={value}
													onBlur={() => {
														onBlur(
															isEmptyForm(index),
															updateHighlight()
														);
													}}
													name={name}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</form>
						</Grid>
					</div>
				</Container>
			</div>
		);
	};
	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}
		return fields.map((item, index) => (
			<div key={item.internalId}>
				{renderForm(item, index)}
				<RequiredQuestionDialog
					open={showDeleteModal}
					title={t(`credentials:TRAINING.CONFIRM_DELETE_TITLE_${constantType}`)}
					text={t(`credentials:TRAINING.CONFIRM_DELETE_MESSAGE_${constantType}`).replace(
						`(${type})`,
						`${type}`
					)}
					buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
					buttonIDs='Yes;No'
					disableBackgroundClick={false}
					onClose={(btnPressed) => {
						deleteEntry(btnPressed, index);
					}}
				/>
			</div>
		));
	};

	return (
		<>
			<div className={classes.pageWrapper}>
				<Grid className={classes.leftPad30}>
					<Grid item xs={12} md={12}>
						<h3 className={classes.header}>
							{t(`credentials:TRAINING.${constantType}_TITLE`)}
						</h3>
						{credState === CRED_STATE.CLOSED ||
						credState === CRED_STATE.PARTIALLY_OPEN ? (
							<Grid item xs={12} md={10} className={classes.messageGrid}>
								<Alert
									severity='error'
									className={classes.alertMessage}
									icon={false}
								>
									{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
								</Alert>
							</Grid>
						) : null}
						<h3 className={classes.infoMessage}>
							{t(`credentials:TRAINING.INFO_MESSAGE_${constantType}`)}
						</h3>
						{`${constantType}` === 'ADDITIONAL_TRAINING' ? (
							<h3 className={classes.infoMessage}>
								{t(`credentials:OTHER_CERTIFICATIONS.NON_REQUIRED`)}
							</h3>
						) : (
							<>
								{' '}
								<br />
								<br />
								<h3 className={classes.infoMessage}>
									{t(`credentials:OTHER_CERTIFICATIONS.NON_REQUIRED`)}
								</h3>
							</>
						)}
					</Grid>
					<Grid item xs={12} md={12}>
						{renderForms()}
					</Grid>
				</Grid>
				<Grid item xs={12} md={12}>
					{isLoaded && credState !== CRED_STATE.CLOSED ? (
						<div
							className={'credAppAddMoreButton'}
							onClick={() => {
								append({
									id: null,
									hospital: '',
									hospitalId: null,
									streetAddress: '',
									city: '',
									state: '',
									zipCode: '',
									country: '',
									specialty: '',
									completed: null,
									programDirectorSalutation: '',
									programDirectorFirstName: '',
									programDirectorLastName: '',
									isCurrentlyInRole: false,
									dateFrom: null,
									dateTo: null,
									expectedEndDate: null,
									research: false,
									isVerified: false,
									detailsRegardingOther: '',
								});
							}}
						>
							{t(`credentials:TRAINING.ADD_MORE_${constantType}`)}
						</div>
					) : null}
				</Grid>
			</div>
		</>
	);
}

export default TrainingForm;
