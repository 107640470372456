import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper, CircularProgress, Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS, DOCUMENT_TYPE } from '../../../utils/Application_Constants';
import {
	getClinicianQualification,
	getSkillsForSpecialties,
	postClinicianQualification,
} from '../../../services/ProfileServices/profileService';
import UploadClinicianDocument from '../Credentials/PageComponenets/DocumentComponents/UploadClinicianDocument';

const styles = (theme) => ({
	header: {
		color: COLORS.ST_TROPAZ,
		[theme.breakpoints.down('sm')]: {
			padding: '36px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '20px',
		fontSize: '17px',
		fontWeight: '700',
	},
	headerText: {},
	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '12px',
	},
	paperRoot: {
		paddingBottom: '60px',
		marginTop: '30px',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			marginLeft: '32px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	setWidthTo100OnMobile: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	profileWrapper: {
		paddingBottom: '50px',
		borderBottom: `1px solid ${COLORS.SEASHELL}`,
	},
});

const DocTypes = [
	{
		value: DOCUMENT_TYPE.CurriculumVitae,
		label: DOCUMENT_TYPE.CurriculumVitae,
	},
];

class ManageCV extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clinicianQualifications: {},
			skillMappingList: [],
			specialtyList: [],
			cvsSelected: [],
			flags: {
				targetTab: 0,
			},
		};
	}

	async componentDidMount() {
		const { externalId, setSaveHandler, setManageCVError } = this.props;
		const state = { ...this.state };

		await Promise.all([getClinicianQualification(externalId), getSkillsForSpecialties()])
			.then(async ([clinicianQualifications, skillMappingList]) => {
				state.clinicianQualifications = clinicianQualifications;
				state.skillMappingList = skillMappingList;
				state.specialtyList = [{ label: 'Select', value: null }];
				state.specialtyList = state.specialtyList.concat(
					Object.keys(state.skillMappingList)
						.reverse()
						.map((item) => ({
							label: item,
							value: item,
						}))
				);
				this.setState({
					clinicianQualifications: state.clinicianQualifications,
					skillMappingList: state.skillMappingList,
					specialtyList: state.specialtyList,
				});
			})
			.catch((e) => {
				setManageCVError(e);
			});
		setSaveHandler(this, this.doSubmit);
	}

	markFlagsDirty = () => {
		const flags = this.props.flags;
		flags.isDirty = true;
		flags.dataSaved = false;
		this.props.updateFlags(flags);
	};

	checkboxHandler = (event, index) => {
		const state = { ...this.state };
		const { name, checked } = event.target;
		state.clinicianQualifications.specialties[index][name] = checked;
		this.markFlagsDirty();
		this.setState({ ...state });
	};

	clearTags = (index) => {
		const state = { ...this.state };
		state.clinicianQualifications.specialties[index].skills = [];
		this.setState({ ...state });
	};

	autoCompleteHandler = (newValue, name, index) => {
		const state = { ...this.state };
		const newValues = [];
		newValue.forEach((item) => {
			if (typeof item === 'string') {
				newValues.push(item);
				return;
			}
			newValues.push(item.value);
		});

		if (state.clinicianQualifications.specialties[index][name] !== newValues) {
			state.clinicianQualifications.specialties[index][name] = newValues;
			this.markFlagsDirty();
		}
		this.setState({ ...state });
	};

	autoCompleteDelete = async (index, name, specialtyIndex) => {
		const state = { ...this.state };
		state.clinicianQualifications.specialties[specialtyIndex][name].splice(index, 1);
		this.markFlagsDirty();
		this.setState({ ...state });
	};

	specialitiesUpdateHandler = (specialities) => {
		const state = { ...this.state };
		state.clinicianQualifications.specialties = specialities;
		this.setState({ ...state });
	};

	certificationsUpdateHandler = (certifications) => {
		const state = { ...this.state };
		state.clinicianQualifications.certifications = certifications;
		this.setState({ ...state });
	};

	licensesUpdateHandler = (licenses) => {
		const state = { ...this.state };
		state.clinicianQualifications.stateLicenses = licenses;
		this.setState({ ...state });
	};

	async doSubmit() {
		// there are no required fields on the qualifications tab
		const state = { ...this.state };
		const { flags, setManageCVError } = this.props;

		flags.isPendingSave = true;
		this.props.updateFlags(flags);
		const qualificationPayload = this.stripIsNewFields(state.clinicianQualifications);
		await postClinicianQualification(qualificationPayload).catch((e) => {
			setManageCVError(e);
		});
		flags.dataSaved = true;
		flags.isDirty = false;
		flags.isPendingSave = false;
		this.props.updateFlags(flags);
	}

	stripIsNewFields(clinicianQualifications) {
		const newQualifications = { ...clinicianQualifications };
		newQualifications.specialties.forEach((item) => {
			if (item.isNew) {
				delete item.isNew;
			}
		});
		newQualifications.stateLicenses.forEach((item) => {
			if (item.isNew) {
				delete item.isNew;
			}
		});
		return newQualifications;
	}

	render() {
		const { classes, t } = this.props;
		const { clinicianQualifications, skillMappingList } = this.state;
		if (
			Object.keys(clinicianQualifications).length === 0 &&
			Object.keys(skillMappingList).length === 0
		) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}

		return (
			<>
				{/* <Paper classes={{ root: classes.paperRoot }}>
          <UploadDocument documentType="Curriculum Vitae" headerText={t('profile:QUALIFICATIONS.CV')} />
        </Paper> */}
				<Paper classes={{ root: classes.paperRoot }}>
					<Grid>
						<Grid item xs={12}>
							<h3 className={classes.header}>{t('tabs:PROFILE.MANAGE_CV')}</h3>
						</Grid>
						<Grid item xs={12} className={classes.gridRoot}>
							<UploadClinicianDocument docTypeList={DocTypes} showDocuments={true} />
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
}

ManageCV.propTypes = {
	classes: PropTypes.shape({
		headerText: PropTypes.string.isRequired,
	}).isRequired,
	t: PropTypes.func.isRequired,
	tabHandler: PropTypes.func.isRequired,
	flags: PropTypes.shape({
		missingData: PropTypes.bool.isRequired,
		dataSaved: PropTypes.bool.isRequired,
		isDirty: PropTypes.bool.isRequired,
		showSaveChanges: PropTypes.bool.isRequired,
		isPendingSave: PropTypes.bool.isRequired,
		targetTab: PropTypes.number.isRequired,
	}).isRequired,
	updateFlags: PropTypes.func.isRequired,
	setSaveHandler: PropTypes.func.isRequired,
	externalId: PropTypes.number.isRequired,
};

export default withTranslation()(withStyles(styles)(ManageCV));
