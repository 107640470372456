import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import ErrorIcon from '@material-ui/icons/Error';

import React, { Component } from 'react';

export class CheckBoxControl extends Component {
	render() {
		const { checked, missing } = this.props;
		return (
			<>
				<Checkbox
					checked={checked}
					icon={
						<CircleUnchecked
							style={{
								width: '20px',
								height: '20px',
							}}
						/>
					}
					checkedIcon={
						missing ? (
							<ErrorIcon
								style={{
									color: '#EA3219',
									width: '20px',
									height: '20px',
								}}
							/>
						) : (
							<CheckCircleIcon
								style={{
									color: '#009639',
									width: '20px',
									height: '20px',
								}}
							/>
						)
					}
					disableRipple
				/>
			</>
		);
	}
}

export default CheckBoxControl;
