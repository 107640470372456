import React, { Component } from 'react';
import { withStyles, Link } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../utils/Application_Constants';
import { postSendEmail } from '../../services/ProfileServices/profileService';
import UserContext from '../../contexts/userContext';
import IdleTimer from 'react-idle-timer';
import msSalService from '../../services/msSalService';
import Logo from '../../assets/logo/locumtenens_com_logo.svg';
import { getItemCookie } from '../../utils/helpers';

const styles = (theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		background: '#FFFFFF',
		fontFamily: 'sans-serif',
		color: '#757B92',
		fontSize: '1rem',
	},
	loginContainer: {
		textAlign: 'center',
		margin: '0 auto',
		padding: '70px',
		zIndex: '1',
	},
	divOne: {
		marginBottom: '50px',
	},
	divTwo: {
		textTransform: 'uppercase',
		background: '#0076BB',
		padding: '10px 20px',
		color: 'white',
		textDecoration: 'none',
		fontWeight: 'bold',
		marginTop: '70px',
		clear: 'both',
	},
	image: {
		width: '70%',
	},
	linkSend: {
		marginBottom: '40px',
	},
	buttonClass: {
		fontWeight: '700',
		width: '50%',
		background: COLORS.DENIM,
		color: 'White',
		justifyContent: 'center',
		borderRadius: '0px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
});

class VerificationPage extends Component {
	constructor(props, context) {
		super(props);
		this.context = context;
		this.state = {
			result: undefined,
			timeout: 1000 * 60 * 7,
			isTimedOut: false,
			action: 'verification',
		};

		this.idleTimer = null;
		this.onIdle = this._onIdle.bind(this);
	}

	_onIdle(e) {
		console.log('user is idle', e);
		const isTimedOut = this.state.isTimedOut;
		console.log('_onIdle isTimedOut ' + isTimedOut);

		if (isTimedOut) {
			console.log('_onIdle isTimedOut so Logout!!!');
			try {
				msSalService.setInitialRedirectUri();
				msSalService.logOut();
			} catch (error) {
				Error(error);
			}
		} else {
			this.idleTimer.reset();
			this.setState({ isTimedOut: true });
		}
	}

	_onAction(e) {
		console.log('user did something', e);
		this.setState({ isTimedOut: false });
	}

	_onActive(e) {
		console.log('user is active', e);
		this.setState({ isTimedOut: false });
	}

	componentDidMount() {
		const { account } = this.context;
		const { invitationSentDate } = this.context;
		var email;
		if (account.idToken.emails) {
			email = account.idToken.emails[0];
		}

		if (invitationSentDate === null || invitationSentDate === undefined) {
			if (localStorage.getItem('LocalStorage_EmailSent') !== 'true') {
				this.sendInitialEmail();
			}
		} else {
			localStorage.setItem('LocalStorage_EmailInvitation', 'true');
			//setLocalStorageWithExpiry('LocalStorage_EmailInvitation', 'true', 300000);
			localStorage.setItem(
				'LocalStorage_EmailInvitationToken',
				getItemCookie('msal.idtoken')
			);
			//setLocalStorageWithExpiry('LocalStorage_EmailInvitationToken', getItemCookie('msal.idtoken'), 300000);

			//localStorage.removeItem('msal.idtoken');
			msSalService.clearMSALCache();
			var reset_password_link = encodeURI(
				process.env.REACT_APP_B2C_BASE_URI +
					'/oauth2/v2.0/authorize?p=B2C_1A_UserName_SMS&client_id=' +
					process.env.REACT_APP_CLIENT_ID +
					'&scope=openid&response_type=id_token&prompt=login&login_hint=' +
					email +
					'&state=null&nonce=defaultNonce&redirect_uri=' +
					window.location.origin
			);

			this.setState({
				result: undefined,
				action: 'invitation',
				resetPasswordURL: reset_password_link,
			});
		}
	}

	render() {
		const { classes, t } = this.props;

		if (this.state.result === true) {
			return (
				<>
					<IdleTimer
						ref={(ref) => {
							this.idleTimer = ref;
						}}
						element={document}
						onActive={this.onActive}
						onIdle={this.onIdle}
						onAction={this.onAction}
						debounce={250}
						timeout={this.state.timeout}
					/>
					<div className={classes.container}>
						<div id='login-container' className={classes.loginContainer}>
							<img alt='LocumTenens Logo' src={Logo} className={classes.image} />
							<h2>Please confirm email</h2>
							<div className={classes.divOne}>
								<p>
									Our records indicate that you are eligible to access advanced
									features on our site.
								</p>

								<p>
									To gain access to these advanced features, please verify your
									email. If you have not received the email verification, resend
									it now by clicking{' '}
									<Link
										className={classes.clickableSpan}
										href='#'
										onClick={this.sendEmail}
									>
										here
									</Link>
									.
								</p>

								<p>
									If the problem persists, please email{' '}
									<a
										href='mailto: CustomerService@LocumTenens.com'
										className={classes.clickableSpan}
									>
										{' '}
										CustomerService@LocumTenens.com{' '}
									</a>{' '}
									or call us at{' '}
									<a
										href={t('common:INFORMATION.HELP_NUMBER_LINK')}
										className={classes.clickableSpan}
									>
										{t('common:INFORMATION.HELP_NUMBER')}
									</a>
									.
								</p>
							</div>
						</div>
					</div>
				</>
			);
		}

		if (this.state.result === undefined && this.state.action === 'verification') {
			return <div className={classes.container}></div>;
		}

		if (this.state.result === undefined && this.state.action === 'invitation') {
			window.location = this.state.resetPasswordURL;
		}

		if (this.state.result === false) {
			return (
				<>
					<IdleTimer
						ref={(ref) => {
							this.idleTimer = ref;
						}}
						element={document}
						onActive={this.onActive}
						onIdle={this.onIdle}
						onAction={this.onAction}
						debounce={250}
						timeout={this.state.timeout}
					/>
					<div className={classes.container}>
						<div id='login-container' className={classes.loginContainer}>
							<h2>Error occurred</h2>
							<div className={classes.divOne}>
								<p>
									An error occurred and we were unable to validate your email
									address.
								</p>

								<p>
									If the problem persists, please email{' '}
									<a
										href='mailto: CustomerService@LocumTenens.com'
										className={classes.clickableSpan}
									>
										{' '}
										CustomerService@LocumTenens.com{' '}
									</a>{' '}
									or call us at{' '}
									<a
										href={t('common:INFORMATION.HELP_NUMBER_LINK')}
										className={classes.clickableSpan}
									>
										{t('common:INFORMATION.HELP_NUMBER')}
									</a>
									.
								</p>
							</div>
						</div>
					</div>
				</>
			);
		}
	}

	sendInitialEmail = async () => {
		const { account } = this.context;

		try {
			if (account !== null && account.idToken.emails) {
				localStorage.setItem('LocalStorage_EmailSent', 'true');
				await postSendEmail(account.idToken.emails[0]).then((response) => {
					console.log('sendInitialEmail Response: ' + response);

					this.setState({
						result: response,
					});
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	sendEmail = async () => {
		const { account } = this.context;

		try {
			if (account !== null && account.idToken.emails) {
				await postSendEmail(account.idToken.emails[0]).then((response) => {
					console.log('SendEmail Response: ' + response);
					//Patch for TDS-2686 to maintain JobBoard Routing Information if this was used
					var aJobboardSignedIN = localStorage.getItem('LocalStorage_JobboardSignedIN');
					var aJobboardSignedPath = localStorage.getItem(
						'LocalStorage_JobboardSignedINPath'
					);
					//
					window.localStorage.clear();
					msSalService.clearAuthInfo();

					//Patch for TDS-2686 to maintain JobBoard Routing Information if this was used
					if (aJobboardSignedIN === 'true') {
						localStorage.setItem('LocalStorage_JobboardSignedIN', aJobboardSignedIN);
						localStorage.setItem(
							'LocalStorage_JobboardSignedINPath',
							aJobboardSignedPath
						);
					}
					//
					document.cookie.split(';').forEach(function (c) {
						document.cookie =
							c.trim().split('=')[0] + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
					});

					this.setState({
						result: response,
					});
				});
			} else {
				this.setState({
					result: false,
				});

				window.localStorage.clear();
				msSalService.clearAuthInfo();
			}

			this.setState({ isTimedOut: false, timeout: 1000 * 2 * 1 });
			this.idleTimer.reset();
		} catch (error) {
			console.error(error);
		}
	};
}
VerificationPage.contextType = UserContext;
export default withTranslation()(withStyles(styles)(VerificationPage));
