import { Grid, MenuItem, Typography, withStyles, Switch } from '@material-ui/core';
import React, { useEffect, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { COLORS } from '../../../utils/Application_Constants';
import { states } from '../../Configurations/ExpenseEntryConfig';
import { getClinician, getClinicianAssignments } from '../../Services/ExpenseService';
import { valueAsNumber, valueAsText } from '../../Utils/ValueAsUtils';
import BookingNumberInputAdornment from './BookingNumberInputAdornment';
import ExpenseAutocomplete from './ExpenseAutocomplete';
import ExpenseTextField from './ExpenseTextField';
import { BillTypeEnum } from '../Expense/BillType.enum';
import Alert from '../Alert';
import { toInteger } from 'lodash';

const styles = (theme) => ({
    formHeader: {
        borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
        paddingBottom: 8,
        marginBottom: 16,
    },
});

const BookingDetailsForm = (props) => {
    const { classes, index, expense, divisions, setIsSGA, mode,isSGA } = props;
    const { control, watch, errors, setValue, getValues, clearErrors } = useFormContext();
    const bookingNumber = watch(`expenseEntries[${index}].bookingId`);
    const expenseEntries = watch('expenseEntries');
    const billType = watch(`expenseEntries[${index}].billType`);
    const bookingNumberRequired = Boolean(
        billType &&
        (billType === BillTypeEnum.Billable || billType === BillTypeEnum.NonBillProducer)
    );
    const clinicianId = watch(`expenseEntries[${index}].clinicianId`);
    const primaryClinicianFirstName = watch('expenseEntries[0].clinicianFirstName');
    const primaryClinicianLastName = watch('expenseEntries[0].clinicianLastName');
    const primaryClinicianId = watch('expenseEntries[0].clinicianId');
    const [alert, setAlert] = useState();
    const isPrimary = index === 0;

    const toggleIsSGA = () => {
        //Backup Form Settings
        setValue(`expenseEntries[${index}].bookingType`, getValues(`expenseEntries[${index}].type`));
        setValue(`expenseEntries[${index}].bookingDivision`, getValues(`expenseEntries[${index}].division`));
        setValue(`expenseEntries[${index}].bookingCategory`, getValues(`expenseEntries[${index}].category`));
        setValue(`expenseEntries[${index}].bookingExpenseClass`, getValues(`expenseEntries[${index}].expenseClass`));
        //Blank Form Values
        setValue(`expenseEntries[${index}].type`, '');
        setValue(`expenseEntries[${index}].division`, '');
        setValue(`expenseEntries[${index}].category`, '');
        setValue(`expenseEntries[${index}].expenseClass`, '');

        isSGA[index] = !isSGA[index];
        setIsSGA(isSGA);
        clearErrors(`expenseEntries[${index}]`);
      };

    useEffect(() => {
        if (mode === "cc"){
            console.log(isSGA[index], `- BookingDetailsForm isSGA ${index} Has changed`)
            if (isSGA[index]){
                //TODO
            }else{
               //TODO
            }
        }
    },[isSGA[index]]) 
    

    // check if there are multiple entries
    const isMultiExpense = expenseEntries.length > 1;

    // check if the primary entries are changed
    const onPrimaryChange = useCallback(
        (key, value) => {
            // if primary changed && multiple entries -> show message
            if (isMultiExpense) {
                setAlert({ message: 'All transactions will be updated', type: 'warning' });
                // updated all expenses with primary value
                for (let i = 1; i < expenseEntries.length; i++) {
                    setValue(`expenseEntries[${i}].${key}`, value);
                }
            }
        },
        [isMultiExpense, expenseEntries, primaryClinicianLastName]
    );

    const autoPopulateBookingNumberFields = (bookingInfo) => {
        if (bookingInfo) {
            setValue(`expenseEntries[${index}].bookingId`, bookingInfo.id, {
                shouldValidate: true,
            });
            setValue(`expenseEntries[${index}].clinicianId`, bookingInfo.clinicianId, {
                shouldValidate: true,
            });
            setValue(
                `expenseEntries[${index}].clinicianFirstName`,
                bookingInfo.clinicianFirstName,
                {
                    shouldValidate: true,
                }
            );
            setValue(
                `expenseEntries[${index}].salesperson`,
                bookingInfo.salesperson.displayName);
            setValue(
                `expenseEntries[${index}].recruiter`,
                bookingInfo.recruiter.displayName);
            setValue(
                `expenseEntries[${index}].clinicianLastName`,
                bookingInfo.clinicianLastName,
                {
                    shouldValidate: true,
                }
            );
            setValue(`expenseEntries[${index}].clientId`, bookingInfo.facilityId, {
                shouldValidate: true,
            });

            let divName = divisions.filter(res => res.description === bookingInfo.division).map(ele => ele.name);
            if (divName.length == 1) {
                setValue(`expenseEntries[${index}].division`, divName[0], {
                    shouldValidate: true,
                });
            } else {
                setValue(`expenseEntries[${index}].division`, '', {
                    shouldValidate: true,
                });
            }

            setValue(`expenseEntries[${index}].state`, bookingInfo.location.state, {
                shouldValidate: true,
            });
        } else {
            setValue(`expenseEntries[${index}].bookingId`, '');
            setValue(`expenseEntries[${index}].clinicianId`, '');
            setValue(`expenseEntries[${index}].clinicianFirstName`, '');
            setValue(`expenseEntries[${index}].clinicianLastName`, '');
            setValue(`expenseEntries[${index}].clientId`, '');
            setValue(`expenseEntries[${index}].division`, '');
            setValue(`expenseEntries[${index}].state`, '');
            setValue(`expenseEntries[${index}].salesperson`, '');
            setValue(`expenseEntries[${index}].recruiter`, '');
        }
        if (isPrimary) onPrimaryChange('bookingId', bookingInfo?.id || '');
        if (isPrimary) onPrimaryChange('clinicianId', bookingInfo?.clinicianId || '');
        if (isPrimary) onPrimaryChange('clinicianFirstName', bookingInfo?.clinicianFirstName || '');
        if (isPrimary) onPrimaryChange('clinicianLastName', bookingInfo?.clinicianLastName ||  '');
        if (isPrimary) onPrimaryChange('clientId', bookingInfo?.facilityId || '');
        if (isPrimary) onPrimaryChange('division', bookingInfo?.division || '');
        if (isPrimary) onPrimaryChange('state', bookingInfo?.location?.state || '');
        if (isPrimary) onPrimaryChange('salesperson', bookingInfo?.salesperson?.displayName || '');
        if (isPrimary) onPrimaryChange('recruiter', bookingInfo?.recruiter?.displayName || '');
    };

    const autoPopulateClinicianFields = (clinicianInfo) => {
        if (clinicianInfo) {
            setValue(`expenseEntries[${index}].clinicianId`, clinicianInfo.id, {
                shouldValidate: true,
            });
            setValue(`expenseEntries[${index}].clinicianFirstName`, clinicianInfo.firstName, {
                shouldValidate: true,
            });
            setValue(`expenseEntries[${index}].clinicianLastName`, clinicianInfo.lastName, {
                shouldValidate: true,
            });
        } else {
            setValue(`expenseEntries[${index}].clinicianId`, '');
            setValue(`expenseEntries[${index}].clinicianFirstName`, '');
            setValue(`expenseEntries[${index}].clinicianLastName`, '');
        }
        if (isPrimary) onPrimaryChange('clinicianId', clinicianInfo?.id || '');
        if (isPrimary) onPrimaryChange('clinicianFirstName', clinicianInfo?.firstName || '');
        if (isPrimary) onPrimaryChange('clinicianLastName', clinicianInfo?.lastName || '');
    };

    const renderClinicianId = (onChange, value) =>
        bookingNumber ? (
            <ExpenseTextField
                id={`clinician-id`}
                inputProps={{ maxLength: 9 }}
                onChange={(e) => onChange(valueAsNumber(e.target.value))}
                value={value}
                disabled={Boolean(bookingNumber)}
                label={'Clinician ID'}
                placeholder='Clinician ID'
                required
                error={Boolean(errors.expenseEntries?.[index]?.clinicianId)}
                helperText={errors.expenseEntries?.[index]?.clinicianId?.message}
            />
        ) : (
            <ExpenseAutocomplete
                id={`clinician-id`}
                inputProps={{ maxLength: 9 }}
                onChange={(e) => {
                    const newValue = e ? '' + e.id : null;
                    if (isPrimary) onPrimaryChange('clinicianFirstName', newValue);
                    autoPopulateClinicianFields(e);
                    return onChange(newValue);
                }}
                value={value}
                label={'Clinician ID'}
                required
                error={Boolean(errors.expenseEntries?.[index]?.clinicianId)}
                helperText={errors.expenseEntries?.[index]?.clinicianId?.message}
                getOptionsMethod={getClinician}
                placeholder='Clinician ID'
                optionLabelKey='id'
                optionSelectedKey='id'
                valueAsMethod={valueAsNumber}
            />
        );

    return (
        <>
            <Typography variant='h5' component='h5' className={classes.formHeader}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        Booking Details
                    </Grid>
                    <Grid item xs={12} md={6}>
                    {mode === 'cc' && 
                        <>
                            Booking
                            <Switch
                                checked={isSGA[index]}
                                onChange={toggleIsSGA}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            SG&A
                        </>
                    }
                    </Grid>
                </Grid>
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].bookingId`}
                        defaultValue={expense.bookingId || null}
                        rules={{ required: bookingNumberRequired && 'Booking number is required' }}
                        render={({ onChange, value }) => (
                            <ExpenseAutocomplete
                                id={'booking-number'}
                                onChange={(e) => {
                                    const newValue = e ? '' + e.id : null;
                                    autoPopulateBookingNumberFields(e);
                                    return onChange(newValue);
                                }}
                                value={value}
                                label={'Booking number'}
                                required={bookingNumberRequired}
                                startAdornment={<BookingNumberInputAdornment />}
                                error={Boolean(errors.expenseEntries?.[index]?.bookingId)}
                                helperText={errors.expenseEntries?.[index]?.bookingId?.message}
                                minLength={6}
                                inputProps={{ maxLength: 50 }}
                                getOptionsMethod={getClinicianAssignments}
                                optionLabelKey='name'
                                optionSelectedKey='id'
                                valueAsMethod={valueAsNumber}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].clinicianId`}
                        defaultValue={expense.clinicianId}
                        rules={{
                            required: 'Clinician ID is required',
                            validate: {
                                match: (value) => index === 0 ||
                                        toInteger(value) === toInteger(primaryClinicianId) ||
                                        'Clinician IDs must match',
                            },
                        }}
                        render={({ onChange, value }) => renderClinicianId(onChange, value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].clinicianFirstName`}
                        defaultValue={expense.clinicianFirstName}
                        rules={{
                            required: !bookingNumber && 'Clinician first name is required',
                            validate: {
                                match: (value) =>
                                    index === 0 ||
                                    value === primaryClinicianFirstName ||
                                    'Clinician first names must match',
                            },
                        }}
                        render={({ onChange, value }) => (
                            <ExpenseTextField
                                id={'clinician-first-name'}
                                inputProps={{ maxLength: 50 }}
                                onChange={(e) => {
                                    if (isPrimary)
                                        onPrimaryChange('clinicianFirstName', e.target.value);
                                    return onChange(valueAsText(e.target.value));
                                }}
                                value={value}
                                label={'Clinician first name'}
                                required={true}
                                placeholder='Clinician first name'
                                disabled={Boolean(bookingNumber || clinicianId)}
                                error={Boolean(errors.expenseEntries?.[index]?.clinicianFirstName)}
                                helperText={
                                    errors.expenseEntries?.[index]?.clinicianFirstName?.message
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].clinicianLastName`}
                        rules={{
                            required: !bookingNumber && 'Clinician last name is required',
                            validate: {
                                match: (value) => {
                                    return (
                                        index === 0 ||
                                        value === primaryClinicianLastName ||
                                        'Clinician last names must match'
                                    );
                                },
                            },
                        }}
                        defaultValue={expense.clinicianLastName}
                        render={({ onChange, value }) => (
                            <ExpenseTextField
                                id={'clinician-last-name'}
                                inputProps={{ maxLength: 50 }}
                                onChange={(e) => {
                                    if (isPrimary)
                                        onPrimaryChange('clinicianLastName', e.target.value);
                                    return onChange(valueAsText(e.target.value));
                                }}
                                value={value}
                                label={'Clinician last name'}
                                required={true}
                                placeholder='Clinician last name'
                                disabled={Boolean(bookingNumber || clinicianId)}
                                error={Boolean(errors.expenseEntries?.[index]?.clinicianLastName)}
                                helperText={
                                    errors.expenseEntries?.[index]?.clinicianLastName?.message
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].clientId`}
                        defaultValue={expense.clientId}
                        render={({ onChange, value }) => (
                            <ExpenseTextField
                                id={'client-id'}
                                inputProps={{ maxLength: 50 }}
                                onChange={onChange}
                                value={value}
                                label='Client ID'
                                placeholder='Client ID'
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].division`}
                        defaultValue={expense.division}
                        rules={{ required: !bookingNumber && 'Division is required' }}
                        render={({ onChange, value }) => (
                            <ExpenseTextField
                                id={`division`}
                                select
                                onChange={onChange}
                                value={value}
                                label={'Division'}
                                required
                                disabled={Boolean(bookingNumber)}
                                error={Boolean(errors.expenseEntries?.[index]?.division)}
                                helperText={errors.expenseEntries?.[index]?.division?.message}
                            >
                                <MenuItem value='' disabled>
                                    Select a division
                                </MenuItem>
                                {divisions.map((option, index) => (
                                    <MenuItem
                                        key={option.name}
                                        value={option.name}
                                        id={`division-menu-option-${index}`}
                                    >
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </ExpenseTextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].salesperson`}
                        defaultValue={expense.salesperson}
                        render={({ value }) => (
                            <ExpenseTextField
                                id={'salesperson'}
                                inputProps={{ maxLength: 50 }}
                                value={value}
                                label='Salesperson'
                                placeholder='Salesperson'
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].recruiter`}
                        defaultValue={expense.recruiter}
                        render={({ value }) => (
                            <ExpenseTextField
                                id={'recruiter'}
                                inputProps={{ maxLength: 50 }}
                                value={value}
                                label='Recruiter'
                                placeholder='Recruiter'
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].state`}
                        defaultValue={expense.state}
                        render={({ onChange, value }) => (
                            <ExpenseTextField
                                id={'state-abbreviation'}
                                onChange={onChange}
                                select
                                value={value}
                                label={'State'}
                                disabled={Boolean(bookingNumber)}
                            >
                                <MenuItem disabled value=''>
                                    Please select a state
                                </MenuItem>
                                {states.map(({ name, value }, index) => (
                                    <MenuItem
                                        key={value}
                                        value={value}
                                        id={`state-abbreviation-menu-option-${index}`}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </ExpenseTextField>
                        )}
                    />
                </Grid>
            </Grid>
            <Alert alert={alert} setAlert={setAlert} />
        </>
    );
};

export default withStyles(styles)(BookingDetailsForm);
