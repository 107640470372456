import {
	Button,
	CircularProgress,
	FormHelperText,
	Grid,
	Paper,
	makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import ExpensesSourceRadioGroup from './ExpensesSourceRadioGroup';
import { FormProvider, useForm } from 'react-hook-form';
import ExpensesUploadInput from './ExpensesUploadInput';
import { uploadExpensesFile } from '../../Services/ExpenseService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Alert from '../Alert';

const useStyles = makeStyles((theme) => ({
	PaperRoot: {
		padding: 24,
		display: 'flex',
		minHeight: 300,
		alignItems: 'center',
	},
	form: {
		width: '100%',
	},
}));

const ExpensesUpload = () => {
	const classes = useStyles();
	const methods = useForm();
	const { handleSubmit, errors } = methods;
	const [alert, setAlert] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();

	const onSubmit = async (data) => {
		setIsLoading(true);
		try {
			const formData = new FormData();
			for (const key in data) {
				formData.append(key, data[key]);
			}
			await uploadExpensesFile(formData);
			history.push('/expenses/review');
		} catch (error) {
			const errorAsArray = !!error.errors && Array.isArray(error.errors);
			const errorAsString = typeof error === 'string';
			const message = errorAsArray
				? `Error: ${error.errors.map(({ errorMessage }) => errorMessage)}`
				: errorAsString
				? error
				: 'An error has occurred.';
			setAlert({
				message,
				type: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Paper classes={{ root: classes.PaperRoot }}>
			{isLoading && <CircularProgress color='primary' />}
			{!isLoading && (
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<ExpensesUploadInput />
								<FormHelperText error>
									{errors && errors.file && errors.file.message}
								</FormHelperText>
							</Grid>
							<Grid item xs={12}>
								<ExpensesSourceRadioGroup />
							</Grid>
							<Grid item xs={12}>
								<Button type='submit' variant='contained' color='primary'>
									Upload
								</Button>
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			)}
			<Alert alert={alert} setAlert={setAlert} />
		</Paper>
	);
};

export default ExpensesUpload;
