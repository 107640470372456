import React, { Component } from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { COLORS } from '../../utils/Application_Constants';
import WithContexts from '../../contexts/withContexts';
import UserContext from '../../contexts/userContext';
import DashboardL1 from './DashboardL1';
import DashboardL2 from './DashboardL2';

const styles = (theme) => ({
	root: {
		backgroundColor: COLORS.BLACK_HAZE,
		paddingBottom: '30px',
		minHeight: '800px',
	},
	activeAssignmentRoot: {
		marginTop: '30px',
		paddingTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	upcomingAssignmentRoot: {
		marginTop: '30px',
		paddingTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		marginTop: '25vh',
	},
});
class DashboardWrapper extends Component {
	constructor(props, context) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName('');
		this.state = {
			userLevelHasProcessed: false,
		};
		this.context = context;

		var intervalID = setInterval(
			function (parent) {
				//Check to see if the promised has finished retrieving the userLevel
				if (parent.context.userLevel.isFulfilled()) {
					//callback to parent to inform of completion
					parent.userLevelFulfilled();
					//stop polling
					clearInterval(intervalID);
				}
			},
			250,
			this
		);
	}

	userLevelFulfilled() {
		this.setState({ userLevelHasProcessed: true });
	}

	render() {
		const { classes } = this.props;
		const { userLevelHasProcessed } = this.state;

		if (userLevelHasProcessed === false) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}

		if (userLevelHasProcessed === true) {
			return (
				<>
					{this.context.userLevel.value() === 1 ? (
						<DashboardL1 classes={classes} />
					) : (
						<DashboardL2 classes={classes} />
					)}
				</>
			);
		}
	}
}

DashboardWrapper.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
	}).isRequired,
};

DashboardWrapper.contextType = UserContext;
export default WithContexts(withStyles(styles)(DashboardWrapper));
