const examTypes = [
	{
		value: 'COMLEX',
		label: 'COMLEX',
	},
	{
		value: 'FLEX',
		label: 'FLEX',
	},
	{
		value: 'LMCC',
		label: 'LMCC',
	},
	{
		value: 'National Boards',
		label: 'National Boards',
	},
	{
		value: 'NBME',
		label: 'NBME',
	},
	{
		value: 'NBOME',
		label: 'NBOME',
	},
	{
		value: 'Other',
		label: 'Other',
	},
	{
		value: 'SPEX',
		label: 'SPEX',
	},

	{
		value: 'State Board Exam',
		label: 'State Board Exam',
	},

	{
		value: 'USMLE',
		label: 'USMLE',
	},

	{
		value: 'NCLEX',
		label: 'NCLEX',
	},

	{
		value: 'PANCE',
		label: 'PANCE',
	},
];
export { examTypes };
