import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { reactPlugin } from '../../../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBoundaryPage from '../../../ContentComponents/Credentials/PageComponenets/ErrorBoundaryPage';
import DocumentsForm from './DocumentComponents/DocumentsForm';
const styles = (theme) => ({
	pagePadding: {
		width: '100%',
		paddingBottom: '20px',
	},
});

export class Documents extends Component {
	render() {
		const {
			classes,
			activePath,
			flags,
			setSaveHandler,
			blockedBackNavigation,
			shouldHighlightFields,
			updateHighlightFieldsState,
			updateFlags,
			segment,
			titles,
			credState,
			sectionCompletion,
			updateSectionCompletion,
		} = this.props;
		return (
			<AppInsightsErrorBoundary
				onError={() => (
					<ErrorBoundaryPage errorCode='500' flags={flags} updateFlags={updateFlags} />
				)}
				appInsights={reactPlugin}
			>
				<div key={activePath} className={classes.pagePadding}>
					<DocumentsForm
						activePath={activePath}
						segment={segment}
						titles={titles}
						setSaveHandler={setSaveHandler}
						flags={flags}
						credState={credState}
						updateFlags={updateFlags}
						shouldHighlightFields={shouldHighlightFields}
						updateHighlightFieldsState={updateHighlightFieldsState}
						blockedBackNavigation={blockedBackNavigation}
						sectionCompletion={sectionCompletion}
						updateSectionCompletion={updateSectionCompletion}
					/>
				</div>
			</AppInsightsErrorBoundary>
		);
	}
}

export default withStyles(styles)(Documents);
