import React, { Component } from 'react';
import { withStyles, Grid, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FacilityContacts from '../FacilityContacts/FacilityContacts';
import ActiveAssignmentList from '../ActiveAssignmentList/ActiveAssignmentList';
import LocumTenensContacts from '../LocumTenensContacts/LocumTenensContacts';
import LocumTenensAfterHourSupportContact from '../LocumTenensContacts/LocumTenensAfterHourSupportContact';
import ActiveAssignmentDetailView from '../ActiveAssignmentDetailView/ActiveAssignmentDetailView';
import { getPresents } from '../../../services/PresentsService';
import UserContext from '../../../contexts/userContext';
import PresentsWidgetComponent from '../PresentsWidgetComponent/PresentsWidgetComponent';
import { getAssignments } from '../../../services/AssignmentsService';

const styles = () => ({});

class ActiveAssignments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listView: true,
			selectedAssignmentId: '',
			assignments: [],
			url: {
				location: 'active',
				id: null,
			},
			isLoading: true,
		};
	}

	async componentDidMount() {
		await this.getUrlParams();
		const { externalId } = this.context;
		const { setAssignmentPageError } = this.props;

		await Promise.all([getPresents(externalId), getAssignments(externalId, 'active', true)])
			.then(([presents, assignments]) => {
				this.setState({
					presents: presents,
					assignments: assignments,
					isLoading: false,
				});
			})
			.catch((e) => {
				setAssignmentPageError(e);
			});
	}

	async componentDidUpdate(prevProps, prevState) {
		const { match } = this.props;
		if (prevState.url.id !== match.params.id) {
			await this.getUrlParams();
		}
	}

	getUrlParams() {
		const { match } = this.props;
		this.setState({ url: { ...match.params } });
	}

	updateActiveAssignments = async () => {
		await this.setState({ isLoading: true });
		const { externalId } = this.context;
		const { setAssignmentPageError } = this.props;
		const assignments = await getAssignments(externalId, 'active', true).catch((e) => {
			setAssignmentPageError(e);
		});
		this.setState({
			assignments: assignments,
			isLoading: false,
		});
	};

	toggleAssignmentView = (assignmentId) => {
		const state = { ...this.state };
		const { history } = this.props;
		state.url.id = assignmentId;
		history.push(`/assignments/active/${assignmentId}`);
		this.setState({ url: state.url });
	};

	getAssignmentBasedOnId = () => {
		const { url, assignments } = this.state;
		const assignment = assignments.filter((item) => item.id.toString() === url.id.toString());
		return assignment[0];
	};

	renderAssignmentView = () => {
		const { url, assignments } = this.state;
		const { externalId } = this.context;
		if (!url.id) {
			return (
				<ActiveAssignmentList
					assignments={assignments}
					toggleAssignmentView={this.toggleAssignmentView}
				/>
			);
		}
		const currentAssignment = this.getAssignmentBasedOnId();
		return (
			<ActiveAssignmentDetailView assignment={currentAssignment} externalId={externalId} />
		);
	};

	renderDetailViewWidgets = () => {
		const currentAssignment = this.getAssignmentBasedOnId();
		if (!currentAssignment) return null;
		return (
			<>
				{this.renderFacilityContact(
					currentAssignment.facilityId,
					currentAssignment.facilityContacts,
					currentAssignment.displayName
				)}
				{this.renderLTContact(currentAssignment.locumtenensContact)}
				{this.renderLTAfterHourSupportContact()}
			</>
		);
	};

	renderFacilityContact = (facilityId, contacts, primaryWorkLocation) => {
		if (!facilityId) return null;
		if (contacts && Array.isArray(contacts) && contacts.length > 0) {
			return (
				<FacilityContacts
					facilityId={facilityId}
					contacts={contacts}
					primaryWorkLocation={primaryWorkLocation}
					updateAssignments={this.updateActiveAssignments}
				/>
			);
		}
		return (
			<FacilityContacts
				facilityId={facilityId}
				contacts={[]}
				updateAssignments={this.updateActiveAssignments}
			/>
		);
	};

	renderLTContact = (contacts) => {
		if (!!contacts) {
			return <LocumTenensContacts contacts={contacts} />;
		} else {
			return null;
		}
	};

	renderLTAfterHourSupportContact = () => {
		return <LocumTenensAfterHourSupportContact />;
	};

	renderWidgetsBasedOnView = () => {
		const { url } = this.state;
		const { presents } = this.state;
		if (url.id) {
			return this.renderDetailViewWidgets();
		}
		return <PresentsWidgetComponent isFirst presents={presents} />;
	};

	render() {
		const { isLoading } = this.state;
		if (isLoading) {
			return (
				<div>
					<CircularProgress color='primary' />
				</div>
			);
		}
		return (
			<>
				<Grid container spacing={4}>
					<Grid item xs={12} md={8}>
						{this.renderAssignmentView()}
					</Grid>
					<Grid item xs={12} md={4}>
						{this.renderWidgetsBasedOnView()}
					</Grid>
				</Grid>
			</>
		);
	}
}
ActiveAssignments.contextType = UserContext;

ActiveAssignments.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
			status: PropTypes.string.isRequired,
		}),
	}).isRequired,
};

export default withRouter(withStyles(styles)(ActiveAssignments));
