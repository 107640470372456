import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	Typography,
	withStyles,
} from '@material-ui/core';
import { PriorityHigh } from '@material-ui/icons';
import React from 'react';
import { COLORS } from '../../utils/Application_Constants';

const styles = (theme) => ({
	dialogPaperRoot: {
		padding: '40px',
		gap: '24px',
	},
	dialogTitleRoot: {
		color: COLORS.LT_FOUNDATION,
		fontSize: '24px',
		fontWeight: '700',
		padding: '0',
	},
	dialogContentRoot: {
		padding: '0',
		'&:first-child': {
			paddingTop: '0',
		},
	},
	dialogGrid: {
		gap: '24px',
	},
	dialogActionsRoot: {
		padding: '0',
		justifyContent: 'center',
	},
	dialogErrorIcon: {
		color: COLORS.LT_PEPPER,
		background: COLORS.LT_PEPPER10,
		borderRadius: '50%',
		padding: '16px',
	},
});

const StatusErrorDialog = (props) => {
	const { classes, message, onClose, onExited, open, name } = props;
	return (
		<Dialog
			open={open}
			onClose={onClose}
			TransitionProps={{ onExited }}
			aria-labelledby={`${name}-dialog-title`}
			aria-describedby={`${name}-dialog-description`}
			fullWidth
			maxWidth='xs'
			classes={{ paper: classes.dialogPaperRoot }}
		>
			<DialogContent classes={{ root: classes.dialogContentRoot }}>
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignItems='center'
					className={classes.dialogGrid}
				>
					<Grid item>
						<Grid container alignContent='center' justifyContent='center'>
							<PriorityHigh
								fontSize='large'
								className={classes.dialogErrorIcon}
							></PriorityHigh>
						</Grid>
					</Grid>
					<Grid item>
						<Typography variant='h5' align='center'>
							{message}
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions classes={{ root: classes.dialogActionsRoot }}>
				<Button variant='contained' color='primary' onClick={onClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default withStyles(styles)(StatusErrorDialog);
