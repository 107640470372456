import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
} from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	dialogPaperRoot: {
		padding: '40px',
		gap: '16px',
	},
	dialogTitleRoot: {
		color: COLORS.LT_FOUNDATION,
		fontSize: '24px',
		fontWeight: '700',
		padding: '0',
	},
	dialogContentRoot: {
		padding: '0',
		'&:first-child': {
			paddingTop: '0',
		},
	},
	dialogGrid: {
		gap: '24px',
	},
	dialogActionsRoot: {
		padding: '0',
	},
	underlinedButton: {
		textDecoration: 'underline',
	},
	dialogErrorIcon: {
		color: COLORS.LT_PEPPER,
		background: COLORS.LT_PEPPER10,
		borderRadius: '50%',
		padding: '16px',
	},
	dialogWarningIcon: {
		color: COLORS.LT_SUNFLOWER,
		background: COLORS.LT_SUNFLOWER25,
		borderRadius: '50%',
		padding: '16px',
	},
}));

const CancelDialog = (props) => {
	const { open, setOpen, nextPath } = props;
	const classes = useStyles();

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby='cancel-modal-title'
			aria-describedby='cancel-modal-description'
			classes={{ paper: classes.dialogPaperRoot }}
			fullWidth
			maxWidth='xs'
		>
			<DialogTitle
				classes={{ root: classes.dialogTitleRoot }}
				disableTypography
				id='cancel-modal-title'
			>
				Unsaved changes
			</DialogTitle>
			<DialogContent
				id='cancel-modal-description'
				classes={{ root: classes.dialogContentRoot }}
			>
				Your changes have not been saved. Are you sure you want to leave this page with
				unsaved changes?
			</DialogContent>
			<DialogActions classes={{ root: classes.dialogActionsRoot }}>
				<Button
					component={RouterLink}
					to={{ pathname: nextPath }}
					color='primary'
					className={classes.underlinedButton}
				>
					Discard changes
				</Button>
				<Button variant='contained' color='primary' onClick={() => setOpen(false)}>
					Stay
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CancelDialog;
