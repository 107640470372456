/**
 * @file Input fields in the artifact differ than what material ui gives us, this is the custom
 * Textfield wrapper letting us leverage material ui with custom CSS.
 */
import { withStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { COLORS } from '../../../utils/Application_Constants';

const DatePickerComponent = withStyles((theme) => ({
	root: {
		'& .MuiInputLabel-root': {
			marginTop: theme.spacing(2),
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: '0px',
		},
		'& .MuiInputBase-root': {
			borderRadius: '0px',
			position: 'relative',
			width: '100%',
			padding: '12',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			'& input :focus': {},
		},
		overrides: {
			MuiInput: {
				borderRadius: '0px',
				underline: {
					'&:after': {
						content: 'none',
					},
					'&:before': {
						content: 'none', // String should be terminated
					},
				},
			},
		},
		'& svg': {
			fill: COLORS.LT_JOURNEY,
		},
	},

	MuiInputLabel: {
		formControl: {
			transform: 'none',
			fontSize: '32px',
		},
	},
}))(KeyboardDatePicker);

export default DatePickerComponent;
