/**
 * @file since we're handling pagination on the front end,
 * this service serves as a parser to mutate the api call
 * to a pagination capable resource.
 */
import { APP_CONFIG } from '../utils/Application_Constants';

function getListByOffset(list = [], offset) {
	const offsetEnd = offset + APP_CONFIG.PAGINATION_LIMIT;
	return list.slice(offset, offsetEnd);
}
function getListOffsetByPage(page) {
	return (page - 1) * APP_CONFIG.PAGINATION_LIMIT;
}
export { getListByOffset, getListOffsetByPage };
