import React from 'react';
import UserContext from './userContext';
import PageContext from './pageContext';

/**
 * @description higher order component that passes contexts down to children
 */
export const WithContexts = (Component) => (props) => (
	<UserContext.Consumer>
		{(user) => (
			<PageContext.Consumer>
				{(page) => <Component UserContext={user} PageContext={page} {...props} />}
			</PageContext.Consumer>
		)}
	</UserContext.Consumer>
);

export default WithContexts;
