/**
 * @file This is the route configuration file for all the protected routes
 * in the application.
 */
import Jobboard from '../pages/Jobboard/Jobboard';
import Profile from '../pages/Profile/Profile';
import TimeAndPayWrapper from '../pages/TimeAndPay/TimeAndPayWrapper';
import Timesheets from '../pages/TimeAndPay/Timesheets';
import Assignments from '../pages/Assignments/Assignments';
import Credentials from '../pages/Credentials/Credentials';
import AccountPage from '../pages/Profile/AccountPage';
import ManageCVPage from '../pages/Profile/ManageCVPage';
import CredReAttestation from '../pages/Credentials/CredReAttestation';
import DashboardWrapper from '../pages/Dashboard/DashboardWrapper';
import TimesheetReview from '../pages/TimeAndPay/TimesheetReview';
import TimesheetSubmit from '../pages/TimeAndPay/TimesheetSubmit';
import Timesheet from '../pages/TimeAndPay/Timesheet';
import TimesheetSave from '../pages/TimeAndPay/TimesheetSave';

const routes = [
	{
		path: '/dashboard',
		component: DashboardWrapper,
	},
	{
		path: '/jobboard',
		component: Jobboard,
	},
	{
		path: '/profile/:status/:id?',
		component: Profile,
	},
	{
		path: '/timeandpay',
		component: TimeAndPayWrapper,
	},
	{
		path: '/timesheets',
		component: Timesheets,
	},
	{
		path: '/timesheet/:id?/review',
		component: TimesheetReview,
	},
	{
		path: '/timesheet/:id?/submit',
		component: TimesheetSubmit,
	},
	{
		path: '/timesheet/:id?/view',
		component: Timesheet,
		mode: 'view',
	},
	{
		path: '/timesheet/:id?/edit',
		component: Timesheet,
		mode: 'edit',
	},
	{
		path: '/timesheet/duplicate',
		component: Timesheet,
		mode: 'duplicate',
	},
	{
		path: '/timesheet/create',
		component: Timesheet,
		mode: 'create',
	},
	{
		path: '/timesheet/save',
		component: TimesheetSave
	},
	{
		path: '/assignments/:status/:id?',
		component: Assignments,
	},
	{
		path: '/credentials',
		component: Credentials,
	},
	{
		path: '/account',
		component: AccountPage,
	},
	{
		path: '/manage-cv',
		component: ManageCVPage,
	},
	{
		path: '/reattestation',
		component: CredReAttestation,
	},
];

export default routes;
