import React, { Component } from 'react';
import {
	withStyles,
	Grid,
	Paper,
	Button,
	Hidden,
	FormControl,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import TextFieldComponent from '../../GlobalComponents/TextFieldComponent/TextFieldComponent';

import { getJobSearchUrl } from '../../../services/jobSearchService';
import ClinicianImg from '../../../assets/images/job-search-image.svg';

const styles = (theme) => ({
	root: {
		padding: '80px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		backgroundColor: COLORS.BLACK_HAZE,
	},

	headerL1: {
		color: COLORS.LT_JOURNEY25,
	},
	headerL2: {
		color: COLORS.LT_MIDNIGHT,
		textAlign: 'left',
	},

	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
	},
	// setWidthTo100OnMobile: {
	//     width: '280px',
	//     [theme.breakpoints.down('sm')]: {
	//         width: '100%',
	//     },
	//     [theme.breakpoints.down('md')]: {
	//         width: '100%',
	//     },
	// },
	// setWidthTo100OnMobileLevel1: {
	//     width: '500px',
	//     [theme.breakpoints.down('sm')]: {
	//         width: '100%',
	//     },
	//     [theme.breakpoints.down('md')]: {
	//         width: '100%',
	//     },
	// },
	paperL2: {
		// marginTop: '2rem',
		padding: '1.5rem',
	},
	paperL1: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		//backgroundColor:COLORS.RICH_ELECTRIC_BLUE,
		background:
			'transparent linear-gradient(146deg, #0077C8 0%, #003C64 100%) 0% 0% no-repeat padding-box',
		padding: '2rem',
	},
	buttonRoot: {
		lineHeight: 'inherit',
		margin: '0 30px 20px',
		padding: 0,
		width: '100%',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
	},
	helpPanelContainerRoot: {
		// padding: '20px',
		// display: 'flex',
		// flexWrap: 'wrap',
	},
	helpPanelHeader: {
		marginBottom: '10px',
	},
	helpPanelLinks: {
		textAlign: 'left',
		marginBottom: '10px',
	},
	helpPanelButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		borderLeft: `1px solid ${COLORS.WHITE_LILAC}`,
		width: '100%',
		borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
		paddingBottom: '10px',
		textTransform: 'inherit',
	},
	helpPanelButtonLevel1: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		textTransform: 'inherit',
		backgroundColor: '#CBE3F4',
		color: COLORS.LT_JOURNEY,
	},
	linkHeader: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '700',
		fontSize: '18px',
		margin: 0,
		textAlign: 'left',
	},
	linkHeaderTile: {
		color: '#CBE3F4',
		fontWeight: '700',
		fontSize: '24px',
		margin: 0,
		textAlign: 'left',
	},
});
class QuickSearchWidget extends Component {
	constructor(props) {
		super(props);
		this.state = { jobSearchString: '' };
		//this.handleChange = this.handleChange.bind(this);
		//this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange = (event) => {
		this.setState({ jobSearchString: event.target.value });
	};

	handleSubmit = async (event) => {
		//To prevent page from refresh after job seach
		event.preventDefault();
		const keywords = encodeURI(this.state.jobSearchString);
		const jobSearchResponse = await getJobSearchUrl(keywords);
		console.log(' process.env.REACT_APP_LT_API_URI ' + process.env.REACT_APP_LT_API_URI);
		const jobSearchUrl = `${process.env.REACT_APP_LT_API_URI}/Resources/Security/Login?url=${process.env.REACT_APP_LT_API_URI}${jobSearchResponse}`;

		window.open(jobSearchUrl, '_self');
	};
	render() {
		const { classes, t, title, level1 } = this.props;
		return (
			<>
				<Grid item xs={12}>
					<Paper className={level1 ? classes.paperL1 : classes.paperL2} align='left'>
						<Grid container direction='row'>
							<Grid container item sm={level1 ? '8' : '12'}>
								<Grid item xs={12}>
									{level1 ? (
										<h3 className={classes.headerL1}>{title}</h3>
									) : (
										<h3 className={classes.headerL2}>{title}</h3>
									)}
								</Grid>
								<Grid item xs={12} classes={{ root: classes.helpPanelLinks }}>
									<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
										<TextFieldComponent
											id='quickJobSearch'
											onChange={this.handleChange}
											name='quickJobSearch'
											placeholder={t(
												'widgets:JOB_SEARCH.DEFAULT_SEARCH_LABEL'
											)}
											className={
												level1
													? classes.setWidthTo100OnMobileLevel1
													: classes.setWidthTo100OnMobile
											}
											value={this.state.jobSearchString}
											fullWidth
										/>
									</FormControl>
								</Grid>
								<Button
									className={
										level1
											? classes.helpPanelButtonLevel1
											: classes.helpPanelButton
									}
									variant='contained'
									color='primary'
									onClick={this.handleSubmit}
								>
									{t('widgets:JOB_SEARCH.SEARCH_JOBS_BTN')}
								</Button>
							</Grid>
							<Hidden only='xs'>
								{level1 ? (
									<Grid item sm={4} xs={12} align='center'>
										<img src={ClinicianImg} height='160px' alt='clinician'></img>
									</Grid>
								) : null}
							</Hidden>
						</Grid>
					</Paper>
				</Grid>
			</>
		);
	}
}
export default withTranslation()(withStyles(styles)(QuickSearchWidget));
