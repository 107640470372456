import { Grid, Hidden, makeStyles } from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../utils/Application_Constants';

const useStyles = makeStyles((theme) => ({
	headerRow: {
		display: 'flex',
		backgroundColor: COLORS.LT_MIDNIGHTBG1,
		borderRadius: '8px',
	},
	headerCell: {
		padding: '12px 16px',
		display: 'flex',
		alignItems: 'center',
		'&:first-child': {
			paddingLeft: '24px',
		},
		'&:last-child': {
			paddingRight: '24px',
			justifyContent: 'flex-end',
		},
	},
	paragraph3: {
		display: 'flex',
		alignItems: 'center',
		color: COLORS.LT_MIDNIGHT50,
		fontSize: '12px',
		lineHeight: '18px',
		whiteSpace: 'nowrap',
	},
}));

const TableHeader = (props) => {
	const classes = useStyles();
	const { tableColumns } = props;

	return (
		<Hidden only={['xs', 'sm']}>
			<Grid container className={classes.headerRow}>
				{tableColumns.map(({ name, size }) => {
					return (
						<Grid item xs={size} key={name} className={classes.headerCell}>
							<div className={classes.paragraph3}>{name}</div>
						</Grid>
					);
				})}
			</Grid>
		</Hidden>
	);
};

export default TableHeader;
