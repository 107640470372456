import React, { useContext } from 'react';
import { ClientAuthContext } from '../Contexts/ClientAuthContext';
import { AppBar, Hidden, withStyles } from '@material-ui/core';
import MobileNavbar from '../../components/HeaderComponents/MobileNavbar/MobileNavbar';
import DesktopNavigation from '../../components/HeaderComponents/DesktopNavigation/DesktopNavigation';

const styles = (theme) => ({
	root: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		borderRadius: 3,
		boxShadow: '0px 3px 12px #0000000D',
		color: 'black',
		height: '60px',
		zIndex: 1300,
		[theme.breakpoints.up('md')]: {
			height: '80px',
		},
	},
	filler: {
		height: '80px',
		[theme.breakpoints.down('sm')]: {
			height: '60px',
		},
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		marginTop: '25vh',
	},
});

const navigation = {
	header: [
		{
			name: 'Timesheet Management',
			isParentClickable: true,
			parentUrlLink: '/timesheets',
			children: [],
			id: 1,
		},
	],
};

const ClientNavbar = (props) => {
	const { classes } = props;
	const { isAuthenticated } = useContext(ClientAuthContext);

	return (
		<>
			<AppBar className={classes.root}>
				<Hidden mdUp>
					<MobileNavbar menuNavigation={isAuthenticated ? navigation.header : []} />
				</Hidden>
				<Hidden smDown>
					<DesktopNavigation menuNavigation={isAuthenticated ? navigation.header : []} />
				</Hidden>
			</AppBar>
			<div className={classes.filler} />
		</>
	);
};

export default withStyles(styles)(ClientNavbar);
