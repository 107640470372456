import React from 'react';
import WithContexts from '../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';

const styles = (theme) => ({
	text: {
		color: COLORS.DENIM,
		fontWeight: '700',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
		},
		buttonWrapper: {
			borderTop: `1px solid ${COLORS.SEASHELL}`,
			display: 'flex',
			justifyContent: 'flex-end',
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'center',
			},
		},
		button: {
			marginRight: '42px',
			marginTop: '25px',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				margin: '20px 32px',
			},
		},
	},
});

function RequiredQuestionDialog({
	classes,
	t,
	open,
	title,
	text,
	buttonTexts,
	buttonIDs,
	onClose,
	disableBackgroundClick,
}) {
	const renderButtons = (buttonText, buttonIds) => {
		// alert(' From  RequiredQuestionDialog ')
		const buttonArr = buttonText.split(';');
		const buttonIdArr = buttonIds.split(';');
		return (
			<>
				{buttonArr.map((btnName, index) => (
					<Grid key={index} item classes={{ root: classes.saveButtonWrapper }}>
						<Button
							onClick={() => handleClose(buttonIdArr[index])}
							variant='contained'
							color='primary'
							id={'dialog-btn-' + index}
							className={classes.button}
						>
							{btnName}
						</Button>
					</Grid>
				))}
			</>
		);
	};

	const handleClose = (buttonPressed) => {
		onClose(buttonPressed);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			className={classes.text}
			disableBackdropClick={disableBackgroundClick}
			disableEscapeKeyDown={true}
		>
			{title !== '' ? <DialogTitle>{title}</DialogTitle> : ''}
			<DialogContent>
				<DialogContentText>{text}</DialogContentText>
			</DialogContent>
			<DialogActions>{renderButtons(buttonTexts, buttonIDs)}</DialogActions>
		</Dialog>
	);
}

RequiredQuestionDialog.propTypes = {
	classes: PropTypes.shape({
		text: PropTypes.string.isRequired,
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired,
	title: PropTypes.string,
	buttonTexts: PropTypes.string.isRequired,
	buttonIDs: PropTypes.string.isRequired,
};

export default WithContexts(withTranslation()(withStyles(styles)(RequiredQuestionDialog)));
