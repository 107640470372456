import { axiosWCApiInstance } from '../Configurations/AxiosConfiguration.js';
import { config } from '../../Client/Configurations/TimesheetReviewConfig';

const isClientApp = process.env.REACT_APP_TYPE === 'Client';

const getUrlParams = ({
	offset,
	sortBy,
	sortDirection,
	limit,
	statuses,
	divisions,
	selectedStartDate,
	selectedEndDate,
	search,
	searchKey,
}) => {
	const noClientStatuses = isClientApp && statuses.length === 0;
	const availableStatuses = noClientStatuses ? config.availableStatuses : statuses;
	const offsetParam = `Offset=${offset}`;
	const pageSizeParam = `&PageSize=${limit}`;
	const statusParams = availableStatuses.map((status) => `&Statuses=${status}`).join('');
	const divisionParams = divisions.map((division) => `&Divisions=${division}`).join('');
	const sortParams = sortBy ? `&OrderBy=${sortBy}&Dir=${sortDirection}` : '';
	const weekendStartParam = selectedStartDate ? `&WeekendStart=${selectedStartDate}` : '';
	const weekendEndParam = selectedEndDate ? `&WeekendEnd=${selectedEndDate}` : '';
	const hasSearch = searchKey !== null;
	let searchColumns;
	if (hasSearch) {
		const isSearchKeyArray = Array.isArray(searchKey.value);
		searchColumns = isSearchKeyArray
			? searchKey.value.map((key) => `&SearchColumns=${key}`).join('')
			: `&SearchColumns=${searchKey.value}`;
	}
	const searchParams = search ? `&SearchWord=${search}${searchColumns}` : '';
	return {
		offsetParam,
		pageSizeParam,
		statusParams,
		divisionParams,
		sortParams,
		weekendStartParam,
		weekendEndParam,
		searchParams,
	};
};

export const getTimesheets = async (request) => {
	const {
		offsetParam,
		pageSizeParam,
		statusParams,
		divisionParams,
		sortParams,
		weekendStartParam,
		weekendEndParam,
		searchParams,
	} = getUrlParams(request);
	try {
		const url = `administration/timepage?${offsetParam}${pageSizeParam}${statusParams}${sortParams}${divisionParams}${weekendStartParam}${weekendEndParam}${searchParams}`;
		const response = await axiosWCApiInstance.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
export const getEmailRecipients = async (timesheetId, reviewId) => {
	try {
		const url = `timesheets/${timesheetId}/TimesheetReviews/${reviewId}/email`;
		const response = await axiosWCApiInstance.get(url);
		if (response.status === 204) {
			throw { message: 'No content', statusCode: response.status };
		}
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const sendManualEmailRequest = async (timesheetId, reviewId, communicationEventType) => {
	try {
		const url = `timesheets/${timesheetId}/TimesheetReviews/${reviewId}/email?type=${communicationEventType}`;
		const response = await axiosWCApiInstance.post(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const updateTimesheetReview = async (timesheetId, reviewId, timesheetReview) => {
	try {
		const url = `timesheets/${timesheetId}/TimesheetReviews/${reviewId}`;
		const response = await axiosWCApiInstance.put(url, timesheetReview);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getTimesheetReview = async (timesheetId, reviewId) => {
	try {
		const url = `timesheets/${timesheetId}/TimesheetReviews/${reviewId}`;
		const response = await axiosWCApiInstance.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getTimesheetHistory = async (clinicianId, timesheetId, locationId) => {
	try {
		let locationParm = '';
		if (locationId) {
			locationParm = `?LocationId=${locationId}`;
		}
		const url = `clinicians/${clinicianId}/timesheets/${timesheetId}/history${locationParm}`;
		const response = await axiosWCApiInstance.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
