/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Paper, withStyles, Typography, Grid, Hidden, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import { COLORS } from '../../../utils/Application_Constants';
import WithContexts from '../../../contexts/withContexts';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TimesheetCard from './TimesheetCard';
import ToolTipComponent from '../../GlobalComponents/ToolTipComponent';
import { ltdomain } from '../../../utils/helpers';

const styles = (theme) => ({
	headerBar: {
		display: 'flex',
		backgroundColor: COLORS.LT_MIDNIGHTBG1,
		padding: '12px 16px 12px 32px',
		borderRadius: '8px',
	},
	heading: {
		paddingLeft: '16px',
	},
	paragraph3: {
		display: 'flex',
		alignItems: 'center',
		color: COLORS.LT_MIDNIGHT50,
		fontSize: '12px',
		lineHeight: '18px',
	},
	selectedPage: {
		backgroundColor: COLORS.LT_JOURNEY,
		padding: '6px 16px',
		color: COLORS.WHITE,
		'&:hover': {
			backgroundColor: COLORS.LT_JOURNEY75,
		},
	},
	pageButton: {
		color: COLORS.LT_MIDNIGHT75,
		padding: '6px 16px',
	},
	paginationNumber: {
		color: COLORS.LT_MIDNIGHT50,
		fontWeight: '600',
		fontSize: '14px',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '15px',
		},
	},
	infoIcon: {
		color: COLORS.LT_MIDNIGHT25,
		height: '12px',
	},

	PaperRoot: {
		padding: '1.5rem 2rem',
		display: 'flex',
		gap: '24px',
		flexDirection: 'column',
	},
});

const TimesheetsList = (props) => {
	const {
		classes,
		status,
		t,
		timesheets,
		isLoading,
		title,
		weekEndings,
		onDidNotWorkTimesheetStatusUpdate,
		refreshTimesheets,
		id,
	} = props;
	const [page, setPage] = useState(1);
	const [offset, setOffset] = useState(0);
	const [limit] = useState(3);

	const [paginatedTimesheets, setPaginatedTimesheets] = useState([]);
	const maxPages = Math.floor((timesheets?.length - 1) / limit + 1);

	useEffect(() => {
		const nextPage = timesheets?.slice(offset, offset + limit);
		setPaginatedTimesheets(nextPage);
	}, [offset]);

	useEffect(() => {
		if (timesheets) {
			const paginatedTimesheets = timesheets.slice(offset, offset + limit);
			setPaginatedTimesheets(paginatedTimesheets);
		}
	}, [timesheets]);

	const handlePageChange = (event, newPage) => {
		const newOffset = newPage === 1 ? 0 : (newPage - 1) * limit;
		setOffset(newOffset);
		setPage(newPage);
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	const renderTimesheetListView = () => {
		if ((!paginatedTimesheets || paginatedTimesheets.length === 0) && !isLoading) {
			if (status === 'PaidAndApproved') {
				return (
					<Typography variant='body1' align='center'>
						You have no paid or approved timesheets.
					</Typography>
				);
			} else {
				return (
					<Grid container direction='column' spacing={2}>
						<Grid item>
							<Typography variant='body1' align='center'>
								You have no outstanding timesheets. Searching for a new job?
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant='body1' align='center'>
								<a
									className='textLink'
									href={ltdomain() + '/Resources/JobSearch/QuickSearch/'}
								>
									Search our jobs
								</a>
								<span> or </span>
								<a className='textLink' href={ltdomain() + '/contact-a-recruiter/'}>
									contact a recruiter
								</a>
							</Typography>
						</Grid>
					</Grid>
				);
			}
		}

		if (paginatedTimesheets && !isLoading) {
			return (
				<>
					{paginatedTimesheets.map((timesheet) => {
						const timesheetKey = `${timesheet.bookingId}--${timesheet.weekEnding}`;
						return (
							<TimesheetCard
								id={timesheetKey}
								key={timesheetKey}
								timesheet={timesheet}
								weekEndings={weekEndings}
								status={status}
								onDidNotWorkTimesheetStatusUpdate={
									onDidNotWorkTimesheetStatusUpdate
								}
								refreshTimesheets={refreshTimesheets}
							/>
						);
					})}
				</>
			);
		}
	};

	const renderTimesheetListHeader = () => {
		return (
			<Hidden only={['xs', 'sm']}>
				<Grid container direction='row' spacing={0} className={classes.headerBar}>
					<Grid item xs={1} sm={2} md={3}>
						<div className={classes.paragraph3}>
							{t('submitTimesheet:INFO.WEEK')}
							<ToolTipComponent
								disableFocusListener
								enterTouchDelay={150}
								interactive
								placement='right'
								title={
									<>
										<span>{t('submitTimesheet:TOOL_TIPS.WEEK')}</span>
									</>
								}
							>
								<InfoOutlinedIcon classes={{ root: classes.infoIcon }} />
							</ToolTipComponent>
						</div>
					</Grid>
					<Grid item container xs={8} sm={6} md={9}>
						<Grid item xs={3}></Grid>
						<Grid item xs={2} className={classes.heading}>
							<div className={classes.paragraph3}>
								{t('submitTimesheet:INFO.HOURS')}
								<ToolTipComponent
									disableFocusListener
									enterTouchDelay={150}
									interactive
									placement='right'
									title={
										<>
											<span>{t('submitTimesheet:TOOL_TIPS.HOURS')}</span>
										</>
									}
								>
									<InfoOutlinedIcon classes={{ root: classes.infoIcon }} />
								</ToolTipComponent>
							</div>
						</Grid>
						<Grid item xs={2} className={classes.heading}>
							<div className={classes.paragraph3}>
								{t('submitTimesheet:INFO.UNITS')}
								<ToolTipComponent
									disableFocusListener
									enterTouchDelay={150}
									interactive
									placement='right'
									title={
										<>
											<span>{t('submitTimesheet:TOOL_TIPS.UNITS')}</span>
										</>
									}
								>
									<InfoOutlinedIcon classes={{ root: classes.infoIcon }} />
								</ToolTipComponent>
							</div>
						</Grid>
						<Grid item xs={2} className={classes.heading}>
							<div className={classes.paragraph3}>
								{t('submitTimesheet:MILEAGE_TABLE.MILEAGE')}
								<ToolTipComponent
									disableFocusListener
									enterTouchDelay={150}
									interactive
									placement='right'
									title={
										<>
											<span>{t('submitTimesheet:TOOL_TIPS.MILEAGE')}</span>
										</>
									}
								>
									<InfoOutlinedIcon classes={{ root: classes.infoIcon }} />
								</ToolTipComponent>
							</div>
						</Grid>
						<Grid item xs={2}>
							<div className={classes.paragraph3}>
								{t('submitTimesheet:INFO.STATUS')}{' '}
							</div>
						</Grid>
						<Grid item xs={1}></Grid>
					</Grid>
				</Grid>
			</Hidden>
		);
	};

	return (
		<Paper className={classes.PaperRoot} id={id}>
			<Typography variant='h5' component='h5'>
				{title}
			</Typography>
			{timesheets && timesheets.length > 0 && !isLoading && renderTimesheetListHeader()}
			{isLoading && <CircularProgress color='primary' />}
			{renderTimesheetListView()}
			{!isLoading && timesheets !== undefined && timesheets.length > limit && (
				<Grid container justifyContent='space-between' alignItems='center'>
					<Grid item>
						<div className={classes.paginationNumber}>
							{`Page ${page} of ${!timesheets ? 0 : maxPages}`}
						</div>
					</Grid>
					<Grid item>
						<Pagination
							color='primary'
							variant='outlined'
							shape='rounded'
							page={page}
							count={!timesheets ? 0 : maxPages}
							onChange={(e, page) => handlePageChange(e, page)}
						/>
					</Grid>
				</Grid>
			)}
		</Paper>
	);
};

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(TimesheetsList))));
