/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
	IconButton,
	withStyles,
	Grid,
	Hidden,
	TextField,
	MenuItem,
	Menu,
	Typography,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import { COLORS } from '../../../utils/Application_Constants';
import { totalCount } from '../../../utils/helpers';
import { MoreVert, ExpandMore, DeleteOutline, MoreHoriz } from '@material-ui/icons';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import PropTypes from 'prop-types';
import RateTypeCategoryEnum from './RateTypeCategories.enum';
import CopyADayMenuItem from './CopyADayMenuItem';
import CopyADayMenu from './CopyADayMenu';
import moment from 'moment';
import { AdminAuthContext } from '../../../Admin/Contexts/AdminAuthContext';
import TimesheetBreak from './TimesheetBreak';
import { useFieldArray, useForm } from 'react-hook-form';
import FeatureFlag from '../../GlobalComponents/FeatureFlagComponent/FeatureFlag';
import RateDisplayNameEnum from '../../ContentComponents/Timesheets/RateDisplayName.enum';

const Placeholder = ({ label }) => <span className='select-placeholder'>{label}</span>;

const styles = (theme) => ({
	entryRow: {
		[theme.breakpoints.up('md')]: {
			flexWrap: 'nowrap',
		},
		zIndex: 0,
		position: 'relative',
	},
	entryCell: {
		'&:first-child': {
			'& .MuiSelect-outlined': {
				borderTopLeftRadius: '8px',
				[theme.breakpoints.up('md')]: {
					borderBottomLeftRadius: '8px',
				},
				[theme.breakpoints.down('md')]: {
					borderRight: 'none',
				},
			},
		},
		'&:nth-child(2)': {
			'& .MuiSelect-outlined': {
				[theme.breakpoints.down('sm')]: {
					borderRight: 'none',
				},
			},
		},
		'&:nth-child(3)': {
			'& .MuiSelect-outlined': {
				[theme.breakpoints.down('sm')]: {
					borderTopRightRadius: '8px',
				},
			},
		},
		'&:nth-child(4)': {
			'& .MuiInputBase-input': {
				[theme.breakpoints.down('md')]: {
					borderRight: 'none',
				},
			},
		},
		'&:nth-child(5)': {
			'& .MuiInputBase-input': {
				[theme.breakpoints.down('sm')]: {
					borderRight: 'none',
				},
			},
		},
		'&:nth-child(7)': {
			'& .MuiInputBase-input': {
				[theme.breakpoints.down('sm')]: {
					borderBottomRightRadius: '8px',
					borderBottomLeftRadius: '8px',
					borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
				},
			},
		},
	},
	menuButtonCell: {
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		padding: '2px',
		borderTopRightRadius: '8px',
		borderBottomRightRadius: '8px',
		overflow: 'hidden',
		background: COLORS.WHITE,
	},
	copyADayMenu: {
		"& .MuiList-root": {
			padding: "0"
		},
	},
	disabledBackgroundColor: {
		background: COLORS.LT_MIDNIGHTBG2,
	},
	formControl: {
		marginBottom: '0',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
			borderRadius: '8px',
		},
	},
	menuButton: {
		color: COLORS.LT_MIDNIGHT25,
	},
	menuIcon: {
		color: COLORS.LT_MIDNIGHT25,
		display: 'flex',
		alignItems: 'center',
	},
	menuIconRemove: {
		color: COLORS.LT_PEPPER,
	},
	menuIconCallBack: {
		color: COLORS.LT_MIDNIGHT33,
	},
	menuIconBreak: {
		color: COLORS.LT_FOUNDATION,
	},
	expandMore: {
		color: COLORS.LT_MIDNIGHT25,
		height: '20px',
		right: '8px',
		top: 'calc(50% - 10px)',
	},
	inputRoot: {
		'&$disabled $notchedOutline': {
			borderColor: 'transparent',
		},

		'&$disabled $input': {
			background: COLORS.LT_MIDNIGHTBG2,
			color: COLORS.LT_MIDNIGHT33,
		},
	},
	disabled: {},
	notchedOutline: {},
	input: {
		padding: '16px !important',
		height: 'auto',
		fontSize: '14px',
		lineHeight: '20px',
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		[theme.breakpoints.up('md')]: {
			borderRight: 'none',
			'&::placeholder': {
				opacity: 0,
			},
		},
		[theme.breakpoints.down('sm')]: {
			borderBottom: 'none',
		},
		'&$disabled': {
			background: COLORS.LT_MIDNIGHTBG2,
		},
		'&[type="time"]': {
			height: '20px',
		},
	},

	selectOutlined: {
		padding: '16px 24px 16px 16px !important',
		fontSize: '14px',
		lineHeight: '20px',
		borderRadius: '0px',
		borderColor: COLORS.LT_MIDNIGHT5,
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		[theme.breakpoints.up('md')]: {
			borderRight: 'none',

			'& .select-placeholder': {
				opacity: 0,
			},
		},
		'&$disabled': {
			background: COLORS.LT_MIDNIGHTBG2,
			color: COLORS.LT_MIDNIGHT33,
		},
		'& .select-placeholder': {
			color: COLORS.LT_MIDNIGHT33,
		},
		[theme.breakpoints.down('sm')]: {
			borderBottom: 'none',
		},
	},
	commentsInput: {
		flexGrow: '1',
		maxWidth: '100%',
	},
	mobileMenuButton: {
		marginBottom: '4px',
		marginLeft: 'auto',
		display: 'flex',
		justifyContent: 'flex-end',

		'& .MuiIconButton-root': {
			padding: '6px',
		},
	},
	breakContainer: {
		flexWrap: 'nowrap',
	},
	midCallBackConnector: {
		position: 'absolute',
		height: '35px',
		width: '600px',
		borderRight: '1px solid #EEF0F1',
		right: '28px',
		borderBottom: '1px solid #EEF0F1',
		bottom: '52%',
		zIndex: -1,
	},
	endCallBackConnector: {
		position: 'absolute',
		height: '6em',
		borderRight: '1px solid #EEF0F1',
		right: '28px',
		borderBottom: '1px solid #EEF0F1',
		bottom: '0%',
		zIndex: -1
	},
	midCallBackConnectorContainer: {
		height: '3.5em',
		width: '3.5em',
		position: 'relative',
	},
	connectorGrid: {
		zIndex: -1,
		backgroundColor: COLORS.WHITE,
	},
	endCallBackConnectorContainer: {
		height: '4em',
		position: 'absolute',
		right: '0%',
	},
	menuGrid: {
		backgroundColor: COLORS.WHITE,
		zIndex: 1,
	},
	endRowContainer: {
		flexWrap: 'nowrap',
	},
});

function TimesheetEntry(props) {
	const { classes, index, dateLookup, timesheet, timesheetEntry, onRemove, isPaidApproved, timesheetInsert, triggerCallRowLockCheck, setTotalTimesheetHours, isLastCallBackEntry } =
		props;
	const { errors, setValue, watch, getValues, clearErrors, reset } = useFormContext();
	const [rateTypeCategory, setRateTypeCategory] = useState(RateTypeCategoryEnum.Hours);
	const [rateTypeDisplayName, setRateTypeDisplayName] = useState(null);
	const [isCallBackEntry, setIsCallBackEntry] = useState(timesheetEntry.relatedEntryId !== '');
	const [isCallWithCallBack, setIsCallWithCallBack] = useState(() => {
		const hasRelatedEntryId = timesheet.timesheetEntries.some(entry =>
			entry.relatedEntryId === timesheetEntry.id
		);
		return hasRelatedEntryId;
	});
	const [modifiedDateLookup, setModifiedDateLookup] = useState(dateLookup);
	const [hasBreaks, setHasBreaks] = useState(false);
	const [entryHours, setEntryHours] = useState('');
	const [showCopyADayMenu, setShowCopyADayMenu] = useState(false);
	const watchRateTypeId = watch(`timesheetEntries[${index}].rateTypeId`);
	const watchTransactionDate = watch(`timesheetEntries[${index}].transactionDate`);
	const watchComment = watch(`timesheetEntries[${index}].comment`);
	const watchStartTime = watch(`timesheetEntries[${index}].startTime`);
	const watchEndTime = watch(`timesheetEntries[${index}].endTime`);
	const readOnlyLocations =
		timesheet.timesheetReviews?.map((review) =>
			review.status !== 'NotStarted' &&
				review.status !== 'PendingSubmission' &&
				review.status !== 'Rejected' &&
				review.status !== 'DidNotWork'
				? review.locationId
				: null
		) || [];
	const adminAuth = useContext(AdminAuthContext);
	const isReadOnly =
		readOnlyLocations.indexOf(timesheetEntry.locationId) > -1 ||
		isPaidApproved ||
		adminAuth?.isLTAssociateUser;
	const [anchorEl, setAnchorEl] = useState(null);
	const isFirstRender = useRef(true);
	const getAvailableLocation = () => {
		return (
			timesheet.timesheetReviews?.find(
				(review) => !readOnlyLocations.includes(review.locationId)
			)?.locationId || null
		);
	};
	const avaliableLocationId = getAvailableLocation();
	const defaultLocationId =
		timesheet.primaryWorkLocationId &&
			!readOnlyLocations.includes(timesheet.primaryWorkLocationId)
			? timesheet.primaryWorkLocationId
			: avaliableLocationId;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const methods = useForm({ defaultValues: {} });

	const {
		formState: { isDirty },
	} = methods;

	const {
		fields: timesheetBreaks,
		insert: breakInsert,
		remove: breakRemove,
	} = useFieldArray({
		control: methods.control,
		name: `timesheetBreaks`,
		keyName: 'timesheetBreakId',
	});

	useEffect(() => {
		if (timesheetEntry) {
			methods.reset(setDefaultValues());
		}
	}, [methods.reset, timesheetEntry]);

	const setDefaultValues = () => {
		let timesheetBreaks = [];

		timesheetBreaks = timesheetEntry.timesheetBreaks;

		return { timesheetBreaks };
	};

	useEffect(() => {
		const rateType = timesheet.booking.availableRates.find(
			(rate) => rate.id === watchRateTypeId
		);
		if (rateType) {
			if (
				rateType.category === RateTypeCategoryEnum.Units ||
				rateType.category === RateTypeCategoryEnum.TeleUnits
			) {
				const units = timesheetEntry.units ? timesheetEntry.units : '';
				setValue(`timesheetEntries[${index}].units`, units);
				setValue(`timesheetEntries[${index}].startTime`, '');
				setValue(`timesheetEntries[${index}].endTime`, '');
				timesheetEntry.units = '';
			} else if (
				rateType.category === RateTypeCategoryEnum.SingleUnit ||
				rateType.category === RateTypeCategoryEnum.TeleSingleUnit
			) {
				setValue(`timesheetEntries[${index}].units`, '1');
				setValue(`timesheetEntries[${index}].startTime`, '');
				setValue(`timesheetEntries[${index}].endTime`, '');
			} else {
				setValue(`timesheetEntries[${index}].units`, '');
			}
			if (rateType && rateType.displayName.includes("Call-Callback")) {
				setIsCallBackEntry(true);
			} else {
				setIsCallBackEntry(false);
			}
			setRateTypeCategory(rateType.category);
			setRateTypeDisplayName(rateType.displayName);
		}
	}, [watchRateTypeId]);

	useEffect(() => {
		if (!isFirstRender.current) {
			const hasRelatedEntryId = getValues().timesheetEntries.some(entry =>
				entry.relatedEntryId === timesheetEntry.id
			);
			setIsCallWithCallBack(hasRelatedEntryId);
		} else {
			isFirstRender.current = false;
		}
	}, [triggerCallRowLockCheck, getValues().timesheetEntries, timesheetEntry.id])

	useEffect(() => {
		const relatedTimesheetEntry = getValues().timesheetEntries.find(entry => entry.id === timesheetEntry.relatedEntryId);
		if (isCallBackEntry && relatedTimesheetEntry) {
			const modifiedDates = [
				{ value: moment(relatedTimesheetEntry.transactionDate).format('YYYY-MM-DDTHH:mm:ss'), label: moment(relatedTimesheetEntry.transactionDate).format('ddd M/D') },
				{ value: moment(relatedTimesheetEntry.transactionDate).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'), label: moment(relatedTimesheetEntry.transactionDate).add(1, 'day').format('ddd M/D') }
			];
			setModifiedDateLookup(modifiedDates);
		}
	}, [reset]);

	useEffect(() => {
		calculateEntryHours();
	}, [watchStartTime, watchEndTime, watchRateTypeId, timesheetBreaks]);

	useEffect(() => {
			setHasBreaks(timesheetBreaks && timesheetBreaks.length > 0);
	}, [timesheetBreaks]);

	const calculateTotalTimesheetHours = () => {
		const timesheetEntries = getValues().timesheetEntries;
		let totalTimesheetHours = 0;
		for (var i = 0; i < timesheetEntries.length; i++) {
			if (timesheetEntries[i].totalTimeInHours) {
				totalTimesheetHours += timesheetEntries[i].totalTimeInHours
			}
		}

		setTotalTimesheetHours(Number(totalTimesheetHours.toFixed(2)));
	};

	const validateUnits = (value) => {
		let regex;
		const hasDecimal = value.indexOf('.') > -1;
		if (hasDecimal) {
			regex = /^\d{0,2}(?:[.]\d{0,2})/;
		} else {
			regex = /^\d{0,2}/;
		}
		const match = value.match(regex);

		if (match) {
			//In-House Call Rate Types have an additional restriction of 26 or less
			if (
				!!!!rateTypeDisplayName &&
				rateTypeDisplayName.toUpperCase().includes('IN-HOUSE CALL') &&
				!(value <= 26)
			) {
				return null;
			}
			return match[0];
		} else {
			return null;
		}
	};

	const calculateEntryHours = () => {
		if (!watchEndTime || !watchStartTime) {
			setValue(`timesheetEntries[${index}].totalTimeInHours`, null);
			setEntryHours('');
			calculateTotalTimesheetHours();
			return;
		}
		const startTime = moment(watchStartTime, 'HH:mm');
		const endTime = moment(watchEndTime, 'HH:mm');
		let hours = moment.duration(endTime.diff(startTime)).asHours();

		if (hours <= 0)
			hours += 24;

		let breakHours = calculateTotalEntryBreakHours();
		if (breakHours)
			hours -= breakHours;

		if (hours < 0)
			hours = 0;

		hours = Number(hours.toFixed(2))
		setValue(`timesheetEntries[${index}].totalTimeInHours`, hours);
		setEntryHours(hours);
		calculateTotalTimesheetHours();
	};

	const calculateTotalEntryBreakHours = () => {
		let entries = getValues().timesheetEntries;

		if (!entries[index].timesheetBreaks)
			return 0;

		return totalCount(entries[index].timesheetBreaks, 'totalTimeInHours');
	}

	const getAssociatedRateType = (rateTypeId) => {
		const rate = timesheet.booking.availableRates.find(rate => rate.id === rateTypeId);
		return rate ? rate.associatedRateType : null;
	};

	const renderCallBackMenuItem = () => {
		const rateExists = watchRateTypeId !== null;
		const associatedRateExists = timesheet.booking.availableRates.some(rate => rate.id === watchRateTypeId
			&& rate.associatedRateType !== null && !rate.displayName.includes("Call-Callback"));

		if (rateExists && associatedRateExists) {
			return (
				<MenuItem
					onClick={() => {
						const entries = getValues().timesheetEntries;
						let lastChildIndex = entries.findLastIndex(element =>
							element.relatedEntryId === entries[index].id
						);
						timesheetInsert(((lastChildIndex > -1) ? lastChildIndex + 1 : index + 1), {
							id: 0,
							transactionDate: entries[index].transactionDate,
							locationId: entries[index].locationId,
							rateTypeId: getAssociatedRateType(watchRateTypeId),
							relatedEntryId: entries[index].id,
							units: '',
							startTime: '',
							endTime: '',
							comment: '',
						});
						setIsCallWithCallBack(true);
						handleClose();
					}}
					id={`timesheet-entry--${index}--menu--list-item--callback`}
				>
					<Grid container alignItems='center' spacing={2}>
						<Grid item className={classes.menuIcon}>
							<SettingsBackupRestoreIcon
								fontSize='small'
								className={classes.menuIconCallBack}
							/>
						</Grid>
						<Grid item>
							<Typography variant='body2'>Add Call-Back</Typography>
						</Grid>
					</Grid>
				</MenuItem>
			);
		} else {
			return null; // or you can render an alternative message or component
		}
	};
	
	const checkBreakRateDisplayName = (rateDisplayName) => {
		const names = [
			RateDisplayNameEnum.Regular,
			RateDisplayNameEnum.RegularOnsite,
			RateDisplayNameEnum.RegularTele,
			RateDisplayNameEnum.DayShift, 
			RateDisplayNameEnum.DayShiftOnsite,
			RateDisplayNameEnum.DayShiftTele,
			RateDisplayNameEnum.NightShift,
			RateDisplayNameEnum.NightShiftOnsite,
			RateDisplayNameEnum.NightShiftTele,
			RateDisplayNameEnum.Orientation,
			RateDisplayNameEnum.OrientationOnsite,
			RateDisplayNameEnum.OrientationTele,
		];
		
		return names.indexOf(rateDisplayName) !== -1;
	};

	const renderBreakMenuItem = () => {
		const rateExists = watchRateTypeId !== null;
		const timeRateExists = timesheet.booking.availableRates.some(rate => rate.id === watchRateTypeId
			&& checkBreakRateDisplayName(rate.displayName));

		if (rateExists && timeRateExists) {
			return (
				<FeatureFlag name='TimesheetBreak' isFlagEnabled='true'>
					<MenuItem
						onClick={() => {
							const entries = getValues().timesheetEntries;

							let lastChildIndex = entries[index].timesheetBreaks?.findLastIndex(element =>
								element.timesheetEntryId === entries[index].id
							);
							const breakIndex = (lastChildIndex > -1) ? lastChildIndex + 1 : 0

							breakInsert((breakIndex), {
								id: 0,
								timesheetEntryId: entries[index].id,
								startTime: '',
								endTime: ''
							});

							handleClose();
						}}
						id={`timesheet-entry--${index}--menu--list-item--break`}
					>
						<Grid container alignItems='center' spacing={2}>
							<Grid item className={classes.menuIcon}>
								<AccessTimeOutlinedIcon
									fontSize='small'
									className={classes.menuIconBreak}
								/>
							</Grid>
							<Grid item>
								<Typography variant='body2'>Add a Break</Typography>
							</Grid>
						</Grid>
					</MenuItem>
				</FeatureFlag>
			);
		} else {
			return null; // or you can render an alternative message or component
		}
	};

	const renderCopyADayMenuItem = () => {
		return (
			<CopyADayMenuItem
				index={index}
				entryType={'timesheet-entry'}
				setAnchorEl={setAnchorEl}
				setShowCopyADayMenu={setShowCopyADayMenu}
				isDisabled={!watchRateTypeId}
			/>
		);
	};

	const renderCopyADayMenu = () => {
		return (
			<CopyADayMenu
				index={index}
				timesheet={timesheet}
				dateLookup={modifiedDateLookup}
				entryType={'timesheet-entry'}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				showCopyADayMenu={showCopyADayMenu}
				setShowCopyADayMenu={setShowCopyADayMenu}
			/>
		);
	};

	const renderBreaks = () => {
		if (!hasBreaks) {
			return null;
		}

		return <Grid item>
			<Grid container direction='column' spacing={1} className={classes.breakContainer}>
				{timesheetBreaks?.map((timesheetBreak, breakIndex) => (
					<Grid item key={timesheetBreak.timesheetBreakId + '-' + index} >
						<TimesheetBreak
							entryIndex={index}
							index={breakIndex}
							timesheetBreak={timesheetBreak}
							isPaidApproved={isPaidApproved}
							calculateEntryHours={calculateEntryHours}
							breakRemove={breakRemove}
							isReadOnly={isReadOnly}
							isLastBreakInEntry={breakIndex === timesheetBreaks.length - 1}
						/>
					</Grid>
				))}
			</Grid>
		</Grid>
	};

	const renderRemoveMenuItem = () => {
		return (
			<MenuItem
				onClick={() => {
					onRemove(getValues().timesheetEntries[index], index);
					handleClose();
				}}
				id={`timesheet-entry--${index}--menu--list-item--remove`}
			>
				<Grid container alignItems='center' spacing={2}>
					<Grid item className={classes.menuIcon}>
						<DeleteOutline
							fontSize='small'
							className={classes.menuIconRemove}
						/>
					</Grid>
					<Grid item>
						<Typography variant='body2'>Remove</Typography>
					</Grid>
				</Grid>
			</MenuItem>
		)
	};

	const renderCallBackConnector = () => {
		if (!isCallBackEntry)
			return null;

		return <Grid item className={classes.connectorGrid}>
			<div className={classes.midCallBackConnectorContainer}>
				<div className={classes.midCallBackConnector}></div>
			</div>
			{isLastCallBackEntry ? null : <div className={classes.endCallBackConnectorContainer}><div className={classes.endCallBackConnector}></div></div>}
		</Grid>
	};

	return (
		<>
			<Hidden
				only={
					isPaidApproved || adminAuth?.isLTAssociateUser
						? ['xs', 'sm', 'md', 'lg', 'xl']
						: ['md', 'lg', 'xl']
				}
			>
				<div className={classes.mobileMenuButton}>
					<IconButton
						id={`timesheet-entry--${index}--menu`}
						color='default'
						aria-label='More options'
						onClick={handleClick}
						disabled={isReadOnly ? true : false}
					>
						<MoreHoriz />
					</IconButton>
					<Menu
						id='timesheetEntry-menu'
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						{renderBreakMenuItem()}
						{renderCopyADayMenuItem()}
						{renderRemoveMenuItem()}
						{renderCallBackMenuItem()}
					</Menu>
					{renderCopyADayMenu()}
				</div>
			</Hidden>
			<Grid
				container
				direction='column'
				spacing={1}
				className={classes.breakContainer}
			>
				<Grid item>
					<Grid container
						className={classes.entryRow}
						id={`timesheet-entry--${index}`}>
						<Controller
							name={`timesheetEntries[${index}].id`}
							defaultValue={timesheetEntry.id}
						/>
						<Controller
							name={`timesheetEntries[${index}].relatedEntryId`}
							defaultValue={timesheetEntry.relatedEntryId}
						/>
						<Grid className={classes.entryCell} item xs={4} md={2} lg={1}>
							<Controller
								name={`timesheetEntries[${index}].transactionDate`}
								defaultValue={timesheetEntry.transactionDate}
								rules={{
									required: watchRateTypeId || watchComment ? 'Date is required' : false,
								}}
								render={({ onChange, value, ref }) => (
									<TextField
										id={`timesheet-entry--${index}--date`}
										onChange={(e) => {
											clearErrors(`timesheetEntries[${index}].transactionDate`);
											onChange(e);
										}}
										select
										value={value}
										className={`${classes.formControl} timesheet-entry--cell`}
										ref={ref}
										InputProps={{
											classes: {
												root: classes.inputRoot,
												disabled: classes.disabled,
												notchedOutline: classes.notchedOutline,
											},
										}}
										SelectProps={{
											classes: {
												icon: classes.expandMore,
												outlined: classes.selectOutlined,
												disabled: classes.disabled,
											},
											IconComponent: ExpandMore,
											displayEmpty: true,
											renderValue:
												value !== '' ? null : () => <Placeholder label='Date' />,
										}}
										variant='outlined'
										disabled={isReadOnly || isCallWithCallBack}
										inputProps={{ tabIndex: index === 0 ? '-1' : '0' }}
										error={
											errors['timesheetEntries']?.[index]?.transactionDate
												? true
												: false
										}
										helperText={
											errors['timesheetEntries']?.[index]?.transactionDate?.message
										}
									>
										{modifiedDateLookup.map((date, dateIndex) => (
											<MenuItem
												key={date.value}
												value={date.value}
												id={`timesheet-entry--${index}--date--list-item--${dateIndex}`}
											>
												{date.label}
											</MenuItem>
										))}
									</TextField>
								)}
							/>
						</Grid>
						<Grid className={classes.entryCell} item xs={4} md={2} lg={3}>
							<Controller
								name={`timesheetEntries[${index}].locationId`}
								defaultValue={
									timesheetEntry.locationId
										? timesheetEntry.locationId
										: defaultLocationId
								}
								rules={{
									required:
										watchRateTypeId || watchComment
											? 'Work Location is required'
											: false,
								}}
								render={({ onChange, value, ref }) => (
									<TextField
										id={`timesheet-entry--${index}--work-location`}
										onChange={(e) => {
											clearErrors(`timesheetEntries[${index}].locationId`);
											onChange(e);
										}}
										select
										value={value}
										className={`${classes.formControl} timesheet-entry--cell`}
										ref={ref}
										InputProps={{
											classes: {
												root: classes.inputRoot,
												disabled: classes.disabled,
												notchedOutline: classes.notchedOutline,
											},
										}}
										SelectProps={{
											classes: {
												icon: classes.expandMore,
												outlined: classes.selectOutlined,
												disabled: classes.disabled,
											},
											IconComponent: ExpandMore,
											displayEmpty: true,
											renderValue:
												value !== ''
													? null
													: () => <Placeholder label='Location' />,
										}}
										variant='outlined'
										disabled={isReadOnly || isCallBackEntry || isCallWithCallBack}
										error={
											errors['timesheetEntries']?.[index]?.locationId ? true : false
										}
										helperText={
											errors['timesheetEntries']?.[index]?.locationId?.message
										}
									>
										{timesheet.booking.locations.map((location, locationIndex) => (
											<MenuItem
												key={location.id}
												value={location.id}
												disabled={readOnlyLocations.indexOf(location.id) > -1}
												id={`timesheet-entry--${index}--work-location--list-item--${locationIndex}`}
											>
												{location.displayName}
											</MenuItem>
										))}
									</TextField>
								)}
							/>
						</Grid>
						<Grid className={classes.entryCell} item xs={4} md={2} lg={1}>
							<Controller
								name={`timesheetEntries[${index}].rateTypeId`}
								defaultValue={timesheetEntry.rateTypeId}
								rules={{
									required: watchComment ? 'Shift type is required' : false,
								}}
								render={({ onChange, value, ref }) => (
									<TextField
										id={`timesheet-entry--${index}--shift-type`}
										onChange={(e) => {
											clearErrors(`timesheetEntries[${index}].rateTypeId`);
											onChange(e);
										}}
										select
										value={value}
										className={`${classes.formControl} timesheet-entry--cell`}
										ref={ref}
										InputProps={{
											classes: {
												root: classes.inputRoot,
												disabled: classes.disabled,
												notchedOutline: classes.notchedOutline,
											},
										}}
										SelectProps={{
											classes: {
												icon: classes.expandMore,
												outlined: classes.selectOutlined,
												disabled: classes.disabled,
											},
											IconComponent: ExpandMore,
											displayEmpty: true,
											renderValue:
												value !== ''
													? null
													: () => <Placeholder label='Shift Type' />,
										}}
										variant='outlined'
										disabled={isReadOnly || isCallBackEntry || isCallWithCallBack || hasBreaks}
										error={
											errors['timesheetEntries']?.[index]?.rateTypeId ? true : false
										}
										helperText={
											errors['timesheetEntries']?.[index]?.rateTypeId?.message
										}
									>
										{timesheet.booking.availableRates
											.filter(item =>
												item.startDate <= moment(watchTransactionDate).format('YYYY-MM-DD') &&
												item.endDate >= moment(watchTransactionDate).format('YYYY-MM-DD') &&
												(isCallBackEntry ? item.displayName.includes("Call-Callback") : !item.displayName.includes("Call-Callback"))
											)
											.map((item, shiftTypeIndex) => (
												<MenuItem
													key={item.id}
													value={item.id}
													id={`timesheet-entry--${index}--shift-type--list-item--${shiftTypeIndex}`}
												>
													{item.displayName}
												</MenuItem>
											))}
										{!watchTransactionDate && (
											<MenuItem disabled>Please select a date</MenuItem>
										)}
									</TextField>
								)}
							/>
						</Grid>
						<Grid className={classes.entryCell} item xs={4} md={1}>
							<Controller
								name={`timesheetEntries[${index}].units`}
								defaultValue={timesheetEntry.units}
								rules={{
									required:
										rateTypeCategory === RateTypeCategoryEnum.Units ||
											rateTypeCategory === RateTypeCategoryEnum.TeleUnits
											? 'Units are required'
											: false,
								}}
								render={({ onChange, value, ref }) => (
									<TextField
										id={`timesheet-entry--${index}--units`}
										className={`${classes.formControl} timesheet-entry--cell`}
										value={value}
										inputRef={ref}
										disabled={
											(rateTypeCategory !== RateTypeCategoryEnum.Units &&
												rateTypeCategory !== RateTypeCategoryEnum.TeleUnits) ||
											isReadOnly || hasBreaks
										}
										onChange={(e) => {
											clearErrors(`timesheetEntries[${index}].units`);
											const newValue = validateUnits(e.target.value);
											if (newValue || newValue === '') {
												onChange(newValue);
											}
										}}
										InputProps={{
											classes: {
												input: classes.input,
												root: classes.inputRoot,
												disabled: classes.disabled,
												notchedOutline: classes.notchedOutline,
											},
										}}
										variant='outlined'
										error={errors['timesheetEntries']?.[index]?.units ? true : false}
										helperText={errors['timesheetEntries']?.[index]?.units?.message}
										placeholder='Units'
									/>
								)}
							/>
						</Grid>
						<Grid className={classes.entryCell} item xs={4} md={1}>
							<Controller
								name={`timesheetEntries[${index}].startTime`}
								defaultValue={timesheetEntry.startTime}
								rules={{
									required:
										watchRateTypeId &&
											(rateTypeCategory === RateTypeCategoryEnum.Hours ||
												rateTypeCategory === RateTypeCategoryEnum.TeleHours)
											? 'Start time is required'
											: false,
								}}
								render={({ onChange, value, ref }) => (
									<TextField
										id={`timesheet-entry--${index}--start-time`}
										type='time'
										className={`${classes.formControl} timesheet-entry--cell`}
										value={value}
										disabled={
											!watchRateTypeId ||
											(rateTypeCategory !== RateTypeCategoryEnum.Hours &&
												rateTypeCategory !== RateTypeCategoryEnum.TeleHours) ||
											isReadOnly
										}
										onChange={(e) => {
											clearErrors(`timesheetEntries[${index}].startTime`);
											onChange(e);
										}}
										onKeyDown = {(e) => {
											if (e.code === 'Space') e.preventDefault()
										}}
										inputRef={ref}
										InputProps={{
											classes: {
												input: classes.input,
												root: classes.inputRoot,
												disabled: classes.disabled,
												notchedOutline: classes.notchedOutline,
											},
										}}
										inputProps={{
											step: 300, // 5 min
										}}
										variant='outlined'
										error={
											errors['timesheetEntries']?.[index]?.startTime ? true : false
										}
										helperText={errors['timesheetEntries']?.[index]?.startTime?.message}
									/>
								)}
							/>
						</Grid>
						<Grid className={classes.entryCell} item xs={4} md={1}>
							<Controller
								name={`timesheetEntries[${index}].endTime`}
								defaultValue={timesheetEntry.endTime}
								rules={{
									required:
										watchRateTypeId &&
											(rateTypeCategory === RateTypeCategoryEnum.Hours ||
												rateTypeCategory === RateTypeCategoryEnum.TeleHours)
											? 'End time is required'
											: false,
								}}
								render={({ onChange, value, ref }) => (
									<TextField
										id={`timesheet-entry--${index}--end-time`}
										type='time'
										className={`${classes.formControl} timesheet-entry--cell`}
										value={value}
										inputRef={ref}
										disabled={
											!watchRateTypeId ||
											(rateTypeCategory !== RateTypeCategoryEnum.Hours &&
												rateTypeCategory !== RateTypeCategoryEnum.TeleHours) ||
											isReadOnly
										}
										onChange={(e) => {
											clearErrors(`timesheetEntries[${index}].endTime`);
											onChange(e);
										}}
										onKeyDown = {(e) => {
											if (e.code === 'Space') e.preventDefault()
										}}
										InputProps={{
											classes: {
												input: classes.input,
												root: classes.inputRoot,
												disabled: classes.disabled,
												notchedOutline: classes.notchedOutline,
											},
										}}
										inputProps={{
											step: 300, // 5 min
										}}
										variant='outlined'
										error={errors['timesheetEntries']?.[index]?.endTime ? true : false}
										helperText={errors['timesheetEntries']?.[index]?.endTime?.message}
									/>
								)}
							/>
						</Grid>
						<Grid className={classes.entryCell} item xs={4} md={1}>
							<Controller
								name={`timesheetEntries[${index}].totalTimeInHours`}
								defaultValue={timesheetEntry.totalTimeInHours}
								render={({ ref }) => (
									<TextField
										id={`timesheet-entry--${index}--totalTimeInHours`}
										className={`${classes.formControl} timesheet-entry--cell`}
										value={entryHours}
										inputRef={ref}
										disabled={true}
										InputProps={{
											classes: {
												input: classes.input,
												root: classes.inputRoot,
												disabled: classes.disabled,
												notchedOutline: classes.notchedOutline,
											},
										}}
										variant='outlined'
										error={errors['timesheetEntries']?.[index]?.totalTimeInHours ? true : false}
										helperText={errors['timesheetEntries']?.[index]?.totalTimeInHours?.message}
										placeholder='Hours'
									/>
								)}
							/>
						</Grid>
						<Grid container
							xs={3}
							className={classes.endRowContainer}>
							<Grid
								item
								xs={isCallBackEntry ? 8 : 10}
								classes={{ root: classes.commentsInput }}
								className={classes.entryCell}
							>
								<Controller
									name={`timesheetEntries[${index}].comment`}
									defaultValue={timesheetEntry.comment}
									render={({ onChange, value, ref }) => (
										<TextField
											id={`timesheet-entry--${index}--comments`}
											inputProps={{ maxLength: 255 }}
											className={`${classes.formControl} timesheet-entry--cell`}
											value={value}
											inputRef={ref}
											onChange={(e) => {
												clearErrors(`timesheetEntries[${index}].comment`);
												onChange(e);
											}}
											InputProps={{
												classes: {
													input: classes.input,
													root: classes.inputRoot,
													disabled: classes.disabled,
													notchedOutline: classes.notchedOutline,
												},
											}}
											variant='outlined'
											disabled={isReadOnly}
											placeholder='Comments'
										/>
									)}
								/>
							</Grid>
							<Grid item className={classes.menuGrid}>
								<Hidden
									only={
										isPaidApproved || adminAuth?.isLTAssociateUser
											? ['xs', 'sm', 'md', 'lg', 'xl']
											: ['xs', 'sm']
									}
								>
									<div
										className={`${classes.menuButtonCell} ${isReadOnly ? classes.disabledBackgroundColor : ''
											}`}
									>
										<IconButton
											id={`timesheet-entry--${index}--menu`}
											className={classes.menuButton}
											color='default'
											aria-label='More options'
											onClick={handleClick}
											disabled={isReadOnly ? true : false}
										>
											<MoreVert />
										</IconButton>
										<Menu
											id='timesheetEntry-menu'
											anchorEl={anchorEl}
											keepMounted
											open={Boolean(anchorEl)}
											onClose={handleClose}
										>
											{renderBreakMenuItem()}
											{renderCopyADayMenuItem()}
											{renderRemoveMenuItem()}
											{renderCallBackMenuItem()}
										</Menu>
										{renderCopyADayMenu()}
									</div>
								</Hidden>
							</Grid>
							{renderCallBackConnector()}
						</Grid>
					</Grid>
				</Grid>
				{renderBreaks()}
			</Grid>
		</>
	);
}

TimesheetEntry.propTypes = {
	timesheetEntry: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		timesheetId: PropTypes.number,
		locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		rateTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		relatedEntryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		orderDateId: PropTypes.number,
		units: PropTypes.string,
		startTime: PropTypes.string,
		endTime: PropTypes.string,
		totalTimeInHours: PropTypes.number,
		comment: PropTypes.string,
		onEntryRowDateChange: PropTypes.func,
		timesheetBreaks: PropTypes.array,
	}).isRequired,
};

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(TimesheetEntry))));
