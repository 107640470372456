const mdtitles = [
	{
		value: 'CNM',
		label: 'CNM',
	},
	{
		value: 'CNS',
		label: 'CNS',
	},
	{
		value: 'CRNA',
		label: 'CRNA',
	},
	{
		value: 'DNP',
		label: 'DNP',
	},
	{
		value: 'DO',
		label: 'DO',
	},
	{
		value: 'DPM',
		label: 'DPM',
	},
	{
		value: 'MD',
		label: 'MD',
	},
	{
		value: 'NP',
		label: 'NP',
	},
	{
		value: 'PA',
		label: 'PA',
	},
	{
		value: 'PhD',
		label: 'PhD',
	},
	{
		value: 'PsyD',
		label: 'PsyD',
	},
	{
		value: 'RN',
		label: 'RN',
	},
	{
		value: 'SFA',
		label: 'SFA',
	},
];

const Dentist_Titles = [
	{
		value: 'DDS',
		label: 'DDS',
	},
	{
		value: 'DMD',
		label: 'DMD',
	},
	{
		value: 'MD',
		label: 'MD',
	},
	{
		value: 'DO',
		label: 'DO',
	},
];

const Physician_Titles = [
	{
		value: 'MD',
		label: 'MD',
	},
	{
		value: 'DO',
		label: 'DO',
	},
	{
		value: 'Resident',
		label: 'Resident',
	},
	{
		value: 'DMD',
		label: 'DMD',
	},
	{
		value: 'DDS',
		label: 'DDS',
	},
];

const Podiatrist_Titles = [
	{
		value: 'DPM',
		label: 'DPM',
	},
];

const Psychologist_Titles = [
	{
		value: 'PhD',
		label: 'PhD',
	},
	{
		value: 'PsyD',
		label: 'PsyD',
	},
	{
		value: 'LCSW',
		label: 'LCSW',
	},
];

const CNM_Titles = [
	{
		value: 'CNM',
		label: 'CNM',
	},
	{
		value: 'DNP',
		label: 'DNP',
	},
	{
		value: 'NP',
		label: 'NP',
	},
	{
		value: 'RN',
		label: 'RN',
	},
];

const CNS_Titles = [
	{
		value: 'CNS',
		label: 'CNS',
	},
	{
		value: 'DNP',
		label: 'DNP',
	},
	{
		value: 'NP',
		label: 'NP',
	},
	{
		value: 'RN',
		label: 'RN',
	},
];

const CRNA_Titles = [
	{
		value: 'CRNA',
		label: 'CRNA',
	},
	{
		value: 'DNAP',
		label: 'DNAP',
	},
	{
		value: 'NP',
		label: 'NP',
	},
	{
		value: 'RN',
		label: 'RN',
	},
];

const NP_Titles = [
	{
		value: 'CNM',
		label: 'CNM',
	},
	{
		value: 'CNS',
		label: 'CNS',
	},
	{
		value: 'CRNA',
		label: 'CRNA',
	},
	{
		value: 'DNP',
		label: 'DNP',
	},
	{
		value: 'NP',
		label: 'NP',
	},
	{
		value: 'PhD',
		label: 'PhD',
	},
	{
		value: 'RN',
		label: 'RN',
	},
	{
		value: 'SFA',
		label: 'SFA',
	},
];

const PA_Titles = [
	{
		value: 'PA',
		label: 'PA',
	},
	{
		value: 'SFA',
		label: 'SFA',
	},
];

const SFA_Titles = [
	{
		value: 'SFA',
		label: 'SFA',
	},
	{
		value: 'RN',
		label: 'RN',
	},
	{
		value: 'PA',
		label: 'PA',
	},
	{
		value: 'NP',
		label: 'NP',
	},
	{
		value: 'CCP',
		label: 'CCP',
	},
];

const PERFUSIONIST_Titles = [
	{
		value: 'CCP',
		label: 'CCP',
	},
];

const emergencyContactRelationship = [
	{
		value: null,
		label: '',
	},
	{
		value: 'Family Member',
		label: 'Family Member',
	},
	{
		value: 'Spouse/Partner',
		label: 'Spouse/Partner',
	},
	{
		value: 'Friend',
		label: 'Friend',
	},
	{
		value: 'Co-Worker',
		label: 'Co-Worker',
	},
];

const visaTypes = [
	{
		value: null,
		label: '',
	},
	{
		value: 'EAD Card',
		label: 'EAD Card',
	},
	{
		value: 'Green Card',
		label: 'Green Card',
	},
	{
		value: 'H1-B Visa',
		label: 'H1-B Visa',
	},
	{
		value: 'F-1 Visa',
		label: 'F-1 Visa',
	},
	{
		value: 'J-1 Visa',
		label: 'J-1 Visa',
	},
	{
		value: 'O-1 Visa',
		label: 'O-1 Visa',
	},
	{
		value: 'E-2 Visa',
		label: 'E-2 Visa',
	},
];

const visaStatus = [
	{
		value: null,
		label: '',
	},
	{
		value: 'Active',
		label: 'Active',
	},
	{
		value: 'In Process',
		label: 'In Process',
	},
	{
		value: 'Expired',
		label: 'Expired',
	},
];
export {
	mdtitles,
	emergencyContactRelationship,
	visaTypes,
	visaStatus,
	Dentist_Titles,
	Physician_Titles,
	Podiatrist_Titles,
	CNM_Titles,
	CNS_Titles,
	CRNA_Titles,
	NP_Titles,
	PA_Titles,
	SFA_Titles,
	Psychologist_Titles,
	PERFUSIONIST_Titles,
};
