import React, { useState, useRef } from 'react';
import WithContexts from '../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import RequiredQuestionDialog from './RequiredQuestionDialog';
import { Prompt, useLocation, useHistory } from 'react-router-dom';

function RouteDeparturePrompt({ t, when, setTabHandlerFromDialog, blockedBackNavigation }) {
	const [modalVisible, setModalVisible] = useState(false);
	const [lastLocation, setLastLocation] = useState(null);
	const confirmedNavigation = useRef(false);

	const currentLocation = useLocation();
	const history = useHistory();

	const handleBlockedNavigation = (nextLocation) => {
		if (!confirmedNavigation.current && nextLocation.pathname !== currentLocation.pathname) {
			setLastLocation(nextLocation);
			setModalVisible(true);
			return false;
		}
		return true;
	};

	const handleNavigationClick = (btnPressed) => {
		//console.log(`btnPressed: ${btnPressed}`);
		setModalVisible(false);
		if (btnPressed === 'Leave') {
			if (lastLocation) {
				if (
					lastLocation.pathname === '/credentials/education' &&
					!!setTabHandlerFromDialog
				) {
					setTabHandlerFromDialog();
				}

				confirmedNavigation.current = true;
				history.push(lastLocation.pathname);
			}
		} else {
			if (!!blockedBackNavigation) {
				console.log('@@@ Calling blockedBackNavigation');
				blockedBackNavigation();
			}
		}
	};

	return (
		<>
			<Prompt when={when} message={handleBlockedNavigation} />
			<RequiredQuestionDialog
				open={modalVisible}
				title={t(`common:ROUTE_DEPARTURE.CONFIRM_TITLE`)}
				text={t(`common:ROUTE_DEPARTURE.CONFIRM_MESSAGE`)}
				buttonTexts={t('common:BUTTONS.LEAVE') + ';' + t('common:BUTTONS.STAY')}
				buttonIDs='Leave;Stay'
				disableBackgroundClick={true}
				onClose={(btnPressed) => {
					handleNavigationClick(btnPressed);
				}}
			/>
		</>
	);
}

export default WithContexts(withTranslation()(RouteDeparturePrompt));
//https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
