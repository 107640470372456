/**
 * @file Root footer component.
 * @TODO add proptypes.
 */
import React, { Component } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { COLORS } from '../../../utils/Application_Constants';
import MobileFooter from '../MobileFooter/MobileFooter';
import DesktopFooter from '../DesktopFooter/DesktopFooter';
import NavigationService from '../../../services/NavigationService';
import { ltdomain } from '../../../utils/helpers';

const styles = (theme) => ({
	root: {
		backgroundColor: COLORS.DENIM,
		color: COLORS.WHITE,
		width: '100%',
	},
	footNotes: {
		fontSize: '14px',
		display: 'flex',
		paddingRight: '32px',
		[theme.breakpoints.up('md')]: {
			paddingRight: '39px',
		},
	},
	footNoteItem: {
		width: 'inherit',
	},
	footNoteText: {
		fontSize: '14px',
	},
	footerBottom: {
		background: COLORS.ST_TROPAZ,
		padding: '5px 0px',
	},
});

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			footerMenuNavigation: [],
		};
		this.currentYear = new Date().getFullYear();
	}

	componentDidMount() {
		const menuNavigation = NavigationService.getFooterNavigation();
		const state = { ...this.state };
		state.footerMenuNavigation = menuNavigation.footer;
		this.setState({ ...state });
	}

	render() {
		const { history } = this.props;
		if (history.location.pathname === '/') {
			return null;
		}
		const { classes } = this.props;
		const { footerMenuNavigation } = this.state;
		return (
			<>
				<div className={classes.root}>
					<Hidden only={['md', 'lg', 'xl']}>
						<MobileFooter menuNavigation={footerMenuNavigation} />
					</Hidden>
					<Hidden only={['xs', 'sm']}>
						<DesktopFooter menuNavigation={footerMenuNavigation} />
					</Hidden>
					<div className='footerBottom'>
						<Grid container>
							<Grid item md={8}>
								<ul className='list-inline footer-bottom-info'>
									<li>&copy; {` ${this.currentYear} LocumTenens.com`}</li>
									<li>{`v: ${process.env.REACT_APP_BUILDNUMBER}`}</li>
									<li>
										<a href={ltdomain() + '/privacy/'}>Privacy policy</a>
									</li>
								</ul>
							</Grid>
							<Grid item md={4}>
								{/* <a href="https://www.facebook.com/LocumTenenscom/" target="_blank"><i className="fa fa-facebook-square footer-icons" aria-hidden="true"></i></a>
                  <a href="https://twitter.com/LocumTenens_com" target="_blank"><i className="fa fa-twitter-square footer-icons" aria-hidden="true"></i></a>
                  <a href="https://www.linkedin.com/company/locumtenens.com" target="_blank"><i className="fa fa-linkedin-square footer-icons" aria-hidden="true"></i></a>
                  <a href="https://www.instagram.com/LocumTenens_com/" target="_blank"><i className="fa fa-instagram footer-icons" aria-hidden="true"></i></a> */}
							</Grid>
						</Grid>
					</div>
				</div>
			</>
		);
	}
}
Footer.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
		footNotes: PropTypes.string.isRequired,
		footNoteItem: PropTypes.string.isRequired,
		footNoteText: PropTypes.string.isRequired,
	}).isRequired,
};
export default withRouter(withStyles(styles)(Footer));
