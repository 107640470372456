import React, { Component, Fragment } from 'react';
import { withStyles, Hidden } from '@material-ui/core';
import { MailOutline, Phone } from '@material-ui/icons';
import { COLORS } from '../../../utils/Application_Constants';
import FacilityContactsAddDialog from '../FacilityContactsDialogs/FacilityContactsAddDialog';

const styles = (theme) => ({
	desktopWrapper: {
		display: 'flex',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		paddingLeft: '20px',
		marginTop: '32px',
		justifyContent: 'space-between',
	},
	infoPanel: {
		display: 'flex',
		width: '95%',
	},
	mobileWrapper: {
		display: 'flex',
		padding: '0 12px',
	},
	innerCardMobileWrapper: {
		padding: '10px 20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		height: '80px',
		[theme.breakpoints.down('xs')]: {
			padding: '10px 10px',
		},
	},
	initialCircle: {
		backgroundColor: COLORS.BOMBAY,
		alignItems: 'center',
		borderRadius: '30px',
		width: '30px',
		height: '30px',
		marginTop: '3px',
		display: 'flex',
		justifyContent: 'center',
	},
	initials: {
		color: COLORS.WHITE,
		fontSize: '13px',
	},
	desktopEditWrapper: {
		paddingRight: '20px',
	},
	contactCard: {
		paddingLeft: '11px',
		paddingBottom: '40px',
		width: '86%',
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '0',
			paddingLeft: '0',
			display: 'flex',
			width: '100%',
		},
	},
	displayName: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
	},
	title: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '14px',
		textAlign: 'left',
	},
	workLocation: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '14px',
		textAlign: 'left',
	},
	contactWrapper: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
	},
	mobileTitleWrapper: {
		marginLeft: '15px',
	},
	communicationInformation: {
		marginTop: '10px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '0px',
			marginLeft: '15px',
		},
	},
	contactIcon: {
		fontSize: '16px',
		marginRight: '5px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
		[theme.breakpoints.down('sm')]: {
			marginLeft: '10px',
		},
	},
	anchorWrapper: {
		[theme.breakpoints.up('md')]: {
			width: '88%',
			textAlign: 'left',
			overflowWrap: 'break-word',
		},
	},
});

class ContactCard extends Component {
	desktopCard = () => {
		const { classes, contact, facilityId, primaryWorkLocation } = this.props;
		return (
			<div className={classes.desktopWrapper}>
				<div className={classes.infoPanel}>
					<div>
						<span className={`${classes.initials} ${classes.initialCircle}`}>
							{contact.initials}
						</span>
					</div>
					<div className={classes.contactCard}>
						<div className={classes.displayName}>{contact.displayName}</div>
						{facilityId && contact.editable === false ? (
							<div className={classes.workLocation}>{primaryWorkLocation}</div>
						) : null}
						<div className={classes.title}>{contact.title}</div>
						<div className={classes.communicationInformation}>
							<div className={classes.contactWrapper}>
								<MailOutline
									classes={{ root: classes.contactIcon }}
									color='primary'
								/>
								<a
									className={classes.anchorWrapper}
									href={`mailto:${contact.emailAddress}`}
								>
									<span className={classes.clickableSpan}>
										{contact.emailAddress}
									</span>
								</a>
							</div>
							<div className={classes.contactWrapper}>
								<Phone classes={{ root: classes.contactIcon }} color='primary' />
								<a href={`tel:${contact.phoneNumber}`}>
									<span className={classes.clickableSpan}>
										{contact.phoneNumber}
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className={classes.desktopEditWrapper}>{this.renderEditIcon()}</div>
			</div>
		);
	};

	mobileCard = () => {
		const { classes, contact, facilityId, primaryWorkLocation } = this.props;
		return (
			<div className={classes.cardMobileWrapper}>
				<div className={classes.innerCardMobileWrapper}>
					<div className={classes.contactCard}>
						<div className={classes.initialCircle}>
							<span className={classes.initials}>{contact.initials}</span>
						</div>
						<div className={classes.mobileTitleWrapper}>
							<div className={classes.displayName}>{contact.displayName}</div>
							{facilityId && contact.editable === false ? (
								<div className={classes.workLocation}>{primaryWorkLocation}</div>
							) : null}
							<div className={classes.title}>{contact.title}</div>
						</div>
					</div>
					<div className={classes.communicationInformation}>
						<a href={`tel:${contact.phoneNumber}`}>
							<span className={classes.clickableSpan}>Phone</span>
						</a>
						<a href={`mailto:${contact.emailAddress}`}>
							<span className={classes.clickableSpan}>Email</span>
						</a>
					</div>
					{this.renderEditIcon()}
				</div>
			</div>
		);
	};

	renderEditIcon() {
		const { contact, editable, facilityId, updateAssignments } = this.props;
		if (editable && contact.editable) {
			return (
				<FacilityContactsAddDialog
					facilityId={facilityId}
					contact={contact}
					updateAssignments={updateAssignments}
					edit
				/>
			);
		}
		return null;
	}

	render() {
		const { contact } = this.props;
		return (
			<Fragment key={contact.initials}>
				<Hidden only={['xs', 'sm']}>{this.desktopCard()}</Hidden>
				<Hidden only={['md', 'lg', 'xl']}>{this.mobileCard()}</Hidden>
			</Fragment>
		);
	}
}

export default withStyles(styles)(ContactCard);
