/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, withStyles, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import TimesheetsList from './TimesheetsList';
import TimesheetsErrors from './TimesheetsErrors';
import { COLORS } from '../../../utils/Application_Constants';
import { getOutstandingTimesheets, saveTimesheet } from '../../../services/TimeAndPayService';
import { TimesheetStatusEnumApi } from './Status.enum';

const styles = (theme) => ({
	root: {
		background: COLORS.LT_MIDNIGHTBG1,
	},
	ContainerRoot: {
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '16px',
			paddingRight: '16px',
		},
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column',
		gap: '40px',
	},
	header: {
		fontWeight: '700',
		margin: 0,
	},
});

const TimesheetsDashboard = ({ classes, t, UserContext, setRefreshNotifications }) => {
	const [outstandingTimesheets, setOutstandingTimesheets] = useState([]);
	const [paidTimesheets, setPaidTimesheets] = useState([]);
	const [isOutstandingLoading, setIsOutstandingLoading] = useState(false);
	const [weekEndings, setWeekEndings] = useState([]);
	const [isPaidLoading, setIsPaidLoading] = useState(false);
	const { externalId } = UserContext;

	const fetchOutstandingTimesheets = async () => {
		setIsOutstandingLoading(true);
		const statusList = [
			TimesheetStatusEnumApi.DidNotWork,
			TimesheetStatusEnumApi.NotStarted,
			TimesheetStatusEnumApi.PendingSubmission,
			TimesheetStatusEnumApi.Submitted,
			TimesheetStatusEnumApi.Rejected,
			TimesheetStatusEnumApi.PeopleNetTimesheet
		];
		try {
			const response = await getOutstandingTimesheets(externalId, statusList);
			const { bookings } = response;
			const outstandingTimesheets = combineBookingsTimesheets(bookings);
			setOutstandingTimesheets(outstandingTimesheets);
			const allWeekEndings = outstandingTimesheets?.map(({ weekEnding, bookingId }) => ({
				weekEnding,
				bookingId,
			}));
			setWeekEndings(allWeekEndings);
			setIsOutstandingLoading(false);
		} catch (error) {
			setIsOutstandingLoading(false);
			console.error(error);
		}
	};
	const fetchPaidTimesheets = async () => {
		setIsPaidLoading(true);
		const statusList = [TimesheetStatusEnumApi.Approved, TimesheetStatusEnumApi.Paid];
		const numberWeeks = 104;
		try {
			const response = await getOutstandingTimesheets(externalId, statusList, numberWeeks);
			const { bookings } = response;
			const paidTimesheets = combineBookingsTimesheets(bookings);
			setPaidTimesheets(paidTimesheets);
			setIsPaidLoading(false);
		} catch (error) {
			setIsPaidLoading(false);
			console.error(error);
		}
	};

	useEffect(() => {
		fetchOutstandingTimesheets();
		fetchPaidTimesheets();
	}, []);

	const combineBookingsTimesheets = (bookings) => {
		let timesheets = [];
		if (!bookings || bookings.length === 0) return timesheets;
		bookings.forEach((booking) => {
			timesheets = [...timesheets, ...booking.timesheets];
		});
		return timesheets.sort((a, b) => new Date(b.weekEnding) - new Date(a.weekEnding));
	};

	const onDidNotWorkTimesheetStatusUpdate = (timesheet, timesheetId = null) => {
		try {
			setIsOutstandingLoading(true);
			setIsPaidLoading(true);
			saveTimesheet(timesheet, timesheetId).then(() => {
				fetchOutstandingTimesheets();
				fetchPaidTimesheets();
				if (setRefreshNotifications){
					setRefreshNotifications();
				}
			});
			
		} catch (error) {
			console.error(error);
		}
	};

	const refreshTimesheets = () => {
		try {
			fetchOutstandingTimesheets();
			fetchPaidTimesheets();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className={classes.root}>
			<Container maxWidth='xl' className={classes.ContainerRoot}>
				<Typography variant='h3' component='h3' className={classes.header}>
					{t('submitTimesheet:HEADERS.MY_TIMESHEETS')}
				</Typography>
				{!isOutstandingLoading && (
					<TimesheetsErrors
						timesheets={outstandingTimesheets}
						weekEndings={weekEndings}
					/>
				)}
				<TimesheetsList
					title={t('submitTimesheet:HEADERS.OUTSTANDING_TIMESHEETS')}
					id='timesheet-list--outstanding'
					status='Outstanding'
					timesheets={outstandingTimesheets}
					isLoading={isOutstandingLoading}
					weekEndings={weekEndings}
					onDidNotWorkTimesheetStatusUpdate={onDidNotWorkTimesheetStatusUpdate}
					refreshTimesheets={refreshTimesheets}
				/>
				<TimesheetsList
					title={t('submitTimesheet:HEADERS.PAID_TIMESHEETS')}
					id='timesheet-list--paid-and-approved'
					status='PaidAndApproved'
					timesheets={paidTimesheets}
					isLoading={isPaidLoading || isOutstandingLoading}
					weekEndings={weekEndings}
					onDidNotWorkTimesheetStatusUpdate={onDidNotWorkTimesheetStatusUpdate}
				/>
			</Container>
		</div>
	);
};

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(TimesheetsDashboard))));
