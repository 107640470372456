import React, { Component } from 'react';
import { withStyles, Box } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AssignmentDetailCard from '../AssignmentDetailCard/AssignmentDetailCard';
import AssignmentListItem from '../AssignmentListItem/AssignmentListItem';
import AssignmentListEmptyState from '../AssignmentListItem/AssignmentListEmptyState';
import { COLORS } from '../../../utils/Application_Constants';

const styles = () => ({
	header: {
		color: COLORS.WATERLOO,
		fontWeight: '700',
		fontSize: '18px',
		textAlign: 'left',
	},
	activeAssignmentHeading: {
		// width: '90%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	activeAssignmentHeadingOld: {
		//width: '90%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
});

class ActiveAssignmentDashboard extends Component {
	toggleAssignmentView = () => {
		const { history, assignments } = this.props;
		history.push(`/assignments/active/${assignments[1].id}`);
	};

	renderAssignmentsConditionally = () => {
		const { assignments } = this.props;
		if (!assignments) {
			return <AssignmentListEmptyState assignmentType='active' />;
		}
		const assignmentCount = assignments.length;
		if (assignmentCount === 0) {
			return <AssignmentListEmptyState assignmentType='active' />;
		}
		if (assignmentCount === 1) {
			return <AssignmentDetailCard dashboard type='active' assignment={assignments[0]} />;
		}
		if (assignmentCount >= 2) {
			return (
				<>
					<Box mb={2}>
						<AssignmentDetailCard dashboard type='active' assignment={assignments[0]} />
					</Box>
					<AssignmentListItem
						toggleAssignmentView={this.toggleAssignmentView}
						assignment={assignments[1]}
						type='active'
					/>
				</>
			);
		}
	};

	renderAssignmentTitleConditonally = () => {
		const { assignments, classes } = this.props;
		if (!assignments) {
			return null;
		}
		if (assignments && assignments.length === 0) {
			return (
				<>
					<div>
						<span className='tileHeader'>Active Assignments({assignments.length})</span>
						{/* <div className={classes.activeAssignmentHeading}>
                  <h1 className={classes.header}>Active Assignments({assignments.length})</h1>
                </div> */}
					</div>
				</>
			);
		}

		return (
			<div className={classes.activeAssignmentHeading}>
				<span className='tileHeader'>Active Assignments({assignments.length})</span>
				<Link
					to='/assignments/active'
					className='textLink'
					style={{ marginBottom: '.5rem' }}
				>
					View All
				</Link>
			</div>
		);
	};

	render() {
		return (
			<>
				{this.renderAssignmentTitleConditonally()}
				{this.renderAssignmentsConditionally()}
			</>
		);
	}
}

ActiveAssignmentDashboard.propTypes = {
	assignments: PropTypes.array,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	classes: PropTypes.shape({
		clickableSpan: PropTypes.string.isRequired,
		activeAssignmentHeading: PropTypes.string.isRequired,
		header: PropTypes.string.isRequired,
	}).isRequired,
};
export default withRouter(withStyles(styles)(ActiveAssignmentDashboard));
