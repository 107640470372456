import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export const tableColumns = [
	{ name: 'CC Account', key: 'account', size: 2, sortKey: 'account' },
	{ name: 'Card name', key: 'name', size: 2, sortKey: 'name' },
	{ name: 'Division', key: 'division', size: 2, sortKey: 'division' },
	{ name: 'Category', key: 'category', size: 2, sortKey: 'category' },
	{ name: 'Type', key: 'type', size: 1, sortKey: 'type' },
	{ name: 'Status', key: 'status', size: 2, sortKey: 'status' },
	{ name: 'Actions', key: 'actions', size: 1 },
];

export const actions = [
	{ name: 'Edit', icon: <EditIcon fontSize='small' /> },
	{ name: 'Add Users', icon: <PersonAddIcon fontSize='small' /> },
];
