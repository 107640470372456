import React, { Component } from 'react';
import {
	withStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Hidden,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';

const styles = (theme) => ({
	dialogHeaderRoot: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE_LILAC,
		justifyContent: 'left',
	},
	dialogContentRoot: {
		margin: '30px 0',
		textAlign: 'center',
		color: COLORS.RAVEN,
		fontWeight: '750',
		fontSize: '24px',
		whiteSpace: 'nowrap',
	},
	deleteButton: {
		lineHeight: 'inherit',
		color: `${COLORS.MILANO_RED}`,
		backgroundColor: `${COLORS.WHITE}`,
		marginLeft: '25px',
		marginBottom: '15px',
		fontWeight: '700',
		width: '150px',
		textTransform: 'inherit',
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			margin: '0 15px',
		},
	},
	noButton: {
		fontWeight: '700',
		lineHeight: 'inherit',
		color: `${COLORS.TRUE_BLUE}`,
		backgroundColor: `${COLORS.WHITE}`,
		border: `1px solid ${COLORS.TRUE_BLUE}`,
		width: '200px',
		margin: '20px',
		textTransform: 'inherit',
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			margin: '0 15px',
		},
	},
	yesButton: {
		fontWeight: '700',
		lineHeight: 'inherit',
		backgroundColor: `${COLORS.TRUE_BLUE}`,
		color: `${COLORS.WHITE}`,
		width: '200px',
		margin: '20px',
		textTransform: 'inherit',
		[theme.breakpoints.down('xs')]: {
			width: '90%',
		},
	},
	mobileButtonWrapper: {
		width: '100%',
		marginTop: '20px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	desktopButtonWrapper: {
		marginTop: '30px',
		justifyContent: 'left',
	},
	actionButtonWrapper: {
		width: '100%',
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
		},
	},
});

class FacilityContactsDeleteDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	openDialog = () => {
		this.setState({ open: true });
	};

	closeDialog = () => {
		this.setState({ open: false });
	};

	deleteContact = () => {
		const { deleteContact } = this.props;
		deleteContact();
		this.closeDialog();
	};

	renderButtons() {
		const { classes, t } = this.props;
		return (
			<>
				<div className={classes.actionButtonWrapper}>
					<Button classes={{ root: classes.noButton }} onClick={this.closeDialog}>
						{t('common:BUTTONS.NO')}
					</Button>
					<Button classes={{ root: classes.yesButton }} onClick={this.deleteContact}>
						{t('common:BUTTONS.YES')}
					</Button>
				</div>
			</>
		);
	}

	renderActionButtons() {
		const { classes } = this.props;
		return (
			<>
				<Hidden only={['xs']}>
					<DialogActions classes={{ root: classes.desktopButtonWrapper }}>
						{this.renderButtons()}
					</DialogActions>
				</Hidden>
				<Hidden only={['sm', 'md', 'lg', 'xl']}>
					<div className={classes.mobileButtonWrapper}>{this.renderButtons()}</div>
				</Hidden>
			</>
		);
	}

	render() {
		const { classes, t } = this.props;
		const state = { ...this.state };
		return (
			<>
				<Button classes={{ root: classes.deleteButton }} onClick={this.openDialog}>
					{t('common:BUTTONS.DELETE')}
				</Button>
				<Dialog open={state.open} fullWidth scroll='body'>
					<DialogTitle classes={{ root: classes.dialogHeaderRoot }}>
						{t('contacts:EDIT.DELETE_CONTACT')}
					</DialogTitle>
					<DialogContent classes={{ root: classes.dialogContentRoot }}>
						{t('contacts:EDIT.VERIFY_DELETE')}
					</DialogContent>
					{this.renderActionButtons()}
				</Dialog>
			</>
		);
	}
}

export default withTranslation()(withStyles(styles)(FacilityContactsDeleteDialog));
