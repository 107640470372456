const ExpenseStatusEnum = {
	0: { value: 'New', UI: 'New' },
	1: { value: 'InProcess', UI: 'In Process' },
	2: { value: 'ReadyForReview', UI: 'Ready for Review' },
	3: { value: 'Rejected', UI: 'Rejected' },
	4: { value: 'OnHoldMatchPending', UI: 'Match Pending' },
	5: { value: 'OnHoldPendingAirfare', UI: 'Pending Airfare' },
	6: { value: 'OnHoldAirlineCreditOnFile', UI: 'Airline Credit On File' },
	7: { value: 'OnHoldDisputed', UI: 'Disputed' },
	8: { value: 'OnHoldAdvancedDeposit', UI: 'Advanced Deposit' },
	9: { value: 'OnHoldReimbursement', UI: 'Reimbursement' },
	10: { value: 'Abandoned', UI: 'Abandoned' },
	11: { value: 'Matched', UI: 'Matched' },
	12: { value: 'Approved', UI: 'Approved' },
	13: { value: 'Posted', UI: 'Posted' },
};

export { ExpenseStatusEnum };
