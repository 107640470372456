import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useQuery = () => {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default useQuery;
