import React, { Component } from 'react';
import { Grid, withStyles, ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import { Flight } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../../utils/Application_Constants';

const styles = (theme) => ({
	expansionPanelSummaryRoot: {
		padding: '8px 20px',
	},
	clickableLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	clickableLinkTravel: {
		marginBottom: '10px',
	},
	alignLeft: {
		width: '50%',
		float: 'left',
		textAlign: 'left',
	},
	helpNumberNoFights: {
		width: '50%',
		float: 'right',
		textAlign: 'right',
		paddingRight: '35px',
		color: COLORS.TRUE_BLUE,
	},
	bold: {
		fontWeight: 'bold',
	},
	iconClass: {
		verticalAlign: 'middle',
		paddingRight: '6px',
		color: COLORS.TRUE_BLUE,
		transform: 'rotate(90deg)',
	},
	medIconClass: {
		marginTop: '26px',
		color: COLORS.TRUE_BLUE,
		height: '32px',
		width: '32px',
		transform: 'rotate(90deg)',
	},
	flightCard: {
		marginTop: '16px',
		backgroundColor: COLORS.DENIM,
		minWidth: '100%',
	},
	fullWidth: {
		minWidth: '100%',
	},
	airportCode: {
		fontWeight: 'bold',
		fontSize: '24px',
	},
	cellConfHeader: {
		color: COLORS.WHITE,
		padding: '10px 8px 10px 8px',
		textAlign: 'right',
	},
	cellDateHeader: {
		color: COLORS.WHITE,
		fontWeight: 'bold',
		padding: '10px 8px 10px 8px',
		textAlign: 'left',
	},
	cellHeading: {
		color: COLORS.RAVEN,
		fontWeight: 'bold',
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		border: 'none',
	},
	cellRoot: {
		color: COLORS.RAVEN,
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		border: 'none',
	},
	cellRightBorder: {
		color: COLORS.RAVEN,
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		borderRight: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	bottomBorder: {
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	cellItinerary: {
		color: COLORS.RAVEN,
		padding: '10px 8px 10px 8px',
		textAlign: 'center',
		borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	commentBlock: {
		fontWeight: '700',
		fontStyle: 'italic',
		color: COLORS.RAVEN,
		fontSize: '14px',
		margin: '5px 15px 0 15px',
	},
	flightItinerary: {
		margin: '15px 15px 0 30px',
	},
});

class Flights extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	justFlights = (travelInfo) => {
		return travelInfo.filter((item) => item.segmentType === 'A');
	};

	renderNoFlightsWithoutTraveRecord = (travelInfo, classes, t) => {
		return (
			<ExpansionPanel>
				<ExpansionPanelSummary
					classes={{ root: classes.expansionPanelSummaryRoot }}
					aria-controls='travelPanelContent'
					id='travelPanelHeader'
				>
					<div className={classes.alignLeft}>
						<Flight classes={{ root: classes.iconClass }} />
						<span>{t('assignments:TRAVEL.TITLE_ITINERARY')} (0)</span>
					</div>
				</ExpansionPanelSummary>
			</ExpansionPanel>
		);
	};

	renderNoFlights = (travelInfo, classes, t) => {
		return (
			<ExpansionPanel>
				<ExpansionPanelSummary
					classes={{ root: classes.expansionPanelSummaryRoot }}
					aria-controls='travelPanelContent'
					id='travelPanelHeader'
				>
					<div className={classes.alignLeft}>
						<Flight classes={{ root: classes.iconClass }} />
						<span>
							{t('assignments:TRAVEL.FLIGHTS_TITLE')} (
							{this.justFlights(travelInfo).length})
						</span>
					</div>
				</ExpansionPanelSummary>
			</ExpansionPanel>
		);
	};

	render() {
		const { t, classes, travelInfo } = this.props;

		if (travelInfo.length === 0) {
			return this.renderNoFlightsWithoutTraveRecord(travelInfo, classes, t);
		}

		const travelItinarary = [];
		travelInfo.map((item) => {
			return travelItinarary.push(item.eItinLink);
		});

		const uniqueItinarary = [...new Set(travelItinarary)];

		return (
			<ExpansionPanel>
				<ExpansionPanelSummary
					classes={{ root: classes.expansionPanelSummaryRoot }}
					aria-controls='travelPanelContent'
					id='travelPanelHeader'
				>
					<Grid container>
						<Grid container item xs={12} direction='column'>
							<Grid container direction='row'>
								<Grid item container xs={12} direction='row'>
									<div className={classes.alignLeft}>
										<Flight classes={{ root: classes.iconClass }} />
										{/* <span>{t('assignments:TRAVEL.FLIGHTS_TITLE')} ({this.justFlights(travelInfo).length})</span> */}
										<span>
											{t('assignments:TRAVEL.TITLE_ITINERARY')} (
											{uniqueItinarary.length})
										</span>
									</div>
								</Grid>
							</Grid>
							<Grid container direction='row'>
								<Grid item xs={12} classes={{ root: classes.flightItinerary }}>
									{
										<div>
											{uniqueItinarary.map((item) => (
												<div className={classes.clickableLinkTravel}>
													<a
														href={item}
														className={classes.clickableLink}
														target='_blank'
														rel='noopener noreferrer'
													>
														{t(
															'assignments:TRAVEL.VIEW_ITENERARY_LINE'
														)}
													</a>
													<br />
												</div>
											))}
										</div>
									}
									{/*<div>
                    <a href="test" className={classes.clickableLink} target='travel'>{t('assignments:TRAVEL.VIEW_ITENERARY_LINE')}</a> </div>*/}
								</Grid>
							</Grid>
							<Grid container direction='row'>
								<Grid item xs={12} classes={{ root: classes.commentBlock }}>
									<p className={classes.commentBlock}>
										{' '}
										Please check with your flight carrier for the most
										up-to-date information.
									</p>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</ExpansionPanelSummary>
			</ExpansionPanel>
		);

		/*
		 * TDS-1626 - Commenting Flight Travel UI, Uncomment below code to view flight details and remove or comment above temperory changes to comment flight details
		 *
		 */

		/* const { t, classes, travelInfo } = this.props;
 
     if (this.justFlights(travelInfo).length === 0) {
       return this.renderNoFlights(travelInfo, classes, t);
     }
 
     return (
       <ExpansionPanel>
         <ExpansionPanelSummary
           classes={{ root: classes.expansionPanelSummaryRoot }}
           expandIcon={<ExpandMore classes={{ root: classes.chevronRoot }} />}
           aria-controls="travelPanelContent"
           id="travelPanelHeader"
         >
           <div className={classes.alignLeft}>
             <Flight classes={{ root: classes.iconClass }} />
             <span className={classes.panelHeader}>{t('assignments:TRAVEL.FLIGHTS_TITLE')} ({this.justFlights(travelInfo).length})</span>
           </div>
         </ExpansionPanelSummary>
         {this.justFlights(travelInfo).map((item) => (
           <Fragment key={item.tripKey + '-' + item.flightNumber} >
             <Grid container direction='row' classes={{ root: classes.flightCard }}>
               <Grid item xs={6} classes={{ root: classes.cellDateHeader }}>{dateRange(item)}</Grid>
               <Grid item xs={6} classes={{ root: classes.cellConfHeader }}>
                 Confirmation No: {item.confirmationNumber}
               </Grid>
             </Grid>
             <Grid container classes={{ root: classes.bottomBorder }}>
               <Grid item container xs={12} md={6} direction='row' classes={{ root: classes.cellRightBorder }}>
                 <Grid item xs={5}>
                   <Grid container direction='column'>
                     <Grid item xs={12} classes={{ root: classes.cellRoot }}>
                       <span className={classes.airportCode}>{item.originCityCode}</span>
                       <br />{item.originCityDesc}
                       <br /><span className={classes.bold}>{`${moment(item.originatedDate).format('hh:mma')}`}</span>
                     </Grid>
                   </Grid>
                 </Grid>
                 <Grid item xs={1}>
                   <Flight classes={{ root: classes.medIconClass }} />
                 </Grid>
                 <Grid item xs={5}>
                   <Grid container direction='column'>
                     <Grid item xs={12} classes={{ root: classes.cellRoot }}>
                       <span className={classes.airportCode}>{item.destinationCityCode}</span>
                       <br />{item.destCityDesc}
                       <br /><span className={classes.bold}>{`${moment(item.completedDate).format('hh:mma')}`}</span>
                     </Grid>
                   </Grid>
                 </Grid>
               </Grid>
               <Grid container item xs={12} md={6} direction='column'>
                 <Grid container direction='row'>
                   <Grid item xs={6} classes={{ root: classes.cellHeading }}>{t('assignments:TRAVEL.AIRLINE_HEADING')}</Grid>
                   <Grid item xs={6} classes={{ root: classes.cellHeading }}>{t('assignments:TRAVEL.FLIGHT_HEADING')}</Grid>
                 </Grid>
                 <Grid container direction='row'>
                   <Grid item xs={6} classes={{ root: classes.cellRoot }}>{item.vendorName}</Grid>
                   <Grid item xs={6} classes={{ root: classes.cellRoot }}>{item.flightNumber}</Grid>
                 </Grid>
                 <Grid container direction='row'>
                   <Grid item xs={12} classes={{ root: classes.cellItinerary }}>
                     <a href={item.eItinLink} className={classes.clickableLink} target='travel'>{t('assignments:TRAVEL.VIEW_ITENERARY_LINE')}</a>
                   </Grid>
                 </Grid>
               </Grid>
             </Grid>
           </Fragment>
         ))}
       </ExpansionPanel>
     );*/
	}
}

export default withTranslation()(withStyles(styles)(Flights));
