import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
	IconButton,
	withStyles,
	Grid,
	Hidden,
	TextField,
	MenuItem,
	Menu,
	Typography,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import { COLORS } from '../../../utils/Application_Constants';
import { MoreVert, ExpandMore, DeleteOutline, MoreHoriz } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { AdminAuthContext } from '../../../Admin/Contexts/AdminAuthContext';
import CopyADayMenuItem from './CopyADayMenuItem';
import CopyADayMenu from './CopyADayMenu';

const Placeholder = ({ label }) => <span className='select-placeholder'>{label}</span>;

const styles = (theme) => ({
	entryCell: {
		'&:first-child': {
			'& .MuiSelect-outlined': {
				borderTopLeftRadius: '8px',
				[theme.breakpoints.up('md')]: {
					borderBottomLeftRadius: '8px',
				},
				[theme.breakpoints.down('md')]: {
					borderRight: 'none',
				},
			},
		},
		'&:nth-child(2)': {
			'& .MuiSelect-outlined': {
				[theme.breakpoints.down('sm')]: {
					borderTopRightRadius: '8px',
				},
			},
		},
	},
	menuButtonCell: {
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		padding: '2px',
		borderTopRightRadius: '8px',
		borderBottomRightRadius: '8px',
		overflow: 'hidden',
	},
	disabledBackgroundColor: {
		background: COLORS.LT_MIDNIGHTBG2,
	},
	formControl: {
		marginBottom: '0',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
			borderRadius: '8px',
		},
	},
	menuButton: {
		color: COLORS.LT_MIDNIGHT25,
	},
	menuIcon: {
		color: COLORS.LT_MIDNIGHT25,
		display: 'flex',
		alignItems: 'center',
	},
	menuIconRemove: {
		color: COLORS.LT_PEPPER,
	},
	infoIcon: {
		color: COLORS.LT_MIDNIGHT25,
		height: '12px',
	},
	expandMore: {
		color: COLORS.LT_MIDNIGHT25,
		height: '20px',
		right: '8px',
		top: 'calc(50% - 10px)',
	},
	input: {
		padding: '16px !important',
		height: 'auto',
		fontSize: '14px',
		lineHeight: '20px',
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		[theme.breakpoints.up('md')]: {
			borderRight: 'none',
			'&::placeholder': {
				opacity: 0,
			},
		},
		[theme.breakpoints.down('sm')]: {
			borderBottom: 'none',
		},
		'&$disabled': {
			background: COLORS.LT_MIDNIGHTBG2,
		},
	},
	inputRoot: {
		'&$disabled $notchedOutline': {
			borderColor: 'transparent',
		},

		'&$disabled $input': {
			background: COLORS.LT_MIDNIGHTBG2,
			color: COLORS.LT_MIDNIGHT33,
		},
	},
	selectOutlined: {
		padding: '16px 24px 16px 16px !important',
		fontSize: '14px',
		lineHeight: '20px',
		borderRadius: '0px',
		borderColor: COLORS.LT_MIDNIGHT5,
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		[theme.breakpoints.up('md')]: {
			borderRight: 'none',
			'& .select-placeholder': {
				opacity: 0,
			},
		},
		[theme.breakpoints.down('sm')]: {
			borderBottom: 'none',
		},
		'& .select-placeholder': {
			color: COLORS.LT_MIDNIGHT33,
		},
		'&$disabled': {
			background: COLORS.LT_MIDNIGHTBG2,
			color: COLORS.LT_MIDNIGHT33,
		},
	},
	disabled: {},
	notchedOutline: {},
	commentsInput: {
		flexGrow: '1',
		'& .MuiOutlinedInput-input': {
			borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
			[theme.breakpoints.down('sm')]: {
				borderBottomRightRadius: '8px',
				borderBottomLeftRadius: '8px',
				overflow: 'hidden',
			},
		},
	},
	mobileMenuButton: {
		marginBottom: '4px',
		marginLeft: 'auto',
		display: 'flex',
		justifyContent: 'flex-end',

		'& .MuiIconButton-root': {
			padding: '6px',
		},
	},
});

function MileageEntry(props) {
	const { classes, index, dateLookup, timesheet, mileageEntry, onRemove, isPaidApproved } = props;
	const { errors, watch, getValues, clearErrors } = useFormContext();
	const watchAssignment = watch(`mileageEntries[${index}].locationId`);
	const watchMiles = watch(`mileageEntries[${index}].miles`);
	const watchComment = watch(`mileageEntries[${index}].comment`);
	const [showCopyADayMenu, setShowCopyADayMenu] = useState(false);
	const readOnlyLocations =
		timesheet.timesheetReviews?.map((review) =>
			review.status !== 'NotStarted' &&
			review.status !== 'PendingSubmission' &&
			review.status !== 'Rejected' &&
			review.status !== 'DidNotWork'
				? review.locationId
				: null
		) || [];
	const adminAuth = useContext(AdminAuthContext);
	const isReadOnly =
		readOnlyLocations.indexOf(mileageEntry.locationId) > -1 || adminAuth?.isLTAssociateUser;
	const [anchorEl, setAnchorEl] = useState(null);
	const getAvailableLocation = () => {
		return (
			timesheet.timesheetReviews?.find(
				(review) => !readOnlyLocations.includes(review.locationId)
			)?.locationId || null
		);
	};
	const avaliableLocationId = getAvailableLocation();
	const defaultLocationId =
		timesheet.primaryWorkLocationId &&
		!readOnlyLocations.includes(timesheet.primaryWorkLocationId)
			? timesheet.primaryWorkLocationId
			: avaliableLocationId;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const validateMileage = (value) => {
		let regex;
		const hasDecimal = value.indexOf('.') > -1;
		if (hasDecimal) {
			regex = /^\d{0,4}(?:[.]\d{0,2})/;
		} else {
			regex = /^\d{0,4}/;
		}
		const match = value.match(regex);
		return match ? match[0] : null;
	};

	const renderCopyADayMenuItem = () => {
		return (
			<CopyADayMenuItem
				index={index}
				entryType={'mileage-entry'}
				setAnchorEl={setAnchorEl}
				setShowCopyADayMenu={setShowCopyADayMenu}
				isDisabled={!watchMiles}
			/>
		);
	};

	const renderCopyADayMenu = () => {
		return (
			<CopyADayMenu
				index={index}
				timesheet={timesheet}
				dateLookup={dateLookup}
				entryType={'mileage-entry'}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				showCopyADayMenu={showCopyADayMenu}
				setShowCopyADayMenu={setShowCopyADayMenu}
			/>
		);
	};

	return (
		<>
			<Hidden
				only={
					isPaidApproved || adminAuth?.isLTAssociateUser
						? ['xs', 'sm', 'md', 'lg', 'xl']
						: ['md', 'lg', 'xl']
				}
			>
				<div className={classes.mobileMenuButton}>
					<IconButton
						id={`mileage-entry--${index}--menu`}
						color='default'
						aria-label='More options'
						onClick={handleClick}
						disabled={isReadOnly ? true : false}
					>
						<MoreHoriz />
					</IconButton>
					<Menu
						id='mileageEntry-menu'
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem
							onClick={() => {
								onRemove(getValues().mileageEntries[index], index);
								handleClose();
							}}
							id={`mileage-entry--${index}--menu--list-item--remove`}
						>
							<Grid container alignItems='center' spacing={2}>
								<Grid item className={classes.menuIcon}>
									<DeleteOutline
										fontSize='small'
										className={classes.menuIconRemove}
									/>
								</Grid>
								<Grid item>
									<Typography variant='body2'>Remove</Typography>
								</Grid>
							</Grid>
						</MenuItem>
					</Menu>
				</div>
			</Hidden>
			<Grid container className={classes.entryRow} id={`mileage-entry--${index}`}>
				<Controller name={`mileageEntries[${index}].id`} defaultValue={mileageEntry.id} />
				<Grid className={classes.entryCell} item xs={6} md={2} lg={2}>
					<Controller
						name={`mileageEntries[${index}].transactionDate`}
						defaultValue={mileageEntry.transactionDate}
						rules={{
							required: watchMiles || watchComment ? 'Date is required' : false,
						}}
						render={({ onChange, value, ref }) => (
							<TextField
								id={`mileage-entry--${index}--date`}
								onChange={(e) => {
									clearErrors(`mileageEntries[${index}].transactionDate`);
									onChange(e);
								}}
								select
								value={value}
								className={`${classes.formControl} mileage-entry--cell`}
								ref={ref}
								InputProps={{
									classes: {
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								SelectProps={{
									classes: {
										icon: classes.expandMore,
										outlined: classes.selectOutlined,
										disabled: classes.disabled,
									},
									IconComponent: ExpandMore,
									displayEmpty: true,
									renderValue:
										value !== '' ? null : () => <Placeholder label='Date' />,
								}}
								variant='outlined'
								disabled={isReadOnly}
								error={
									errors['mileageEntries']?.[index]?.transactionDate
										? true
										: false
								}
								helperText={
									errors['mileageEntries']?.[index]?.transactionDate?.message
								}
							>
								{dateLookup.map((date, dateIndex) => (
									<MenuItem
										key={date.value}
										value={date.value}
										id={`mileage-entry--${index}--date--list-item--${dateIndex}`}
									>
										{date.label}
									</MenuItem>
								))}
							</TextField>
						)}
					/>
				</Grid>
				<Grid className={classes.entryCell} item xs={6} md={3}>
					<Controller
						name={`mileageEntries[${index}].locationId`}
						defaultValue={
							mileageEntry.locationId ? mileageEntry.locationId : defaultLocationId
						}
						rules={{
							required:
								watchMiles || watchComment ? 'Work Location is required' : false,
						}}
						render={({ onChange, value, ref }) => (
							<TextField
								id={`mileage-entry--${index}--work-location`}
								onChange={(e) => {
									clearErrors(`mileageEntries[${index}].locationId`);
									onChange(e);
								}}
								select
								value={value}
								className={`${classes.formControl} mileage-entry--cell`}
								ref={ref}
								InputProps={{
									classes: {
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								SelectProps={{
									classes: {
										icon: classes.expandMore,
										outlined: classes.selectOutlined,
										disabled: classes.disabled,
									},
									IconComponent: ExpandMore,
									displayEmpty: true,
									renderValue:
										value !== ''
											? null
											: () => <Placeholder label='Location' />,
								}}
								variant='outlined'
								disabled={isReadOnly}
								error={errors['mileageEntries']?.[index]?.locationId ? true : false}
								helperText={errors['mileageEntries']?.[index]?.locationId?.message}
							>
								{timesheet.booking.locations.map((location, locationIndex) => (
									<MenuItem
										key={location.id}
										value={location.id}
										disabled={readOnlyLocations.indexOf(location.id) > -1}
										id={`mileage-entry--${index}--work-location--list-item--${locationIndex}`}
									>
										{location.displayName}
									</MenuItem>
								))}
							</TextField>
						)}
					/>
				</Grid>
				<Grid className={classes.entryCell} item xs={12} md={1}>
					<Controller
						name={`mileageEntries[${index}].miles`}
						defaultValue={mileageEntry.miles}
						rules={{
							required: watchComment ? 'Miles are required' : false,
						}}
						render={({ onChange, value, ref }) => (
							<TextField
								id={`mileage-entry--${index}--mileage`}
								className={`${classes.formControl} mileage-entry--cell`}
								inputRef={ref}
								onChange={(e) => {
									clearErrors(`mileageEntries[${index}].miles`);
									const newValue = validateMileage(e.target.value);
									if (newValue || newValue === '') {
										onChange(newValue);
									}
								}}
								InputProps={{
									classes: {
										input: classes.input,
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								value={value}
								variant='outlined'
								disabled={isReadOnly}
								error={errors['mileageEntries']?.[index]?.miles ? true : false}
								helperText={errors['mileageEntries']?.[index]?.miles?.message}
								placeholder='Miles'
							/>
						)}
					/>
				</Grid>
				<Grid item className={classes.entryCell} classes={{ root: classes.commentsInput }}>
					<Controller
						name={`mileageEntries[${index}].comment`}
						defaultValue={mileageEntry.comment}
						render={({ onChange, value, ref }) => (
							<TextField
								inputProps={{ maxLength: 255 }}
								id={`mileage-entry--${index}--comments`}
								className={`${classes.formControl} mileage-entry--cell`}
								value={value}
								ref={ref}
								onChange={(e) => {
									clearErrors(`mileageEntries[${index}].comment`);
									onChange(e);
								}}
								InputProps={{
									classes: {
										input: classes.input,
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								variant='outlined'
								disabled={isReadOnly}
								placeholder='Comments'
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<Hidden
						only={
							isPaidApproved || adminAuth?.isLTAssociateUser
								? ['xs', 'sm', 'md', 'lg', 'xl']
								: ['xs', 'sm']
						}
					>
						<div
							className={`${classes.menuButtonCell} ${
								isReadOnly ? classes.disabledBackgroundColor : ''
							}`}
						>
							<IconButton
								id={`mileage-entry--${index}--menu`}
								className={classes.menuButton}
								color='default'
								aria-label='More options'
								onClick={handleClick}
								disabled={isReadOnly ? true : false}
							>
								<MoreVert />
							</IconButton>
							<Menu
								id='mileageEntry-menu'
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								{renderCopyADayMenuItem()}
								<MenuItem
									onClick={() => {
										onRemove(getValues().mileageEntries[index], index);
										handleClose();
									}}
									id={`mileage-entry--${index}--menu--list-item--remove`}
								>
									<Grid container alignItems='center' spacing={2}>
										<Grid item className={classes.menuIcon}>
											<DeleteOutline
												fontSize='small'
												className={classes.menuIconRemove}
											/>
										</Grid>
										<Grid item>
											<Typography variant='body2'>Remove</Typography>
										</Grid>
									</Grid>
								</MenuItem>
							</Menu>
							{renderCopyADayMenu()}
						</div>
					</Hidden>
				</Grid>
			</Grid>
		</>
	);
}

MileageEntry.propTypes = {
	mileageEntry: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		timesheetId: PropTypes.number,
		locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		miles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		transactionId: PropTypes.string,
	}),
};

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(MileageEntry))));
