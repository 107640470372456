import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
} from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../utils/Application_Constants';

const useStyles = makeStyles(() => ({
	dialogPaperRoot: {
		padding: '40px',
		gap: '16px',
	},
	dialogTitleRoot: {
		color: COLORS.LT_FOUNDATION,
		fontSize: '24px',
		fontWeight: '700',
		padding: '0',
	},
	dialogContentRoot: {
		padding: '0',
		'&:first-child': {
			paddingTop: '0',
		},
	},
	dialogGrid: {
		gap: '24px',
	},
	dialogActionsRoot: {
		padding: '0',
	},
	underlinedButton: {
		textDecoration: 'underline',
	},
	cancelButton: {
		backgroundColor: COLORS.LT_PEPPER10,
		color: COLORS.LT_PEPPER,
		'&:hover': {
			backgroundColor: COLORS.LT_PEPPER25,
		},
	},
	dialogErrorIcon: {
		color: COLORS.LT_PEPPER,
		background: COLORS.LT_PEPPER10,
		borderRadius: '50%',
		padding: '16px',
	},
	dialogWarningIcon: {
		color: COLORS.LT_SUNFLOWER,
		background: COLORS.LT_SUNFLOWER25,
		borderRadius: '50%',
		padding: '16px',
	},
}));

const ClearDialog = (props) => {
	const { open, setOpen, handleClear } = props;
	const classes = useStyles();

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby='clear-dialog-title'
			aria-describedby='clear-dialog-description'
			fullWidth
			maxWidth='xs'
			classes={{ paper: classes.dialogPaperRoot }}
		>
			<DialogTitle
				id='clear-dialog-title'
				classes={{ root: classes.dialogTitleRoot }}
				disableTypography
			>
				Are you sure?
			</DialogTitle>
			<DialogContent classes={{ root: classes.dialogContentRoot }}>
				Clearing all your data cannot be undone. Are you sure you would like to clear all
				data?
			</DialogContent>
			<DialogActions classes={{ root: classes.dialogActionsRoot }}>
				<Button
					color='primary'
					className={classes.underlinedButton}
					onClick={() => setOpen(false)}
				>
					No, go back
				</Button>
				<Button
					color='primary'
					className={classes.cancelButton}
					variant='contained'
					onClick={handleClear}
				>
					Clear
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ClearDialog;
