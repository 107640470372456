import React, { Component } from 'react';
import { withStyles, Grid, Paper, Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import NavigationService from '../../../services/NavigationService';

const styles = (theme) => ({
	helpPanelContainerRoot: {
		padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	helpPanelHeader: {
		marginBottom: '10px',
	},
	helpPanelLinks: {
		textAlign: 'left',
		marginBottom: '10px',
	},
	helpPanelButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		paddingBottom: '10px',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
		fontWeight: '700',
	},
	header: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '20px',
		margin: 0,
	},
	linkHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
	},
	link: {
		color: COLORS.TRUE_BLUE,
		marginBottom: '10px',
		fontWeight: '400',
		fontSize: '16px',
		cursor: 'pointer',
		display: 'block',
	},
	topTileRoot: {
		marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	progressButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		whiteSpace: 'nowrap',
	},
});

class ResourcesTile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timeAndPayLinks: [],
		};
	}

	async componentDidMount() {
		const timeAndPayLinks = NavigationService.getTimeAndPayNavigationLinks();
		this.setState({
			timeAndPayLinks: timeAndPayLinks.timeandpay,
		});
	}

	render() {
		const { timeAndPayLinks } = this.state;
		const { classes, t } = this.props;

		return (
			<Paper classes={{ root: classes.helpPanelRoot }} elevation={0}>
				<Grid container classes={{ root: classes.helpPanelContainerRoot }}>
					<Grid item xs={12} classes={{ root: classes.helpPanelHeader }}>
						<h1 className={classes.linkHeader}>{t('timeAndPay:HEADERS.HELP')}</h1>
					</Grid>

					<Grid item xs={12} classes={{ root: classes.helpPanelLinks }}>
						{timeAndPayLinks.map((item, index) => {
							if (item.id < 6) {
								return (
									<a className={classes.link} href={item.link} target='_self'>
										{t(`timeAndPay:LINKS.${item.name}`)}
									</a>
								);
							}
							return null;
						})}
					</Grid>

					<Grid item xs={5}>
						<Button
							className={classes.progressButton}
							variant='contained'
							color='primary'
							href='mailto:LT_OnlineServices@locumtenens.com'
						>
							{t('timeAndPay:BUTTONS.EMAIL')}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

export default withTranslation()(withStyles(styles)(ResourcesTile));
