import React, { Component } from 'react';
import {
	Paper,
	withStyles,
	CircularProgress,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableContainer,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Pagination from 'material-ui-flat-pagination';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import PresentsTableMobileCard from '../PresentsTableMobileCard/PresentsTableMobileCard';
import TextFieldComponent from '../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { COLORS, APP_CONFIG } from '../../../utils/Application_Constants';
import { getListByOffset, getListOffsetByPage } from '../../../services/listPaginationService';
import { getPresents } from '../../../services/PresentsService';
import UserContext from '../../../contexts/userContext';
import presentsFilterOptions from './presentsOptions';
import PresentComponent from './PresentComponent';

const styles = (theme) => ({
	tableHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '18px',
		marginRight: '15px',
		textAlign: 'left',
		[theme.breakpoints.down('sm')]: {
			width: '98%',
			margin: '0 auto',
		},
	},
	tableHeaderWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: '98%',
		margin: '0 auto',
		padding: '25px 0',
	},
	tableRowHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		padding: '25px 0',
		textAlign: 'center',
		fontSize: '16px',
	},
	cellRoot: {
		color: COLORS.RAVEN,
		fontWeight: '300',
		fontSize: '14px',
		padding: '25px 0',
		textAlign: 'center',
	},
	tableContainerRoot: {
		width: '98%',
		margin: '0 auto',
		border: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	accountRoot: {
		color: COLORS.RAVEN,
		fontWeight: '600',
		fontSize: '14px',
		padding: '25px 0',
		textAlign: 'center',
	},
	presentsWrapper: {
		minHeight: '800px',
	},
	inputLabel: {
		display: 'none',
	},
	selectedPage: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		borderRadius: '0',
		paddingLeft: '17px',
		paddingRight: '17px',
		'&:hover': {
			backgroundColor: COLORS.TRUE_BLUE,
		},
	},
	pageButton: {
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		borderRadius: '0',
		paddingLeft: '15px',
		paddingRight: '15px',
	},
	paginationNumber: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		opacity: '.8',
		fontSize: '16px',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '15px',
		},
	},
	paginationWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: '15px',
		alignItems: 'center',
		width: '90%',
		[theme.breakpoints.down('sm')]: {
			flexFlow: 'column',
		},
	},
	paginationFlexCenter: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '25px',
	},
	noMargin: {
		marginTop: '0 !important',
	},
	PaperMobile__FilterByMonth: {
		margin: '25px 0',
	},
	FormControlMobile__Root: {
		width: '100%',
	},
	TextFieldComponentMobile_Root: {
		width: '100%',
		marginTop: '0 !important',
	},
	PaperMobile__PresentsWrapper: {
		padding: '25px 0',
		margin: '25px 0',
	},
	MobileHeader__Wrapper: {
		width: '95%',
		margin: '0 auto',
		display: 'flex',
		marginBottom: '20px',
	},
});

class PresentsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			presents: {},
			isLoading: true,
			activePresents: {},
			offset: 0,
			page: 1,
			presentsWithOffsets: [],
			presentsWithFilters: [],
			presentsFilter: '3',
		};
	}

	async componentDidMount() {
		const { externalId } = this.context;
		const state = { ...this.state };
		const { location, history, setAssignmentPageError } = this.props;
		const urlParams = queryString.parse(location.search);
		state.presents = await getPresents(externalId, true).catch((e) => {
			setAssignmentPageError(e);
		});

		state.presentsFilter = urlParams.months || 3;
		state.presentsWithFilters = this.getFilteredDatesOnLoad(state.presents, 3);
		state.isLoading = false;
		state.page = urlParams.page || 1;
		state.offset = getListOffsetByPage(Number(state.page));
		state.presentsWithOffsets = getListByOffset(state.presentsWithFilters, state.offset);

		history.push(`/assignments/presents?page=${state.page}&months=${state.presentsFilter}`);

		this.setState({
			presents: state.presents,
			presentsFilter: state.presentsFilter,
			presentsWithFilters: state.presentsWithFilters,
			isLoading: false,
			page: state.page,
			offset: state.offset,
			presentsWithOffsets: state.presentsWithOffsets,
		});
	}

	handlePagination = (offset, page) => {
		const state = { ...this.state };
		const { history } = this.props;
		const { presentsWithFilters } = this.state;
		state.offset = offset;
		state.page = page;
		state.presentsWithOffsets = getListByOffset(presentsWithFilters, offset);
		this.setState({ ...state });
		history.push(`/assignments/presents?page=${page}&months=${state.presentsFilter}`);
	};

	getFilteredDatesOnLoad = (presents, months) => {
		const historicalDate = moment().startOf('day').subtract(months, 'month');
		const yesterdayEndOfRange = moment().endOf('day').subtract(1, 'day');
		return presents.filter((present) =>
			moment(present.presentDate).isBetween(historicalDate, yesterdayEndOfRange)
		);
	};

	filterDatesByMonths = (number) => {
		const state = { ...this.state };
		const { history } = this.props;
		const historicalDate = moment().startOf('day').subtract(number, 'month');
		const yesterdayEndOfRange = moment().endOf('day').subtract(1, 'day');
		const newPresents = state.presents.filter((present) =>
			moment.utc(present.presentDate).isBetween(historicalDate, yesterdayEndOfRange)
		);
		state.presentsFilter = number;
		state.presentsWithFilters = newPresents;
		state.page = 1;
		state.offset = 0;
		state.presentsWithOffsets = getListByOffset(newPresents, 0);
		history.push(`/assignments/presents?page=${state.page}&months=${number}`);
		this.setState({ ...state });
	};

	filterByDate = (e) => {
		this.filterDatesByMonths(e.target.value);
	};

	renderPagination = () => {
		const { classes, t } = this.props;
		const { presentsWithFilters, page, offset } = this.state;
		return (
			<div className={classes.paginationFlexCenter}>
				<div className={classes.paginationWrapper}>
					<div className={classes.paginationNumber}>
						{t('common:GENERIC_PHRASES.PAGE')} {page} {t('common:GENERIC_PHRASES.OF')}{' '}
						{Math.floor(
							(presentsWithFilters.length - 1) / APP_CONFIG.PAGINATION_LIMIT + 1
						)}
					</div>
					<Pagination
						classes={{
							rootCurrent: classes.selectedPage,
							rootStandard: classes.pageButton,
							rootEnd: classes.pageButton,
						}}
						limit={APP_CONFIG.PAGINATION_LIMIT}
						total={presentsWithFilters.length}
						onClick={(e, offset, page) => this.handlePagination(offset, page)}
						offset={offset}
					/>
				</div>
			</div>
		);
	};

	desktopTableRender() {
		const { presentsWithOffsets, presentsFilter } = this.state;
		const { classes, t } = this.props;
		return (
			<div className={classes.presentsWrapper}>
				<Paper>
					<div className={classes.tableHeaderWrapper}>
						<span className={classes.tableHeader}>{t('presents:PRESENTS')}</span>
						<FormControl>
							<InputLabel
								shrink
								htmlFor='PresentsFilter'
								classes={{ root: classes.inputLabel }}
							>
								Filter
							</InputLabel>
							<Select
								id='PresentsFilter'
								required
								name='presentsFilter'
								value={presentsFilter}
								onChange={this.filterByDate}
								input={<TextFieldComponent classes={{ root: classes.noMargin }} />}
							>
								{presentsFilterOptions.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<TableContainer classes={{ root: classes.tableContainerRoot }}>
						<Table aria-label='presents table'>
							<TableRow>
								<TableCell classes={{ root: classes.tableRowHeader }}>
									FacilityName
								</TableCell>
								<TableCell classes={{ root: classes.tableRowHeader }}>
									City
								</TableCell>
								<TableCell classes={{ root: classes.tableRowHeader }}>
									State
								</TableCell>
								<TableCell classes={{ root: classes.tableRowHeader }}>
									Present Date
								</TableCell>
							</TableRow>
							<TableBody>
								{presentsWithOffsets.map((item, index) => (
									<TableRow scope='row' key={`TR-${index + 1}`}>
										<TableCell classes={{ root: classes.accountRoot }}>
											{item.accountName}
										</TableCell>
										<TableCell classes={{ root: classes.cellRoot }}>
											{item.city}
										</TableCell>
										<TableCell classes={{ root: classes.cellRoot }}>
											{item.state}
										</TableCell>
										<TableCell classes={{ root: classes.cellRoot }}>
											{moment.utc(item.presentDate).format('MMM D, YYYY')}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					{presentsWithOffsets !== undefined && presentsWithOffsets.length > 0
						? this.renderPagination()
						: null}
				</Paper>
			</div>
		);
	}

	mobilePresentsTable() {
		const { presentsWithOffsets, presentsFilter } = this.state;
		const { classes, t } = this.props;
		return (
			<>
				<Paper classes={{ root: classes.PaperMobile__FilterByMonth }}>
					<FormControl classes={{ root: classes.FormControlMobile__Root }}>
						<InputLabel
							shrink
							htmlFor='PresentsFilter'
							classes={{ root: classes.inputLabel }}
						>
							Filter
						</InputLabel>
						<Select
							id='PresentsFilter'
							required
							name='presentsFilter'
							value={presentsFilter}
							onChange={this.filterByDate}
							classes={{ root: classes.TextFieldComponentMobile_Root }}
							input={
								<TextFieldComponent
									classes={{ root: classes.TextFieldComponentMobile_Root }}
								/>
							}
						>
							{presentsFilterOptions.map((item) => (
								<MenuItem key={item.value} value={item.value}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Paper>
				<Paper classes={{ root: classes.PaperMobile__PresentsWrapper }}>
					<div className={classes.MobileHeader__Wrapper}>
						<span className={classes.tableHeader}>{t('presents:PRESENTS')}</span>
					</div>
					{presentsWithOffsets.map((item, index) => (
						<PresentsTableMobileCard key={`TR=${index + 1}`} present={item} />
					))}
					{presentsWithOffsets !== undefined && presentsWithOffsets.length > 0
						? this.renderPagination()
						: null}
				</Paper>
			</>
		);
	}

	render() {
		const { isLoading } = this.state;
		const { classes, setAssignmentPageError } = this.props;
		if (isLoading) {
			return <CircularProgress />;
		}
		return (
			<>
				<Paper className={classes.paper}>
					<PresentComponent setAssignmentPageError={setAssignmentPageError} />
				</Paper>
			</>
		);
	}
}

PresentsList.contextType = UserContext;

export default withTranslation()(withRouter(withStyles(styles)(PresentsList)));
