/**
 * @file is the preferences travel component of the preferences page.
 * @TODO add proptypes
 */

import React, { Component, Fragment } from 'react';
import {
	withStyles,
	Grid,
	NativeSelect,
	InputLabel,
	Button,
	FormControl,
	IconButton,
	Hidden,
	Icon,
	InputAdornment,
	ThemeProvider,
} from '@material-ui/core';
import { Star, StarBorderOutlined } from '@material-ui/icons';
import TextFieldComponent from '../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { COLORS } from '../../../utils/Application_Constants';
import {
	airlineOptions,
	seatPreferenceOptions,
	carRentalCompanyOptions,
} from './preferencesTravelOptions';
import { withTranslation } from 'react-i18next';
import { Flight, Hotel, DirectionsCar, Pets } from '@material-ui/icons';
import DatePickerComponent from '../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import UtcUtils from '../../GlobalComponents/UtcUtils/UtcUtils';
import datePickerTheme from '../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import moment from 'moment';
import { Event } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const styles = (theme) => ({
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			padding: '0 32px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0 15px',
		},
	},
	setWidthTo100: {
		width: '100%',
	},
	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		whiteSpace: 'nowrap',
	},
	header: {
		color: COLORS.ST_TROPAZ,
		[theme.breakpoints.down('sm')]: {
			padding: '36px 0 0 15px',
		},
		textAlign: 'left',
		paddingTop: '36px',
		fontSize: '17px',
		fontWeight: '700',
	},
	colorTrueBlue: {
		color: COLORS.TRUE_BLUE,
	},
	colorRemove: {
		color: COLORS.MILANO_RED,
	},
	colorRaven: {
		color: COLORS.RAVEN,
	},
	colorWhite: {
		color: COLORS.WHITE,
	},
	cssOutlinedInput: {
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
		'&$cssFocused $notchedOutline': {
			borderColor: '#6D707E', //focused
			borderWidth: '1px',
			borderRadius: 1,
		},
	},
	button: {
		textTransform: 'capitalize',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			padding: '10px 0 15px 0',
		},
	},
	buttonGridRoot: {
		display: 'flex',
		justifyContent: 'center',
	},
	disbaledInput: {
		backgroundColor: COLORS.WILD_SAND,
	},
	preferredLabel: {
		color: COLORS.WHITE,
		font: '500',
		fontSize: '16px',
	},
	mobileFormPreferredRoot: {
		diplay: 'flex',
		width: '100%',
		height: '100%',
	},
	containerInheritWidth: {
		width: 'inherit',
	},
	preferenceRow: {
		[theme.breakpoints.up('md')]: {
			justifyContent: 'flex-start',
			marginBottom: '15px',
		},
	},
	preferredStar: {
		[theme.breakpoints.up('md')]: {
			marginTop: '15px',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
		},
	},
	preferredItemStyle: {
		height: '64px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		},
	},
	preferredItemActiveStyles: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '64px',
			backgroundColor: COLORS.TRUE_BLUE,
		},
	},
	containerIsPreferred: {
		[theme.breakpoints.down('sm')]: {
			border: `2px solid ${COLORS.TRUE_BLUE}`,
			boxShadow: '0px 3px 12px #0074C833',
			borderRadius: '3px',
			marginBottom: '20px',
		},
	},
	containerIsNotPreferred: {
		[theme.breakpoints.down('sm')]: {
			border: `1px solid ${COLORS.WHITE_LILAC}`,
			borderRadius: '3px',
			marginBottom: '20px',
		},
	},
	gridInputRoot: {
		[theme.breakpoints.down('sm')]: {
			padding: '10px 15px',
		},
		[theme.breakpoints.up('md')]: {
			paddingRight: '15px',
		},
	},
	iconRoot: {
		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'center',
		justifyContent: 'center',
	},
	preferredButtonMobileLabelWrapper: {
		display: 'flex',
	},
	preferredButtonMobileLabelText: {
		lineHeight: '1.5',
	},
	travelGroupRoot: {
		[theme.breakpoints.up('md')]: {
			paddingTop: '25px',
			borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
		},
	},
	iconClassForAirline: {
		verticalAlign: 'middle',
		paddingRight: '6px',
		color: COLORS.TRUE_BLUE,
		transform: 'rotate(90deg)',
	},
	iconClass: {
		verticalAlign: 'middle',
		paddingRight: '6px',
		color: COLORS.TRUE_BLUE,
	},
	alignLeft: {
		float: 'left',
		color: '#356094',
		fontSize: '17px',
		textAlign: 'left',
		fontWeight: '700',
		paddingTop: '36px',
		paddingBottom: '20px',
	},
	vendorIsPreferred: {
		[theme.breakpoints.down('sm')]: {
			border: `1px solid ${COLORS.WHITE_LILAC}`,
			borderRadius: '3px',
			marginBottom: '20px',
		},
		[theme.breakpoints.up('md')]: {
			border: `1px solid ${COLORS.WHITE_LILAC}`,
			borderRadius: '3px',
			marginBottom: '20px',
			padding: '10px 5px',
		},
	},
	preferredVendor: {
		float: 'left',
		color: '#356094',
		fontSize: '13px',
		textAlign: 'left',
		fontWeight: '700',
		padding: '0 0 5px 0',
		[theme.breakpoints.down('sm')]: {
			padding: '10px',
		},
	},
});

class PreferencesTravelComponent extends Component {
	constructor(props) {
		super(props);
		this.airlineOptions = airlineOptions;
		this.carRentalCompanyOptions = carRentalCompanyOptions;
		this.seatPreferenceOptions = seatPreferenceOptions;
		this.state = {
			legalFirstName: props.clinicianPreferences.legalFirstName,
			legalMiddleInitial: props.clinicianPreferences.legalMiddleInitial,
			legalLastName: props.clinicianPreferences.legalLastName,
			knownTravelerNo: this.setKnownTravelerNoToState(props),
			knownTravelerExpirationDate: this.setKnownTravelerExpirationDateToState(props),
			seatPreference: this.setSeatPreferenceToState(props),
			petTravel: props.clinicianPreferences.petTravel,
			petType: props.clinicianPreferences.petType,
			petBreed: props.clinicianPreferences.petBreed,
			petWeight: props.clinicianPreferences.petWeight,
		};
	}

	/**
	 * The logic that decides which star to show based on
	 * preference flag.
	 * @param {String} device
	 * @param {Boolean} is prefered
	 * @returns {JSX}
	 */
	renderPreferredLogic = (device, isPreferred) => {
		const isMobile = device === 'mobile';
		if (isPreferred) {
			if (!isMobile) {
				return <Star color='primary' />;
			}
			return <Star htmlColor={COLORS.WHITE} />;
		}
		return <StarBorderOutlined />;
	};

	setKnownTravelerExpirationDateToState = (props) => {
		if (props.airlinePreferences && props.airlinePreferences.length > 0) {
			return props.airlinePreferences[0].expirationDate;
		}
		return null;
	};

	/**
	 * The Known traveler global to all the airline rows, only the first
	 * row should have a dropdown. When the component is constructed,
	 * we check to see if it already exists in the data. There currently
	 * isn't one global value for known traveler in numnber in the resource
	 * @returns {String}
	 */
	setKnownTravelerNoToState = (props) => {
		if (props.airlinePreferences && props.airlinePreferences.length > 0) {
			return props.airlinePreferences[0].knownTravellerNumber;
		}
		return '';
	};

	/**
	 * The seat is global to all the airline rows, only the first
	 * row should have a dropdown. When the component is constructed,
	 * we check to see if it already exists in the data. Currently there
	 * is no single global option for seat preference in the resource
	 * @returns {String}
	 */
	setSeatPreferenceToState = (props) => {
		if (props.airlinePreferences && props.airlinePreferences.length > 0) {
			return props.airlinePreferences[0].seatPreference;
		}
		return '';
	};

	/**
	 * Unique handler for airline known traveler number
	 */
	handleKnownTravelerNo = (event) => {
		const state = { ...this.state };
		const { travelGroupHandler, airlinePreferences } = this.props;
		const { value } = event.target;
		state.knownTravelerNo = value;
		airlinePreferences.forEach((item, index) => {
			travelGroupHandler(event, index, 'airlinePreferences');
		});
		this.setState({ ...state });
	};

	/**
	 * unique handler for seat preference.
	 */
	handleSeatPreference = (event) => {
		const state = { ...this.state };
		const { travelGroupHandler, airlinePreferences } = this.props;
		const { value } = event.target;
		state.seatPreference = value;
		airlinePreferences.forEach((item, index) => {
			travelGroupHandler(event, index, 'airlinePreferences');
		});
		this.setState({ ...state });
	};

	handleLegalFirstName = (event) => {
		const state = { ...this.state };
		const { clinicianPreferences, shouldDisableSave } = this.props;
		const { value } = event.target;
		state.legalFirstName = value;
		clinicianPreferences.legalFirstName = value;
		shouldDisableSave();
		this.setState({ ...state });
	};

	handleLegalMiddleInitial = (event) => {
		const state = { ...this.state };
		const { clinicianPreferences, shouldDisableSave } = this.props;
		const { value } = event.target;
		state.legalMiddleInitial = value;
		clinicianPreferences.legalMiddleInitial = value;
		shouldDisableSave();
		this.setState({ ...state });
	};

	handleLegalLastName = (event) => {
		const state = { ...this.state };
		const { clinicianPreferences, shouldDisableSave } = this.props;
		const { value } = event.target;
		state.legalLastName = value;
		clinicianPreferences.legalLastName = value;
		shouldDisableSave();
		this.setState({ ...state });
	};

	handlePetTravel = (event) => {
		const state = { ...this.state };
		const { clinicianPreferences } = this.props;
		const { value } = event.target;
		state.petTravel = value;
		clinicianPreferences.petTravel = value;
		this.setState({ ...state });
	};

	handlePetType = (event) => {
		const state = { ...this.state };
		const { clinicianPreferences, shouldDisableSave } = this.props;
		const { value } = event.target;
		state.petType = value;
		clinicianPreferences.petType = value;
		shouldDisableSave();
		this.setState({ ...state });
	};

	handlePetBreed = (event) => {
		const state = { ...this.state };
		const { clinicianPreferences, shouldDisableSave } = this.props;
		const { value } = event.target;
		state.petBreed = value;
		clinicianPreferences.petBreed = value;
		shouldDisableSave();
		this.setState({ ...state });
	};

	handlePetWeight = (event) => {
		const state = { ...this.state };
		const { clinicianPreferences, shouldDisableSave } = this.props;
		const { value } = event.target;
		state.petWeight = value;
		clinicianPreferences.petWeight = value;
		shouldDisableSave();
		this.setState({ ...state });
	};

	/**
	 * Renders the add airline button
	 * @returns {JSX}
	 */
	addAnotherAirlineButton = () => {
		const { classes, addAirlineClickEvent, airlinePreferences } = this.props;
		const validAirlineList = airlinePreferences.filter((item) => item.airlineName !== null);
		if (validAirlineList.length >= 3) {
			return null;
		}
		return (
			<Grid item xs={12} md={2} classes={{ root: classes.buttonGridRoot }}>
				<Button
					component='span'
					classes={{ text: `${classes.button} ${classes.colorTrueBlue}` }}
					onClick={addAirlineClickEvent}
				>
					+ Add Airline
				</Button>
			</Grid>
		);
	};

	/**
	 * Renders the add additional hotel button
	 * @returns {JSX}
	 */
	addAnotherHotelButton = () => {
		const { classes, addHotelClickEvent, hotels } = this.props;
		const validHotelList = hotels.filter((item) => item.hotelName !== null);
		if (validHotelList.length >= 3) {
			return null;
		}
		return (
			<Grid item xs={12} md={4} classes={{ root: classes.buttonGridRoot }}>
				<Button
					component='span'
					classes={{ text: `${classes.button} ${classes.colorTrueBlue}` }}
					onClick={addHotelClickEvent}
				>
					+ Add additional Hotel
				</Button>
			</Grid>
		);
	};

	/**
	 * Renering the remove airline button
	 * @param {Number} index
	 */
	removeAirlineButton = (index) => {
		const { classes, removeSelectedPreferenceByTravelGroup } = this.props;
		return (
			<Grid item xs={12} md={2} classes={{ root: classes.buttonGridRoot }}>
				<Button
					component='span'
					classes={{ text: `${classes.button} ${classes.colorRemove}` }}
					onClick={() =>
						removeSelectedPreferenceByTravelGroup(index, 'airlinePreferences')
					}
				>
					- Remove Airline
				</Button>
			</Grid>
		);
	};

	/**
	 * Renders the remove car rental button
	 * @param {Number} index
	 * @returns {JSX}
	 */
	removeCarRentalButton = (index) => {
		const { classes, removeSelectedPreferenceByTravelGroup } = this.props;
		return (
			<Grid item xs={12} md={3} classes={{ root: classes.buttonGridRoot }}>
				<Button
					component='span'
					classes={{ text: `${classes.button} ${classes.colorRemove}` }}
					onClick={() => removeSelectedPreferenceByTravelGroup(index, 'rentalServices')}
				>
					- Remove Car Rental Service
				</Button>
			</Grid>
		);
	};

	/**
	 * Renders the remove hotel button
	 * @param {Number} index
	 * @returns {JSX}
	 */
	removeHotelButton = (index) => {
		const { classes, removeSelectedPreferenceByTravelGroup } = this.props;
		return (
			<Grid item xs={12} md={2} classes={{ root: classes.buttonGridRoot }}>
				<Button
					component='span'
					classes={{ text: `${classes.button} ${classes.colorRemove}` }}
					onClick={() => removeSelectedPreferenceByTravelGroup(index, 'hotels')}
				>
					- Remove Hotel
				</Button>
			</Grid>
		);
	};

	/**
	 * Renders the preferred star component for desktop devices.
	 * @param {Number} index
	 * @param {Boolean} isPreferred
	 * @param {String} travelGroup
	 * @returns {JSX}
	 */
	preferredDesktop = (index, isPreferred, travelGroup) => {
		const { classes, handlePreferredButtonClick } = this.props;
		return (
			<Grid item xs={12} md={1} classes={{ item: this.preferredItemStyles(isPreferred) }}>
				<FormControl>
					<InputLabel shrink htmlFor='IsPreferred' classes={{ root: classes.inputLabel }}>
						Preferred
					</InputLabel>
					<IconButton
						onClick={() => {
							handlePreferredButtonClick(index, travelGroup);
						}}
						classes={{ root: classes.preferredStar }}
					>
						{this.renderPreferredLogic('desktop', isPreferred)}
					</IconButton>
				</FormControl>
			</Grid>
		);
	};

	/**
	 * Renders the perferred cards on mobile devices
	 * @param {Number} index
	 * @param {Boolean} isPreferred
	 * @param {String} travelGroup
	 * @returns {JSX}
	 */
	preferredMobile = (index, isPreferred, travelGroup) => {
		const { classes, handlePreferredButtonClick } = this.props;
		return (
			<Grid item xs={12} classes={{ item: this.preferredItemStyles(isPreferred) }}>
				<Button
					onClick={() => {
						handlePreferredButtonClick(index, travelGroup);
					}}
					component='span'
					classes={{
						root: classes.mobileFormPreferredRoot,
						text: this.preferredMobileButtonTextStyles(isPreferred),
						label: classes.preferredButtonMobileLabelWrapper,
					}}
				>
					<Icon classes={{ root: classes.preferredStar }}>
						{this.renderPreferredLogic('mobile', isPreferred)}
					</Icon>
					<span className={classes.prefferedMobileLabelText}>
						{this.preferredMobileLabelGenerator(isPreferred, travelGroup)}
					</span>
				</Button>
			</Grid>
		);
	};

	/**
	 * Generates the string that is shown in the preferred
	 * mobile cards based on the travel group.
	 * @param {Boolean} isPreferred
	 * @param {String} travelGroup
	 * @returns {String}
	 */
	preferredMobileLabelGenerator = (isPreferred, travelGroup) => {
		const prefix = isPreferred ? 'Preferred' : 'Select';
		return `${prefix} ${this.getTravelGroupLabel(travelGroup)}`;
	};

	/**
	 * Switch statement that deciedes what suffix to show in the mobile
	 * preferred cards based on travel group.
	 * @param {String} travelGroup
	 * @returns {String}
	 */
	getTravelGroupLabel = (travelGroup) => {
		switch (travelGroup) {
			case 'airlinePreferences':
				return 'Airline';
			case 'rentalServices':
				return 'Car Rental Service';
			case 'hotels':
				return 'Hotel';
			default:
				Error('Error setting travel group preference label');
				return '';
		}
	};

	/**
	 * Preferred button mobile text css
	 * @param {Boolean} isPreferred
	 * @returns {String}
	 */
	preferredMobileButtonTextStyles = (isPreferred) => {
		const { classes } = this.props;
		if (isPreferred) {
			return `${classes.button} ${classes.colorWhite}`;
		}
		return `${classes.button} ${classes.colorRaven}`;
	};

	/**
	 * Method checks whether or not to show remove option next to
	 * rendered airline. Should not show if there user only has one airline preference.
	 * @param {Number} index - The index in the array that is identifying where to handle
	 * the remove logic
	 * @returns {JSX | Null}
	 */
	renderAirlineRemoveOption = (index) => {
		const { airlinePreferences } = this.props;
		const validAirlineList = airlinePreferences.filter((item) => item.airlineName);
		if (validAirlineList.length <= 3) {
			return this.removeAirlineButton(index);
		}
		return null;
	};

	datePickerHelper(date) {
		if (date) {
			return date;
		}
		return null;
	}

	dynamicDateChangeHandler = (date, parsedDate, name, index) => {
		const state = { ...this.state };
		const { airlinePreferences } = this.props;
		if (date) {
			state.knownTravelerExpirationDate = moment.utc(
				date.startOf('day').format('YYYY-MM-DD HH:mm:ss')
			);
			airlinePreferences.forEach((item, index) => {
				item.expirationDate = state.knownTravelerExpirationDate;
			});
		} else {
			state.knownTravelerExpirationDate = null;
			airlinePreferences.forEach((item, index) => {
				item.expirationDate = null;
			});
		}
		//this.setState({ ...state })
		this.setState({
			knownTravelerExpirationDate: state.knownTravelerExpirationDate,
		});
	};

	/**
	 * Renders the hotel removal button
	 * @param {Number} index
	 * @returns {JSX | Null}
	 */
	renderHotelRemoveOption = (index) => {
		const { hotels } = this.props;
		const validHotelList = hotels.filter((item) => item.hotelName);
		if (validHotelList.length <= 3) {
			return this.removeHotelButton(index);
		}
		return null;
	};

	/**
	 * Conditional to render CSS class depending on if preference is selected.
	 * @param {Boolean} isPreferred
	 * @returns {String}
	 */
	preferredItemStyles = (isPreferred) => {
		const { classes } = this.props;
		return isPreferred ? classes.preferredItemActiveStyles : classes.preferredItemStyle;
	};

	/**
	 * If the airline is preferred, will give a border around the container.
	 * @param {Boolean} isPreferred
	 * @returns {String}
	 */
	preferredContainerStyles = (isPreferred) => {
		const { classes } = this.props;
		if (!isPreferred) {
			return classes.containerIsNotPreferred;
		}
		return classes.containerIsPreferred;
	};

	preferredVendorContainerStyles = (index) => {
		const { classes } = this.props;
		if (index === 0) {
			return classes.vendorIsPreferred;
		}
		return null;
	};

	/**
	 * When the user has toggeld other in the native select, the other field
	 * is set on the next state rerender.
	 * @param {Number} index
	 * @returns {JSX}
	 */
	renderOtherCarRentalInput = (index) => {
		const { rentalServices, classes, travelGroupHandler } = this.props;
		if (rentalServices[index].rentalCompany !== 'Other') {
			return null;
		}
		return (
			<Grid item xs={12} md={2} classes={{ root: classes.gridInputRoot }}>
				<FormControl classes={{ root: classes.setWidthTo100 }}>
					<InputLabel
						shrink
						htmlFor={`RentalCarCompanyOther-${index}`}
						classes={{ root: classes.inputLabel }}
					>
						Car Agency / Membership No.
					</InputLabel>
					<TextFieldComponent
						id={`RentalCarCompanyOther-${index}`}
						classes={{ input: classes.setWidthTo100 }}
						onChange={(event) => travelGroupHandler(event, index, 'rentalServices')}
						name='otherCarMembershipNumber'
						value={rentalServices[index].otherCarMembershipNumber || ''}
					/>
				</FormControl>
			</Grid>
		);
	};

	renderFirstRowSpecificFields = (index) => {
		const { classes, airlinePreferences } = this.props;
		let firstNonNull = true;
		for (let i = 0; i < index; i++) {
			if (airlinePreferences[i].airlineName != null) {
				firstNonNull = false;
				break;
			}
		}
		if (!firstNonNull) {
			return null;
		}
		const { knownTravelerNo, seatPreference, knownTravelerExpirationDate } = this.state;
		return (
			<>
				<Grid item container direction={'row'} spacing={2}>
					<Grid item xs={12} md={3} classes={{ root: classes.gridInputRoot }}>
						<FormControl classes={{ root: classes.setWidthTo100 }}>
							<InputLabel
								shrink
								htmlFor='KnownTravellerNumber'
								classes={{ root: classes.inputLabel }}
								error={!!knownTravelerNo && knownTravelerNo.length > 20}
							>
								Known Traveler No.
							</InputLabel>
							<TextFieldComponent
								id='KnownTravellerNumber'
								classes={{
									input: classes.setWidthTo100,
									disabled: classes.disbaledInput,
								}}
								required
								onChange={this.handleKnownTravelerNo}
								name='knownTravellerNumber'
								value={knownTravelerNo || ''}
							/>
						</FormControl>
					</Grid>
					<Grid item classes={{ root: classes.gridRoot }} xs={12} md={3}>
						<ThemeProvider theme={datePickerTheme}>
							<MuiPickersUtilsProvider utils={UtcUtils} moment={moment}>
								<FormControl classes={{ root: classes.setWidthTo100 }}>
									<InputLabel
										shrink
										htmlFor='KnownTravelerExpirationDate'
										classes={{ root: classes.inputLabel }}
									>
										Expiration Date
									</InputLabel>
									<DatePickerComponent
										theme={datePickerTheme}
										variant='inline'
										autoOk='true'
										format='MM/DD/YYYY'
										placeholder='MM/DD/YYYY'
										name='KnownTravelerExpirationDate'
										margin='normal'
										id={`KnownTravelerExpirationDate-${index}`}
										value={this.datePickerHelper(knownTravelerExpirationDate)}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<Event />
												</InputAdornment>
											),
											classes: { root: classes.cssOutlinedInput },
										}}
										onChange={(date, parsedDate) => {
											this.dynamicDateChangeHandler(
												date,
												parsedDate,
												'KnownTravelerExpirationDate',
												index
											);
										}}
										KeyboardButtonProps={{
											'aria-label': 'Known Traveler Expiration Date',
										}}
										inputVariant='outlined'
									/>
								</FormControl>
							</MuiPickersUtilsProvider>
						</ThemeProvider>
					</Grid>
					<Grid item xs={12} md={3} classes={{ root: classes.gridInputRoot }}>
						<FormControl classes={{ root: classes.setWidthTo100 }}>
							<InputLabel
								shrink
								htmlFor='SeatPreference'
								classes={{ root: classes.inputLabel }}
							>
								Seat Preference
							</InputLabel>
							<NativeSelect
								id='SeatPreference'
								required
								onChange={this.handleSeatPreference}
								name='seatPreference'
								value={seatPreference || ''}
								input={
									<TextFieldComponent
										classes={{ input: classes.setWidthTo100 }}
									/>
								}
							>
								{this.seatPreferenceOptions.map((item) => (
									<option key={item.value} value={item.value}>
										{item.label}
									</option>
								))}
							</NativeSelect>
						</FormControl>
					</Grid>
				</Grid>
			</>
		);
	};

	/**
	 * When the other option is not selected as a car rental company,
	 * we show the car rental membership no. This method renders the input.
	 * @param {Number} index
	 */
	renderCarRentalMembershipNo = (index) => {
		const { classes, travelGroupHandler, rentalServices } = this.props;
		const props = { ...this.props };
		if (rentalServices[index].rentalCompany === 'Other') {
			return null;
		}
		return (
			<Grid item xs={12} md={3} classes={{ root: classes.gridInputRoot }}>
				<FormControl classes={{ root: classes.setWidthTo100 }}>
					<InputLabel
						shrink
						htmlFor={`MembershipNo-${index}-${rentalServices[index].rentalCompany}`}
						classes={{ root: classes.inputLabel }}
						error={
							!!props.rentalServices[index].membershipNumber &&
							props.rentalServices[index].membershipNumber.length > 20
						}
					>
						Membership No
					</InputLabel>
					<TextFieldComponent
						id={`MembershipNo-${index}-${rentalServices[index].rentalCompany}`}
						classes={{ input: classes.setWidthTo100, disabled: classes.disbaledInput }}
						onChange={(event) => {
							travelGroupHandler(event, index, 'rentalServices');
						}}
						name='membershipNumber'
						value={props.rentalServices[index].membershipNumber || ''}
					/>
				</FormControl>
			</Grid>
		);
	};

	render() {
		const { classes, t } = this.props;
		const {
			clinicianPreferences,
			airlinePreferences,
			rentalServices,
			hotels,
			travelGroupHandler,
		} = this.props;
		return (
			<Grid
				container
				classes={{ root: classes.gridRoot, container: classes.containerInheritWidth }}
			>
				<Grid container item xs={12} direction='row'>
					<div className={classes.alignLeft}>
						<span style={{ fontSize: 24, color: COLORS.DENIM }}>
							{t('profile:PREFERENCES.TRAVEL_PREFERENCES')}
						</span>
					</div>
					<Grid item container direction={'row'} spacing={2}>
						<Grid item xs={12} md={4} classes={{ root: classes.gridInputRoot }}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='LegalFirstName'
									classes={{ root: classes.inputLabel }}
									error={
										!!clinicianPreferences.legalFirstName &&
										clinicianPreferences.legalFirstName.length > 50
									}
								>
									Legal First Name
								</InputLabel>
								<TextFieldComponent
									id='LegalFirstName'
									classes={{
										input: classes.setWidthTo100,
										disabled: classes.disbaledInput,
									}}
									required
									onChange={this.handleLegalFirstName}
									name='LegalFirstName'
									value={clinicianPreferences.legalFirstName || ''}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={3} classes={{ root: classes.gridInputRoot }}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='LegalMiddleInitial'
									classes={{ root: classes.inputLabel }}
									error={
										!!clinicianPreferences.legalMiddleInitial &&
										clinicianPreferences.legalMiddleInitial.length > 1
									}
								>
									Legal Middle Initial
								</InputLabel>
								<TextFieldComponent
									id='LegalMiddleInitial'
									classes={{
										input: classes.setWidthTo100,
										disabled: classes.disbaledInput,
									}}
									required
									onChange={this.handleLegalMiddleInitial}
									name='LegalMiddleInitial'
									value={clinicianPreferences.legalMiddleInitial || ''}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4} classes={{ root: classes.gridInputRoot }}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='LegalLastName'
									classes={{ root: classes.inputLabel }}
									error={
										!!clinicianPreferences.legalLastName &&
										clinicianPreferences.legalLastName.length > 50
									}
								>
									Legal Last Name
								</InputLabel>
								<TextFieldComponent
									id='LegalLastName'
									classes={{
										input: classes.setWidthTo100,
										disabled: classes.disbaledInput,
									}}
									required
									onChange={this.handleLegalLastName}
									name='LegalLastName'
									value={clinicianPreferences.legalLastName || ''}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<div className={classes.alignLeft}>
						<span>{t('profile:PREFERENCES.FLIGHT_PREFERENCES')}</span>
					</div>
				</Grid>

				<Grid container classes={{ root: classes.travelGroupRoot }}>
					{airlinePreferences.map((item, index) => {
						if (item.airlineName === null) {
							return null;
						}
						return (
							<Fragment key={`flightpreference-${index + 1}`}>
								<Grid container>{this.renderFirstRowSpecificFields(index)}</Grid>
							</Fragment>
						);
					})}
				</Grid>

				<Grid container item xs={12} direction='row'>
					<div className={classes.alignLeft}>
						<Flight classes={{ root: classes.iconClassForAirline }} />
						<span>{t('profile:PREFERENCES.AIRLINE_PREFERENCES')}</span>
					</div>
				</Grid>
				<Grid container classes={{ root: classes.travelGroupRoot }}>
					{airlinePreferences.map((item, index) => {
						const { isPreferred } = airlinePreferences[index];
						if (item.airlineName === null) {
							return null;
						}
						return (
							<Fragment key={`preference-${index + 1}`}>
								<Grid
									container
									classes={{
										root: classes.preferenceRow,
										container: this.preferredContainerStyles(isPreferred),
									}}
								>
									<Hidden only={['xs', 'sm']}>
										{this.preferredDesktop(
											index,
											isPreferred,
											'airlinePreferences'
										)}
									</Hidden>
									<Hidden only={['md', 'lg', 'xl']}>
										{this.preferredMobile(
											index,
											isPreferred,
											'airlinePreferences'
										)}
									</Hidden>
									<Grid
										item
										xs={12}
										md={3}
										classes={{ root: classes.gridInputRoot }}
									>
										<FormControl classes={{ root: classes.setWidthTo100 }}>
											<InputLabel
												shrink
												htmlFor={`Airline-${index}`}
												classes={{ root: classes.inputLabel }}
											>
												Airline
											</InputLabel>
											<NativeSelect
												id={`Airline-${index}`}
												required
												onChange={(event) =>
													travelGroupHandler(
														event,
														index,
														'airlinePreferences'
													)
												}
												name='airlineName'
												value={
													airlinePreferences[
														index
													].airlineName.toLowerCase() || ''
												}
												input={
													<TextFieldComponent
														classes={{ input: classes.setWidthTo100 }}
													/>
												}
											>
												{this.airlineOptions.map((item) => (
													<option
														key={item.value}
														value={item.value.toLowerCase()}
													>
														{item.label}
													</option>
												))}
											</NativeSelect>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={12}
										md={2}
										classes={{ root: classes.gridInputRoot }}
									>
										<FormControl classes={{ root: classes.setWidthTo100 }}>
											<InputLabel
												shrink
												htmlFor={`FrequentFlyerNumber-${index}`}
												classes={{ root: classes.inputLabel }}
												error={
													!!airlinePreferences[index]
														.frequentFlyerNumber &&
													airlinePreferences[index].frequentFlyerNumber
														.length > 20
												}
											>
												Frequent Flyer No.
											</InputLabel>
											<TextFieldComponent
												id={`FrequentFlyerNumber-${index}`}
												classes={{ input: classes.setWidthTo100 }}
												onChange={(event) =>
													travelGroupHandler(
														event,
														index,
														'airlinePreferences'
													)
												}
												name='frequentFlyerNumber'
												value={
													airlinePreferences[index].frequentFlyerNumber ||
													''
												}
											/>
										</FormControl>
									</Grid>
									{index > airlinePreferences.length
										? null
										: this.renderAirlineRemoveOption(index)}
								</Grid>
							</Fragment>
						);
					})}
					<Grid container>{this.addAnotherAirlineButton()}</Grid>
				</Grid>
				<Grid container item xs={12} direction='row'>
					<div className={classes.alignLeft}>
						<DirectionsCar classes={{ root: classes.iconClass }} />
						<span>{t('profile:PREFERENCES.CAR_RENTAL_PREFERENCES')}</span>
					</div>
				</Grid>

				<Grid container classes={{ root: classes.travelGroupRoot }}>
					{rentalServices
						.sort((a, b) =>
							a.rentalCompany > b.rentalCompany
								? 1
								: b.rentalCompany > a.rentalCompany
								? -1
								: 0
						)
						.map((item, index) => {
							if (rentalServices[index].rentalCompany === 'Other') {
								return null;
							} else {
								return (
									<Fragment key={`rentalcar-${index + 1}`}>
										<Grid
											container
											classes={{
												root: classes.preferenceRow,
												container:
													this.preferredVendorContainerStyles(index),
											}}
										>
											{index === 0 ? (
												<Grid container item xs={12} direction='row'>
													{' '}
													<div className={classes.preferredVendor}>
														{' '}
														<span>
															{t(
																'profile:PREFERENCES.PREFERED_VENDOR'
															)}
														</span>
													</div>
												</Grid>
											) : null}

											<Grid
												item
												xs={12}
												md={3}
												classes={{ root: classes.gridInputRoot }}
											>
												<FormControl
													classes={{ root: classes.setWidthTo100 }}
												>
													<InputLabel
														shrink
														htmlFor={`RentalCompany-${index}`}
														classes={{ root: classes.inputLabel }}
													>
														Rental Car Company
													</InputLabel>
													<TextFieldComponent
														id={`RentalCompany-${index}`}
														classes={{
															input: classes.setWidthTo100,
															disabled: classes.disbaledInput,
														}}
														name='RentalCompany'
														value={
															rentalServices[index].rentalCompany ||
															''
														}
														disabled
													/>
												</FormControl>
											</Grid>

											{this.renderCarRentalMembershipNo(index)}
										</Grid>
									</Fragment>
								);
							}
						})}
				</Grid>
				<Grid container item xs={12} direction='row'>
					<div className={classes.alignLeft}>
						<Hotel classes={{ root: classes.iconClass }} />
						<span>{t('profile:PREFERENCES.LODGING_PREFERENCES')}</span>
					</div>
				</Grid>

				<Grid container classes={{ root: classes.travelGroupRoot }}>
					{hotels.map((item, index) => {
						const { isPreferred } = hotels[index];
						if (item.hotelName === null) {
							return null;
						}
						return (
							<Fragment key={`rentalcar-${index + 1}`}>
								<Grid
									container
									classes={{
										root: classes.preferenceRow,
										container: this.preferredContainerStyles(isPreferred),
									}}
								>
									<Hidden only={['xs', 'sm']}>
										{this.preferredDesktop(index, isPreferred, 'hotels')}
									</Hidden>
									<Hidden only={['md', 'lg', 'xl']}>
										{this.preferredMobile(index, isPreferred, 'hotels')}
									</Hidden>
									<Grid
										item
										xs={12}
										md={2}
										classes={{ root: classes.gridInputRoot }}
									>
										<FormControl classes={{ root: classes.setWidthTo100 }}>
											<InputLabel
												shrink
												htmlFor={`Hotel-${index}`}
												classes={{ root: classes.inputLabel }}
												error={
													!!hotels[index].hotelName &&
													hotels[index].hotelName.length > 50
												}
											>
												Hotel
											</InputLabel>
											<TextFieldComponent
												id={`Hotel-${index}`}
												classes={{
													input: classes.setWidthTo100,
													disabled: classes.disbaledInput,
												}}
												onChange={(event) => {
													travelGroupHandler(event, index, 'hotels');
												}}
												name='hotelName'
												value={hotels[index].hotelName || ''}
											/>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={12}
										md={2}
										classes={{ root: classes.gridInputRoot }}
									>
										<FormControl classes={{ root: classes.setWidthTo100 }}>
											<InputLabel
												shrink
												htmlFor={`MembershipNo-${index}`}
												classes={{ root: classes.inputLabel }}
												error={
													!!hotels[index].membershipNumber &&
													hotels[index].membershipNumber.length > 20
												}
											>
												Membership No
											</InputLabel>
											<TextFieldComponent
												id={`MembershipNo-${index}`}
												classes={{
													input: classes.setWidthTo100,
													disabled: classes.disbaledInput,
												}}
												onChange={(event) => {
													travelGroupHandler(event, index, 'hotels');
												}}
												name='membershipNumber'
												value={hotels[index].membershipNumber || ''}
											/>
										</FormControl>
									</Grid>
									{index > hotels.length - 1
										? null
										: this.renderHotelRemoveOption(index)}
								</Grid>
							</Fragment>
						);
					})}
					<Grid container>{this.addAnotherHotelButton()}</Grid>
				</Grid>
				<Grid container item xs={12} direction='row'>
					<div className={classes.alignLeft}>
						<Pets classes={{ root: classes.iconClass }} />
						<span>{t('profile:PREFERENCES.OTHER_PREFERENCES')}</span>
					</div>
				</Grid>
				<Grid
					container
					classes={{ root: classes.travelGroupRoot }}
					spacing={2}
					direction={'row'}
				>
					<Grid item xs={12} md={3} classes={{ root: classes.gridInputRoot }}>
						<FormControl classes={{ root: classes.setWidthTo100 }}>
							<InputLabel
								shrink
								htmlFor={`petTravel`}
								classes={{ root: classes.inputLabel }}
							>
								Will you be traveling with a pet?
							</InputLabel>
							<NativeSelect
								id={`petTravel`}
								required
								onChange={this.handlePetTravel}
								name='petTravel'
								value={clinicianPreferences.petTravel || ''}
								input={
									<TextFieldComponent
										classes={{ input: classes.setWidthTo100 }}
									/>
								}
							>
								<option key={null} value={null}></option>
								<option key={'Yes'} value={'Yes'}>
									Yes
								</option>
								<option key={'No'} value={'No'}>
									No
								</option>
							</NativeSelect>
						</FormControl>
					</Grid>
					{clinicianPreferences.petTravel === 'Yes' && (
						<Grid item xs={12} md={3} classes={{ root: classes.gridInputRoot }}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='petType'
									classes={{ root: classes.inputLabel }}
									error={
										!!clinicianPreferences.petType &&
										clinicianPreferences.petType.length > 100
									}
								>
									Type of Pet
								</InputLabel>
								<TextFieldComponent
									id='petType'
									classes={{
										input: classes.setWidthTo100,
										disabled: classes.disbaledInput,
									}}
									required
									onChange={this.handlePetType}
									name='petType'
									value={clinicianPreferences.petType || ''}
								/>
							</FormControl>
						</Grid>
					)}
					{clinicianPreferences.petTravel === 'Yes' && (
						<Grid item xs={12} md={3} classes={{ root: classes.gridInputRoot }}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='petBreed'
									classes={{ root: classes.inputLabel }}
									error={
										!!clinicianPreferences.petBreed &&
										clinicianPreferences.petBreed.length > 100
									}
								>
									Which breed?
								</InputLabel>
								<TextFieldComponent
									id='petBreed'
									classes={{
										input: classes.setWidthTo100,
										disabled: classes.disbaledInput,
									}}
									required
									onChange={this.handlePetBreed}
									name='petBreed'
									value={clinicianPreferences.petBreed || ''}
								/>
							</FormControl>
						</Grid>
					)}
					{clinicianPreferences.petTravel === 'Yes' && (
						<Grid item xs={12} md={3} classes={{ root: classes.gridInputRoot }}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='petWeight'
									classes={{ root: classes.inputLabel }}
									error={
										!!clinicianPreferences.petWeight &&
										clinicianPreferences.petWeight.length > 20
									}
								>
									How much does he/she weigh?
								</InputLabel>
								<TextFieldComponent
									id='petWeight'
									classes={{
										input: classes.setWidthTo100,
										disabled: classes.disbaledInput,
									}}
									required
									onChange={this.handlePetWeight}
									name='petWeight'
									value={clinicianPreferences.petWeight || ''}
								/>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>
		);
	}
}

export default withTranslation()(withStyles(styles)(PreferencesTravelComponent));
