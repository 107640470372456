const licenseStatusOption = [
	{
		value: 'Active',
		label: 'Active',
	},
	{
		value: 'Canceled',
		label: 'Canceled',
	},
	{
		value: 'Expired',
		label: 'Expired',
	},
	{
		value: 'Inactive',
		label: 'Inactive',
	},
	{
		value: 'Pending',
		label: 'Pending',
	},
	{
		value: 'Terminated',
		label: 'Terminated',
	},
	{
		value: 'Suspended',
		label: 'Suspended',
	},
	{
		value: 'Probation',
		label: 'Probation',
	},
	{
		value: 'Revoked',
		label: 'Revoked',
	},
	{
		value: 'Withdrawn',
		label: 'Withdrawn',
	},
	{
		value: 'Denied',
		label: 'Denied',
	},
];

const licenseTitleMD = [
	{
		value: 'DDS',
		label: 'DDS',
	},
	{
		value: 'DMD',
		label: 'DMD',
	},
	{
		value: 'DO',
		label: 'DO',
	},
	{
		value: 'DPM',
		label: 'DPM',
	},
	{
		value: 'MD',
		label: 'MD',
	},
];

const licenseTitleAP = [
	{
		value: 'CNM',
		label: 'CNM',
	},
	{
		value: 'CNS',
		label: 'CNS',
	},
	{
		value: 'CRNA',
		label: 'CRNA',
	},
	{
		value: 'PA',
		label: 'PA',
	},
	{
		value: 'LPN',
		label: 'LPN',
	},
	{
		value: 'NP',
		label: 'NP',
	},
	{
		value: 'PhD',
		label: 'PhD',
	},
	{
		value: 'PsyD',
		label: 'PsyD',
	},
	{
		value: 'SFA',
		label: 'SFA',
	},
	{
		value: 'RN',
		label: 'RN',
	},
	{
		value: 'CCP',
		label: 'CCP',
	},
];

const licenseTypeAP = [
	{
		value: 'State',
		label: 'State',
	},
	{
		value: 'CSR',
		label: 'CSR',
	},
	{
		value: 'DEA',
		label: 'DEA',
	},
	{
		value: 'Prescriptive Authority',
		label: 'Prescriptive Authority',
	},
	//{
	//    value: 'Tele-Medicine',
	//    label: 'Tele-Medicine',

	//}
];

const licenseTypeMD = [
	{
		value: 'State',
		label: 'State',
	},
	{
		value: 'CSR',
		label: 'CSR',
	},
	{
		value: 'DEA',
		label: 'DEA',
	},
	//{
	//    value: 'Tele-Medicine',
	//    label: 'Tele-Medicine',

	//}
];

export { licenseStatusOption, licenseTitleMD, licenseTitleAP, licenseTypeMD, licenseTypeAP };
