import { makeStyles } from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../utils/Application_Constants';

const useStyles = makeStyles((theme) => ({
	fileRejection: {
		color: COLORS.LT_PEPPER,
		fontSize: '.75rem',

		'& ul': {
			marginTop: 0,
			paddingLeft: '24px',
		},

		'& li span': {
			display: 'block',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			maxWidth: '100%',
		},
	},
	fileRejections: {
		marginTop: '24px',
	},
}));

const FileRejections = (props) => {
	const classes = useStyles();
	const { fileRejections } = props;

	return (
		<div className={classes.fileRejections}>
			{fileRejections.map(({ file, errors }) => (
				<div key={file.path} className={classes.fileRejection}>
					<b>{file.path}</b>
					<ul>
						{errors.map((e) => {
							const message =
								e.code === 'file-invalid-type' ? 'Invalid file type' : e.message;
							return (
								<li title={message} key={e.code}>
									<span>{message}</span>
								</li>
							);
						})}
					</ul>
				</div>
			))}
		</div>
	);
};

export default FileRejections;
