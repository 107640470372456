import React, { Component } from 'react';
import { withStyles, Hidden } from '@material-ui/core';
import { MailOutline, Phone } from '@material-ui/icons';
import { COLORS } from '../../../utils/Application_Constants';
import FacilityContactsAddDialog from '../FacilityContactsDialogs/FacilityContactsAddDialog';

const styles = (theme) => ({
	innerCardMobileWrapper: {
		display: 'flex',
		padding: '5px 12px',
		alignItems: 'center',
		justifyContent: 'left',
		height: '80px',
	},
	spacingBorder: {
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	contactCard: {
		paddingBottom: '0',
		paddingLeft: '0',
		display: 'flex',
		width: '40%',
		[theme.breakpoints.down('xs')]: {
			width: '70%',
		},
	},
	displayName: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
	},
	workLocation: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '14px',
		textAlign: 'left',
	},
	title: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '14px',
		textAlign: 'left',
	},
	contactWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	mobileTitleWrapper: {
		marginLeft: '15px',
	},
	communicationInformation: {
		with: '60%',
		paddingLeft: '15px',
	},
	contactIcon: {
		fontSize: '16px',
		marginRight: '5px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
		marginLeft: '10px',
	},
	phone: {
		marginTop: '5px',
	},
	editIcon: {
		position: 'absolute',
		width: '10%',
		right: '10px',
		[theme.breakpoints.down('xs')]: {
			right: '25px',
		},
	},
});

class DialogContactCard extends Component {
	renderEditIcon() {
		const { contact, facilityId, updateAssignments } = this.props;
		if (contact.editable) {
			return (
				<FacilityContactsAddDialog
					facilityId={facilityId}
					contact={contact}
					updateAssignments={updateAssignments}
					edit
				/>
			);
		}
		return null;
	}

	render() {
		const { classes, contact, isLast, facilityId, primaryWorkLocation } = this.props;
		return (
			<div className={classes.cardMobileWrapper}>
				<div
					className={`${classes.innerCardMobileWrapper} ${
						!isLast ? classes.spacingBorder : ''
					}`}
				>
					<div className={classes.contactCard}>
						<div className={classes.mobileTitleWrapper}>
							<div className={classes.displayName}>{contact.displayName}</div>
							{facilityId && contact.editable === false ? (
								<div className={classes.workLocation}>{primaryWorkLocation}</div>
							) : null}
							<div className={classes.title}>{contact.title}</div>
						</div>
					</div>
					<div className={classes.communicationInformation}>
						<div className={classes.contactWrapper}>
							<MailOutline classes={{ root: classes.contactIcon }} color='primary' />
							<Hidden only={['xs']}>
								<a href={`mailto:${contact.emailAddress}`}>
									<span className={classes.clickableSpan}>
										{contact.emailAddress}
									</span>
								</a>
							</Hidden>
						</div>
						<div className={`${classes.contactWrapper} ${classes.phone}`}>
							<Phone classes={{ root: classes.contactIcon }} color='primary' />
							<Hidden only={['xs']}>
								<a href={`tel:${contact.phoneNumber}`}>
									<span className={classes.clickableSpan}>
										{contact.phoneNumber}
									</span>
								</a>
							</Hidden>
						</div>
					</div>
					<div className={classes.editIcon}>{this.renderEditIcon()}</div>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(DialogContactCard);
