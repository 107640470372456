import React, { Component } from 'react';
import { List } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SideDrawerNavigation from './SideDrawerNavigation';
import { COLORS } from '../../../../utils/Application_Constants';
import menuNavigation from '../../../../configuration/navigation/credentials/menuItems.json';
import menuItems_woCredOverview from '../../../../configuration/navigation/credentials/menuItems_woCredOverview.json';
import menuItems_AP_path from '../../../../configuration/navigation/credentials/menuItems_AP_path.json';
import menuItems_AP_path_woCredOverview from '../../../../configuration/navigation/credentials/menuItems_AP_path_woCredOverview.json';
import { ACTIVE_PATHNAME_AP } from '../../../../utils/Application_Constants';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
	drawer: {
		width: '300px',
		flexShrink: 0,
	},
	drawerPaper: {
		width: '300px',
		backgroundColor: 'none',
		boxShadow: 'none',
		marginTop: '60px',
	},

	sideNavigation: {
		background: '#f7f7f7',
		[theme.breakpoints.up('md')]: {
			color: COLORS.RAVEN,
			height: '100%',
			overflow: 'hidden',
		},
		[theme.breakpoints.down('sm')]: {
			color: COLORS.RAVEN,
			height: '70vh',
			overflow: 'scroll',
		},
		padding: '0px',
	},
});

class SideNavigation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			profileNavigation: [],
			navigationPath: [],
		};
	}

	async componentDidMount() {
		const navigationFileName = await this.selectNavigationPath();

		if (navigationFileName) {
			this.setState({ navigationPath: navigationFileName });
		}
	}

	selectNavigationPath = async () => {
		const { activePath, showHideCredOverviewPage } = this.props;
		let navigationPath = menuNavigation.data;
		if (activePath === ACTIVE_PATHNAME_AP) {
			if (showHideCredOverviewPage()) {
				navigationPath = menuItems_AP_path.data;
			} else {
				navigationPath = menuItems_AP_path_woCredOverview.data;
			}
		} else {
			if (showHideCredOverviewPage()) {
				navigationPath = menuNavigation.data;
			} else {
				navigationPath = menuItems_woCredOverview.data;
			}
		}
		return navigationPath;
	};

	render() {
		const { classes, tabHandler } = this.props;
		return (
			<>
				<List className={classes.sideNavigation}>
					<SideDrawerNavigation
						tabHandler={tabHandler}
						menuNavigation={this.state.navigationPath}
					/>
				</List>
			</>
		);
	}
}

export default withRouter(withStyles(styles)(SideNavigation));
