import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import WithContexts from '../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import { Hidden } from '@material-ui/core';
import { COLORS, professionList } from '../../utils/Application_Constants';
import {
	withStyles,
	Button,
	FormControl,
	InputLabel,
	NativeSelect,
	ThemeProvider,
} from '@material-ui/core';
import StartCredentialingImage from '../../assets/images/start-credentialing-bg.jpg';
import StartCredentialingMobileIcon from '../../assets/icons/start-credentialing-mobile-icon.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextFieldComponent from '../../components/GlobalComponents/TextFieldComponent/TextFieldComponent';
import createTheme from '@material-ui/core/styles/createTheme';
const styles = (theme) => ({
	root: {
		// margin: '80px 0',
		//padding:'80px 0
		// height: '100vh',
		minHeight: '700px',
		// backgroundImage: `url(${StartCredentialingImage})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		// filter: 'blur(10px)'

		background: `linear-gradient(to right,#4370A0ba, #3f6a99), url(${StartCredentialingImage}) no-repeat top center`,
	},
	mobileIcon: {
		height: '500px',
	},
	leftPane: {
		padding: '80px 65px',
		[theme.breakpoints.down('sm')]: {
			padding: '50px 15px',
		},
		color: 'white',
	},
	rightPane: {
		paddingTop: '80px',
	},
	title: {
		color: '#FFFFFF',
		float: 'left',
		fontSize: '30px',
		fontWeight: '500',
		marginBottom: '10px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
			fontSize: '25px',
			paddingRight: '2px',
		},
	},
	subtitle: {
		color: '#FFFFFF',
		float: 'left',
		fontSize: '25px',
		fontWeight: '500',
		marginBottom: '30px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '20px',
		},
	},
	// section: {
	//     color: '#FFFFFF',
	//     float: 'left',
	//     fontSize: '13px',
	//     fontWeight: '300',
	//     textAlign: 'left',
	//     marginBottom: '20px'
	// },
	infoBlock: {
		color: '#FFFFFF',
		marginBottom: '20px',
	},
	info: {
		color: '#FFFFFF',
		float: 'left',
		textAlign: 'left',
		marginBottom: '10px',
	},
	informationText: {
		color: '#FFFFFF',
		textAlign: 'left',
		marginBottom: '10px',
	},
	getStartedButton: {
		//backgoundColor: '#2a3953',
		[theme.breakpoints.up('md')]: {
			width: '365px',
			fontSize: '16px',
			height: '50px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '310px',
			fontSize: '16px',
			height: '50px',
		},
		disabledButton: {
			backgroundColor: 'red',
		},
	},
	inputLabel: {
		color: '#FFFFFF',
		fontFamily: 'museo-sans',
		fontSize: '16px',
		fontWeight: '700',
		marginLeft: '0px',
		position: 'inherit',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		marginTop: '10px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	gridRoot: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	validationErrorMsg: {
		color: '#8b0000',
	},
});

// const errorTheme = createTheme({
// 	overrides: {
// 		MuiFormLabel: {
// 			root: {
// 				'&$error': {
// 					color: '#8b0000',
// 				},
// 			},
// 		},
// 	},
// });
const normalTheme = createTheme({
	overrides: {
		MuiNativeSelect: {
			// Name of the rule
			select: {
				'&:hover': {
					background: '#FFFFFF',
				},
				'&$focused': {
					background: '#FFFFFF',
					backgroundColor: '#FFFFFF',
				},
				background: '#FFFFFF !important',
				backgroundColor: '#FFFFFF !important',
			},
		},
		MuiFormLabel: {
			root: {
				color: '#FFFFFF',
				'&$focused': {
					color: '#FFFFFF',
				},
			},
		},
		MuiButton: {
			containedPrimary: {
				backgroundColor: COLORS.LT_SUNFLOWER,
				fontWeight: '700',
				borderRadius: '0px',
				boxShadow: 'none',
				color: COLORS.LT_MIDNIGHT,
				fontFamily: 'inherit',
				textTransform: 'none',
				'&:hover': {
					backgroundColor: COLORS.LT_SUNFLOWER75,
					boxShadow: 'none',
				},
			},
		},
	},
});
function StartCredentials({ classes, t, initialProfession, getStarted }) {
	const [profession, setProfession] = useState(initialProfession);
	const [submitted, setSubmitted] = useState(false);

	let items = ['1', '2', '3', '4', '5'];

	const inforamtionTextBlock = items.map((item, index) => {
		return (
			<div
				className={`${classes.informationText}`}
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<CheckCircleIcon style={{ height: '13px', width: '13px' }} />
				<span style={{ marginLeft: '10px' }}>
					{t(`credentials:START_CREDENTIALING.CRED_INFO_${item}`)}
				</span>
			</div>
		);
	});

	const handleChange = (event) => {
		setProfession(event.target.value);
	};

	const submitAction = () => {
		setSubmitted(true);
		if (!!profession) {
			getStarted(profession);
		}
	};

	return (
		<div className={classes.root}>
			<Container maxWidth='xl'>
				<CssBaseline />
				<Grid item xs={12}>
					<Grid container justify='space-evenly'>
						<Grid item xs={12} md={7} className={classes.leftPane}>
							<h1 className='cr-white'>
								{t(`credentials:START_CREDENTIALING.TITLE`)}
							</h1>
							<h3 className='cr-white'>
								{t(`credentials:START_CREDENTIALING.SUB_TITLE`)}
							</h3>
							<p className='cr-mt-4'>
								{t(`credentials:START_CREDENTIALING.SECTION`)}
							</p>

							<span className={`${classes.infoBlock}`}> {inforamtionTextBlock}</span>
							{/* <Grid item xs={12} md={7}>                            <span
                                className={`${classes.info}`}>{t(`credentials:START_CREDENTIALING.GET_STARTED_INFO`)}
                            </span>
                            </Grid> */}

							<Grid item classes={{ root: classes.gridRoot }} xs={12} md={7}>
								<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
									<ThemeProvider theme={normalTheme}>
										<InputLabel
											shrink
											htmlFor='profession'
											classes={{ root: classes.inputLabel }}
										>
											{t('credentials:START_CREDENTIALING.PROFESSION')}
										</InputLabel>
									</ThemeProvider>
									<ThemeProvider theme={normalTheme}>
										<NativeSelect
											id='profession'
											name='profession'
											value={profession}
											onChange={handleChange}
											input={
												<TextFieldComponent
													style={{ border: `0px solid ${COLORS.RAVEN}` }}
													classes={{
														input: classes.setWidthTo100OnMobile,
													}}
												/>
											}
										>
											{' '}
											<option key={''} value={null}></option>
											{professionList.map((item) => (
												<option key={item.value} value={item.value}>
													{item.label}
												</option>
											))}
										</NativeSelect>
									</ThemeProvider>
								</FormControl>
							</Grid>

							<Grid item xs={12} md={7}>
								<div>
									<ThemeProvider theme={normalTheme}>
										<Button
											className={classes.getStartedButton}
											variant='contained'
											color='primary'
											disabled={!profession || submitted}
											onClick={submitAction}
										>
											{t(`credentials:START_CREDENTIALING.GET_STARTED`)}
										</Button>
									</ThemeProvider>
								</div>
							</Grid>
						</Grid>
						<Hidden only={['xs', 'sm']}>
							<Grid item xs={12} md={5} className={classes.rightPane}>
								<img
									src={StartCredentialingMobileIcon}
									className={classes.mobileIcon}
									alt='StartCredentialing Mobile Icon'
								/>
							</Grid>
						</Hidden>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default WithContexts(withTranslation()(withStyles(styles)(StartCredentials)));
