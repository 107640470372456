import { Box, Button, Grid, MenuItem, Typography, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import ExpenseDatePicker from './ExpenseDatePicker';
import StatusOption from './StatusOption';
import { HistoryOutlined } from '@material-ui/icons';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ExpenseStatusEnum } from './ExpenseStatus.enum';
import { PreCodedStatusEnum } from './PreCodedStatus.enum';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    formHeader: {
        borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
        paddingBottom: 8,
        marginBottom: 16,
    },
}));

const InternalNotesForm = (props) => {
    const { index, expense, isReimbursement, mode } = props;
    const classes = useStyles();
    const { control, errors } = useFormContext();
    const statusEnum = mode === 'pending-cc' ? PreCodedStatusEnum : ExpenseStatusEnum
    const statuses = useMemo(
        () => Object.entries(statusEnum).map(([key, value]) => value),
        []
    );
    const { id } = useParams();
    const isNew = expense.id ? false : true;
    const createdDate = useMemo(
        () => moment(expense.created) && moment(expense.created).format('MM/DD/YYYY'),
        [expense]
    );
    const precodedCreatedDate = useMemo(
        () => moment(expense.precodedCreated) && moment(expense.precodedCreated).format('MM/DD/YYYY'),
        [expense]
    );


    return (
        <div>
            <Typography variant='h5' component='h5' className={classes.formHeader}>
                Internal Notes
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].notes`}
                        defaultValue={expense.notes || ''}
                        render={({ onChange, value }) => (
                            <ExpenseTextField
                                multiline
                                inputProps={{ maxLength: 300 }}
                                minRows={3}
                                id='notes'
                                onChange={onChange}
                                value={value}
                                label='Notes'
                                helperText={value && `${value.length}/300`}
                                placeholder='Internal notes here'
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].status`}
                        defaultValue={expense.status}
                        rules={{
                            required: 'Status is required',
                        }}
                        render={({ onChange, value }) => (
                            <ExpenseTextField
                                id={'status'}
                                select
                                onChange={onChange}
                                value={value}
                                label={'Status'}
                                required
                                error={Boolean(errors.expenseEntries?.[index]?.status)}
                                helperText={errors.expenseEntries?.[index]?.status?.message}
                            >
                                <MenuItem value='' disabled>
                                    Select a status
                                </MenuItem>
                                {statuses.map((status, index) => (
                                    <MenuItem
                                        key={status.value}
                                        value={status.value}
                                        id={`status-menu-option-${index}`}
                                    >
                                        <StatusOption status={status.value} />
                                    </MenuItem>
                                ))}
                            </ExpenseTextField>
                        )}
                    />
                </Grid>
                {!isReimbursement && id && (
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={`expenseEntries[${index}].periodEnd`}
                                    defaultValue={expense.periodEnd || null}
                                    rules={{
                                        required: 'Transaction date required',
                                        validate: {
                                            invalid: (value) =>
                                                moment(value).isValid() || 'Invalid date',
                                        },
                                    }}
                                    render={({ onChange, value }) => (
                                        <ExpenseDatePicker
                                            onChange={(e) => onChange(handleDateChange(e))}
                                            value={value}
                                            id='period-end'
                                            label='Period end'
                                            disabled
                                            error={Boolean(
                                                errors.expenseEntries?.[index]?.periodEnd
                                            )}
                                            helperText={
                                                errors.expenseEntries?.[index]?.periodEnd?.message
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={`expenseEntries[${index}].postingDate`}
                                    defaultValue={expense.postingDate || null}
                                    rules={{
                                        required: 'Post date required',
                                        validate: {
                                            invalid: (value) =>
                                                moment(value).isValid() || 'Invalid date',
                                        },
                                    }}
                                    render={({ onChange, value }) => (
                                        <ExpenseDatePicker
                                            onChange={(e) => onChange(handleDateChange(e))}
                                            value={value}
                                            id='post-date'
                                            label='Post date'
                                            disabled
                                            error={Boolean(
                                                errors.expenseEntries?.[index]?.postingDate
                                            )}
                                            helperText={
                                                errors.expenseEntries?.[index]?.postingDate?.message
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} md={6}>
                            {!isNew && (
                                <Typography variant='body2'>
                                    {
                                        (expense.hasOwnProperty('wasMergedWithAPrecoded') && expense.wasMergedWithAPrecoded === true)
                                            ? `Pending CC Submitted by ${expense.precodedCreatedUserName} on ${precodedCreatedDate} `
                                            : (
                                                (expense.expenseClass === "IngestedCCTransaction")
                                                    ? `Imported by ${expense.createdUserName} on ${createdDate} `
                                                    : `Submitted by ${expense.createdUserName} on ${createdDate} `
                                            )
                                    }
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display={'flex'} justifyContent={'flex-end'}>
                                <Button startIcon={<HistoryOutlined />}>Expense History</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default InternalNotesForm;
