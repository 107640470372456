import React from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import { ltdomain } from '../../../utils/helpers';

const styles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
	},
}));

function ResourcesTile({ t }) {
	const classes = styles();

	return (
		<>
			<Paper className={classes.paper}>
				<Grid item xs={12} md={12}>
					<h3>{t(`dashboard:HEADERS.RESOURCE_CENTER`)}</h3>
					<p>{t(`dashboard:INFO.RESOURCE_CENTER_TEXT`)}</p>
					<a className='textLink' target='_Self' href={ltdomain() + '/resource-center/'}>
						{t(`dashboard:BUTTONS.VISIT_CENTER`)}
					</a>
				</Grid>
			</Paper>
		</>
	);
}

export default ResourcesTile;
