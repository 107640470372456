import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import {
	FormControlLabel,
	InputAdornment,
	NativeSelect,
	Radio,
	RadioGroup,
	ThemeProvider,
	FormHelperText,
	CircularProgress,
	Checkbox,
	Input,
} from '@material-ui/core';
import {
	COLORS,
	COUNTRIES,
	SEGMENT_PA,
	SEGMENT_SFA,
	SEGMENT_ALLIED,
	ACTIVE_PATHNAME_AP,
	ACTIVE_PATHNAME_MD,
	CRED_STATE,
	SEGMENT_PERFUSIONIST,
} from '../../../../../utils/Application_Constants';
import TextFieldComponent from '../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { statesForLicense } from '../../../Account/accountOptions';
import createTheme from '@material-ui/core/styles/createTheme';
import DatePickerComponent from '../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import { Event } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import {
	getEducation,
	postEducation,
	deleteEducation,
} from '../../../../../services/Credentialing';
import { withStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { convertToDateIgnoringTime, matchesTemplate } from '../../../../../utils/helpers';
import {
	mdDegrees,
	nursingPostGrad,
	nursingUnderGrad,
	paPostGrad,
	paUnderGrad,
	perfusionistPostGrad,
	perfusionistUnderGrad,
	psychPostGrad,
	psychUnderGrad,
	sfaPostGrad,
	sfaUnderGrad,
} from './EducationOptions';
import {
	educationSectionCheck,
	educationSectionPointsCheck,
} from '../../../../../utils/CredentialingAppUtility';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import Alert from '@material-ui/lab/Alert';
import RequiredQuestionDialog from '../../../../GlobalComponents/Dialog/RequiredQuestionDialog';

const styles = (theme) => ({
	// cssOutlinedInput: {
	//     marginBottom: '15px',
	// }
});

function EducationForm({
	classes,
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	setCredAppError,
	flags,
	updateFlags,
	titles,
	activePath,
	segment,
	credState,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	const normalTheme = createTheme({
		overrides: {
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					// marginBottom: '15px',
				},
			},

			MuiSelect: {
				select: {},
			},
		},
	});
	const errorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					border: `1px solid ${COLORS.LT_PEPPER}!important`,
				},
			},
		},
	});

	const formTemplate = {
		id: 'null',
		medicalSchoolId: 'null',
		medicalSchool: '',
		streetAddress: '',
		city: '',
		state: '',
		zipCode: '',
		country: '',
		dateFrom: null,
		dateTo: null,
		educationDegree: '',
		educationLevel: '',
	};

	const itemTemplate = {
		id: null,
		medicalSchoolId: null,
		medicalSchool: '',
		streetAddress: '',
		city: '',
		state: '',
		zipCode: '',
		country: '',
		dateFrom: null,
		dateTo: null,
		educationDegree: '',
		educationLevel: '',
	};

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isRequired, setIsRequired] = useState(false);
	const [ecfmgDateIssued, setEcfmgDateIssued] = useState(null);
	const [isEcfmgCertified, setIsEcfmgCertified] = useState(false);
	const [fifthPathway, setFifthPathWay] = useState(false);
	const [revision, setRevision] = useState(0);
	const [deleteRecordIndex, setDeleteRecordIndex] = useState();
	const [deleteRecordId, setDeleteRecordId] = useState();
	const [deleteRecords, setDeleteRecords] = useState([]);
	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);

	const { register, control, handleSubmit, errors, reset, getValues, formState, trigger, watch } =
		useForm({
			mode: 'onBlur',
			defaultValues: {
				id: null,
				medicalSchoolId: null,
				medicalSchool: '',
				streetAddress: '',
				city: '',
				state: '',
				zipCode: '',
				country: '',
				dateFrom: null,
				dateTo: null,
				completed: null,
				educationDegree: '',
				educationLevel: '',
				isVerified: false,
			},
		});
	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			setSaveHandler(
				EducationForm,
				'Education',
				handleSubmit(async (info) => await onSubmit(info))
			);

			await Promise.all([getEducation(externalId)])
				.then(([info]) => {
					if (!!info) {
						//const { educations = [] } = info;

						if (activePath === ACTIVE_PATHNAME_MD) {
							info['educations'] = info['educations'].filter(
								(item) => item.educationLevel === 'Medical School'
							);
						}

						if (info['educations'].length === 0) {
							if (activePath === ACTIVE_PATHNAME_AP) {
								info['educations'].push({
									id: null,
									medicalSchoolId: null,
									medicalSchool: '',
									streetAddress: '',
									city: '',
									state: '',
									zipCode: '',
									country: '',
									dateFrom: null,
									dateTo: null,
									completed: null,
									educationDegree: '',
									educationLevel: 'Professional',
									isGraduate: true,
									isVerified: false,
								});
							} else {
								info['educations'].push({
									id: null,
									medicalSchoolId: null,
									medicalSchool: '',
									streetAddress: '',
									city: '',
									state: '',
									zipCode: '',
									country: '',
									dateFrom: null,
									dateTo: null,
									completed: null,
									educationDegree: '',
									educationLevel: 'Medical School',
									isVerified: false,
								});
							}
						} else {
							info['educations'].map((item) => {
								//Set graduate level
								//set to true if education level is professional
								item.isGraduate =
									item.educationLevel === 'Professional' ||
									item.educationLevel === 'Medical School' ||
									item.educationLevel === 'Post Graduate';
								if (item.medicalSchool === null) {
									item.medicalSchool = '';
								}
								if (item.streetAddress === null) {
									item.streetAddress = '';
								}
								if (item.city === null) {
									item.city = '';
								}
								if (item.state === null) {
									item.state = '';
								}
								if (item.zipCode === null) {
									item.zipCode = '';
								}
								if (item.country === null) {
									item.country = '';
								}
								if (item.educationDegree === null) {
									item.educationDegree = '';
								}

								item.completed = `${item.completed}`;
								//TDS-3251 temparory disable check
								item.isVerified = false;

								return item;
							});
						}
						if (!info.ecfmgDateIssued) info['ecfmgDateIssued'] = null;
						else
							info['ecfmgDateIssued'] = moment(
								convertToDateIgnoringTime(info.ecfmgDateIssued)
							).format('MM/DD/yyyy');

						if (!info.fifthPathway) info['fifthPathway'] = false;

						reset(info);
						flags.dataSaved = false;
						updateFlags(flags);
						setIsLoaded(true);
						setEcfmgDateIssued(info['ecfmgDateIssued']);
						setFifthPathWay(info['fifthPathway']);

						//TDS-3125 temporary diable check
						//setIsEcfmgCertified(info['isEcfmgCertified'])
						setIsEcfmgCertified(false);

						console.log('response is', info);

						if (shouldHighlightFields) {
							setLocalShouldHighlightFields(true);
							let status = {
								status: 'incomplete',
								missingFields: [],
							};

							status = educationSectionCheck(info, activePath, titles, segment);

							if (!!status.missingFields && status.missingFields.length > 0) {
								setHighlights(status.missingFields);
							}
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);

	function updateHighlight() {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};

			status = educationSectionCheck(watch(), activePath, titles, segment);

			//alert(`missingFields: ${status.missingFields}`)
			setHighlights(status.missingFields);
		}
	}

	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'educations',
	});

	/**
	 * Check if empty form exists
	 * @param index
	 * @returns {boolean}
	 */

	const isEmptyForm = async (index) => {
		const { educations } = getValues();
		if (educations !== null && educations !== undefined) {
			await setIsRequired(!matchesTemplate(educations[index], formTemplate));

			var allTriggers = [
				`educations[${index}].medicalSchool`,
				`educations[${index}].city`,
				`educations[${index}].state`,
				`educations[${index}].country`,
			];
			var dataFields = Object.keys(educations[index]);
			var runTriggers = allTriggers.filter((val) => {
				return dataFields.includes(val.substring(val.lastIndexOf('.') + 1));
			});

			trigger(runTriggers);
		}
	};

	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('educations')) {
			for (let i = 0; i < errors.educations.length; i++) {
				if (!!errors?.educations[i]) {
					//This would be 'return anError.type !== 'manual'', but manually set errors return a malformed error object with no 'type' field (the contents of the type field are inserted as an array of chars on the main object)
					missingRequiredToSaveFields = Object.values(errors.educations[i]).filter(
						(anError) => {
							return anError.hasOwnProperty('type');
						}
					);
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	async function onSubmit(data) {
		let success = false;

		const { externalId } = UserContext;
		data['clinicianId'] = externalId;
		data['ecfmgDateIssued'] =
			activePath === ACTIVE_PATHNAME_MD
				? moment(data.ecfmgDateIssued).toISOString(true)
				: null;
		data['fifthPathway'] = activePath === ACTIVE_PATHNAME_MD ? data.fifthPathway : false;
		if (!!data['educations']) {
			data['educations'] = data['educations']
				.map((item) => {
					if (item.id === 'null') item.id = null;
					if (item.medicalSchoolId === 'null') item.medicalSchoolId = null;

					if (
						item.completed !== null &&
						item.completed !== 'null' &&
						item.completed !== '' &&
						item.completed !== undefined
					) {
						item.completed = item.completed !== 'false';
					} else {
						item.completed = null;
					}

					if (item.dateFrom !== null) {
						let firstDay = moment(item.dateFrom).startOf('month').format('MM/DD/yyyy');
						item.dateFrom = !firstDay ? null : moment(firstDay).toISOString(true);
					}
					if (item.dateTo !== null) {
						let lastDay = moment(item.dateTo).endOf('month').format('MM/DD/yyyy');
						item.dateTo = !lastDay ? null : moment(lastDay).toISOString(true);
					}

					//item.dateFrom = !item.dateFrom ? null : moment(item.dateFrom).toISOString(true);
					//item.dateTo = !item.dateTo ? null : moment(item.dateTo).toISOString(true);
					// There is some VERY strange bug that causes fifthPathway to be turned into an array when setting it to true/false.
					// If you can figure that out, that would be wonderful. I've spent far too much time on this stupid error. I'm just going
					// to put code here to convert it.
					if (Array.isArray(item.fifthPathway))
						item.fifthPathway = item.fifthPathway.length > 0;
					// if (item.fifthPathway === null) item.fifthPathway = false;

					itemTemplate.educationLevel = item.educationLevel;
					if (matchesTemplate(item, itemTemplate)) {
						return null;
					}
					return item;
				})
				.filter((d) => d !== null);
		}

		if (deleteRecords.length > 0) {
			const deleteRecordsNumbers = deleteRecords.filter(function (element) {
				return element !== 'null';
			});
			await deleteEducation(externalId, deleteRecordsNumbers).catch((e) => {
				setCredAppError(e);
			});
		}

		const jsonData = JSON.stringify(data);
		console.log('submitting:' + jsonData);
		await postEducation(externalId, jsonData);

		sectionCompletion.education.current = await educationSectionPointsCheck(
			data,
			activePath,
			titles,
			segment,
			sectionCompletion
		);
		updateSectionCompletion(sectionCompletion);
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		updateFlags(flags);
		success = true;
		setIsLoaded(true);

		updateFlags(flags);
		return success;
	}

	const deleteEntry = (btnPressed, index) => {
		if (btnPressed === 'Yes') {
			if (deleteRecordId !== null) {
				deleteRecords.push(deleteRecordId);
				setDeleteRecords(deleteRecords);
				setShowDeleteModal(false);
			}
			remove(deleteRecordIndex);
			setShowDeleteModal(false);
		}
		setShowDeleteModal(false);
	};

	const openDeleteConfirmation = (item, index) => {
		setDeleteRecordIndex(index);
		setDeleteRecordId(item.id);
		setShowDeleteModal(true);
	};

	// const datePickerHelper = (date) => {
	// 	if (!!date) {
	// 		return date;
	// 	}
	// 	return null;
	// };

	// const ecfmgDateChangeHandler = (data) => {
	// 	setEcfmgDateIssued(data);
	// };

	// const ecfmgComponent = (index, country, activePath, isVerified,fields) =>
	const ecfmgComponent = (activePath, fields) => {
		if (!titles || (!titles.includes('MD') && !titles.includes('DO'))) {
			return null;
		}

		for (let i = 0; i < fields.length; i++) {
			let item = fields[i];
			let country = item.country;
			let index = i;
			let isVerified = item.isVerified;

			if (
				activePath !== ACTIVE_PATHNAME_AP &&
				country !== 'US' &&
				country !== 'U.S.' &&
				country !== 'United States' &&
				country !== 'Canada' &&
				country !== '' &&
				!!country
			) {
				return (
					<Grid container direction='row'>
						<Grid item xs={12} md={12}>
							<h3 className={classes.header}>
								{t(`credentials:EDUCATION.INTERNATIONAL`)}
							</h3>
						</Grid>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='ecfmgDateIssued'
									classes={{ root: classes.inputLabel }}
									error={
										errors?.ecfmgDateIssued ||
										(!!highlights &&
											highlights.includes(`education.ecfmgDateIssued`))
									}
									required={
										activePath !== ACTIVE_PATHNAME_AP &&
										country !== 'US' &&
										country !== 'U.S.' &&
										country !== 'United States' &&
										country !== 'Canada' &&
										country !== '' &&
										!!country
									}
								>
									{t('credentials:EDUCATION.ECFMG_DATE')}
								</InputLabel>
								<ThemeProvider theme={datePickerTheme}>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<Controller
											control={control}
											rules={{
												validate: (date) => dateValidation(date),
											}}
											defaultValue={
												ecfmgDateIssued === 'null'
													? null
													: convertToDateIgnoringTime(ecfmgDateIssued)
											}
											name='ecfmgDateIssued'
											render={({ onChange, value, name }) => (
												<DatePickerComponent
													theme={datePickerTheme}
													error={
														errors?.ecfmgDateIssued ||
														(!!highlights &&
															highlights.includes(
																`education.ecfmgDateIssued`
															))
													}
													variant='inline'
													autoOk='true'
													clearable={true}
													format='MM/dd/yyyy'
													placeholder='MM/DD/YYYY'
													name={name}
													margin='normal'
													disabled={
														isEcfmgCertified ||
														credState === CRED_STATE.CLOSED ||
														credState === CRED_STATE.CLOSED
													}
													inputRef={register()}
													InputProps={{
														classes: {
															disabled: classes.disabledInput,
															root: classes.cssOutlinedInput,
															error: classes.error,
														},
														endAdornment: (
															<InputAdornment position='end'>
																<Event />
															</InputAdornment>
														),
													}}
													value={value}
													onChange={onChange}
													onBlur={() => {
														updateHighlight();
													}}
													minDateMessage={
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.DATE'
															)}
														</span>
													}
													maxDateMessage={
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.DATE'
															)}
														</span>
													}
													invalidDateMessage={
														<span
															className={`${classes.cssLabel} ${classes.marginLeft12}`}
														>
															{t(
																'credentials:VALIDATION_MESSAGE.DATE'
															)}
														</span>
													}
													KeyboardButtonProps={{
														'aria-label': t(
															'credentials:EDUCATION.ECFMG_DATE'
														),
													}}
													inputVariant='outlined'
												/>
											)}
										/>
									</MuiPickersUtilsProvider>
								</ThemeProvider>
							</FormControl>
						</Grid>
						<Grid classes={{ root: classes.gridRoot }} item xs={12} md={10}>
							<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
								<InputLabel
									shrink
									htmlFor='ecfmgNumber'
									classes={{ root: classes.inputLabel }}
									error={
										errors?.ecfmgNumber ||
										(!!highlights &&
											highlights.includes(`education.ecfmgNumber`))
									}
									//className={(errors?.ecfmgNumber || (!!highlights && !!includes(`education.ecfmgNumber`))) && classes.errorColor}
								>
									{t('credentials:EDUCATION.ECFMG')}
								</InputLabel>
								<TextField
									id='ecfmgNumber'
									name='ecfmgNumber'
									className={
										isEcfmgCertified || credState === CRED_STATE.CLOSED
											? classes.disabledInput
											: classes.normalInput
									}
									margin='dense'
									variant='outlined'
									inputRef={register({
										maxLength: {
											value: 30,
											message: t('common:MESSAGES.CHARACTER_LIMIT'),
										},
									})}
									helperText={
										errors?.ecfmgNumber
											? t('common:MESSAGES.CHARACTER_LIMIT')
											: null
									}
									onChange={() => isEmptyForm(index)}
									onBlur={() => {
										isEmptyForm(index);
										updateHighlight();
									}}
									autoComplete='off'
									error={
										errors?.ecfmgNumber ||
										(!!highlights &&
											highlights.includes(`education.ecfmgNumber`))
									}
									InputProps={{
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											error: classes.error,
										},
										readOnly:
											Boolean(isEcfmgCertified) ||
											credState === CRED_STATE.CLOSED,
									}}
								/>
							</FormControl>
						</Grid>

						<Grid classes={{ root: classes.gridRoot }} item xs={1} md={12}>
							<div>
								<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
									<InputLabel
										shrink
										htmlFor='fifthPathway'
										classes={{ root: 'inputLabel', error: classes.error }}
										// error={(errors?.educations && errors?.educations[`${index}`]?.fifthPathway) || (!!highlights && highlights.includes(`educations[${index}].fifthPathway`))}
									>
										{t('credentials:EDUCATION.FIFTH_PATHWAY')}
									</InputLabel>
									<Controller
										name='fifthPathway'
										control={control}
										rules={{}}
										defaultChecked={
											`${fifthPathway}` === 'null' ? false : `${fifthPathway}`
										}
										render={({ onChange, value, name }) => (
											<Checkbox
												color='primary'
												style={{
													marginTop: '10px',
													justifyContent: 'left',
												}}
												onChange={(e) => onChange(e.target.checked)}
												//onChnage = {e => fifthPathwayChangeHandler(e.target.checked)}
												onBlur={() => updateHighlight()}
												checked={value}
												name={name}
												// disabled={(!!item.id && (!!item.isVerified && credState === CRED_STATE.OPEN) || credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN)}
												disabled={
													(!!isVerified &&
														credState === CRED_STATE.OPEN) ||
													credState === CRED_STATE.CLOSED ||
													credState === CRED_STATE.PARTIALLY_OPEN
												}
											/>
										)}
									/>
								</FormControl>
							</div>
						</Grid>
					</Grid>
				);
			}
			// else {
			//     return null;
			// }
		}
	};

	const countryChanged = (item, index, data) => {
		item.country = data.target.value;
		setRevision(revision + 1);
	};

	const renderMedSchool = (item, index, itemDegrees, forGraduate) => {
		// See if we need to skip this one
		if (activePath === ACTIVE_PATHNAME_AP && !!forGraduate !== !!item.isGraduate) {
			return null;
		}

		const lockFields =
			!!item.id &&
			(credState === CRED_STATE.CLOSED ||
				(!!item.isVerified &&
					(activePath === ACTIVE_PATHNAME_MD || credState === CRED_STATE.OPEN)) ||
				(!!item.isVerified &&
					activePath === ACTIVE_PATHNAME_AP &&
					item.isGraduate &&
					(credState === CRED_STATE.PARTIALLY_OPEN || credState === CRED_STATE.OPEN)) ||
				(activePath === ACTIVE_PATHNAME_AP &&
					!item.isGraduate &&
					credState === CRED_STATE.PARTIALLY_OPEN));

		let dateFormat = 'MM/yyyy';
		// format="MM/yyyy"
		// placeholder="MM/YYYY"
		let dateErrorMsg = t('credentials:VALIDATION_MESSAGE.DATE');
		if (activePath !== ACTIVE_PATHNAME_AP) {
			dateFormat = 'MM/yyyy';
			dateErrorMsg = t('credentials:VALIDATION_MESSAGE.MONTH_AND_YEAR');
		}

		return (
			<Grid container key={`ed-${item.id}`}>
				<RouteDeparturePrompt
					blockedBackNavigation={blockedBackNavigation}
					when={!flags.dataSaved ? !!Object.keys(formState.dirtyFields).length : false}
				/>

				<Grid
					container
					className={index === 0 ? 'credAppFirstHeader' : 'credAppAdditionalHeader'}
				>
					<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
						{index === 0
							? 'Education'
							: t(`credentials:EDUCATION.ADDITIONAL_EDUCATION`)}
					</Grid>
					<Grid
						item
						xs={3}
						className={
							item.isVerified || credState === CRED_STATE.CLOSED
								? classes.hideDeleteBtn
								: 'credAppAdditionalDelete'
						}
						onClick={() => {
							openDeleteConfirmation(item, index);
						}}
					>
						{t('credentials:EDUCATION.DELETE_BTN')}
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={12}>
						<Grid container>
							<Grid item sm={8} xs={12}>
								<CssBaseline />
								<Input
									style={{ display: 'none' }}
									name={`educations[${index}].id`}
									value={`${item.id}` || null}
									defaultValue={`${item.id}`}
									inputRef={register()}
								/>
								<Input
									style={{ display: 'none' }}
									name={`educations[${index}].medicalSchoolId`}
									value={`${item.medicalSchoolId}` || null}
									defaultValue={`${item.medicalSchoolId}`}
									inputRef={register()}
								/>
								<Input
									style={{ display: 'none' }}
									name={`educations[${index}].educationLevel`}
									value={`${item.educationLevel}` || null}
									defaultValue={`${item.educationLevel}`}
									inputRef={register()}
								/>
							</Grid>
						</Grid>

						<Grid container mb={1}>
							<Grid item sm={5} xs={12}>
								<FormControl fullWidth={true}>
									<InputLabel
										shrink
										htmlFor={`educations[${index}].medicalSchool`}
										classes={{ root: classes.inputLabel }}
										className={
											errors?.educations &&
											errors?.educations[`${index}`]?.medicalSchool &&
											classes.errorColor
										}
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.medicalSchool) ||
											(!!highlights &&
												highlights.includes(
													`educations[${index}].medicalSchool`
												))
										}
										required
									>
										{activePath !== ACTIVE_PATHNAME_AP
											? t('credentials:EDUCATION.MD_SCHOOL_NAME')
											: t('credentials:EDUCATION.AP_SCHOOL_NAME')}
									</InputLabel>
									<TextField
										id={`educations[${index}].medicalSchool`}
										name={`educations[${index}].medicalSchool`}
										className={
											lockFields ? classes.disabledInput : classes.normalInput
										}
										margin='dense'
										variant='outlined'
										defaultValue={`${item.medicalSchool}`}
										inputRef={register({
											required: isRequired,
											maxLength: {
												value: 80,
												message: t('common:MESSAGES.CHARACTER_LIMIT'),
											},
											pattern: /^[A-Za-z0-9][\s\S]{2,}/,
										})}
										helperText={
											(errors?.educations &&
												errors?.educations[`${index}`]?.medicalSchool &&
												errors?.educations[`${index}`]?.medicalSchool[
													'type'
												] !== 'maxLength') ||
											(!!highlights &&
												highlights.includes(
													`educations[${index}].medicalSchool`
												))
												? t('credentials:VALIDATION_MESSAGE.SCHOOL_NAME')
												: (errors?.educations &&
														errors?.educations[`${index}`]
															?.medicalSchool &&
														errors?.educations[`${index}`]
															?.medicalSchool['type'] ===
															'maxLength') ||
												  (!!highlights &&
														highlights.includes(
															`educations[${index}].medicalSchool`
														))
												? t('common:MESSAGES.CHARACTER_LIMIT')
												: null
										}
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.medicalSchool) ||
											(!!highlights &&
												highlights.includes(
													`educations[${index}].medicalSchool`
												))
										}
										onBlur={() => {
											isEmptyForm(index);
											updateHighlight();
										}}
										onChange={() => isEmptyForm(index)}
										autoComplete='off'
										InputProps={{
											classes: {
												root: classes.cssOutlinedInput,
												focused: classes.cssFocused,
												notchedOutline: classes.notchedOutline,
												error: classes.error,
											},
											readOnly: lockFields,
										}}
									/>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item sm={5} xs={12}>
								<FormControl fullWidth={true}>
									<InputLabel
										shrink
										InputLabelProps={{ shrink: false }}
										htmlFor={`educations[${index}].country`}
										required
										//className={errors?.educations && errors?.educations[`${index}`]?.country && classes.errorColor}
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.country) ||
											(!!highlights &&
												highlights.includes(`educations[${index}].country`))
										}
									>
										{t('credentials:EDUCATION.COUNTRY')}
									</InputLabel>
									<ThemeProvider
										theme={
											(errors?.educations &&
												errors?.educations[`${index}`]?.country) ||
											(!!highlights &&
												highlights.includes(`educations[${index}].country`))
												? errorTheme
												: normalTheme
										}
									>
										<NativeSelect
											id={`educations[${index}].country`}
											defaultValue={`${item.country}`}
											inputRef={register({ required: isRequired })}
											name={`educations[${index}].country`}
											className={
												(!!item.isVerified &&
													(activePath === ACTIVE_PATHNAME_MD ||
														item.isGraduate)) ||
												credState === CRED_STATE.CLOSED
													? classes.disabledSelect
													: classes.normalInput
											}
											onBlur={() => {
												isEmptyForm(index);
												updateHighlight();
											}}
											onChange={(data) => {
												countryChanged(item, index, data);
												isEmptyForm(index);
											}}
											disabled={lockFields}
											input={<TextFieldComponent />}
										>
											<option key={'country'} value={null}></option>
											{COUNTRIES.map((item) => (
												<option key={item} value={item}>
													{item}
												</option>
											))}
										</NativeSelect>
									</ThemeProvider>
									{errors?.educations &&
										errors?.educations[`${index}`]?.country && (
											<FormHelperText className={'Mui-error'}>
												{t('credentials:VALIDATION_MESSAGE.EDU_COUNTRY')}
											</FormHelperText>
										)}
								</FormControl>

								{lockFields ? (
									<Input
										style={{ display: 'none' }}
										name={`educations[${index}].country`}
										value={`${item.country}`}
										inputRef={register()}
									/>
								) : null}
							</Grid>
						</Grid>
						<Grid container>
							<Grid item sm={6} xs={12}>
								<FormControl fullWidth={true}>
									<InputLabel
										shrink
										htmlFor={`educations[${index}].streetAddress`}
										classes={{ root: classes.inputLabel }}
										//className={errors?.educations && errors?.educations[`${index}`]?.streetAddress && classes.errorColor}
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.streetAddress) ||
											(!!highlights &&
												highlights.includes(
													`educations[${index}].streetAddress`
												))
										}
									>
										{t('credentials:EDUCATION.ADDRESS')}
									</InputLabel>
									<TextField
										id={`educations[${index}].streetAddress`}
										name={`educations[${index}].streetAddress`}
										className={
											lockFields ? classes.disabledInput : classes.normalInput
										}
										margin='dense'
										variant='outlined'
										defaultValue={`${item.streetAddress}`}
										inputRef={register({
											maxLength: {
												value: 255,
												message: t('common:MESSAGES.CHARACTER_LIMIT'),
											},
										})}
										helperText={
											(errors?.educations &&
												errors?.educations[`${index}`]?.streetAddress &&
												errors?.educations[`${index}`]?.streetAddress[
													'type'
												] === 'maxLength') ||
											(!!highlights &&
												highlights.includes(
													`educations[${index}].streetAddress`
												))
												? t('common:MESSAGES.CHARACTER_LIMIT')
												: null
										}
										onBlur={() => {
											isEmptyForm(index);
											updateHighlight();
										}}
										onChange={() => isEmptyForm(index)}
										autoComplete='off'
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.streetAddress) ||
											(!!highlights &&
												highlights.includes(
													`educations[${index}].streetAddress`
												))
										}
										InputProps={{
											classes: {
												root: classes.cssOutlinedInput,
												focused: classes.cssFocused,
												notchedOutline: classes.notchedOutline,
												error: classes.error,
											},
											readOnly: lockFields,
										}}
									/>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container spacing={4}>
							<Grid item sm={4} xs={12}>
								<FormControl fullWidth={true}>
									<InputLabel
										shrink
										htmlFor={`educations[${index}].city`}
										classes={{ root: classes.inputLabel }}
										//className={errors?.educations && errors?.educations[`${index}`]?.city && classes.errorColor}
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.city) ||
											(!!highlights &&
												highlights.includes(`educations[${index}].city`))
										}
										required
									>
										{t('credentials:EDUCATION.CITY')}
									</InputLabel>
									<TextField
										id={`educations[${index}].city`}
										name={`educations[${index}].city`}
										className={
											lockFields ? classes.disabledInput : classes.normalInput
										}
										margin='dense'
										variant='outlined'
										defaultValue={`${item.city}`}
										inputRef={register({
											required: isRequired,
											maxLength: {
												value: 255,
												message: t('common:MESSAGES.CHARACTER_LIMIT'),
											},
											pattern: /^[A-Za-z0-9]/,
										})}
										helperText={
											(errors?.educations &&
												errors?.educations[`${index}`]?.city &&
												errors?.educations[`${index}`]?.city['type'] !==
													'maxLength') ||
											(!!highlights &&
												highlights.includes(`educations[${index}].city`))
												? t('credentials:VALIDATION_MESSAGE.CITY')
												: (errors?.educations &&
														errors?.educations[`${index}`]?.city &&
														errors?.educations[`${index}`]?.city[
															'type'
														] === 'maxLength') ||
												  (!!highlights &&
														highlights.includes(
															`educations[${index}].city`
														))
												? t('common:MESSAGES.CHARACTER_LIMIT')
												: null
										}
										onBlur={() => {
											isEmptyForm(index);
											updateHighlight();
										}}
										onChange={() => isEmptyForm(index)}
										autoComplete='off'
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.city) ||
											(!!highlights &&
												highlights.includes(`educations[${index}].city`))
										}
										InputProps={{
											classes: {
												root: classes.cssOutlinedInput,
												focused: classes.cssFocused,
												notchedOutline: classes.notchedOutline,
												error: classes.error,
											},
											readOnly: lockFields,
										}}
									/>
								</FormControl>
							</Grid>

							{/* Add state on country = US */}
							{watch(`educations[${index}].country`) === 'United States' ? (
								<Grid item sm={3} xs={12}>
									<FormControl fullWidth={true}>
										<InputLabel
											shrink
											htmlFor={`educations[${index}].state`}
											classes={{ root: classes.inputLabel }}
											required
											className={
												errors?.educations &&
												errors?.educations[`${index}`]?.state &&
												classes.errorColor
											}
											error={
												(errors?.educations &&
													errors?.educations[`${index}`]?.state) ||
												(!!highlights &&
													highlights.includes(
														`educations[${index}].state`
													))
											}
										>
											{t('credentials:EDUCATION.STATE')}
										</InputLabel>
										<ThemeProvider
											theme={
												(errors?.educations &&
													errors?.educations[`${index}`]?.state) ||
												(!!highlights &&
													highlights.includes(
														`educations[${index}].state`
													))
													? errorTheme
													: normalTheme
											}
										>
											<NativeSelect
												id={`educations[${index}].state`}
												name={`educations[${index}].state`}
												defaultValue={
													watch(`educations[${index}].country`) ===
														'United States' &&
													watch(`educations[${index}].state`) === 'IT'
														? ''
														: `${item.state}`
												}
												className={
													(!!item.isVerified &&
														(activePath === ACTIVE_PATHNAME_MD ||
															item.isGraduate)) ||
													credState === CRED_STATE.CLOSED
														? classes.disabledSelect
														: classes.normalInput
												}
												inputRef={register({
													required:
														watch(`educations[${index}].country`) ===
														'United States',
												})}
												onBlur={() => {
													isEmptyForm(index);
													updateHighlight();
												}}
												onChange={() => {
													isEmptyForm(index);
													updateHighlight();
												}}
												disabled={lockFields}
												input={<TextFieldComponent />}
											>
												<option key={'state'} value={null}></option>
												{statesForLicense.map((item) => (
													<option key={item.value} value={item.value}>
														{item.label}
													</option>
												))}
											</NativeSelect>
										</ThemeProvider>
										{errors?.educations &&
											errors?.educations[`${index}`]?.state && (
												<FormHelperText className={'Mui-error'}>
													{t('credentials:VALIDATION_MESSAGE.STATE')}
												</FormHelperText>
											)}
									</FormControl>
									{lockFields ? (
										<Input
											style={{ display: 'none' }}
											name={`educations[${index}].state`}
											value={`${item.state}`}
											inputRef={register()}
										/>
									) : null}
								</Grid>
							) : null}

							{/* Add zip on country = US */}
							{watch(`educations[${index}].country`) === 'United States' ? (
								<Grid item sm={3} xs={12}>
									<FormControl fullWidth={true}>
										<InputLabel
											shrink
											htmlFor={`educations[${index}].zipCode`}
											classes={{ root: classes.inputLabel }}
											className={
												errors?.educations &&
												errors?.educations[`${index}`]?.zipCode &&
												classes.errorColor
											}
											error={
												(errors?.educations &&
													errors?.educations[`${index}`]?.zipCode) ||
												(!!highlights &&
													highlights.includes(
														`educations[${index}].zipCode`
													))
											}
										>
											{t('credentials:EDUCATION.ZIP_CODE')}
										</InputLabel>
										<TextField
											id={`educations[${index}].zipCode`}
											name={`educations[${index}].zipCode`}
											className={
												(!!item.isVerified &&
													(activePath === ACTIVE_PATHNAME_MD ||
														item.isGraduate)) ||
												credState === CRED_STATE.CLOSED
													? classes.disabledInput
													: classes.normalInput
											}
											margin='dense'
											variant='outlined'
											defaultValue={
												watch(`educations[${index}].country`) ===
													'United States' &&
												watch(`educations[${index}].zipCode`) === '00000'
													? ''
													: `${item.zipCode}`
											}
											inputRef={register({
												maxLength: {
													value: 5,
													message: t('common:MESSAGES.CHARACTER_LIMIT'),
												},
												minLength: 5,
											})}
											helperText={
												(errors?.educations &&
													errors?.educations[`${index}`]?.zipCode) ||
												(!!highlights &&
													highlights.includes(
														`educations[${index}].zipCode`
													))
													? t('credentials:VALIDATION_MESSAGE.ZIP_CODE')
													: null
											}
											onBlur={() => {
												isEmptyForm(index);
												updateHighlight();
											}}
											onChange={() => isEmptyForm(index)}
											autoComplete='off'
											error={
												(errors?.educations &&
													errors?.educations[`${index}`]?.zipCode) ||
												(!!highlights &&
													highlights.includes(
														`educations[${index}].zipCode`
													))
											}
											InputProps={{
												classes: {
													root: classes.cssOutlinedInput,
													focused: classes.cssFocused,
													notchedOutline: classes.notchedOutline,
													error: classes.error,
												},
												readOnly:
													(!!item.isVerified &&
														(activePath === ACTIVE_PATHNAME_MD ||
															item.isGraduate)) ||
													credState === CRED_STATE.CLOSED,
											}}
										/>
									</FormControl>
								</Grid>
							) : null}
						</Grid>

						<Grid container>
							<Grid item sm={6} xs={12}>
								<FormControl fullWidth={true}>
									<InputLabel
										shrink
										htmlFor={`educations[${index}].educationDegree`}
										classes={{ root: classes.inputLabel }}
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.educationDegree) ||
											(!!highlights &&
												highlights.includes(
													`educations[${index}].educationDegree`
												))
										}
										required={
											watch(`educations[${index}].completed`) === 'true' ||
											watch(`educations[${index}].completed`) === true
										}
									>
										{t('credentials:EDUCATION.DEGREE')}
									</InputLabel>
									<ThemeProvider
										theme={
											(errors?.educations &&
												errors?.educations[`${index}`]?.educationDegree) ||
											(!!highlights &&
												highlights.includes(
													`educations[${index}].educationDegree`
												))
												? errorTheme
												: normalTheme
										}
									>
										<NativeSelect
											defaultValue={`${item.educationDegree}`}
											inputRef={register()}
											name={`educations[${index}].educationDegree`}
											className={
												lockFields
													? classes.disabledSelect
													: classes.normalInput
											}
											onBlur={() => {
												isEmptyForm(index);
												updateHighlight();
											}}
											onChange={() => {
												isEmptyForm(index);
												updateHighlight();
											}}
											disabled={lockFields}
											input={
												<TextFieldComponent
													style={{ border: `0px solid ${COLORS.RAVEN}` }}
													classes={{
														input: classes.setWidthTo100OnMobile,
													}}
												/>
											}
										>
											<option key={'degree'} value={null}></option>
											{itemDegrees.map((item) => (
												<option key={item.value} value={item.value}>
													{item.label}
												</option>
											))}
										</NativeSelect>
									</ThemeProvider>
								</FormControl>
								{lockFields ? (
									<Input
										style={{ display: 'none' }}
										name={`educations[${index}].educationDegree`}
										value={`${item.educationDegree}`}
										inputRef={register()}
									/>
								) : null}
							</Grid>
						</Grid>

						<Grid container spacing={4}>
							<Grid item sm={4} xs={12}>
								<FormControl fullWidth={true}>
									<InputLabel
										shrink
										htmlFor={`educations[${index}].dateFrom`}
										classes={{ root: classes.inputLabel }}
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.dateFrom) ||
											(!!highlights &&
												highlights.includes(
													`educations[${index}].dateFrom`
												))
										}
										required
									>
										{t('credentials:EDUCATION.FROM_DATE')}
									</InputLabel>
									<ThemeProvider theme={datePickerTheme}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<Controller
												control={control}
												rules={{
													validate: (date) => dateValidation(date),
												}}
												defaultValue={
													`${item.dateFrom}` === 'null'
														? null
														: convertToDateIgnoringTime(
																`${item.dateFrom}`
														  )
												}
												name={`educations[${index}].dateFrom`}
												render={({ onChange, onBlur, value, name }) => (
													<DatePickerComponent
														onBlur={() => {
															onBlur(isEmptyForm(index));
															updateHighlight();
														}}
														onChange={onChange}
														onClose={() => {
															updateHighlight();
														}}
														name={name}
														value={value}
														format={dateFormat}
														placeholder='MM/YYYY'
														clearable
														disabled={lockFields}
														variant='inline'
														autoOk='true'
														minDateMessage={
															<span
																className={`${classes.cssLabel} ${classes.marginLeft12}`}
															>
																{dateErrorMsg}
															</span>
														}
														maxDateMessage={
															<span
																className={`${classes.cssLabel} ${classes.marginLeft12}`}
															>
																{dateErrorMsg}
															</span>
														}
														invalidDateMessage={
															<span
																className={`${classes.cssLabel} ${classes.marginLeft12}`}
															>
																{dateErrorMsg}
															</span>
														}
														margin='dense'
														theme={datePickerTheme}
														error={
															(errors?.educations &&
																errors?.educations[`${index}`]
																	?.dateFrom) ||
															(!!highlights &&
																highlights.includes(
																	`educations[${index}].dateFrom`
																))
														}
														InputProps={{
															classes: {
																disabled: classes.disabledInput,
																root: classes.cssOutlinedInput,
															},
														}}
														KeyboardButtonProps={{
															'aria-label': t(
																'credentials:EDUCATION.FROM_DATE'
															),
														}}
														inputVariant='outlined'
													/>
												)}
											/>
										</MuiPickersUtilsProvider>
									</ThemeProvider>
								</FormControl>
							</Grid>
							<Grid item sm={4} xs={12}>
								<FormControl fullWidth={true}>
									<InputLabel
										shrink
										htmlFor={`educations[${index}].dateTo`}
										classes={{ root: classes.inputLabel }}
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.dateTo) ||
											(!!highlights &&
												highlights.includes(`educations[${index}].dateTo`))
										}
										required
									>
										{t('credentials:EDUCATION.TO_DATE')}
									</InputLabel>
									<ThemeProvider theme={datePickerTheme}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<Controller
												control={control}
												rules={{
													validate: (date) => dateValidation(date),
												}}
												defaultValue={
													`${item.dateTo}` === 'null'
														? null
														: convertToDateIgnoringTime(
																`${item.dateTo}`
														  )
												}
												name={`educations[${index}].dateTo`}
												render={({ onChange, onBlur, value, name }) => (
													<DatePickerComponent
														onBlur={() => {
															onBlur(isEmptyForm(index));
															updateHighlight();
														}}
														onChange={onChange}
														onClose={() => {
															updateHighlight();
														}}
														name={name}
														value={value}
														format={dateFormat}
														placeholder='MM/YYYY'
														theme={datePickerTheme}
														error={
															(errors?.educations &&
																errors?.educations[`${index}`]
																	?.dateTo) ||
															(!!highlights &&
																highlights.includes(
																	`educations[${index}].dateTo`
																))
														}
														clearable
														disabled={lockFields}
														variant='inline'
														autoOk='true'
														minDateMessage={
															<span
																className={`${classes.cssLabel} ${classes.marginLeft12}`}
															>
																{dateErrorMsg}
															</span>
														}
														maxDateMessage={
															<span
																className={`${classes.cssLabel} ${classes.marginLeft12}`}
															>
																{dateErrorMsg}
															</span>
														}
														invalidDateMessage={
															<span
																className={`${classes.cssLabel} ${classes.marginLeft12}`}
															>
																{dateErrorMsg}
															</span>
														}
														margin='dense'
														InputProps={{
															classes: {
																disabled: classes.disabledInput,
																root: classes.cssOutlinedInput,
															},
														}}
														KeyboardButtonProps={{
															'aria-label': t(
																'credentials:EDUCATION.FROM_DATE'
															),
														}}
														inputVariant='outlined'
													/>
												)}
											/>
										</MuiPickersUtilsProvider>
									</ThemeProvider>
								</FormControl>
							</Grid>
						</Grid>

						<Grid container spacing={4}>
							<Grid item sm={4} xs={12}>
								<FormControl fullWidth={true}>
									<InputLabel
										shrink
										htmlFor={`educations[${index}].completed`}
										classes={{ root: classes.inputRadio }}
										error={
											(errors?.educations &&
												errors?.educations[`${index}`]?.completed) ||
											(!!highlights &&
												highlights.includes(
													`educations[${index}].completed`
												))
										}
										required
									>
										{t('credentials:EDUCATION.COMPLETED')}
									</InputLabel>
									<div style={{ marginTop: '10px' }}>
										<RadioGroup
											row
											aria-label='completed'
											name={`educations[${index}].completed`}
											defaultValue={`${item.completed}`}
											error={
												(errors?.educations &&
													errors?.educations[`${index}`]?.completed) ||
												(!!highlights &&
													highlights.includes(
														`educations[${index}].completed`
													))
											}
											onChange={() => {
												isEmptyForm(index);
												updateHighlight();
											}}
										>
											<FormControlLabel
												value='true'
												control={
													<Radio
														color='primary'
														style={{ color: COLORS.RAVEN }}
														inputRef={register()}
														disableRipple
														size='small'
														disabled={
															!!item.id &&
															(credState === CRED_STATE.CLOSED ||
																credState ===
																	CRED_STATE.PARTIALLY_OPEN ||
																(activePath ===
																	ACTIVE_PATHNAME_AP &&
																	!!item.isVerified &&
																	credState === CRED_STATE.OPEN))
														}
														className={
															!!item.id &&
															(credState === CRED_STATE.CLOSED ||
																credState ===
																	CRED_STATE.PARTIALLY_OPEN ||
																(activePath ===
																	ACTIVE_PATHNAME_AP &&
																	!!item.isVerified &&
																	credState === CRED_STATE.OPEN))
																? classes.disabledInput
																: classes.normalInput
														}
													/>
												}
												label={<span>Yes</span>}
											/>
											<FormControlLabel
												value='false'
												control={
													<Radio
														color='primary'
														style={{ color: COLORS.RAVEN }}
														inputRef={register()}
														disableRipple
														size='small'
														disabled={
															!!item.id &&
															(credState === CRED_STATE.CLOSED ||
																credState ===
																	CRED_STATE.PARTIALLY_OPEN ||
																(activePath ===
																	ACTIVE_PATHNAME_AP &&
																	item.isVerified &&
																	credState === CRED_STATE.OPEN))
														}
														className={
															!!item.id &&
															(credState === CRED_STATE.CLOSED ||
																credState ===
																	CRED_STATE.PARTIALLY_OPEN ||
																(activePath ===
																	ACTIVE_PATHNAME_AP &&
																	item.isVerified &&
																	credState === CRED_STATE.OPEN))
																? classes.disabledInput
																: classes.normalInput
														}
													/>
												}
												label={<span>No</span>}
											/>
										</RadioGroup>
									</div>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{/* {ecfmgComponent(index, item.country, activePath, item.isVerified)} */}

				<RequiredQuestionDialog
					open={showDeleteModal}
					title={t(`credentials:EDUCATION.CONFIRM_DELETE_TITLE`)}
					text={t(`credentials:EDUCATION.CONFIRM_DELETE_MESSAGE`)}
					buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
					buttonIDs='Yes;No'
					disableBackgroundClick={false}
					onClose={(btnPressed) => {
						deleteEntry(btnPressed, index);
					}}
				/>
			</Grid>
		);
	};

	const getDegrees = () => {
		let under = nursingUnderGrad;
		let grad = nursingPostGrad;
		if (segment === SEGMENT_PA) {
			under = paUnderGrad;
			grad = paPostGrad;
		} else if (segment === SEGMENT_ALLIED) {
			under = psychUnderGrad;
			grad = psychPostGrad;
		} else if (segment === SEGMENT_SFA) {
			under = sfaUnderGrad;
			grad = sfaPostGrad;
		} else if (segment === SEGMENT_PERFUSIONIST) {
			under = perfusionistUnderGrad;
			grad = perfusionistPostGrad;
		}
		return { under: under, grad: grad };
	};

	const renderEducationList = () => {
		if (activePath !== ACTIVE_PATHNAME_AP) {
			return (
				<>
					{fields.map((item, index) => renderMedSchool(item, index, mdDegrees, true))}
					{credState === CRED_STATE.CLOSED ? null : (
						<Grid item xs={12} md={12}>
							{isLoaded ? (
								<div
									className={'credAppAddMoreButton'}
									onClick={() =>
										append({
											id: null,
											medicalSchoolId: null,
											medicalSchool: '',
											streetAddress: '',
											city: '',
											state: '',
											zipCode: '',
											country: '',
											dateFrom: null,
											dateTo: null,
											completed: null,
											educationDegree: '',
											educationLevel: 'Medical School',
											//             fifthPathway: false,
											isVerified: false,
										})
									}
								>
									{t(`credentials:EDUCATION.ADD_MORE_MD`)}
								</div>
							) : null}
						</Grid>
					)}
					{
						// fields.map( (item,index) => ecfmgComponent(index, item.country, activePath, item.isVerified,fields))

						ecfmgComponent(activePath, fields)
					}
				</>
			);
		} else {
			const { under, grad } = getDegrees();
			return (
				<>
					{fields.map((item, index) => renderMedSchool(item, index, grad, true))}
					{credState === CRED_STATE.CLOSED ? null : (
						<Grid item xs={12} md={12}>
							{isLoaded ? (
								<div
									className={'credAppAddMoreButton'}
									onClick={() =>
										append({
											id: null,
											medicalSchool: '',
											medicalSchoolId: null,
											streetAddress: '',
											city: '',
											state: '',
											zipCode: '',
											country: '',
											dateFrom: null,
											dateTo: null,
											completed: null,
											educationDegree: '',
											educationLevel: 'Professional',
											isGraduate: true,
											isVerified: false,
										})
									}
								>
									{t(`credentials:EDUCATION.ADD_MORE_AP_ADV`)}
								</div>
							) : null}
						</Grid>
					)}
					<Grid item xs={12}>
						<h3 className={classes.header}>
							{t('credentials:EDUCATION.UNDERGRAD_TITLE')}
						</h3>
					</Grid>
					{fields.map((item, index) => renderMedSchool(item, index, under, false))}
					{credState === CRED_STATE.CLOSED ? null : (
						<Grid item xs={12} md={12}>
							{isLoaded ? (
								<div
									className={'credAppAddMoreButton'}
									onClick={() =>
										append({
											id: null,
											medicalSchool: '',
											medicalSchoolId: null,
											streetAddress: '',
											city: '',
											state: '',
											zipCode: '',
											country: '',
											dateFrom: null,
											dateTo: null,
											completed: null,
											educationDegree: '',
											educationLevel: 'Undergraduate',
											isGraduate: false,
											isVerified: false,
										})
									}
								>
									{t(`credentials:EDUCATION.ADD_MORE_AP_UNDER`)}
								</div>
							) : null}
						</Grid>
					)}
				</>
			);
		}
	};

	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}

		return (
			<form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
				<Input
					autoComplete='off'
					style={{ display: 'none' }}
					name='certificationId'
					value={fields.certificationId}
					defaultValue={fields.certificationId}
					inputRef={register()}
				/>
				{renderEducationList()}
			</form>
		);
	};

	return (
		<div className='credAppContent'>
			<Container spacing={4}>
				<Grid container>
					<Grid item xs={12}>
						<h3>
							{activePath !== ACTIVE_PATHNAME_AP
								? t('credentials:EDUCATION.MD_TITLE')
								: t('credentials:EDUCATION.AP_TITLE')}
						</h3>
						{credState === CRED_STATE.CLOSED ||
						credState === CRED_STATE.PARTIALLY_OPEN ? (
							<Grid item xs={12} md={10} className={classes.messageGrid}>
								<Alert
									severity='error'
									className={classes.alertMessage}
									icon={false}
								>
									{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
								</Alert>
							</Grid>
						) : null}
						{activePath === ACTIVE_PATHNAME_AP ? (
							<p className='credInfoMsg'>
								Please provide all institutions attended including your
								Undergraduate Education.
							</p>
						) : (
							<p className='credInfoMsg'>{t(`credentials:EDUCATION.INFO_MESSAGE`)}</p>
						)}
					</Grid>

					<Grid item xs={12}>
						{renderForms()}
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default WithContexts(withTranslation()(withStyles(styles)(EducationForm)));
