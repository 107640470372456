import React, { Component } from 'react';
import { withStyles, Tab, Tabs, Container, Grid, Button } from '@material-ui/core';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TravelPreferences from '../../components/ContentComponents/Preferences/TravelPreferences';
import AssignmentPreferences from '../../components/ContentComponents/Preferences/AssignmentPreferences';
import CommunicationPreferences from '../../components/ContentComponents/Preferences/CommunicationPreferences';
import PaymentElectionPreferences from '../../components/ContentComponents/Preferences/PaymentElectionPreferences';
import FeatureFlag from '../../components/GlobalComponents/FeatureFlagComponent/FeatureFlag';
import { COLORS } from '../../utils/Application_Constants';
import WithContexts from '../../contexts/withContexts';
import { withRouter } from 'react-router-dom';
import ErrorDialog from '../../components/GlobalComponents/Dialog/ErrorDialog';
import RequiredQuestionDialog from '../../components/GlobalComponents/Dialog/RequiredQuestionDialog';
import { getClinicianPaymentElectionPreferences } from '../../services/ProfileServices/profileService';

const styles = (theme) => ({
	root: {
		backgroundColor: COLORS.BLACK_HAZE,
		// marginTop: '80px',
		paddingBottom: '80px',
		minHeight: '800px',
	},
	indicator: {
		backgroundColor: COLORS.DENIM,
		opacity: '1',
	},
	tabsRoot: {
		color: COLORS.SLATE_GRAY,
		[theme.breakpoints.up('md')]: {
			padding: '26px 0',
		},
	},
	progressButtonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			paddingLeft: '15px',
		},
	},
	progressButton: {
		marginTop: '25px',
		marginRight: '15px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	saveButtonWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
	saveButton: {
		marginLeft: '42px',
		marginTop: '25px',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '20px 32px',
		},
	},
	saveMessage: {
		marginTop: '25px',
	},
	errorMessage: {
		color: 'red',
	},
	disabeldInput: {
		opacity: 0.6,
	},
	travelTabStyle: {
		[theme.breakpoints.up('md')]: {
			minWidth: '120px',
		},
	},
	payTabStyle: {
		[theme.breakpoints.up('md')]: {
			minWidth: '120px',
		},
	},
});

class Profile extends Component {
	constructor(props) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName(props.t('common:PAGES.PROFILE'));
		this.state = {
			currentlyActiveTab: false,
			handleSubmit: null,
			childTab: null,
			flags: {
				missingData: false,
				isCharacterLimit: false,
				dataSaved: false,
				isDirty: false,
				showSaveChanges: false,
				isPendingSave: false,
				targetTab: 0,
				isLoading: false,
				validationError: false,
				navDisabled: false,
				apiError: '',
				errorMessage: '',
			},
			url: {
				location: 'preference',
				id: null,
			},
		};
	}

	setPreferencePageError = (e) => {
		const { flags } = { ...this.state };
		if (e.hasOwnProperty('title') && e.hasOwnProperty('message')) {
			flags.apiError = e;
		} else {
			flags.errorMessage = 'Error loading page';
		}
		flags.isLoading = false;
		flags.isPendingSave = false;
		this.setState({ flags: flags });
	};

	closeError = () => {
		const state = { ...this.state };
		state.flags.apiError = null;
		this.setState({ ...state });
	};

	async componentDidMount() {
		const { externalId } = this.props.UserContext;
		const payPreferences = await getClinicianPaymentElectionPreferences(externalId).catch(
			(e) => {
				this.getUrlParams();
			}
		);
		if (payPreferences.w9Status !== 'Complete' || payPreferences.ddStatus !== 'Complete') {
			this.setState({ url: { status: 'paymentElections' } });
			this.moveToTab(3);
		} else {
			await this.getUrlParams();
		}
		await this.setTabState();
	}
	async componentDidUpdate(prevProps) {
		const { match } = this.props;

		if (prevProps.match.params.status !== match.params.status) {
			await this.getUrlParams();
			this.setState({
				currentlyActiveTab: this.getLocationIndexByStatus(match.params.status),
			});
		}
	}

	getUrlParams() {
		const { match } = this.props;
		this.setState({ url: { ...match.params } });
	}

	getLocationByIndex(currentIndex) {
		switch (currentIndex) {
			case 0:
				return 'preference';
			case 1:
				return 'communication';
			case 2:
				return 'travel';
			case 3:
				return 'paymentElections';

			default:
				return 'preference';
		}
	}

	setTabState = async () => {
		const { url } = { ...this.state };
		await this.setState({ currentlyActiveTab: this.getLocationIndexByStatus(url.status) });
	};

	getLocationIndexByStatus(status) {
		switch (status) {
			case 'preference':
				return 0;
			case 'communication':
				return 1;
			case 'travel':
				return 2;
			case 'paymentElections':
				return 3;

			default:
				return 0;
		}
	}

	updateFlags = (flags) => {
		this.setState({ flags: flags });
	};

	tabChanged = (event, newValue) => {
		const state = { ...this.state };
		if (state.flags.isDirty) {
			state.flags.showSaveChanges = true;
			state.flags.targetTab = newValue;
			event.preventDefault();
			this.setState({ ...state });
		} else {
			const { history } = this.props;
			history.push(`/profile/${this.getLocationByIndex(newValue)}`);
			this.setState({ currentlyActiveTab: newValue });
		}
	};

	setSaveHandler = (child, handler) => {
		const state = { ...this.state };
		state.childTab = child;
		state.handleSubmit = handler;
		// Reset save flags
		state.flags.missingData = false;
		state.flags.isCharacterLimit = false;
		state.flags.dataSaved = false;
		state.flags.isDirty = false;
		state.flags.showSaveChanges = false;
		state.flags.isPendingSave = false;
		this.setState({ ...state });
	};

	setCurrentlyActiveTabHandler = (tabIndexToRender) => {
		this.setState({ currentlyActiveTab: tabIndexToRender });
		window.scrollTo(0, 0);
	};

	moveToTab = (idx) => {
		const state = { ...this.state };
		if (state.flags.isDirty) {
			state.flags.showSaveChanges = true;
			state.flags.targetTab = idx;
			this.setState({ ...state });
		} else {
			this.setCurrentlyActiveTabHandler(idx);
			const { history } = this.props;
			history.push(`/profile/${this.getLocationByIndex(idx)}`);
		}
	};

	onTabClicked = (event, idx) => {
		const state = { ...this.state };
		if (state.flags.isDirty) {
			state.flags.showSaveChanges = true;
			state.flags.targetTab = idx;
			event.preventDefault();
			this.setState({ ...state });
		}
	};

	closeDialog = (btnPressed) => {
		const state = { ...this.state };
		state.flags.showSaveChanges = false;
		if (btnPressed === 'Leave') {
			state.flags.isDirty = false; // allow to move on
		}
		this.setState({ ...state });
		if (btnPressed === 'Leave') {
			this.moveToTab(state.flags.targetTab);
		}
	};

	renderSaveMessage = () => {
		const state = { ...this.state };
		const { t, classes } = this.props;
		if (state.flags.missingData) {
			return (
				<span className={classes.errorMessage}>{t('profile:PROFILE.MISSING_INPUT')}</span>
			);
		}
		if (state.flags.isCharacterLimit) {
			return (
				<span className={classes.errorMessage}>{t('common:MESSAGES.CHARACTER_LIMIT')}</span>
			);
		}
		if (state.flags.dataSaved) {
			return <span>{t('common:MESSAGES.DATA_SAVED')}</span>;
		}
		return null;
	};

	tabRender = (currentIndex) => {
		const { externalId } = this.props.UserContext;
		const state = { ...this.state };
		switch (currentIndex) {
			case 0:
				return (
					<AssignmentPreferences
						tabHandler={this.setCurrentlyActiveTabHandler}
						flags={state.flags}
						updateFlags={this.updateFlags}
						setSaveHandler={this.setSaveHandler}
						externalId={externalId}
						setPreferencePageError={this.setPreferencePageError}
					/>
				);
			case 1:
				return (
					<CommunicationPreferences
						tabHandler={this.setCurrentlyActiveTabHandler}
						flags={state.flags}
						updateFlags={this.updateFlags}
						setSaveHandler={this.setSaveHandler}
						externalId={externalId}
						setPreferencePageError={this.setPreferencePageError}
					/>
				);
			case 2:
				return (
					<TravelPreferences
						tabHandler={this.setCurrentlyActiveTabHandler}
						flags={state.flags}
						updateFlags={this.updateFlags}
						setSaveHandler={this.setSaveHandler}
						externalId={externalId}
						setPreferencePageError={this.setPreferencePageError}
					/>
				);
			case 3:
				return (
					<PaymentElectionPreferences
						tabHandler={this.setCurrentlyActiveTabHandler}
						flags={state.flags}
						updateFlags={this.updateFlags}
						setSaveHandler={this.setSaveHandler}
						externalId={externalId}
						setPreferencePageError={this.setPreferencePageError}
					/>
				);
			default:
				return null;
		}
	};

	onCallSubmit = () => {
		if (this.state.handleSubmit !== null && this.state.childTab !== null) {
			this.state.handleSubmit.call(this.state.childTab);
		}
	};

	renderTabButtons() {
		const state = { ...this.state };
		const { classes, t } = this.props;
		if (state.currentlyActiveTab === 0) {
			return (
				<Button
					className={classes.progressButton}
					variant='contained'
					color='primary'
					onClick={() => {
						this.moveToTab(1);
					}}
					endIcon={<ChevronRight />}
				>
					{t('tabs:PROFILE.COMMUNICATION')}
				</Button>
			);
		} else if (state.currentlyActiveTab === 1) {
			return (
				<>
					<Button
						className={classes.progressButton}
						variant='outlined'
						color='primary'
						onClick={() => {
							this.moveToTab(0);
						}}
						startIcon={<ChevronLeft />}
					>
						{t('tabs:PROFILE.ASSIGNMENT')}
					</Button>
					<Button
						className={classes.progressButton}
						variant='contained'
						color='primary'
						onClick={() => {
							this.moveToTab(2);
						}}
						endIcon={<ChevronRight />}
					>
						{t('tabs:PROFILE.TRAVEL')}
					</Button>
				</>
			);
		} else if (state.currentlyActiveTab === 2) {
			return (
				<>
					<Button
						className={classes.progressButton}
						variant='outlined'
						color='primary'
						onClick={() => {
							this.moveToTab(1);
						}}
						startIcon={<ChevronLeft />}
					>
						{t('tabs:PROFILE.COMMUNICATION')}
					</Button>
					<FeatureFlag name='W9-DD' isFlagEnabled='true'>
						<Button
							className={classes.progressButton}
							variant='contained'
							color='primary'
							onClick={() => {
								this.moveToTab(3);
							}}
							startIcon={<ChevronRight />}
						>
							{t('tabs:PROFILE.PAYMENT_ELECTIONS')}
						</Button>
					</FeatureFlag>
				</>
			);
		} else if (state.currentlyActiveTab === 3) {
			return (
				<Button
					className={classes.progressButton}
					variant='outlined'
					color='primary'
					onClick={() => {
						this.moveToTab(2);
					}}
					startIcon={<ChevronLeft />}
				>
					{t('tabs:PROFILE.TRAVEL')}
				</Button>
			);
		}
	}

	render() {
		const { classes, t } = this.props;
		const { currentlyActiveTab, flags, handleSubmit } = this.state;
		return (
			<div className={classes.root}>
				<ErrorDialog
					open={!!flags.apiError}
					onClose={this.closeError}
					title={!flags.apiError ? null : flags.apiError.title}
					description={!flags.apiError ? null : flags.apiError.message}
					errorId={!flags.apiError ? null : flags.apiError.errorId}
				/>
				<Container maxWidth='md'>
					<RequiredQuestionDialog
						open={flags.showSaveChanges}
						title={t('common:MESSAGES.SAVE_TITLE')}
						text={t('common:MESSAGES.SAVE_MESSAGE')}
						buttonTexts={t('common:MESSAGES.SAVE_BUTTONS')}
						buttonIDs='Stay;Leave'
						disableBackgroundClick={false}
						onClose={this.closeDialog}
					/>
					<FeatureFlag name='W9-DD' isFlagEnabled='true'>
						<Tabs
							classes={{ indicator: classes.indicator, root: classes.tabsRoot }}
							value={currentlyActiveTab}
							onChange={this.tabChanged}
						>
							<Tab
								label={t('tabs:PROFILE.ASSIGNMENT')}
								onClick={(event) => {
									this.onTabClicked(event, 0);
								}}
								id='tab-1'
							/>
							<Tab
								label={t('tabs:PROFILE.COMMUNICATION')}
								onClick={(event) => {
									this.onTabClicked(event, 1);
								}}
								id='tab-2'
							/>
							<Tab
								label={t('tabs:PROFILE.TRAVEL')}
								onClick={(event) => {
									this.onTabClicked(event, 2);
								}}
								id='tab-3'
							/>
							<Tab
								label={t('tabs:PROFILE.PAYMENT_ELECTIONS')}
								className={classes.payTabStyle}
								onClick={(event) => {
									this.onTabClicked(event, 3);
								}}
								id='tab-4'
							/>
						</Tabs>
					</FeatureFlag>
					<FeatureFlag name='W9-DD' isFlagEnabled='false'>
						<Tabs
							classes={{ indicator: classes.indicator, root: classes.tabsRoot }}
							value={currentlyActiveTab}
							onChange={this.tabChanged}
						>
							<Tab
								label={t('tabs:PROFILE.ASSIGNMENT')}
								onClick={(event) => {
									this.onTabClicked(event, 0);
								}}
								id='tab-1'
							/>
							<Tab
								label={t('tabs:PROFILE.COMMUNICATION')}
								onClick={(event) => {
									this.onTabClicked(event, 1);
								}}
								id='tab-2'
							/>
							<Tab
								label={t('tabs:PROFILE.TRAVEL')}
								className={classes.travelTabStyle}
								onClick={(event) => {
									this.onTabClicked(event, 2);
								}}
								id='tab-3'
							/>
						</Tabs>
					</FeatureFlag>
					{this.tabRender(currentlyActiveTab)}

					<Grid container>
						<Grid item xs={8} md={2} classes={{ root: classes.saveButtonWrapper }}>
							<Button
								className={classes.saveButton}
								classes={{ disabled: classes.disabeldInput }}
								variant='contained'
								color='primary'
								disabled={
									!handleSubmit ||
									flags.isPendingSave ||
									flags.missingData ||
									flags.isCharacterLimit
								}
								onClick={this.onCallSubmit}
							>
								{t('common:BUTTONS.SAVE')}
							</Button>
						</Grid>

						<Grid item xs={4} md={2} classes={{ root: classes.saveMessage }}>
							{this.renderSaveMessage()}
						</Grid>

						<Grid item xs={12} md={8} classes={{ root: classes.progressButtonWrapper }}>
							{this.renderTabButtons()}
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
}

Profile.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
	}).isRequired,
	t: PropTypes.func.isRequired,
};

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(Profile))));
