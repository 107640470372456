import React, { useContext } from 'react';
import ClientAppRouter from '../Router/ClientAppRouter';
import { CircularProgress, withStyles } from '@material-ui/core';
import { ClientAuthContext } from '../Contexts/ClientAuthContext';
import Banner from '../../components/HeaderComponents/Banner/Banner';
import ClientNavbar from './ClientNavbar';
import Footer from '../../Shared/Components/Footer';
import { COLORS } from '../../utils/Application_Constants';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '800px',
	},
	pageRoot: {
		background: COLORS.LT_MIDNIGHTBG1,
		minHeight: 'calc(100vh - 210px)',
		[theme.breakpoints.up('md')]: {
			minHeight: 'calc(100vh - 230px)',
		},
	},
	isLoadingRoot: {
		height: '100vh',
	},
	progressRoot: {
		margin: 'auto',
	},
});

const ClientLayout = (props) => {
	const { classes } = props;
	const { isLoadingAuth, isAuthenticated } = useContext(ClientAuthContext);
	return (
		<div className={`${classes.root} ${isLoadingAuth ? classes.isLoadingRoot : null}`}>
			{isLoadingAuth ? (
				<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
			) : (
				<>
					{isAuthenticated && (
						<>
							<ClientNavbar />
							<ClientAuthContext.Consumer>
								{(props) => <Banner pageName='' {...props} />}
							</ClientAuthContext.Consumer>
						</>
					)}
					<div className={classes.pageRoot}>
						<ClientAppRouter />
					</div>
					{isAuthenticated && <Footer />}
				</>
			)}
		</div>
	);
};

export default withStyles(styles)(ClientLayout);
