import React from 'react';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';

const TimeoutWarningModal = ({
	showModal,
	togglePopup,
	handleRemainLogin,
	handleLogout,
	formattedMinutes,
	classes,
	remainingTime,
}) => {
	// var temp = localStorage.getItem('LocalStorage_formatted');
	return (
		<Dialog
			open={showModal}
			scroll='body'
			maxWidth={'sm'}
			fullWidth
			onClose={() => togglePopup(false)}
		>
			<DialogTitle>Stay Logged in?</DialogTitle>
			<DialogContent>
				{`You've been inactive for 10 Minutes and will be logged out in ${formattedMinutes} minutes.`}
			</DialogContent>
			<DialogActions style={{ justifyContent: 'center' }}>
				<Button
					className={classes.progressButton}
					variant='outlined'
					color='primary'
					size='small'
					onClick={handleRemainLogin}
				>
					Stay Logged In
				</Button>
				<Button
					className={classes.progressButton}
					variant='outlined'
					color='primary'
					size='small'
					onClick={handleLogout}
				>
					Log Out
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default TimeoutWarningModal;
