import React from 'react';
import AttestationQuestionsForm from './AttestationQuestionsForm';

function DisclosureQuestionsForm({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	setCredAppError,
	flags,
	updateFlags,
	activePath,
	credState,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	const myQuestions = [
		'Has used drugs illegally',
		'Has significant physical or mental condition',
		'Unable to safely perform the essential functions',
		'Has criminal charges',
		'Has been arrested for child related sexual offense',
		'Has been convicted or plead out of a criminal offense',
		'Has significant issue affecting their ability to perform',
		'Has been reported to NPDB or HIPDB',
	];

	return (
		<AttestationQuestionsForm
			myQuestions={myQuestions}
			t={t}
			setSaveHandler={setSaveHandler}
			blockedBackNavigation={blockedBackNavigation}
			UserContext={UserContext}
			activePath={activePath}
			credState={credState}
			flags={flags}
			updateFlags={updateFlags}
			constantType='DISCLOSURE_QUESTIONS'
			sectionCompletion={sectionCompletion}
			updateSectionCompletion={updateSectionCompletion}
			updateHighlightFieldsState={updateHighlightFieldsState}
			shouldHighlightFields={shouldHighlightFields}
			setCredAppError={setCredAppError}
		/>
	);
}

export default DisclosureQuestionsForm;
