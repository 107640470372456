/**
 * @file This is the route configuration file for all the protected routes
 * in the application.
 */
import Timesheets from '../../Shared/Pages/Timesheets';
import TimesheetReview from '../../Shared/Components/TimesheetReview';
import TimesheetConfirmation from '../../Shared/Components/ConfirmationDialog';
const clientRoutes = [
	{
		path: '/timesheets',
		component: Timesheets,
	},
	{
		path: '/clinician/:clinicianId?/timesheet/:id?/review/:locationId?',
		component: TimesheetReview,
	},
	{
		path: '/timesheet/confirmation/:mode?',
		component: TimesheetConfirmation,
	},
];

export default clientRoutes;
