import React, { Component } from 'react';
import { withStyles, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { COLORS } from '../../../utils/Application_Constants';
import PresentsCard from '../PresentsCard/PresentsCard';

const styles = (theme) => ({
	presentsWrapperUserLevel: {
		paddingTop: '0.5rem',
	},
	widgetHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '18px',
		paddingLeft: '20px',
		textAlign: 'left',
	},
	buttonFont: {
		color: COLORS.TRUE_BLUE,
		textTransform: 'inherit',
		fontWeight: '700',
		fontSize: '16px',
	},
	paperRoot: {
		[theme.breakpoints.down('sm')]: {
			marginTop: '20px',
		},
		boxShadow: '0px 3px 6px #00000008',
	},
	presentsHeading: {
		width: '90%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '-30px',
	},
	presentsHeader: {
		color: COLORS.LT_STEEL,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'left',
	},
});

class PresentsWidgetComponent extends Component {
	renderPresentsCards = () => {
		const { presents } = this.props;
		if (this.presentsVaildatior()) {
			return <PresentsCard isEmpty present={[]} />;
		}
		return presents.map((item, index) => {
			if (index >= 3) {
				return null;
			}
			return <PresentsCard present={item} key={`g-${index + 1}`} />;
		});
	};

	/**
	 * Presents method checks if should  render an empty state.
	 * @returns {Boolean}
	 */
	presentsVaildatior = () => {
		const presentsListValid = [];
		const { presents } = this.props;
		if (!presents || presents.length === 0) {
			return true;
		}
		presents.forEach((item, index) => {
			if (index >= 3) {
				return;
			}
			if (!item.accountName || !item.presentDate) {
				presentsListValid.push(false);
			} else {
				presentsListValid.push(true);
			}
		});
		return presentsListValid.every((cv) => cv === false);
	};

	/**
	 * Retrieve the correct wrapper class dependent on if the presents wrapper
	 * is first.
	 */
	getPaperRootClass = () => {
		const { isFirst, classes } = this.props;
		if (isFirst) {
			return classes.isFirstPresentsWrapper;
		}
		return classes.presentsWrapper;
	};

	render() {
		const { classes, t } = this.props;
		return (
			<>
				<span className='tileHeader'>{t(`dashboard:HEADERS.PRESENTS`)}</span>
				<Paper className={classes.paper}>
					{this.renderPresentsCards()}
					<div align='center'>
						<Link
							to='/assignments/presents'
							className='textLink cr-p-sm'
							style={{ display: 'block' }}
						>
							{t('common:BUTTONS.VIEW_ALL')}
						</Link>
					</div>
				</Paper>
			</>
		);
	}
}

export default withRouter(withTranslation()(withStyles(styles)(PresentsWidgetComponent)));
