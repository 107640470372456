import React, { useState } from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import CancelDialog from '../Dialog/CancelDialog';

const styles = (theme) => ({
	header: {
		fontWeight: '700',
		margin: 0,
	},
	overline1: {
		fontWeight: '600',
	},
});

const ExpenseEntryHeader = (props) => {
	const { classes, title, backLink, expensesPath } = props;
	const history = useHistory();
	const {
		formState: { isDirty },
	} = useFormContext();
	const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

	const handleCancel = () => {
		if (isDirty) {
			return setCancelDialogOpen(true);
		}
		history.push(expensesPath);
	};

	return (
		<>
			<Grid item xs={12}>
				<Button
					variant='text'
					color='primary'
					startIcon={<ChevronLeft />}
					onClick={handleCancel}
				>
					<Typography variant='overline' className={classes.overline1}>
						{backLink}
					</Typography>
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Typography variant='h3' component='h3' className={classes.header}>
					{title}
				</Typography>
			</Grid>
			<CancelDialog
				open={cancelDialogOpen}
				setOpen={setCancelDialogOpen}
				nextPath={expensesPath}
			/>
		</>
	);
};

export default withStyles(styles)(ExpenseEntryHeader);
