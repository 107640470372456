import React from 'react';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import MalpracticeCarrierForm from './MalpracticeCarrierForm';
import { withStyles } from '@material-ui/core';
import { reactPlugin } from '../../../../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBoundaryPage from '../../../../ContentComponents/Credentials/PageComponenets/ErrorBoundaryPage';
const styles = (theme) => ({
	pagePadding: {
		paddingBottom: '40px',
	},
});

function MalpracticeCarriers({
	t,
	classes,
	setSaveHandler,
	blockedBackNavigation,
	setCredAppError,
	UserContext,
	flags,
	updateFlags,
	activePath,
	credState,
	type = 'Malpractice Carrier',
	constantType = 'MALPRACTICE_CARRIERS',
	apiType = 'malpracticecarriers',
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	return (
		<AppInsightsErrorBoundary
			onError={() => (
				<ErrorBoundaryPage errorCode='500' flags={flags} updateFlags={updateFlags} />
			)}
			appInsights={reactPlugin}
		>
			<div className={classes.pagePadding}>
				<MalpracticeCarrierForm
					t={t}
					setSaveHandler={setSaveHandler}
					blockedBackNavigation={blockedBackNavigation}
					UserContext={UserContext}
					flags={flags}
					updateFlags={updateFlags}
					type={type}
					constantType={constantType}
					apiType={apiType}
					activePath={activePath}
					credState={credState}
					sectionCompletion={sectionCompletion}
					updateSectionCompletion={updateSectionCompletion}
					updateHighlightFieldsState={updateHighlightFieldsState}
					shouldHighlightFields={shouldHighlightFields}
					setCredAppError={setCredAppError}
				/>
			</div>
		</AppInsightsErrorBoundary>
	);
}

export default WithContexts(withTranslation()(withStyles(styles)(MalpracticeCarriers)));
