import axios from 'axios';
import { environment } from '../../utils/helpers';

const isAdminApp = process.env.REACT_APP_TYPE === 'Admin';
const isClientApp = process.env.REACT_APP_TYPE === 'Client';
const subscriptionKey = process.env.REACT_APP_WC_API_SUBSCRIPTION_KEY;
const { oktaAuthConfig } = require('../../Admin/Configurations/OktaConfig.js');
const OktaAuth = require('@okta/okta-auth-js').OktaAuth;

const INVALID_TOKEN = 'Invalid CpToken';

const axiosWCApiInstance = axios.create({
	baseURL: process.env.REACT_APP_WC_API_BASE_URI,
});

axiosWCApiInstance.interceptors.request.use(
	async (config) => {
		const env = environment();
		if (isClientApp) {
			const CpToken = localStorage.getItem('token') ? localStorage.getItem('token') : null;
			config.headers = {
				CpToken,
				'LT-Roles': 'ClientApprover',
				'Ocp-Apim-Subscription-Key': subscriptionKey,
			};
		} else if (isAdminApp) {
			const authClient = new OktaAuth(oktaAuthConfig);
			const idToken = authClient?.getIdToken();
			const Authorization = idToken ? `Bearer ${idToken}` : null;
			config.headers = {
				Authorization,
				'LT-Roles': 'TimesheetAdmin',
				'Ocp-Apim-Subscription-Key': subscriptionKey,
			};
			if (env === 'LOCAL') {
				config.headers['LT-Roles'] = 'TimesheetAdmin,ExpenseSystemAdmin';
			}
		}
		if (env === 'LOCAL') {
			config.headers.email = 'local@fake.com';
		}
		return config;
	},
	(error) => Promise.reject(error)
);

axiosWCApiInstance.interceptors.response.use(
	async (response) => response,
	(error) => {
		if (error && error.response && error.response.data) {
			if (error.response.data.error === INVALID_TOKEN) {
				window.location.reload();
				return new Promise(() => {});
			}

			return Promise.reject(error.response.data);
		}
		return Promise.reject(error);
	}
);

export { axiosWCApiInstance };
