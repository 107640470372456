import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Container, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { COLORS } from '../../utils/Application_Constants';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import useQuery from '../../utils/useQuery';
import { PaginationItem } from '@material-ui/lab';
import { config } from '../Components/Expense/ExpenseReviewConfig';
import ExpenseFiltersForm from '../Components/Expense/ExpenseFiltersForm';
import { getExpensesWithFilter, getExpenseCategories, getDivisions, getPreCodedWithFilter } from '../Services/ExpenseService';
import ExpenseTable from '../Components/Expense/ExpenseTable';
import Alert from '../Components/Alert';
import { formatErrorMessage } from '../Utils/ErrorMessage';
import getCriteriaFromLocalStorage from '../Components/Expense/GetCriteriaFromLocalStorage';
import { method } from 'lodash';

const styles = (theme) => ({
    PaperRoot: {
        padding: '1.5rem 2rem',
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
        marginTop: '2em',
    },
    ContainerRoot: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        paddingTop: '2.5rem',
        paddingBottom: '5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
    },
    header: {
        fontWeight: '700',
        margin: 0,
    },
    paginationNumber: {
        color: COLORS.LT_MIDNIGHT50,
        fontWeight: '600',
        fontSize: '14px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '15px',
        },
    },
    paginationSpace: {
        paddingTop: '2em',
    },
    outlinedPageButton: {
        color: COLORS.LT_MIDNIGHT75,
        background: 'white',
    },
});

const PendingCCReview = (props) => {
    const { classes } = props;
    const history = useHistory();
    const [expenses, setExpenses] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const query = useQuery();
    const [criteria, setCriteria] = useState(getCriteriaFromLocalStorage('pending-cc'))
    const [page, setPage] = useState(query.get('page') ? Number(query.get('page')) : 1);
    const [pages, setPages] = useState(0);
    const [sortBy, setSortBy] = useState(criteria ? criteria.sortBy : 'division');
    const [sortDirection, setSortDirection] = useState(criteria ? criteria.sortDirection : 'asc');
    const [limit, setLimit] = useState(10);
    const [alert, setAlert] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const getOffset = page === 1 ? 0 : (page - 1) * limit;
    const [offset, setOffset] = useState(getOffset);
    const [isSorting, setIsSorting] = useState(false);
    const [expenseCategories, setExpenseCategories] = useState(null);
    const defaultFormValues = {
        Statuses: criteria ? criteria.statuses : [],
        Divisions: criteria ? criteria.divisions : [],
        'Expense Types': criteria && criteria.expenseTypes ? criteria.expenseTypes : [],
        'Bill Types': criteria && criteria.billTypes ? criteria.billTypes : [],
        StartStartDate: criteria && criteria.startStartDate ? criteria.startStartDate : null,
        StartEndDate: criteria && criteria.startEndDate ? criteria.startEndDate : null,
        EndStartDate: criteria && criteria.endStartDate ? criteria.endStartDate : null,
        TransactionStartDate:
            criteria && criteria.transactionStartDate ? criteria.transactionStartDate : null,
        TransactionEndDate:
            criteria && criteria.transactionEndDate ? criteria.transactionEndDate : null,
    };
    const methods = useForm({ defaultValues: defaultFormValues });
    const { handleSubmit, reset, getValues, setValue, watch } = methods;
    const watchForValues = watch();
    const [totalNumberExpense, setTotalNumberExpense] = useState(getValues('totalNumberExpense'));

    useEffect(() => {
        history.replace(`/expenses/pending-cc/review?page=${page}`);
        getData();
    }, []);

    useEffect(() => {
        history.replace(`/expenses/pending-cc/review?page=${page}`);
    }, [page]);

    const getNewSortValues = (sortKey) => {
        let newSortBy = sortBy;
        let newSortDirection = sortDirection;
        if (sortKey !== sortBy) {
            newSortBy = sortKey;
            newSortDirection = 'asc';
            setSortBy(sortKey);
            setSortDirection(newSortDirection);
        } else {
            newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
            newSortBy = newSortDirection === 'asc' && sortDirection === 'desc' ? null : sortKey;
            if (newSortBy !== sortKey) setSortBy(newSortBy);
            setSortDirection(newSortDirection);
        }

        return { newSortBy, newSortDirection };
    };

    const handleSort = async (sortKey) => {
        setIsSorting(true);
        setIsLoading(true);
        const { newSortBy, newSortDirection } = getNewSortValues(sortKey, sortBy, sortDirection);

        const currentCriteria = getCriteriaFromLocalStorage('pending-cc');

        currentCriteria.sortBy = newSortBy;
        currentCriteria.sortDirection = newSortDirection;
        setCriteria(currentCriteria);
        await fetchData(currentCriteria);
    };

    const fetchData = async (request) => {
        localStorage.setItem('pendingCCCriteria', JSON.stringify(request));
        try {
            const response = await getPreCodedWithFilter(request);
            setTotalNumberExpense(response.totalCount);
            setExpenses(response.data);
            setPages(Math.ceil(response.totalCount / limit));
            setIsSorting(false);
        } catch (error) {
            const message = formatErrorMessage(error);
            const code = error.statusCode;
            if (code == 404) {
                setExpenses([]);
                setTotalNumberExpense(0);
                setPages(0);
            } else {
                setAlert({
                    message,
                    type: 'error',
                });
            }
        } finally {
            setIsLoading(false);

            reset({
                Statuses: request?.statuses ? request?.statuses : [],
                Divisions: request?.divisions,
                'Expense Types': request?.expenseTypes,
                'Bill Types': request?.billTypes,
                'Merchant States': request?.merchantStates,
                StartStartDate: request?.startStartDate,
                StartEndDate: request?.startEndDate,
                EndStartDate: request?.endStartDate,
                EndEndDate: request?.endEndDate,
                TransactionStartDate: request?.transactionStartDate,
                TransactionEndDate: request?.transactionEndDate,
            });
        }
    };

    const getData = async () => {
        setIsLoading(true);
        const request = {
            offset,
            sortBy,
            sortDirection,
            limit,
            statuses: defaultFormValues.Statuses,
            divisions: defaultFormValues.Divisions,
            transactionStartDate: defaultFormValues.TransactionStartDate,
            transactionEndDate: defaultFormValues.TransactionEndDate,
            expenseTypes: defaultFormValues['Expense Types'],
            billTypes: defaultFormValues['Bill Types'],
            startStartDate: defaultFormValues.StartStartDate,
            startEndDate: defaultFormValues.StartEndDate,
            endStartDate: defaultFormValues.EndStartDate,
            endEndDate: defaultFormValues.EndEndDate,
        };
        await Promise.all([getPreCodedWithFilter(request), getExpenseCategories(), getDivisions(false, false)])
            .then(async ([response, cats, divs]) => {
                setTotalNumberExpense(response.totalCount);
                setPages(Math.ceil(response.totalCount / limit));
                setExpenses(response.data.slice(0, limit));
                setDivisions(divs);
                setExpenseCategories(cats);
            })
            .catch((error) => {
                const message = formatErrorMessage(error);
                if (message == 'Internal Server Error') {
                    setExpenses([]);
                } else {
                    setAlert({
                        message,
                        type: 'error',
                    });
                }
            })
            .finally(() => { setIsLoading(false); });
    };

    const handlePageChange = async (e, newPage) => {
        setIsLoading(true);
        const newOffset = newPage === 1 ? 0 : (newPage - 1) * limit;
        setOffset(newOffset);
        setPage(newPage);

        const currentCriteria = getCriteriaFromLocalStorage('pending-cc');

        currentCriteria.offset = newOffset;
        currentCriteria.page = newPage;
        setCriteria(currentCriteria);
        await fetchData(currentCriteria);
    };

    const renderPagination = () => (
        <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            className={classes.paginationSpace}
        >
            <Grid item>
                <div className={classes.paginationNumber}>
                    {`Page ${page} of ${!expenses ? 0 : pages}`}
                </div>
            </Grid>
            <Grid item>
                {pages > 1 && (
                    <Pagination
                        color='primary'
                        variant='outlined'
                        shape='rounded'
                        page={page}
                        count={!expenses ? 0 : pages}
                        onChange={(e, page) => handlePageChange(e, page)}
                        renderItem={(item) => {
                            const hidden =
                                (item.type === 'previous' || item.type === 'next') && item.disabled;
                            if (hidden) return;
                            return (
                                <PaginationItem
                                    {...item}
                                    classes={{ outlined: classes.outlinedPageButton }}
                                />
                            );
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );

    const renderFilters = () => (
        <FormProvider {...methods}>
            <ExpenseFiltersForm
                criteria={criteria}
                fetchData={fetchData}
                setIsLoading={setIsLoading}
                setOffset={setOffset}
                setPage={setPage}
                limit={limit}
                sortBy={sortBy}
                sortDirection={sortDirection}
                expenseCategories={expenseCategories}
                availableDivisions={divisions}
                pendingCCReview={true}
            />
        </FormProvider>
    );

    const renderExpenseTable = () => (
        <Paper classes={{ root: classes.PaperRoot }}>
            <ExpenseTable
                config={config}
                expenses={expenses}
                sortBy={sortBy}
                isLoading={isLoading}
                sortDirection={sortDirection}
                handleSort={handleSort}
                limit={limit}
                pendingCCReview={true}
            />
        </Paper>
    );
    return (
        <Container maxWidth='xl' className={classes.ContainerRoot}>
            <Grid>
                <Grid container justifyContent='space-between' spacing={2}>
                    <Grid item>
                        <Typography variant='h3' component='h3' className={classes.header}>
                            Pending CC Expense Review ({totalNumberExpense ? totalNumberExpense : 0})
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            aria-controls='search-key'
                            aria-haspopup='true'
                            onClick={() => history.push('/expenses/pending-cc')}
                            variant='contained'
                            color='primary'
                            id='search-key'
                        >
                            + Add Pending CC
                        </Button>
                    </Grid>
                </Grid>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit()}>
                        <Grid>{renderFilters()}</Grid>
                        <Grid>{renderExpenseTable()}</Grid>
                    </form>
                </FormProvider>

                {pages > 0 && renderPagination()}
            </Grid>
            <Alert alert={alert} setAlert={setAlert} />
        </Container>
    );
};

export default withStyles(styles)(PendingCCReview);
