import React from 'react';
import WithContexts from '../../../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import TrainingForm from '../../TrainingComponent/TrainingForm';

function FellowshipForm({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	updateFlags,
	activePath,
	setCredAppError,
	credState,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
	type = 'fellowships',
	constantType = 'FELLOWSHIP',
}) {
	return (
		<TrainingForm
			t={t}
			setSaveHandler={setSaveHandler}
			blockedBackNavigation={blockedBackNavigation}
			UserContext={UserContext}
			activePath={activePath}
			credState={credState}
			flags={flags}
			updateFlags={updateFlags}
			type={type}
			constantType={constantType}
			sectionCompletion={sectionCompletion}
			updateSectionCompletion={updateSectionCompletion}
			updateHighlightFieldsState={updateHighlightFieldsState}
			shouldHighlightFields={shouldHighlightFields}
			setCredAppError={setCredAppError}
		/>
	);
}

export default WithContexts(withTranslation()(FellowshipForm));
