import React, { Component } from 'react';
import {
	Paper,
	withStyles,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableContainer,
	Hidden,
	Chip,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Pagination from 'material-ui-flat-pagination';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { COLORS, APP_CONFIG } from '../../../utils/Application_Constants';
import { getListByOffset, getListOffsetByPage } from '../../../services/listPaginationService';
import { getPresents } from '../../../services/PresentsService';
import UserContext from '../../../contexts/userContext';
import PresentsMobileComponent from '../PresentsTableMobileCard/PresentsMobileComponent';

const styles = (theme) => ({
	tableHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '18px',
		marginRight: '15px',
		textAlign: 'left',
		[theme.breakpoints.down('sm')]: {
			width: '98%',
			margin: '0 auto',
		},
	},
	tableHeaderWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: '98%',
		margin: '0 auto',
		padding: '25px 0',
	},
	tableRowHeader: {
		color: '#122340',
		fontWeight: '900',
		textAlign: 'left',
		fontSize: '12px',
		textTransform: 'uppercase',
		opacity: '100%',
	},
	cellRoot: {
		color: '#596674',
		fontWeight: '500',
		fontSize: '16px',
		//padding: '25px 0',
		textAlign: 'left',
	},
	tableContainerRoot: {
		width: '98%',
		margin: '0 auto',
	},
	accountRoot: {
		color: '#596674',
		fontWeight: '500',
		fontSize: '16px',
		//padding: '15px 0',
		textAlign: 'left',
	},
	presentsWrapper: {
		minHeight: '400px',
	},
	inputLabel: {
		display: 'none',
	},
	selectedPage: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		borderRadius: '0',
		paddingLeft: '17px',
		paddingRight: '17px',
		'&:hover': {
			backgroundColor: COLORS.TRUE_BLUE,
		},
	},
	pageButton: {
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		borderRadius: '0',
		paddingLeft: '15px',
		paddingRight: '15px',
	},
	paginationNumber: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		opacity: '.8',
		fontSize: '16px',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '15px',
		},
	},
	paginationWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: '15px',
		alignItems: 'center',
		width: '90%',
		[theme.breakpoints.down('sm')]: {
			flexFlow: 'column',
		},
	},
	paginationFlexCenter: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '25px',
	},
	noMargin: {
		marginTop: '0 !important',
	},
	PaperMobile__FilterByMonth: {
		margin: '25px 0',
	},
	FormControlMobile__Root: {
		width: '100%',
	},
	TextFieldComponentMobile_Root: {
		width: '100%',
		marginTop: '0 !important',
	},
	PaperMobile__PresentsWrapper: {
		padding: '25px 0',
		margin: '25px 0',
	},
	MobileHeader__Wrapper: {
		width: '95%',
		margin: '0 auto',
		display: 'flex',
		marginBottom: '20px',
	},
	pendingStatus: {
		color: COLORS.LT_JOURNEY,
		borderColor: COLORS.LT_JOURNEY,
		backgroundColor: '#E5F1FA',
		fontWeight: '500',
		fontSize: '14px',
		border: '1px solid #1177C8',
		borderRadius: '8px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '-5px',
		},
	},
	closedStatus: {
		backgroundColor: COLORS.LT_PEPPER10,
		color: COLORS.LT_PEPPER,
		borderColor: COLORS.LT_PEPPER,
		padding: '3px',
		fontWeight: '500',
		fontSize: '14px',
		border: '1px solid #1177C8',
		borderRadius: '8px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '-5px',
		},
	},
	noPresentText: {
		color: '#4D5965',
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'center',
		display: 'block',
		minHeight: '250px',
		marginTop: '130px',
		[theme.breakpoints.down('sm')]: {
			minHeight: '200px',
			paddingTop: '32px',
		},
	},
});

class PresentComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			presents: {},
			isLoading: true,
			activePresents: {},
			offset: 0,
			page: 1,
			presentsWithOffsets: [],
			presentsWithFilters: [],
			presentsFilter: '4',
		};
	}

	async componentDidMount() {
		const { externalId } = this.context;
		const state = { ...this.state };
		const { location, history, setAssignmentPageError } = this.props;
		const urlParams = queryString.parse(location.search);
		state.presents = await getPresents(externalId, true).catch((e) => {
			setAssignmentPageError(e);
		});
		state.presentsFilter = urlParams.months || 24;
		state.isLoading = false;
		state.page = urlParams.page || 1;
		state.offset = getListOffsetByPage(Number(state.page));

		let presents = this.sortDataPerDates(state.presents);
		state.presentsWithFilters = this.getFilteredDatesOnLoad(presents, 24);
		state.presentsWithOffsets = getListByOffset(state.presentsWithFilters, state.offset);

		this.setState({
			presents: state.presents,
			presentsFilter: state.presentsFilter,
			presentsWithFilters: state.presentsWithFilters,
			isLoading: false,
			page: state.page,
			offset: state.offset,
			presentsWithOffsets: state.presentsWithOffsets,
		});

		history.push(`/assignments/presents?page=${state.page}&months=${state.presentsFilter}`);
	}

	handlePagination = (offset, page) => {
		const state = { ...this.state };
		const { presentsWithFilters } = this.state;
		state.offset = offset;
		state.page = page;
		state.presentsWithOffsets = getListByOffset(presentsWithFilters, offset);
		this.setState({ ...state });
	};

	getFilteredDatesOnLoad = (presents, months) => {
		const historicalDate = moment().startOf('day').subtract(months, 'month');
		const yesterdayEndOfRange = moment().endOf('day').subtract(1, 'day');
		return presents.filter((present) =>
			moment(present.presentDate).isBetween(historicalDate, yesterdayEndOfRange)
		);
	};

	renderStatus = (item) => {
		const { classes } = this.props;
		if (!!item) {
			if (
				item.status === 'Presented' ||
				item.status === 'Interview' ||
				item.status === 'Selected'
			) {
				return (
					<Chip variant='outlined' label={'Pending'} className={classes.pendingStatus} />
				);
			}
			if (item.status === 'Loss/Rejected' || item.status === 'Booked') {
				return (
					<Chip variant='outlined' label={'Closed'} className={classes.closedStatus} />
				);
			}
		}
	};
	renderPagination = () => {
		const { classes, t } = this.props;
		const { presentsWithFilters, page, offset } = this.state;
		return (
			<div className={classes.paginationFlexCenter}>
				<div className={classes.paginationWrapper}>
					<div className={classes.paginationNumber}>
						{t('common:GENERIC_PHRASES.PAGE')} {page} {t('common:GENERIC_PHRASES.OF')}{' '}
						{Math.floor(
							(presentsWithFilters.length - 1) / APP_CONFIG.PAGINATION_LIMIT + 1
						)}
					</div>
					<Pagination
						classes={{
							rootCurrent: classes.selectedPage,
							rootStandard: classes.pageButton,
							rootEnd: classes.pageButton,
						}}
						limit={APP_CONFIG.PAGINATION_LIMIT}
						total={presentsWithFilters.length}
						onClick={(e, offset, page) => this.handlePagination(offset, page)}
						offset={offset}
					/>
				</div>
			</div>
		);
	};

	sortDataPerDates = (data) => {
		let result = data.sort((a, b) => {
			return moment(b.presentDate).diff(a.presentDate);
		});

		return result;
	};

	desktopTableRender() {
		const { presentsWithOffsets } = this.state;

		const { classes } = this.props;
		return (
			<div className={classes.presentsWrapper}>
				<Paper elevation>
					<TableContainer classes={{ root: classes.tableContainerRoot }}>
						<Table aria-label='presents table'>
							<TableRow>
								<TableCell classes={{ root: classes.tableRowHeader }}>
									Facility
								</TableCell>
								<TableCell classes={{ root: classes.tableRowHeader }}>
									Location
								</TableCell>
								<TableCell classes={{ root: classes.tableRowHeader }}>
									Present Date
								</TableCell>
								<TableCell classes={{ root: classes.tableRowHeader }}>
									Status
								</TableCell>
							</TableRow>
							<TableBody>
								{presentsWithOffsets.map((item, index) => (
									<TableRow scope='row' key={`TR-${index + 1}`}>
										<TableCell classes={{ root: classes.accountRoot }}>
											{item.accountName}
										</TableCell>
										<TableCell classes={{ root: classes.cellRoot }}>
											{item.city},{item.state}
										</TableCell>
										<TableCell classes={{ root: classes.cellRoot }}>
											{moment.utc(item.presentDate).format('MMM D, YYYY')}
										</TableCell>
										<TableCell classes={{ root: classes.cellRoot }}>
											{this.renderStatus(item)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					{presentsWithOffsets !== undefined && presentsWithOffsets.length > 0
						? this.renderPagination()
						: null}
				</Paper>
			</div>
		);
	}

	mobilePresentsTable() {
		const { presentsWithOffsets } = this.state;
		const { classes, t } = this.props;
		return (
			<>
				<Paper classes={{ root: classes.PaperMobile__PresentsWrapper }}>
					<div className={classes.MobileHeader__Wrapper}>
						<span className={classes.tableHeader}>{t('presents:PRESENTS')}</span>
					</div>
					{presentsWithOffsets.map((item, index) => (
						<PresentsMobileComponent
							key={`TR=${index + 1}`}
							present={item}
							renderStatus={this.renderStatus}
						/>
					))}
					{presentsWithOffsets !== undefined && presentsWithOffsets.length > 0
						? this.renderPagination()
						: null}
				</Paper>
			</>
		);
	}

	render() {
		const { isLoading, presentsWithOffsets } = this.state;
		const { classes, t } = this.props;
		if (isLoading) {
			return <CircularProgress />;
		}

		if (
			presentsWithOffsets.length === 0 ||
			presentsWithOffsets === null ||
			presentsWithOffsets === undefined
		) {
			return <div className={classes.noPresentText}>{t('presents:NO_PRESENTS_TO_SHOW')}</div>;
		}
		return (
			<>
				<Hidden only={['xs', 'sm']}>{this.desktopTableRender()}</Hidden>
				<Hidden only={['md', 'lg', 'xl']}>{this.mobilePresentsTable()}</Hidden>
			</>
		);
	}
}

PresentComponent.contextType = UserContext;

export default withTranslation()(withRouter(withStyles(styles)(PresentComponent)));
