import React, { useContext } from 'react';
import { Container, Grid, Typography, withStyles } from '@material-ui/core';
import ExpenseCard from '../Components/Expense/ExpenseCard';
import ExpensesUpload from '../Components/Expense/ExpensesUpload';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import { AdminAuthContext } from '../../Admin/Contexts/AdminAuthContext';

const styles = (theme) => ({
    ContainerRoot: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        paddingTop: '2.5rem',
        paddingBottom: '5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
    },
    header: {
        fontWeight: '700',
        margin: 0,
        color: '#336195',
        marginBottom: 24,
    },
    description: {
        fontSize: '1rem',
    },
});

const Expenses = (props) => {
    const { classes } = props;
    const { isExpenseSystemAdministratorUser } = useContext(AdminAuthContext);

    return (
        <Container maxWidth='md' className={classes.ContainerRoot}>
            <Grid container direction='column' spacing={6}>
                <Grid item xs={12}>
                    <Typography
                        variant='h3'
                        component='h3'
                        className={classes.header}
                        align='center'
                    >
                        Expense Management
                    </Typography>
                    <Typography variant='body2' align='center' className={classes.description}>
                        Create, review, and upload expense reports - all in one convenient location!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5' component='h5' className={classes.header}>
                        Submitting an expense
                    </Typography>
                    <Grid container direction='column' spacing={2}>
                        <ExpenseCard
                            icon={<LocalAtmIcon color='primary' />}
                            label='New Clinician Reimbursement'
                            path='/expenses/clinician-reimbursement'
                        />
                        <ExpenseCard
                            icon={<CreditCardIcon color='primary' />}
                            label='New Pending CC'
                            path='/expenses/pending-cc'
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5' component='h5' className={classes.header}>
                        Viewing expenses
                    </Typography>
                    <Grid container direction='column' spacing={2}>
                        <ExpenseCard
                            icon={<FindInPageIcon color='primary' />}
                            label='CC/CR Expense Review'
                            path='/expenses/review'
                        />
                        <ExpenseCard
                            icon={<CardMembershipIcon color='primary' />}
                            label='Pending CC Review'
                            path='/expenses/pending-cc/review'
                        />
                    </Grid>
                </Grid>
                {isExpenseSystemAdministratorUser && (
                    <Grid item xs={12}>
                        <Typography variant='h5' component='h5' className={classes.header}>
                            Credit Card File Upload
                        </Typography>
                        <ExpensesUpload />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default withStyles(styles)(Expenses);
