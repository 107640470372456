import createActivityDetector from 'activity-detector';
import mySalService from '../../../services/msSalService';
import { appInsights } from '../../../AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

class ActiveTokenRefresh {
	constructor() {
		this.successInterval = this.successInterval.bind(this);
		this.errorInterval = this.errorInterval.bind(this);
		this.clear = this.clear.bind(this);
		this.intervalID = null;

		this.activityDetector = createActivityDetector({
			timeToIdle: 300000, // wait 5 minutes of inactivity to consider the user is idle
			autoInit: false, // I don't want to start the activity detector yet.
		});

		this.activityDetector.on(
			'idle',
			function () {
				console.log(
					'ActiveTokenRefresh idle: The user is not interacting with the page stop automatic token refresh'
				);
				this.clear();
			}.bind(this)
		);

		this.activityDetector.on(
			'active',
			function () {
				console.log(
					'ActiveTokenRefresh active: The user is using the automatic token refresh'
				);
				this.clear();
				//Call silentReferesh when coming off of idle and then every 10 minutes while still active
				mySalService.getMSALObj
					.acquireTokenSilent({ scopes: [process.env.REACT_APP_CLIENT_ID] })
					.then(
						function (accessTokenResponse) {
							console.log(
								'ActiveTokenRefresh initInterval: acquireTokenSilent success starting successInterval'
							);
							this.successInterval();
						}.bind(this)
					)
					.catch(
						function (error) {
							console.log(
								'ActiveTokenRefresh initInterval: acquireTokenSilent fail error.errorCode: ' +
									error.errorCode +
									' starting errorInterval'
							);
							appInsights.trackException({
								exception: error,
								properties: {
									method: 'ActiveTokenRefresh',
									severityLevel: SeverityLevel.Error,
								},
							});
							this.errorInterval();
						}.bind(this)
					);
			}.bind(this)
		);

		if (!ActiveTokenRefresh.instance) {
			ActiveTokenRefresh.instance = this;
		}

		return ActiveTokenRefresh.instance;
	}

	successInterval() {
		console.log('ActiveTokenRefresh successInterval: Is in use start');
		this.intervalID = setInterval(
			function (parent) {
				console.log(
					'ActiveTokenRefresh successInterval: Token Silent Referesh Ran ' +
						' intervalID ' +
						parent.intervalID
				);
				// call silentReferesh every 5 minutes while user is active and tokens ARE successful refreshed
				mySalService.getMSALObj
					.acquireTokenSilent({ scopes: [process.env.REACT_APP_CLIENT_ID] })
					.then(function (accessTokenResponse) {
						console.log(
							'ActiveTokenRefresh successInterval: SUCCESS accessToken refreshed  ' +
								parent.intervalID
						);
					})
					.catch(function (error) {
						appInsights.trackException({
							exception: error,
							properties: {
								method: 'successInterval',
								severityLevel: SeverityLevel.Error,
							},
						});
						console.log(
							'ActiveTokenRefresh successInterval: ERROR intervalID ' +
								parent.intervalID
						);
						clearInterval(parent.intervalID);
						parent.errorInterval();
					});
			},
			300000,
			this
		);
		console.log(
			'ActiveTokenRefresh successInterval: Is in use end intervalID ' + this.intervalID
		);
	}

	errorInterval() {
		console.log('ActiveTokenRefresh errorInterval: Is in use start');
		this.intervalID = setInterval(
			function (parent) {
				console.log(
					'ActiveTokenRefresh errorInterval: Token Silent Referesh Ran ' +
						' intervalID ' +
						parent.intervalID
				);
				//Call silentReferesh every 15 seconds while user is active and tokens ARE NOT successful refreshed
				mySalService.getMSALObj
					.acquireTokenSilent({ scopes: [process.env.REACT_APP_CLIENT_ID] })
					.then(function (accessTokenResponse) {
						console.log(
							'ActiveTokenRefresh errorInterval: SUCCESS intervalID ' +
								parent.intervalID
						);
						clearInterval(parent.intervalID);
						parent.successInterval();
					})
					.catch(function (error) {
						console.log(
							'ActiveTokenRefresh errorInterval: error.errorCode: ' +
								error.errorCode +
								' intervalID ' +
								parent.intervalID
						);
					});
			},
			15000,
			this
		);
		console.log(
			'ActiveTokenRefresh errorInterval: Is in use end IntervalID ' + this.intervalID
		);
	}

	clear() {
		if (!!this.intervalID) {
			console.log('ActiveTokenRefresh clear: intervalID ' + this.intervalID);
			clearInterval(this.intervalID);
			this.intervalID = null;
		}
	}

	stop() {
		console.log('ActiveTokenRefresh stop ');
		this.clear();
		this.activityDetector.stop();
	}

	start() {
		console.log('ActiveTokenRefresh start ');
		this.clear();
		// I want to start the activity detector now!
		this.activityDetector.init();
	}
}

const instance = new ActiveTokenRefresh();
export default instance;
