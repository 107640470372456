import React from 'react';
import AttestationQuestionsForm from './AttestationQuestionsForm';

function DisciplinaryActionQuestionsForm({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	setCredAppError,
	updateFlags,
	activePath,
	credState,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	const myQuestions = [
		'Had disciplinary action on medical license',
		'Had disciplinary action on DEA registration',
		'Had disciplinary action on other professional registration',
		'Had disciplinary action on clinical privileges',
		'Had disciplinary action on membership',
		'Had disciplinary action on institutional affiliation',
		'Had disciplinary acton on professional society membership',
		'Has professional sanction',
		'Had disciplinary action on govt health insurance',
	];

	return (
		<AttestationQuestionsForm
			myQuestions={myQuestions}
			description={t(`credentials:DISCIPLINARY_QUESTIONS.DESCRIPTION`)}
			t={t}
			setSaveHandler={setSaveHandler}
			blockedBackNavigation={blockedBackNavigation}
			UserContext={UserContext}
			activePath={activePath}
			credState={credState}
			flags={flags}
			updateFlags={updateFlags}
			constantType={'DISCIPLINARY_QUESTIONS'}
			sectionCompletion={sectionCompletion}
			updateSectionCompletion={updateSectionCompletion}
			updateHighlightFieldsState={updateHighlightFieldsState}
			shouldHighlightFields={shouldHighlightFields}
			setCredAppError={setCredAppError}
		/>
	);
}

export default DisciplinaryActionQuestionsForm;
