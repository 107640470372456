import React, { Component, Fragment } from 'react';
import { withStyles, Grid, Chip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { COLORS } from '../../../utils/Application_Constants';

const styles = (theme) => ({
	desktopWrapper: {
		display: 'flex',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		paddingLeft: '20px',
		marginTop: '32px',
		paddingBottom: '32px',
		flexDirection: 'column',
		textAlign: 'left',
	},
	header: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '18px',
		margin: 0,
	},
	spanTitleFont: {
		fontWeight: '900',
		color: COLORS.RAVEN,
		fontSize: '12px',
		display: 'flex',
		textTransform: 'uppercase',
	},
	emptyStateWrapper: {
		display: 'flex',
	},
	innerCardEmptyWrapper: {
		margin: '10px 20px',
		display: 'flex',
		padding: '10px',
		borderRadius: '5px',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-around',
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		[theme.breakpoints.down('xs')]: {
			margin: '10px 10px',
		},
	},
	dateFont: {
		fontWeight: '300',
		textTransform: 'uppercase',
	},
	addressWrapper: {
		paddingBottom: '16px',
	},
	locationWrapper: {
		marginTop: '1rem',
		width: '100%',
	},
	addressFont: {
		fontWeight: '500',
		fontSize: '14px',
		color: COLORS.RAVEN,
	},
	locationFont: {
		fontWeight: '700',
		fontSize: '16px',
		color: COLORS.LT_SLATE,
		//marginRight:'5rem',
		textAlign: 'left',
	},
	locationValue: {
		fontWeight: '500',
		fontSize: '16px',
		color: COLORS.LT_SLATE,
		textAlign: 'left',
	},
	presentDateFont: {
		fontWeight: '700',
		fontSize: '16px',
		color: COLORS.LT_SLATE,
		//marginRight:'2rem',
		textAlign: 'left',
	},
	presentDateValue: {
		fontWeight: '500',
		fontSize: '16px',
		color: COLORS.LT_SLATE,
		textAlign: 'left',
	},
	emptyStateHeader: {
		textAlign: 'left',
		fontWeight: '700',
		letterSpacing: '0',
		fontSize: '20px',
		color: COLORS.RAVEN,
		opacity: '0.3',
	},
	presentDate: {
		display: 'flex',
		flexFlow: 'column',
	},
	dateInfoGrid: {
		display: 'grid',
		width: '500px',
		gridTemplateColumns: 'repeat(auto-fit, minmax(30px, 1fr))',
		[theme.breakpoints.down('sm')]: {
			width: '250px',
			gridTemplateColumns: 'repeat(auto-fit, minmax(25px, 1fr))',
		},
	},
	pendingStatus: {
		color: COLORS.LT_JOURNEY,
		borderColor: COLORS.LT_JOURNEY,
		backgroundColor: '#E5F1FA',
		fontWeight: '500',
		fontSize: '14px',
		border: '1px solid #1177C8',
		borderRadius: '8px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '-5px',
		},
	},
	closedStatus: {
		backgroundColor: COLORS.LT_PEPPER10,
		color: COLORS.LT_PEPPER,
		borderColor: COLORS.LT_PEPPER,
		padding: '3px',
		fontWeight: '500',
		fontSize: '14px',
		borderRadius: '8px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '-5px',
		},
	},
});

class PresentsCard extends Component {
	presentsCard = () => {
		const { present, t } = this.props;
		if (!present.accountName) {
			return null;
		}
		return (
			<div>
				<div className='cr-p-3'>
					<h5 className='cr-mb-2'>{present.accountName}</h5>
					<Grid container spacing={2}>
						<Grid item md={6}>
							<p className='cr-mb-1'>
								<strong>Location</strong>
							</p>
						</Grid>
						<Grid item md={6}>
							<p className='cr-mb-1'>{`${present.city}, ${present.state}`}</p>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item md={6}>
							<p className='cr-mb-1'>
								<strong>{t('presents:PRESENTED_DATE')}</strong>
							</p>
						</Grid>
						<Grid item md={6}>
							<p className='cr-mb-1'>
								{present.presentDate
									? moment.utc(present.presentDate).format('MMM DD, YYYY')
									: t('presents:NO_DATE')}
							</p>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item md={6}>
							<p className='cr-mb-1'>
								<strong>{t('presents:STATUS')}</strong>
							</p>
						</Grid>
						<Grid item md={6}>
							{this.renderStatus(present)}
						</Grid>
					</Grid>
				</div>
				<hr />
			</div>
		);
	};

	renderStatus = (item) => {
		if (!!item) {
			if (
				item.status === 'Presented' ||
				item.status === 'Interview' ||
				item.status === 'Selected'
			) {
				return (
					<Chip
						variant='outlined'
						label={'Pending'}
						className='cr-chip cr-chip--statusJourney'
					/>
				);
			}
			if (item.status === 'Loss/Rejected' || item.status === 'Booked') {
				return (
					<Chip
						variant='outlined'
						label={'Closed'}
						className='cr-chip cr-chip--statusPepper'
					/>
				);
			}
		}
	};

	emptyCard = () => {
		const { classes, t } = this.props;
		return (
			<div className={classes.emptyStateWrapper}>
				<div className={classes.innerCardEmptyWrapper}>
					<span className={classes.emptyStateHeader}>{t('presents:NO_PRESENTS')}</span>
				</div>
			</div>
		);
	};

	renderPresentCard = () => {
		const { isEmpty } = this.props;
		if (isEmpty) {
			return this.emptyCard();
		}
		return this.presentsCard();
	};

	render() {
		const { present } = this.props;
		return <Fragment key={present.accountName || 1}>{this.renderPresentCard()}</Fragment>;
	}
}

export default withTranslation()(withStyles(styles)(PresentsCard));
