import React, { Component } from 'react';
import {
	withStyles,
	Container,
	Grid,
	Paper,
	Button,
	CircularProgress,
	ExpansionPanelSummary,
	ExpansionPanel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../utils/Application_Constants';
import UserContext from '../../contexts/userContext';
import WithContexts from '../../contexts/withContexts';
import PayStatementTable from '../../components/ContentComponents/TimeandPay/PayStatementTable';
import YTDEarningsDetails from '../../components/ContentComponents/TimeandPay/YTDEarningsDetails';
import { getSamlResponse } from '../../services/peoplenetSsoService';
import NavigationService from '../../services/NavigationService';
import {
	getNextPayDateWCAPI,
	getYTDPayData,
	getPayStatementDetailsData,
	getTaxStatementDetails,
} from '../../services/TimeAndPayService';
import { getClinicianAccountV2 } from '../../services/ProfileServices/profileService';
import FeedbackTile from '../../components/ContentComponents/TimeandPay/FeedbackTile';
import { Doughnut } from 'react-chartjs-2';
import { ExpandMore } from '@material-ui/icons';
import { convertToDateIgnoringTime, stateAcronymToFullName } from '../../utils/helpers';
import { CSVLink } from 'react-csv';
import ResourcesTile from '../../components/ContentComponents/TimeandPay/ResourcesTile';
import Alert from '@material-ui/lab/Alert';
import TaxStatementTable from '../../components/ContentComponents/TimeandPay/TaxStatementTable';
import { Link, withRouter } from 'react-router-dom';

const styles = (theme) => ({
	root: {
		padding: '40px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		backgroundColor: COLORS.BLACK_HAZE,
	},
	dropdownRoot: {
		marginTop: '30px',
		padding: '30px 0',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	helpPanelRoot: {
		marginTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	dropdownContainerRoot: {
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		width: '100%',
		padding: '20px 20px',
		textAlign: 'left',
		borderRadius: '4px',
		position: 'relative',
	},
	buttonRoot: {
		lineHeight: 'inherit',
		margin: '0 30px 20px',
		padding: 0,
		width: '100%',
		textTransform: 'none',
		boxShadow: 'none',
	},
	helpPanelContainerRoot: {
		padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	helpPanelHeader: {
		marginBottom: '10px',
	},
	helpPanelLinks: {
		textAlign: 'left',
		marginBottom: '10px',
	},
	helpPanelButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		textTransform: 'none',
		boxShadow: 'none',
		fontWeight: '700',
	},
	header: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '20px',
		margin: 0,
	},
	linkHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
		whiteSpace: 'nowrap',
	},
	circularProgessTS: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '22px',
		marginLeft: '10px',
		textAlign: 'left',
		whiteSpace: 'nowrap',
	},

	progressWrapper: {
		height: '80vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	link: {
		color: COLORS.TRUE_BLUE,
		marginBottom: '10px',
		textDecoration: `underline dotted ${COLORS.LIGHT_BLUE}`,
		fontWeight: '400',
		fontSize: '16px',
		cursor: 'pointer',
		display: 'block',
	},
	linkDetails: {
		color: COLORS.TRUE_BLUE,
		// fontWeight: '300',
		// fontSize: '14px',
		cursor: 'pointer',
		// display: 'block',
	},
	timesheetText: {
		color: 'red !important',
		fontSize: '14px',
		textAlign: 'left',
		marginTop: 5,
		marginBottom: 23,
	},
	timesheetButton: {
		color: COLORS.WHITE_LILAC,
		fontWeight: '500',
		fontSize: '20px',
		margin: 0,
	},
	topTileRoot: {
		marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	topTileRootTax: {
		//marginTop: '0px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	topTileRootYTD: {
		marginTop: '32px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	topTileRootPST: {
		//padding: '0px'
		paddingLeft: '-10px',
	},
	nextPayDateSubheader: {
		fontSize: '14px',
		textAlign: 'left',
		marginTop: 5,
		marginBottom: 23,
	},
	nextPayDateNullSubheader: {
		fontSize: '14px',
		textAlign: 'left',
		marginTop: -8,
		marginBottom: 23,
	},
	nextPayDate: {
		fontSize: '24px',
		textAlign: 'left',
		marginBottom: 5,
		marginTop: 5,
		color: COLORS.DARK_BLUE,
		fontWeight: 'bold',
	},
	ContainerRoot: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: 70,
			paddingRight: 70,
		},
	},
	DoughnutContainer: {
		minHeight: '175px !important',
		maxHeight: '250px',
		minWidth: '175px !important',
		paddingRight: '20px',
		marginLeft: '-25px',
	},
	progressButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		whiteSpace: 'nowrap',
	},
	ButtonContainer: {
		marginBottom: '15px',
	},
	applyButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '150px',
		textTransform: 'none',
		boxShadow: 'none',
		fontWeight: '700',
		marginTop: '20px',
	},

	expansionPanelSummaryRoot: {
		padding: '8px 20px',
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
	PaperRoot: {
		padding: '1.5rem',
		display: 'flex',
		gap: '24px',
		flexDirection: 'column',
		marginTop: '30px',
	},
});

/**
 * Landing page for Time and Pay
 */
class TimeAndPayV2 extends Component {
	constructor(props) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName('Time and Pay');
		this.state = {
			isLoading: true,
			timeAndPayLinks: [],
			nextPayDateSubheader: 'For week worked ',
			nextPayDate: '',
			nextPayDateStatus: '',
			payStatementDetails: '',
			ytdData: null,
			ytdTotal: '',
			ytdDataRaw: [],
			openDialog: false,
			taxStatementDetails: '',
			payStatementError: false,
			taxStatementError: false,
			submitTimeSheetError: false,
			payDateDataError: false,
			ytdDataError: false,
			isTSInProgress: false,
			hasLTOEAssignments: false
		};
	}
	async componentDidMount() {
		const { nextPayDateSubheader } = this.state;
		const { externalId } = this.props.UserContext;
		const timeAndPayLinks = NavigationService.getTimeAndPayNavigationLinks();

		const payDateData = await getNextPayDateWCAPI(externalId).catch((e) => {
			this.setState({ payDateDataError: true });
			payDateData.payrollDate = null;
		});

		if (!!payDateData.status) {
			if (payDateData.status !== 'Errored') {
				payDateData.payrollDate = convertToDateIgnoringTime(payDateData.payrollDate).format(
					'l'
				);
				payDateData.weekStartingDate = convertToDateIgnoringTime(payDateData.weekEndingDate)
					.subtract(7, 'days')
					.format('l');
				payDateData.weekEndingDate = convertToDateIgnoringTime(
					payDateData.weekEndingDate
				).format('l');
			} else {
				payDateData.payrollDate = null;
			}
		} else {
			payDateData.payrollDate = null;
		}

		const YTDdataset = {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [],
					hoverBackgroundColor: [],
				},
			],
			text: '',
		};

		const doughnutColors = [
			'#FFA7A7',
			'#A7BBFD',
			'#AAFEA7',
			'#D3D3D3',
			COLORS.LT_JOURNEY,
			COLORS.LT_FOUNDATION,
			COLORS.LT_EMERALD,
			COLORS.LT_POMEGRANATE,
			COLORS.LT_SUNFLOWER,
			COLORS.LT_SILVER,
			COLORS.LT_MIDNIGHT,
		];

		var hasLTOEAssignments = false;
		try {
			const clinicianInfo = await getClinicianAccountV2(externalId);
			hasLTOEAssignments = clinicianInfo?.hasLTOEAssignments;
		} catch (e) {
			console.log('Error retrieving clinician info: '+externalId);
		}

		var ytdTotal = '';

		var ytdDataRaw = null;

		ytdDataRaw = await getYTDPayData(externalId).catch((e) => {
			this.setState({ ytdDataError: true });
		});
		console.log(' ytdDataRaw --> ' + JSON.stringify(ytdDataRaw));

		const payStateData = await getPayStatementDetailsData(externalId, null, null).catch((e) => {
			this.setState({ payStatementError: true });
		});
		// fetch first 12 records initally.

		const taxStateData = await getTaxStatementDetails(externalId).catch((e) => {
			this.setState({ taxStatementError: true });
		});

		var ytdData = {};

		if (!this.state.ytdDataError && !!ytdDataRaw && ytdDataRaw.length > 0) {
			var i;
			for (i = 0; i < ytdDataRaw.length; i++) {
				let state = ytdDataRaw[i].facilityState;
				if (ytdData.hasOwnProperty(state)) {
					ytdData[state] += ytdDataRaw[i].net;
				} else {
					Object.assign(ytdData, { [`${state}`]: ytdDataRaw[i].net });
				}
			}

			var earningsSum = 0;
			Object.keys(ytdData).forEach(function (key, index) {
				YTDdataset.labels.push(stateAcronymToFullName(key));
				YTDdataset.datasets[0].data.push(ytdData[key]);
				earningsSum += ytdData[key];
				YTDdataset.datasets[0].backgroundColor.push(doughnutColors[index]);
				YTDdataset.datasets[0].hoverBackgroundColor.push(doughnutColors[index]);
			});

			const numberFormatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
			});
			YTDdataset.text = `${numberFormatter.format(earningsSum)}`;
		} else {
			YTDdataset.text = 'No Data';
			YTDdataset.labels.push('No Data');
			YTDdataset.datasets[0].data.push(0);
			YTDdataset.datasets[0].backgroundColor.push(COLORS.LT_SILVER);
			YTDdataset.datasets[0].hoverBackgroundColor.push(COLORS.LT_SILVER);
		}

		this.setState({
			isLoading: false,
			timeAndPayLinks: timeAndPayLinks.timeandpay,
			nextPayDateSubheader:
				nextPayDateSubheader +
				`${payDateData.weekStartingDate} - ${payDateData.weekEndingDate}`,
			nextPayDate: payDateData.payrollDate,
			nextPayDateStatus: payDateData.status,
			ytdData: YTDdataset,
			ytdTotal: ytdTotal,
			payStatementDetails: payStateData,
			ytdDataRaw: ytdDataRaw,
			taxStatementDetails: taxStateData,
			hasLTOEAssignments: hasLTOEAssignments
		});
	}

	toggleOpendialog = (view) => {
		this.setState({ openDialog: view });
	};
	handleViewYTDDetails() {
		this.setState({ openDialog: true });
	}

	async doSubmitTimesheet() {
		this.setState({ submitTimeSheetError: false, isTSInProgress: true });

		await getSamlResponse().catch((e) => {
			this.setState({ submitTimeSheetError: true, isTSInProgress: false });
		});
		this.setState({ isTSInProgress: false });
	}

	renderYTDDetailsDialog() {
		const { t } = this.props;
		const { ytdData, ytdDataRaw } = this.state;

		return (
			<YTDEarningsDetails
				t={t}
				openDialog={this.state.openDialog}
				toggleOpendialog={this.toggleOpendialog}
				ytdDataRaw={ytdDataRaw}
				finalData={ytdData}
			/>
		);
	}

	handleAppcuesTrack = () => {
		window.Appcues.track("Enter Timesheet Flow (Submit)", {
			key: "Click the submit timesheet button",
			description: "Enter the timesheet landing page in LTOE"
		})
	}

	render() {
		const {
			isLoading,
			nextPayDateSubheader,
			nextPayDate,
			nextPayDateStatus,
			payStatementDetails,
			ytdData,
			ytdDataRaw,
			taxStatementDetails,
			payStatementError,
			taxStatementError,
			submitTimeSheetError,
			payDateDataError,
			ytdDataError,
			isTSInProgress,
			hasLTOEAssignments
		} = this.state;

		const { classes, t, UserContext } = this.props;
		if (localStorage.getItem('LocalStorage_WorkNFlow') === 'true') {
			return getSamlResponse();
		}

		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress color='primary' />
				</div>
			);
		}
		return (
			<div className={classes.root}>
				<Container maxWidth='xl' className={classes.ContainerRoot} justify='space-evenly'>
					<Grid container direction='row' spacing={4}>
						<Grid item xs={12} md={4}>
							<Paper classes={{ root: classes.topTileRoot }}>
								<Grid container classes={{ root: classes.helpPanelContainerRoot }}>
									<Grid item xs={12} className={classes.linkHeader}>
										{t('timeAndPay:HEADERS.TIMESHEET')}
									</Grid>
									{isTSInProgress ? (
										<Grid item xs={12} className={classes.circularProgessTS}>
											<CircularProgress />
										</Grid>
									) : (
										<>
											<Grid item xs={12}>
												<p className={classes.timesheetText}>
													{t('timeAndPay:INFO.TIMESHEET_INFO')}
												</p>
											</Grid>
												{hasLTOEAssignments ?
													<Link to='/timesheets'>
														<Button
															className={classes.helpPanelButton}
															variant='contained'
															color='primary'
															onClick={this.handleAppcuesTrack}
														>
															{t('timeAndPay:BUTTONS.SUBMIT_TIMESHEET')}
														</Button>
													</Link>
													:
													<Button
														className={classes.helpPanelButton}
														variant='contained'
														color='primary'
														onClick={async () => {
															await this.doSubmitTimesheet();
														}}
													>
														{t('timeAndPay:BUTTONS.SUBMIT_TIMESHEET')}
													</Button>
												}
										</>
									)}
								</Grid>

								{submitTimeSheetError ? (
									<Grid
										container
										classes={{ root: classes.helpPanelContainerRoot }}
									>
										<Grid item xs={12}>
											<h3>
												Error Occurred while submitting timesheet. Please
												try after some time.
											</h3>
										</Grid>
									</Grid>
								) : null}
							</Paper>
						</Grid>
						<Grid item xs={12} md={4}>
							<Paper classes={{ root: classes.topTileRoot }}>
								<Grid container classes={{ root: classes.helpPanelContainerRoot }}>
									<Grid item xs={12} className={classes.linkHeader}>
										{t(`timeAndPay:HEADERS.NEXT_PAY_DATE`)}
									</Grid>
									{!!nextPayDate ? (
										<Grid item xs={12}>
											<p className={classes.nextPayDateSubheader}>
												{nextPayDateSubheader}
											</p>
										</Grid>
									) : !!nextPayDateStatus ? (
										<Grid item xs={12}>
											<p className={classes.nextPayDateNullSubheader}>
												{t('timeAndPay:INFO.ERROR_NEXT_PAY_DATE')}
											</p>
										</Grid>
									) : (
										<Grid item xs={12}>
											<p className={classes.nextPayDateNullSubheader}>
												{t('timeAndPay:INFO.NO_NEXT_PAY_DATE')}
											</p>
										</Grid>
									)}
									<Grid item xs={12}>
										<p className={classes.nextPayDate}>{nextPayDate}</p>
									</Grid>
									{payDateDataError ? (
										<Grid item xs={12}>
											<h3>
												Error getting next Payment Date. Please try after
												some time.
											</h3>
										</Grid>
									) : null}
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12} md={4}>
							<FeedbackTile externalId={UserContext.externalId} />
						</Grid>
					</Grid>
					<Grid container direction='row' spacing={4}>
						<Grid item xs={12} md={8}>
							<ExpansionPanel>
								<ExpansionPanelSummary
									classes={{ root: classes.topTileRoot }}
									expandIcon={<ExpandMore />}
									aria-controls='documentPanelContent'
									id='documentPanelHeader'
								>
									<Grid item xs={12} className={classes.linkHeader}>
										{t(`timeAndPay:PAY_STATEMENT_TABLE.TITLE`)}
									</Grid>
								</ExpansionPanelSummary>
								<PayStatementTable
									t={t}
									classes={classes}
									payStatementDetails={payStatementDetails}
									UserContext={UserContext}
									payStatementError={payStatementError}
								/>
							</ExpansionPanel>
							<ExpansionPanel>
								<ExpansionPanelSummary
									classes={{ root: classes.topTileRoot }}
									expandIcon={<ExpandMore />}
									aria-controls='documentPanelContent'
									id='documentPanelHeader'
								>
									<Grid item xs={12} className={classes.linkHeader}>
										{t(`timeAndPay:HEADERS.YTDTAXHISTORY`)}
									</Grid>
								</ExpansionPanelSummary>
								<TaxStatementTable
									t={t}
									classes={classes}
									taxStatementDetails={taxStatementDetails}
									UserContext={UserContext}
									taxStatementError={taxStatementError}
								/>
							</ExpansionPanel>
						</Grid>
						<Grid item style={{ height: '500px' }} xs={12} md={4}>
							<Paper classes={{ root: classes.topTileRoot }}>
								<Grid container classes={{ root: classes.helpPanelContainerRoot }}>
									<Grid item xs={12} className={classes.linkHeader}>
										{t(`timeAndPay:HEADERS.YTD_EARNINGS`)}
									</Grid>
									{ytdDataError ? (
										<Grid item xs={12}>
											<h3>
												Error getting YTD Data. Please try again after some
												time.
											</h3>
										</Grid>
									) : (
										<>
											<Grid
												item
												xs={12}
												justify='flex-start'
												className={classes.DoughnutContainer}
											>
												<Doughnut
													data={ytdData}
													options={{
														cutout: '80%',
														radius: '80%',
														responsive: true,
														maintainAspectRatio: false,
														position: 'start',
														plugins: {
															legend: {
																display: true,
																position: 'right',
																align: 'start',
																labels: {
																	usePointStyle: true,
																	padding: 10,
																	font: {
																		family: 'museo-sans',
																		size: 13,
																	},
																},
															},
														},
													}}
													plugins={[
														{
															beforeDraw: function (chart) {
																var width = chart.chartArea.width,
																	height = chart.chartArea.height,
																	ctx = chart.ctx;
																ctx.restore();
																var fontSize = (
																	height / 180
																).toFixed(2);
																ctx.font =
																	fontSize + 'em museo-sans';
																ctx.textBaseline = 'top';
																var text = ytdData.text,
																	textX = Math.round(
																		width / 2 -
																			ctx.measureText(text)
																				.width /
																				2
																	),
																	textY = height / 2 - 8;
																ctx.fillStyle = COLORS.RAVEN;
																ctx.fillText(text, textX, textY);
																ctx.save();
															},
														},
													]}
												/>
											</Grid>
											<Grid
												container
												item
												justify='flex-end'
												spacing={2}
												className={classes.ButtonContainer}
											>
												<Grid
													item
													justify='flex-end'
													xs={6}
													md={5}
													lg={4}
													xl={3}
												>
													<Button
														className={classes.progressButton}
														variant='outlined'
														color='primary'
														size='small'
														onClick={() => {
															this.handleViewYTDDetails();
														}}
													>
														{t('timeAndPay:BUTTONS.VIEW_DETAILS')}
													</Button>
												</Grid>
												<Grid
													item
													justify='flex-end'
													xs={6}
													md={5}
													lg={4}
													xl={3}
												>
													<Button
														className={classes.progressButton}
														variant='outlined'
														color='primary'
														size='small'
														onClick={() => {
															if (ytdData.text !== 'No Data') {
																this.csvLink.link.click();
															}
														}}
													>
														{t('timeAndPay:BUTTONS.DOWNLOAD_YTD')}
													</Button>
													{!ytdDataError && !!ytdDataRaw ? (
														<CSVLink
															data={ytdDataRaw}
															filename={'YTD Earnings.csv'}
															target='_blank'
															className='hidden'
															ref={(r) => (this.csvLink = r)}
														/>
													) : null}
												</Grid>
											</Grid>
											<Grid item xs={12} style={{ marginTop: '-10px' }}>
												<Alert
													severity='error'
													className={classes.alertMessage}
													icon={false}
												>
													{t(`timeAndPay:INFO.YTD_DISCLAIMER`)}
												</Alert>
											</Grid>
										</>
									)}
								</Grid>
							</Paper>
							<Grid item xs={12}>
								<Paper classes={{ root: classes.topTileRoot }}>
									<ResourcesTile />
								</Paper>
							</Grid>
						</Grid>
					</Grid>
					{this.renderYTDDetailsDialog()}
				</Container>
			</div>
		);
	}
}
TimeAndPayV2.contextType = UserContext;

TimeAndPayV2.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
	}).isRequired,
};
export default WithContexts(withRouter(withTranslation()(withStyles(styles)(TimeAndPayV2))));
