import PropTypes from 'prop-types';

const menuNavigationProps = PropTypes.arrayOf(
	PropTypes.shape({
		name: PropTypes.string.isRequired,
		isParentClickable: PropTypes.bool.isRequired,
		children: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				link: PropTypes.string,
				id: PropTypes.number,
			})
		).isRequired,
		id: PropTypes.number.isRequired,
		parentUrlLink: PropTypes.string,
	})
);

export { menuNavigationProps };
