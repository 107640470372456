const certificationStatusList = [
	{
		label: 'Select',
		value: null,
	},
	{
		label: 'Board Certified',
		value: 'Board Certified',
	},
	{
		label: 'Board Eligible',
		value: 'Board Eligible',
	},
	{
		label: 'Non-Board Eligible',
		value: 'Non-Board Eligible',
	},
];

export { certificationStatusList };
