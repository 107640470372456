import React, { Component } from 'react';
import { withStyles, Grid, Paper, CircularProgress } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import WithContexts from '../../../contexts/withContexts';
import { Doughnut } from 'react-chartjs-2';

const styles = (theme) => ({
	root: {
		padding: '80px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		backgroundColor: COLORS.BLACK_HAZE,
	},
	dropdownRoot: {
		marginTop: '30px',
		padding: '30px 0',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	helpPanelRoot: {
		marginTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	dropdownContainerRoot: {
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		width: '100%',
		padding: '20px 20px',
		textAlign: 'left',
		borderRadius: '3px',
		position: 'relative',
	},
	buttonRoot: {
		lineHeight: 'inherit',
		margin: '0 30px 20px',
		padding: 0,
		width: '100%',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
	},
	helpPanelContainerRoot: {
		padding: '5px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	helpPanelHeader: {
		marginBottom: '10px',
	},
	helpPanelLinks: {
		textAlign: 'left',
		marginBottom: '10px',
	},
	helpPanelButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		paddingBottom: '10px',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
		fontWeight: '700',
	},
	header: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '20px',
		margin: 0,
	},
	linkHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
		whiteSpace: 'nowrap',
	},
	progressWrapper: {
		height: '80vh',
		alignItems: 'right',
		paddingLeft: '100px',
	},
	progressRoot: {
		marginTop: '25vh',
		align: 'center',
	},
	link: {
		color: COLORS.TRUE_BLUE,
		marginBottom: '10px',
		textDecoration: `underline dotted ${COLORS.LIGHT_BLUE}`,
		fontWeight: '400',
		fontSize: '16px',
		cursor: 'pointer',
		display: 'block',
	},
	linkDetails: {
		color: COLORS.TRUE_BLUE,
		cursor: 'pointer',
	},
	timesheetText: {
		color: 'red !important',
		fontSize: '14px',
		textAlign: 'left',
		marginTop: 5,
		marginBottom: 23,
	},
	timesheetButton: {
		color: COLORS.WHITE_LILAC,
		fontWeight: '500',
		fontSize: '20px',
		margin: 0,
	},
	topTileRoot: {
		marginTop: '2px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	topTileRootYTD: {
		marginTop: '32px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	topTileRootPST: {
		//padding: '0px'
		paddingLeft: '-10px',
	},
	nextPayDateSubheader: {
		fontSize: '14px',
		textAlign: 'left',
		marginTop: 5,
		marginBottom: 23,
	},
	nextPayDateNullSubheader: {
		fontSize: '14px',
		textAlign: 'left',
		marginTop: -8,
		marginBottom: 23,
	},
	nextPayDate: {
		fontSize: '24px',
		textAlign: 'left',
		marginBottom: 5,
		marginTop: 5,
		color: COLORS.DARK_BLUE,
		fontWeight: 'bold',
	},

	DoughnutContainer: {
		minHeight: '175px !important',
		minWidth: '175px !important',
		alignItems: 'center',
	},
	progressButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		whiteSpace: 'nowrap',
	},
	ButtonContainer: {
		marginBottom: '15px',
	},
	applyButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '150px',
		paddingBottom: '10px',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
		fontWeight: '700',
		marginTop: '20px',
	},
	expansionPanelSummaryRoot: {
		padding: '8px 20px',
	},
});

class YTDEarningsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			ytdData: null,
			ytdTotal: '',
			ytdDataRaw: [],
		};
	}
	async componentDidMount() {
		var ytdTotal = '';
		let ytdDataRaw = this.props.ytdDataRaw;
		this.setState({
			isLoading: false,
			ytdData: this.props.finalData,
			ytdTotal: ytdTotal,
			ytdDataRaw: ytdDataRaw,
		});
	}

	render() {
		const { isLoading, ytdData } = this.state;

		const { classes } = this.props;

		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}
		return (
			<>
				<Grid item container xs={12} direction='row' style={{ maxHeight: '650px' }}>
					<Grid item style={{ maxHeight: '325px' }} xs={12}>
						<Paper elevation={0} classes={{ root: classes.topTileRoot }}>
							<Grid container classes={{ root: classes.helpPanelContainerRoot }}>
								<Grid
									item
									xs={12}
									justify='flex-start'
									className={classes.DoughnutContainer}
								>
									<Doughnut
										data={ytdData}
										options={{
											cutout: '80%',
											radius: '80%',
											responsive: true,
											maintainAspectRatio: false,
											position: 'start',
											plugins: {
												legend: {
													display: false,
													position: 'right',
													align: 'start',
													labels: {
														usePointStyle: true,
														padding: 10,
														font: {
															family: 'museo-sans',
															size: 13,
														},
													},
												},
											},
										}}
										plugins={[
											{
												beforeDraw: function (chart) {
													var width = chart.chartArea.width,
														height = chart.chartArea.height,
														ctx = chart.ctx;
													ctx.restore();
													var fontSize = (height / 180).toFixed(2);
													ctx.font = fontSize + 'em museo-sans';
													ctx.textBaseline = 'top';
													var text = ytdData.text,
														textX = Math.round(
															width / 2 -
																ctx.measureText(text).width / 2
														),
														textY = height / 2 - 8;
													ctx.fillStyle = COLORS.RAVEN;
													ctx.fillText(text, textX, textY);
													ctx.save();
												},
											},
										]}
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</>
		);
	}
}
export default WithContexts(withTranslation()(withStyles(styles)(YTDEarningsComponent)));
