import React, { Component } from 'react';
import CertificationExamsForm from './CertificationExams/CertificationExamsForm';
import { reactPlugin } from '../../../../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBoundaryPage from '../../../../ContentComponents/Credentials/PageComponenets/ErrorBoundaryPage';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
	pagePadding: {
		paddingBottom: '50px',
	},
});

export class CertificationExams extends Component {
	render() {
		const {
			classes,
			activePath,
			credState,
			flags,
			setSaveHandler,
			blockedBackNavigation,
			updateFlags,
			setCredAppError,
			sectionCompletion,
			updateSectionCompletion,
			updateHighlightFieldsState,
			shouldHighlightFields,
		} = this.props;
		return (
			<AppInsightsErrorBoundary
				onError={() => (
					<ErrorBoundaryPage errorCode='500' flags={flags} updateFlags={updateFlags} />
				)}
				appInsights={reactPlugin}
			>
				<div className={classes.pagePadding}>
					<CertificationExamsForm
						setSaveHandler={setSaveHandler}
						blockedBackNavigation={blockedBackNavigation}
						activePath={activePath}
						credState={credState}
						flags={flags}
						updateFlags={updateFlags}
						sectionCompletion={sectionCompletion}
						updateSectionCompletion={updateSectionCompletion}
						updateHighlightFieldsState={updateHighlightFieldsState}
						shouldHighlightFields={shouldHighlightFields}
						setCredAppError={setCredAppError}
					/>
				</div>
			</AppInsightsErrorBoundary>
		);
	}
}

export default withStyles(styles)(CertificationExams);
