/**
 * @file this component checks the authenticated state of the user and whether to route them
 * forward or not.
 */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ClientAuthContext } from '../Contexts/ClientAuthContext';

function PrivateRoute(route) {
	const { isAuthenticated } = useContext(ClientAuthContext);
	return (
		<Route
			path={route.path}
			render={(props) =>
				isAuthenticated ? (
					<route.component {...props} routes={route.routes} mode={route.mode} />
				) : (
					<Redirect to='/auth' />
				)
			}
		/>
	);
}

export default PrivateRoute;
