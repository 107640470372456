const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const activeCreditCards = Array(9)
	.fill()
	.map((item, index) => ({
		id: index,
		account: `000${index}`,
		name: `Credit card ${index}`,
		division: `Division ${index}`,
		category: `Category ${index}`,
		type: `Type ${index}`,
		status: 'Active',
	}));

const inactiveCreditCard = {
	id: 10,
	account: `000${10}`,
	name: `Credit card ${10}`,
	division: `Division ${10}`,
	category: `Category ${10}`,
	type: `Type ${10}`,
	status: 'Inactive',
};

export const getCreditCards = async (request) => {
	try {
		await sleep(1000);
		return [...activeCreditCards, inactiveCreditCard];
	} catch (error) {
		console.error(error);
		throw error;
	}
};
