const privMap = new Map([
	[null, null],
	['New', 'In Process'],
	['Assigned', 'In Process'],
	['In Process', 'In Process'],
	['Pending', 'In Process'],
	['Granted', 'Complete'],
	['Temp Granted', 'Complete'],
	['Cancelled', null],
	['Verified', 'Complete'],
	['Not Required', 'Complete'],
	['Hold', 'In Process'],
	['Expired', null],
]);

export default privMap;
