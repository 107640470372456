/**
 * @file Specialy and skills section of the qualifications tab.
 * @TODO add proptypes
 */
import React, { Component, Fragment } from 'react';
import {
	Grid,
	FormControl,
	InputLabel,
	withStyles,
	Button,
	Checkbox,
	FormHelperText,
	NativeSelect,
	Chip,
	TextField,
	Collapse,
	IconButton,
	InputAdornment,
	ThemeProvider,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withTranslation } from 'react-i18next';
import { certificationStatusList } from './specialtyOptions';
import TextFieldComponent from '../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { COLORS, ACTIVE_PATHNAME_MD } from '../../../utils/Application_Constants';
import DatePickerComponent from '../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import UtcUtils from '../../GlobalComponents/UtcUtils/UtcUtils';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { Event } from '@material-ui/icons';

const styles = (theme) => ({
	header: {
		color: COLORS.ST_TROPAZ,
		[theme.breakpoints.down('sm')]: {
			padding: '36px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '36px',
		fontSize: '17px',
		fontWeight: '700',
	},
	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		whiteSpace: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			margin: '-10px 0',
		},
		textAlign: 'left',
	},
	buttonFont: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		textTransform: 'capitalize',
	},
	addAnotherSpecialityMargin: {
		[theme.breakpoints.up('md')]: {
			marginTop: '25px',
		},
	},
	colorRemove: {
		color: COLORS.MILANO_RED,
	},
	removeButtonGrid: {
		alignContent: 'center',
		margin: '10px 8px',
		display: 'flex',
		outline: `1px solid ${COLORS.WHITE_LILAC}`,
		justifyContent: 'center',
	},
	checkboxWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginRight: '9px',
	},
	checkBoxFont: {
		color: COLORS.RAVEN,
		fontWeight: '300',
		fontSize: '0.875rem',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			padding: '0 20px 0 32px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 5px',
			textAlign: 'left',
		},
	},
	dateRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			padding: '0 32px 0 0',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 5px',
			textAlign: 'left',
		},
	},
	calendarAlign: {
		[theme.breakpoints.up('md')]: {
			padding: '0 10px 0 0',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 0 20px 0',
		},
	},
	setWidthTo100: {
		width: '100%',
		marginTop: '5px',
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		[theme.breakpoints.up('md')]: {
			padding: '5px 5px',
		},
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.WHITE_LILAC,
		},
	},
	containerRoot: {
		margin: '10px auto',
		width: '90%',
	},
	disabledInput: {
		opacity: '0.6',
	},
	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '16px',
		padding: '0 32px',
	},
});

class SpecialtyAndSkillsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			containsAnExisitingSpecialty: false,
		};
	}

	specialtyConstructor = () => ({
		specialtyName: '',
		isActivelyPracticing: false,
		startedPracticing: null,
		lastPracticed: null,
		skills: null,
		isNew: true,
	});

	handleToastOpen = () => {
		this.setState({ containsAnExisitingSpecialty: true });
	};

	handleToastClose = () => {
		this.setState({ containsAnExisitingSpecialty: false });
	};

	changeHandler = (event, index) => {
		const { specialties, specialtiesUpdateHandler, setDirtyFlag, shouldDisableSave } =
			this.props;
		const { name, value } = event.target;
		setDirtyFlag();
		if (name === 'specialtyName') {
			if (!this.doesSpecialtyExist(value)) {
				this.handleToastClose();
				specialties[index][name] = value;
			} else {
				this.handleToastOpen();
				specialties[index][name] = '';
			}
		} else {
			this.handleToastClose();
			specialties[index][name] = value;
		}
		shouldDisableSave(index);
		specialtiesUpdateHandler(specialties);
	};

	skillsForSpecialty = (specialty) => {
		const { skillMappingList } = this.props;
		let ret = [];
		if (skillMappingList !== null && skillMappingList !== undefined) {
			ret = skillMappingList[specialty];
		}
		if (ret === null || ret === undefined) ret = [];
		return ret;
	};

	addSpecialty = () => {
		const { specialties, specialtiesUpdateHandler, setDirtyFlag } = this.props;
		specialties.push(this.specialtyConstructor());
		setDirtyFlag();
		specialtiesUpdateHandler(specialties);
	};

	doesSpecialtyExist = (value) => {
		const { specialties } = this.props;
		let exists = false;
		specialties.forEach((item) => {
			if (value === item.specialtyName) {
				exists = true;
			}
		});
		return exists;
	};

	renderAddSpecialtyButton = () => {
		const { classes } = this.props;
		return (
			<Grid item classes={{ root: classes.gridRoot }} xs={12} md={10}>
				<Button
					component='span'
					classes={{ text: classes.buttonFont, root: classes.addAnotherSpecialityMargin }}
					onClick={this.addSpecialty}
				>
					+ Add Another Specialty
				</Button>
			</Grid>
		);
	};

	renderRemoveSpecialtyButton = (index, item) => {
		const { classes } = this.props;
		if (!item.isNew) {
			return null;
		}
		return (
			<Grid item xs={12} classes={{ root: classes.removeButtonGrid }}>
				<Button
					component='span'
					classes={{ text: `${classes.buttonFont} ${classes.colorRemove}` }}
					onClick={() => this.removeSpecialty(index)}
				>
					-&nbsp;Remove Specialty
				</Button>
			</Grid>
		);
	};

	renderMissingFieldsMessage = (index, item) => {
		const { t, classes, activePath } = this.props;
		const missingFields = [];

		if (activePath !== ACTIVE_PATHNAME_MD) {
			if (item.specialtyName === '' || item.specialtyName === 'Select') {
				missingFields.push('Specialty');
			}
			if (item.isActivelyPracticing === true && item.startedPracticing === null) {
				missingFields.push('Started Practicing');
			}
			if (
				item.startedPracticing !== null &&
				(item.isActivelyPracticing === null || item.isActivelyPracticing === false) &&
				item.lastPracticed === null
			) {
				missingFields.push('Actively Practicing or Last Practiced');
			}
			if (item.lastPracticed !== null && item.startedPracticing === null) {
				missingFields.push('Started Practicing');
			}
		}

		if (missingFields.length > 0) {
			return (
				<FormHelperText className={classes.cssLabel}>
					{t('profile:QUALIFICATIONS.MISSING_FIELDS') + missingFields.toString()}
				</FormHelperText>
			);
		} else {
			return null;
		}
	};

	removeSpecialty = (index) => {
		const { specialties, specialtiesUpdateHandler, setDirtyFlag, shouldDisableSave } =
			this.props;
		specialties.splice(index, 1);
		setDirtyFlag();
		shouldDisableSave(index);
		specialtiesUpdateHandler(specialties);
	};

	render() {
		const {
			classes,
			specialties,
			specialtyList,
			t,
			checkboxHandler,
			autoCompleteHandler,
			autoCompleteDelete,
			clearTags,
			activePath,
			specialtiesDateChangeHandler,
			datePickerHelper,
			flags,
		} = this.props;
		const { containsAnExisitingSpecialty } = this.state;
		return (
			<>
				<Grid item xs={12}>
					<h3 className={classes.header}>Specialties & Skills</h3>
				</Grid>
				<Grid container spacing={2}>
					{specialties.map((item, index) => {
						if (!item.certificationStatus) {
							item.certificationStatus = null;
						}
						return (
							<Fragment key={`specialty-${index + 1}`}>
								<Grid item classes={{ root: classes.gridRoot }} xs={12}>
									<Grid item classes={{ root: classes.gridRoot }} xs={12} md={6}>
										<FormControl classes={{ root: classes.setWidthTo100 }}>
											<InputLabel
												shrink
												htmlFor='Specialty'
												classes={{ root: classes.inputLabel }}
											>
												{t('profile:QUALIFICATIONS.SPECIALTY')}
											</InputLabel>
											<NativeSelect
												id='Specialty'
												onChange={(event) => {
													this.changeHandler(event, index);
													clearTags(index);
												}}
												disabled={!item.isNew}
												name='specialtyName'
												onFocus={this.handleToastClose}
												value={item.specialtyName || ''}
												classes={{
													root: classes.setWidthTo100,
													disabled: classes.disabledInput,
												}}
												input={
													<TextFieldComponent
														classes={{
															disabled: classes.disabledInput,
														}}
													/>
												}
											>
												{specialtyList.map((specialtyItem) => (
													<option
														key={specialtyItem.label}
														value={specialtyItem.value}
													>
														{specialtyItem.label}
													</option>
												))}
											</NativeSelect>
										</FormControl>
									</Grid>
									{activePath === ACTIVE_PATHNAME_MD ? (
										<Grid
											item
											classes={{ root: classes.gridRoot }}
											xs={12}
											md={4}
										>
											<FormControl classes={{ root: classes.setWidthTo100 }}>
												<InputLabel
													shrink
													htmlFor='CertificationStatus'
													classes={{ root: classes.inputLabel }}
												>
													{t(
														'profile:QUALIFICATIONS.CERTIFICATION_STATUS'
													)}
												</InputLabel>
												<NativeSelect
													id='CertificationStatus'
													onChange={(event) => {
														this.changeHandler(event, index);
													}}
													name='certificationStatus'
													disabled={!item.isNew}
													value={item.certificationStatus || ''}
													classes={{ root: classes.setWidthTo100 }}
													input={
														<TextFieldComponent
															classes={{
																disabled: classes.disabledInput,
															}}
														/>
													}
												>
													{certificationStatusList.map((item) => (
														<option key={item.label} value={item.value}>
															{item.label}
														</option>
													))}
												</NativeSelect>
											</FormControl>
										</Grid>
									) : (
										<Grid item classes={{ root: classes.dateRoot }} xs={12}>
											<Grid
												item
												classes={{ root: classes.calendarAlign }}
												xs={12}
												md={6}
											>
												<ThemeProvider theme={datePickerTheme}>
													<MuiPickersUtilsProvider
														utils={UtcUtils}
														moment={moment}
													>
														<FormControl
															classes={{
																root: classes.setWidthTo100,
															}}
														>
															<InputLabel
																shrink
																htmlFor='StartedPracticing'
																classes={{
																	root: classes.inputLabel,
																}}
															>
																{t(
																	'profile:QUALIFICATIONS.STARTED_PRACTICING'
																)}
															</InputLabel>
															<DatePickerComponent
																theme={datePickerTheme}
																variant='inline'
																autoOk='true'
																format='MM/DD/YYYY'
																placeholder='MM/DD/YYYY'
																name='startedPracticing'
																margin='dense'
																InputProps={{
																	endAdornment: (
																		<InputAdornment position='end'>
																			<Event />
																		</InputAdornment>
																	),
																	classes: {
																		root: classes.cssOutlinedInput,
																	},
																}}
																id='StartedPracticing'
																value={datePickerHelper(
																	item.startedPracticing
																)}
																onChange={(date) => {
																	specialtiesDateChangeHandler(
																		date,
																		index,
																		'startedPracticing'
																	);
																}}
																KeyboardButtonProps={{
																	'aria-label':
																		'Started Practicing',
																}}
																inputVariant='outlined'
															/>
														</FormControl>
													</MuiPickersUtilsProvider>
												</ThemeProvider>
											</Grid>
											{!item.isActivelyPracticing && (
												<Grid xs={12} md={6}>
													<ThemeProvider theme={datePickerTheme}>
														<MuiPickersUtilsProvider
															utils={UtcUtils}
															moment={moment}
														>
															<FormControl
																classes={{
																	root: classes.setWidthTo100,
																}}
															>
																<InputLabel
																	shrink
																	htmlFor='LastPracticed'
																	classes={{
																		root: classes.inputLabel,
																	}}
																>
																	{t(
																		'profile:QUALIFICATIONS.LAST_PRACTICED'
																	)}
																</InputLabel>
																<DatePickerComponent
																	theme={datePickerTheme}
																	variant='inline'
																	autoOk='true'
																	format='MM/DD/YYYY'
																	placeholder='MM/DD/YYYY'
																	name='lastPracticed'
																	margin='dense'
																	InputProps={{
																		endAdornment: (
																			<InputAdornment position='end'>
																				<Event />
																			</InputAdornment>
																		),
																		classes: {
																			root: classes.cssOutlinedInput,
																		},
																	}}
																	id='LastPracticed'
																	value={datePickerHelper(
																		item.lastPracticed
																	)}
																	onChange={(date) => {
																		specialtiesDateChangeHandler(
																			date,
																			index,
																			'lastPracticed'
																		);
																	}}
																	KeyboardButtonProps={{
																		'aria-label':
																			'Last Practiced',
																	}}
																	inputVariant='outlined'
																/>
															</FormControl>
														</MuiPickersUtilsProvider>
													</ThemeProvider>
												</Grid>
											)}
										</Grid>
									)}
								</Grid>
								<Grid item classes={{ root: classes.gridRoot }} xs={12}>
									<Grid item classes={{ root: classes.gridRoot }} xs={12} md={10}>
										<FormControl classes={{ root: classes.setWidthTo100 }}>
											<InputLabel
												shrink
												htmlFor={`skills-${index}`}
												classes={{ root: classes.inputLabel }}
											>
												{t('profile:QUALIFICATIONS.SKILLS')}
											</InputLabel>
											<Autocomplete
												multiple
												options={this.skillsForSpecialty(
													item.specialtyName
												)}
												getOptionLabel={(option) => option.label}
												id={`skills-${index}`}
												classes={{ root: classes.setWidthTo100 }}
												onChange={(event, newValue) =>
													autoCompleteHandler(newValue, 'skills', index)
												}
												value={item.skills || []}
												required
												renderTags={(value, { className }) =>
													value.map((option, tagIndex) => (
														<Chip
															key={tagIndex}
															variant='outlined'
															classes={{
																root: classes.chipRoot,
																label: classes.chipLabel,
															}}
															data-tag-index={tagIndex}
															tabIndex={-1}
															label={option}
															className={className}
															onDelete={() =>
																autoCompleteDelete(
																	tagIndex,
																	'skills',
																	index
																)
															}
														/>
													))
												}
												renderInput={(params) => (
													<TextField
														{...params}
														classes={{ root: classes.typeAheadInput }}
														variant='standard'
														margin='normal'
														fullWidth
													/>
												)}
											/>
											{!item.lastPracticed && (
												<div className={classes.checkboxWrapper}>
													<Checkbox
														color='primary'
														name='isActivelyPracticing'
														onClick={(event) => {
															this.changeHandler(event, index);
														}}
														checked={item.isActivelyPracticing}
														onChange={(event) => {
															checkboxHandler(event, index);
														}}
													/>
													<span className={classes.checkBoxFont}>
														{t(
															'profile:QUALIFICATIONS.ACTIVELY_PARTICING'
														)}
													</span>
												</div>
											)}
										</FormControl>
									</Grid>
								</Grid>
								{flags.missingData
									? this.renderMissingFieldsMessage(index, item)
									: null}
								{specialties.length > 1
									? this.renderRemoveSpecialtyButton(index, item)
									: null}
							</Fragment>
						);
					})}
				</Grid>
				<Grid item classes={{ root: classes.gridRoot }} xs={12}>
					{this.renderAddSpecialtyButton()}
				</Grid>
				<Collapse
					classes={{ container: classes.containerRoot }}
					in={containsAnExisitingSpecialty}
				>
					<Alert
						severity='warning'
						action={
							<IconButton
								aria-label='close'
								color='inherit'
								size='small'
								onClick={() => {
									this.handleToastClose();
								}}
							>
								<Close fontSize='inherit' />
							</IconButton>
						}
					>
						{t('profile:QUALIFICATIONS.SPECIALTY_ERROR')}
					</Alert>
				</Collapse>
			</>
		);
	}
}
export default withTranslation()(withStyles(styles)(SpecialtyAndSkillsComponent));
