import React, { Component } from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../utils/Application_Constants';

const styles = (theme) => ({
	contentRoot: {
		height: '100vh',
	},
	dialogHeaderRoot: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE_LILAC,
		justifyContent: 'left',
	},
	dialogContentRoot: {
		padding: '0',
		margin: '24px',
		borderRadius: '5px',
		color: `${COLORS.RAVEN}`,
	},
	buttonRoot: {
		lineHeight: 'inherit',
		color: `${COLORS.TRUE_BLUE}`,
		backgroundColor: `${COLORS.WHITE}`,
		border: `1px solid ${COLORS.TRUE_BLUE}`,
		margin: '0 15px 15px',
		width: '200px',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	timeOutHeader: {
		fontWeight: '500',
		opacity: '0.5',
	},
});

class TimedOutPage extends Component {
	render() {
		const { classes, t } = this.props;
		return (
			<div className={classes.contentRoot}>
				<Dialog open fullWidth scroll='body'>
					<DialogTitle classes={{ root: classes.dialogHeaderRoot }}>
						{t('error:TIMED_OUT.HEADER')}
					</DialogTitle>
					<DialogContent classes={{ root: classes.dialogContentRoot }}>
						<h3>{t('error:TIMED_OUT.INNER_MESSAGE')}</h3>
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(TimedOutPage));
