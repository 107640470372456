import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
	withStyles,
	CircularProgress,
	Grid
} from '@material-ui/core';
import DidNotWorkIcon from '@material-ui/icons/PriorityHigh';
import { COLORS } from '../../utils/Application_Constants';
import React from 'react';
import moment from 'moment';
import { TimesheetStatusEnumApi } from '../../components/ContentComponents/Timesheets/Status.enum';

const styles = (theme) => ({
	dialogPaperRoot: {
		padding: '40px',
		gap: '16px',
	},
	dialogContentRoot: {
		padding: '0',
		'&:first-child': {
			paddingTop: '0',
		},textAlign: 'center',
	},
	dialogActionsRoot: {
		paddingTop: '40px',
		justifyContent: 'center'
	},
	body1: {
		lineHeight: '20px',
	},
	dialogWarningIcon: {
		color: COLORS.LT_SUNFLOWER,
		background: COLORS.LT_SUNFLOWER25,
		borderRadius: '50%',
		padding: '16px',
	}
});

const DidNotWorkDialog = (props) => {
	const { classes, onClose, onExited, handleAction, open, name, timesheetsList, isLoading, timesheet } = props;
	const timesheets = (timesheetsList && timesheetsList.length && timesheetsList.length == 1) ? timesheetsList[0].timesheets : [];
	const weekEnding = (timesheetsList && timesheetsList.length && timesheetsList.length == 1) ? timesheetsList[0].weekEnding : '';
	const notStartedCount  = timesheets.filter (({status}) => status === TimesheetStatusEnumApi.NotStarted).length;
	const pendingSubmissionCount  = timesheets.filter (({status}) => status === TimesheetStatusEnumApi.PendingSubmission || status === TimesheetStatusEnumApi.DidNotWork).length;
	let mode = 'Cancel';//AllNotStarted	PendingExists Cancel
	if (notStartedCount === timesheets.length){
		mode = 'AllNotStarted';
	} else if ((pendingSubmissionCount + notStartedCount)  === timesheets.length){
		mode = 'PendingExists';
	}else {
		mode = 'Cancel'
	}

	let message1 = '';
	let message2 = '';
	let formattedWeekEnding = '';
	if (moment(weekEnding, "YYYY-MM-DD", true).isValid()){
		formattedWeekEnding = moment(weekEnding).format('MMMM Do');
	}

	switch (mode) {
		case 'AllNotStarted':
			message2 = `Are you sure you would like to mark the week ending ${formattedWeekEnding} as Not Worked for all locations?`;
			break;
		case 'PendingExists':
			message1 = 'You have entered time!';
			message2 = `Are you sure you would like to mark the week ending ${formattedWeekEnding} as Not Worked for all locations?`;
			break;
		case 'Cancel':
			message2 = `All locations must be in a Not Started or Pending Submission status to mark this timesheet as Not Worked! Please Cancel Not Worked action for the week ending ${formattedWeekEnding}.`;
			break;
	}

	const handleClose = () => {
		onClose();
	};

	const renderDialogContent = () => (
		<>
			<DialogContent classes={{ root: classes.dialogContentRoot }}>
				<DidNotWorkIcon  fontSize='large' className={classes.dialogWarningIcon} />
				<Typography variant='body1' style={{ 
					color: COLORS.LT_JOURNEY
				}}>
					{message1}
				</Typography>
				<Typography variant='body1' color='textPrimary'>
					{message2}
				</Typography>
				<Typography variant='body1' color='textPrimary' style={{ 
					paddingTop: '20px',
					gap: '16px',
					textAlign: 'left'
				}} >
					{
						timesheets.map((timesheet) => {
							return (
								<Grid container direction={'row'}  spacing={5} style={{width: '100%'}} >
									<Grid item xs={12} md={6} >
											<Typography
												variant='body2'
											>
												<b>Location: </b>{timesheet.location}
											</Typography>

									</Grid>
									<Grid item xs={12} md={6} >
										<Typography
												variant='body2'
											>
												<b>Status: </b>{timesheet.status}
											</Typography>
									</Grid>
								</Grid>

							);
							}
						)
					}
				</Typography>
				<DialogActions classes={{ root: classes.dialogActionsRoot }}>
					<Button color='primary' onClick={handleClose}>
						Cancel
					</Button>
					<Button
						color='primary'
						variant='contained'
						disabled={mode === 'Cancel'}
						onClick={() => {
							handleAction(timesheet);
						}}
					>
						Confirm
					</Button>
				</DialogActions>
			</DialogContent>
		</>
	);

	return (
		<form>
			<Dialog
				open={open}
				onClose={handleClose}
				TransitionProps={{ onExited }}
				aria-labelledby={`${name}-dialog-title`}
				aria-describedby={`${name}-dialog-description`}
				fullWidth
				maxWidth='xs'
				classes={{ paper: classes.dialogPaperRoot }}
			>
				{!isLoading && timesheetsList && timesheet ? (
					renderDialogContent()
				) : (
					<CircularProgress color='primary' />
				)}
			</Dialog>
		</form>
	);
};

export default withStyles(styles)(DidNotWorkDialog);
