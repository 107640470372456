import React from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import { ltdomain } from '../../../utils/helpers';

const styles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
	},
}));

function SalarySurveyTile({ t }) {
	const classes = styles();

	return (
		<>
			<Paper className={classes.paper}>
				<Grid item xs={12} md={12}>
					<h3>{t(`dashboard:HEADERS.SALARY_SURVEY`)}</h3>
					<p>{t(`dashboard:INFO.SALARY_SURVEY_TEXT`)}</p>
					<a
						className='textLink'
						target='_Self'
						href={ltdomain() + '/resource-center/salary-survey-reports/'}
					>
						{t(`dashboard:BUTTONS.SALARY_SURVEY`)}
					</a>
				</Grid>
			</Paper>
		</>
	);
}

export default SalarySurveyTile;
