import React, { useState, useEffect } from 'react';
import {
	Grid,
	Paper,
	Button,
	FormGroup,
	FormControlLabel,
	TableHead,
	TableRow,
	TableCell,
	Checkbox,
	Toolbar,
	TableContainer,
	Table,
	TableBody,
	CircularProgress,
} from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import {
	getClinicianTaxStatementPdf,
	getClinicianTaxStatementsZip,
} from '../../../services/TimeAndPayService';

const styles = (theme) => ({
	root: {
		padding: '80px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		backgroundColor: COLORS.BLACK_HAZE,
	},
	helpPanelRoot: {
		marginTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	buttonRoot: {
		lineHeight: 'inherit',
		margin: '0 30px 20px',
		padding: 0,
		width: '100%',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
	},
	helpPanelContainerRootTable: {
		padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '30px',
	},
	helpPanelHeader: {
		marginBottom: '10px',
	},
	helpPanelLinks: {
		textAlign: 'left',
		marginBottom: '10px',
	},
	header: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '20px',
		margin: 0,
	},
	linkHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
	},
	progressWrapper: {
		height: '80vh',
	},
	progressWrapperLoading: {
		height: '1vh',
	},
	progressRoot: {
		marginTop: '25vh',
	},
	topTileRoot: {
		marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	headerStatementTile: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			padding: '0 32px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '5px 10px',
		},
	},
	applyButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '150px',
		paddingBottom: '10px',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
		fontWeight: '700',
		marginTop: '20px',
	},
	table: {
		minWidth: '100%',
	},
	link: {
		color: COLORS.TRUE_BLUE,
		marginBottom: '10px',
		textDecoration: `underline dotted ${COLORS.LIGHT_BLUE}`,
		fontWeight: '400',
		fontSize: '16px',
		cursor: 'pointer',
		display: 'block',
	},
	disableLinkDetails: {
		color: COLORS.TRUE_BLUE,
		textDecoration: `underline dotted ${COLORS.LIGHT_BLUE}`,
		fontWeight: '400',
		fontSize: '16px',
		display: 'block',
		opacity: 0.4,
		cursor: 'default',
	},
	linkDetails: {
		color: COLORS.TRUE_BLUE,
		textDecoration: `underline dotted ${COLORS.LIGHT_BLUE}`,
		fontWeight: '400',
		fontSize: '16px',
		cursor: 'pointer',
		display: 'block',
	},
	topTileTable: {
		marginTop: '30px',
		height: '95%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	helpPanelContainerRoot: {
		padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	mainLoader: {
		position: 'absolute',
		paddingTop: '15%',
		width: '100%',
		height: '100%',
		zIndex: 1000,
		backgroundColor: '#000000',
		opacity: 0.1,
		textAlign: 'center',
	},
});

function TaxStatementTable({ t, classes, taxStatementDetails, UserContext, taxStatementError }) {
	const headCells = [
		{ id: 'date', numeric: false, disablePadding: false, label: 'YEAR', fontWeight: 'bold' },
		{ id: 'amount', numeric: true, disablePadding: false, label: 'TYPE', fontWeight: 'bold' },
		{ id: 'details', numeric: false, disablePadding: false, label: '' },
	];

	const [page] = useState(0);
	const [taxTableError, setTaxTableError] = useState(false);
	const [rowsPerPage] = useState(4);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [isChecked, setIsChecked] = useState(
		!taxStatementError ? taxStatementDetails.slice().fill(false) : false
	);
	const [selectedRows, setSelectedRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [taxDetails, setTaxDetails] = useState({});

	useEffect(() => {
		(async function setData() {
			setTaxDetails(taxStatementDetails);
		})();
		setIsLoading(false);
	}, [taxStatementDetails]);

	const handleChangeCheckbox = (page, rowsPerPage, row, index) => {
		const adjustedIndex = page * rowsPerPage + index;
		setIsChecked(isChecked.map((value, i) => (i === adjustedIndex ? !value : value)));
		if (isChecked[adjustedIndex] === false) {
			row['adjustedIndex'] = adjustedIndex;
			selectedRows.push(row);
		}
		if (isChecked[adjustedIndex] === true) {
			if (isCheckedAll) {
				setIsCheckedAll(false);
			}
			if (!row.adjustedIndex) {
				row['adjustedIndex'] = adjustedIndex;
			}
			const findIndex = selectedRows.findIndex((obj) => obj.adjustedIndex === adjustedIndex);
			selectedRows.splice(findIndex, 1);
		}
		setSelectedRows(selectedRows);
	};

	const handleSelectAll = (event) => {
		setIsCheckedAll(event.target.checked);
		setIsChecked(isChecked.map((item) => (item = event.target.checked)));

		if (isCheckedAll === false) {
			selectedRows.splice(0, selectedRows.length);
			taxDetails.map((item) => selectedRows.push(item));
		}
		if (isCheckedAll === true) {
			selectedRows.splice(0, selectedRows.length);
		}
		setSelectedRows(selectedRows);
	};

	const isRowChecked = (item) => {
		return selectedRows.includes(item) ? true : false;
	};

	async function handleDowloadSelected(externalId, selectedRows) {
		setIsLoading(true);
		setTaxTableError(false);
		await getClinicianTaxStatementsZip(externalId, selectedRows).catch((e) => {
			setTaxTableError(true);
			setIsLoading(false);
		});
		setIsLoading(false);
	}
	function renderTaxTable() {
		if (isLoading) {
			return (
				<div
					className={isLoading ? classes.progressWrapperLoading : classes.progressWrapper}
				>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}

		if (!!taxDetails) {
			// const numberFormatter = new Intl.NumberFormat('en-US', {
			// 	style: 'currency',
			// 	currency: 'USD',
			// });
			const taxData = taxDetails;
			const numberofRecords = Object.keys(taxData).length;

			const emptyRows =
				rowsPerPage - Math.min(rowsPerPage, numberofRecords - page * rowsPerPage);

			return (
				<>
					<Toolbar>
						<TableContainer component={Paper}>
							<Table classes={{ root: classes.table }}>
								<TableHead>
									<TableRow>
										<TableCell padding='checkbox'>
											<Checkbox
												indeterminate={false}
												color='primary'
												checked={isCheckedAll}
												onChange={handleSelectAll}
												inputProps={{ 'aria-label': 'select all' }}
											/>
										</TableCell>
										{headCells.map((headCell) => (
											<TableCell
												key={headCell.id}
												align={'left'}
												padding={
													headCell.disablePadding ? 'none' : 'default'
												}
											>
												{headCell.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{taxData.map((row, index) => (
										<TableRow key={row.id}>
											<TableCell align='center'>
												<FormGroup>
													<FormControlLabel
														control={
															<Checkbox
																color='primary'
																checked={
																	isChecked[
																		page * rowsPerPage + index
																	]
																}
																onChange={() =>
																	handleChangeCheckbox(
																		page,
																		rowsPerPage,
																		row,
																		index
																	)
																}
																inputProps={{
																	'aria-label': 'select',
																}}
															/>
														}
													/>
												</FormGroup>
											</TableCell>
											{!row.corrected && (
												<TableCell align='left'>{row.year}</TableCell>
											)}
											{!!row.corrected && (
												<TableCell align='left'>
													{row.year}-Corrected
												</TableCell>
											)}
											<TableCell align='left'>{row.statementType}</TableCell>
											<TableCell
												align='left'
												className={
													isRowChecked(row)
														? classes.linkDetails
														: classes.disableLinkDetails
												}
												onClick={async () => {
													setTaxTableError(false);
													setIsLoading(true);
													await getClinicianTaxStatementPdf(
														UserContext.externalId,
														row.externalId,
														row.year,
														row.statementType,
														row.corrected
													).catch((e) => {
														setTaxTableError(true);
														setIsLoading(false);
													});
													setIsLoading(false);
												}}
											>
												Download Statement
											</TableCell>
										</TableRow>
									))}
									{emptyRows > 0 && (
										<TableRow style={{ height: 1 * emptyRows }}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							</Table>
							{/* <TablePagination
                            count={numberofRecords}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            rowsPerPageOptions={[]}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /> */}
						</TableContainer>
					</Toolbar>
				</>
			);
		}
	}

	return (
		<>
			<Paper classes={{ root: classes.topTileRoot }}>
				<Grid container classes={{ root: classes.helpPanelContainerRoot }} spacing={2}>
					<Grid item xs={12} md={4}>
						<Button
							className={classes.helpPanelButton}
							disabled={selectedRows.length === 0}
							color='primary'
							variant='outlined'
							onClick={async () => {
								await handleDowloadSelected(UserContext.externalId, selectedRows);
							}}
						>
							{t('timeAndPay:PAY_STATEMENT_TABLE.DOWNLOAD_SELECTED')}
						</Button>
					</Grid>
				</Grid>

				<Grid container spacing={3} classes={{ root: classes.helpPanelContainerRoot }}>
					<Grid item xs={12}>
						{renderTaxTable()}
					</Grid>
				</Grid>
				{taxStatementError || taxTableError ? (
					<Grid container spacing={3} classes={{ root: classes.helpPanelContainerRoot }}>
						<Grid item xs={12}>
							<h3>
								Error Occurred while fetching/downloading tax data. Please try after
								some time.
							</h3>
						</Grid>
					</Grid>
				) : null}
				<Grid container classes={{ root: classes.helpPanelContainerRoot }} spacing={2}>
					<Grid item xs={12} md={4}>
						<Button
							className={classes.helpPanelButton}
							disabled={selectedRows.length === 0}
							color='primary'
							variant='outlined'
							onClick={async () => {
								await handleDowloadSelected(UserContext.externalId, selectedRows);
							}}
						>
							{t('timeAndPay:PAY_STATEMENT_TABLE.DOWNLOAD_SELECTED')}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
}

export default TaxStatementTable;
