/**
 * @file this component checks the authenticated state of the user and whether to route them
 * forward or not.
 */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserContext from '../contexts/userContext';

function PrivateRoute(route) {
	const user = useContext(UserContext);
	if (user.account) {
		return (
			<Route
				path={route.path}
				render={(props) => (
					// pass the sub-routes down to keep nesting
					<route.component {...props} routes={route.routes} mode={route.mode} setRefreshNotifications={route.setRefreshNotifications}/>
				)}
			/>
		);
	}
	return <Redirect to={{ pathname: process.env.REACT_APP_LOGIN_URL }} />;
}

export default PrivateRoute;
