import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Grid, Paper, Typography, withStyles, Button } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';
import WithContexts from '../../contexts/withContexts';
import { COLORS } from '../../utils/Application_Constants';

const styles = (theme) => ({
	root: {
		background: COLORS.LT_MIDNIGHTBG1,
		minHeight: '600px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	ContainerRoot: {
		paddingTop: '2.5rem',
		paddingBottom: '2.5rem',
	},
	GridRoot: {
		minHeight: '300px',
	},
	doneIcon: {
		color: COLORS.LT_JOURNEY,
		background: COLORS.LT_JOURNEY10,
		borderRadius: '50%',
		padding: '16px',
	},
});

function TimesheetSave({ classes, t }) {
	return (
		<div className={classes.root}>
			<Container maxWidth='lg' className={classes.ContainerRoot}>
				<Grid container justifyContent='center' alignItems='center'>
					<Grid item xs={10} sm={8} md={4}>
						<Paper elevation={3}>
							<Grid
								container
								direction='column'
								spacing={2}
								justifyContent='center'
								alignItems='center'
								className={classes.GridRoot}
							>
								<Grid item>
									<DoneIcon
										fontSize='large'
										className={classes.doneIcon}
									></DoneIcon>
								</Grid>
								<Grid item>
									<Typography variant='h5'>
										{t('submitTimesheet:HEADERS.TIMESHEET_SAVED')}
									</Typography>
								</Grid>
								<Grid item>
									<Link to='/timesheets'>
										<Button variant='contained' color='primary'>
											{t('submitTimesheet:BUTTONS.DONE')}
										</Button>
									</Link>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(TimesheetSave))));
