import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {
	CircularProgress,
	FormControlLabel,
	NativeSelect,
	Radio,
	RadioGroup,
	ThemeProvider,
	InputAdornment,
} from '@material-ui/core';
import { COLORS, CRED_STATE, DOCUMENT_TYPE } from '../../../../../utils/Application_Constants';
import DatePickerComponent from '../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import TextFieldComponent from '../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	getMalpracticeClaimHistories,
	postMalpracticeClaimHistories,
	deleteMalpracticeClaimHistories,
} from '../../../../../services/Credentialing';
import Input from '@material-ui/core/Input';
import DateFnsUtils from '@date-io/date-fns';
import { convertToDateIgnoringTime } from '../../../../../utils/helpers';
import { statesForLicense } from '../../../Account/accountOptions';
import createTheme from '@material-ui/core/styles/createTheme';
import UploadClinicianDocument from '../DocumentComponents/UploadClinicianDocument';
import UploadClinicianDocumentV2 from '../DocumentComponents/UploadClinicianDocumentV2';
import HelpIcon from '@material-ui/icons/Help';
import ToolTipComponent from '../../../../GlobalComponents/ToolTipComponent';
import {
	malpracticeClaimsHistorySectionPointsCheck,
	malpracticeClaimsHistorySectionCheck,
} from '../../../../../utils/CredentialingAppUtility';
import Alert from '@material-ui/lab/Alert';
import RequiredQuestionDialog from '../../../../GlobalComponents/Dialog/RequiredQuestionDialog';
import FeatureFlag from '../../../../GlobalComponents/FeatureFlagComponent/FeatureFlag';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '10px',
		paddingBottom: '10px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
	},
	secondaryHeaderArea: {
		alignItems: 'center',
		maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '46px',
		marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	UploaderHeaderArea: {
		alignItems: 'center',
		maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '0px',
		marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	form: {
		width: '100%',
		margin: theme.spacing(1, 0, 5),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	// inputLabel: {
	//     top: '-5px',
	//     position: 'relative',
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	//     marginTop: theme.spacing(-1),
	// },
	// selectInputLabel: {
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	// },
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: `${COLORS.RAVEN} `,
			borderWidth: 1,
		},
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
	},
	cssFocused: {},

	// malpracticeSummaryInput: {
	//     '&$cssFocused $notchedOutline': {
	//         borderColor: `${COLORS.RAVEN}`,
	//         borderWidth: 1
	//     },
	//     height: 100,
	//     fontSize: '16px',
	//     color: COLORS.RAVEN,
	//     paddingRight: '0px',
	//     paddingTop: '25px',
	//     paddingBottom: '5px',
	//     paddingLeft: '10px',
	//     '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //default
	//     },
	//     '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
	//         borderColor: '#E7E7E7' //hovered
	//     }
	// },
	disabled: {},
	disabledInput: {
		opacity: 0.6,
	},
	disabledSelect: {
		opacity: 0.6,
		cursor: 'default',
	},
	focused: {},
	marginLeft12: {
		marginLeft: 12,
	},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	noTopPad: {
		paddingTop: '0px !important',
	},
	// textNextToDate: {

	//     [theme.breakpoints.up('md')]: {
	//         paddingTop: '30px !important',
	//     }, [theme.breakpoints.down('sm')]: {
	//         paddingLeft: '30px !important',
	//         marginBottom: '10px'
	//     },
	// },
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	radioGroup: {
		marginLeft: '8px',
	},
	// radioGroupLabel: {

	//     [theme.breakpoints.up('md')]: {
	//         marginTop: '15px',
	//     }, [theme.breakpoints.down('sm')]: {
	//         marginTop: '45px'
	//     },
	// },
	// inputRadio: {
	//     color: COLORS.RAVEN,
	//     '&.Mui-focused': {
	//         color: COLORS.RAVEN
	//     },

	//     fontWeight: '900',
	//     fontSize: '14px',
	//     whiteSpace: 'wrap',
	//     textTransform: 'uppercase',
	//     textAlign: 'left'
	// },
	attestationText: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '500',
		fontSize: '14px',
		whiteSpace: 'wrap',
		textAlign: 'left',
	},
	setWidthSelectGroup: {
		top: '-23px',
		width: 'calc(100% - 1px)',
		marginTop: '10px',
		marginRight: '10px',
	},
	setWidthSelects: {
		minWidth: '100%',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},
	hideDeleteBtn: {
		display: 'none',
	},
	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	// selectContainerOffset: {
	//     padding: '0 16px 16px 16px !important'
	// },
	selectContainer: {
		padding: '3px !important',
	},
	selectContainerGroup: {
		[theme.breakpoints.down('sm')]: {
			padding: '3px 10px 0px 10px !important',
		},
		minWidth: '97%',
	},
	// multiGridOffset: {
	//     paddingTop: '13px !important'
	// },
	leftPad30: {
		paddingLeft: '30px !important',
	},
	containerSM: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% + 13px) !important',
		},
	},
	marginBottom20Neg: {
		marginBottom: -20,
	},
	infoMessage: {
		color: COLORS.RAVEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '14px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingLeft: '10px !important',
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
}));

function MalpracticeClaimsHistoryForm({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	updateFlags,
	setCredAppError,
	activePath,
	credState,
	type,
	constantType,
	apiType,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	const normalTheme = createTheme({
		overrides: {
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '18px',
					borderRadius: '1px !important',
				},
			},
		},
	});
	const errorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '17px',
					borderRadius: '1px !important',
					border: '2px solid red !important',
				},
			},
		},
	});

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [incomingData, setIncomingData] = useState({
		qa1Denied: null,
		qa2MalpracticeClaim: null,
		qa3PendingClaim: null,
	});

	const DocTypes = [
		{
			value: DOCUMENT_TYPE.CourtBoardDocuments,
			label: DOCUMENT_TYPE.CourtBoardDocuments,
		},
		{
			value: DOCUMENT_TYPE.Explanation,
			label: DOCUMENT_TYPE.Explanation,
		},
		{
			value: DOCUMENT_TYPE.Claims,
			label: DOCUMENT_TYPE.Claims,
		},
	];

	const [isLoaded, setIsLoaded] = useState(false);

	const [deleteRecordIndex, setDeleteRecordIndex] = useState();
	const [deleteRecordId, setDeleteRecordId] = useState();
	const [deleteRecords, setDeleteRecords] = useState([]);

	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);

	const { register, control, handleSubmit, watch, errors, reset, getValues, formState } = useForm(
		{
			mode: 'all',
			reValidateMode: 'onChange',
			defaultValues: {
				id: null,
				plaintiffLastName: '',
				insuranceCarrier: '',
				county: '',
				state: '',
				status: '',
				incidentDate: null,
				settlementAmount: '',
				malpracticeSummary: '',
			},
		}
	);

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			setSaveHandler(
				MalpracticeClaimsHistoryForm,
				'Malpractice Claims History',
				handleSubmit(async (data) => await onSubmit(data))
			);

			await getMalpracticeClaimHistories(externalId, apiType)
				.then(function (data) {
					const {
						malpracticeClaimHistories = [],
						qa1Denied,
						qa2MalpracticeClaim,
						qa3PendingClaim,
					} = data;
					setIncomingData({
						qa1Denied: qa1Denied,
						qa2MalpracticeClaim: qa2MalpracticeClaim,
						qa3PendingClaim: qa3PendingClaim,
					});

					console.log('response is', data);

					if (malpracticeClaimHistories.length === 0) {
						append({
							id: null,
							plaintiffLastName: '',
							insuranceCarrier: '',
							county: '',
							state: '',
							status: '',
							incidentDate: null,
							settlementAmount: '',
							malpracticeSummary: '',
						});
						data['malpracticeClaimHistories'].push({
							id: null,
							plaintiffLastName: '',
							insuranceCarrier: '',
							county: '',
							state: '',
							status: '',
							incidentDate: null,
							settlementAmount: '',
							malpracticeSummary: '',
						});
					} else {
						data['malpracticeClaimHistories'].map((item) => {
							if (item.county === null) {
								item.county = '';
							}
							if (item.status === null) {
								item.status = '';
							}
							if (item.state === null) {
								item.state = '';
							}
							if (item.insuranceCarrier === null) {
								item.insuranceCarrier = '';
							}
							if (item.plaintiffLastName === null) {
								item.plaintiffLastName = '';
							}
							if (item.malpracticeSummary === null) {
								item.malpracticeSummary = '';
							}
							if (item.settlementAmount === 0) {
								item.settlementAmount = '0';
							}
							return item;
						});
					}
					flags.dataSaved = false;
					updateFlags(flags);
					reset(data);
					setIsLoaded(true);

					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status = {
							status: 'incomplete',
							missingFields: [],
						};
						status = malpracticeClaimsHistorySectionCheck(data, activePath);

						if (!!status.missingFields) {
							setHighlights(status.missingFields);
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);

	function updateHighlight() {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};

			let data = watch();
			status = malpracticeClaimsHistorySectionCheck(data, activePath);

			//alert(`missingFields: ${status.missingFields}`)
			setHighlights(status.missingFields);
		}
	}

	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'malpracticeClaimHistories',
	});

	useEffect(() => {
		// if(!flags.dataSaved) {
		//     flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		// }
		// if (formState.errors.hasOwnProperty('malpracticeClaimHistories') && flags.validationError === false) {
		//     flags.validationError = true;
		//     updateFlags(flags);
		// } else {
		//     if (!formState.errors.hasOwnProperty('malpracticeClaimHistories') && flags.validationError === true) {
		//         flags.validationError = false;
		//         updateFlags(flags);
		//     }
		// }

		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('malpracticeClaimHistories')) {
			for (let i = 0; i < errors.malpracticeClaimHistories.length; i++) {
				if (!!errors?.malpracticeClaimHistories[i]) {
					missingRequiredToSaveFields = Object.values(
						errors.malpracticeClaimHistories[i]
					).filter((anError) => {
						return anError.hasOwnProperty('type');
					});
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	const classes = styles();

	async function onSubmit(data) {
		let success = false;

		const { externalId } = UserContext;
		data['clinicianId'] = externalId;
		data['credentialingId'] = getValues(`credentialingId`) || null;

		if (
			data['malpracticeClaimHistories'].length === 1 &&
			getValues(`malpracticeClaimHistories[0].plaintiffLastName`) === '' &&
			getValues(`malpracticeClaimHistories[0].insuranceCarrier`) === '' &&
			getValues(`malpracticeClaimHistories[0].county`) === '' &&
			getValues(`malpracticeClaimHistories[0].state`) === '' &&
			getValues(`malpracticeClaimHistories[0].status`) === '' &&
			getValues(`malpracticeClaimHistories[0].incidentDate`) === null &&
			getValues(`malpracticeClaimHistories[0].settlementAmount`) === '' &&
			getValues(`malpracticeClaimHistories[0].malpracticeSummary`) === ''
		) {
			data['malpracticeClaimHistories'] = [];
		} else {
			data['malpracticeClaimHistories'].map((item) => {
				if (item.id === 'null') {
					item.id = null;
				}
				item.incidentDate = moment(item.incidentDate).toISOString(true);

				return item;
			});
		}

		if (deleteRecords.length > 0) {
			const deleteRecordsNumbers = deleteRecords.filter(function (element) {
				return element !== 'null';
			});
			await deleteMalpracticeClaimHistories(externalId, deleteRecordsNumbers, apiType).catch(
				(e) => {
					setCredAppError(e);
				}
			);
		}

		console.log('before json', data);
		const jsonData = JSON.stringify(data);
		console.log('post payload is:', jsonData);
		await postMalpracticeClaimHistories(externalId, jsonData, apiType);
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;

		sectionCompletion.malpracticeInsurance.malpracticeClaimsHistory.current =
			await malpracticeClaimsHistorySectionPointsCheck(data, activePath, sectionCompletion);
		updateSectionCompletion(sectionCompletion);

		updateFlags(flags);
		return success;
	}

	const deleteEntry = (btnPressed, index) => {
		if (btnPressed === 'Yes') {
			if (deleteRecordId !== null) {
				deleteRecords.push(deleteRecordId);
				setDeleteRecords(deleteRecords);
				setShowDeleteModal(false);
			}
			remove(deleteRecordIndex);
			setShowDeleteModal(false);
		}
		setShowDeleteModal(false);
	};

	const openDeleteConfirmation = (item, index) => {
		setDeleteRecordIndex(index);
		setDeleteRecordId(item.id);
		setShowDeleteModal(true);
	};

	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	const renderForm = (item, index) => {
		const lockFields =
			!!item.id &&
			(credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN);

		// const areAnyFieldsFilled = () => {
		// 	let anyFilled = false;
		// 	if (
		// 		getValues(`malpracticeClaimHistories[${index}].plaintiffLastName`) !== '' ||
		// 		getValues(`malpracticeClaimHistories[${index}].insuranceCarrier`) !== '' ||
		// 		getValues(`malpracticeClaimHistories[${index}].county`) !== '' ||
		// 		getValues(`malpracticeClaimHistories[${index}].state`) !== '' ||
		// 		getValues(`malpracticeClaimHistories[${index}].status`) !== '' ||
		// 		getValues(`malpracticeClaimHistories[${index}].incidentDate`) !== null ||
		// 		getValues(`malpracticeClaimHistories[${index}].settlementAmount`) !== '' ||
		// 		getValues(`malpracticeClaimHistories[${index}].malpracticeSummary`) !== ''
		// 	) {
		// 		anyFilled = true;
		// 	}
		// 	return anyFilled;
		// };

		const areAnyQuestionsYes = () => {
			let anyYes = false;
			if (
				getValues(`qa1Denied`) === 'true' ||
				getValues(`qa2MalpracticeClaim`) === 'true' ||
				getValues(`qa3PendingClaim`) === 'true'
			) {
				anyYes = true;
			}
			return anyYes;
		};

		return (
			<div className={classes.profileWrapper}>
				<RouteDeparturePrompt
					blockedBackNavigation={blockedBackNavigation}
					when={!flags.dataSaved ? !!Object.keys(formState.dirtyFields).length : false}
				/>
				<Grid container>
					{index === 0 ? (
						<Grid container className={'credAppAdditionalHeader'}>
							<Grid item xs={12} className={classes.noTopPad}>
								<FormControl
									classes={{ root: classes.setWidthTo100OnMobile }}
									className={classes.radioGroup}
								>
									<InputLabel
										shrink
										htmlFor={`qa1Denied`}
										classes={{ root: classes.inputRadio }}
										className={
											(errors?.qa1Denied ||
												(!!highlights &&
													highlights.includes(`qa1Denied`))) &&
											classes.errorColor
										}
										required
									>
										{t(`credentials:${constantType}.qa1DeniedLine`)}
									</InputLabel>

									<div>
										<RadioGroup
											row
											aria-label='completed'
											name={`qa1Denied`}
											defaultValue={incomingData.qa1Denied}
											classes={{ root: classes.radioGroupLabel }}
											onBlur={() => {
												updateHighlight();
											}}
											onChange={() => {
												updateHighlight();
											}}
										>
											<FormControlLabel
												value='Yes'
												control={
													<Radio
														color='primary'
														style={{ color: COLORS.RAVEN }}
														inputRef={register({
															//required: areAnyFieldsFilled()
														})}
														disableRipple
														size='small'
														disabled={lockFields}
														className={
															lockFields
																? classes.disabledInput
																: classes.normalInput
														}
													/>
												}
												label={
													<span
														style={{
															fontSize: '16px',
															color: COLORS.RAVEN,
														}}
													>
														Yes
													</span>
												}
											/>
											<FormControlLabel
												value='No'
												control={
													<Radio
														color='primary'
														style={{ color: COLORS.RAVEN }}
														inputRef={register({
															//required: areAnyFieldsFilled()
														})}
														disableRipple
														size='small'
														disabled={lockFields}
														className={
															lockFields
																? classes.disabledInput
																: classes.normalInput
														}
													/>
												}
												label={
													<span
														style={{
															fontSize: '16px',
															color: COLORS.RAVEN,
														}}
													>
														No
													</span>
												}
											/>
										</RadioGroup>
										{(errors?.qa1Denied ||
											(!!highlights && highlights.includes(`qa1Denied`))) && (
											<span className={classes.cssLabel}>
												{t(
													'credentials:VALIDATION_MESSAGE.ATTESTATION_QUESTION'
												)}
											</span>
										)}
									</div>
								</FormControl>
								<Input
									autoComplete='off'
									variant='outlined'
									style={{ display: 'none' }}
									name={`credentialingId`}
									fullWidth
									value={getValues(`credentialingId`) || ''}
									inputRef={register()}
								/>
							</Grid>

							<Grid item xs={12} className={classes.noTopPad}>
								<FormControl
									classes={{ root: classes.setWidthTo100OnMobile }}
									className={classes.radioGroup}
								>
									<InputLabel
										shrink
										htmlFor={`qa2MalpracticeClaim`}
										classes={{ root: classes.inputRadio }}
										className={
											(errors?.qa2MalpracticeClaim ||
												(!!highlights &&
													highlights.includes(`qa2MalpracticeClaim`))) &&
											classes.errorColor
										}
										required
									>
										{t(`credentials:${constantType}.qa2MalpracticeClaimLine`)}
									</InputLabel>

									<div>
										<RadioGroup
											row
											aria-label='completed'
											name={`qa2MalpracticeClaim`}
											defaultValue={incomingData.qa2MalpracticeClaim}
											classes={{ root: classes.radioGroupLabel }}
											onBlur={() => {
												updateHighlight();
											}}
											onChange={() => {
												updateHighlight();
											}}
										>
											<FormControlLabel
												value='Yes'
												control={
													<Radio
														color='primary'
														style={{ color: COLORS.RAVEN }}
														inputRef={register({
															//required: areAnyFieldsFilled()
														})}
														disableRipple
														size='small'
														disabled={lockFields}
														className={
															lockFields
																? classes.disabledInput
																: classes.normalInput
														}
													/>
												}
												label={
													<span
														style={{
															fontSize: '16px',
															color: COLORS.RAVEN,
														}}
													>
														Yes
													</span>
												}
											/>
											<FormControlLabel
												value='No'
												control={
													<Radio
														color='primary'
														style={{ color: COLORS.RAVEN }}
														inputRef={register({
															//required: areAnyFieldsFilled()
														})}
														disableRipple
														size='small'
														disabled={lockFields}
														className={
															lockFields
																? classes.disabledInput
																: classes.normalInput
														}
													/>
												}
												label={
													<span
														style={{
															fontSize: '16px',
															color: COLORS.RAVEN,
														}}
													>
														No
													</span>
												}
											/>
										</RadioGroup>
										{(errors?.qa2MalpracticeClaim ||
											(!!highlights &&
												highlights.includes(`qa2MalpracticeClaim`))) && (
											<span className={classes.cssLabel}>
												{t(
													'credentials:VALIDATION_MESSAGE.ATTESTATION_QUESTION'
												)}
											</span>
										)}
									</div>
								</FormControl>
							</Grid>

							<Grid item xs={9} className={classes.noTopPad}>
								<FormControl
									classes={{ root: classes.setWidthTo100OnMobile }}
									className={classes.radioGroup}
								>
									<InputLabel
										shrink
										htmlFor={`qa3PendingClaim`}
										classes={{ root: classes.inputRadio }}
										className={
											(errors?.qa3PendingClaim ||
												(!!highlights &&
													highlights.includes(`qa3PendingClaim`))) &&
											classes.errorColor
										}
										required
									>
										{t(`credentials:${constantType}.qa3PendingClaimLine`)}
									</InputLabel>

									<div>
										<RadioGroup
											row
											aria-label='completed'
											name={`qa3PendingClaim`}
											defaultValue={incomingData.qa3PendingClaim}
											classes={{ root: classes.radioGroupLabel }}
											onBlur={() => {
												updateHighlight();
											}}
											onChange={() => {
												updateHighlight();
											}}
										>
											<FormControlLabel
												value='Yes'
												control={
													<Radio
														color='primary'
														style={{ color: COLORS.RAVEN }}
														inputRef={register({
															//required: areAnyFieldsFilled()
														})}
														disableRipple
														size='small'
														disabled={lockFields}
														className={
															lockFields
																? classes.disabledInput
																: classes.normalInput
														}
													/>
												}
												label={
													<span
														style={{
															fontSize: '16px',
															color: COLORS.RAVEN,
														}}
													>
														Yes
													</span>
												}
											/>
											<FormControlLabel
												value='No'
												control={
													<Radio
														color='primary'
														style={{ color: COLORS.RAVEN }}
														inputRef={register({
															//required: areAnyFieldsFilled()
														})}
														disableRipple
														size='small'
														disabled={lockFields}
														className={
															lockFields
																? classes.disabledInput
																: classes.normalInput
														}
													/>
												}
												label={
													<span
														style={{
															fontSize: '16px',
															color: COLORS.RAVEN,
														}}
													>
														No
													</span>
												}
											/>
										</RadioGroup>
										{(errors?.qa3PendingClaim ||
											(!!highlights &&
												highlights.includes(`qa3PendingClaim`))) && (
											<span className={classes.cssLabel}>
												{t(
													'credentials:VALIDATION_MESSAGE.ATTESTATION_QUESTION'
												)}
											</span>
										)}
									</div>
								</FormControl>
							</Grid>

							<Grid item xs={12} className={classes.attestationText}>
								<div>{t(`credentials:${constantType}.ATTESTATION_TEXT`)}</div>
							</Grid>
						</Grid>
					) : (
						<Grid container className={'credAppAdditionalHeader'}>
							<Grid item xs={9} className={'credAppAdditionalHeaderTitle'}>
								{t(`credentials:${constantType}.ADDITIONAL_CLAIM`)}
							</Grid>
							<Grid
								item
								xs={3}
								className={
									credState === CRED_STATE.CLOSED
										? classes.hideDeleteBtn
										: 'credAppAdditionalDelete'
								}
								onClick={() => {
									openDeleteConfirmation(item, index);
								}}
							>
								{t(`credentials:${constantType}.DELETE_BTN`)}
							</Grid>
						</Grid>
					)}
				</Grid>

				<Container component='main' maxWidth='md'>
					<CssBaseline />
					<div>
						<Grid container item md={12} className={classes.containerSM}>
							<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
								<Grid container spacing={4}>
									<Grid item xs={12}>
										<InputLabel
											shrink
											htmlFor={`malpracticeClaimHistories[${index}].plaintiffLastName`}
											classes={{ root: classes.inputLabel }}
											className={
												((errors?.malpracticeClaimHistories &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.plaintiffLastName) ||
													(!!highlights &&
														highlights.includes(
															`malpracticeClaimHistories[${index}].plaintiffLastName`
														))) &&
												classes.errorColor
											}
											required
										>
											{t(`credentials:${constantType}.PLAINTIFFLASTNAME`)}
										</InputLabel>
										<TextField
											margin='dense'
											autoComplete='off'
											variant='outlined'
											name={`malpracticeClaimHistories[${index}].plaintiffLastName`}
											error={
												(!!errors?.malpracticeClaimHistories &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.plaintiffLastName) ||
												(!!highlights &&
													highlights.includes(
														`malpracticeClaimHistories[${index}].plaintiffLastName`
													))
											}
											fullWidth
											defaultValue={`${item.plaintiffLastName}`}
											inputRef={register({
												required: areAnyQuestionsYes(),
												maxLength: {
													value: 100,
													message: t('common:MESSAGES.CHARACTER_LIMIT'),
												},
											})}
											className={
												lockFields
													? classes.disabledInput
													: classes.normalInput
											}
											InputProps={{
												readOnly: lockFields,
												classes: {
													root: classes.cssOutlinedInput,
													focused: classes.cssFocused,
													notchedOutline: classes.notchedOutline,
													error: classes.error,
												},
											}}
											onBlur={() => {
												updateHighlight();
											}}
										/>
										<Input
											autoComplete='off'
											variant='outlined'
											style={{ display: 'none' }}
											name={`malpracticeClaimHistories[${index}].id`}
											fullWidth
											value={`${item.id}` || null}
											inputRef={register()}
										/>
										{((errors?.malpracticeClaimHistories &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.plaintiffLastName &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.plaintiffLastName['type'] === 'maxLength') ||
											(!!highlights &&
												highlights.includes(
													`malpracticeClaimHistories[${index}].plaintiffLastName`
												))) && (
											<span className={classes.cssLabel}>
												{errors?.malpracticeClaimHistories &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.plaintiffLastName &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.plaintiffLastName.message}
											</span>
										)}
										{((errors?.malpracticeClaimHistories &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.plaintiffLastName &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.plaintiffLastName['type'] !== 'maxLength') ||
											(!!highlights &&
												highlights.includes(
													`malpracticeClaimHistories[${index}].plaintiffLastName`
												))) && (
											<span className={classes.cssLabel}>
												{t(
													'credentials:VALIDATION_MESSAGE.PLAINTIFFLASTNAME'
												)}
											</span>
										)}
									</Grid>

									<Grid item xs={12}>
										<InputLabel
											shrink
											htmlFor={`malpracticeClaimHistories[${index}].insuranceCarrier`}
											classes={{ root: classes.inputLabel }}
											className={
												((errors?.malpracticeClaimHistories &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.insuranceCarrier) ||
													(!!highlights &&
														highlights.includes(
															`malpracticeClaimHistories[${index}].insuranceCarrier`
														))) &&
												classes.errorColor
											}
											required
										>
											{t(`credentials:${constantType}.INSURANCE_CARRIER`)}
										</InputLabel>
										<TextField
											margin='dense'
											autoComplete='off'
											variant='outlined'
											fullWidth
											defaultValue={`${item.insuranceCarrier}`}
											name={`malpracticeClaimHistories[${index}].insuranceCarrier`}
											error={
												(!!errors?.malpracticeClaimHistories &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.insuranceCarrier) ||
												(!!highlights &&
													highlights.includes(
														`malpracticeClaimHistories[${index}].insuranceCarrier`
													))
											}
											inputRef={register({
												required: areAnyQuestionsYes(),
												maxLength: {
													value: 255,
													message: t('common:MESSAGES.CHARACTER_LIMIT'),
												},
											})}
											className={
												lockFields
													? classes.disabledInput
													: classes.normalInput
											}
											InputProps={{
												readOnly: lockFields,
												classes: {
													root: classes.cssOutlinedInput,
													focused: classes.cssFocused,
													notchedOutline: classes.notchedOutline,
													error: classes.error,
												},
											}}
											onBlur={() => {
												updateHighlight();
											}}
										/>
										{((errors?.malpracticeClaimHistories &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.insuranceCarrier &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.insuranceCarrier['type'] === 'maxLength') ||
											(!!highlights &&
												highlights.includes(
													`malpracticeClaimHistories[${index}].insuranceCarrier`
												))) && (
											<span className={classes.cssLabel}>
												{errors?.malpracticeClaimHistories &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.insuranceCarrier &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.insuranceCarrier.message}
											</span>
										)}
										{((errors?.malpracticeClaimHistories &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.insuranceCarrier &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.insuranceCarrier['type'] !== 'maxLength') ||
											(!!highlights &&
												highlights.includes(
													`malpracticeClaimHistories[${index}].insuranceCarrier`
												))) && (
											<span className={classes.cssLabel}>
												{t(
													'credentials:VALIDATION_MESSAGE.INSURANCE_CARRIER'
												)}
											</span>
										)}
									</Grid>

									<Grid
										item
										xs={12}
										md={4}
										classes={{ root: classes.selectContainerOffset }}
									>
										<FormControl
											classes={{ root: classes.setWidthTo100OnMobile }}
										>
											<InputLabel
												shrink
												htmlFor={`trainings[${index}].state`}
												classes={{ root: classes.selectInputLabel }}
												className={
													((errors?.malpracticeClaimHistories &&
														errors?.malpracticeClaimHistories[
															`${index}`
														]?.state) ||
														(!!highlights &&
															highlights.includes(
																`malpracticeClaimHistories[${index}].state`
															))) &&
													classes.errorColor
												}
												required
											>
												{t(`credentials:${constantType}.STATE`)}
											</InputLabel>
											<ThemeProvider
												theme={
													(errors?.malpracticeClaimHistories &&
														errors?.malpracticeClaimHistories[
															`${index}`
														]?.state) ||
													(!!highlights &&
														highlights.includes(
															`malpracticeClaimHistories[${index}].state`
														))
														? errorTheme
														: normalTheme
												}
											>
												<NativeSelect
													defaultValue={`${item.state}`}
													inputRef={register({
														required: areAnyQuestionsYes(),
													})}
													error={
														!!errors?.malpracticeClaimHistories &&
														errors?.malpracticeClaimHistories[
															`${index}`
														]?.state
													}
													name={`malpracticeClaimHistories[${index}].state`}
													className={
														lockFields
															? classes.disabledSelect
															: classes.normalInput
													}
													disabled={lockFields}
													input={
														<TextFieldComponent
															style={{
																border: `0px solid ${COLORS.RAVEN}`,
															}}
															classes={{
																input: classes.setWidthTo100OnMobile,
															}}
														/>
													}
													onBlur={() => {
														updateHighlight();
													}}
												>
													<option key={''} value={null}></option>
													{statesForLicense.map((item) => (
														<option key={item.value} value={item.value}>
															{item.label}
														</option>
													))}
												</NativeSelect>
											</ThemeProvider>
										</FormControl>
										{((errors?.malpracticeClaimHistories &&
											errors?.malpracticeClaimHistories[`${index}`]?.state) ||
											(!!highlights &&
												highlights.includes(
													`malpracticeClaimHistories[${index}].state`
												))) && (
											<span
												className={`${classes.cssLabel} ${classes.marginLeft12}`}
											>
												{t('credentials:VALIDATION_MESSAGE.STATE')}
											</span>
										)}
										{lockFields ? (
											<Input
												style={{ display: 'none' }}
												name={`malpracticeClaimHistories[${index}].state`}
												value={`${item.state}`}
												inputRef={register()}
											/>
										) : null}
									</Grid>

									<Grid
										item
										xs={12}
										md={4}
										classes={{ root: classes.multiGridOffset }}
									>
										<InputLabel
											shrink
											htmlFor={`malpracticeClaimHistories[${index}].county`}
											className={classes.inputLabel}
										>
											{t(`credentials:${constantType}.COUNTY`)}
										</InputLabel>
										<TextField
											margin='dense'
											autoComplete='off'
											variant='outlined'
											fullWidth
											defaultValue={`${item.county}`}
											error={
												!!errors?.malpracticeClaimHistories &&
												errors?.malpracticeClaimHistories[`${index}`]
													?.county
											}
											name={`malpracticeClaimHistories[${index}].county`}
											inputRef={register({
												maxLength: {
													value: 100,
													message: t('common:MESSAGES.CHARACTER_LIMIT'),
												},
											})}
											className={
												lockFields
													? classes.disabledInput
													: classes.normalInput
											}
											InputProps={{
												readOnly: lockFields,
												classes: {
													root: classes.cssOutlinedInput,
													focused: classes.cssFocused,
													notchedOutline: classes.notchedOutline,
													error: classes.error,
												},
											}}
											onBlur={() => {
												updateHighlight();
											}}
										/>
										{((errors?.malpracticeClaimHistories &&
											errors?.malpracticeClaimHistories[`${index}`]?.county &&
											errors?.malpracticeClaimHistories[`${index}`]?.county[
												'type'
											] === 'maxLength') ||
											(!!highlights &&
												highlights.includes(
													`malpracticeClaimHistories[${index}].county`
												))) && (
											<span className={classes.cssLabel}>
												{errors?.malpracticeClaimHistories &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.county &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.county.message}
											</span>
										)}
										{errors?.malpracticeClaimHistories &&
											errors?.malpracticeClaimHistories[`${index}`]?.county &&
											errors?.malpracticeClaimHistories[`${index}`]?.county[
												'type'
											] !== 'maxLength' && (
												<span className={classes.cssLabel}>
													{t('credentials:VALIDATION_MESSAGE.COUNTY')}
												</span>
											)}
									</Grid>

									<Grid
										item
										xs={12}
										md={4}
										className={classes.selectContainerOffset}
									>
										<FormControl
											classes={{ root: classes.setWidthTo100OnMobile }}
										>
											<InputLabel
												shrink
												htmlFor={`trainings[${index}].status`}
												classes={{ root: classes.selectInputLabel }}
												className={
													((errors?.malpracticeClaimHistories &&
														errors?.malpracticeClaimHistories[
															`${index}`
														]?.status) ||
														(!!highlights &&
															highlights.includes(
																`malpracticeClaimHistories[${index}].status`
															))) &&
													classes.errorColor
												}
												required
											>
												{t(`credentials:${constantType}.STATUS`)}
											</InputLabel>
											<ThemeProvider
												theme={
													(errors?.malpracticeClaimHistories &&
														errors?.malpracticeClaimHistories[
															`${index}`
														]?.status) ||
													(!!highlights &&
														highlights.includes(
															`malpracticeClaimHistories[${index}].status`
														))
														? errorTheme
														: normalTheme
												}
											>
												<NativeSelect
													defaultValue={`${item.status}`}
													inputRef={register({
														required: areAnyQuestionsYes(),
													})}
													error={
														(!!errors?.malpracticeClaimHistories &&
															errors?.malpracticeClaimHistories[
																`${index}`
															]?.status) ||
														(!!highlights &&
															highlights.includes(
																`malpracticeClaimHistories[${index}].status`
															))
													}
													name={`malpracticeClaimHistories[${index}].status`}
													className={
														credState === CRED_STATE.CLOSED
															? classes.disabledSelect
															: classes.normalInput
													}
													disabled={credState === CRED_STATE.CLOSED}
													input={
														<TextFieldComponent
															style={{
																border: `0px solid ${COLORS.RAVEN}`,
															}}
															classes={{
																input: classes.setWidthTo100OnMobile,
															}}
														/>
													}
													onBlur={() => {
														updateHighlight();
													}}
												>
													<option key={''} value={null}></option>
													<option key={'Settled'} value={'Settled'}>
														Settled
													</option>
													<option key={'Dismissed'} value={'Dismissed'}>
														Dismissed
													</option>
													<option key={'Pending'} value={'Pending'}>
														Pending
													</option>
													<option key={'Judgement'} value={'Judgement'}>
														Judgement
													</option>
												</NativeSelect>
											</ThemeProvider>
										</FormControl>
										{((errors?.malpracticeClaimHistories &&
											errors?.malpracticeClaimHistories[`${index}`]?.state) ||
											(!!highlights &&
												highlights.includes(
													`malpracticeClaimHistories[${index}].status`
												))) && (
											<span
												className={`${classes.cssLabel} ${classes.marginLeft12}`}
											>
												{t('credentials:VALIDATION_MESSAGE.STATUS')}
											</span>
										)}
										{lockFields ? (
											<Input
												style={{ display: 'none' }}
												name={`malpracticeClaimHistories[${index}].status`}
												value={`${item.status}`}
												inputRef={register()}
											/>
										) : null}
									</Grid>

									<Grid container direction={'row'} spacing={4}>
										<Grid item xs={12} md={4} className={classes.leftPad30}>
											<FormControl
												classes={{ root: classes.setWidthTo100OnMobile }}
											>
												<InputLabel
													shrink
													htmlFor={`malpracticeClaimHistories[${index}].incidentDate`}
													classes={{ root: classes.selectInputLabel }}
													className={
														((errors?.malpracticeClaimHistories &&
															errors?.malpracticeClaimHistories[
																`${index}`
															]?.incidentDate) ||
															(!!highlights &&
																highlights.includes(
																	`malpracticeClaimHistories[${index}].incidentDate`
																))) &&
														classes.errorColor
													}
													required
												>
													{t(`credentials:${constantType}.DATE`)}
												</InputLabel>
												<ThemeProvider theme={datePickerTheme}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<Controller
															control={control}
															rules={{
																validate: (date) =>
																	dateValidation(date),
																required: areAnyQuestionsYes(),
															}}
															name={`malpracticeClaimHistories[${index}].incidentDate`}
															defaultValue={
																`${item.incidentDate}` === 'null'
																	? null
																	: convertToDateIgnoringTime(
																			`${item.incidentDate}`
																	  )
															}
															render={({
																onChange,
																onBlur,
																value,
																name,
															}) => (
																<DatePickerComponent
																	onChange={onChange}
																	name={name}
																	value={value}
																	format='MM/yyyy'
																	placeholder='MM/YYYY'
																	clearable
																	variant='inline'
																	autoOk='true'
																	disabled={lockFields}
																	minDateMessage={
																		<span
																			className={`${classes.cssLabel} ${classes.marginLeft12}`}
																		>
																			{t(
																				`credentials:${constantType}.DATE_VALIDATION`
																			)}
																		</span>
																	}
																	maxDateMessage={
																		<span
																			className={`${classes.cssLabel} ${classes.marginLeft12}`}
																		>
																			{t(
																				`credentials:${constantType}.DATE_VALIDATION`
																			)}
																		</span>
																	}
																	invalidDateMessage={``}
																	margin='normal'
																	theme={datePickerTheme}
																	error={
																		(errors?.malpracticeClaimHistories &&
																			errors
																				?.malpracticeClaimHistories[
																				`${index}`
																			]?.incidentDate) ||
																		(!!highlights &&
																			highlights.includes(
																				`malpracticeClaimHistories[${index}].incidentDate`
																			))
																	}
																	KeyboardButtonProps={{
																		'aria-label': t(
																			'credentials:OTHER_CERTIFICATIONS.EXPIRATION'
																		),
																	}}
																	InputProps={{
																		classes: {
																			disabled:
																				classes.disabledInput,
																			root: classes.cssOutlinedInput,
																			error: classes.error,
																		},
																	}}
																	onClose={() => {
																		updateHighlight();
																	}}
																	onBlur={() => {
																		updateHighlight();
																	}}
																	inputVariant='outlined'
																/>
															)}
														/>
													</MuiPickersUtilsProvider>
												</ThemeProvider>
											</FormControl>
											{((errors?.malpracticeClaimHistories &&
												errors?.malpracticeClaimHistories[`${index}`]
													?.incidentDate) ||
												(!!highlights &&
													highlights.includes(
														`malpracticeClaimHistories[${index}].incidentDate`
													))) && (
												<span
													className={`${classes.cssLabel} ${classes.marginLeft12}`}
												>
													{t(
														`credentials:${constantType}.DATE_VALIDATION`
													)}
												</span>
											)}
										</Grid>

										<Grid
											item
											xs={12}
											md={4}
											className={classes.textNextToDate}
										>
											<InputLabel
												shrink
												htmlFor={`malpracticeClaimHistories[${index}].settlementAmount`}
												classes={{ root: classes.inputLabel }}
												className={
													errors?.malpracticeClaimHistories &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.settlementAmount &&
													classes.errorColor
												}
											>
												{t(`credentials:${constantType}.SETTLEMENT_AMOUNT`)}
											</InputLabel>
											<TextField
												margin='dense'
												autoComplete='off'
												variant='outlined'
												fullWidth
												defaultValue={`${item.settlementAmount}`}
												name={`malpracticeClaimHistories[${index}].settlementAmount`}
												error={
													!!errors?.malpracticeClaimHistories &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.settlementAmount
												}
												inputRef={register({
													maxLength: {
														value: 80,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
													pattern: /^\d+(\.\d+)?$/,
												})}
												className={
													credState === CRED_STATE.CLOSED
														? classes.disabledInput
														: classes.normalInput
												}
												InputProps={{
													readOnly: credState === CRED_STATE.CLOSED,
													startAdornment: (
														<InputAdornment position='start'>
															$
														</InputAdornment>
													),
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
											/>
											{((errors?.malpracticeClaimHistories &&
												errors?.malpracticeClaimHistories[`${index}`]
													?.settlementAmount &&
												errors?.malpracticeClaimHistories[`${index}`]
													?.settlementAmount['type'] === 'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`malpracticeClaimHistories[${index}].settlementAmount`
													))) && (
												<span className={classes.cssLabel}>
													{errors?.malpracticeClaimHistories &&
														errors?.malpracticeClaimHistories[
															`${index}`
														]?.settlementAmount &&
														errors?.malpracticeClaimHistories[
															`${index}`
														]?.settlementAmount.message}
												</span>
											)}
											{errors?.malpracticeClaimHistories &&
												errors?.malpracticeClaimHistories[`${index}`]
													?.settlementAmount &&
												errors?.malpracticeClaimHistories[`${index}`]
													?.settlementAmount['type'] !== 'maxLength' && (
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t(
															`credentials:VALIDATION_MESSAGE.SETTLEMENT_AMOUNT`
														)}
													</span>
												)}
										</Grid>
									</Grid>

									<Grid item xs={12}>
										<InputLabel
											shrink
											htmlFor={`malpracticeClaimHistories[${index}].malpracticeSummary`}
											classes={{ root: classes.inputLabel }}
											className={
												errors?.malpracticeClaimHistories &&
												errors?.malpracticeClaimHistories[`${index}`]
													?.malpracticeSummary &&
												classes.errorColor
											}
											required
										>
											{t(`credentials:${constantType}.MALPRACTICE_SUMMARY`)}
											<ToolTipComponent
												disableFocusListener
												enterTouchDelay={50}
												placement='right'
												title={t(
													`credentials:${constantType}.SUMMARY_TOOLTIP`
												)}
											>
												<HelpIcon
													style={{
														margin: '0px',
														fontSize: '15px',
														cursor: 'pointer',
														position: 'relative',
														left: '10px',
													}}
													color={'action'}
												/>
											</ToolTipComponent>
										</InputLabel>

										<TextField
											margin='dense'
											autoComplete='off'
											variant='outlined'
											fullWidth
											multiline
											rows={5}
											style={{ padding: '0px' }}
											defaultValue={`${item.malpracticeSummary}`}
											error={
												!!errors?.malpracticeClaimHistories &&
												errors?.malpracticeClaimHistories[`${index}`]
													?.malpracticeSummary
											}
											name={`malpracticeClaimHistories[${index}].malpracticeSummary`}
											inputRef={register({
												required: areAnyQuestionsYes(),
												maxLength: {
													value: 10000,
													message: t('common:MESSAGES.CHARACTER_LIMIT'),
												},
											})}
											className={
												credState === CRED_STATE.CLOSED
													? classes.disabledInput
													: classes.normalInput
											}
											InputProps={{
												readOnly: credState === CRED_STATE.CLOSED,
												classes: {
													root: classes.malpracticeSummaryInput,
													focused: classes.cssFocused,
													notchedOutline: classes.notchedOutline,
													error: classes.error,
												},
											}}
										/>
										{((errors?.malpracticeClaimHistories &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.malpracticeSummary &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.malpracticeSummary['type'] === 'maxLength') ||
											(!!highlights &&
												highlights.includes(
													`malpracticeClaimHistories[${index}].malpracticeSummary`
												))) && (
											<span className={classes.cssLabel}>
												{errors?.malpracticeClaimHistories &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.malpracticeSummary &&
													errors?.malpracticeClaimHistories[`${index}`]
														?.malpracticeSummary.message}
											</span>
										)}
										{errors?.malpracticeClaimHistories &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.malpracticeSummary &&
											errors?.malpracticeClaimHistories[`${index}`]
												?.malpracticeSummary['type'] !== 'maxLength' && (
												<span
													className={`${classes.cssLabel} ${classes.marginLeft12}`}
												>
													{t(
														`credentials:VALIDATION_MESSAGE.MALPRACTICE_SUMMARY`
													)}
												</span>
											)}
									</Grid>
								</Grid>
							</form>
						</Grid>
					</div>
				</Container>
			</div>
		);
	};

	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}
		return fields.map((item, index) => (
			<div key={item.id}>
				{renderForm(item, index)}
				<RequiredQuestionDialog
					open={showDeleteModal}
					title={t(`credentials:${constantType}.CONFIRM_DELETE_TITLE`)}
					text={t(`credentials:${constantType}.CONFIRM_DELETE_MESSAGE`).replace(
						`(${type})`,
						`${type}`
					)}
					buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
					buttonIDs='Yes;No'
					disableBackgroundClick={false}
					onClose={(btnPressed) => {
						deleteEntry(btnPressed, index);
					}}
				/>
			</div>
		));
	};

	return (
		<div>
			<Grid className={classes.leftPad30}>
				<Grid item xs={12}>
					<h3 className={classes.header}>{t(`credentials:${constantType}.TITLE`)}</h3>
					{credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN ? (
						<Grid item xs={12} md={10} className={classes.messageGrid}>
							<Alert severity='error' className={classes.alertMessage} icon={false}>
								{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
							</Alert>
						</Grid>
					) : null}
					<h3 className={classes.infoMessage}>
						{t(`credentials:${constantType}.INFO_MESSAGE`)}
					</h3>
				</Grid>

				{renderForms()}
			</Grid>

			{isLoaded && credState !== CRED_STATE.CLOSED ? (
				<div
					className={'credAppAddMoreButton'}
					onClick={() =>
						append({
							id: null,
							plaintiffLastName: '',
							insuranceCarrier: '',
							county: '',
							state: '',
							status: '',
							incidentDate: null,
							settlementAmount: '0',
							malpracticeSummary: '',
						})
					}
				>
					{t(`credentials:${constantType}.ADD_MORE_CLAIMS`)}
				</div>
			) : null}

			{isLoaded && credState !== CRED_STATE.CLOSED ? (
				<Grid className={classes.leftPad30}>
					<Grid className={classes.UploaderHeaderArea}>
						<Grid item xs={12} className={'credAppAdditionalHeaderTitle'}>
							Upload Documents
						</Grid>
					</Grid>
					<Grid item xs={12} className={classes.leftPad30}>
						<FeatureFlag name='EnableHasSharepointDocs' isFlagEnabled='true'>
							<UploadClinicianDocumentV2
								docTypeList={DocTypes}
								showDocuments={true}
								credState={credState}
							/>
						</FeatureFlag>
						<FeatureFlag name='EnableHasSharepointDocs' isFlagEnabled='false'>
							<UploadClinicianDocument
								docTypeList={DocTypes}
								showDocuments={true}
								credState={credState}
							/>
						</FeatureFlag>
					</Grid>
				</Grid>
			) : null}
		</div>
	);
}

export default MalpracticeClaimsHistoryForm;
