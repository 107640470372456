import React from 'react';
import { withTranslation } from 'react-i18next';
import {
	Grid,
	Typography,
	withStyles,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	useMediaQuery,
	useTheme,
	Chip,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import WithContexts from '../../../contexts/withContexts';
import { COLORS } from '../../../utils/Application_Constants';
import { totalCount } from '../../../utils/helpers';
import TimesheetReviewTable from './TimesheetReviewTable';
import MileageReviewTable from './MileageReviewTable';
import { TimesheetStatusEnum, TimesheetStatusEnumApi } from './Status.enum';

const styles = (theme) => ({
	PaperRoot: {
		padding: '1.5rem',
		display: 'flex',
		gap: '24px',
		flexDirection: 'column',
	},
	chipSubmitted: {
		backgroundColor: COLORS.GLITTER,
		color: COLORS.RICH_ELECTRIC_BLUE,
		borderColor: COLORS.RICH_ELECTRIC_BLUE,
	},
	chipApproved: {
		backgroundColor: COLORS.AQUA_SPRING,
		color: COLORS.KELLY_GREEN,
		borderColor: COLORS.KELLY_GREEN,
	},
	chipRejected: {
		backgroundColor: COLORS.LT_PEPPER10,
		color: COLORS.LT_PEPPER,
		borderColor: COLORS.LT_PEPPER,
	},
	chipPaid: {
		color: 'white',
		background: COLORS.LT_EMERALD,
	},
	chipContainer: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			marginTop: '8px',
		},
	},
	overline2: {
		fontSize: '10px',
		fontWeight: '600',
		lineHeight: '18px',
		color: COLORS.LT_MIDNIGHT50,
	},
	body1: {
		lineHeight: '20px',
	},
	columnHeader: {
		fontSize: '12px',
		marginBottom: '3px',
	},
	timesheetPanelDetail: {
		padding: '24px 0',
	},
	timesheetSummary: {
		minWidth: '240px',

		[theme.breakpoints.up('sm')]: {
			minWidth: '315px',
		},
	},
	expansionPanelSummaryRoot: {
		borderRadius: '8px',
		background: COLORS.LT_MIDNIGHTBG1,
		padding: '8px 24px',
		'&.Mui-expanded': {
			minHeight: '48px',
		},
	},
	expansionPanelSummaryContent: {
		margin: '0',
		maxWidth: '100%',

		'&.Mui-expanded': {
			margin: '0',
		},
	},

	expansionPanelSummaryExpandIcon: {
		color: COLORS.LT_MIDNIGHT25,
	},

	expansionPanelRoot: {
		'&:before': {
			content: 'none',
		},
		'&.Mui-expanded': {
			margin: '0px',
		},
	},
	mobileStatus: {
		width: '100%',
	},
});

function TimesheetAccordion(props) {
	const {
		classes,
		t,
		timesheet,
		timesheetEntries,
		mileageEntries,
		bookingLocation,
		defaultExpanded,
		index,
	} = props;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
		defaultMatches: true,
	});
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});
	let timesheetStatus = '';
	let showChipBasedOnStatus = false;
	for (let i = 0; i < timesheet?.timesheetReviews?.length; i++) {
		if (
			(timesheet.timesheetReviews[i].status === TimesheetStatusEnumApi.Submitted ||
				timesheet.timesheetReviews[i].status === TimesheetStatusEnumApi.Approved ||
				timesheet.timesheetReviews[i].status === TimesheetStatusEnumApi.Paid ||
				timesheet.timesheetReviews[i].status === TimesheetStatusEnumApi.TimeNotEntered) &&
			timesheet.timesheetReviews[i].locationId === bookingLocation.id
		) {
			timesheetStatus = timesheet.timesheetReviews[i].status;
			showChipBasedOnStatus = true;
		}
	}

	const getStatusColor = (status) => {
		switch (status) {
			case TimesheetStatusEnum.Submitted:
				return classes.chipSubmitted;
			case TimesheetStatusEnum.Approved:
				return classes.chipApproved;
			case TimesheetStatusEnum.Rejected:
				return classes.chipRejected;
			case TimesheetStatusEnum.Paid:
				return classes.chipPaid;
			default:
				return 'default';
		}
	};

	return (
		<Accordion
			defaultExpanded={defaultExpanded}
			elevation={0}
			classes={{ root: classes.expansionPanelRoot }}
			id={`location--${index}`}
		>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				classes={{
					root: classes.expansionPanelSummaryRoot,
					content: classes.expansionPanelSummaryContent,
					expandIcon: classes.expansionPanelSummaryExpandIcon,
				}}
			>
				<Grid
					container
					alignItems={isDesktop ? 'center' : 'flex-start'}
					justifyContent='space-between'
					direction={isDesktop ? 'row' : 'column'}
				>
					<Grid item className={isMobile ? classes.mobileStatus : ''}>
						<Grid container justifyContent='space-between'>
							<Typography variant='body1' color='textPrimary'>
								{bookingLocation.displayName}
							</Typography>
							<Grid className={classes.chipContainer}>
								{showChipBasedOnStatus && timesheetStatus && isMobile ? (
									<Chip
										label={
											timesheetStatus
												? TimesheetStatusEnum[timesheetStatus]
												: TimesheetStatusEnum.Submitted
										}
										classes={{
											root: getStatusColor(
												timesheetStatus
													? timesheetStatus
													: TimesheetStatusEnum.Submitted
											),
										}}
									/>
								) : (
									<></>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item className={classes.timesheetSummary}>
						<Grid container spacing={isMobile ? 4 : 8}>
							{!isMobile ? (
								<Grid item className={classes.chipContainer}>
									{showChipBasedOnStatus && timesheetStatus ? (
										<Chip
											label={
												timesheetStatus
													? TimesheetStatusEnum[timesheetStatus]
													: TimesheetStatusEnum.Submitted
											}
											classes={{
												root: getStatusColor(
													timesheetStatus
														? timesheetStatus
														: TimesheetStatusEnum.Submitted
												),
											}}
										/>
									) : (
										<></>
									)}
								</Grid>
							) : (
								<></>
							)}
							<Grid item className={classes.expansionPanelSummaryItem}>
								<Typography variant='overline' className={classes.overline2}>
									{t('submitTimesheet:INFO.HOURS')}
								</Typography>
								<Typography
									variant='body1'
									className={classes.body1}
									color={
										totalCount(timesheetEntries, 'totalTimeInHours') > 0
											? 'textPrimary'
											: 'textSecondary'
									}
								>
									{`${totalCount(timesheetEntries, 'totalTimeInHours')} 
									${t('submitTimesheet:INFO.HOURS')}`}
								</Typography>
							</Grid>
							<Grid item className={classes.expansionPanelSummaryItem}>
								<Typography variant='overline' className={classes.overline2}>
									{t('submitTimesheet:INFO.UNITS')}
								</Typography>
								<Typography
									variant='body1'
									className={classes.body1}
									color={
										totalCount(timesheetEntries, 'units') > 0
											? 'textPrimary'
											: 'textSecondary'
									}
								>
									{`${totalCount(timesheetEntries, 'units')} 
									${t('submitTimesheet:INFO.UNITS')}`}
								</Typography>
							</Grid>
							<Grid item className={classes.expansionPanelSummaryItem}>
								<Typography variant='overline' className={classes.overline2}>
									{t('submitTimesheet:INFO.MILES')}
								</Typography>
								<Typography
									variant='body1'
									className={classes.body1}
									color={
										totalCount(mileageEntries, 'miles') > 0
											? 'textPrimary'
											: 'textSecondary'
									}
								>
									{`${totalCount(mileageEntries, 'miles')} 
								${t('submitTimesheet:INFO.MILES')}`}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails className={classes.timesheetPanelDetail}>
				<Grid container spacing={4}>
					<Grid item xs={12} id={`location--${index}--time`}>
						<Typography variant='body1' className={classes.columnHeader}>
							TIME
						</Typography>
						<TimesheetReviewTable
							timesheetEntries={timesheetEntries}
							availableRates={timesheet.booking.availableRates}
						/>
					</Grid>
					<Grid item xs={12} id={`location--${index}--miles`}>
						<Typography variant='body1' className={classes.columnHeader}>
							MILES
						</Typography>
						<MileageReviewTable mileageEntries={mileageEntries} />
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
}

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(TimesheetAccordion))));
