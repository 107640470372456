import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import msSalService from '../../services/msSalService';
import { getItemCookie, isExpired } from '../../utils/helpers';

function parse_query_string(query) {
	var vars = query.split('&');
	var query_string = {};
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		var key = decodeURIComponent(pair[0]);
		var value = decodeURIComponent(pair[1]);
		// If first entry with this name
		if (typeof query_string[key] === 'undefined') {
			query_string[key] = decodeURIComponent(value);
			// If second entry with this name
		} else if (typeof query_string[key] === 'string') {
			var arr = [query_string[key], decodeURIComponent(value)];
			query_string[key] = arr;
			// If third or later entry with this name
		} else {
			query_string[key].push(decodeURIComponent(value));
		}
	}
	return query_string;
}

function wait(ms) {
	var start = new Date().getTime();
	var end = start;
	while (end < start + ms) {
		end = new Date().getTime();
	}
}

class Jobboard extends Component {
	state = {
		redirecturl: undefined,
		signedIn: undefined,
		signedInPath: undefined,
	};

	componentWillMount() {
		var aJobboardSignedIN = localStorage.getItem('LocalStorage_JobboardSignedIN');
		var aIdtoken = getItemCookie('msal.idtoken');

		if (aIdtoken === null) {
			//Pause to see if timing is to quick
			wait(2000); //2 seconds in milliseconds
			localStorage.setItem('LocalStorage_JobboardSignedIN', 'true');
			this.setPath();
			msSalService.signIn();
		} else if (isExpired() === true) {
			this.setState({
				redirecturl: encodeURI(
					`${process.env.REACT_APP_LT_API_URI}/Resources/Security/LogoutApplication?url=${process.env.REACT_APP_LOGIN_URL}`
				),
				signedIn: false,
			});
			msSalService.setNewRedirectUri();
			msSalService.logOut();
		} else if (aJobboardSignedIN === 'true' && aIdtoken !== null) {
			localStorage.setItem('LocalStorage_JobboardSignedIN', 'false');
			this.setState({
				redirecturl: localStorage.getItem('LocalStorage_JobboardSignedINPath'),
			});
		} else if (
			(aJobboardSignedIN === 'false' || aJobboardSignedIN === null) &&
			aIdtoken !== null
		) {
			this.setPath();
			this.setState({
				redirecturl: localStorage.getItem('LocalStorage_JobboardSignedINPath'),
			});
		}
	}

	setPath = () => {
		var query_string = window.location.hash.split('?')[1];

		if (query_string !== undefined) {
			var parsed_qs = parse_query_string(query_string);

			if (parsed_qs.path !== undefined) {
				var query_path = parsed_qs.path.split('#')[1];

				localStorage.setItem('LocalStorage_JobboardSignedINPath', query_path);
			}
		} else {
			localStorage.setItem('LocalStorage_JobboardSignedINPath', '/dashboard');
		}
	};

	render() {
		if (this.state.redirecturl) {
			if (this.state.signedIn === false) {
				return <div></div>;
			} else {
				return <Redirect to={this.state.redirecturl} />;
			}
		} else {
			return <div></div>;
		}
	}
}

export default Jobboard;
