import React, { Component } from 'react';
import { withStyles, Button, CircularProgress } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../utils/Application_Constants';
import msSalService from '../../services/msSalService';
import { postVerifyEmail, postVerifyEmailSF } from '../../services/ProfileServices/profileService';
import xImg from '../../assets/images/email-denied.svg';
import Logo from '../../assets/logo/locumtenens_com_logo.svg';
import { getItemCookie, parse_query_string } from '../../utils/helpers';
import { ltdomain } from '../../utils/helpers';

const styles = (theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		background: '#FFFFFF',
		fontFamily: 'sans-serif',
		color: '#757B92',
		fontSize: '1rem',
	},
	loginContainer: {
		textAlign: 'center',
		margin: '0 auto',
		padding: '70px',
		zIndex: '1',
	},
	divOne: {
		marginBottom: '50px',
	},
	divTwo: {
		textTransform: 'uppercase',
		background: '#0076BB',
		padding: '10px 20px',
		color: 'white',
		textDecoration: 'none',
		fontWeight: 'bold',
		marginTop: '70px',
		clear: 'both',
	},
	image: {
		width: '80%',
	},
	buttonClass: {
		fontWeight: '700',
		width: '50%',
		background: COLORS.DENIM,
		color: 'White',
		justifyContent: 'center',
		borderRadius: '0px',
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		marginTop: '70px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
});

class RegistrationPage extends Component {
	constructor() {
		super();
		this.state = {
			result: undefined,
		};
	}

	async componentDidMount() {
		const EmailInvitation = localStorage.getItem('LocalStorage_EmailInvitation');
		//const EmailInvitation = getLocalStorageWithExpiry('LocalStorage_EmailInvitation');
		const EmailInvitationToken = localStorage.getItem('LocalStorage_EmailInvitationToken');
		//const EmailInvitationToken = getLocalStorageWithExpiry('LocalStorage_EmailInvitationToken');

		var JWTHelper = require('jwthelper');
		var helper = JWTHelper.createJWTHelper();
		var reset_password_link;

		var query_string = window.location.hash.split('?')[1];
		var parsed_qs;
		var decoded;
		if (query_string !== undefined) {
			parsed_qs = parse_query_string(query_string);
			if (!parsed_qs.token) {
				window.location.href = '/#/500';
				return; // Invalid request
			}
			decoded = helper.decode(parsed_qs.token);
		}

		//This page handles Pre and Post Email Invitation as well as Email Verification
		if (EmailInvitation !== 'true') {
			if (parsed_qs === undefined) {
				this.setState({
					result: false,
					action: 'emailInvitation',
					isauth: false,
				});
			} else {
				const data = {
					action: parsed_qs.action,
				};
				var response;
				//Validates Email Token for EmailVerification and EmailInvitation, as well as updates B2C VerifiedDate for EmailVerification
				response = await postVerifyEmail(data, parsed_qs.token);

				if (response !== 'verified') {
					//Patch for TDS-2686 to maintain JobBoard Routing Information if this was used
					var aJobboardSignedIN = localStorage.getItem('LocalStorage_JobboardSignedIN');
					var aJobboardSignedPath = localStorage.getItem(
						'LocalStorage_JobboardSignedINPath'
					);
					//

					//Clear Authentication Information
					msSalService.clearAuthInfo();

					//Patch for TDS-2686 to maintain JobBoard Routing Information if this was used
					if (aJobboardSignedIN === 'true') {
						localStorage.setItem('LocalStorage_JobboardSignedIN', aJobboardSignedIN);
						localStorage.setItem(
							'LocalStorage_JobboardSignedINPath',
							aJobboardSignedPath
						);
					}
					//

					if (parsed_qs.action === 'emailInvitation') {
						//This is EmailInvitation Pre Step set flag for return to Post Step when return from B2C MFA Flow
						//Email Invitation Pre Process and Email Verification

						console.log('emailInvitation goto B2C_1A_PasswordReset_UserName_SMS');
						localStorage.setItem('LocalStorage_EmailInvitation', 'true');
						//setLocalStorageWithExpiry('LocalStorage_EmailInvitation', 'true', 300000);
						localStorage.setItem('LocalStorage_EmailInvitationToken', parsed_qs.token);
						//setLocalStorageWithExpiry('LocalStorage_EmailInvitationToken', parsed_qs.token, 300000);

						reset_password_link = encodeURI(
							process.env.REACT_APP_B2C_BASE_URI +
								'/oauth2/v2.0/authorize?p=B2C_1A_PasswordReset_UserName_SMS&client_id=' +
								process.env.REACT_APP_CLIENT_ID +
								'&scope=openid&response_type=id_token&prompt=login&login_hint=' +
								decoded.email +
								'&state=null&nonce=defaultNonce&redirect_uri=' +
								window.location.origin
						);
					}
				}

				let waitTime = 1;

				if (parsed_qs.action !== 'emailInvitation') {
					//only wait 7 seconds for emailVerification to allow time to pickup B2C VerifiedDate Change during msSalService.signIn();
					//seems to be a slight delay to make that data available from the B2C server for MSAL signIn process.  We MUST pickup that
					//date so its in aout token/usercontext
					waitTime = 7000;
				}

				//wait 7 seconds to allow time to pickup B2C VerifiedDate Change during msSalService.signIn();
				//seems to be a slight delay to make that data available from the B2C server for MSAL signIn process.  We MUST pickup that
				//date so its in the token/usercontext
				setTimeout(
					function () {
						//Start the timer
						this.setState({
							emailToken: parsed_qs.token,
							action: parsed_qs.action,
							email: decoded.email,
							result: response,
							resetPasswordURL: reset_password_link,
							isauth: false,
						});
					}.bind(this),
					waitTime
				);
			}
		} else if (EmailInvitation === 'true') {
			if (parsed_qs !== undefined && parsed_qs.action === 'emailInvitation') {
				const data = {
					action: parsed_qs.action,
				};
				//Validates Email Token for EmailVerification and EmailInvitation, as well as updates B2C VerifiedDate for EmailVerification
				response = await postVerifyEmail(data, parsed_qs.token);
				if (response !== 'verified') {
					//This is EmailInvitation Pre Step set flag for return to Post Step when return from B2C MFA Flow
					//This is EmailInvitation Pre Step set flag for return to Post Step when return from B2C MFA Flow
					console.log('emailInvitation goto B2C_1A_PasswordReset_UserName_SMS');
					localStorage.setItem('LocalStorage_EmailInvitation', 'true');
					//setLocalStorageWithExpiry('LocalStorage_EmailInvitation', 'true', 300000);
					localStorage.setItem('LocalStorage_EmailInvitationToken', parsed_qs.token);
					//setLocalStorageWithExpiry('LocalStorage_EmailInvitationToken', parsed_qs.token, 300000);

					reset_password_link = encodeURI(
						process.env.REACT_APP_B2C_BASE_URI +
							'/oauth2/v2.0/authorize?p=B2C_1A_PasswordReset_UserName_SMS&client_id=' +
							process.env.REACT_APP_CLIENT_ID +
							'&scope=openid&response_type=id_token&prompt=login&login_hint=' +
							decoded.email +
							'&state=null&nonce=defaultNonce&redirect_uri=' +
							window.location.origin
					);
				}

				let waitTime = 1;

				if (parsed_qs.action !== 'emailInvitation') {
					//only wait 7 seconds for emailVerification to allow time to pickup B2C VerifiedDate Change during msSalService.signIn();
					//seems to be a slight delay to make that data available from the B2C server for MSAL signIn process.  We MUST pickup that
					//date so its in aout token/usercontext
					waitTime = 7000;
				}

				//wait 7 seconds to allow time to pickup B2C VerifiedDate Change during msSalService.signIn();
				//seems to be a slight delay to make that data available from the B2C server for MSAL signIn process.  We MUST pickup that
				//date so its in the token/usercontext
				setTimeout(
					function () {
						//Start the timer
						this.setState({
							emailToken: parsed_qs.token,
							action: parsed_qs.action,
							email: decoded.email,
							result: response,
							resetPasswordURL: reset_password_link,
							isauth: false,
						});
					}.bind(this),
					waitTime
				);
			} else {
				//Email Invitation Post Process
				var decodeMfaTOken = helper.decode(getItemCookie('msal.idtoken'));
				var decodeEmailInvitationToken = helper.decode(EmailInvitationToken);

				//In the case of a user logged in with UID and PWD but not yet verified to enter into MFA
				//upon returning from MFA immediately wait 5 seconds to make available the new verified date from the msal.idtoken token
				if (decodeEmailInvitationToken !== null && decodeEmailInvitationToken.tfp) {
					if (decodeMfaTOken === null) {
						msSalService.signIn();
					} else {
						localStorage.removeItem('LocalStorage_EmailInvitation');
						localStorage.removeItem('LocalStorage_EmailInvitationToken');

						let data = {
							action: 'emailInvitationPost',
							verifiedDate: decodeMfaTOken.extension_VerifiedDate,
							sfExternalId: decodeMfaTOken.extension_SfExternalId,
						};
						response = await postVerifyEmailSF(data, EmailInvitationToken);
						window.location.reload();
					}
				} else {
					//Do what it has always been doing
					//Cleanup Flags used for returning from B2C MFA Process
					localStorage.removeItem('LocalStorage_EmailInvitation');
					localStorage.removeItem('LocalStorage_EmailInvitationToken');

					var data = {
						action: 'emailInvitationPost',
					};
					if (decodeMfaTOken) {
						data = {
							action: 'emailInvitationPost',
							verifiedDate: decodeMfaTOken.extension_VerifiedDate,
							sfExternalId: decodeMfaTOken.extension_SfExternalId,
						};

						if (!!decodeMfaTOken.extension_VerifiedDate) {
							console.log('USER IS ALREADY VERIFIED');
							this.setState({
								result: 'verified',
								action: 'emailVerification',
								isauth: true,
							});
							return;
						}
					}

					//var decodeEmailInvitationToken = helper.decode(EmailInvitationToken);
					if (!decodeEmailInvitationToken) {
						console.log('NO EMAIL TOKEN');
						response = false;
					} else {
						//Checks to see if need to updated saleforce verified date using the email token or b2c token api call.
						if (decodeEmailInvitationToken.tfp) {
							//in some cases the verified date is not yet in the B2C token returned until after the msSalService.signIn(); call is made below to ensure it is
							//This check
							response = await postVerifyEmailSF(data, EmailInvitationToken);
						} else {
							response = await postVerifyEmail(data, EmailInvitationToken);
						}
					}

					if (response === false) {
						this.setState({
							result: response,
							action: 'emailInvitation',
							isauth: false,
						});
					} else if (response === true) {
						msSalService.signIn();
					} else if (response === 'verified') {
						this.setState({
							result: response,
							action: 'emailInvitation',
							isauth: true,
						});
					}
				}
			}
		}
	}

	render() {
		const { classes } = this.props;

		if (this.state.result === undefined) {
			// || this.state.isauth===true) {
			return (
				<div className={classes.container}>
					<div id='login-container' className={classes.loginContainer}>
						<div className={classes.divOne}>
							<p>Please wait while we process your request</p>

							<div className={classes.progressWrapper}>
								<CircularProgress
									classes={{ root: classes.progressRoot }}
									color='primary'
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}

		if (this.state.result === false && this.state.action === 'emailVerification') {
			return (
				<div className={classes.container}>
					<div id='login-container' className={classes.loginContainer}>
						<img src={Logo} className={classes.image} alt='LocumTenens Logo' />
						<h2>Verification email expired</h2>
						<div className={classes.divOne}>
							<p>
								Your invitation has expired. Please contact your recruiter to resend
								the invitation so you can access our online features, including,
								present data, assignment details and your profile.
							</p>

							<p>
								Please note: For security purposes, this invitation expires after 24
								hours.
							</p>
						</div>

						<div>
							<Button
								onClick={this.authenticationLogin}
								className={classes.buttonClass}
							>
								Log In
							</Button>
						</div>
					</div>
				</div>
			);
		}

		if (this.state.result === true && this.state.action === 'emailVerification') {
			return (
				<div className={classes.container}>
					<div id='login-container' className={classes.loginContainer}>
						<img
							src={ltdomain() + '/images/email-confirm.gif'}
							className={classes.image}
							alt='Check Mark'
						/>
						<h2>Email address confirmed</h2>
						<div className={classes.divOne}>
							<p>
								You have successfully confirmed your email address. Use the link
								below to return to the login screen.
							</p>

							<p>Please use your email address to log in.</p>
						</div>

						<div>
							<Button
								onClick={this.authenticationLogin}
								className={classes.buttonClass}
							>
								Log In
							</Button>
						</div>
					</div>
				</div>
			);
		}

		if (this.state.result === true && this.state.action === 'emailInvitation') {
			window.location = this.state.resetPasswordURL;
			//Add CircularProgress when redirecting to B2C
			return (
				<div className={classes.container}>
					<div id='login-container' className={classes.loginContainer}>
						<div className={classes.divOne}>
							<div className={classes.progressWrapper}>
								<CircularProgress
									classes={{ root: classes.progressRoot }}
									color='primary'
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}

		if (this.state.result === false && this.state.action === 'emailInvitation') {
			return (
				<div className={classes.container}>
					<div id='login-container' className={classes.loginContainer}>
						<img src={Logo} className={classes.image} alt='LocumTenens Logo' />
						<img src={xImg} className={classes.image} alt='X mark' />
						<h2>Invitation email expired</h2>
						<div className={classes.divOne}>
							<p>
								Please contact your recruiter and have them send a new invitation
								email.
							</p>
							<p>
								If you have already registered, please click the following link{' '}
								<a href={ltdomain()} className={classes.clickableSpan}>
									www.locumtenens.com
								</a>{' '}
								to be directed to the login page.
							</p>
						</div>
					</div>
				</div>
			);
		}

		if (this.state.result === 'verified' && this.state.action === 'emailInvitation') {
			return (
				<div className={classes.container}>
					<div id='login-container' className={classes.loginContainer}>
						<img src={Logo} className={classes.image} alt='LocumTenens Logo' />
						<h2>Email already verified</h2>
						<div className={classes.divOne}>
							<p>
								Good News! You've already verified your email with us. If you are
								looking to log in, please click{' '}
								<a
									href='https://portal.locumtenens.com/#/dashboard'
									className={classes.clickableSpan}
								>
									here
								</a>
								.
							</p>
						</div>
					</div>
				</div>
			);
		}

		if (this.state.result === 'verified' && this.state.action === 'emailVerification') {
			return (
				<div className={classes.container}>
					<div id='login-container' className={classes.loginContainer}>
						<img src={Logo} className={classes.image} alt='LocumTenens Logo' />
						<h2>Verification email accepted</h2>
						<div className={classes.divOne}>
							<p>
								Your registration has already been accepted. Please click the
								following link{' '}
								<a href={ltdomain()} className={classes.clickableSpan}>
									www.locumtenens.com
								</a>{' '}
								to be directed to the login page.
							</p>
						</div>
					</div>
				</div>
			);
		}

		//When an unhandled state is reached in the registration process...clear the information and prompt user to try again.
		msSalService.clearAuthInfo();
		return (
			<div className={classes.container}>
				<div id='login-container' className={classes.loginContainer}>
					<img src={Logo} className={classes.image} alt='LocumTenens Logo' />
					<h2>Email issue detected</h2>
					<div className={classes.divOne}>
						<p>Temporary Email issue detected. Please wait 5 minutes and try again.</p>
					</div>
				</div>
			</div>
		);
	}

	authenticationLogin = async () => {
		try {
			await msSalService.signIn();
		} catch (error) {
			console.error(error);
		}
	};
}

export default withTranslation()(withStyles(styles)(RegistrationPage));
