export const formatErrorMessage = (error) => {
	const errorAsArray = !!error.errors && Array.isArray(error.errors);
	const errorAsObject = !!error.message;
	const errorAsString = typeof error === 'string';
	const message = errorAsArray
		? `Error: ${error.errors.map(({ errorMessage }) => errorMessage)}`
		: errorAsString
		? error
		: errorAsObject
		? error.message
		: 'An error has occurred.';
	return message;
};
