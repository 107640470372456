import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {
	CircularProgress,
	NativeSelect,
	ThemeProvider,
	RadioGroup,
	Radio,
	FormControlLabel,
} from '@material-ui/core';
import TextFieldComponent from '../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import {
	COLORS,
	branchService,
	militaryStatus,
	CRED_STATE,
} from '../../../../../utils/Application_Constants';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Controller, useForm } from 'react-hook-form';
import DatePickerComponent from '../../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import RouteDeparturePrompt from '../../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import { makeStyles } from '@material-ui/core/styles';
import createTheme from '@material-ui/core/styles/createTheme';
import { getWorkHistories, postWorkHistories } from '../../../../../services/Credentialing';
import moment from 'moment';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { convertToDateIgnoringTime } from '../../../../../utils/helpers';
import {
	militaryExperienceSectionPointsCheck,
	militaryExperienceSectionCheck,
} from '../../../../../utils/CredentialingAppUtility';
import Alert from '@material-ui/lab/Alert';
import { reactPlugin } from '../../../../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBoundaryPage from '../../../../ContentComponents/Credentials/PageComponenets/ErrorBoundaryPage';

const styles = makeStyles((theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '10px',
		paddingBottom: '10px',
		fontSize: '22px',
		fontWeight: '700',
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
	},
	secondaryHeaderArea: {
		alignItems: 'center',
		maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '46px',
		marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	form: {
		width: '100%',
		margin: theme.spacing(1, 0, 5),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	// inputLabel: {
	//     top: '-5px',
	//     position: 'relative',
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	//     marginTop: theme.spacing(-1),
	// },
	// selectInputLabel: {
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	// },
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: `${COLORS.RAVEN} `,
			borderWidth: 1,
		},
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
	},
	cssFocused: {},

	disabled: {},
	disabledInput: {
		opacity: 0.6,
	},
	disabledSelect: {
		opacity: 0.6,
		pointerEvents: 'none',
		cursor: 'default',
	},
	focused: {},
	marginLeft12: {
		marginLeft: 12,
	},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	errorColor: {
		padding: '5px',
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	topPad: {
		//paddingTop: '0.5rem',
		//paddingBottom:'0.5vmax'
	},
	notchedOutline: {
		borderWidth: '1px',
		borderRadius: 1,
	},
	radioGroup: {
		marginLeft: '8px',
	},
	inputRadio: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
	},
	setWidthSelectGroup: {
		top: '-23px',
		width: 'calc(100% - 1px)',
		marginTop: '10px',
		marginRight: '10px',
	},
	setWidthSelects: {
		minWidth: '100%',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	container: {
		paddingBottom: '50px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},
	cssLabel: {
		padding: '5px',
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	selectContainerOffset: {
		padding: '0 16px 16px 16px !important',
	},
	selectContainer: {
		padding: '3px !important',
	},
	selectContainerGroup: {
		[theme.breakpoints.down('sm')]: {
			padding: '3px 10px 0px 10px !important',
		},
		minWidth: '97%',
		height: '75px !important',
	},
	multiGridOffset: {
		paddingTop: '13px !important',
	},
	leftPad30: {
		paddingLeft: '30px !important',
	},
	marginBottom20Neg: {
		marginBottom: -20,
	},
	marginTop16: {
		marginTop: '8px',
	},
	dateDischarge: {
		width: '97%',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '10px',
		},
	},
	containerGrid: {
		width: '100%',
		height: '300px',
	},
	infoMessage: {
		color: COLORS.RAVEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '14px',
		fontWeight: '500',
		paddingLeft: '10px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingBottom: '15px',
	},
	additionalInfoMessage: {
		color: COLORS.RAVEN,
		float: 'left',
		textAlign: 'left',
		fontSize: '14px',
		fontWeight: '500',
		paddingLeft: '10px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '20px',
		},
		paddingBottom: '15px',
		marginTop: '-0.5rem',
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingBottom: '10px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
	containerSM: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% + 13px) !important',
		},
	},
}));

const MilitaryExperience = ({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	setCredAppError,
	updateFlags,
	activePath,
	credState,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) => {
	const normalTheme = createTheme({
		overrides: {
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '18px',
					borderRadius: '1px !important',
				},
			},
		},
	});
	const errorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '17px',
					borderRadius: '1px !important',
					border: '2px solid red !important',
				},
			},
		},
	});

	const { register, control, handleSubmit, reset, errors, getValues, formState, watch } = useForm(
		{
			mode: 'onBlur',
			defaultValues: {
				serviceBranch: '',
				militaryStatus: '',
				dischargeDate: null,
			},
		}
	);
	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};
	const [isLoaded, setIsLoaded] = useState(false);
	const [highlights, setHighlights] = useState([]);
	const [isMilitaryExp, setIsMilitaryExp] = useState(null);
	const [, setLocalShouldHighlightFields] = useState(false);

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;
			await getWorkHistories(externalId, 'militaryExperience').then(function (data) {
				const { serviceBranch } = data;
				console.log('response is', JSON.stringify(data));

				if (!!serviceBranch) {
					setIsMilitaryExp('true');
				} else {
					setIsMilitaryExp('false');
				}
				data['dischargeDate'] = convertToDateIgnoringTime(data['dischargeDate']);

				if (data['militaryStatus'] === null) {
					data['militaryStatus'] = '';
				}
				if (data['serviceBranch'] === null) {
					data['serviceBranch'] = '';
				}

				flags.dataSaved = false;
				updateSectionCompletion(sectionCompletion);
				updateFlags(flags);
				reset(data);
				setIsLoaded(true);

				if (shouldHighlightFields) {
					setLocalShouldHighlightFields(true);
					let status = {
						status: 'incomplete',
						missingFields: [],
					};

					status = militaryExperienceSectionCheck(data);

					if (!!status.missingFields) {
						//alert(`missingFields: ${status.missingFields}`)
						setHighlights(status.missingFields);
					}
				}
			});

			setSaveHandler(
				MilitaryExperience,
				'Military Experience',
				handleSubmit(async (data) => await onSubmit(data))
			);
		})();
	}, [reset]);

	function updateHighlight() {
		//if(localShouldHighlightFields) {
		let status = {
			status: 'incomplete',
			missingFields: [],
		};
		status = militaryExperienceSectionCheck(watch());
		setHighlights(status.missingFields);

		//}
	}

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		flags.validationError = false;

		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}

		if (!_.isEmpty(formState.errors) && flags.validationError === false) {
			flags.validationError = true;
		} else {
			if (_.isEmpty(formState.errors) && flags.validationError === true) {
				flags.validationError = false;
			}
		}
		// console.log(' fields with error ' + JSON.stringify(highlights));
		if (highlights.length > 0) {
			flags.validationError = true;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	const classes = styles();

	async function onSubmit(data) {
		let success = false;

		const { externalId } = UserContext;
		data['clinicianId'] = externalId;

		if (isMilitaryExp === false || isMilitaryExp === 'false') {
			data['dischargeDate'] = '';
			data['militaryStatus'] = '';
			data['serviceBranch'] = '';
		}
		data['dischargeDate'] =
			getValues('militaryStatus') === 'Honorable' ||
			getValues('militaryStatus') === 'Dishonorable'
				? moment(data['dischargeDate']).toISOString(true)
				: null;
		const jsonData = JSON.stringify(data);
		await postWorkHistories(externalId, jsonData, 'militaryExperience');
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;

		sectionCompletion.workHistory.militaryExperience.current =
			await militaryExperienceSectionPointsCheck(data, sectionCompletion);
		updateSectionCompletion(sectionCompletion);

		setIsLoaded(true);

		updateFlags(flags);
		return success;
	}

	if (!isLoaded) {
		return (
			<div className={classes.progress}>
				<CircularProgress />
			</div>
		);
	}
	const showDischargeDate = () => {
		return (
			<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
				<InputLabel
					shrink
					htmlFor='dischargeDate'
					classes={{ root: classes.selectInputLabel }}
					error={
						errors?.dischargeDate ||
						(!!highlights && highlights.includes('dischargeDate'))
					}
					required
				>
					{t('credentials:MILITARY_EXPERIENCE.DATE_OF_DISCHARGE')}
				</InputLabel>
				<ThemeProvider theme={datePickerTheme}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Controller
							control={control}
							rules={{
								validate: (date) => dateValidation(date),
							}}
							name='dischargeDate'
							render={({ onChange, name, onBlur, value }) => (
								<DatePickerComponent
									onChange={onChange}
									onClose={() => {
										updateHighlight();
									}}
									name={name}
									onBlur={() => {
										updateHighlight();
									}}
									format='MM/dd/yyyy'
									value={value}
									placeholder='MM/DD/YYYY'
									clearable
									variant='inline'
									autoOk='true'
									disabled={credState === CRED_STATE.CLOSED}
									minDateMessage={
										<span
											className={`${classes.cssLabel} ${classes.marginLeft12}`}
										>
											{t('credentials:VALIDATION_MESSAGE.DATE')}
										</span>
									}
									maxDateMessage={
										<span
											className={`${classes.cssLabel} ${classes.marginLeft12}`}
										>
											{t('credentials:VALIDATION_MESSAGE.DATE')}
										</span>
									}
									invalidDateMessage={
										<span
											className={`${classes.cssLabel} ${classes.marginLeft12}`}
										>
											{t('credentials:VALIDATION_MESSAGE.DATE')}
										</span>
									}
									margin='normal'
									theme={datePickerTheme}
									error={
										errors?.dischargeDate ||
										(!!highlights && highlights.includes(`dischargeDate`))
									}
									KeyboardButtonProps={{
										'aria-label': t(
											'credentials:MILITARY_EXPERIENCE.DATE_OF_DISCHARGE'
										),
									}}
									helperText={
										!!errors?.dischargeDate &&
										t('credentials:VALIDATION_MESSAGE.DATE')
									}
									InputProps={{
										classes: {
											disabled: classes.disabledInput,
											root: classes.cssOutlinedInput,
										},
									}}
									inputVariant='outlined'
								/>
							)}
						/>
						{/* {isDischargeDateRequired &&
                    <span
                        className={`${classes.cssLabel} ${classes.marginLeft12}`}>{t('credentials:VALIDATION_MESSAGE.DATE')}</span>}*/}
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			</FormControl>
		);
	};

	const renderHaveMilitaryExp = () => {
		return (
			<div>
				<Grid item xs={12} className={classes.gridRoot}>
					<FormControl
						classes={{ root: classes.setWidthTo100OnMobile }}
						className={classes.radioGroup}
					>
						<InputLabel
							shrink
							htmlFor={`isMilitaryExp`}
							classes={{ root: classes.inputRadio }}
							className={
								(errors?.isMilitaryExp ||
									(!!highlights && highlights.includes(`isMilitaryExp`))) &&
								classes.errorColor
							}
							required
						>
							{t(`credentials:MILITARY_EXPERIENCE.HAVE_MILITARY_EXP`)}
						</InputLabel>
						<div>
							<RadioGroup
								row
								aria-label='completed'
								name={`isMilitaryExp`}
								defaultValue={isMilitaryExp}
								classes={{ root: classes.radioGroupLabel }}
								onBlur={() => {
									updateHighlight();
								}}
								onChange={() => {
									setTimeout(() => {
										updateHighlight();
									}, 50);
								}}
							>
								<FormControlLabel
									value='true'
									control={
										<Radio
											color='primary'
											style={{ color: COLORS.RAVEN }}
											name={'isMilitaryExp'}
											inputRef={register()}
											disableRipple
											size='small'
											disabled={
												credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN
											}
											className={
												credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN
													? classes.disabledInput
													: classes.normalInput
											}
										/>
									}
									label={
										<span
											style={{
												fontSize: '16px',
												color: COLORS.RAVEN,
											}}
										>
											Yes
										</span>
									}
								/>
								<FormControlLabel
									value='false'
									control={
										<Radio
											color='primary'
											style={{ color: COLORS.RAVEN }}
											name={'isMilitaryExp'}
											inputRef={register()}
											disableRipple
											size='small'
											disabled={
												credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN
											}
											className={
												credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN
													? classes.disabledInput
													: classes.normalInput
											}
										/>
									}
									label={
										<span
											style={{
												fontSize: '16px',
												color: COLORS.RAVEN,
											}}
										>
											No
										</span>
									}
								/>
							</RadioGroup>
							{(errors?.isMilitaryExp ||
								(!!highlights && highlights.includes(`isMilitaryExp`))) && (
								<span className={classes.cssLabel}>
									{t('credentials:VALIDATION_MESSAGE.ATTESTATION_QUESTION')}
								</span>
							)}
						</div>
					</FormControl>
				</Grid>
				<h3 className={classes.additionalInfoMessage}>
					{t(`credentials:MILITARY_EXPERIENCE.ADDITIONAL_MESSAGE`)}
				</h3>
			</div>
		);
	};

	return (
		<AppInsightsErrorBoundary
			onError={() => (
				<ErrorBoundaryPage errorCode='500' flags={flags} updateFlags={updateFlags} />
			)}
			appInsights={reactPlugin}
		>
			<Grid className={classes.containerGrid}>
				<Grid className={classes.leftPad30}>
					<Grid item xs={12}>
						<h3 className={classes.header}>
							{t(`credentials:MILITARY_EXPERIENCE.TITLE`)}
						</h3>
						{credState === CRED_STATE.CLOSED ||
						credState === CRED_STATE.PARTIALLY_OPEN ? (
							<Grid item xs={12} md={10} className={classes.messageGrid}>
								<Alert
									severity='error'
									className={classes.alertMessage}
									icon={false}
								>
									{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
								</Alert>
							</Grid>
						) : null}
						<h3 className={classes.infoMessage}>
							{t(`credentials:MILITARY_EXPERIENCE.INFO_MESSAGE`)}
						</h3>
					</Grid>
				</Grid>
				<Grid className={classes.leftPad30}>{renderHaveMilitaryExp()}</Grid>
				{(watch('isMilitaryExp') === 'true' || watch('isMilitaryExp')) === true ? (
					<Container component='main' maxWidth='md'>
						<CssBaseline />
						<div>
							<Grid
								container
								item
								xs={12}
								md={8}
								spacing={4}
								classes={{ root: classes.gridRoot }}
								className={classes.marginTop16}
							>
								{/* <Grid container item md={12} spacing={4} className={classes.containerSM}>     */}
								<form
									className={classes.form}
									noValidate
									onSubmit={handleSubmit(onSubmit)}
								>
									<RouteDeparturePrompt
										blockedBackNavigation={blockedBackNavigation}
										when={
											!flags.dataSaved
												? !!Object.keys(formState.dirtyFields).length
												: false
										}
									/>

									<Grid
										item
										xs={12}
										md={10}
										classes={{ root: classes.selectContainerGroup }}
										className={classes.topPad}
									>
										<FormControl classes={{ root: classes.setWidthSelects }}>
											<InputLabel
												shrink
												htmlFor='serviceBranch'
												classes={{ root: classes.selectInputLabel }}
												required={watch(`militaryStatus`) !== ''}
												error={
													(errors?.serviceBranch ||
														(!!highlights &&
															highlights.includes(
																'serviceBranch'
															))) &&
													classes.errorColor
												}
											>
												{t('credentials:MILITARY_EXPERIENCE.BRANCH')}
											</InputLabel>
											<ThemeProvider
												theme={
													errors?.serviceBranch ||
													(!!highlights &&
														highlights.includes('serviceBranch'))
														? errorTheme
														: normalTheme
												}
											>
												<NativeSelect
													name='serviceBranch'
													inputRef={register()}
													className={
														credState === CRED_STATE.CLOSED ||
														credState === CRED_STATE.PARTIALLY_OPEN
															? classes.disabledSelect
															: classes.normalInput
													}
													onBlur={() => {
														updateHighlight();
													}}
													onChange={() => {
														updateHighlight();
													}}
													input={
														<TextFieldComponent
															style={{
																border: `0px solid ${COLORS.RAVEN}`,
															}}
															classes={{
																input: classes.setWidthTo100OnMobile,
															}}
														/>
													}
													InputProps={{
														readOnly:
															credState === CRED_STATE.CLOSED ||
															credState === CRED_STATE.PARTIALLY_OPEN,
													}}
												>
													<option key={''} value={null}></option>
													{branchService.map((item) => (
														<option key={item.value} value={item.value}>
															{item.label}
														</option>
													))}
												</NativeSelect>
											</ThemeProvider>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={12}
										md={10}
										classes={{ root: classes.selectContainerGroup }}
										className={classes.topPad}
									>
										<FormControl classes={{ root: classes.setWidthSelects }}>
											<InputLabel
												shrink
												htmlFor='militaryStatus'
												classes={{ root: classes.selectInputLabel }}
												required={watch(`serviceBranch`) !== ''}
												className={
													(errors?.militaryStatus ||
														(!!highlights &&
															highlights.includes(
																'militaryStatus'
															))) &&
													classes.errorColor
												}
											>
												{t('credentials:MILITARY_EXPERIENCE.STATUS')}
											</InputLabel>
											<ThemeProvider
												theme={
													errors?.militaryStatus ||
													(!!highlights &&
														highlights.includes('militaryStatus'))
														? errorTheme
														: normalTheme
												}
											>
												<NativeSelect
													inputRef={register()}
													onBlur={() => {
														updateHighlight();
													}}
													onChange={() => {
														updateHighlight();
													}}
													name='militaryStatus'
													className={
														credState === CRED_STATE.CLOSED
															? classes.disabledSelect
															: classes.normalInput
													}
													input={
														<TextFieldComponent
															style={{
																border: `0px solid ${COLORS.RAVEN}`,
															}}
															classes={{
																input: classes.setWidthTo100OnMobile,
															}}
														/>
													}
													InputProps={{
														readOnly: credState === CRED_STATE.CLOSED,
													}}
												>
													<option key={''} value={null}></option>
													{militaryStatus.map((item) => (
														<option key={item.value} value={item.value}>
															{item.label}
														</option>
													))}
												</NativeSelect>
											</ThemeProvider>
										</FormControl>
										{/* {errors.militaryStatus &&
                            <span
                                className={`${classes.cssLabel} ${classes.marginLeft12}`}>{t('credentials:VALIDATION_MESSAGE.MILITARY_STATUS')}</span>}*/}
									</Grid>
									<Grid
										item
										xs={12}
										md={12}
										classes={{ root: classes.dateDischarge }}
										className={classes.topPad}
									>
										{watch(`militaryStatus`) === 'Honorable' ||
										watch(`militaryStatus`) === 'Dishonorable'
											? showDischargeDate()
											: null}
									</Grid>
								</form>
							</Grid>
						</div>
					</Container>
				) : null}
			</Grid>
		</AppInsightsErrorBoundary>
	);
};
export default WithContexts(withTranslation()(MilitaryExperience));
