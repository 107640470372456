import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper, CircularProgress, Grid, Chip, Button } from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import { getClinicianPaymentElectionPreferences } from '../../../services/ProfileServices/profileService';
import AdobeSignDocumentModal from '../AdobeSignModal/AdobeSignDocumentModal';
import msSalService from '../../../services/msSalService';
import PlayerModal from '../../GlobalComponents/PlayerModal/PlayerModal';

const styles = (theme) => ({
	header: {
		color: COLORS.ST_TROPAZ,
		textAlign: 'left',
		paddingTop: '36px',
		fontSize: '17px',
		fontWeight: '700',
	},
	columnLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '12px',
	},
	helpLabel: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '900',
		fontSize: '14px',
	},
	progress: {
		height: '100vh',
	},
	paperRoot: {
		paddingBottom: '60px',
	},
	bottomBorder: {
		border: `1px solid ${COLORS.MILANO_RED}`,
	},
	helpText: {
		marginTop: '20px',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			padding: '0 32px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '5px 10px',
		},
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			padding: '0 32px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '5px 10px',
		},
	},
	itemRow: {
		borderBottom: `2px solid ${COLORS.LIGHT_GRAY}`,
		padding: '8px 0 8px 32px',
	},
	setWidthTo100: {
		width: '100%',
	},
	leftAlign: {
		padding: '0px',
	},
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.RAVEN,
		},
	},
	chipRootGreen: {
		border: `1px solid #009639`,
		color: COLORS.KELLY_GREEN,
		backgroundColor: '#f0f8ff',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
		},
	},
	chipRootRed: {
		border: `1px solid ${COLORS.MILANO_RED}`,
		color: COLORS.MILANO_RED,
		backgroundColor: '#ffcccb',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
		},
	},
	chipRootBlue: {
		border: '1px solid #0077C8',
		color: '#0077C8',
		backgroundColor: '#E5F1FA',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
		},
	},
	chipRootYellow: {
		border: `1px solid #FFC56E`,
		color: '#FFC56E',
		backgroundColor: '#FFF9F0',
		borderRadius: '5px',
		align: 'left',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
		},
	},
	chipLabel: {
		fontWeight: '500',
		fontSize: '14px',
	},
	disabledInput: {
		opacity: '0.6',
	},
	docLink: {
		color: '#0077C8',
		cursor: 'hand',
		textDecoration: 'underline',
		marginLeft: '-15px',
		marginRight: '-15px',
	},
	reason: {
		color: COLORS.MILANO_RED,
		paddingLeft: '20px',
	},
	pointerCursor: {
		cursor: 'pointer',
	},
	tips: {
		color: '#0077C8',
	},
	tipIcon: {
		height: '13pt',
		verticalAlign: 'middle',
		color: '#0077C8',
		marginLeft: '15px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		textAlign: 'center',
		cursor: 'pointer',
	},
});
class PaymentElectionPreferences extends Component {
	constructor(props) {
		super(props);
		this.state = {
			payPreferences: {},
			showingDocument: false,
			signingUrl: null,
			signingUrlsExist: false,
			showingVideo: false,
			videoUrl: null,
		};
	}

	async componentDidMount() {
		const { externalId, setSaveHandler, setPreferencePageError } = this.props;
		const state = { ...this.state };
		state.payPreferences = await getClinicianPaymentElectionPreferences(externalId).catch(
			(e) => {
				setPreferencePageError(e);
			}
		);

		this.setState({
			payPreferences: state.payPreferences,
			signingUrlsExist:
				state.payPreferences.w9SigningUrl && state.payPreferences.ddSigningUrl
					? true
					: false,
		});
		setSaveHandler(this, null); // Nothing to save
	}

	showW9 = async () => {
		await this.setState({
			showingDocument: true,
			signingUrl: this.state.payPreferences.w9SigningUrl,
		});
	};

	showDD = async () => {
		await this.setState({
			showingDocument: true,
			signingUrl: this.state.payPreferences.ddSigningUrl,
		});
	};

	showW9Video = async () => {
		await this.setState({
			showingVideo: true,
			videoUrl: 'https://video.vidyard.com/watch/p4phL6gtFzMMT5PA2TTK7x',
		});
	};

	showDDVideo = async () => {
		await this.setState({
			showingVideo: true,
			videoUrl: 'https://video.vidyard.com/watch/UZcM9ZUS1E3QAV5QbQCWC5',
		});
	};

	adobeSignDocumentModal = () => {
		const { showingDocument, signingUrl } = { ...this.state };
		if (showingDocument === true && !!signingUrl) {
			return (
				<AdobeSignDocumentModal
					toggleHandler={this.hideDocumentModal}
					openDialog={showingDocument}
					signingUrl={signingUrl}
					responseTimeout={''}
				/>
			);
		}
		return null;
	};

	playerDocumentModal = () => {
		const { showingVideo, videoUrl } = { ...this.state };
		if (showingVideo === true && !!videoUrl) {
			return (
				<PlayerModal
					toggleHandler={this.hideVideoModal}
					openDialog={showingVideo}
					videoUrl={videoUrl}
					responseTimeout={''}
				/>
			);
		}
		return null;
	};

	hideDocumentModal = async () => {
		await this.setState({ showingDocument: false });
	};

	hideVideoModal = async () => {
		await this.setState({ showingVideo: false });
	};

	renderStatus(status, classes) {
		let chipClass = classes.chipRootRed; //Incomplete
		let label = 'Incomplete';
		switch (status) {
			case 'Complete':
				chipClass = classes.chipRootGreen;
				label = 'Complete';
				break;
			case 'In Review':
				chipClass = classes.chipRootBlue;
				label = 'In Review';
				break;
			case 'Attention Needed':
				chipClass = classes.chipRootYellow;
				label = 'Attention Needed';
				break;
			default:
				break;
		}
		return (
			<span>
				<Chip classes={{ root: chipClass, label: classes.chipLabel }} label={label} />
			</span>
		);
	}

	render() {
		const { t, classes } = this.props;
		const { payPreferences, showingDocument, signingUrlsExist } = this.state;
		const userName = msSalService.pullEmailFromAccount();
		const date = new Date().toLocaleString();
		const emailLink = `mailto: LTCustomerCareW9DD@locumtenens.com?subject=Clinician Portal W9 and Direct Deposit&body=${userName} does not see W9 or Direct Deposit Documents on ${date}`;
		if (Object.keys(payPreferences).length === 0) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}

		return (
			<>
				<Paper classes={{ root: classes.paperRoot }}>
					<Grid container classes={{ root: classes.gridRoot }} item xs={12}>
						<h3 className={classes.header}>
							{t('profile:PREFERENCES.PAYMENT_ELECTION_PREFERENCES')}
						</h3>
						<span className={classes.leftAlign}>
							In order to process your payroll request, both a filled W-9 and a direct
							deposit form are required.
						</span>
					</Grid>
					<Grid container classes={{ root: classes.itemRow }} item xs={12}>
						<Grid item xs={12} md={5}>
							<span className={classes.columnLabel}>Document</span>
						</Grid>
						<Grid item xs={12} md={7}>
							<span className={classes.columnLabel}>Status</span>
						</Grid>
					</Grid>

					<Grid container classes={{ root: classes.itemRow }} item xs={12}>
						<Grid item xs={12} md={5}>
							{payPreferences.w9Status === 'Complete' ||
							payPreferences.w9Status === 'In Review' ||
							!payPreferences.w9SigningUrl ? (
								'W-9'
							) : (
								<Button
									variant='text'
									onClick={() => this.showW9()}
									className={classes.docLink}
									disabled={showingDocument}
								>
									W-9
								</Button>
							)}
							<span
								onClick={() => this.showW9Video()}
								className={classes.pointerCursor}
							>
								<PlayCircleFilledIcon className={classes.tipIcon} />
								<span className={classes.tips}>Tips</span>
							</span>
							{this.playerDocumentModal()}
						</Grid>
						<Grid item xs={12} md={7}>
							{this.renderStatus(payPreferences.w9Status, classes)}
						</Grid>
						<Grid item xs={12}>
							{payPreferences.w9Status === 'Attention Needed' ? (
								<div className={classes.reason}>
									<ul>
										{payPreferences.w9RejectionReason.map((item, index) => (
											<li key={index}>{item}</li>
										))}
									</ul>
								</div>
							) : null}
						</Grid>
						{this.adobeSignDocumentModal()}
					</Grid>

					<Grid container classes={{ root: classes.itemRow }} item xs={12}>
						<Grid item xs={12} md={5}>
							{payPreferences.ddStatus === 'Complete' ||
							payPreferences.ddStatus === 'In Review' ||
							!payPreferences.ddSigningUrl ? (
								'Direct Deposit'
							) : (
								<Button
									variant='text'
									onClick={() => this.showDD()}
									className={classes.docLink}
									disabled={showingDocument}
								>
									Direct deposit
								</Button>
							)}
							<span
								onClick={() => this.showDDVideo()}
								className={classes.pointerCursor}
							>
								<PlayCircleFilledIcon className={classes.tipIcon} />
								<span className={classes.tips}>Tips</span>
							</span>
							{this.playerDocumentModal()}
						</Grid>
						<Grid item xs={12} md={7}>
							{this.renderStatus(payPreferences.ddStatus, classes)}
						</Grid>
						<Grid item xs={12}>
							{payPreferences.ddStatus === 'Attention Needed' ? (
								<div className={classes.reason}>
									<ul>
										{payPreferences.ddRejectionReason.map((item, index) => (
											<li key={index}>{item}</li>
										))}
									</ul>
								</div>
							) : null}
						</Grid>
						{this.adobeSignDocumentModal()}
					</Grid>

					<Grid container classes={{ root: classes.helpText }} spacing={2}>
						<Grid item xs={12}>
							{signingUrlsExist === false ? (
								<>
									<ul>
										<li>
											If you have an upcoming or active assignment with LocumTenens.com,
											you can access and complete these documents 30 days prior to your start date.
										</li>
										<li>
											If you do not have an upcoming or active assignment and are interested in working with LocumTenens.com,
											please call 800.562.8663, tell us your preferred specialty,
											and a recruiter will contact you with job opportunities that match your preferences.
										</li>
									</ul>
								</>
							) : null}

							<span className={classes.helpLabel}>Tips for completing your W-9</span>
							<ul>
								<li>
									If you will be paid as an individual, please be sure to leave
									line 2, the business name line blank. Be sure to include your
									SSN as the tax identification number.
								</li>
								<li>
									If you will be paid to a business, please add your name on line
									1, your business name on line 2. Check the box beside the type
									of business you have/are a part of and add your EIN number to
									the Tax Identification Number section.
								</li>
								<li>
									In section 3, please make sure that you have the correct
									corporation type.
								</li>
								<li>
									Make sure that the names on your ID and W-9 are exactly the same
								</li>
							</ul>

							<span className={classes.helpLabel}>
								Tips for completing your Direct Deposit Form
							</span>
							<ul>
								<li>
									If you listed a business on the W9, please also include the
									business information on the Direct Deposit. The two forms must
									match.
								</li>
								<li>
									Double check your Transit/ABA Number and Account Number. These
									pieces of information are critical as they determine where your
									payment will be sent. You can typically validate this
									information quickly by searching for “Direct Deposit” within
									your bank’s website.
								</li>
							</ul>
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
}

PaymentElectionPreferences.propTypes = {
	classes: PropTypes.shape({
		header: PropTypes.string.isRequired,
	}).isRequired,
	t: PropTypes.func.isRequired,
	tabHandler: PropTypes.func.isRequired,
	flags: PropTypes.shape({
		missingData: PropTypes.bool.isRequired,
		dataSaved: PropTypes.bool.isRequired,
		isDirty: PropTypes.bool.isRequired,
		showSaveChanges: PropTypes.bool.isRequired,
		isPendingSave: PropTypes.bool.isRequired,
		targetTab: PropTypes.number.isRequired,
	}).isRequired,
	updateFlags: PropTypes.func.isRequired,
	setSaveHandler: PropTypes.func.isRequired,
	externalId: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(styles)(PaymentElectionPreferences));
