/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
	Grid,
	withStyles,
	Button,
	Collapse,
	Typography,
	FormControl,
	FormGroup,
} from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import { textFieldStyles } from '../../Configurations/ExpenseEntryConfig';

const styles = (theme) => {
	const baseStyles = textFieldStyles(theme);
	return {
		drawer: {
			width: '28em',
			padding: '1em',
			position: 'initial',
			'&:hover': {
				backgroundColor: 'transparent',
			},
			'&:focus': {
				backgroundColor: 'transparent',
			},
			'&:active': {
				backgroundColor: 'transparent',
			},
			borderRadius: '0',
		},
		content: {
			paddingLeft: '1em',
		},
		datePicker: {
			width: '10em',
			...baseStyles.root,
			'& .MuiIconButton-root': {
				color: COLORS.LT_JOURNEY,
			},
		},
		icon: {
			color: COLORS.LT_JOURNEY,
		},
		clear: {
			textDecoration: 'underline',
			paddingLeft: '1em',
		},
		spacing: {
			paddingRight: '1em',
		},
		...baseStyles,
		inputRoot: {
			...baseStyles.inputRoot,
			'&$disabled .MuiIconButton-root': {
				background: COLORS.LT_MIDNIGHTBG2,
				color: COLORS.LT_MIDNIGHT33,
				borderRadius: 0,
			},
		},
	};
};

const ExpenseFilterDate = (props) => {
	const { classes, filterName, displayStartDate, displayEndDate } = props;
	const { control, watch, setValue } = useFormContext();
	const startDate = watch(displayStartDate);
	const endDate = watch(displayEndDate);
	const name = filterName.replace(/ /g, '-');
	const [expanded, setExpanded] = useState(false);
	const [clear, setClear] = useState(false);
	const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
	const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
	const [dateError, setDateError] = useState('');

	const format = 'YYYY-MM-DD';

	const formatWeekEndingDate = (date, type) => {
		if (!date) {
			setValue(type, null);
			return;
		}
		const newDate = moment(date).format(format);
		setValue(type, newDate);
	};

	const handleDateChange = (date, type, setOpen) => {
		setDateError('');
		if (date) {
			if (type === displayStartDate && endDate && moment(date).isAfter(moment(endDate))) {
				setDateError('Start date cannot be greater than end date');
			} else if (type === displayEndDate && startDate && moment(date).isBefore(moment(startDate))) {
				setDateError('End date cannot be less than start date');
			} else {
				formatWeekEndingDate(date, type);
			}
        }
		setOpen(false);
	};

	const renderDateFilter = () => {
		return (
			<FormControl component='fieldset'>
				<FormGroup>
					<Grid container>
						<Grid item className={classes.spacing}>
							<Controller
								name={displayStartDate}
								control={control}
								defaultValue={null}
								render={({ field, onChange, value }) => {
									return (
										<Grid item>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<Grid
													container
													direction='row'
													justifyContent='space-between'
													alignItems='center'
													spacing={2}
												>
													<Grid item md={6}>
														<KeyboardDatePicker
															className={classes.datePicker}
															disableToolbar
															variant='inline'
															format='MM/dd/yyyy'
															id={`${name}-inline`}
															label={'From'}
															value={
																startDate ? moment(startDate) : null
															}
															onChange={(e) => handleDateChange(e, displayStartDate, setOpenStartDatePicker)}
															onClose={() => setOpenStartDatePicker(false)}
															open={openStartDatePicker}
															onClick={() => setOpenStartDatePicker(true)}
															InputProps={{
																classes: {
																	root: classes.inputRoot,
																},
															}}
															KeyboardButtonProps={{
																'aria-label': 'change date',
																className: classes.icon,
															}}
														/>
													</Grid>
												</Grid>
											</MuiPickersUtilsProvider>
										</Grid>
									);
								}}
							/>
						</Grid>
						<Grid item>
							<Controller
								name={displayEndDate}
								control={control}
								defaultValue={null}
								render={({ field, onChange, value }) => {
									return (
										<Grid item>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<Grid
													container
													direction='row'
													justifyContent='space-between'
													alignItems='center'
													spacing={2}
												>
													<Grid item md={6}>
														<KeyboardDatePicker
															className={classes.datePicker}
															disableToolbar
															variant='inline'
															format='MM/dd/yyyy'
															id={`${name}-inline`}
															label={'To'}
															value={
																endDate ? moment(endDate) : null
															}
															onChange={(e) => handleDateChange(e, displayEndDate, setOpenEndDatePicker)}
															onClose={() => setOpenEndDatePicker(false)}
															open={openEndDatePicker}
															onClick={() => setOpenEndDatePicker(true)}
															InputProps={{
																classes: {
																	root: classes.inputRoot,
																	input: classes.input,
																	focused: classes.focused,
																	multiline: classes.multiline,
																	error: classes.error,
																	disabled: classes.disabled,
																},
															}}
															KeyboardButtonProps={{
																'aria-label': 'change date',
																className: classes.icon,
															}}
														/>
													</Grid>
												</Grid>
											</MuiPickersUtilsProvider>
										</Grid>
									);
								}}
							/>
						</Grid>
					</Grid>
					{dateError && (
						<Typography className={classes.error} color='error'>
							{dateError}
						</Typography>
					)}
				</FormGroup>
			</FormControl>
		);
	};

	const renderAccordian = () => {
		return (
			<>
				<Button
					className={classes.drawer}
					style={
						!expanded
							? { borderBottom: `1px solid ${COLORS.LT_MIDNIGHTSTEEL}` }
							: { borderBottom: 'none' }
					}
					disableRipple
					onClick={() => {
						setExpanded(!expanded);
						setClear(!clear);
					}}
					aria-expanded={expanded}
					aria-controls='panel1-content'
					endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				>
					<Grid container alignItems='center' spacing={1}>
						<Grid item>
							<Typography>
								{filterName} ({startDate || endDate ? 1 : 0})
							</Typography>
						</Grid>

						{clear && (
							<Grid item>
								<Typography
									variant='body2'
									className={classes.clear}
									onClick={(event) => {
										event.stopPropagation();
										setValue(displayStartDate, null);
										setValue(displayEndDate, null);
										setDateError('');
									}}
								>
									Clear
								</Typography>
							</Grid>
						)}
					</Grid>
				</Button>
				<Collapse in={expanded} className={classes.content}>
					{renderDateFilter()}
				</Collapse>
			</>
		);
	};

	return <> {renderAccordian()} </>;
};

export default withStyles(styles)(ExpenseFilterDate);
