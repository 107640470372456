/**
 * @file Input fields in the artifact differ than what material ui gives us, this is the custom
 * Textfield wrapper letting us leverage material ui with custom CSS.
 */
import { InputBase, withStyles } from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';

const TextFieldComponent = withStyles((theme) => ({
	root: {
		fontFamily: 'museo-sans',
		'label + &': {
			// marginTop: theme.spacing(1),
			// marginBottom: theme.spacing(1)
		},
	},
	input: {
		fontFamily: 'museo-sans',
		borderRadius: 0,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.LT_MIDNIGHT,
		border: `1px solid`,
		borderColor: COLORS.WHITE_LILAC,
		fontSize: '16px',
		width: '100%',
		padding: '12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:hover': {
			borderColor: COLORS.LT_JOURNEY,
		},
		'&:focus': {
			borderColor: COLORS.LT_JOURNEY,
		},
	},
	disabled: {},
}))(InputBase);

export default TextFieldComponent;
