import React from 'react';
import { Grid, Paper, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { COLORS } from '../../../utils/Application_Constants';

const styles = (theme) => ({
	label: {
		fontWeight: '700',
		color: COLORS.LT_JOURNEY,
	},
	card: {
		padding: 16,

		'&:hover': {
			background: COLORS.LT_JOURNEY10,
			transition: '.25s',
		},
	},
	icon: {
		height: 40,
		width: 40,
		background: COLORS.LT_JOURNEY10,
		borderRadius: 100,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const ExpenseCard = (props) => {
	const { classes, icon, label, path } = props;

	return (
		<Grid item xs={12}>
			<Link to={path}>
				<Paper classes={{ root: classes.card }}>
					<Grid container spacing={3} alignItems='center'>
						<Grid item>
							<div className={classes.icon}>{icon}</div>
						</Grid>
						<Grid item className={classes.label}>
							{label}
						</Grid>
					</Grid>
				</Paper>
			</Link>
		</Grid>
	);
};

export default withStyles(styles)(ExpenseCard);
