import React, { useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { Controller, useFormContext} from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import { billTypes } from '../../Configurations/ExpenseEntryConfig';

const ExpenseBillType = (props) => {
    const { index, expense, isClinicianReimbursement, mode } = props;
    const { control, errors } = useFormContext();
    const [required, setRequired] = useState({ required: (mode==='cc'?false:true)});
    return (
        <Grid item xs={12} md={6}>
            <Controller
                control={control}
                name={`expenseEntries[${index}].billType`}
                defaultValue={expense.billType}
                rules={{
                    required: required.required ? 'Bill type is required' : false,
                }}
                render={({ onChange, value }) => (
                    <ExpenseTextField
                        id={'bill-type'}
                        select
                        onChange={onChange}
                        value={value}
                        label={'Bill type'}
                        {...required}
                        error={Boolean(errors.expenseEntries?.[index]?.billType)}
                        helperText={errors.expenseEntries?.[index]?.billType?.message}
                    >
                        <MenuItem value='' disabled>
                            Select a bill type
                        </MenuItem>
                        {isClinicianReimbursement ? billTypes.filter((type) => type.value !== 'ClinicianDeduction').map((option, index) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                id={`bill-type-menu-option-${index}`}
                            >
                                {option.type}
                            </MenuItem>
                        )) : billTypes.map((option, index) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                id={`bill-type-menu-option-${index}`}
                            >
                                {option.type}
                            </MenuItem>
                        ))}
                    </ExpenseTextField>
                )}
            />
        </Grid>
    );
};

export default ExpenseBillType;
