import React, { Component } from 'react';
import { withStyles, Grid, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import UpcomingAssignmentList from '../UpcomingAssignmentList/UpcomingAssignmentList';
import UpcomingAssignmentDetailView from '../UpcomingAssignmentDetailView/UpcomingAssignmentDetailView';
import { getPresents } from '../../../services/PresentsService';
import UserContext from '../../../contexts/userContext';
import PresentsWidgetComponent from '../PresentsWidgetComponent/PresentsWidgetComponent';
import FacilityContacts from '../FacilityContacts/FacilityContacts';
import LocumTenensContacts from '../LocumTenensContacts/LocumTenensContacts';
import LocumTenensAfterHourSupportContact from '../LocumTenensContacts/LocumTenensAfterHourSupportContact';
import { getAssignments } from '../../../services/AssignmentsService';

const styles = () => ({});

class UpcomingAssignments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listView: true,
			selectedAssignmentId: '',
			assignments: [],
			url: {
				location: 'upcoming',
				id: null,
			},
			isLoading: true,
			presents: [],
		};
	}

	async componentDidMount() {
		await this.getUrlParams();
		const state = { ...this.state };
		const { externalId } = this.context;
		const { setAssignmentPageError } = this.props;
		state.presents = await getPresents(externalId).catch((e) => {
			setAssignmentPageError(e);
		});
		state.assignments = this.filterUpcomingAssignments(
			await getAssignments(externalId, 'upcoming', true)
		);
		state.isLoading = false;
		this.setState({ ...state });
	}

	async componentDidUpdate(prevProps, prevState) {
		const { match } = this.props;
		if (prevState.url.id !== match.params.id) {
			await this.getUrlParams();
		}
	}

	getUrlParams() {
		const { match } = this.props;
		const state = { ...this.state };
		state.url = { ...match.params };
		this.setState({ ...state });
	}

	updateUpcomingAssignments = async () => {
		const state = { ...this.state };
		state.isLoading = true;
		await this.setState({ isLoading: state.isLoading });
		const { externalId } = this.context;
		const { setAssignmentPageError } = this.props;
		state.assignments = this.filterUpcomingAssignments(
			await getAssignments(externalId, 'upcoming').catch((e) => {
				setAssignmentPageError(e);
			}),
			true
		);
		state.isLoading = false;
		//this.setState({ ...state });
		this.setState({
			assignments: state.assignments,
			isLoading: state.isLoading,
		});
	};

	toggleAssignmentView = (assignmentId) => {
		const state = { ...this.state };
		const { history } = this.props;
		state.listView = !state.listView;
		state.url.id = assignmentId;
		history.push(`/assignments/upcoming/${assignmentId}`);
		this.setState({ ...state });
	};

	getAssignmentBasedOnId = () => {
		const { url, assignments } = this.state;
		const assignment = assignments.filter((item) => item.id.toString() === url.id.toString());
		return assignment[0];
	};

	filterUpcomingAssignments(assignments) {
		if (!assignments) {
			return null;
		}
		const filteredAssignments = [];
		for (const assignment of assignments) {
			if (
				assignment.location.addressLine1 ||
				(assignment.location.city && assignment.location.state)
			) {
				filteredAssignments.push(assignment);
			}
		}
		return filteredAssignments;
	}

	renderAssignmentView = () => {
		const { url, assignments } = this.state;
		const { externalId } = this.context;
		if (!url.id) {
			return (
				<UpcomingAssignmentList
					assignments={assignments}
					toggleAssignmentView={this.toggleAssignmentView}
				/>
			);
		}
		const currentAssignment = this.getAssignmentBasedOnId();
		return (
			<UpcomingAssignmentDetailView
				assignment={currentAssignment}
				externalId={externalId}
				type='upcoming'
				assignmentId={url.id}
			/>
		);
	};

	renderDetailViewWidgets = () => {
		const currentAssignment = this.getAssignmentBasedOnId();
		if (!currentAssignment) return null;
		return (
			<>
				{this.renderFacilityContact(
					currentAssignment.facilityId,
					currentAssignment.facilityContacts,
					currentAssignment.displayName
				)}
				{this.renderLTContact(currentAssignment?.locumtenensContact)}
				{this.renderLTAfterHourSupportContact()}
			</>
		);
	};

	renderFacilityContact = (facilityId, contacts, primaryWorkLocation) => {
		if (!!facilityId && !!contacts && Array.isArray(contacts) && contacts.length > 0) {
			return (
				<FacilityContacts
					facilityId={facilityId}
					contacts={contacts}
					primaryWorkLocation={primaryWorkLocation}
					updateAssignments={this.updateUpcomingAssignments}
				/>
			);
		} else {
			return null;
		}
	};

	renderLTContact = (contacts) => {
		if (!!contacts && Array.isArray(contacts) && contacts.length > 0) {
			return <LocumTenensContacts contacts={contacts} />;
		} else {
			return null;
		}
	};

	renderLTAfterHourSupportContact = () => {
		return <LocumTenensAfterHourSupportContact />;
	};

	renderWidgetsBasedOnView = () => {
		const { url, presents } = this.state;
		if (url.id) {
			return this.renderDetailViewWidgets();
		}
		return <PresentsWidgetComponent isFirst presents={presents} />;
	};

	render() {
		const { isLoading } = this.state;
		if (isLoading) {
			return (
				<div>
					<CircularProgress color='primary' />
				</div>
			);
		}
		return (
			<>
				<Grid container spacing={4}>
					<Grid item xs={12} md={8}>
						{this.renderAssignmentView()}
					</Grid>

					<Grid item xs={12} md={4}>
						{this.renderWidgetsBasedOnView()}
					</Grid>
				</Grid>
			</>
		);
	}
}
UpcomingAssignments.contextType = UserContext;

UpcomingAssignments.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
			status: PropTypes.string.isRequired,
		}),
	}).isRequired,
};

export default withRouter(withStyles(styles)(UpcomingAssignments));
