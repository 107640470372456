import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PersonalInformationForm from './PersonalInformationComponents/PersonalInformationForm';
import PersonalInformationDisclaimerAlert from '../../Notifications/PersonalInformationDisclaimerAlert';
import {
	getClinicianFlags,
	postClinicianFlags,
	patchClinicianFlags,
} from '../../../../services/Credentialing';
import WithContexts from '../../../../contexts/withContexts';
import UserContext from '../../../../contexts/userContext';
import { reactPlugin } from '../../../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBoundaryPage from '../../../ContentComponents/Credentials/PageComponenets/ErrorBoundaryPage';
const styles = (theme) => ({
	pagePadding: {
		paddingBottom: '50px',
	},
});

export class PersonalInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openAlert: true,
			clinicianFlags: {},
			isObjectEmpty: null,
		};
	}

	async componentDidMount() {
		const { externalId } = this.props.UserContext;
		const { setCredAppError } = this.props;
		let clinicianFlags = await getClinicianFlags(externalId).catch((e) => {
			setCredAppError(e);
		});
		let isEmpty = false;
		if (!clinicianFlags) {
			isEmpty = true;
			clinicianFlags = {};
		}

		this.setState({ clinicianFlags: clinicianFlags, isObjectEmpty: isEmpty });
	}

	toggleSignInAlert = async (isViewable, alertChecked) => {
		const state = this.state.clinicianFlags;
		const { externalId } = this.props.UserContext;
		const { setCredAppError } = this.props;
		if (alertChecked) {
			if (!this.state.isObjectEmpty) {
				state.flags.personalInfoDisclaimerFlag = alertChecked;
				await patchClinicianFlags(state, externalId).catch((e) => {
					setCredAppError(e);
				});
			}
			if (this.state.isObjectEmpty) {
				const newObject = {
					flags: {
						personalInfoDisclaimerFlag: alertChecked,
					},
					clinicianExternalId: externalId,
				};
				await postClinicianFlags(newObject, externalId).catch((e) => {
					setCredAppError(e);
				});
			}
		}
		localStorage.setItem('LocalStorage_PersonalInfoDisclaimerAlert', 'true');
		this.setState({ clinicianFlags: state, openAlert: isViewable });
	};

	renderDisclaimerAlert = () => {
		if (this.state.isObjectEmpty !== null) {
			if (
				(!this.state.clinicianFlags.flags ||
					!this.state.clinicianFlags.flags.personalInfoDisclaimerFlag) &&
				!localStorage.getItem('LocalStorage_PersonalInfoDisclaimerAlert')
			) {
				return (
					<PersonalInformationDisclaimerAlert
						toggleHandler={this.toggleSignInAlert}
						openDialog={this.state.openAlert}
					/>
				);
			}
		}
		return null;
	};

	render() {
		const {
			classes,
			activePath,
			flags,
			setSaveHandler,
			blockedBackNavigation,
			updateFlags,
			setCredAppError,
			updateTitles,
			profession,
			credState,
			sectionCompletion,
			updateSectionCompletion,
			setTabHandlerFromDialog,
			shouldHighlightFields,
			updateHighlightFieldsState,
		} = this.props;
		return (
			<AppInsightsErrorBoundary
				onError={() => (
					<ErrorBoundaryPage errorCode='500' flags={flags} updateFlags={updateFlags} />
				)}
				appInsights={reactPlugin}
			>
				<div className={classes.pagePadding}>
					<PersonalInformationForm
						activePath={activePath}
						credState={credState}
						profession={profession}
						setSaveHandler={setSaveHandler}
						blockedBackNavigation={blockedBackNavigation}
						flags={flags}
						updateFlags={updateFlags}
						updateTitles={updateTitles}
						setCredAppError={setCredAppError}
						sectionCompletion={sectionCompletion}
						updateSectionCompletion={updateSectionCompletion}
						setTabHandlerFromDialog={setTabHandlerFromDialog}
						shouldHighlightFields={shouldHighlightFields}
						updateHighlightFieldsState={updateHighlightFieldsState}
					/>
					{this.renderDisclaimerAlert()}
				</div>
			</AppInsightsErrorBoundary>
		);
	}
}

PersonalInformation.contextType = UserContext;
export default WithContexts(withStyles(styles)(PersonalInformation));
