import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { COLORS } from '../../../utils/Application_Constants';

const useStyles = makeStyles({
	progressBarContainer: {
		maxWidth: '100%',
		height: 4,
		backgroundColor: COLORS.LT_MIDNIGHT25,
		borderRadius: 4,
		overflow: 'hidden',
	},
	progressBar: {
		animationName: '$progress',
		height: '100%',
		backgroundColor: COLORS.LT_JOURNEY,
	},
	'@keyframes progress': {
		from: { width: '0%' },
		to: { width: '100%' },
	},
});

function ProgressBarAnimation({ durationSeconds }) {
	const classes = useStyles();
	const progressBarRef = useRef();

	useEffect(() => {
		if (durationSeconds === 0.25) {
			progressBarRef.current.style.width = '100%';
		}
	}, [durationSeconds]);

	return (
		<div className={classes.progressBarContainer}>
			<div
				ref={progressBarRef}
				className={classes.progressBar}
				style={{
					animationDuration: `${durationSeconds}s`,
				}}
			></div>
		</div>
	);
}

export default ProgressBarAnimation;
