import React, { Component } from 'react';
import { withStyles, Grid, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Pagination from 'material-ui-flat-pagination';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import FacilityContacts from '../FacilityContacts/FacilityContacts';
import PastAssignmentList from '../PastAssignmentList/PastAssignmentList';
import PastAssignmentDetailView from '../PastAssignmentDetailView/PastAssignmentDetailView';
import { getListByOffset, getListOffsetByPage } from '../../../services/listPaginationService';
import UserContext from '../../../contexts/userContext';
import { COLORS, APP_CONFIG } from '../../../utils/Application_Constants';
import { getAssignments } from '../../../services/AssignmentsService';

const styles = (theme) => ({
	header: {
		color: COLORS.WATERLOO,
		fontWeight: '700',
		fontSize: '18px',
		width: '90%',
		padding: '18px 0 0 20px',
		textAlign: 'left',
	},
	assignmentListPaperWrapper: {
		top: '-12px',
		position: 'relative',
	},
	selectedPage: {
		backgroundColor: COLORS.TRUE_BLUE,
		color: COLORS.WHITE,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		borderRadius: '0',
		paddingLeft: '17px',
		paddingRight: '17px',
		'&:hover': {
			backgroundColor: COLORS.TRUE_BLUE,
		},
	},
	pageButton: {
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		borderRadius: '0',
		paddingLeft: '15px',
		paddingRight: '15px',
	},
	paginationNumber: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		opacity: '.8',
		fontSize: '16px',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '15px',
		},
	},
	paginationWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: '15px',
		alignItems: 'center',
		width: '90%',
		[theme.breakpoints.down('sm')]: {
			flexFlow: 'column',
		},
	},
	paginationFlexCenter: {
		display: 'flex',
		justifyContent: 'center',
	},
});

class PastAssignments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listView: true,
			selectedAssignmentId: '',
			offset: 0,
			page: 1,
			assignments: [],
			url: {
				location: 'past',
				id: null,
			},
			assignmentsWithOffset: [],
			isLoading: true,
		};
	}

	async componentDidMount() {
		await this.mountPastAssignments();
	}

	/**
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * When the url changes, we need to reflect it in the component to manage
	 * the componenet state.
	 */
	componentDidUpdate(prevProps, prevState) {
		const { match } = this.props;
		if (prevState.url.id !== match.params.id) {
			this.getUrlParams();
		}
	}

	getUrlParams() {
		const { match, location, history } = this.props;
		const state = { ...this.state };
		state.url = { ...match.params };
		state.page = queryString.parse(location.search).page || 1;
		if (!state.url.id && state.page !== 1) {
			history.push(`/assignments/past?page=${state.page}`);
			state.offset = getListOffsetByPage(Number(state.page));
		}
		this.setState({ ...state });
	}

	/**
	 * Initial mounting of the past assignments component.
	 * We retrieve the url, and set the page, offset and retrieve
	 * the assignments. When we get the assignemnts we need to determine
	 * how much to offset them by for pagination.
	 */
	mountPastAssignments = async () => {
		const { match, location, history } = this.props;
		const state = { ...this.state };
		const { externalId } = this.context;
		const { setAssignmentPageError } = this.props;
		state.offset = getListOffsetByPage(Number(state.page));
		history.push(`/assignments/past?page=${state.page}`);
		const assignments = await getAssignments(externalId, 'past', true).catch((e) => {
			setAssignmentPageError(e);
		});
		const sortedAssignments = assignments.sort((a, b) => {
			return new Date(b.endDate) - new Date(a.endDate);
		});
		state.assignmentsWithOffset = getListByOffset(sortedAssignments, state.offset);
		await this.setState({
			url: { ...match.params },
			page: queryString.parse(location.search).page || 1,
			offset: state.offset,
			assignments: sortedAssignments,
			assignmentsWithOffset: state.assignmentsWithOffset,
			isLoading: false,
		});
	};

	toggleAssignmentView = (assignmentId) => {
		const state = { ...this.state };
		const { history } = this.props;
		state.listView = !state.listView;
		state.url.id = assignmentId;
		history.push(`/assignments/past/${assignmentId}`);
		this.setState({ ...state });
	};

	getAssignmentBasedOnId = () => {
		const { url, assignments } = this.state;
		const assignment = assignments.filter((item) => item.id.toString() === url.id.toString());
		return assignment[0];
	};

	renderAssignmentView = () => {
		const { url, offset, page, assignments, assignmentsWithOffset } = this.state;
		const { externalId } = this.context;

		const { classes, t } = this.props;
		if (!url.id) {
			return (
				<>
					<div>
						<PastAssignmentList
							assignments={assignmentsWithOffset}
							toggleAssignmentView={this.toggleAssignmentView}
							totalAssignment={assignments == null ? 0 : assignments.length}
						/>

						{assignments !== undefined && assignments.length > 0 ? (
							<div className={classes.paginationFlexCenter}>
								<div className={classes.paginationWrapper}>
									<div className={classes.paginationNumber}>
										{t('common:GENERIC_PHRASES.PAGE')} {page}{' '}
										{t('common:GENERIC_PHRASES.OF')}{' '}
										{!assignments
											? 0
											: Math.floor(
													(assignments.length - 1) /
														APP_CONFIG.PAGINATION_LIMIT +
														1
											  )}
									</div>
									<Pagination
										classes={{
											rootCurrent: classes.selectedPage,
											rootStandard: classes.pageButton,
											rootEnd: classes.pageButton,
										}}
										limit={APP_CONFIG.PAGINATION_LIMIT}
										total={!assignments ? 0 : assignments.length}
										onClick={(e, offset, page) =>
											this.handlePagination(offset, page)
										}
										offset={offset}
									/>
								</div>
							</div>
						) : null}
					</div>
				</>
			);
		}
		const currentAssignment = this.getAssignmentBasedOnId();
		return <PastAssignmentDetailView assignment={currentAssignment} externalId={externalId} />;
	};

	renderDetailViewWidgets = () => {
		const currentAssignment = this.getAssignmentBasedOnId();
		if (!currentAssignment) return null;
		return (
			<>
				{this.renderFacilityContact(
					currentAssignment.facilityId,
					currentAssignment.facilityContacts,
					currentAssignment.displayName
				)}
			</>
		);
	};

	renderFacilityContact = (facilityId, contacts, primaryWorkLocation) => {
		if (!facilityId) return null;
		if (contacts && Array.isArray(contacts) && contacts.length > 0) {
			return (
				<FacilityContacts
					facilityId={facilityId}
					contacts={contacts}
					primaryWorkLocation={primaryWorkLocation}
					past
				/>
			);
		}
		return <FacilityContacts facilityId={facilityId} contacts={[]} past />;
	};

	renderWidgetsBasedOnView = () => {
		const { url } = this.state;
		if (!url.id) {
			return (
				<Grid item xs={12} md={11}>
					{this.renderAssignmentView()}
				</Grid>
			);
		}
		return (
			<>
				<Grid item xs={12} md={7}>
					{this.renderAssignmentView()}
				</Grid>
				<Grid item xs={0} md={1}></Grid>
				<Grid item xs={12} md={3}>
					{this.renderDetailViewWidgets()}
				</Grid>
			</>
		);
	};

	handlePagination = (offset, page) => {
		const state = { ...this.state };
		const { history } = this.props;
		state.offset = offset;
		state.page = page;
		state.assignmentsWithOffset = getListByOffset(state.assignments, offset);
		history.push(`/assignments/past?page=${page}`);
		this.setState({ ...state });
	};

	render() {
		const { isLoading } = this.state;
		const { classes } = this.props;
		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}
		return (
			<>
				<Grid item xs={12}>
					<Grid container>{this.renderWidgetsBasedOnView()}</Grid>
				</Grid>
			</>
		);
	}
}
PastAssignments.contextType = UserContext;
export default withTranslation()(withRouter(withStyles(styles)(PastAssignments)));
