import React, { Component } from 'react';
import {
	getClinicianCredAppCreateReAttestPdf,
	getDisclosureQuestions,
	getMalpracticeClaimHistories,
	patchCredAppStatus,
} from '../../services/Credentialing';
import {
	Grid,
	FormControl,
	withStyles,
	Button,
	InputLabel,
	ThemeProvider,
	CircularProgress,
	TextField,
	InputAdornment,
	Container,
} from '@material-ui/core';
import { COLORS, CRED_STATE, DOCUMENT_TYPE } from '../../utils/Application_Constants';
import datePickerTheme from '../../components/GlobalComponents/DatePickerComponent/DatePickerTheme';
import moment from 'moment';
import { Event } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import DatePickerComponent from '../../components/GlobalComponents/DatePickerComponent/DatePickerComponent';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import UserContext from '../../contexts/userContext';
import WithContexts from '../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import ReAttestationQuestionsForm from '../../components/ContentComponents/Credentials/PageComponenets/attestationquestions/ReAttestationQuestionsForm';
import ReAttestMalpracticeClaimsHistoryForm from '../../components/ContentComponents/Credentials/PageComponenets/malpracticeinsurance/ReAttestMalpracticeClaimsHistoryForm';
import { NavLink } from 'react-router-dom';
import UploadClinicianDocument from '../../components/ContentComponents/Credentials/PageComponenets/DocumentComponents/UploadClinicianDocument';
import UploadClinicianDocumentV2 from '../../components/ContentComponents/Credentials/PageComponenets/DocumentComponents/UploadClinicianDocumentV2';
import CssBaseline from '@material-ui/core/CssBaseline';
import ErrorDialog from '../../components/GlobalComponents/Dialog/ErrorDialog';
import FeatureFlag from '../../components/GlobalComponents/FeatureFlagComponent/FeatureFlag';

const useStyles = (theme) => ({
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingLeft: '32px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	nestetdContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	// inputRadio: {
	//     color: COLORS.RAVEN,
	//     "&.Mui-focused": {
	//         color: COLORS.RAVEN
	//     },

	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',

	// },

	// inputLabel: {
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase'
	// },
	gridRoot: {
		marginTop: '5px',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	completedIcon: {
		color: 'green',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		'& input': {
			height: '32px',
		},
		borderRadius: 3,
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.RAVEN,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: 16,
		width: '100%',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: COLORS.WHITE_LILAC,
			borderColor: COLORS.RAVEN,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		paddingLeft: '10px',
	},
	textField: {
		width: '70%',
	},

	cssLabel: {
		color: 'red',
	},

	cssOutlinedInput: {
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
		'&$cssFocused $notchedOutline': {
			borderColor: '#6D707E', //focused
			borderWidth: '1px',
			borderRadius: 1,
		},
	},
	disabledInput: {
		opacity: 0.6,
	},
	error: {
		borderColor: '#6D707E', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	saveButtonWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
	saveButton: {
		marginLeft: '30px',
		marginTop: '25px',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '20px 32px',
			marginLeft: '15px',
		},
	},
	disabeldInput: {
		opacity: 0.6,
	},
	dateGridWidth: {
		width: '60%',
		//marginTop: '-16px'
	},
	leftPad30: {
		paddingLeft: '30px !important',
	},
	UploaderHeaderArea: {
		alignItems: 'center',
		maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '0px',
		// marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		//borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	textLink: {
		color: COLORS.RICH_ELECTRIC_BLUE,
		fontWeight: '700',
		fontSize: '12px',
		textAlign: 'center',
	},
	title: {
		color: COLORS.BLACK_MARLIN,
		float: 'center',
		fontSize: '30px',
		fontWeight: '500',
		marginBottom: '10px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
			fontSize: '25px',
			paddingRight: '2px',
		},
	},
	subtitle: {
		color: '#FFFFFF',
		float: 'left',
		fontSize: '25px',
		fontWeight: '500',
		marginBottom: '30px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '20px',
		},
	},
	rootAllSet: {
		// margin: '80px 0',
		//padding:'80px 0
		// height: '100vh',
		minHeight: '700px',
		// backgroundImage: `url(${StartCredentialingImage})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	leftPane: {
		padding: '40px 65px',
		[theme.breakpoints.down('sm')]: {
			padding: '50px 15px',
		},
	},
	instructions: {
		paddingTop: '6px',
		paddingBottom: '3px',
		paddingLeft: '30px',
		textAlign: 'left',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '15px',
		},
	},
	instructionsWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	progressRoot: {
		[theme.breakpoints.up('md')]: {
			marginTop: '15px',
			marginLeft: '15px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '10px',
			marginLeft: '5px',
		},
	},
});

class CredReAttestation extends Component {
	constructor(props) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName('Cred ReAttestation');

		this.state = {
			isLoading: true,
			activePath: null,
			segment: null,
			currentSelectedLinkId: 0,
			toggleSideNavDrawer: false,
			credState: CRED_STATE.OPEN,
			highlights: [],
			displayQuestions: false,
			displayMalp: false,
			attestChildPage: null,
			handleAttestSubmit: null,
			attechChildName: null,
			malpChildPage: null,
			handleMalpSubmit: null,
			malpChildName: null,
			flags: {
				missingData: false,
				dataSaved: false,
				isDirty: false,
				showSaveChanges: false,
				isPendingSave: false,
				targetTab: 0,
				validationError: false,
				navDisabled: false,
				apiError: '',
				errorMessage: '',
			},
			credAppStatus: '',
			hasCredRecord: false,
			navDisabled: false,
			credType: '',
			signatureName: '',
			signatureDate: null,
			isMalpHighlighted: false,
			isNameProvided: false,
			isSubmitSuccessful: false,
			submitInProgress: false,
		};
	}

	datePickerHelper = (date) => {
		if (date) {
			return date;
		}
		return null;
	};

	setCredAppError = (e) => {
		console.log(' Inside setCredAppError ---> : ' + JSON.stringify(e));
		const { flags } = { ...this.state };
		if (e.hasOwnProperty('title') && e.hasOwnProperty('message')) {
			flags.apiError = e.message;
		} else {
			flags.errorMessage = 'Error loading page';
		}
		flags.isLoading = false;
		flags.isPendingSave = false;
		this.setState({ flags: flags });
	};

	onCallSubmit = async (data) => {
		const state = { ...this.state };
		const { externalId } = this.props.UserContext;
		let signedDate = new Date().toISOString();
		signedDate = moment.utc(signedDate);

		let dto = {
			clinicianId: externalId,
			reattestationSignature: this.state.signatureName,
			reattestationSignatureDate: signedDate,
		};

		this.setState({
			submitInProgress: true,
		});

		if (state.displayMalp && state.handleMalpSubmit && state.malpChildPage) {
			await state.handleMalpSubmit.call(state.malpChildPage);
		}

		if (state.displayQuestions && state.handleAttestSubmit && state.attestChildPage) {
			await state.handleAttestSubmit.call(state.attestChildPage);
		}

		await patchCredAppStatus(externalId, dto).catch((e) => {
			this.setCredAppError(e);
		});

		await getClinicianCredAppCreateReAttestPdf(externalId).catch((e) => {
			this.setCredAppError(e);
		});

		this.setState({
			isSubmitSuccessful: true,
			submitInProgress: false,
		});

		localStorage.setItem('LocalStorage_Thankyou', 'YES');
	};

	setMalpHighlightedFlag = (value) => {
		this.setState({
			isMalpHighlighted: value,
		});
	};
	setMalpSaveHandler = (child, tabName, handler) => {
		const state = { ...this.state };
		state.malpChildPage = child;
		state.malpChildName = tabName;
		state.handleMalpSubmit = handler;
		// Reset save flags
		state.flags.missingData = false;
		state.flags.dataSaved = false;
		state.flags.isDirty = false;
		state.flags.showSaveChanges = false;
		state.flags.isPendingSave = false;
		state.flags.errorMessage = null;
		state.flags.apiError = null;
		state.backButton = null;
		this.setState({ ...state });
	};

	setSaveHandler = (child, tabName, handler) => {
		this.setState({
			attestChildPage: child,
			attechChildName: tabName,
			handleAttestSubmit: handler,
		});
	};

	updateFlags = (flags) => {
		console.log('Updating flags');
		//this.setState({ flags: flags });
	};

	async componentDidMount() {
		// (async function fetchData() {
		const { externalId } = this.props.UserContext;
		await Promise.all([
			getDisclosureQuestions(externalId),
			getMalpracticeClaimHistories(externalId, 'malpracticeclaimhistories'),
		])
			.then(
				async ([
					data,
					{
						malpracticeClaimHistories = [],
						qa1Denied,
						qa2MalpracticeClaim,
						qa3PendingClaim,
						updateQa1Denied,
						updateQa2MalpracticeClaim,
						updateQa3PendingClaim,
					},
				]) => {
					const { questions = [] } = data;

					questions.forEach((item) => {
						if (item.UpdatedAnswerNeeded === true) {
							const state = { ...this.state };
							state.displayQuestions = true;
							this.setState({ displayQuestions: state.displayQuestions });
							return;
						}
					});

					if (
						updateQa1Denied === true ||
						updateQa2MalpracticeClaim === true ||
						updateQa3PendingClaim === true
					) {
						const state = { ...this.state };
						state.displayMalp = true;
						this.setState({ displayMalp: state.displayQuestions });
					}
					this.setState({
						isLoading: false,
					});
				}
			)
			.catch((e) => {
				this.setCredAppError(e);
			});
	}

	dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	handleNameChange = (e) => {
		const regex = /^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/;
		if (e.target.value) {
			this.setState({ signatureName: e.target.value });
			if (regex.test(e.target.value)) {
				this.setState({ isNameProvided: true });
			} else {
				this.setState({ isNameProvided: false });
			}
		} else {
			this.setState({ signatureName: '' });
			this.setState({ isNameProvided: false });
		}
	};

	closeError = () => {
		const state = { ...this.state };
		state.flags.apiError = null;
		this.setState({ ...state });
	};
	render() {
		//const localClasses = useStyles();
		const regex = /^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/;

		const { displayQuestions, flags, displayMalp } = this.state;

		const {
			t,
			blockedBackNavigation,
			UserContext,
			activePath,
			credState,
			sectionCompletion,
			updateSectionCompletion,
			updateHighlightFieldsState,
			shouldHighlightFields,
			classes,
		} = this.props;

		const myQuestionsDisciplinary = [
			'Had disciplinary action on medical license',
			'Had disciplinary action on DEA registration',
			'Had disciplinary action on other professional registration',
			'Had disciplinary action on clinical privileges',
			'Had disciplinary action on membership',
			'Had disciplinary action on institutional affiliation',
			'Had disciplinary acton on professional society membership',
			'Has professional sanction',
			'Had disciplinary action on govt health insurance',
		];

		const myQuestionsDisclosure = [
			'Has used drugs illegally',
			'Has significant physical or mental condition',
			'Unable to safely perform the essential functions',
			'Has criminal charges',
			'Has been arrested for child related sexual offense',
			'Has been convicted or plead out of a criminal offense',
			'Has significant issue affecting their ability to perform',
			'Has been reported to NPDB or HIPDB',
		];

		const myQuestionsCombined = myQuestionsDisciplinary.concat(myQuestionsDisclosure);

		const DocTypes = [
			{
				value: DOCUMENT_TYPE.CourtBoardDocuments,
				label: DOCUMENT_TYPE.CourtBoardDocuments,
			},
			{
				value: DOCUMENT_TYPE.Explanation,
				label: DOCUMENT_TYPE.Explanation,
			},
			{
				value: DOCUMENT_TYPE.Claims,
				label: DOCUMENT_TYPE.Claims,
			},
		];

		if (this.state.isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}
		if (this.state.isSubmitSuccessful) {
			return (
				<div className={classes.rootAllSet}>
					<Container maxWidth='xl'>
						<CssBaseline />
						<Grid item xs={12}>
							<Grid container justify='space-evenly'>
								<Grid item xs={12} md={7} className={classes.leftPane}>
									<span className={classes.title}>Thank You!</span>
								</Grid>
								<Grid item xs={12} md={7}>
									<span className={classes.subtitle1}>
										{t(`credentials:CRED_REATTEST.ANSWERES_SAVED`)}
										<br />
										<br />
									</span>
								</Grid>
								<Grid item xs={12} md={7}>
									<span className={classes.subtitle1}>
										{t(`credentials:CRED_REATTEST.APPLICATION_DOWNLOAD`)}
										<br />
									</span>
									<span>
										{'Please Visit the '}
										<NavLink
											className={classes.textLink}
											to={'/credentials/checkStatusAndSign'}
										>
											{' '}
											application status
										</NavLink>
										{' page.'}
									</span>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</div>
			);
		}
		if (displayQuestions || displayMalp) {
			return (
				<>
					<ErrorDialog
						open={!!flags.apiError || !!flags.errorMessage}
						onClose={this.closeError}
						title={!flags.apiError ? null : flags.apiError.title}
						description={!flags.apiError ? null : flags.apiError.message}
						errorId={!flags.apiError ? null : flags.apiError.errorId}
					/>
					<ReAttestMalpracticeClaimsHistoryForm
						t={t}
						setMalpSaveHandler={this.setMalpSaveHandler}
						blockedBackNavigation={blockedBackNavigation}
						UserContext={UserContext}
						flags={flags}
						updateFlags={this.updateFlags}
						type={'Malpractice Claims History'}
						constantType={'MALPRACTICE_CLAIMS_HISTORY'}
						apiType={'malpracticeclaimhistories'}
						activePath={activePath}
						credState={credState}
						sectionCompletion={sectionCompletion}
						updateSectionCompletion={updateSectionCompletion}
						updateHighlightFieldsState={updateHighlightFieldsState}
						shouldHighlightFields={shouldHighlightFields}
						setCredAppError={this.setCredAppError}
						setMalpHighlightedFlag={this.setMalpHighlightedFlag}
					/>
					<ReAttestationQuestionsForm
						myQuestionsCombined={myQuestionsCombined}
						myQuestionsDisciplinary={myQuestionsDisciplinary}
						myQuestionsDisclosure={myQuestionsDisclosure}
						t={t}
						setSaveHandler={this.setSaveHandler}
						blockedBackNavigation={blockedBackNavigation}
						UserContext={UserContext}
						activePath={activePath}
						credState={credState}
						flags={flags}
						updateFlags={this.updateFlags}
						constantType={'DISCIPLINARY_QUESTIONS'}
						sectionCompletion={sectionCompletion}
						updateSectionCompletion={updateSectionCompletion}
						updateHighlightFieldsState={updateHighlightFieldsState}
						shouldHighlightFields={shouldHighlightFields}
						setCredAppError={this.setCredAppError}
					/>

					<Container>
						<Grid container>
							<Grid item xs={12}>
								<h4 className='cr-foundation'>Upload Documents</h4>
							</Grid>
						</Grid>

						<Grid container>
							{/* <Grid className={classes.UploaderHeaderArea}>
                        
                    </Grid> */}
							<Grid item xs={12} className={classes.leftPad30}>
								<FeatureFlag name='EnableHasSharepointDocs' isFlagEnabled='true'>
									<UploadClinicianDocumentV2
										docTypeList={DocTypes}
										showDocuments={true}
										credState={credState}
									/>
								</FeatureFlag>
								<FeatureFlag name='EnableHasSharepointDocs' isFlagEnabled='false'>
									<UploadClinicianDocument
										docTypeList={DocTypes}
										showDocuments={true}
										credState={credState}
									/>
								</FeatureFlag>
							</Grid>
						</Grid>

						<Grid container className={'credAppAdditionalHeader'}>
							<Grid item xs={12} classes={classes.instructionsWrapper}>
								<p
									className={classes.instructions}
									dangerouslySetInnerHTML={{
										__html: t(`credentials:CRED_REATTEST.ATTEST_DECLARATION`),
									}}
								/>
							</Grid>
							<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
								<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
									<InputLabel
										shrink
										htmlFor='Signature'
										classes={{ root: classes.inputLabel }}
										required
										error={
											!this.state.signatureName ||
											this.state.signatureName.length < 2
										}
									>
										{t('credentials:CRED_REATTEST.SIGNATURE')}
									</InputLabel>
									<TextField
										id='Signature'
										name='signature'
										className={classes.textField}
										margin='normal'
										variant='outlined'
										value={this.state.signatureName}
										error={
											!this.state.signatureName ||
											this.state.signatureName.length < 2 ||
											!regex.test(this.state.signatureName)
										}
										// inputRef ={register(
										//     {
										//         required:true,
										//         maxLength: 100 })}
										onBlur={this.handleNameChange}
										onChange={this.handleNameChange}
										helperText={t(
											'credentials:VALIDATION_MESSAGE.SIGNATURE_NAME'
										)}
										InputProps={{
											classes: {
												root: classes.cssOutlinedInput,
												focused: classes.cssFocused,
												notchedOutline: classes.notchedOutline,
												error: classes.error,
											},
											pattern: '[a-z]{1,15}',
										}}
									/>
								</FormControl>
							</Grid>
							<Grid classes={{ root: classes.gridRoot }} item xs={12} md={4}>
								<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
									<InputLabel
										shrink
										htmlFor='SignatureDate'
										classes={{ root: classes.inputLabel }}
										required
									>
										{t('credentials:CRED_REATTEST.SIGNATURE_DATE')}
									</InputLabel>
									<ThemeProvider theme={datePickerTheme}>
										<MuiPickersUtilsProvider
											utils={DateFnsUtils}
											moment={moment}
										>
											<FormControl classes={{ root: classes.dateGridWidth }}>
												<DatePickerComponent
													disabled={true}
													theme={datePickerTheme}
													variant='inline'
													autoOk='true'
													format='MM/dd/yyyy'
													placeholder='MM/DD/YYYY'
													name='signatureDate'
													margin='normal'
													InputProps={{
														endAdornment: (
															<InputAdornment position='end'>
																<Event />
															</InputAdornment>
														),
														classes: { root: classes.cssOutlinedInput },
													}}
													id='SignatureDate'
													value={this.datePickerHelper(new Date())}
													onChange={(date, parsedDate) => {
														this.dateChangeHandler(
															date,
															parsedDate,
															'signatureDate'
														);
													}}
													KeyboardButtonProps={{
														'aria-label': 'Snooze Date',
													}}
													inputVariant='outlined'
												/>
											</FormControl>
										</MuiPickersUtilsProvider>
									</ThemeProvider>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={4} classes={{ root: classes.saveButtonWrapper }}>
								<Button
									className={classes.saveButton}
									variant='contained'
									color='primary'
									disabled={
										this.state.isMalpHighlighted ||
										!this.state.isNameProvided ||
										this.state.submitInProgress
									}
									onClick={this.onCallSubmit}
								>
									{t('credentials:CRED_REATTEST.SUBMIT')}
								</Button>
								{this.state.submitInProgress ? (
									<div className={classes.progressWrapper}>
										<CircularProgress
											classes={{ root: classes.progressRoot }}
											color='primary'
										/>
									</div>
								) : null}
							</Grid>
						</Grid>
					</Container>
				</>
			);
		} else {
			return (
				<div className={classes.rootAllSet}>
					<Container maxWidth='xl'>
						<CssBaseline />
						<Grid item xs={12}>
							<Grid container justify='space-evenly'>
								<Grid item xs={12} md={7} className={classes.leftPane}>
									<span className={classes.title}>
										{t(`credentials:CRED_REATTEST.ALLSET_HEADING`)}
									</span>
								</Grid>
								<Grid item xs={12} md={7}>
									<span className={classes.subtitle1}>
										{t(`credentials:CRED_REATTEST.ALLSET_SUBTITLE1`)}
										<br />
										<br />
									</span>
								</Grid>
								<Grid item xs={12} md={7}>
									<span className={classes.subtitle1}>
										{t(`credentials:CRED_REATTEST.ALLSET_SUBTITLE2`)}
										<br />
									</span>
									<span>
										{'Visit the'}
										<NavLink
											className={classes.textLink}
											to={'/credentials/checkStatusAndSign'}
										>
											{' '}
											application status
										</NavLink>
										{' page'}
									</span>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</div>
			);
		}
	}
}

CredReAttestation.contextType = UserContext;

export default WithContexts(withTranslation()(withStyles(useStyles)(CredReAttestation)));
