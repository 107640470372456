const examStatuses = [
	{
		value: 'Failed',
		label: 'Failed',
	},
	{
		value: 'Passed',
		label: 'Passed',
	},
	{
		value: 'Pending',
		label: 'Pending',
	},
];
export { examStatuses };
