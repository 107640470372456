import { axiosCredentialVerificationInstance } from '../configuration/axios/axiosConfiguration';

export async function setProviderRecipe(npi, degree, credentialingStatus, previousCredentialingStatus) {
    try {
        await axiosCredentialVerificationInstance.post(`providers/${npi}/recipes`, { degree, credentialingStatus, previousCredentialingStatus });
    } catch (error) {
        console.log(`${setProviderRecipe.name} Failed: ${error}`);
    }
}

export async function providerSync(npi, providerSyncRequest) {
    try {
        await axiosCredentialVerificationInstance.post(`providers/${npi}/sync`, providerSyncRequest);
    } catch (error) {
        console.log(`${providerSync.name} Failed: ${error}`);
    }
}
