import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
	FormControl,
	FormControlLabel,
	RadioGroup,
	Radio,
	TextField,
	InputLabel,
} from '@material-ui/core';
import { CircularProgress, NativeSelect, ThemeProvider } from '@material-ui/core';
import {
	COLORS,
	specialties,
	CRED_STATE,
	ACTIVE_PATHNAME_MD,
	specialtiesMD,
} from '../../../../utils/Application_Constants';
import TextFieldComponent from '../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import DatePickerComponent from '../../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import RouteDeparturePrompt from '../../../GlobalComponents/Dialog/RouteDeparturePrompt';
import {
	getClinicianReferences,
	postClinicianReferences,
} from '../../../../services/Credentialing';
import Input from '@material-ui/core/Input';
import DateFnsUtils from '@date-io/date-fns';
import createTheme from '@material-ui/core/styles/createTheme';
import { matchesTemplate } from '../../../../utils/helpers';
import InputMask from 'react-input-mask';
import {
	referencesStatusPointsCheck,
	referencesStatusCheck,
	referenceDateToValidation,
} from '../../../../utils/CredentialingAppUtility';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import RequiredQuestionDialog from '../../../GlobalComponents/Dialog/RequiredQuestionDialog';

const styles = makeStyles((theme) => ({
	// header: {
	//     color: COLORS.BLACK_MARLIN,
	//     [theme.breakpoints.down('sm')]: {
	//         padding: '15px 0 0 15px',
	//     },
	//     textAlign: 'left',
	//     paddingLeft: '10px',
	//     paddingBottom: '5px',
	//     fontSize: '22px',
	//     fontWeight: '700',
	// }, nestetdContainer: {
	//     [theme.breakpoints.up('md')]: {
	//         marginTop: '10px'
	//     }, [theme.breakpoints.down('sm')]: {
	//         marginTop: '5px',
	//     },

	// },
	headerText: {
		color: COLORS.RAVEN,
		'&.Mui-focused': {
			color: COLORS.RAVEN,
		},

		fontWeight: '500',
		fontSize: '14px',
		whiteSpace: 'wrap',
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '20px',
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		textAlign: 'left',
		fontWeight: '800',
	},
	secondaryHeaderArea: {
		alignItems: 'center',
		maxWidth: '94%',
		marginTop: '5px',
		marginBottom: '46px',
		marginLeft: '10px',
		marginRight: '32px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderTop: 'solid 1px lightgrey',
		borderBottom: 'solid 1px lightgrey',
	},
	form: {
		width: '100%',
		margin: theme.spacing(1, 0, 5),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	// inputLabel: {
	//     top: '-5px',
	//     position: 'relative',
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	//     marginTop: theme.spacing(-1),
	// },
	// selectInputLabel: {
	//     textAlign: 'left',
	//     marginLeft: '5px',
	//     color: COLORS.RAVEN,
	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',
	// },
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: `${COLORS.RAVEN}`,
			borderWidth: 1,
		},
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
	},
	cssFocused: {},

	disabled: {},
	disabledInput: {
		opacity: 0.6,
	},
	disabledSelect: {
		opacity: 0.6,
		cursor: 'default',
	},
	focused: {},
	marginLeft12: {
		marginLeft: 12,
	},
	error: {
		border: '1px solid red !important', //focused
		borderWidth: '1px',
		borderRadius: 1,
	},
	errorColor: {
		color: 'red !important',
		cursor: 'pointer',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red !important',
			},
		},
	},
	noTopPad: {
		paddingTop: '0 !important',
	},
	notchedOutline: {
		borderWidth: '1px',

		borderRadius: 1,
	},
	radioGroup: {
		marginLeft: '8px',
	},
	// inputRadio: {
	//     color: COLORS.RAVEN,
	//     '&.Mui-focused': {
	//         color: COLORS.RAVEN
	//     },

	//     fontWeight: '900',
	//     fontSize: '12px',
	//     whiteSpace: 'nowrap',
	//     textTransform: 'uppercase',

	// },
	setWidthSelectGroup: {
		top: '-23px',
		width: 'calc(100% - 1px)',
		marginTop: '10px',
		marginRight: '10px',
	},
	setWidthSelects: {
		minWidth: '100%',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		width: 'auto',
	},
	hideDeleteBtn: {
		display: 'none',
	},
	cssLabel: {
		color: 'red',
		float: 'left',
		textAlign: 'left',
		fontSize: '0.75rem',
	},
	selectContainerOffset: {
		padding: '0 16px 16px 16px !important',
	},
	selectContainer: {
		padding: '3px !important',
	},
	selectContainerGroup: {
		[theme.breakpoints.down('sm')]: {
			padding: '3px 10px 0px 10px !important',
		},
		minWidth: '97%',
	},
	multiGridOffset: {
		paddingTop: '13px !important',
	},
	leftPad30: {
		paddingLeft: '30px !important',
	},
	containerSM: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% + 13px) !important',
		},
	},
	marginBottom20Neg: {
		marginBottom: -20,
	},
	messageGrid: {
		textAlign: 'left',
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
		},
	},
	infoMessage: {
		color: COLORS.RAVEN,

		textAlign: 'left',
		fontSize: '14px',
		fontWeight: '500',
		paddingLeft: '10px !important',
		paddingBottom: '15px',
	},
	alertMessage: {
		border: `1px solid ${COLORS.BROWN_COLOR}`,
		color: COLORS.BROWN_COLOR,
		backgroundColor: COLORS.LIGHT_YELLOW,
		fontSize: '13px',
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
	checkboxInputLabel: {
		color: COLORS.RAVEN,
		marginLeft: '6px',
		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
	},
	checkboxInput: {
		padding: '24px',
		justifyContent: 'left',
	},
}));

function ReferencesForm({
	t,
	setSaveHandler,
	blockedBackNavigation,
	UserContext,
	flags,
	updateFlags,
	type,
	setCredAppError,
	constantType,
	apiType,
	activePath,
	credState,
	credType,
	sectionCompletion,
	updateSectionCompletion,
	updateHighlightFieldsState,
	shouldHighlightFields,
}) {
	const normalTheme = createTheme({
		overrides: {
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '18px',
					borderRadius: '1px !important',
				},
			},
		},
	});

	const salutationNormalTheme = createTheme({
		overrides: {
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					marginTop: -16,
					height: '18px',
					borderRadius: '1px !important',
				},
			},
		},
	});
	const errorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					height: '17px',
					borderRadius: '1px !important',
					border: '2px solid red !important',
				},
			},
		},
	});
	const salutationErrorTheme = createTheme({
		overrides: {
			// Style sheet name
			MuiNativeSelect: {
				// Name of the rule
				select: {
					// Some CSS
					marginTop: -16,
					height: '17px',
					borderRadius: '1px !important',
					border: '2px solid red !important',
				},
			},
		},
	});

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [isLoaded, setIsLoaded] = useState(false);

	const [deleteRecordIndex, setDeleteRecordIndex] = useState();
	const [deleteRecordItem, setDeleteRecordItem] = useState();
	const [deleteRecords, setDeleteRecords] = useState([]);

	const [haveReferenceInTwoYear, setHaveReferenceInTwoYear] = useState(null);
	const [highlights, setHighlights] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);

	const itemTemplate = {
		id: null,
		firstName: '',
		lastName: '',
		salutation: '',
		specialty: '',
		relationshipToApplicant: '',
		dateContactTo: null,
		phone: '',
		fax: '',
		email: '',
		currentlyWorkingWith: null,
	};

	const { register, control, handleSubmit, errors, reset, formState, watch, trigger } = useForm({
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			id: 'null',
			firstName: '',
			lastName: '',
			salutation: '',
			specialty: '',
			relationshipToApplicant: '',
			dateContactTo: null,
			phone: '',
			fax: '',
			email: '',
			currentlyWorkingWith: null,
		},
	});
	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			setSaveHandler(
				ReferencesForm,
				'References',
				handleSubmit(async (data) => await onSubmit(data))
			);

			await getClinicianReferences(externalId, apiType)
				.then(function (data) {
					const { references = [] } = data;

					if (data.haveReferenceInTwoYear === true) {
						setHaveReferenceInTwoYear('true');
						data.haveReferenceInTwoYear = 'true';
					} else if (data.haveReferenceInTwoYear === false) {
						setHaveReferenceInTwoYear('false');
						data.haveReferenceInTwoYear = 'false';
					} else {
						setHaveReferenceInTwoYear(null);
					}

					console.log('response is', data);

					if (references.length > 0) {
						data['references'].map((item) => {
							if (item.firstName === null) {
								item.firstName = '';
							}
							if (item.lastName === null) {
								item.lastName = '';
							}
							if (item.salutation === null) {
								item.salutation = '';
							}
							if (item.specialty === null) {
								item.specialty = '';
							}
							if (item.relationshipToApplicant === null) {
								item.relationshipToApplicant = '';
							}
							if (item.phone === null) {
								item.phone = '';
							}
							if (item.fax === null) {
								item.fax = '';
							}
							if (item.email === null) {
								item.email = '';
							}
							return item;
						});
					}

					if (references.length === 0) {
						append({
							id: 'null',
							firstName: '',
							lastName: '',
							salutation: '',
							specialty: '',
							relationshipToApplicant: '',
							dateContactTo: null,
							phone: '',
							fax: '',
							email: '',
							currentlyWorkingWith: null,
						});
						data['references'].push({
							id: 'null',
							firstName: '',
							lastName: '',
							salutation: '',
							specialty: '',
							relationshipToApplicant: '',
							dateContactTo: null,
							phone: '',
							fax: '',
							email: '',
							currentlyWorkingWith: null,
						});
						append({
							id: 'null',
							firstName: '',
							lastName: '',
							salutation: '',
							specialty: '',
							relationshipToApplicant: '',
							dateContactTo: null,
							phone: '',
							fax: '',
							email: '',
							currentlyWorkingWith: null,
						});
						data['references'].push({
							id: 'null',
							firstName: '',
							lastName: '',
							salutation: '',
							specialty: '',
							relationshipToApplicant: '',
							dateContactTo: null,
							phone: '',
							fax: '',
							email: '',
							currentlyWorkingWith: null,
						});
					} else {
						if (references.length === 1) {
							append({
								id: 'null',
								firstName: '',
								lastName: '',
								salutation: '',
								specialty: '',
								relationshipToApplicant: '',
								dateContactTo: null,
								phone: '',
								fax: '',
								email: '',
								currentlyWorkingWith: null,
							});
							data['references'].push({
								id: 'null',
								firstName: '',
								lastName: '',
								salutation: '',
								specialty: '',
								relationshipToApplicant: '',
								dateContactTo: null,
								phone: '',
								fax: '',
								email: '',
								currentlyWorkingWith: null,
							});
						}
					}

					updateSectionCompletion(sectionCompletion);
					flags.dataSaved = false;
					updateFlags(flags);
					reset(data);
					setIsLoaded(true);

					if (shouldHighlightFields) {
						setLocalShouldHighlightFields(true);
						let status = {
							status: 'incomplete',
							missingFields: [],
						};
						status = referencesStatusCheck(data);

						if (!!status.missingFields) {
							setHighlights(status.missingFields);
						}
					}
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [reset]);
	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'references',
		keyName: 'internalId',
	});

	function updateHighlight() {
		if (localShouldHighlightFields) {
			let status = {
				status: 'incomplete',
				missingFields: [],
			};

			status = referencesStatusCheck(watch());
			setHighlights(status.missingFields);
		}
	}

	useEffect(() => {
		let originalFlags = JSON.stringify(flags);
		if (!flags.dataSaved) {
			flags.isDirty = !!Object.keys(formState.dirtyFields).length;
		}

		let missingRequiredToSaveFields = [];
		if (errors.hasOwnProperty('references')) {
			for (let i = 0; i < errors.references.length; i++) {
				if (!!errors?.references[i]) {
					missingRequiredToSaveFields = Object.values(errors.references[i]).filter(
						(anError) => {
							return anError.hasOwnProperty('type');
						}
					);
				}
			}
		}

		if (missingRequiredToSaveFields.length !== 0 && flags.validationError === false) {
			flags.validationError = true;
		} else if (missingRequiredToSaveFields.length === 0 && flags.validationError === true) {
			flags.validationError = false;
		}

		if (JSON.stringify(flags) !== originalFlags) {
			updateFlags(flags);
		}
	}, [formState]);

	useEffect(() => {
		//TDS-4387 For re-creds, we need to make sure the validation holds, Trigger validation once form loads.
		var aReferences = watch('references');
		if (!!aReferences && aReferences.length > 0) {
			for (var obj = 0; obj < aReferences.length; obj++) {
				trigger(`references[${obj}].dateContactTo`);
			}
		}
	}, [isLoaded]);

	const classes = styles();

	async function onSubmit(data) {
		let success = false;

		let reference = watch();

		if (!data['references']) {
			data.haveReferenceInTwoYear = false;
			if (
				reference.haveReferenceInTwoYear === null ||
				reference.haveReferenceInTwoYear === 'null' ||
				reference.haveReferenceInTwoYear === '' ||
				reference.haveReferenceInTwoYear === undefined
			) {
				data.haveReferenceInTwoYear = null;
			}
			data['references'] = [];
		}

		if (data.haveReferenceInTwoYear === 'true') {
			data.haveReferenceInTwoYear = true;
		}

		const { externalId } = UserContext;
		data['clinicianId'] = externalId;
		if (watch('haveReferenceInTwoYear') === 'true') {
			data['references'] = data['references']
				.map((item) => {
					if (item.id === 'null' || item.id === 'null') {
						item.id = null;
					}
					if (item.phone !== '' && item.phone !== undefined) {
						item.phone = item.phone.replace(/-/g, '').trim();
					} else if (item.phone === undefined) {
						item.phone = '';
					}
					if (item.fax !== '' && item.fax !== undefined) {
						item.fax = item.fax.replace(/-/g, '').trim();
					} else if (item.fax === undefined) {
						item.fax = '';
					}
					// if (item.dateContactFrom !== null) {
					//     item.dateContactFrom = moment(item.dateContactFrom).toISOString(true)
					// }
					if (item.dateContactTo !== null) {
						//let firstDay = moment(item.dateContactTo).startOf('month').format('MM/DD/yyyy');
						let firstDay = moment(item.dateContactTo)
							.startOf('month')
							.add(1, 'days')
							.format('MM/DD/yyyy');
						//item.dateContactTo = moment.utc((firstDay)).toISOString(true)
						item.dateContactTo = moment(firstDay).toISOString(true);
					}

					if (item.currentlyWorkingWith === true) {
						item.dateContactTo = null;
					} else if (item.currentlyWorkingWith === undefined) {
						item.currentlyWorkingWith = null;
					}

					if (matchesTemplate(item, itemTemplate)) {
						return null;
					}

					return item;
				})
				.filter((d) => d !== null);
		}
		if (deleteRecords.length > 0) {
			deleteRecords.forEach((item) => {
				if (item.id !== null && item.id !== 'null') {
					item.status = 'Inactive';
					if (item.hasOwnProperty('internalId')) {
						delete item.internalId;
					}
					data['references'].push(item);
				}
			});
		}

		const jsonData = JSON.stringify(data);
		console.log('submitting: ' + jsonData);

		await postClinicianReferences(externalId, jsonData, apiType);
		reset(data);
		flags.dataSaved = true;
		flags.isDirty = false;
		success = true;

		sectionCompletion.references.current = await referencesStatusPointsCheck(
			data,
			sectionCompletion
		);
		updateSectionCompletion(sectionCompletion);

		updateFlags(flags);
		return success;
	}

	const deleteEntry = (btnPressed, index) => {
		if (btnPressed === 'Yes') {
			if (deleteRecordItem !== null) {
				deleteRecords.push(deleteRecordItem);
				setDeleteRecords(deleteRecords);
				setShowDeleteModal(false);
			}
			remove(deleteRecordIndex);
			setShowDeleteModal(false);
		}
		setShowDeleteModal(false);
	};

	const openDeleteConfirmation = (item, index) => {
		setDeleteRecordIndex(index);
		setDeleteRecordItem(item);
		setShowDeleteModal(true);
	};

	const dateValidation = (date) => {
		return !isNaN(new Date(date).getTime()) === true;
	};

	const numberValidation = (value) => {
		if (!!!value) {
			return true;
		}

		const number = value.replace(/-/g, '').trim();
		if (!number) return true;
		return number.length === 10 && !isNaN(number) ? true : false;
	};

	const renderForm = (item, index) => {
		const lockFields =
			!!item.id &&
			(credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN);

		return (
			<div className={classes.profileWrapper}>
				<Grid container>
					{(watch('haveReferenceInTwoYear') === 'true' ||
						watch('haveReferenceInTwoYear') === true) &&
					index === 0 ? (
						<p
							className={classes.headerText}
							dangerouslySetInnerHTML={{
								__html: t(`credentials:${constantType}.INFO_MESSAGE`),
							}}
						/>
					) : null}
					{watch('haveReferenceInTwoYear') === 'true' ||
					watch('haveReferenceInTwoYear') === true ? (
						<Grid container className={classes.secondaryHeaderArea}>
							<Grid item xs={9} className={classes.secondaryHeader}>
								{`Reference #${index + 1}`}
							</Grid>
							<Grid
								item
								xs={3}
								className={
									credState === CRED_STATE.CLOSED
										? classes.hideDeleteBtn
										: 'credAppAdditionalDelete'
								}
								onClick={() => {
									openDeleteConfirmation(item, index);
								}}
							>
								{t(`credentials:${constantType}.DELETE_BTN`)}
							</Grid>
						</Grid>
					) : null}
				</Grid>
				{watch('haveReferenceInTwoYear') === 'true' ||
				watch('haveReferenceInTwoYear') === true ? (
					<Container component='main' maxWidth='md'>
						<CssBaseline />
						<div>
							<Grid
								container
								item
								md={12}
								spacing={4}
								className={classes.containerSM}
							>
								<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
									<RouteDeparturePrompt
										blockedBackNavigation={blockedBackNavigation}
										when={
											!flags.dataSaved
												? !!Object.keys(formState.dirtyFields).length
												: false
										}
									/>
									<Grid container direction={'row'} spacing={4}>
										<Grid item xs={12} md={4}>
											<InputLabel
												shrink
												htmlFor={`references[${index}].firstName`}
												classes={{ root: classes.inputLabel }}
												className={
													((errors?.references &&
														errors?.references[`${index}`]
															?.firstName) ||
														(!!highlights &&
															highlights.includes(
																`references[${index}].firstName`
															))) &&
													classes.errorColor
												}
												required
											>
												{t(`credentials:${constantType}.FIRST_NAME`)}
											</InputLabel>
											<TextField
												autoComplete='off'
												variant='outlined'
												name={`references[${index}].firstName`}
												className={
													lockFields
														? classes.disabledInput
														: classes.normalInput
												}
												onBlur={() => {
													updateHighlight();
												}}
												onChange={() => {
													updateHighlight();
												}}
												error={
													(!!errors?.references &&
														errors?.references[`${index}`]
															?.firstName) ||
													(!!highlights &&
														highlights.includes(
															`references[${index}].firstName`
														))
												}
												fullWidth
												defaultValue={`${item.firstName}`}
												inputRef={register({
													maxLength: {
														value: 50,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
												})}
												InputProps={{
													readOnly: lockFields,
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
											/>
											<Input
												autoComplete='off'
												variant='outlined'
												style={{ display: 'none' }}
												name={`references[${index}].id`}
												fullWidth
												value={item.id}
												defaultValue={item.id}
												inputRef={register()}
											/>
											{((errors?.references &&
												errors?.references[`${index}`]?.firstName &&
												errors?.references[`${index}`]?.firstName[
													'type'
												] === 'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`references[${index}].firstName`
													))) && (
												<span className={classes.cssLabel}>
													{errors?.references &&
														errors?.references[`${index}`]?.firstName &&
														errors?.references[`${index}`]?.firstName
															.message}
												</span>
											)}
											{((errors?.references &&
												errors?.references[`${index}`]?.firstName &&
												errors?.references[`${index}`]?.firstName[
													'type'
												] !== 'maxLength') ||
												(highlights &&
													highlights.includes(
														`references[${index}].firstName`
													))) && (
												<span className={classes.cssLabel}>
													{t('credentials:VALIDATION_MESSAGE.FIRST_NAME')}
												</span>
											)}
										</Grid>

										<Grid item xs={12} md={4}>
											<InputLabel
												shrink
												htmlFor={`references[${index}].lastName`}
												classes={{ root: classes.inputLabel }}
												className={
													((errors?.references &&
														errors?.references[`${index}`]?.lastName) ||
														(highlights &&
															highlights.includes(
																`references[${index}].lastName`
															))) &&
													classes.errorColor
												}
												required
											>
												{t(`credentials:${constantType}.LAST_NAME`)}
											</InputLabel>
											<TextField
												autoComplete='off'
												variant='outlined'
												fullWidth
												defaultValue={`${item.lastName}`}
												name={`references[${index}].lastName`}
												className={
													lockFields
														? classes.disabledInput
														: classes.normalInput
												}
												onBlur={() => {
													updateHighlight();
												}}
												onChange={() => {
													updateHighlight();
												}}
												inputRef={register({
													maxLength: {
														value: 50,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
												})}
												error={
													(errors?.references &&
														errors?.references[`${index}`]?.lastName) ||
													(highlights &&
														highlights.includes(
															`references[${index}].lastName`
														))
												}
												InputProps={{
													readOnly: lockFields,
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
											/>
											{((errors?.references &&
												errors?.references[`${index}`]?.lastName &&
												errors?.references[`${index}`]?.lastName['type'] ===
													'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`references[${index}].lastName`
													))) && (
												<span className={classes.cssLabel}>
													{errors?.references &&
														errors?.references[`${index}`]?.lastName &&
														errors?.references[`${index}`]?.lastName
															.message}
												</span>
											)}
											{((errors?.references &&
												errors?.references[`${index}`]?.lastName &&
												errors?.references[`${index}`]?.lastName['type'] !==
													'maxLength') ||
												(highlights &&
													highlights.includes(
														`references[${index}].lastName`
													))) && (
												<span className={classes.cssLabel}>
													{t('credentials:VALIDATION_MESSAGE.LAST_NAME')}
												</span>
											)}
										</Grid>
									</Grid>
									<br />
									<br />
									<Grid container direction={'row'} spacing={4}>
										<Grid item xs={12} md={4}>
											<InputLabel
												shrink
												required
												htmlFor={`references[${index}].salutation`}
												classes={{ root: classes.inputLabel }}
												className={
													((errors?.references &&
														errors?.references[`${index}`]
															?.salutation) ||
														(!!highlights &&
															highlights.includes(
																`references[${index}].salutation`
															))) &&
													classes.errorColor
												}
											>
												{t(`credentials:${constantType}.JOB_TITLE`)}
											</InputLabel>
											<ThemeProvider
												theme={
													(errors?.references &&
														errors?.references[`${index}`]
															?.salutation) ||
													(!!highlights &&
														highlights.includes(
															`references[${index}].salutation`
														))
														? salutationErrorTheme
														: salutationNormalTheme
												}
											>
												<NativeSelect
													defaultValue={`${item.salutation}`}
													inputRef={register()}
													fullWidth
													name={`references[${index}].salutation`}
													className={
														lockFields
															? classes.disabledSelect
															: classes.normalInput
													}
													disabled={lockFields}
													input={
														<TextFieldComponent
															style={{
																border: `0px solid ${COLORS.RAVEN}`,
															}}
															classes={{
																input: classes.setWidthTo100OnMobile,
															}}
														/>
													}
													error={
														(!!errors?.references &&
															errors?.references[`${index}`]
																?.salutation) ||
														(!!highlights &&
															highlights.includes(
																`references[${index}].salutation`
															))
													}
													onBlur={() => {
														updateHighlight();
													}}
													onChange={() => {
														updateHighlight();
													}}
												>
													<option key={''} value={null}></option>
													<option key={'Dr.'} value={'Dr.'}>
														Dr.
													</option>
													<option key={'Mrs.'} value={'Mrs.'}>
														Mrs.
													</option>
													<option key={'Ms.'} value={'Ms.'}>
														Ms.
													</option>
													<option key={'Mr.'} value={'Mr.'}>
														Mr.
													</option>
												</NativeSelect>
											</ThemeProvider>
											{((errors?.references &&
												errors?.references[`${index}`]?.salutation) ||
												(!!highlights &&
													highlights.includes(
														`references[${index}].salutation`
													))) && (
												<span className={classes.cssLabel}>
													{t('credentials:VALIDATION_MESSAGE.JOB_TITLE')}
												</span>
											)}
											{lockFields ? (
												<Input
													style={{ display: 'none' }}
													name={`references[${index}].salutation`}
													value={`${item.salutation}`}
													inputRef={register()}
												/>
											) : null}
										</Grid>

										<Grid item xs={12} md={4}>
											<InputLabel
												shrink
												htmlFor={`references[${index}].relationshipToApplicant`}
												classes={{ root: classes.inputLabel }}
												className={
													((errors?.references &&
														errors?.references[`${index}`]
															?.relationshipToApplicant) ||
														(!!highlights &&
															highlights.includes(
																`references[${index}].relationshipToApplicant`
															))) &&
													classes.errorColor
												}
											>
												{t(`credentials:${constantType}.RELATIONSHIP`)}
											</InputLabel>
											<TextField
												autoComplete='off'
												variant='outlined'
												fullWidth
												defaultValue={`${item.relationshipToApplicant}`}
												name={`references[${index}].relationshipToApplicant`}
												className={
													!!item.id && credState === CRED_STATE.CLOSED
														? classes.disabledInput
														: classes.normalInput
												}
												inputRef={register({
													maxLength: {
														value: 100,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
												})}
												error={
													(!!errors?.references &&
														errors?.references[`${index}`]
															?.relationshipToApplicant) ||
													(!!highlights &&
														highlights.includes(
															`references[${index}].relationshipToApplicant`
														))
												}
												onBlur={() => {
													updateHighlight();
												}}
												InputProps={{
													readOnly:
														!!item.id &&
														credState === CRED_STATE.CLOSED,
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
											/>
											{((errors?.references &&
												errors?.references[`${index}`]
													?.relationshipToApplicant &&
												errors?.references[`${index}`]
													?.relationshipToApplicant['type'] ===
													'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`references[${index}].relationshipToApplicant`
													))) && (
												<span className={classes.cssLabel}>
													{errors?.references &&
														errors?.references[`${index}`]
															?.relationshipToApplicant &&
														errors?.references[`${index}`]
															?.relationshipToApplicant.message}
												</span>
											)}
											{((errors?.references &&
												errors?.references[`${index}`]
													?.relationshipToApplicant &&
												errors?.references[`${index}`]
													?.relationshipToApplicant['type'] !==
													'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`references[${index}].relationshipToApplicant`
													))) && (
												<span className={classes.cssLabel}>
													{t(
														'credentials:VALIDATION_MESSAGE.RELATIONSHIP'
													)}
												</span>
											)}
										</Grid>
									</Grid>
									<br />
									<br />
									<Grid container direction={'row'} spacing={4}>
										<Grid
											item
											xs={8}
											md={8}
											classes={{ root: classes.marginBottom20Neg }}
										>
											<FormControl
												classes={{ root: classes.setWidthSelectGroup }}
											>
												<InputLabel
													shrink
													required
													htmlFor={`references[${index}].specialty`}
													classes={{ root: classes.selectInputLabel }}
													className={
														((errors?.references &&
															errors?.references[`${index}`]
																?.specialty) ||
															(!!highlights &&
																highlights.includes(
																	`references[${index}].specialty`
																))) &&
														classes.errorColor
													}
												>
													{t(`credentials:${constantType}.SPECIALTY`)}
												</InputLabel>
												<ThemeProvider
													theme={
														(errors?.references &&
															errors?.references[`${index}`]
																?.specialty) ||
														(!!highlights &&
															highlights.includes(
																`references[${index}].specialty`
															))
															? errorTheme
															: normalTheme
													}
												>
													<NativeSelect
														defaultValue={`${item.specialty}`}
														inputRef={register()}
														name={`references[${index}].specialty`}
														className={
															!!item.id &&
															credState === CRED_STATE.CLOSED
																? classes.disabledSelect
																: classes.normalInput
														}
														disabled={
															!!item.id &&
															credState === CRED_STATE.CLOSED
														}
														input={
															<TextFieldComponent
																style={{
																	border: `0px solid ${COLORS.RAVEN}`,
																}}
																classes={{
																	input: classes.setWidthTo100OnMobile,
																}}
															/>
														}
														error={
															(!!errors?.references &&
																errors?.references[`${index}`]
																	?.specialty) ||
															(!!highlights &&
																highlights.includes(
																	`references[${index}].specialty`
																))
														}
														onBlur={() => {
															updateHighlight();
														}}
														onChange={() => {
															updateHighlight();
														}}
													>
														<option key={''} value={null}></option>
														{activePath === ACTIVE_PATHNAME_MD
															? specialtiesMD
																	.sort((a, b) =>
																		a.label > b.label
																			? 1
																			: b.label > a.label
																			? -1
																			: 0
																	)
																	.map((item) => (
																		<option
																			key={item.value}
																			value={item.value}
																		>
																			{item.label}
																		</option>
																	))
															: specialties
																	.sort((a, b) =>
																		a.label > b.label
																			? 1
																			: b.label > a.label
																			? -1
																			: 0
																	)
																	.map((item) => (
																		<option
																			key={item.value}
																			value={item.value}
																		>
																			{item.label}
																		</option>
																	))}
														<optgroup label=''></optgroup>
													</NativeSelect>
												</ThemeProvider>
												{((errors?.references &&
													errors?.references[`${index}`]?.specialty) ||
													(!!highlights &&
														highlights.includes(
															`references[${index}].specialty`
														))) && (
													<span className={classes.cssLabel}>
														{t(
															'credentials:VALIDATION_MESSAGE.SPECIALTY'
														)}
													</span>
												)}
											</FormControl>

											{!!item.id && credState === CRED_STATE.CLOSED ? (
												<Input
													style={{ display: 'none' }}
													name={`references[${index}].specialty`}
													value={`${item.specialty}`}
													inputRef={register()}
												/>
											) : null}
										</Grid>
									</Grid>
									<Grid
										container
										spacing={4}
										classes={{ root: classes.setWidthSelectGroup }}
									>
										<Grid item xs={12} md={4}>
											<FormControl
												classes={{ root: classes.setWidthTo100OnMobile }}
											>
												{watch(
													`references[${index}].currentlyWorkingWith`
												) !== true ? (
													<>
														<InputLabel
															shrink
															required={
																watch(
																	`references[${index}].currentlyWorkingWith`
																) === true
																	? false
																	: true
															}
															htmlFor={`references[${index}].dateContactTo`}
															classes={{
																root: classes.selectInputLabel,
															}}
															className={
																((errors?.references &&
																	errors?.references[`${index}`]
																		?.dateContactTo) ||
																	(!!highlights &&
																		highlights.includes(
																			`references[${index}].dateContactTo`
																		))) &&
																classes.errorColor
															}
														>
															{t(
																`credentials:${constantType}.DATETO`
															)}
														</InputLabel>
														<ThemeProvider theme={datePickerTheme}>
															<MuiPickersUtilsProvider
																utils={DateFnsUtils}
															>
																<Controller
																	control={control}
																	rules={{
																		validate: {
																			validDate: (date) =>
																				dateValidation(
																					date
																				),
																			validValue: (date) =>
																				referenceDateToValidation(
																					date
																				),
																		},
																	}}
																	name={`references[${index}].dateContactTo`}
																	defaultValue={
																		`${item.dateContactTo}` ===
																		'null'
																			? null
																			: `${item.dateContactTo}`
																	}
																	render={({
																		onChange,
																		onBlur,
																		value,
																		name,
																	}) => (
																		<DatePickerComponent
																			onChange={(e) => {
																				onChange(e);
																				updateHighlight();
																			}}
																			name={name}
																			value={value}
																			format='MM/yyyy'
																			placeholder='MM/YYYY'
																			clearable
																			variant='inline'
																			autoOk='true'
																			disabled={
																				!!item.id &&
																				credState ===
																					CRED_STATE.CLOSED
																			}
																			minDateMessage={
																				<span
																					className={`${classes.cssLabel} ${classes.marginLeft12}`}
																				>
																					{t(
																						`credentials:${constantType}.DATE_VALIDATION`
																					)}
																				</span>
																			}
																			maxDateMessage={
																				<span
																					className={`${classes.cssLabel} ${classes.marginLeft12}`}
																				>
																					{t(
																						`credentials:${constantType}.DATE_VALIDATION`
																					)}
																				</span>
																			}
																			invalidDateMessage={``}
																			margin='normal'
																			theme={datePickerTheme}
																			error={
																				(errors?.references &&
																					errors
																						?.references[
																						`${index}`
																					]
																						?.dateContactTo) ||
																				(!!highlights &&
																					highlights.includes(
																						`references[${index}].dateContactTo`
																					))
																			}
																			InputProps={{
																				classes: {
																					disabled:
																						classes.disabledInput,
																					root: classes.cssOutlinedInput,
																					error: classes.error,
																				},
																			}}
																			onClose={() => {
																				updateHighlight();
																			}}
																			onBlur={() => {
																				updateHighlight();
																			}}
																			KeyboardButtonProps={{
																				'aria-label': t(
																					'credentials:OTHER_CERTIFICATIONS.EXPIRATION'
																				),
																			}}
																			inputVariant='outlined'
																		/>
																	)}
																/>
															</MuiPickersUtilsProvider>
														</ThemeProvider>
													</>
												) : (
													''
												)}
											</FormControl>
											{((errors?.references &&
												errors?.references[`${index}`]?.dateContactTo &&
												errors?.references[`${index}`]?.dateContactTo
													.type === 'validDate') ||
												(!!highlights &&
													highlights.includes(
														`references[${index}].dateContactTo`
													) &&
													!(
														errors?.references &&
														errors?.references[`${index}`]
															?.dateContactTo &&
														errors?.references[`${index}`]
															?.dateContactTo.type === 'validValue'
													))) && (
												<span
													className={`${classes.cssLabel} ${classes.marginLeft12}`}
												>
													{t(
														`credentials:${constantType}.DATE_VALIDATION`
													)}
												</span>
											)}
											{errors?.references &&
												errors?.references[`${index}`]?.dateContactTo &&
												errors?.references[`${index}`]?.dateContactTo
													.type === 'validValue' && (
													<span
														className={`${classes.cssLabel} ${classes.marginLeft12}`}
													>
														{t(
															`credentials:${constantType}.DATE_VALIDATION_VALUE`
														)}
													</span>
												)}
										</Grid>
										<Grid container item md spacing={4}>
											<Grid item xs={12} md={4}>
												<FormControl
													classes={{
														root: classes.setWidthTo100OnMobile,
													}}
												>
													<InputLabel
														shrink
														htmlFor={`references[${index}].currentlyWorkingWith`}
														classes={{
															root: classes.checkboxInputLabel,
															error: classes.error,
														}}
														error={
															(errors?.references &&
																errors?.references[`${index}`]
																	?.currentlyWorkingWith) ||
															(!!highlights &&
																highlights.includes(
																	`references[${index}].currentlyWorkingWith`
																))
														}
													>
														{t(
															'credentials:REFERENCES.CURRENTLY_WORKING_WITH'
														)}
													</InputLabel>

													<Controller
														name={`references[${index}].currentlyWorkingWith`}
														control={control}
														rules={{}}
														defaultChecked={item.currentlyWorkingWith}
														render={({ onChange, value, name }) => (
															<Checkbox
																color='primary'
																onChange={(e) => {
																	onChange(e.target.checked);
																	setTimeout(() => {
																		updateHighlight();
																	}, 50);
																}}
																checked={value}
																name={name}
																className={classes.checkboxInput}
																disabled={lockFields}
															/>
														)}
													/>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>

									<Grid container direction={'row'} spacing={4}>
										<Grid item xs={8} md={8}>
											<h4 className='cr-foundation'>
												Best way to contact this reference
											</h4>
											<p className='cr-text-bold cr-text-sm'>
												NOTE: Only an email OR a phone number is needed.
											</p>
										</Grid>
									</Grid>
									<Grid container direction={'row'} spacing={4}>
										<Grid item xs={8} md={8}>
											<InputLabel
												shrink
												htmlFor={`references[${index}].email`}
												classes={{ root: classes.inputLabel }}
												className={
													(((errors?.references &&
														errors?.references[`${index}`]?.email) ||
														(!!highlights &&
															highlights.includes(
																`references[${index}].email`
															))) &&
														classes.errorColor,
													classes.inputLabel)
												}
												required
											>
												{t(`credentials:${constantType}.EMAIL`)}
											</InputLabel>
											<TextField
												autoComplete='off'
												variant='outlined'
												fullWidth
												defaultValue={`${item.email}`}
												name={`references[${index}].email`}
												className={
													!!item.id && credState === CRED_STATE.CLOSED
														? classes.disabledInput
														: classes.normalInput
												}
												inputRef={register({
													pattern:
														/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
													maxLength: {
														value: 80,
														message: t(
															'common:MESSAGES.CHARACTER_LIMIT'
														),
													},
												})}
												InputProps={{
													readOnly:
														!!item.id &&
														credState === CRED_STATE.CLOSED,
													classes: {
														root: classes.cssOutlinedInput,
														focused: classes.cssFocused,
														notchedOutline: classes.notchedOutline,
														error: classes.error,
													},
												}}
												error={
													(!!errors?.references &&
														errors?.references[`${index}`]?.email) ||
													(!!highlights &&
														highlights.includes(
															`references[${index}].email`
														))
												}
												onBlur={() => {
													updateHighlight();
												}}
												onChange={() => {
													updateHighlight();
												}}
											/>
											{((errors?.references &&
												errors?.references[`${index}`]?.email &&
												errors?.references[`${index}`]?.email['type'] ===
													'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`references[${index}].email`
													))) && (
												<span className={classes.cssLabel}>
													{errors?.references &&
														errors?.references[`${index}`]?.email &&
														errors?.references[`${index}`]?.email
															.message}
												</span>
											)}
											{((errors?.references &&
												errors?.references[`${index}`]?.email &&
												errors?.references[`${index}`]?.email['type'] !==
													'maxLength') ||
												(!!highlights &&
													highlights.includes(
														`references[${index}].email`
													))) && (
												<span className={classes.cssLabel}>
													{t(
														'credentials:VALIDATION_MESSAGE.EMAIL_ADDRESS'
													)}
												</span>
											)}
										</Grid>
									</Grid>
									<br />
									<br />
									<Grid container direction={'row'} spacing={4}>
										<Grid item xs={12} md={4}>
											<InputLabel
												shrink
												required
												htmlFor={`references[${index}].phone`}
												classes={{ root: classes.inputLabel }}
												className={
													((errors?.references &&
														errors?.references[`${index}`]?.phone) ||
														(!!highlights &&
															highlights.includes(
																`references[${index}].phone`
															))) &&
													classes.errorColor
												}
											>
												{t(`credentials:${constantType}.PHONE`)}
											</InputLabel>
											<Controller
												control={control}
												name={`references[${index}].phone`}
												rules={{
													validate: (value) => numberValidation(value),
												}}
												render={({
													onChange,
													onBlur,
													value,
													ref,
													name,
												}) => (
													<InputMask
														name={name}
														mask='999-999-9999'
														disabled={false}
														maskChar=' '
														defaultValue={`${item.phone}`}
														onChange={onChange}
														onBlur={() => {
															updateHighlight();
														}}
													>
														{() => (
															<TextField
																autoComplete='off'
																variant='outlined'
																name={name}
																placeholder='XXX-XXX-XXXX'
																helperText={
																	errors?.references &&
																	errors?.references[`${index}`]
																		?.phone &&
																	t(
																		'credentials:VALIDATION_MESSAGE.HOME_PHONE'
																	)
																}
																fullWidth
																className={
																	!!item.id &&
																	credState === CRED_STATE.CLOSED
																		? classes.disabledInput
																		: classes.normalInput
																}
																InputProps={{
																	readOnly:
																		!!item.id &&
																		credState ===
																			CRED_STATE.CLOSED,
																	classes: {
																		root: classes.cssOutlinedInput,
																		focused: classes.cssFocused,
																		notchedOutline:
																			classes.notchedOutline,
																		error: classes.error,
																	},
																}}
																error={
																	(!!errors?.references &&
																		errors?.references[
																			`${index}`
																		]?.phone) ||
																	(!!highlights &&
																		highlights.includes(
																			`references[${index}].phone`
																		))
																}
															/>
														)}
													</InputMask>
												)}
											/>
										</Grid>

										<Grid
											item
											xs={12}
											md={4}
											classes={{ root: classes.gridRoot }}
										>
											<InputLabel
												shrink
												htmlFor={`references[${index}].fax`}
												classes={{ root: classes.inputLabel }}
												className={
													((errors?.references &&
														errors?.references[`${index}`]?.fax) ||
														(!!highlights &&
															highlights.includes(
																`references[${index}].fax`
															))) &&
													classes.errorColor
												}
											>
												{t(`credentials:${constantType}.FAX`)}
											</InputLabel>
											<Controller
												control={control}
												name={`references[${index}].fax`}
												rules={{
													validate: (value) => numberValidation(value),
												}}
												render={({
													onChange,
													onBlur,
													value,
													ref,
													name,
												}) => (
													<InputMask
														name={name}
														mask='999-999-9999'
														disabled={false}
														maskChar=' '
														defaultValue={`${item.fax}`}
														onChange={onChange}
														onBlur={() => {
															updateHighlight();
														}}
													>
														{() => (
															<TextField
																autoComplete='off'
																variant='outlined'
																name={name}
																placeholder='XXX-XXX-XXXX'
																helperText={
																	errors?.references &&
																	errors?.references[`${index}`]
																		?.fax &&
																	t(
																		'credentials:VALIDATION_MESSAGE.HOME_PHONE'
																	)
																}
																fullWidth
																className={
																	!!item.id &&
																	credState === CRED_STATE.CLOSED
																		? classes.disabledInput
																		: classes.normalInput
																}
																InputProps={{
																	readOnly:
																		!!item.id &&
																		credState ===
																			CRED_STATE.CLOSED,
																	classes: {
																		root: classes.cssOutlinedInput,
																		focused: classes.cssFocused,
																		notchedOutline:
																			classes.notchedOutline,
																		error: classes.error,
																	},
																}}
																error={
																	(!!errors?.references &&
																		errors?.references[
																			`${index}`
																		]?.fax) ||
																	(!!highlights &&
																		highlights.includes(
																			`references[${index}].fax`
																		))
																}
															/>
														)}
													</InputMask>
												)}
											/>
										</Grid>
									</Grid>
									<br />
									<br />
								</form>
							</Grid>
						</div>
					</Container>
				) : (watch('haveReferenceInTwoYear') === 'false' ||
						watch('haveReferenceInTwoYear') === false) &&
				  index === 0 ? (
					<Container component='main' maxWidth='md'>
						<CssBaseline />
						<div>
							<Grid
								container
								item
								md={12}
								spacing={4}
								className={classes.containerSM}
							>
								<i>
									<h3 className={classes.infoMessage}>
										{t(`credentials:${constantType}.REFERENCES_MESSAGE`)}
									</h3>
								</i>
							</Grid>
						</div>
					</Container>
				) : null}
			</div>
		);
	};

	const renderHaveReferenceInTwoYear = () => {
		return (
			<div>
				<Grid item xs={12} className={classes.gridRoot}>
					<FormControl
						classes={{ root: classes.setWidthTo100OnMobile }}
						className={classes.radioGroup}
					>
						<InputLabel
							shrink
							htmlFor={`haveReferenceInTwoYear`}
							classes={{ root: classes.inputRadio }}
							className={
								(errors?.haveReferenceInTwoYear ||
									(!!highlights &&
										highlights.includes(`haveReferenceInTwoYear`))) &&
								classes.errorColor
							}
							required
						>
							{t(`credentials:${constantType}.REFERENCES_QUESTION`)}
						</InputLabel>
						<br />
						<div>
							<RadioGroup
								row
								aria-label='completed'
								name={`haveReferenceInTwoYear`}
								defaultValue={haveReferenceInTwoYear}
								classes={{ root: classes.radioGroupLabel }}
								onBlur={() => {
									updateHighlight();
								}}
								onChange={() => {
									setTimeout(() => {
										updateHighlight();
									}, 50);
								}}
							>
								<FormControlLabel
									value='true'
									control={
										<Radio
											color='primary'
											style={{ color: COLORS.RAVEN }}
											name={'haveReferenceInTwoYear'}
											inputRef={register()}
											disableRipple
											size='small'
											disabled={
												credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN
											}
											className={
												credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN
													? classes.disabledInput
													: classes.normalInput
											}
										/>
									}
									label={
										<span
											style={{
												fontSize: '16px',
												color: COLORS.RAVEN,
											}}
										>
											Yes
										</span>
									}
								/>
								<FormControlLabel
									value='false'
									control={
										<Radio
											color='primary'
											style={{ color: COLORS.RAVEN }}
											name={'haveReferenceInTwoYear'}
											inputRef={register()}
											disableRipple
											size='small'
											disabled={
												credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN
											}
											className={
												credState === CRED_STATE.CLOSED ||
												credState === CRED_STATE.PARTIALLY_OPEN
													? classes.disabledInput
													: classes.normalInput
											}
										/>
									}
									label={
										<span
											style={{
												fontSize: '16px',
												color: COLORS.RAVEN,
											}}
										>
											No
										</span>
									}
								/>
							</RadioGroup>
							{(errors?.haveReferenceInTwoYear ||
								(!!highlights &&
									highlights.includes(`haveReferenceInTwoYear`))) && (
								<span className={classes.cssLabel}>
									{t('credentials:VALIDATION_MESSAGE.ATTESTATION_QUESTION')}
								</span>
							)}
							<br />
						</div>
					</FormControl>
				</Grid>
			</div>
		);
	};

	const renderForms = () => {
		if (!isLoaded) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}
		return (
			<>
				{renderHaveReferenceInTwoYear()}
				{fields.map((item, index) => (
					<div key={item.internalId}>
						{renderForm(item, index)}
						<RequiredQuestionDialog
							open={showDeleteModal}
							title={t(`credentials:${constantType}.CONFIRM_DELETE_TITLE`)}
							text={t(`credentials:${constantType}.CONFIRM_DELETE_MESSAGE`).replace(
								`(${type})`,
								`${type}`
							)}
							buttonTexts={t('common:BUTTONS.YES') + ';' + t('common:BUTTONS.NO')}
							buttonIDs='Yes;No'
							disableBackgroundClick={false}
							onClose={(btnPressed) => {
								deleteEntry(btnPressed, index);
							}}
						/>
					</div>
				))}
			</>
		);
	};

	return (
		<div>
			<Grid className={classes.leftPad30}>
				<Grid item xs={12}>
					<h2 className='cr-mt-4'>{t(`credentials:${constantType}.TITLE`)}</h2>
					{credState === CRED_STATE.CLOSED || credState === CRED_STATE.PARTIALLY_OPEN ? (
						<Grid item xs={12} md={10} className={classes.messageGrid}>
							<Alert severity='error' className={classes.alertMessage} icon={false}>
								{t(`credentials:CREDENTIALS.CRED_APP_LOCKED_MESSAGE`)}
							</Alert>
						</Grid>
					) : null}
				</Grid>
				{renderForms()}
			</Grid>

			{isLoaded &&
			credState !== CRED_STATE.CLOSED &&
			(watch('haveReferenceInTwoYear') === 'true' ||
				watch('haveReferenceInTwoYear') === true) ? (
				<div
					className='credAppAddMoreButton'
					onClick={() =>
						append({
							id: 'null',
							firstName: '',
							lastName: '',
							salutation: '',
							specialty: '',
							relationshipToApplicant: '',
							dateContactTo: null,
							phone: '',
							fax: '',
							email: '',
							currentlyWorkingWith: null,
						})
					}
				>
					{t(`credentials:${constantType}.ADD_MORE_REFERENCES`)}
				</div>
			) : null}
		</div>
	);
}

export default ReferencesForm;
