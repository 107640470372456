export const ExpenseUserGroupEnum = {
	ExpenseSystemAdministrator: 'LTOE - Expense System Administrator',
	LTExpenseAssociate: 'LTOE - LT Expense Associate ViewOnly',
	ExpenseAdministrator: 'LTOE - Expense Administrator',
};

export const ExpenseUserGroupProdEnum = {
	ExpenseSystemAdministratorPROD: 'LTOE - Expense System Administrator PROD',
	LTExpenseAssociatePROD: 'LTOE - LT Expense Associate ViewOnly PROD',
	ExpenseAdministratorPROD: 'LTOE - Expense Administrator PROD',
};

export const TimesheetUserGroupEnum = {
	SystemAdministrator: 'LTOE - System Administrator',
	LTAssociate: 'LTOE - LT Associate ViewOnly',
	TimesheetAdmin: 'LTOE - Timesheet Admin',
};

export const TimesheetUserGroupProdEnum = {
	SystemAdministratorPROD: 'LTOE - System Administrator PROD',
	LTAssociatePROD: 'LTOE - LT Associate ViewOnly PROD',
	TimesheetAdminPROD: 'LTOE - Timesheet Admin PROD',
};

export const TimesheetUserGroups = [
	TimesheetUserGroupEnum.SystemAdministrator,
	TimesheetUserGroupEnum.LTAssociate,
	TimesheetUserGroupEnum.TimesheetAdmin,
];

export const ExpenseUserGroups = [
	ExpenseUserGroupEnum.ExpenseSystemAdministrator,
	ExpenseUserGroupEnum.LTExpenseAssociate,
	ExpenseUserGroupEnum.ExpenseAdministrator,
];

export const TimesheetUserProdGroups = [
	TimesheetUserGroupProdEnum.SystemAdministratorPROD,
	TimesheetUserGroupProdEnum.LTAssociatePROD,
	TimesheetUserGroupProdEnum.TimesheetAdminPROD,
];

export const ExpenseUserProdGroups = [
	ExpenseUserGroupProdEnum.ExpenseSystemAdministratorPROD,
	ExpenseUserGroupProdEnum.LTExpenseAssociatePROD,
	ExpenseUserGroupProdEnum.ExpenseAdministratorPROD,
];
