import React from 'react';
import { withTranslation } from 'react-i18next';
import {
	withStyles,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import WithContexts from '../../../contexts/withContexts';
import { COLORS } from '../../../utils/Application_Constants';
import moment from 'moment';

const styles = (theme) => ({
	tableContainer: {
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		borderRadius: '8px',
	},
	tableHead: {
		fontSize: '12px',
		lineHeight: '16px',
		color: COLORS.LT_MIDNIGHT33,
		padding: '8px 16px',
	},
	emptyText: {
		color: COLORS.LT_MIDNIGHT25,
	},
});

function MileageReviewTable(props) {
	const { classes, t, mileageEntries } = props;

	const renderTableBody = () => {
		if (mileageEntries && mileageEntries.length > 0) {
			return mileageEntries.map((row, index) => (
				<TableRow key={row.id} id={`mileage-entry--${index}`}>
					<TableCell>{moment(row.transactionDate).format('ddd M/D')}</TableCell>
					<TableCell>{row.miles}</TableCell>
					<TableCell className={!row.comment ? classes.emptyText : null}>
						{row.comment ? row.comment : 'No comments'}
					</TableCell>
				</TableRow>
			));
		} else {
			return (
				<TableRow id='mileage-entry--no-data'>
					<TableCell colSpan={3}>
						{t('submitTimesheet:MILEAGE_TABLE.NO_MILEAGE_DATA')}
					</TableCell>
				</TableRow>
			);
		}
	};

	return (
		<TableContainer className={classes.tableContainer}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className={classes.tableHead}>
							{t('submitTimesheet:MILEAGE_TABLE.DATE')}
						</TableCell>
						<TableCell className={classes.tableHead}>
							{t('submitTimesheet:MILEAGE_TABLE.MILEAGE')}
						</TableCell>
						<TableCell className={classes.tableHead}>
							{t('submitTimesheet:MILEAGE_TABLE.COMMENTS')}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{renderTableBody()}</TableBody>
			</Table>
		</TableContainer>
	);
}

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(MileageReviewTable))));
