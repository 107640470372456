/**
 *@file Services file that fetches MetaData from the LT API
 */
import {
	axiosWCApiInstance,
} from '../configuration/axios/axiosConfiguration';
import { ApiError } from '../utils/ApiError';

async function getHolidays() {
	let response = {
		data: {},
	};
	try {
		response = await axiosWCApiInstance.get(`holidays`);
	} catch (error) {
		console.error(`${getHolidays.name} error=${error}`);
		if (!!error.response && !!error.response.data) {
			console.error(`throwing ApiError:${JSON.stringify(error.response.data)}`);
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.error(`throwing ApiError:${JSON.stringify(error.message)}`);
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				getHolidays.name
			);
		}
	}
	return response.data;
}

export {
	getHolidays,
};
