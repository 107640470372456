import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
	bottom: {
		color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	top: {
		color: '#1a90ff',
		animationDuration: '550ms',
		position: 'absolute',
	},
	circle: {
		strokeLinecap: 'round',
		color: '#0076bb',
	},
});

class CustomCircularProgress extends Component {
	constructor(props) {
		super(props);
		const { progress } = this.props;
		this.state = {
			value: progress,
		};
	}

	render() {
		const { classes, size, thickness, progressType } = this.props;
		return (
			<Box position='relative' display='inline-flex'>
				<CircularProgress
					variant='static'
					className={classes.bottom}
					size={size}
					thickness={thickness}
					value={100}
				/>
				<CircularProgress
					variant='static'
					disableShrink
					className={classes.top}
					classes={{
						circle: classes.circle,
					}}
					size={size}
					thickness={thickness}
					value={this.state.value}
				/>
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position='absolute'
					display='flex'
					alignItems='center'
					justifyContent='center'
				>
					{progressType === 'desktop' ? (
						<h2
							style={{ color: '#0076bb', fontWeight: '700', margin: '0px' }}
						>{`${Math.round(this.state.value)}%`}</h2>
					) : (
						<h5
							style={{ color: '#0076bb', fontWeight: '700', margin: '0px' }}
						>{`${Math.round(this.state.value)}%`}</h5>
					)}
				</Box>
			</Box>
		);
	}
}

export default withStyles(styles)(CustomCircularProgress);
