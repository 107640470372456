/**
 *@file Services file that is responsible for SAML generation and rerouting
 */
import { axiosWCApiInstance } from '../configuration/axios/axiosConfiguration';
import msalService from '../services/msSalService';
import { ApiError } from '../utils/ApiError';

/**
 * Gets signed SAML response from SSO azure function
 */
async function getSamlResponse() {
	let response;
	let externalId = getExternalId();
	try {
		response = await axiosWCApiInstance.get(
			`clinicians/${externalId}/GenerateSamlResponse`,
			{}
		);
	} catch (error) {
		//Error(error);
		console.log('GenerateSamlResponse error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianTaxStatementsZip'
			);
		}
	}
	// checks both null and undefined
	if (!response.error && response.data !== null && response.data !== undefined) {
		const fakeElement = document.createElement('html');
		fakeElement.innerHTML = response.data.trim();
		document.body.appendChild(fakeElement);
		document.forms.samlform.target = '_self';
		document.forms.samlform.submit();
		document.body.removeChild(fakeElement);
	} else {
		console.log('Response.data is empty');
	}
}

function getExternalId() {
	const account = msalService.getAccount || null;
	const id = account?.idTokenClaims?.extension_SfExternalId;
	if (!id) return null;
	return id.trim();
}

export { getSamlResponse };
