import React, { Component } from 'react';
import {
	withStyles,
	Container,
	Grid,
	Paper,
	Box,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { COLORS } from '../../utils/Application_Constants';
import { getPresents } from '../../services/PresentsService';
import WithContexts from '../../contexts/withContexts';
import QuickSearchWidget from '../../components/ContentComponents/QuickSearchWidget/QuickSearchWidget';
import LetUsDoTheWorkTile from '../../components/ContentComponents/LetUsDoTheWork/LetUsDoTheWorkTile';
import SelfEmploymentPlanning from '../../components/ContentComponents/LocumTennensResources/SelfEmploymentPlanning';
import SalarySurveyTile from '../../components/ContentComponents/LocumTennensResources/SalarySurveyTile';
import ResourcesTile from '../../components/ContentComponents/LocumTennensResources/ResourcesTile';
import msSalService from '../../services/msSalService';
import UserContext from '../../contexts/userContext';
import { getRecommendedJobList } from '../../services/ProfileServices/profileService';
import ErrorDialog from '../../components/GlobalComponents/Dialog/ErrorDialog';
import CarouselComponent from '../../components/GlobalComponents/CarouselComponent/CarouselComponent';
import { ltdomain } from '../../utils/helpers';
import { NavLink, withRouter } from 'react-router-dom';
import PreferenceCompletion from '../../components/ContentComponents/Preferences/PreferenceCompletion';
import { withTranslation } from 'react-i18next';
import GetCredentialsWidgetDashboard from '../../components/ContentComponents/Credentials/GetCredentialsWidgetDashboard/GetCredentialsWidgetDashboard';
import PresentComponent from '../../components/ContentComponents/PresentsList/PresentComponent';
import UpdatePrefsImg from '../../assets/images/ltoe-update-preferences-icon.svg';
import savedJobsIcon from '../../assets/images/ltoe-saved-jobs.svg';
import presentsIcon from '../../assets/images/ltoe-presents.svg';
import cvIcon from '../../assets/images/ltoe-cv-manager.svg';

const styles = (theme) => ({
	prefPaper: {
		padding: theme.spacing(4),
	},

	root: {
		backgroundColor: COLORS.BLACK_HAZE,
		paddingBottom: '30px',
		minHeight: '800px',
	},
	activeAssignmentRoot: {
		marginTop: '30px',
		paddingTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	upcomingAssignmentRoot: {
		marginTop: '30px',
		paddingTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	carouselRoot: {
		marginTop: '25px',
		paddingTop: '25px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		marginTop: '25vh',
	},
	headerJobs: {
		color: COLORS.LT_STEEL,
		fontWeight: '600',
		fontSize: '15px',
		textAlign: 'left',
		paddingTop: '15px',
		paddingBottom: '2px',
	},
	//   tileHeader: {
	//     color: COLORS.LT_STEEL,
	//     fontWeight: '700',
	//     textTransform: 'uppercase',
	//     letterSpacing: '.5px',
	//     fontSize: '1rem',
	//     display: 'block',
	//     marginBottom: '.5rem',
	//   },
	tileHeaderPreferecne: {
		color: '#172B4D',
		fontWeight: '700',
		letterSpacing: '.5px',
		fontSize: '1rem',
		display: 'block',
		marginBottom: '2rem',
		textAlign: 'center',
	},
	ContainerRoot: {
		// [theme.breakpoints.up('md')]: {
		//     paddingLeft: 70,
		//     paddingRight: 70
		// },
		paddingTop: '4rem',
	},
	topTileRoot: {
		marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	helpPanelContainerRootJobSearch: {
		padding: '15px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	helpPanelContainerRoot: {
		padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	linkHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '22px',
		textAlign: 'left',
		whiteSpace: 'nowrap',
	},
	linkHeaderJobSearch: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
		whiteSpace: 'nowrap',
	},
	helpPanelRootLevel1: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		backgroundColor: COLORS.RICH_ELECTRIC_BLUE,
	},
	dateInfoGrid: {
		display: 'grid',
		width: '150%',
		gridTemplateColumns: 'repeat(auto-fit, minmax(40%, 1fr))',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			gridTemplateColumns: 'repeat(auto-fit, minmax(50%, 1fr))',
		},
	},
	dateItemColumn: {
		textAlign: 'center',
	},
	iconIndicator: {
		textAlign: 'left',
	},
	iconText: {
		marginTop: '.5rem',
		marginBottom: '0px',
		color: COLORS.LT_MIDNIGHT,
	},
	jobsIcon: {
		cursor: 'pointer',
	},
	savedPaper: {
		borderTopColor: COLORS.LT_POMEGRANATE,
		borderTopStyle: 'solid',
		display: 'block',
		textAlign: 'center',
		marginBottom: '1.5rem',
	},
	presentsIcon: {
		// paddingTop: '10px',
		// paddingBottom: '10px',
		// marginLeft: '40%'
		cursor: 'pointer',
	},
	presentsPaper: {
		borderTopColor: COLORS.LT_JOURNEY,
		borderTopStyle: 'solid',
		display: 'block',
		textAlign: 'center',
		marginBottom: '1.5rem',
		// marginTop:'-40px'
	},
	cvIcon: {
		color: COLORS.LT_SUNFLOWER,
		// paddingTop: '10px',
		// paddingBottom: '10px',
		// marginLeft: '40%'
	},
	cvPaper: {
		borderTopColor: COLORS.LT_SUNFLOWER,
		borderTopStyle: 'solid',
		display: 'block',
		textAlign: 'center',
		// marginTop:'-25px'
	},
	preferenceIcon: {
		// paddingTop: '10px',
		// paddingBottom: '10px',
		// marginLeft: '40%'
	},
	preferencePaper: {
		color: COLORS.LT_EMERALD,
		borderTopStyle: 'solid',
		display: 'block',
		textAlign: 'center',
		// marginTop:'-25px'
	},
	clinicianActionIcon: {
		width: 'auto',
		height: '40px',
	},
	preferecneCenterPaper: {
		padding: '32px',
		// height:'40vh'
	},
	jobOpportunityPaper: {
		padding: '0px',
	},
	linkHeaderTitle: {
		color: '#172B4D',
		fontWeight: '700',
		fontSize: '24px',
		margin: 0,
		textAlign: 'left',
		whiteSpace: 'nowrap',
		marginTop: '20px',
	},
});
class DashboardL1 extends Component {
	constructor(props) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName('');
		this.state = {
			isLoading: true,
			viewToggleNotification: false,
			openDialog: true,
			openAlert: true,
			signDocument: false,
			signDocumentURL: '',
			signDocumentError: '',
			timesheetNotification: false,
			documentNotification: false,
			recommendedJobList: {},
			savedJobLink: null,
			openPresentsDialog: false,
			optimizeVariant: 0,
			flags: {
				isLoading: false,
				dataSaved: false,
				isDirty: false,
				showSaveChanges: false,
				isPendingSave: false,
				targetTab: 0,
				validationError: false,
				navDisabled: false,
				//apiError: '',
				//errorMessage: '',
			},
		};
	}

	async componentDidMount() {
		this.mountDashboard();
		msSalService.setInitialRedirectUri();

		await window.dataLayer.push({ event: 'optimize.activate' });
		var checkCount = 0;
		this.intervalId = setInterval(async () => {
			console.log('checking for optimize:' + checkCount);
			if (window.google_optimize === undefined) {
				if (++checkCount > 20) {
					clearInterval(this.intervalId);
				}
			} else {
				clearInterval(this.intervalId);
				this.intervalId = null;

				const variant = window.google_optimize.get(
					process.env.REACT_APP_GOOGLE_EXPERIMENT_ID_FOR_JOBS
				);
				console.log('variant=' + variant);
				var useAltSearch = false;
				if (typeof variant !== 'undefined') {
					useAltSearch = variant === 1;
					this.setState({ optimizeVariant: variant });
				}
				this.setRecEngineMethod(useAltSearch);
			}
		}, 100);
	}

	async setRecEngineMethod(useAltSearch) {
		const { externalId } = this.props.UserContext;
		await Promise.all([getRecommendedJobList(externalId, useAltSearch)])
			.then(([recommendedJobList]) => {
				console.log('gotJobs:' + recommendedJobList);
				if (recommendedJobList && recommendedJobList.length > 4) {
					recommendedJobList = recommendedJobList.slice(0, 4);
				}
				this.setState({
					recommendedJobList: recommendedJobList,
				});
			})
			.catch((e) => {
				this.setDashboardPageError(e);
			});
	}

	setDashboardPageError = (e) => {
		const { flags } = { ...this.state };
		if (e.hasOwnProperty('title') && e.hasOwnProperty('message')) {
			flags.apiError = e;
		} else {
			flags.errorMessage = 'Error loading page';
		}
		flags.isLoading = false;
		flags.isPendingSave = false;
		this.setState({ flags: flags });
		this.setState({ isLoading: false });
	};

	closeError = () => {
		const state = { ...this.state };
		state.flags.apiError = null;
		this.setState({ ...state });
	};

	async mountDashboard() {
		const { externalId, account } = this.props.UserContext;
		console.log('B2C id for the user is ' + account.accountIdentifier);

		await Promise.all([getPresents(externalId)])
			.then(([presents]) => {
				this.setState({
					presents: presents,
					isLoading: false,
				});
			})
			.catch((e) => {
				this.setDashboardPageError(e);
			});
	}

	getSavedJobLink = () => {
		return ltdomain() + '/Resources/JobPosting/SavedJobs';
	};

	togglePresentDialog = (view) => {
		this.setState({ openPresentsDialog: view });
	};

	displayPresentsDialog = (name) => {
		this.setState({ openPresentsDialog: true });
	};

	renderPresentsDialog = () => {
		const { openPresentsDialog } = this.state;
		const { classes, t } = this.props;
		return (
			<div className={classes.contentRoot}>
				<Paper classes={{ root: classes.topTileRoot }}>
					<Dialog
						open={openPresentsDialog}
						scroll='body'
						maxWidth={'md'}
						fullWidth
						onClose={() => this.togglePresentDialog(false)}
					>
						<DialogTitle style={{ padding: '8px 16px' }}>
							<Grid item xs={12} className={classes.linkHeaderTitle}>
								{t('presents:PRESENTS')}
							</Grid>
						</DialogTitle>
						<DialogContent
							classes={{ root: classes.dialogContentRoot }}
							style={{ padding: '8px 16px' }}
						>
							<PresentComponent setAssignmentPageError={this.setDashboardPageError} />
						</DialogContent>
					</Dialog>
				</Paper>
			</div>
		);
	};

	render() {
		const { isLoading, flags, optimizeVariant, recommendedJobList } = this.state;

		console.log(' recommendedJobList  -> ' + JSON.stringify(recommendedJobList));
		const { classes, t } = this.props;
		const { externalId } = this.props.UserContext;
		const linkSavedJob = this.getSavedJobLink();
		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}
		return (
			<>
				<div className={classes.root}>
					<ErrorDialog
						open={!!flags.apiError}
						onClose={this.closeError}
						title={!flags.apiError ? null : flags.apiError.title}
						description={!flags.apiError ? null : flags.apiError.message}
						errorId={!flags.apiError ? null : flags.apiError.errorId}
					/>
					<Container
						maxWidth='xl'
						className={classes.ContainerRoot}
						justify='space-evenly'
					>
						<Box mb={2}>
							<Grid container direction='row' spacing={4}>
								<Grid
									item
									xs={12}
									md={8}
									className={classes.helpPanelContainerRootJobSearch}
								>
									<div className={classes.dateInfoGrid}>
										<div className={classes.dateItemColumn}>
											<QuickSearchWidget
												title={'Find your next job '}
												level1={true}
											/>
										</div>
									</div>
								</Grid>
								<Grid
									item
									xs={12}
									md={4}
									className={classes.helpPanelContainerRoot}
								>
									<Grid item xs={12}>
										<span className='tileHeader'>
											{t(`dashboard:HEADERS.CLINICIAN_ACTION`)}
										</span>
									</Grid>
									<Grid container direction='row' spacing={3}>
										<Grid item xs={12} md={6}>
											<Paper
												className={classes.presentsPaper}
												onClick={() => this.displayPresentsDialog('Paper')}
											>
												<Box p={1}>
													<Grid item className={classes.presentsIcon}>
														<img
															src={presentsIcon}
															alt={t(`dashboard:CA_TITLES.PRESENTS`)}
															className={classes.clinicianActionIcon}
														></img>
														<h5 className={classes.iconText}>
															{t(`dashboard:CA_TITLES.PRESENTS`)}
														</h5>
													</Grid>
												</Box>
											</Paper>
										</Grid>
										<Grid item xs={12} md={6}>
											<Paper className={classes.savedPaper}>
												<Box p={1}>
													<Grid item className={classes.jobsIcon}>
														<a href={linkSavedJob}>
															<img
																src={savedJobsIcon}
																alt={t(
																	`dashboard:CA_TITLES.SAVED_JOBS`
																)}
																className={
																	classes.clinicianActionIcon
																}
															></img>
															<h5 className={classes.iconText}>
																{t(
																	`dashboard:CA_TITLES.SAVED_JOBS`
																)}
															</h5>
														</a>
													</Grid>
												</Box>
											</Paper>
										</Grid>
									</Grid>

									<Grid container direction='row' spacing={3}>
										<Grid item xs={12} md={6}>
											<Paper className={classes.cvPaper}>
												<Box p={1}>
													<Grid item className={classes.cvIcon}>
														<NavLink to={{ pathname: '/manage-cv' }}>
															<img
																src={cvIcon}
																alt={t(
																	`dashboard:CA_TITLES.MANAGE_CV`
																)}
																className={
																	classes.clinicianActionIcon
																}
															></img>
															<h5 className={classes.iconText}>
																{t(`dashboard:CA_TITLES.MANAGE_CV`)}
															</h5>
														</NavLink>
													</Grid>
												</Box>
											</Paper>
										</Grid>
										<Grid item xs={12} md={6}>
											<Paper className={classes.preferencePaper}>
												<Box p={1}>
													<Grid item className={classes.cvIcon}>
														<NavLink
															to={{ pathname: '/profile/preference' }}
														>
															<img
																src={UpdatePrefsImg}
																alt={t(
																	`dashboard:CA_TITLES.PREFERENCES`
																)}
																className={
																	classes.clinicianActionIcon
																}
															></img>
															<h5 className={classes.iconText}>
																{t(
																	`dashboard:CA_TITLES.PREFERENCES`
																)}
															</h5>
														</NavLink>
													</Grid>
												</Box>
											</Paper>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>

						<Box mb={4}>
							{recommendedJobList.length > 0 ? (
								<Grid container spacing={4}>
									<Grid item xs={12}>
										<span className='tileHeader'>
											{t(`dashboard:HEADERS.RECOMMENDED_JOBS`)}
										</span>
										<CarouselComponent
											recommendedJobList={recommendedJobList}
											optimizeVariant={optimizeVariant}
											t={t}
										/>
									</Grid>
								</Grid>
							) : null}
						</Box>

						<Box mb={4}>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<LetUsDoTheWorkTile t={t} />
								</Grid>
							</Grid>
						</Box>

						<Box mb={4}>
							<Grid container spacing={4}>
								<Grid item xs={12} md={6} lg={5}>
									{/* <span className="tileHeader">{t(`dashboard:HEADERS.PREF_CENTER`)}</span> */}
									<Paper className={classes.prefPaper}>
										<PreferenceCompletion externalId={externalId} />
									</Paper>
								</Grid>
								<Grid item xs={12} md={6} lg={7}>
									<GetCredentialsWidgetDashboard />
								</Grid>
							</Grid>
						</Box>

						<Grid container>
							<Grid item xs={12}>
								<span className='tileHeader'>
									{t(`dashboard:HEADERS.SELF_EMP_HEADER`)}
								</span>
							</Grid>
						</Grid>
						<Grid container spacing={4}>
							<Grid item xs={12} md={4}>
								<SelfEmploymentPlanning t={t} />
							</Grid>
							<Grid item xs={12} md={4}>
								<ResourcesTile t={t} />
							</Grid>
							<Grid item xs={12} md={4}>
								<SalarySurveyTile t={t} />
							</Grid>
						</Grid>

						{this.renderPresentsDialog()}
					</Container>
				</div>
			</>
		);
	}
}

DashboardL1.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
	}).isRequired,
};

DashboardL1.contextType = UserContext;
export default WithContexts(withRouter(withTranslation()(withStyles(styles)(DashboardL1))));
