import React, { Component } from 'react';
import {
	withStyles,
	Grid,
	Button,
	InputLabel,
	Input,
	CircularProgress,
	Chip,
	NativeSelect,
	Hidden,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS, DOCUMENT_REMOVE_LIST } from '../../../../../utils/Application_Constants';
import {
	uploadDocumentWCAPI,
	getDocumentsForTypesWCAPI,
} from '../../../../../services/DocumentService';
import TextFieldComponent from '../../../../GlobalComponents/TextFieldComponent/TextFieldComponent';
import ClinicianDocumentTableV2 from './ClinicianDocumentTableV2';
import WithContexts from '../../../../../contexts/withContexts';
import Alert from '@material-ui/lab/Alert';

const styles = (theme) => ({
	header: {
		textAlign: 'left',
		fontWeight: '700',
		letterSpacing: '0',
		fontSize: '16px',
		paddingBottom: '10px',
		color: COLORS.RAVEN,
	},
	subtext: {
		margin: '0',
		fontSize: '14px',
		fontStyle: 'italic',
		color: COLORS.RAVEN,
		[theme.breakpoints.up('md')]: {
			textAlign: 'left',
		},
	},
	inputLabel: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '18px',
		textAlign: 'left',
		margin: '5px 0',
	},
	docList: {
		textAlign: 'left',
	},
	uploadWrapper: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			justifyContent: 'flex-start',
		},
		paddingRight: '5px',
	},
	buttonWrapper: {
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		borderRadius: '3px',
		display: 'flex',
		justifyContent: 'flex-start',
	},
	uploadDocumentContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	uploadStatusContainer: {
		marginTop: '12px',
		color: COLORS.RAVEN,
		fontWeight: '600',
		textAlign: 'left',
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
			textAlign: 'center',
		},
	},
	uploadButtonContainer: {
		margin: '5px 10px 5px 0px',
		[theme.breakpoints.down('sm')]: {
			margin: '5px',
		},
		paddingTop: '25px',
	},
	filePickerButton: {
		textTransform: 'inherit',
		margin: '5px',
		[theme.breakpoints.down('sm')]: {},
	},
	buttonRoot: {
		backgroundColor: `${COLORS.TRUE_BLUE}`,
		color: `${COLORS.WHITE}`,
		width: '100%',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
	},
	buttonDisabled: {
		textTransform: 'inherit',
		backgroundColor: `${COLORS.TRUE_BLUE}`,
		color: `${COLORS.WHITE} !important`,
		opacity: '.3',
		width: '100%',
	},
	fileInput: {
		display: 'none',
	},
	fileText: {
		color: COLORS.RAVEN,
		fontWeight: '600',
		textAlign: 'left',
		overflowWrap: 'anywhere',
	},
	uploadContainer: {
		marginTop: '10px',
	},
	documentContainer: {
		marginTop: '10px',
		paddingRight: '60px',
		marginLeft: '-20px',
		marginBottom: '10px',
		paddingBottom: '40px',
	},
	progressWrapper: {
		width: '100%',
		textAlign: 'center',
		padding: '15px 0',
	},
	chipRoot: {
		//border: `1px solid ${COLORS.ASTRAL}`,
		color: COLORS.RAVEN,
		backgroundColor: COLORS.WHITE,
		marginLeft: '15px',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(2)': {
				marginLeft: '0',
			},
			marginBottom: '10px',
			marginTop: '10px',
			marginLeft: '0',
		},
	},
	alignLeft: {
		textAlign: 'left',
	},
	chipLabel: {
		fontWeight: '500',
		fontSize: '14px',
	},
	errorMessage: {
		margin: '0px 30px',
	},
	alertMessage: {
		fontSize: '12px',
		fontWeight: '500',
		padding: '10px 15px',
	},
});

class UploadClinicianDocumentV2 extends Component {
	constructor(props) {
		super(props);
		let docType = props.documentType;
		if (!docType)
			docType =
				!props.docTypeList || props.docTypeList.length === 0
					? null
					: props.docTypeList[0].value;

		this.state = {
			documentType: docType,
			fileName: null,
			fileSelected: false,
			uploadStatus: null,
			isLoading: false,
			documents: [],
			badExtension: false,
			errorMessageFlag: false,
		};
	}

	getDocuments = async () => {
		const { externalId } = this.props.UserContext;
		const { documentType } = this.state;
		const { docTypeList } = this.props;
		const allDocTypes = !docTypeList ? [documentType] : docTypeList.map((i) => i.value);
		return await getDocumentsForTypesWCAPI(externalId, allDocTypes);
	};

	deleteHandle = async () => {
		this.setState({
			errorMessageFlag: false,
		});
	};
	async reloadDocuments() {
		this.setState({ isLoading: true });
		const documents = await this.getDocuments();
		this.setState({
			documents: documents || [],
			isLoading: false,
		});
	}

	async componentDidMount() {
		const { t, showDocuments } = this.props;
		if (showDocuments !== false) {
			this.reloadDocuments(); // will set state
		} else {
			this.setState({ fileName: t('credentials:DOCUMENTS.NO_FILE_SELECTED') });
		}
	}

	/**
	 * Alerts the user of the document upload status.
	 */
	getUploadStatus() {
		const { t } = this.props;
		const state = { ...this.state };
		if (state.badExtension) {
			return t('credentials:DOCUMENTS.BAD_FILE_TYPE');
		}
		if (state.uploadStatus === 200) {
			return t('documents:UPLOAD.SUCCESS');
		}
		if (state.uploadStatus !== null) {
			return t('documents:UPLOAD.FAILURE');
		}
		return '';
	}

	/**
	 * Renders the file that was selected by the computers
	 * explorer window.
	 * @param {MouseEvent} event
	 */
	renderFile = (event) => {
		const state = { ...this.state };
		const { files } = event.target;
		if (files && files.length !== 0) {
			state.fileName = files[0].name;
			state.fileSelected = true;
		}
		this.setState({ ...state });
	};

	doUploadDocument = async () => {
		const { t, statusCallback, updateSectionCompletion, sectionCompletion } = this.props;
		const state = { ...this.state };

		// Get doc before it is hidden
		const file = document.getElementById(`uploadInput-${state.documentType}`).files[0];

		if (state.documentType === 'Photo') {
			var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.bmp|\.tif|\.tiff)$/i;
			var filePath = file.name;
			if (!allowedExtensions.exec(filePath)) {
				this.setState({ badExtension: true });
				return;
			}
		}

		this.setState({ isLoading: true });

		if (state.documentType === 'CV' || state.documentType === 'Curriculum Vitae') {
			const uploadedDocuments = await this.getDocuments();
			console.log('uploadedDocument.length=' + uploadedDocuments.length);
			if (uploadedDocuments !== undefined && uploadedDocuments.length >= 3) {
				this.setState({
					badExtension: false,
					fileSelected: false,
					uploadStatus: null,
					fileName: t('credentials:DOCUMENTS.NO_FILE_SELECTED'),
					isLoading: false,
					errorMessageFlag: true,
				});
				return;
			}
		}
		// NOTE: metadata may need to change depending on what needs to be set on upload
		// like assignmentId for assignment docs
		const metadata = {};
		const status = await uploadDocumentWCAPI(state.documentType, file, metadata);
		const documents = await this.getDocuments();
		if (status) {
			state.fileName = t('credentials:DOCUMENTS.NO_FILE_SELECTED');
			state.fileSelected = false;
		}
		this.setState({
			documents: documents || [],
			uploadStatus: !status ? null : status,
			badExtension: false,
			fileName: state.fileName,
			fileSelected: state.fileSelected,
			isLoading: false,
		});
		if (!!statusCallback) statusCallback(true, documents);
		if (!!updateSectionCompletion) updateSectionCompletion(sectionCompletion);
	};

	renderTable = () => {
		const { showDocuments, credState, updateSectionCompletion, sectionCompletion } = this.props;
		if (showDocuments === false) return null;
		const { documents } = this.state;
		return (
			<div>
				<ClinicianDocumentTableV2
					documents={documents}
					reloadDocuments={this.reloadDocuments}
					deleteHandle={this.deleteHandle}
					credState={credState}
					sectionCompletion={sectionCompletion}
					updateSectionCompletion={updateSectionCompletion}
				/>
			</div>
		);
	};

	renderUploadButton() {
		const { classes, t } = this.props;
		const state = { ...this.state };
		if (state.isLoading) {
			return null;
		}
		return (
			<Button
				onClick={this.doUploadDocument}
				disabled={!state.fileSelected}
				classes={{ root: classes.buttonRoot, disabled: classes.buttonDisabled }}
			>
				{t('common:BUTTONS.UPLOAD')}
			</Button>
		);
	}

	closeControl = () => {
		const { statusCallback } = this.props;
		if (!!statusCallback) statusCallback(false);
	};

	renderCloseButton() {
		const { classes, t, showClose } = this.props;
		const state = { ...this.state };
		if (state.isLoading || !showClose) {
			return null;
		}
		return (
			<Button onClick={this.closeControl} classes={{ root: classes.buttonRoot }}>
				{t('common:BUTTONS.CLOSE')}
			</Button>
		);
	}

	docTypeChange = (event) => {
		const { value } = event.target;
		this.setState({ documentType: value });
	};

	renderDocumentTypesControl() {
		const { classes } = this.props;
		const { documentType } = this.state;

		let { docTypeList } = this.props;

		if (!!docTypeList && docTypeList.length > 0) {
			docTypeList = docTypeList.filter(
				(ar) => !DOCUMENT_REMOVE_LIST.find((rm) => rm.value === ar.value)
			);
		}

		if (!docTypeList || docTypeList.length === 0) return null;

		return (
			<Grid item xs={12} md={5} className={classes.docList}>
				<NativeSelect
					id='documentType'
					onChange={(event) => {
						this.docTypeChange(event);
					}}
					name='documentType'
					value={documentType}
					input={
						<TextFieldComponent classes={{ input: classes.setWidthTo100OnMobile }} />
					}
				>
					{docTypeList.map((item) => (
						<option key={item.value} value={item.value}>
							{item.label}
						</option>
					))}
				</NativeSelect>
			</Grid>
		);
	}

	render() {
		const { classes, headerText, docTypeList, t } = this.props;

		const { documentType, errorMessageFlag } = this.state;
		const state = { ...this.state };
		if (state.isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress />
				</div>
			);
		}
		return (
			<>
				{' '}
				{errorMessageFlag &&
				(documentType === 'CV' || documentType === 'Curriculum Vitae') ? (
					<div className={classes.errorMessage}>
						<Alert
							variant='filled'
							severity='error'
							className={classes.alertMessage}
							onClose={() => {
								this.setState({ errorMessageFlag: false });
							}}
						>
							{t('profile:QUALIFICATIONS.CV_UPLOAD_ERROR_MESSAGE')}
						</Alert>
					</div>
				) : null}
				<Grid xs={12} className={classes.documentContainer}>
					{this.renderTable()}
				</Grid>
				<Grid container classes={{ root: classes.uploadContainer }}>
					{!docTypeList ? (
						''
					) : (
						<Grid container direction='row' xs={12} className={classes.header}>
							<Grid item xs={12} md={5}>
								{t('credentials:DOCUMENTS.DOCUMENT_TYPE')}
							</Grid>
							<Hidden only={['xs', 'sm']}>
								<Grid item>{t('credentials:DOCUMENTS.UPLOAD_LABEL')}</Grid>
							</Hidden>
						</Grid>
					)}
					{!headerText ? '' : <h3 className={classes.header}>{headerText}</h3>}
					{!!docTypeList ? (
						''
					) : (
						<InputLabel
							shrink
							htmlFor='documentType'
							classes={{ root: classes.inputLabel }}
						>
							{documentType === 'CV' || documentType === 'Curriculum Vitae' ? (
								<Chip
									classes={{ root: classes.chipRoot, label: classes.chipLabel }}
									label={t('credentials:DOCUMENTS.CV_UPLOAD_MESSAGE')}
								/>
							) : null}
						</InputLabel>
					)}
					<Grid container direction='row'>
						{this.renderDocumentTypesControl()}
						<Grid item xs={12} md={7} className={classes.uploadWrapper}>
							<Grid classes={{ root: classes.uploadDocumentContainer }}>
								<Grid item classes={{ root: classes.buttonWrapper }}>
									<Input
										id={`uploadInput-${documentType}`}
										type='file'
										onChange={this.renderFile}
										className={classes.fileInput}
									/>
									<label htmlFor={`uploadInput-${documentType}`}>
										<Button
											variant='outlined'
											component='span'
											color='primary'
											classes={{ root: classes.filePickerButton }}
										>
											{t('common:GENERIC_PHRASES.SELECT_FILE')}
										</Button>
									</label>
									<p className={classes.fileText}>{state.fileName}</p>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container xs={12}>
						<Grid item classes={{ root: classes.uploadButtonContainer }}>
							{this.renderUploadButton()}
						</Grid>
						<Grid item classes={{ root: classes.uploadStatusContainer }}>
							{this.getUploadStatus()}
						</Grid>
						<Grid item classes={{ root: `${classes.uploadButtonContainer} ` }}>
							{this.renderCloseButton()}
						</Grid>
					</Grid>
					<div>
						{documentType !== 'Photo' && (
							<h3 className={classes.subtext}>
								{t('credentials:DOCUMENTS.UPLOAD_SPECIFICATIONS')}
							</h3>
						)}
						{documentType === 'Photo' && (
							<h3 className={classes.subtext}>
								{t('credentials:DOCUMENTS.IMAGE_UPLOAD_SPECIFICATIONS')}
							</h3>
						)}
					</div>
				</Grid>
			</>
		);
	}
}

export default WithContexts(withTranslation()(withStyles(styles)(UploadClinicianDocumentV2)));
